import { createReducer, createSelector } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import {
  fetchPastRecordings,
  postPastRecording,
  deletePastRecording,
} from './api';
import remove from 'lodash/remove';

export const loadPastRecordings = createThunk(
  fetchPastRecordings,
  'load',
  'pastRecordings',
);

export const createPastRecording = createThunk(
  postPastRecording,
  'create',
  'pastRecordings',
);

export const removePastRecording = createThunk(
  deletePastRecording,
  'remove',
  'pastRecordings',
);

const INITIAL_STATE = {
  pastRecordings: null,
  createError: false,
  deleteError: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadPastRecordings.type.success]: (state, action) => {
    state.pastRecordings = action.payload.data;
  },
  [createPastRecording.type.request]: (state, action) => {},
  [createPastRecording.type.success]: (state, action) => {
    const newRecording = action.payload.data;

    state.pastRecordings = [newRecording, ...state.pastRecordings];
    state.createError = false;
  },
  [createPastRecording.type.error]: state => {
    state.createError = false;
  },
  [removePastRecording.type.success]: (state, action) => {
    const deletedRecordingId = action.request[1];

    remove(
      state.pastRecordings,
      recording => recording.id === deletedRecordingId,
    );
    state.deleteError = false;
  },
  [removePastRecording.type.error]: state => {
    state.deleteError = true;
  },
});

export const getDeleteError = createSelector(['pastRecordings.deleteError']);
export const getCreateError = createSelector(['pastRecordings.createError']);
