import { createReducer, createAction } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import isEmpty from 'lodash/isEmpty';
import unionBy from 'lodash/unionBy';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import createSelector from 'selectorator';

import { Assets, uploadAsset } from 'modules/assets';
import {
  createAgentAssignment,
  deleteAgentAssignment,
  updateAgentAssignment,
} from 'modules/agents';
import { Mapper } from 'modules/core';
import { resolveErrorMessage } from 'modules/errors';
import { postPastRecording } from 'modules/past-recordings';

import {
  search,
  fetchTalent,
  fetchTalents,
  postTalent,
  patchTalent,
  patchSamplesOrder,
} from './api';

const CLEAR_TALENTS = 'talents/clearTalents';

// TODO: Add error
const INITIAL_STATE = {
  searchedTalents: [],
  searchedTalentsMeta: null,
  searchedTalentsForInvite: [],
  loading: false,
  // TODO: Make obsolete (AHAB-597)
  talents: [],
  talent: null,
  latestRequest: null,
  latestTopCandidatesSearchQuery: null,
  talentsFilterData: null,
  reorderSamplesError: null,
};

export const searchTalents = createThunk(search, 'search', 'talents');
export const searchTalentsForInvite = createThunk(
  search,
  'searchInvite',
  'talents',
);
export const loadTalent = createThunk(fetchTalent, 'loadSingle', 'talents');
export const loadTalents = createThunk(fetchTalents, 'load', 'talents');
export const createTalent = createThunk(postTalent, 'create', 'talents');
export const setTalentsFilterData = createAction('talents/setFilterData');
export const clearTalentsFilterData = createAction('talents/clearFilterData');

export const clearTalents = () => ({
  type: CLEAR_TALENTS,
});

export function uploadTalentProfileImage(accountId, imageFile) {
  return dispatch => {
    const context = Assets.createAccountContext(accountId);
    return dispatch(uploadAsset(imageFile, 'image', 'profileImage', context));
  };
}

const update = createThunk(patchTalent, 'update', 'talents');
export function updateTalent(accountId, talentId, values) {
  return dispatch => {
    const { profileImage } = values;

    if (profileImage) {
      return dispatch(uploadTalentProfileImage(accountId, profileImage)).then(
        assetPath => {
          const talentValues = {
            ...values,
            profileImage: assetPath,
          };

          return dispatch(update(talentId, talentValues));
        },
      );
    }

    return dispatch(update(talentId, values));
  };
}

function updateTalentAndRecordings(talentId, values) {
  const { pastRecordings } = values;

  if (isEmpty(pastRecordings)) {
    return patchTalent(talentId, values);
  }

  const createRecordings = Promise.all(
    map(pastRecordings, pastRecordingValues =>
      postPastRecording(talentId, pastRecordingValues),
    ),
  );

  return createRecordings.then(recordings => {
    const talentWithRecordings = {
      ...values,
      pastRecordingsIds: map(recordings, recording => recording.data.id),
    };

    return patchTalent(talentId, talentWithRecordings);
  });
}

export const updateTalentProfessionalInfo = createThunk(
  updateTalentAndRecordings,
  'updateProfessionalInfo',
  'talents',
);

export const updateSamplesOrder = createThunk(
  patchSamplesOrder,
  'updateSamplesOrder',
  'talents',
);

export const clearReorderSamplesError = createAction(
  'talents/clearReorderSamplesError',
);

export const reducer = createReducer(INITIAL_STATE, {
  [searchTalents.type.request]: (state, action) => {
    state.latestTopCandidatesSearchQuery = action.request[0].query;
    state.loading = true;
  },
  [searchTalents.type.success]: (state, action) => {
    if (action.request[0].query !== state.latestTopCandidatesSearchQuery) {
      return;
    }
    const newSearchedTalents = action.payload.data;

    state.loading = false;
    state.searchedTalents = newSearchedTalents;
    state.searchedTalentsMeta = action.payload.meta;

    state.talents = unionBy(state.talents, newSearchedTalents, 'id');
  },
  [searchTalents.type.error]: state => {
    state.loading = false;
  },
  [searchTalentsForInvite.type.request]: (state, action) => {
    state.latestRequest = action.request[0].query;
    state.searchingForInvite = true;
  },
  [searchTalentsForInvite.type.success]: (state, action) => {
    // We only want to return the last query result (in e.g. a series of requests)
    if (action.request[0].query !== state.latestRequest) {
      return;
    }

    const newSearchedTalents = action.payload.data;

    state.searchingForInvite = false;
    state.searchedTalentsForInvite = newSearchedTalents;
    state.talents = unionBy(newSearchedTalents, state.talents, 'id');
  },
  [searchTalentsForInvite.type.error]: state => {
    state.searchingForInvite = false;
  },
  [loadTalent.type.request]: state => {
    state.loading = true;
  },
  [loadTalent.type.success]: (state, action) => {
    const loadedTalent = get(action, 'payload.data');

    state.loading = false;
    state.talent = loadedTalent;
    state.talents = Mapper.addOrReplace(state.talents, loadedTalent);
  },
  [loadTalent.type.error]: state => {
    state.loading = false;
  },
  [loadTalents.type.request]: state => {
    state.loading = true;
  },
  [loadTalents.type.success]: (state, action) => {
    state.loading = false;
    state.talents = action.payload.data;
  },
  [CLEAR_TALENTS]: state => {
    state.talents = [];
  },
  [createTalent.type.request]: state => {
    state.loading = true;
  },
  [createTalent.type.success]: (state, action) => {
    const newTalent = action.payload.data;

    state.loading = false;
    state.talent = newTalent;
    state.talents = [...state.talents, newTalent];
  },
  [update.type.request]: state => {
    state.loading = true;
  },
  [update.type.success]: (state, action) => {
    const updatedTalent = action.payload.data;

    state.loading = false;
    state.talent = updatedTalent;
    state.talents = Mapper.addOrReplace(state.talents, updatedTalent);
  },
  [updateTalentProfessionalInfo.type.success]: (state, action) => {
    const updatedTalent = action.payload.data;

    state.talent = updatedTalent;
    state.talents = Mapper.addOrReplace(state.talents, updatedTalent);
  },
  [setTalentsFilterData]: (state, action) => {
    state.talentsFilterData = { ...action.payload };
  },
  [clearTalentsFilterData]: state => {
    state.talentsFilterData = null;
  },
  // TODO remove this once we refactor agent assignments reducer and fetching
  [createAgentAssignment.type.success]: (state, action) => {
    state.talent.agentAssignments = [
      ...state.talent.agentAssignments,
      action.payload.data,
    ];
  },
  [updateAgentAssignment.type.success]: (state, action) => {
    state.talent.agentAssignments = state.talent.agentAssignments.map(
      assignment =>
        assignment.id !== action.payload.data.id
          ? assignment
          : action.payload.data,
    );
  },
  [deleteAgentAssignment.type.success]: (state, action) => {
    state.talent.agentAssignments = filter(
      state.talent.agentAssignments,
      assignment => assignment.id !== action.request[0],
    );
  },
  [updateSamplesOrder.type.request]: state => {
    state.loading = true;
  },
  [updateSamplesOrder.type.success]: state => {
    state.loading = false;
  },
  [updateSamplesOrder.type.error]: (state, action) => {
    state.loading = false;
    state.reorderSamplesError = resolveErrorMessage(action.error);
  },
  [clearReorderSamplesError]: state => {
    state.reorderSamplesError = null;
  },
});

export const getTalent = createSelector(['talents.talent']);
export const getSearchedTalents = createSelector(['talents.searchedTalents']);
export const getSearchedTalentsMeta = createSelector([
  'talents.searchedTalentsMeta',
]);

export const getSearchedTalentsForInvite = existingAuditionees =>
  createSelector(
    ['talents.searchedTalentsForInvite'],
    searchedTalents => {
      return filter(
        searchedTalents,
        searchedTalent => !find(existingAuditionees, { id: searchedTalent.id }),
      );
    },
  );
export const getSearchingForInvite = createSelector([
  'talents.searchingForInvite',
]);
export const getTalents = createSelector(['talents.talents']);
export const getTalentLoading = createSelector(['talents.loading']);

export const getTalentById = talentId =>
  createSelector(
    [getTalents],
    talents => find(talents, { id: talentId }),
  );

export const getTalentByAccountId = accountId =>
  createSelector(
    [getTalents],
    talents => find(talents, { accountId }),
  );

export const getTalentsFilterData = createSelector([
  'talents.talentsFilterData',
]);

export const getVisibleTalentsOnAgentsProfile = agentAssignments =>
  createSelector(
    [getSearchedTalents],
    talents => {
      const visibleAssignements = filter(
        agentAssignments,
        assignement => assignement.visibleOnAgentProfile,
      );
      const visibleTalentsIds = visibleAssignements.map(
        assignement => assignement.talentId,
      );
      const visibleTalents = [];

      forEach(talents, talent => {
        const { id } = talent;
        const clientVisible = includes(visibleTalentsIds, id);
        if (clientVisible) {
          visibleTalents.push(talent);
        }
      });
      return visibleTalents;
    },
  );

export const getNotVisibleTalentsOnAgentsProfile = agentAssignments =>
  createSelector(
    [getSearchedTalents],
    talents => {
      const notVisibleAssignements = filter(
        agentAssignments,
        assignement => !assignement.visibleOnAgentProfile,
      );
      const notVisibleTalentsIds = notVisibleAssignements.map(
        assignement => assignement.talentId,
      );
      const notVisibleTalents = [];

      forEach(talents, talent => {
        const { id } = talent;
        const clientNotVisible = includes(notVisibleTalentsIds, id);
        if (clientNotVisible) {
          notVisibleTalents.push(talent);
        }
      });

      return notVisibleTalents;
    },
  );

export const getReorderSamplesError = createSelector([
  'talents.reorderSamplesError',
]);
