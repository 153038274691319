import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  TextInput,
  Textarea,
  ManagedForm,
  ManagedImageInput,
  Select,
  DatePicker,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';
import { Project } from 'modules/projects';
import { Utils } from 'modules/core/services';
import { Validator } from '../services';
import { ProjectGrid } from './form-grids';

const styles = {
  form: {
    display: 'block',
    paddingTop: 92,
    paddingBottom: 12,
    width: 552,
  },
  textArea: {
    maxHeight: 197,
  },
  image: {
    width: 128,
    height: 128,
    borderRadius: 4,
  },
  imageManager: {
    marginTop: 16,
    width: 128,
  },
};

const CROP_SIZE = { width: 242, height: 242 };

const ProjectForm = forwardRef((props, ref) => {
  const {
    onSubmit,
    onCancel,
    initialValues,
    submitButtonLabel,
    cover,
    ...otherProps
  } = props;

  const [currentVertical, setCurrentVertical] = useState(
    get(initialValues, 'vertical') || null,
  );
  const [genreOptions, setGenreOptions] = useState(
    Project.getGenresFromVertical(get(initialValues, 'vertical')) || null,
  );

  function handleValueChange(formValues) {
    const { vertical } = formValues;
    if (vertical !== currentVertical) {
      setCurrentVertical(vertical);
      setGenreOptions(Project.getGenresFromVertical(vertical));
    }
  }

  return (
    <ManagedForm
      ref={ref}
      style={styles.form}
      showFooterDivider
      showFooterDescription={false}
      title="Project Details"
      submitButtonLabel={submitButtonLabel}
      onSubmit={onSubmit}
      onChangeValues={handleValueChange}
      onCancel={onCancel}
      successText="Saved"
      initialValues={initialValues}
      validate={Validator.validateProject}
      TemplateComponent={ProjectGrid}
      {...otherProps}
    >
      <ManagedImageInput
        name="coverImage"
        label="Cover"
        title="Edit cover photo"
        initialImage={cover}
        cropSize={CROP_SIZE}
        cropShape="rect"
        showGrid
        imageStyle={styles.image}
        style={styles.imageManager}
      />
      <TextInput
        required
        autoFocus
        label="Title"
        name="title"
        placeholder="E.g. Moby Dick"
        autoComplete={Utils.PREVENT_AUTOCOMPLETE_IN_MODAL}
      />
      <TextInput
        type="number"
        label="Length"
        name="length"
        placeholder="000"
        autoComplete={Utils.PREVENT_AUTOCOMPLETE_IN_MODAL}
        maskFunction={Utils.isEnteringFloat}
      />
      <Select
        name="lengthType"
        required
        lightInput
        expected
        options={Dictionary.RECORDING_LENGTH_DURATION_TYPE_OPTIONS}
        isInModal
      />
      <DatePicker label="Project due date" name="recordingDueDate" />
      <Select
        label="Category"
        name="vertical"
        placeholder="Choose a Category"
        options={Dictionary.VERTICAL_OPTIONS}
        required
        isInModal
      />
      <Select
        label="Genre"
        name="genre"
        placeholder="Choose a genre"
        options={genreOptions}
        disabled={isEmpty(genreOptions)}
        isInModal
      />
      <Textarea
        label="Short description"
        name="description"
        maxChars={1000}
        placeholder="Enter Description"
        style={styles.textArea}
      />
    </ManagedForm>
  );
});

ProjectForm.propTypes = {
  submitButtonLabel: PropTypes.string,
  cover: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
};

export default ProjectForm;
