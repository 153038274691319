import isNil from 'lodash/isNil';

import { Api } from 'modules/core';

const resourceType = 'general-messages';

function globalMessagesUrl(threadId, isGetAll) {
  if (threadId && isGetAll) {
    return `general-message-threads/${threadId}/messages/all`;
  }
  if (threadId) {
    return `general-message-threads/${threadId}/messages`;
  }
  return `general-message-threads`;
}

export function fetchGlobalMessages(threadId) {
  if (isNil(threadId)) return Promise.resolve();
  return Api.find(globalMessagesUrl(threadId, true));
}

export function postGlobalMessage(threadId, text) {
  if (isNil(threadId) || isNil(text)) return Promise.resolve();
  return Api.create(globalMessagesUrl(threadId), { resourceType, text });
}

export function fetchUnreadMessagesCount() {
  const endpoint = `${globalMessagesUrl()}/actions/countUnread`;
  return Api.find(endpoint);
}
