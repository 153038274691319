/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'modules/ui';
import { Colors } from 'modules/theme';
import { IconButton } from 'modules/ui/components/IconButton';
import styles from './AccountAvatarMenu.module.scss';
import AccountDropdown from './AccountDropdown';
import { useAnchorEl } from '../hooks';

export default function AccountAvatarMenu({
  fullName,
  city,
  state,
  country,
  avatar,
  onEditProfile,
  onSettings,
  onLogout,
  isActive,
}) {
  const [anchorEl, handleDropdownClick, handleClose] = useAnchorEl();

  function performEditProfile(event) {
    onEditProfile();
    handleClose(event);
  }

  function performSettings(event) {
    onSettings();
    handleClose(event);
  }

  function performLogoutClick(event) {
    onLogout();
    handleClose(event);
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={styles.avatarMenu} onClick={handleDropdownClick}>
      <Avatar photoUrl={avatar} aria-haspopup="true" isActive={isActive} />
      <IconButton
        iconName="settingsDropdown"
        iconColor={Colors.grayishBlue}
        iconSize={12}
        onClick={handleDropdownClick}
      />
      <AccountDropdown
        fullName={fullName}
        city={city}
        state={state}
        country={country}
        anchorEl={anchorEl}
        onProfile={handleClose}
        onEditProfile={performEditProfile}
        onSettings={performSettings}
        onLogout={performLogoutClick}
        onClose={handleClose}
      />
    </div>
  );
}

AccountAvatarMenu.propTypes = {
  fullName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  avatar: PropTypes.string,
  onLogout: PropTypes.func,
  onEditProfile: PropTypes.func,
  onSettings: PropTypes.func,
  isActive: PropTypes.bool,
};

AccountAvatarMenu.defaultProps = {
  avatar: null,
};
