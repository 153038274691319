import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { getAsset } from 'modules/assets';
import { Avatar, LineDivider } from 'modules/ui';

import { relativeTime } from '../const';

import classes from './MessagingCard.module.scss';

moment.updateLocale('en', { relativeTime });

function MessagingCard({
  title,
  subtitle,
  photoUrl,
  message,
  createdAt,
  isUnread,
  onClick,
  headingLabel,
}) {
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    getAsset(photoUrl).then(url => setAvatar(url));
  }, [photoUrl]);

  return [
    <div className={classes.card} onClick={onClick}>
      <Avatar size={32} photoUrl={avatar} />
      <div className={classes.cardContent}>
        {title && (
          <p className={classNames(classes.cardText, classes.cardTitle)}>
            {title}
          </p>
        )}
        <div className={classes.cardSubtitle}>
          <p className={classNames(classes.cardText, classes.cardSubtitleText)}>
            {`${subtitle} `}
            {headingLabel && (
              <span className={classes.headingLabel}>{headingLabel}</span>
            )}
          </p>
          <div className={classes.cardTimeAgo}>
            <p className={classes.cardTimeAgoText}>
              {moment(createdAt).fromNow()}
            </p>
            {isUnread && <div className={classes.cardTimeAgoIcon} />}
          </div>
        </div>
        <p className={classes.cardLastMessageText}>{message}</p>
      </div>
    </div>,
    <LineDivider />,
  ];
}

MessagingCard.propTypes = {
  subtitle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  isUnread: PropTypes.bool.isRequired,
  title: PropTypes.string,
  photoUrl: PropTypes.string,
  onClick: PropTypes.func,
  headingLabel: PropTypes.string,
};

export default MessagingCard;
