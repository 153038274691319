import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Section } from 'modules/ui';

const EMPTY_PRONOUNS_NOTICE = 'Add pronouns.';

const styles = {
  content: {
    wordWrap: 'break-word',
  },
};

export default function PronounSection({
  pronouns,
  className,
  emptyPronounsNotice,
}) {
  return (
    <Section
      title="Pronouns"
      isEmpty={isEmpty(pronouns)}
      className={className}
      placeholderText={emptyPronounsNotice || EMPTY_PRONOUNS_NOTICE}
    >
      <p className="body16" style={styles.content}>
        {pronouns}
      </p>
    </Section>
  );
}

PronounSection.propTypes = {
  pronouns: PropTypes.string,
  className: PropTypes.string,
  emptyPronounsNotice: PropTypes.string,
};
