import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Collapsible,
  IconButton,
  MultiSelect,
  CheckboxInput,
} from 'modules/ui';
import { Colors } from 'modules/theme';
import Dictionary from 'modules/dictionary';
import styles from './SearchForm.module.scss';

export default function ExperienceSearchForm({
  handleSetFormValue,
  getValue,
  toggleExperienceFormOpen,
  experienceFormOpen,
}) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Experience</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleExperienceFormOpen}
        />
      </div>
      <Collapsible hideButton collapsed={!experienceFormOpen}>
        <MultiSelect
          name="recordingExperienceIds"
          value={getValue('recordingExperienceIds')}
          placeholder="Audiobooks Read"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.RECORDING_EXPERIENCES}
          options={Dictionary.RECORDING_EXPERIENCE_OPTIONS}
        />
        <MultiSelect
          name="preferredGenres"
          value={getValue('preferredGenres')}
          placeholder="Preferred Audiobook Genres"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.AUDIOBOOKS_GENRES}
          options={Dictionary.AUDIOBOOK_GENRE_OPTIONS}
        />
        <CheckboxInput
          name="trainedInMotionCapture"
          text="Trained in Mo-cap"
          value={getValue('trainedInMotionCapture')}
          setFormValue={handleSetFormValue}
          containerClassName={classNames(
            styles.searchFormCheckboxInput,
            styles.trainedInMoCapContainer,
          )}
        />
      </Collapsible>
    </div>
  );
}

ExperienceSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleExperienceFormOpen: PropTypes.func.isRequired,
  experienceFormOpen: PropTypes.func.isRequired,
};
