import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';

import { getAccountType, AccountType } from 'modules/accounts';
import { loadAsset } from 'modules/assets';
import { Avatar } from 'modules/ui';
import { Talent, getTalentById } from 'modules/talents';
import { TableCell, TableRow } from '@material-ui/core';
import DateUtil from 'modules/core/services/date';

import OfferStatusDisplay from './OfferStatusDisplay';
import Offer from '../services/offer';
import classes from './OfferTableRow.module.scss';

const styles = {
  nameCell: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 16,
    maxWidth: 44,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
  },
  avatar: {
    margin: 10,
  },
  pointerCell: {
    cursor: 'pointer',
  },
};

function OffersTableRow({ offer, onClick }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const accountType = useSelector(getAccountType);
  const talentId = get(offer, 'talentId');
  const talent = useSelector(getTalentById(talentId));

  const projectRole = get(offer, 'projectRole');
  const project = get(projectRole, 'project');
  const agentsTalent = get(offer, 'talent');

  const [image, setImage] = useState({});

  useEffect(() => {
    if (AccountType.isAgent(accountType)) {
      const { profileImage } = agentsTalent;
      dispatch(loadAsset(profileImage, true)).then(asset => setImage(asset));
    }
  }, [agentsTalent, dispatch, accountType]);

  const hasAgent = Talent.hasAgent(talent, get(project, 'vertical'));

  return (
    <TableRow style={styles.pointerCell}>
      {AccountType.isAgent(accountType) && (
        <TableCell
          align="left"
          component="th"
          scope="row"
          style={styles.nameCell}
          onClick={() => history.push(`/talent/${agentsTalent.id}`)}
        >
          <Avatar
            photoUrl={image}
            aria-haspopup="true"
            className={classes.avatar}
          />
          {agentsTalent.fullName}
        </TableCell>
      )}
      <TableCell
        align="left"
        component="th"
        scope="row"
        onClick={onClick}
        style={styles.nameCell}
      >
        {get(offer, 'projectRole.project.title')}
      </TableCell>
      <TableCell
        align="left"
        component="th"
        scope="row"
        onClick={onClick}
        style={styles.nameCell}
      >
        {get(offer, 'projectRole.name')}
      </TableCell>
      {AccountType.isTalent(accountType) && (
        <TableCell align="left" component="th" scope="row" onClick={onClick}>
          {get(offer, 'producer.fullName')}
        </TableCell>
      )}
      <TableCell align="left" component="th" scope="row" onClick={onClick}>
        {DateUtil.formatDate(get(offer, 'createdAt'))}
      </TableCell>
      <TableCell align="left" component="th" scope="row" onClick={onClick}>
        {DateUtil.formatDate(get(offer, 'dueDate'))}
      </TableCell>
      <TableCell align="left" component="th" scope="row" onClick={onClick}>
        <OfferStatusDisplay
          resolvedStatus={Offer.getResolvedStatus(offer, true)}
          hasAgent={hasAgent}
          showHasAgent={AccountType.isTalent(accountType)}
        />
      </TableCell>
    </TableRow>
  );
}

OffersTableRow.propTypes = {
  offer: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(OffersTableRow);
