import React from 'react';
import { useSelector } from 'react-redux';

import { MessagingHorizontalMenuLabel } from 'modules/chat';
import { getUnreadMessagesCount } from 'modules/global-messages';

function GlobalMessagingHorizontalMenuLabel() {
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);
  return (
    <MessagingHorizontalMenuLabel
      label="Messages"
      unreadMessages={unreadMessagesCount}
    />
  );
}

export default GlobalMessagingHorizontalMenuLabel;
