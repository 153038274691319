import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import noop from 'lodash/noop';
import WizardTabs from './WizardTabs';
import WizardFooter from './WizardFooter';

export default function Wizard({
  children,
  onPageNavigation,
  onPreviousPage,
  onNextPage,
  finalButtonLabel,
  onSubmit,
  hasFooter,
  canSkipPages,
  ...otherProps
}) {
  const [page, setPage] = useState(0);
  const numOfPages = size(children);
  const isFinalPage = page === numOfPages - 1;

  function handleNextPage() {
    const nextIndex = page < numOfPages - 1 ? page + 1 : page;

    return onPageNavigation()
      .then(() => onNextPage())
      .then(() => setPage(nextIndex))
      .catch(noop);
  }

  function handlePreviousPage() {
    const nextIndex = page > 0 ? page - 1 : page;

    return onPageNavigation()
      .then(() => onPreviousPage())
      .then(() => setPage(nextIndex))
      .catch(noop);
  }

  function handlePageSelect(key) {
    if (canSkipPages) {
      return onPageNavigation().then(() => setPage(key));
    }

    const isMovingForward = key > page;
    const oneStepForward = key === page + 1;

    if (!isMovingForward) {
      return setPage(key);
    }

    if (oneStepForward) {
      return onPageNavigation().then(() => setPage(key));
    }

    return onPageNavigation();
  }

  const renderChild = (child, index) => {
    const isActive = index === page;
    const disabled = !canSkipPages && index > page + 1;

    return cloneElement(child, {
      pageIndex: index,
      isActive,
      disabled,
    });
  };

  return (
    <>
      <WizardTabs onClick={handlePageSelect}>
        {Children.map(children, renderChild)}
      </WizardTabs>
      {hasFooter && (
        <WizardFooter
          onNextClick={isFinalPage ? onSubmit : handleNextPage}
          onPreviousClick={handlePreviousPage}
          nextButtonLabel={isFinalPage ? finalButtonLabel : undefined}
          {...otherProps}
        />
      )}
    </>
  );
}

Wizard.propTypes = {
  ...WizardFooter.propTypes,
  children: PropTypes.node,
  onNextPage: PropTypes.func,
  onPageNavigation: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onSubmit: PropTypes.func,
  hasFooter: PropTypes.bool,
  canSkipPages: PropTypes.bool,
};

Wizard.defaultProps = {
  onNextPage: () => Promise.resolve(),
  onPreviousPage: () => Promise.resolve(),
  onPageNavigation: () => Promise.resolve(),
  hasFooter: true,
  canSkipPages: false,
};
