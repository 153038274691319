import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import values from 'lodash/values';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useSelector, useDispatch } from 'react-redux';
import { Page, SearchHeader, SearchSort } from 'modules/ui';
import { getProducer } from 'modules/producers';
import {
  getSearchedTalents,
  getSearchedTalentsMeta,
  getTalentsFilterData,
  searchTalents,
  setTalentsFilterData,
  getTalentLoading,
} from 'modules/talents';
import { InviteTalentFromSearchDialog } from 'modules/auditions-invitations';
import { Colors } from 'modules/theme';
import { loadLists, AddTalentDialog } from 'modules/lists';
import { SearchForm } from 'modules/sharing';
import { HEADER_SCROLL_OFFSET } from 'modules/ui/components/Page';
import { useTealiumView } from 'modules/accounts';
import { useRouteListener, useScrollRestoration, Utils } from 'modules/core';
import { SearchTalentList } from '../components';
import { SearchTalentCard } from './index';

const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 78,
  },
  content: {
    paddingLeft: 55,
    width: '100%',
    minHeight: '100vh',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  talentsLengthTitle: {
    fontSize: 16,
    color: Colors.darkGrey,
  },
  sortContainer: {
    display: 'flex',
  },
  sortInput: {
    marginLeft: 'auto',
  },
};

const DEFAULT_ROWS_PER_PAGE = 15;
const DEFAULT_SORT = '-updatedAt';
export const TALENTS_REGEX = RegExp('/talent/(\\d+)');

const SORT_OPTIONS = [
  { label: 'Recently Updated', value: DEFAULT_SORT },
  { label: 'Recently Added', value: '-createdAt' },
  { label: 'Alphabetical', value: 'lastName,firstName' },
];

const handleDebounceSearchTalents = debounce((dispatch, data) => {
  dispatch(searchTalents(data));
}, 500);

export default function SearchPage() {
  const dispatch = useDispatch();
  const talentsFilterData = useSelector(getTalentsFilterData);

  const [filterValues, setFilterValues] = useState(
    get(talentsFilterData, 'filterValues') || null,
  );
  const [searchValue, setSearchValue] = useState(
    get(talentsFilterData, 'searchValue') || null,
  );
  const [sort, setSort] = useState(
    get(talentsFilterData, 'sort') || DEFAULT_SORT,
  );
  const [selectedTalent, setSelectedTalent] = useState(null);
  const [page, setPage] = useState(get(talentsFilterData, 'page') || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(talentsFilterData, 'rowsPerPage') || DEFAULT_ROWS_PER_PAGE,
  );
  const [addTalentDialogOpen, setAddTalentDialogOpen] = useState(false);
  const [showInviteTalentModal, setShowInviteTalentModal] = useState(false);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [selectedPreferredGenres, setSelectedPreferredGenres] = useState([]);
  const [scrollY] = useState(get(talentsFilterData, 'scrollY'));

  const meta = useSelector(getSearchedTalentsMeta);
  const producer = useSelector(getProducer);
  const talents = useSelector(getSearchedTalents);
  const talentsLoading = useSelector(getTalentLoading);

  const producerId = get(producer, 'id');
  const count = get(meta, 'count', 0);
  const talentsLengthTitle = `${count} talents`;

  const getSearchData = useCallback(() => {
    return {
      sort,
      query: searchValue,
      offset: rowsPerPage * page,
      limit: rowsPerPage,
      ...filterValues,
    };
  }, [sort, searchValue, rowsPerPage, page, filterValues]);

  useTealiumView('Talent Search Page');

  useLayoutEffect(() => {
    handleDebounceSearchTalents(dispatch, getSearchData());
  }, [searchValue, dispatch, getSearchData]);

  useLayoutEffect(() => {
    dispatch(searchTalents(getSearchData()));
  }, [page, filterValues, rowsPerPage, sort, dispatch, getSearchData]);

  useScrollRestoration(scrollY);

  useRouteListener(
    TALENTS_REGEX,
    talentId =>
      dispatch(
        setTalentsFilterData({
          talentId,
          page,
          filterValues,
          sort,
          rowsPerPage,
          searchValue,
          scrollY: window.scrollY,
        }),
      ),
    [page, filterValues, sort, rowsPerPage, searchValue],
  );

  useEffect(() => {
    dispatch(loadLists(producerId, 'producer'));
  }, [dispatch, producerId]);

  function resetPagination() {
    setPage(0);
  }

  function handleSetFormValue(value) {
    const newValues = { ...filterValues, ...value };
    resetPagination();
    setFilterValues(newValues);

    global.utag.link({
      event_type: 'filter_click',
      module_type: 'Filter Click',
      module_variation: `${Object.keys(value)[0]} | ${Object.values(value)[0]}`,
    });

    const verticals = get(newValues, 'verticals');
    const preferredGenres = get(newValues, 'preferredGenres');
    if (verticals || isNull(verticals)) {
      setSelectedVerticals(isNull(verticals) ? [] : verticals);
    }
    if (preferredGenres || isNull(preferredGenres)) {
      setSelectedPreferredGenres(
        isNull([preferredGenres]) ? [] : preferredGenres,
      );
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    window.scrollTo(0, HEADER_SCROLL_OFFSET);
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  function handleSearchChange(value) {
    resetPagination();
    setSearchValue(value);
  }

  function handleSortSet(name, value) {
    resetPagination();
    setSort(value);
  }

  function handleClearSearch() {
    resetPagination();
    setSearchValue('');
  }

  function handleReset() {
    resetPagination();
    setFilterValues(null);
  }

  function handleAddToList(talent) {
    setSelectedTalent(talent);
    setAddTalentDialogOpen(true);
  }

  function handleAddTalentDialogCancel() {
    setSelectedTalent(null);
    setAddTalentDialogOpen(false);
  }

  function handleInviteToAudition(talent) {
    setSelectedTalent(talent);
    setShowInviteTalentModal(true);
  }

  const selectedTalentId = get(selectedTalent, 'id');

  const isFilterActive = filterValues
    ? !every(values(filterValues), Utils.isEmptyValues)
    : false;

  const shouldShowTalentCount =
    (searchValue || isFilterActive) && !talentsLoading;

  const TalentCards = map(talents, (talent, index) => {
    const key = `talent-card-${index}`;
    return (
      <SearchTalentCard
        key={key}
        talent={talent}
        addToList={handleAddToList}
        onInviteToAudition={handleInviteToAudition}
        selectedVerticals={selectedVerticals}
        selectedPreferredGenres={selectedPreferredGenres}
      />
    );
  });

  return (
    <Page
      contentStyle={styles.contentContainer}
      HeaderComponent={
        <SearchHeader
          title="Ahab Talent Search"
          description="Welcome to Search. Select filters, listen to samples, and preview profiles."
          placeholder="Search by Talent name or keyword"
          defaultValue={searchValue}
          onChange={handleSearchChange}
          onClear={handleClearSearch}
        />
      }
    >
      <SearchForm
        values={filterValues}
        setFormValue={handleSetFormValue}
        reset={handleReset}
        showProducersFilters
      />
      <div style={styles.content}>
        <div style={styles.contentHeader}>
          {shouldShowTalentCount && (
            <div style={styles.talentsLengthTitle}>{talentsLengthTitle}</div>
          )}
          <SearchSort
            options={SORT_OPTIONS}
            setFormValue={handleSortSet}
            value={sort}
            containerStyles={styles.sortInput}
          />
        </div>
        <SearchTalentList
          TalentCards={TalentCards}
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      {addTalentDialogOpen && (
        <AddTalentDialog
          talentId={selectedTalentId}
          onCancel={handleAddTalentDialogCancel}
        />
      )}
      {showInviteTalentModal && (
        <InviteTalentFromSearchDialog
          talent={selectedTalent}
          onClose={() => setShowInviteTalentModal(false)}
          onCancel={() => setShowInviteTalentModal(false)}
        />
      )}
    </Page>
  );
}
