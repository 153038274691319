import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';
import { PageLoader } from 'modules/ui';
import { getLoadingAgents } from 'modules/agents';
import AssignmentListItem from './AssignmentListItem';

export default function TalentAgentsList({ agentAssignments, agents }) {
  const agentsLoading = useSelector(getLoadingAgents);

  if (agentsLoading.length !== 0) {
    return <PageLoader />;
  }

  return map(agentAssignments, assignment => {
    
    const agentWithAccount = find(
      agents,
      assignedAgent => assignedAgent.id === assignment.agentId,
    );

    const agencyName = get(agentWithAccount, 'agencyName');

    return (
      <AssignmentListItem
        name={`${assignment.agentFirstName} ${assignment.agentLastName}`}
        agentId={assignment.agentId}
        company={agencyName || assignment.companyName}
        verticals={assignment.verticals}
      />
    );
  });
}

TalentAgentsList.propTypes = {
  agentAssignments: PropTypes.array.isRequired,
  agents: PropTypes.array.isRequired,
};
