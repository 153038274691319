import React from 'react';
import PropTypes from 'prop-types';

import { ChatPlaceholder } from 'modules/chat';

function GlobalChatPlaceholder({ className }) {
  return (
    <ChatPlaceholder
      className={className}
      title="No messages, yet."
      subtitle="Your conversations will appear here."
    />
  );
}

GlobalChatPlaceholder.propTypes = {
  className: PropTypes.string,
};

export default GlobalChatPlaceholder;
