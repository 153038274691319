import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavBar as ProducerNavBar } from 'modules/producers';
import { NavBar as AgentNavBar } from 'modules/agents';
import { NavBar as TalentNavBar } from 'modules/talents';
import { RESET_SERVER_ERROR } from 'modules/core';
import { logout } from 'modules/auth';
import { getAccountType, getAvatar, preloadAccount } from '../redux';
import { AccountType } from '../services';

export default function AccountNavBar() {
  const dispatch = useDispatch();

  const accountType = useSelector(getAccountType);
  const avatar = useSelector(getAvatar);

  useEffect(() => {
    dispatch(preloadAccount());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch({ type: RESET_SERVER_ERROR });
    dispatch(logout());
  };

  if (AccountType.isProducer(accountType)) {
    return <ProducerNavBar avatar={avatar} onLogout={handleLogout} />;
  }

  if (AccountType.isTalent(accountType)) {
    return <TalentNavBar avatar={avatar} onLogout={handleLogout} />;
  }

  if (AccountType.isAgent(accountType)) {
    return <AgentNavBar avatar={avatar} onLogout={handleLogout} />;
  }

  return null;
}
