import React from 'react';
import pick from 'lodash/pick';
import Dictionary from '@prhdigitalgroup/ahab-shared-data';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
import { Section, InlineSection } from 'modules/ui';
import { VoiceSkills } from 'modules/talents';
import { DictionaryUtil } from 'modules/dictionary';

function VoiceSkillsSection({
  audition,
  error,
  Placeholder,
  placeholderText,
  ...otherProps
}) {
  const voiceSkillsFields = [
    'requiredGender',
    'requiredPrimaryLanguages',
    'requiredAccents',
    'requiredForeignLanguages',
    'requiredEthnicities',
    'requiredRecordingExperiencesIds',
    'requiredVoiceAgeRangesIds',
  ];
  const voiceSkills = pick(audition, voiceSkillsFields);

  const voiceAgeRangeObjs = DictionaryUtil.getDictionaryObjects(
    voiceSkills.requiredVoiceAgeRangesIds,
    Dictionary.VOICE_RANGES,
  );

  const recordingExperienceObjs = DictionaryUtil.getDictionaryObjects(
    voiceSkills.requiredRecordingExperiencesIds,
    Dictionary.RECORDING_EXPERIENCES,
  );

  const genderAndAgeText = VoiceSkills.getGenderAndAgeString(
    voiceSkills.requiredGender,
    voiceAgeRangeObjs,
  );
  const recordingExperienceText = VoiceSkills.getRecordingExperienceString(
    recordingExperienceObjs,
  );

  const foreignLanguagesText =
    voiceSkills.requiredForeignLanguages &&
    voiceSkills.requiredForeignLanguages.join(', ');
  const accentsText =
    voiceSkills.requiredAccents && voiceSkills.requiredAccents.join(', ');

  return (
    <Section title="Voice Skills" {...otherProps}>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <InlineSection
            title="Gender & Vocal Age Range"
            text={genderAndAgeText}
            placeholderText={placeholderText}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InlineSection
            title="Audiobooks Read"
            text={recordingExperienceText}
            placeholderText="Any"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InlineSection
            title="Character cultural background"
            text={
              voiceSkills.requiredEthnicities &&
              voiceSkills.requiredEthnicities.join(', ')
            }
            placeholderText="Any"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InlineSection
            title="Primary language"
            text={
              voiceSkills.requiredPrimaryLanguages &&
              voiceSkills.requiredPrimaryLanguages.join(', ')
            }
            placeholderText={placeholderText}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InlineSection
            title="Language Fluencies"
            text={foreignLanguagesText}
            placeholderText="Language fluencies not provided"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InlineSection
            title="Accents"
            text={accentsText}
            placeholderText="Accents not provided"
          />
        </Grid>
      </Grid>
    </Section>
  );
}

VoiceSkillsSection.propTypes = {
  audition: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  Placeholder: PropTypes.node,
  placeholderText: PropTypes.string,
};

VoiceSkillsSection.defaultProps = {
  placeholderText: 'Not provided.',
};

export default VoiceSkillsSection;
