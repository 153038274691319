import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NavBarDropdown } from 'modules/ui';
import { AuditionMessagingDropdownMenu } from 'modules/audition-messages';
import { GlobalMessagingDropdownMenu } from 'modules/global-messages-threads';
import { ChatDropdownTab, MessagingHorizontalMenu } from 'modules/chat';

import classes from './MessagingDropdown.module.scss';

function MessagingDropdown({ onClose, anchorEl, isDesktopWidth }) {
  const [activeTab, setActiveTab] = useState(ChatDropdownTab.GLOBAL);

  function handleTabChange(tabIndex) {
    setActiveTab(tabIndex);
  }

  function resolveTabComponent() {
    if (activeTab === ChatDropdownTab.GLOBAL)
      return (
        <GlobalMessagingDropdownMenu
          displaySeeAll={isDesktopWidth}
          onClose={onClose}
        />
      );
    return (
      <AuditionMessagingDropdownMenu
        displaySeeAll={isDesktopWidth}
        onClose={onClose}
      />
    );
  }

  return (
    <NavBarDropdown
      open={!!anchorEl}
      width={420}
      showRightArrow={false}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MessagingHorizontalMenu
        containerClassName={classes.tabs}
        tabClassName={classes.tab}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
      {resolveTabComponent()}
    </NavBarDropdown>
  );
}

MessagingDropdown.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  isDesktopWidth: PropTypes.bool.isRequired,
};

export default MessagingDropdown;
