import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import Page from './Page';
import styles from './Page.module.scss';

export default function NotFoundPage() {
  const history = useHistory();

  function handleButtonClick() {
    history.push('/');
  }

  return (
    <Page className={styles['page--notFound']}>
      <h1>404: Page not found</h1>
      <div className="body16 darkGrey">
        The page you're looking for could not be found.
      </div>
      <Button primary className="mt-64 pl-36 pr-36" onClick={handleButtonClick}>
        Back to home
      </Button>
    </Page>
  );
}

NotFoundPage.propTypes = {
  history: PropTypes.object,
};
