import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import get from 'lodash/get';
import {
  Button,
  Section,
  TableWithPagination,
  Select,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';
import { Colors } from 'modules/theme';
import { SamplePreview } from 'modules/audio-samples';
import { AudioSamples } from 'modules/audio-samples/services';
import { getAccountType } from 'modules/accounts';
import { AccountType } from 'modules/accounts/services';
import { Talent } from '../services';
import classes from './AudioSamplesSection.module.scss';

const SCROLL_OFFSET = 313;

const SAMPLE_DETAILS = [
  'ageRange',
  'genre',
  'pointOfView',
  'primaryLanguage',
  'vertical',
];

const verticalOptions = [
  AudioSamples.ALL_VERTICALS,
  ...Dictionary.VERTICAL_OPTIONS,
];

const styles = {
  uploadSamplesBtn: {
    background: 'transparent',
    color: Colors.secondary,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 11,
    textAlign: 'right',
    letterSpacing: 1,
    marginRight: 20,
  },
  chooseVertical: {
    width: 150,
    fontSize: 14,
    fontWeight: 600,
  },
  chooseVerticalContainer: {
    display: 'inline-block',
  },
};

function AudioSamplesSection({ talent, onAddClick }) {
  const accountType = useSelector(getAccountType);

  const [currentVertical, setCurrentVertical] = useState(null);

  const samples = get(talent, 'audioSamples', []);
  const filteredSamples = AudioSamples.filterByVertical(
    samples,
    currentVertical,
  );
  const prioritizedSamples = AudioSamples.verticalNotSelected(currentVertical)
    ? AudioSamples.prioritize(samples)
    : filteredSamples;
  const samplesCount = size(samples);
  const placeholderSamples =
    samplesCount < Talent.REQUIRED_SAMPLES_COUNT
      ? new Array(
          Talent.REQUIRED_SAMPLES_COUNT - size(prioritizedSamples),
        ).fill(undefined)
      : [];
  const formattedSamples = prioritizedSamples.concat(placeholderSamples);

  const activeVerticals = [
    AudioSamples.ALL_VERTICALS.label,
    ...Talent.getSamplesVerticals(talent),
  ];
  const isTalent = AccountType.isTalent(accountType);

  function handleVerticalChange(event) {
    setCurrentVertical(event.target.value);
  }

  function renderSamplePreview(sample, index) {
    return (
      <SamplePreview
        sample={sample}
        index={index}
        isTalent={isTalent}
        onAddClick={onAddClick}
        paths={SAMPLE_DETAILS}
        containerClassName={
          index !== size(formattedSamples) - 1 && classes.placeholderContainer
        }
      />
    );
  }

  return (
    <Section
      title={`Audio Samples (${samplesCount})`}
      className="mb-36"
      ActionComponent={
        <span>
          {isTalent && (
            <Button onClick={onAddClick} style={styles.uploadSamplesBtn}>
              + Upload samples
            </Button>
          )}
          <Select
            menuStyle
            name="vertical"
            options={verticalOptions}
            activeOptions={activeVerticals}
            onChange={handleVerticalChange}
            placeholder="Choose a Category"
            lightInput
            style={styles.chooseVertical}
            containerClassName={classes.chooseVerticalContainer}
            required
            isInModal
          />
        </span>
      }
    >
      <TableWithPagination
        count={size(formattedSamples)}
        rows={formattedSamples}
        renderRow={(sample, index) => renderSamplePreview(sample, index)}
        rowsPerPageLabel="Audio Samples Per Page"
        className={classes.samplesPreviewCard}
        pageChangeScrollY={SCROLL_OFFSET}
      />
    </Section>
  );
}

AudioSamplesSection.propTypes = {
  talent: PropTypes.object,
  onAddClick: PropTypes.func,
};

export default memo(AudioSamplesSection);
