import { createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import { resolveErrorMessage } from 'modules/errors';
import { suggestAudition } from './api';

const INITIAL_STATE = {
  error: false,
  loading: false,
};

export const sendSuggestion = createThunk(
  suggestAudition,
  'suggestAudition',
  'audition-suggestions',
);

export const reducer = createReducer(INITIAL_STATE, {
  [sendSuggestion.type.request]: state => {
    state.loading = true;
  },
  [sendSuggestion.type.success]: state => {
    state.loading = false;
    state.error = false;
  },
  [sendSuggestion.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
});

export const getSuggestionError = createSelector(['auditionSuggestions.error']);
export const getSuggestionLoading = createSelector([
  'auditionSuggestions.loading',
]);
