import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import flatten from 'lodash/flatten';
import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import { useWindowSize } from 'modules/core';
import {
  Select,
  TextButton,
  MultiSelect,
  AutoCompleteSelect,
  Drawer,
  Button,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';
import { Project } from 'modules/projects';
import styles from './SearchForm.module.scss';

export default function SearchForm({
  values,
  setFormValue,
  reset,
  filtersExpanded,
  onCloseFilters,
}) {
  const { desktopWidth } = useWindowSize();

  const [verticals, setVerticals] = useState(get(values, 'verticals') || null);
  const [genreOptions, setGenreOptions] = useState(
    get(values, 'genres') || null,
  );

  useEffect(() => {
    const currentVerticals = get(values, 'verticals');
    if (!isEmpty(xor(verticals, currentVerticals))) {
      setVerticals(currentVerticals);
    }
  }, [values]);

  useEffect(() => {
    let currentGenreOptions = [];
    if (!isEmpty(verticals)) {
      currentGenreOptions = compact(
        flatten(
          map(verticals, vertical => Project.getGenresFromVertical(vertical)),
        ),
      );
    }

    setGenreOptions(uniqBy(currentGenreOptions, 'value'));
  }, [verticals]);

  function getValue(name) {
    return get(values, name);
  }

  function handleSetFormValue(name, value) {
    if (!isEmpty(value) || value === true) {
      return setFormValue({ [name]: value });
    }

    return setFormValue({ [name]: null });
  }

  const filterComponents = (
    <div className={desktopWidth ? styles.container : styles.containerMobile}>
      {desktopWidth && (
        <div className={styles.listTitleContainer}>
          <span className={styles.listTitle}>Filters</span>
          <TextButton
            title="Reset"
            onClick={reset}
            className={styles.resetButton}
          />
        </div>
      )}
      <div
        className={
          desktopWidth
            ? styles.dropdownContainer
            : styles.dropdownContainerMobile
        }
      >
        <MultiSelect
          name="verticals"
          value={getValue('verticals')}
          placeholder="Category"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.VERTICALS}
          options={Dictionary.VERTICAL_OPTIONS}
        />
        <MultiSelect
          name="genres"
          value={getValue('genres')}
          placeholder="Genres"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={genreOptions}
          disabled={isEmpty(genreOptions)}
        />
        <MultiSelect
          name="voiceAgeRanges"
          value={getValue('voiceAgeRanges')}
          placeholder="Vocal Age Range"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={Dictionary.VOICE_RANGE_OPTIONS}
        />
        <Select
          name="requiredGender"
          value={getValue('requiredGender')}
          placeholder="Gender"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={Dictionary.GENDER_REQUIREMENTS_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          name="primaryLanguages"
          value={getValue('primaryLanguages')}
          placeholder="Primary Language"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={Dictionary.LANGUAGE_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          name="foreignLanguages"
          value={getValue('foreignLanguages')}
          placeholder="Language Fluencies"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.LANGUAGES}
          options={Dictionary.LANGUAGE_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          name="accents"
          value={getValue('accents')}
          placeholder="Accent"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={Dictionary.ACCENT_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          name="ethnicities"
          value={getValue('ethnicities')}
          placeholder="Cultural Background"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={Dictionary.ETHNICITY_OPTIONS}
        />
        {!desktopWidth && (
          <Button
            primary
            onClick={onCloseFilters}
            className={styles.applyButton}
            id="apply-filters-button"
            type="submit"
          >
            Apply
          </Button>
        )}
      </div>
    </div>
  );

  if (!desktopWidth) {
    return (
      <Drawer
        open={filtersExpanded}
        anchor="left"
        onClose={onCloseFilters}
        drawerTitle="Filters"
        rightHeaderComponent={
          <TextButton
            title="Reset"
            onClick={reset}
            className={styles.resetButtonWhite}
          />
        }
      >
        {filterComponents}
      </Drawer>
    );
  }

  return filterComponents;
}

SearchForm.propTypes = {
  values: PropTypes.object,
  setFormValue: PropTypes.func,
  reset: PropTypes.func,
  filtersExpanded: PropTypes.bool.isRequired,
  onCloseFilters: PropTypes.func.isRequired,
};
