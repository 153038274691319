import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar } from 'modules/ui';

import classes from './MessageHeading.module.scss';

function MessageHeading({
  title,
  titleClassName,
  subtitle,
  subtitleClassName,
  photoUrl,
  displayAvatar,
}) {
  return (
    <div className={classes.heading}>
      {displayAvatar && <Avatar size={32} photoUrl={photoUrl} />}
      <div className={classes.headingText}>
        <span className={classNames(classes.headingTitle, titleClassName)}>
          {title}
        </span>
        <p className={classNames(classes.headingSubtitle, subtitleClassName)}>
          {subtitle}
        </p>
      </div>
    </div>
  );
}

MessageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleClassName: PropTypes.string,
  photoUrl: PropTypes.string,
  displayAvatar: PropTypes.bool,
};

MessageHeading.defaultProps = {
  displayAvatar: true,
};

export default MessageHeading;
