import React from 'react';
import TableRow from '@material-ui/core/TableRow/TableRow';
import { TableCell, TableCellSortable } from 'modules/ui';
import Submission from '../services/submission';

function SubmissionsHeader({ sort, onRowClick, onSortClick }) {
  return (
    <TableRow>
      <TableCellSortable
        name={Submission.SORT_OPTIONS.FULLNAME}
        sort={sort}
        onClick={onSortClick}
      >
        Talent Name
      </TableCellSortable>
      <TableCell>Sample</TableCell>
      <TableCell>Submitted</TableCell>
      <TableCell />
    </TableRow>
  );
}

export default SubmissionsHeader;
