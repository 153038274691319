import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  TextInput,
  Textarea,
  ManagedForm,
  ManagedImageInput,
  AutoCompleteSelect,
  OptionUtil,
  MultiSelect,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';
import Validator from '../services/validator';
import classes from './ProfileDetailsForm.module.scss';
import ProfileDetailsGrid from './form-grids/ProfileDetailsGrid';

const style = {
  noLabel: {
    marginTop: 34,
  },
};

export default function ProfileDetailsForm({
  onSubmit,
  initialValues,
  image,
  ...otherProps
}) {
  const countryId = get(initialValues, 'country.id');
  const [country, setCountry] = useState(
    find(Dictionary.LOCATIONS, { id: countryId }),
  );
  const states = country ? country.states : null;
  const [availableStates, setAvailableStates] = useState(states);

  function handleCountryChange(event) {
    const id = event.target.value;
    const currentCountry = find(Dictionary.LOCATIONS, { id });

    const foundStates = currentCountry ? currentCountry.states : null;

    setAvailableStates(foundStates);
    setCountry(currentCountry);
  }

  const stateOptions = OptionUtil.getOptions(
    availableStates,
    OptionUtil.mapOptionFromModel,
  );

  function handleValueChange(values) {
    if (!values.countryId) {
      setAvailableStates([]);
    }
  }

  return (
    <ManagedForm
      className={classes.form}
      withPrompt
      title="Profile Details"
      description="Tell us about yourself so that actors can have a better overview of who is hiring them."
      submitButtonLabel="Save personal info"
      onSubmit={onSubmit}
      validate={Validator.validateProfileDetails}
      onChangeValues={handleValueChange}
      TemplateComponent={ProfileDetailsGrid}
      initialValues={initialValues}
      {...otherProps}
    >
      <TextInput
        autoFocus
        label="First Name"
        name="firstName"
        placeholder="E.g. John"
      />
      <TextInput
        autoFocus
        label="Last Name"
        name="lastName"
        placeholder="E.g. Doe"
      />
      <MultiSelect
        label="Pronouns"
        name="pronouns"
        placeholder="Select pronoun"
        options={Dictionary.PRONOUN_OPTIONS}
      />
      <ManagedImageInput
        name="profileImage"
        label="Profile photo"
        title="Edit profile photo"
        initialImage={image}
        cropShape="round"
        showGrid={false}
        imageClass={classes.image}
        className={classes.avatar}
      />
      <TextInput
        autoFocus
        label="Professional Tagline/Title"
        name="professionalHeadline"
        placeholder="E.g. Creative producer"
      />
      <TextInput
        label="Company"
        name="organizationName"
        placeholder="Enter your company name"
      />
      <AutoCompleteSelect
        label="Address"
        name="countryId"
        placeholder="Enter country"
        onChange={handleCountryChange}
        options={Dictionary.LOCATIONS_OPTIONS}
      />
      <AutoCompleteSelect
        name="stateId"
        placeholder="Enter state"
        options={stateOptions}
        disabled={isEmpty(stateOptions)}
        style={style.noLabel}
        arrowStyle={style.noLabel}
      />
      <TextInput name="city" placeholder="City" />
      <TextInput name="zipCode" placeholder="ZIP Code" />
      <Textarea
        label="Biography"
        name="shortBio"
        maxChars={500}
        placeholder="Tell us about yourself."
        className={classes.textarea}
      />
    </ManagedForm>
  );
}

ProfileDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
