// these imports are for ie compatibility\
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// IE11 polyfill for fetch-interceptor to work
// https://stackoverflow.com/a/57485231/9801221
import 'abortcontroller-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { Cognito, initializeAuth } from './modules/auth';
import serviceWorker from './serviceWorker';
import App from './App';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

// initialize Auth for AWS Cognito
Cognito.initialize();

// update state if user is authenticated or not
store.dispatch(initializeAuth());

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Persist loading...</div>} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
