import React, { memo, useState } from 'react';
import classNames from 'classnames';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import styles from './HorizontalMenu.module.scss';
import HorizontalTabLink from './HorizontalTabLink';

function HorizontalMenu({
  tabs,
  light,
  onTabChange,
  containerClassName,
  tabClassName,
  initialTabIndex,
}) {
  const [pageIndex, setPageIndex] = useState(initialTabIndex);
  const containerClasses = classNames(styles.container, containerClassName);

  function handleTabClick(index) {
    setPageIndex(index);

    if (onTabChange) {
      onTabChange(index);
    }
  }

  function renderTab(tab, index) {
    const resolvedIndex = tab.index || index;

    return (
      <HorizontalTabLink
        key={resolvedIndex}
        className={tabClassName}
        light={light}
        onClick={() => handleTabClick(resolvedIndex)}
        active={pageIndex === resolvedIndex}
      >
        {tab.label}
      </HorizontalTabLink>
    );
  }

  return (
    <div className={containerClasses}>
      {map(tabs, (tab, index) => renderTab(tab, index))}
    </div>
  );
}

HorizontalMenu.propTypes = {
  containerClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  tabs: PropTypes.node,
  light: PropTypes.bool,
  onTabChange: PropTypes.func,
  initialTabIndex: PropTypes.number,
};

HorizontalMenu.defaultProps = {
  initialTabIndex: 0,
};

export default memo(HorizontalMenu);
