import React from 'react';
import PropTypes from 'prop-types';

import { SubscribeButton } from 'modules/newsletter';

import { SmallLogo } from "../SmallLogo"
import classes from './PageFooter.module.scss';

function PageFooter({ hideSubscribeButton }) {
  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <SmallLogo light width={45} />
      </div>
      <div className={classes.linksContainer}>
        <div className={classes.columnContainer}>
          <div className={classes.category}>SUPPORT</div>
          <a
            className={classes.link}
            href="https://www.ahabtalent.com/faq/"
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </a>
          <a
            className={classes.link}
            href="mailto:customerservice@ahabtalent.com?subject=Report Abuse"
          >
            Report Abuse
          </a>
          <a
            className={classes.link}
            href="mailto:customerservice@ahabtalent.com"
          >
            Contact Us
          </a>
        </div>
        <div className={classes.columnContainer}>
          <div className={classes.category}>LEGAL</div>
          <a
            className={classes.link}
            href="https://www.ahabtalent.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className={classes.link}
            href="https://www.ahabtalent.com/ca-privacy-notice/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CA Privacy Notice
          </a>
          <a
            className={classes.link}
            href="https://www.ahabtalent.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms Of Service
          </a>
        </div>
      </div>
      <div className={classes.columnContainer}>
        <div className={classes.category}>FOLLOW US</div>
        {!hideSubscribeButton && <SubscribeButton /> }
      </div>
      <div className={classes.info}>
        {`© ${new Date().getFullYear()} Penguin Random House LLC`}
      </div>
    </div>
  );
}


PageFooter.propTypes = {

  hideSubscribeButton: PropTypes.bool.isRequired,

};

export default PageFooter