import { Validator } from 'modules/core';

function validateChangeEmail({ newEmail }) {
  const emailError = Validator.validateEmail(newEmail);

  return {
    ...(!!emailError && { newEmail: emailError }),
  };
}

export default {
  changeEmailData: validateChangeEmail,
};
