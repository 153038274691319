import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import trim from 'lodash/trim';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {
  TextInput,
  PasswordInput,
  ManagedForm,
  CheckboxInput,
} from 'modules/ui';
import { useTealiumView, PLUS_SIGN_REGEX } from 'modules/accounts';

import { Validator } from '../services';
import {
  clearSignUpStatus,
  getSignupError,
  getSignupSuccess,
  signUp,
} from '../redux';
import { SignUpFormGrid, SignUpFormFooterGrid, AhabReCaptchaComponent } from '../components';
import classes from './SignUpPageForm.module.scss';
import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';

const ACCOUNT_TYPES = {
  talent: {
    key: 'talent',
    title: 'Get started absolutely free',
    description: (
      <>
        <strong>Sign up as Talent</strong> and <strong>start </strong>
        auditioning for the roles
      </>
    ),
  },
  contentCreator: {
    key: 'producer',
    title: 'Create your account',
    description: (
      <>
        <strong>Sign up as Content Creator</strong> and
        <strong> build projects</strong> and <strong>auditions</strong>
      </>
    ),
  },
  agent: {
    key: 'agent',
    title: 'Find jobs for your clients',
    description: (
      <>
        <strong>Sign up as Agent</strong> and <strong>manage</strong>{' '}
        opportunities for your client list.
      </>
    ),
  },
};

export default function SignUpPage({ signupAccountType }) {
  const [email, setEmail] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const signupError = useSelector(getSignupError);
  const signupSuccess = useSelector(getSignupSuccess);

  const contentCreatorEmail = get(params, 'email');
  const contentCreatorFirstName = get(params, 'firstName');
  const contentCreatorLastName = get(params, 'lastName');
  
  function resolvePrefilledNames() {
    let firstName = '';
    let lastName = '';
    if (contentCreatorFirstName) {
      firstName = contentCreatorFirstName.replace(PLUS_SIGN_REGEX, ' ');
    }
    if (contentCreatorLastName) {
      lastName = contentCreatorLastName.replace(PLUS_SIGN_REGEX, ' ');
    }
    return { firstName, lastName };
  }

  const initialValues = {
    firstName: resolvePrefilledNames().firstName,
    lastName: resolvePrefilledNames().lastName,
    email: contentCreatorEmail,
  };

  const [values, setValues] = useState(initialValues);

  useTealiumView('Signup Page');

  useEffect(() => () => dispatch(clearSignUpStatus()), []);

  useEffect(() => {
    if (formSubmitted && signupSuccess) {
      history.push({
        pathname: '/welcome',
        state: { email },
      });
    }
  }, [formSubmitted, signupSuccess, email, history]);

  const getTitle = () => {
    const matchingAccount = find(
      ACCOUNT_TYPES,
      account => account.key === signupAccountType,
    );
    return matchingAccount.title;
  };

  const getDescription = () => {
    const matchingAccount = find(
      ACCOUNT_TYPES,
      account => account.key === signupAccountType,
    );
    return matchingAccount.description;
  };

  const [recaptchaToken, setRecaptchaToken] = useState('');

  const getRecaptchaToken = (tokenData) => {
    setRecaptchaToken(tokenData);
    //console.log(tokenData);
  }

  const handleSubmit = async () => {
    const { firstName, lastName } = values;

    const updatedUser = {
      ...values,
      lastName: trim(lastName),
      firstName: trim(firstName),
      accountType: signupAccountType,
      email: trim(values.email.toLowerCase()),
    };
    setEmail(updatedUser.email);
    setFormSubmitted(true);
    
    //console.log({ recaptchaToken });
    return dispatch(
      signUp({
        ...updatedUser,
        newsletterSignup: get(updatedUser, 'newsletterSignup', false),
        recaptchaToken: recaptchaToken,
      }),
    );
  };

  const handleChangeValue = data => {
    setValues(data);
  };
  
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
    <ManagedForm
      title={getTitle()}
      description={getDescription()}
      footerDescription={
        'By clicking "Agree & Continue" I agree to Ahab’s Terms of Service and Privacy Policy.'
      }
      submitButtonLabel="Agree & Continue"
      onSubmit={handleSubmit}
      validate={Validator.signUpData}
      TemplateComponent={SignUpFormGrid}
      FooterTemplateComponent={SignUpFormFooterGrid}
      initialValues={initialValues}
      className={classes.form}
      error={signupError}
      onChangeValues={handleChangeValue}
      values={values}
    >
      <TextInput
        autoFocus
        label="First name"
        name="firstName"
        placeholder="E.g. Julie"
        id="first-name"
      />
      <TextInput
        label="Last name"
        name="lastName"
        placeholder="E.g. Doe"
        id="last-name"
      />
      <TextInput
        label="Email address"
        name="email"
        placeholder="julie@company.com"
        id="email"
      />
      <PasswordInput
        showHint
        label="Add password"
        name="password"
        placeholder="Enter password"
        id="password"
      />
      
      <CheckboxInput
        label=""
        name="newsletterSignup"
        text="Sign up for news and other communications from Ahab"
      />
      <AhabReCaptchaComponent func={getRecaptchaToken}/>
    </ManagedForm>
  </GoogleReCaptchaProvider>
  );
}

SignUpPage.propTypes = {
  signupAccountType: PropTypes.string.isRequired,
};
