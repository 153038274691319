import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import isNaN from 'lodash/isNaN';
import omitBy from 'lodash/omitBy';
import { Validator } from 'modules/core/index';

const WEBSITE_NAME_MAX_LENGTH = 20;

function validatePersonalInfo(values) {
  const firstNameError = Validator.validateNotEmpty(
    values.firstName,
    'First name',
  );
  const lastNameError = Validator.validateNotEmpty(
    values.lastName,
    'Last name',
  );

  return {
    ...(firstNameError && { firstName: firstNameError }),
    ...(lastNameError && { lastName: lastNameError }),
  };
}

function validateProfessionalInfo(values) {
  const socialMediaLinks = pick(values, [
    'audibleLink',
    'websiteLink',
    'facebookLink',
    'instagramLink',
    'twitterLink',
    'linkedinLink',
    'imdbLink',
    'youtubeLink',
  ]);

  const socialErrors = reduce(
    socialMediaLinks,
    (result, link, name) => {
      if (isEmpty(link)) {
        return result;
      }

      const socialMedia = name.slice(0, -4); // instagramLink => instagram
      const linkError =
        socialMedia === 'website'
          ? Validator.validateUrl(link)
          : Validator.validateLink(link, socialMedia);

      if (linkError) {
        // eslint-disable-next-line no-param-reassign
        result[name] = linkError;
      }
      return result;
    },
    {},
  );

  const isUnionMember = get(values, 'isUnionMember');
  const unions = get(values, 'unions');

  return {
    ...socialErrors,
    ...(isUnionMember &&
      isEmpty(unions) && {
        unions: "Select which unions you're a member of",
      }),
  };
}

function validateProfessionalAward(award) {
  const yearError = isNaN(parseInt(award.year, 10))
    ? 'Enter a year value'
    : false;
  const nameError = Validator.validateNotEmpty(award.name, 'Name');

  return {
    ...(!!yearError && { year: yearError }),
    ...(!!nameError && { name: nameError }),
  };
}

function validatePastRecording(recording) {
  const nameError = Validator.validateNotEmpty(recording.name, 'Name');

  return {
    ...(!!nameError && { name: nameError }),
  };
}

function validateAdditionalWebsite(websiteInput) {
  const name = get(websiteInput, 'name');
  const url = get(websiteInput, 'url');

  const errors = {
    name:
      Validator.validateStringLength(name, WEBSITE_NAME_MAX_LENGTH, 'Name') ||
      Validator.validateNotEmpty(name, 'Name'),
    url:
      Validator.validateNotEmpty(url, 'Website URL') ||
      Validator.validateUrl(url),
  };

  return omitBy(errors, error => error === false);
}

export default {
  validatePersonalInfo,
  validateProfessionalInfo,
  validateProfessionalAward,
  validatePastRecording,
  validateAdditionalWebsite,
};
