import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactUtil } from 'modules/core';
import styles from './InlineSection.module.scss';

function getPlaceholder(placeholderText) {
  if (!placeholderText) {
    return <p className="body16 grayishBlueColor">Not defined</p>;
  }

  return <p className="body16 grayishBlueColor">{placeholderText}</p>;
}

function InlineSection({
  title,
  isEmpty,
  PlaceholderComponent,
  placeholderText,
  text,
  className,
  children,
}) {
  const placeholder = PlaceholderComponent
    ? ReactUtil.resolveComponent(PlaceholderComponent)
    : getPlaceholder(placeholderText);

  const sectionEmpty = isEmpty || (!text && !children);
  const containerClasses = classNames(styles.container, className);

  return (
    <div className={containerClasses}>
      <h3 className={styles.title}>{title}</h3>
      {sectionEmpty && placeholder}
      {text && <p className="body16">{text}</p>}
      {!sectionEmpty && children}
    </div>
  );
}

InlineSection.defaultProps = {
  placeholderText: 'No info.',
};

InlineSection.propTypes = {
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
  PlaceholderComponent: PropTypes.node,
  children: PropTypes.node,
  text: PropTypes.string,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
};

export default InlineSection;
