import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';
import { Colors } from 'modules/theme';
import { secondsToDuration } from '../../../audio/services/assets';
import classes from './Slider.module.scss';

const useStyles = makeStyles({
  root: sliderStyle => ({
    color: Colors.grayishBlue,
    paddingTop: 11,
    paddingBottom: 11,
    ...sliderStyle,
  }),
});

function Slider({
  min,
  max,
  value,
  setValue,
  sliderStyle,
  containerClassName,
  durationLabelClassName,
}) {
  const containerClasses = classNames(classes.container, containerClassName);
  const sliderClasses = useStyles(sliderStyle);
  const durationLabelClasses = classNames(
    classes.durationLabel,
    durationLabelClassName,
  );

  const [sliderValue, setSliderValue] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);

  useEffect(() => {
    if (!isSeeking) {
      setSliderValue(value);
    }
  }, [isSeeking, value]);

  function handleChange(event, newValue) {
    setIsSeeking(true);
    setSliderValue(newValue);
  }

  function handleChangeCommitted(event, newValue) {
    setIsSeeking(false);
    setValue(newValue);
  }

  return (
    <div className={containerClasses}>
      <div className={durationLabelClasses}>{secondsToDuration(value)}</div>
      <MaterialSlider
        className={sliderClasses.root}
        min={min}
        max={max}
        value={sliderValue}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        aria-labelledby="continuous-slider"
        component="div"
      />
      <div className={durationLabelClasses}>{secondsToDuration(max)}</div>
    </div>
  );
}

Slider.defaultProps = {
  min: 0,
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  setValue: PropTypes.func,
  sliderStyle: PropTypes.object,
  containerClassName: PropTypes.string,
  durationLabelClassName: PropTypes.string,
};

export default memo(Slider);
