import React from 'react';
import PropTypes from 'prop-types';

import { MaximizeIcon, CloseIcon } from '../assets';

import classes from './ChatPopupOptions.module.scss';

function ChatPopupOptions({ onMaximizeClick, onCloseClick }) {
  return (
    <div className={classes.popupIcons}>
      <MaximizeIcon className={classes.icon} onClick={onMaximizeClick} />
      <CloseIcon className={classes.icon} onClick={onCloseClick} />
    </div>
  );
}

ChatPopupOptions.propTypes = {
  onMaximizeClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default ChatPopupOptions;
