import _ from 'lodash';
import { Validator } from 'modules/core';

function validateProfileDetails(values) {
  const firstNameError = Validator.validateNotEmpty(
    values.firstName,
    'First name',
  );
  const lastNameError = Validator.validateNotEmpty(
    values.lastName,
    'Last name',
  );

  return {
    ...(firstNameError && { firstName: firstNameError }),
    ...(lastNameError && { lastName: lastNameError }),
  };
}

function validateAgencyDetails({ agencyName, agencyWebsite }) {
  const agencyNameError = Validator.validateStringLength(
    agencyName,
    250,
    'Agency name',
  );
  //const websiteLinkError = Validator.validateUrl(agencyWebsite, 'website'); TODO: Temporary solution. "expected string but recieved null"
  return {
    //...(!!websiteLinkError && { agencyWebsite: websiteLinkError }),
    ...(!!agencyNameError && { agencyName: agencyNameError }),
  };
}

function validateSocialLinks(agent) {
  const socialMediaLinks = _.pick(agent, [
    'linkedinLink',
    'instagramLink',
    'twitterLink',
    'facebookLink',
  ]);

  return _.reduce(
    socialMediaLinks,
    (result, link, name) => {
      if (_.isEmpty(link)) {
        return result;
      }
      const socialMedia = name.slice(0, -4); // instagramLink => instagram
      const linkError = Validator.validateLink(link, socialMedia);
      if (linkError) {
        // eslint-disable-next-line no-param-reassign
        result[name] = linkError;
      }
      return result;
    },
    {},
  );
}

function validateClientInvite(values) {
  const { email } = values;
  const inviteEmailError = Validator.isEmail(email);
  return {
    ...(inviteEmailError && { email: inviteEmailError }),
  };
}

export default {
  validateSocialLinks,
  validateAgencyDetails,
  validateProfileDetails,
  validateClientInvite,
};
