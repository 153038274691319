import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Textarea, ManagedForm } from 'modules/ui';
import { Assets, FileInput, MAX_FILE_SIZE, Validator } from 'modules/assets';
import AuditionAdditionalDetailsGrid from './form-grids/AuditionAdditionalDetailsGrid';

export default function AuditionAdditionalDetailsForm({
  handleRef,
  ...otherProps
}) {
  const formRef = useRef(null);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const refChanged = ref ? ref.current !== formRef.current : true;

    if (refChanged && handleRef) {
      handleRef(formRef.current);
      setRef(formRef);
    }
  }, [ref, handleRef]);

  return (
    <ManagedForm
      ref={formRef}
      title="Additional Details"
      description="Do you want to tell actor more about your project? Upload a video or other information."
      TemplateComponent={AuditionAdditionalDetailsGrid}
      submitButtonLabel="Save additional details"
      {...otherProps}
    >
      <FileInput
        type="media"
        name="additionalMediaUrl"
        label="Additional media"
        buttonLabel="Upload"
        description={`Upload image, video, document or sound file.\nMax size: ${Assets.convertBytesToMegaBytes(
          MAX_FILE_SIZE.media,
        )}MB`}
        validate={file => Validator.validate(file, 'media')}
      />
      <Textarea
        label="Additional notes"
        name="additionalNotes"
        maxChars={1000}
        placeholder="Add any additional notes here you’d like Talent to know before auditioning, such as rates or eligible unions."
      />
    </ManagedForm>
  );
}

AuditionAdditionalDetailsForm.propTypes = {
  handleRef: PropTypes.func,
};
