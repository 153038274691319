import validator from 'validator';
import get from 'lodash/get';
import isEmpty from "lodash/isEmpty";
import trim from 'lodash/trim';

const FIRST_NAME = 'managerFirstName';
const LAST_NAME = 'managerLastName';
const EMAIL = 'managerEmail';

function validateManagerAssignment(assignment){
  let error = {};

  if(isEmpty(trim(get(assignment, FIRST_NAME)))){
    error = {...error, [FIRST_NAME]: 'First name cannot be empty'}
  }
  if(isEmpty(trim(get(assignment, LAST_NAME)))){
    error = {...error, [LAST_NAME]: 'Last name cannot be empty'}
  }
  if(!validator.isEmail(trim(get(assignment, EMAIL)))){
    error = {...error, [EMAIL]: 'This is not a valid email'}
  }
  if(isEmpty(trim(get(assignment, EMAIL)))){
    error = {...error, [EMAIL]: 'Email cannot be empty'}
  }

  return error;

}

export default {validateManagerAssignment};