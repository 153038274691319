import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { getAsset } from 'modules/assets';
import { showNotification, Notification } from 'modules/notifications';
import { TalentCard } from 'modules/talents';
import { Utils } from 'modules/core';

function MyClientsCard({
  talent,
  selectedVerticals,
  selectedPreferredGenres,
  talentVisibleStatus,
  handleClientVisibility,
  addToList,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profileImage } = talent;
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    if (profileImage) {
      getAsset(profileImage).then(url => setProfileUrl(url));
    }
  }, [profileImage, setProfileUrl]);

  function handleClick() {
    const talentPath = `talent/${talent.id}`;
    history.push(talentPath);
  }

  function onSetVisibility() {
    handleClientVisibility(talent.id);
  }

  function handleAddToList(event) {
    if (event) {
      event.stopPropagation();
    }

    if (addToList) {
      addToList(talent);
    }
  }

  function handleShareProfileClick() {
    return Utils.copyToClipboard(
      `${window.location.host}/talent/${talent.id}`,
    ).then(() =>
      dispatch(
        showNotification({
          message: 'Profile link copied',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );
  }

  return (
    <TalentCard
      onClick={handleClick}
      talent={talent}
      avatar={profileUrl}
      onAddToList={handleAddToList}
      selectedVerticals={selectedVerticals}
      selectedPreferredGenres={selectedPreferredGenres}
      onShareProfile={handleShareProfileClick}
      onSetVisibility={onSetVisibility}
      talentVisibleStatus={talentVisibleStatus}
      showInviteToAudition={false}
    />
  );
}

MyClientsCard.propTypes = {
  talent: PropTypes.object.isRequired,
  selectedVerticals: PropTypes.array.isRequired,
  selectedPreferredGenres: PropTypes.array.isRequired,
  talentVisibleStatus: PropTypes.number.isRequired,
  handleClientVisibility: PropTypes.func.isRequired,
  addToList: PropTypes.func.isRequired,
};

export default withRouter(MyClientsCard);
