import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'modules/ui';
import styles from './OptionUI.module.scss';

// TODO: Is this getting too elaborate of a component? Maybe a refactor is needed
// TODO: (for the menu options: SearchSort component and AudioSamplesSection)
function OptionUI({
  hideIcon,
  isCheckbox,
  selected,
  disabled,
  label,
  menuStyle,
  containerStyle,
  onClick,
  divider,
}) {
  const containerClasses = classNames(styles.container, {
    [styles.disabled]: disabled,
    [styles['with-divider']]: divider,
    [styles['menu--selected']]: menuStyle && selected,
  });

  const iconClasses = classNames(styles.icon, {
    [styles['icon--multiselect']]: isCheckbox,
    [styles['icon--selected']]: selected,
  });

  const showIcon = !hideIcon && !menuStyle;

  return (
    <div
      type="button"
      style={containerStyle}
      className={containerClasses}
      onClick={disabled ? null : onClick}
      onKeyPress={disabled ? null : onClick}
    >
      {showIcon && (
        <div>
          <div className={iconClasses}>
            {selected && <Icon name="checked" color="#fff" size={24} />}
          </div>
        </div>
      )}
      <span>{label}</span>
    </div>
  );
}

OptionUI.propTypes = {
  isCheckbox: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  menuStyle: PropTypes.bool,
  containerStyle: PropTypes.object,
  hideIcon: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  divider: PropTypes.bool,
};

OptionUI.defaultProps = {
  isCheckbox: false,
  divider: false,
  hideIcon: false,
};

export default memo(OptionUI);
