import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../Input';
import CheckboxUI from './CheckboxUI';

export default function CheckboxInput({
  setFormValue,
  value,
  name,
  text,
  children,
  onChange,
  ...otherProps
}) {
  const [optionValue, setOptionValue] = useState(value || false);

  useEffect(() => {
    setOptionValue(value);
  }, [value]);

  function handleChange() {
    const newValue = !optionValue;
    setOptionValue(newValue);

    if (setFormValue) {
      setFormValue(name, newValue);
    }

    if (onChange) {
      onChange(newValue);
    }
  }

  const selected = !!optionValue;

  return (
    <Input {...otherProps}>
      <CheckboxUI selected={selected} onClick={handleChange} label={text} />
    </Input>
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  text: PropTypes.string,
  setFormValue: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
};

CheckboxInput.defaultProps = {
  value: false,
};
