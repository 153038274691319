import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkWrapper, Avatar } from 'modules/ui';
import { getAsset } from 'modules/assets';
import { getAgentById } from 'modules/agents';
import styles from './AssignmentListItem.module.scss';

function AssignmentListItem({ name, company, verticals, agentId }) {
  const titleClasses = classNames('body16', styles.title);

  const agent = useSelector(getAgentById(agentId));

  const profileImage = get(agent, 'profileImage');
  const agentFullName = get(agent, 'fullName');

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar) {
      getAsset(profileImage).then(url => setAvatar(url));
    }
  }, [avatar, profileImage]);

  function getProfileLink() {
    return `/agents/${agentId}`;
  }

  if (agentId)
    return (
      <div className={styles.container}>
        <LinkWrapper to={getProfileLink}>
          <div className={styles.nameContainer}>
            <Avatar
              photoUrl={avatar}
              className={styles.avatar}
              aria-haspopup="true"
            />
            <h3 className={titleClasses}>
              {agentFullName} 
              {company && <div className={styles.company}>{company}</div>}
            </h3>
          </div>
          {verticals && (
            <div className={`grayishBlueColor ${styles.verticals}`}>
              Categories: {verticals.join(', ')}
            </div>
          )}
        </LinkWrapper>
      </div>
    );

  return (
    <div className={styles.container}>
      <div>
        <h3 className={titleClasses}>
          {name}
          {company && <div className={styles.company}>{company}</div>}
        </h3>
        {verticals && (
          <div className={`grayishBlueColor ${styles.verticals}`}>
            Categories: {verticals.join(', ')}
          </div>
        )}
      </div>
    </div>
  );
}

AssignmentListItem.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  verticals: PropTypes.array,
  agentId: PropTypes.string,
};

export default memo(AssignmentListItem);
