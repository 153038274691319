import map from 'lodash/map';
import { Api } from 'modules/core';

const TYPE = 'audition-invitations';

const invitationsProducerEndpoint = (producerId, auditionId) => {
  return `producers/${producerId}/auditions/${auditionId}/audition-invitations`;
};

export function fetchProducerInvitations(producerId, auditionId) {
  if (!producerId || !auditionId) {
    return Promise.resolve(null);
  }

  const endpoint = invitationsProducerEndpoint(producerId, auditionId);
  const params = { include: 'talent' };

  return Api.find(`${endpoint}/all`, params);
}

export function fetchTalentInvitations(talentId) {
  if (!talentId) {
    return Promise.resolve(null);
  }

  const endpoint = `talents/${talentId}/audition-invitations/all`;
  const params = { include: 'audition' };

  return Api.find(endpoint, params);
}

export function postInvitation(producerId, auditionId, talentIds, message) {
  const endpoint = `${invitationsProducerEndpoint(producerId, auditionId)}`;

  const auditionInvite = {
    resourceType: TYPE,
    note: message,
  };

  const relationships = {
    ...(producerId && {
      producer: {
        data: { type: 'producers', id: producerId },
      },
    }),
    ...(auditionId && {
      audition: {
        data: { type: 'auditions', id: auditionId },
      },
    }),
  };

  const invitations = map(talentIds, id => {
    const talent = {
      type: 'talents',
      id,
    };

    const invitationRelationships = {
      ...relationships,
      ...(id && {
        talent: {
          data: talent,
        },
      }),
    };

    const params = { include: 'talent' };

    return Api.create(
      endpoint,
      auditionInvite,
      invitationRelationships,
      params,
    );
  });

  return Promise.all(invitations);
}
