import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FullScreenModal, ConfirmationDialog } from 'modules/ui';
import { LodashUtil } from 'modules/core';
import { getProducer } from 'modules/producers';
import { showNotification, Notification } from 'modules/notifications';
import { ProjectForm } from '../components';
import {
  createProject,
  updateProject,
  getError,
  getProjectById,
  loadCover,
  removeError,
  getCoverImage,
} from '../redux';

const INITIAL_VALUES = {
  lengthType: 'studioHours',
};

export default function ProjectModal({ onClose, onProjectCreate, projectId }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const producer = useSelector(getProducer);
  const project = useSelector(getProjectById(projectId));
  const error = useSelector(getError);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const initialCover = get(project, 'coverImage');
  const cover = useSelector(getCoverImage(initialCover));

  const producerId = get(producer, 'id');
  const isEditMode = !isEmpty(project);
  const title = isEditMode
    ? `Edit ${LodashUtil.truncateText(project.title, 40)} project details`
    : 'New Project';
  const submitButtonLabel = isEditMode ? 'Save changes' : 'Create project';

  useEffect(() => () => dispatch(removeError()), [dispatch]);

  useEffect(() => {
    if (initialCover && !cover) {
      dispatch(loadCover(initialCover));
    }
  }, [dispatch, initialCover, cover]);

  function handleCreateOrUpdateProject(values) {
    if (projectId) {
      return dispatch(updateProject(producerId, projectId, values)).then(
        updatedProject => {
          const projectTitle =
            get(updatedProject, 'data.title') || project.title;

          if (updatedProject) {
            dispatch(
              showNotification({
                message: `${LodashUtil.truncateText(
                  projectTitle,
                  40,
                )} project saved`,
                type: Notification.TYPES.SUCCESS,
              }),
            );
            onClose();
          }
        },
      );
    }

    return dispatch(createProject(producerId, values)).then(createdProject => {
      const newTitle = get(createdProject, 'data.title');

      if (newTitle) {
        dispatch(
          showNotification({
            message: `${newTitle} project created`,
            type: Notification.TYPES.SUCCESS,
          }),
        );
        onProjectCreate(createdProject);

        global.utag.link({
          event_type: 'create_project',
          project_category: values.vertical,
          project_name: values.title,
        });
      }
    });
  }

  function handleClose() {
    const hasChanges = formRef.current.getHasChanges();
    if (!hasChanges) {
      return onClose();
    }

    return setShowConfirmation(true);
  }

  function handleConfirmationClose() {
    setShowConfirmation(false);
  }

  function handleConfirmationAction() {
    setShowConfirmation(false);
    onClose();
  }

  return (
    <FullScreenModal open title={title} onClose={handleClose}>
      <ProjectForm
        ref={formRef}
        submitButtonLabel={submitButtonLabel}
        initialValues={project || INITIAL_VALUES}
        cover={cover}
        error={error}
        onSubmit={handleCreateOrUpdateProject}
        onCancel={handleClose}
      />
      {showConfirmation && (
        <ConfirmationDialog
          withOverlay
          title="Changes made to project details won’t be saved."
          message="Changes made to this section won’t be saved if you discard them."
          actionBtnLabel="Discard"
          cancelBtnLabel="Keep editing"
          onAction={handleConfirmationAction}
          onCancel={handleConfirmationClose}
        />
      )}
    </FullScreenModal>
  );
}

ProjectModal.propTypes = {
  onClose: PropTypes.func,
  onProjectCreate: PropTypes.func,
  projectId: PropTypes.string,
};
