import { createReducer, createAction } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import map from 'lodash/map';
import filter from 'lodash/filter';
import unionBy from 'lodash/unionBy';
import createSelector from 'selectorator';
import { resolveErrorMessage } from 'modules/errors';
import {
  postInvitation,
  fetchProducerInvitations,
  fetchTalentInvitations,
} from './api';

const INITIAL_STATE = {
  invitations: [],
  error: false,
  loading: false,
};

export const loadProducerInvitations = createThunk(
  fetchProducerInvitations,
  'producerInvitations',
  'auditions-invitations',
);

export const loadTalentInvitations = createThunk(
  fetchTalentInvitations,
  'talentInvitations',
  'auditions-invitations',
);

export const removeInviteErrorAction = createAction(
  'auditions-invitations/invitations/removeError',
);

export const inviteTalent = createThunk(
  postInvitation,
  'createInvite',
  'auditions-invitations',
);

export const reducer = createReducer(INITIAL_STATE, {
  // Producer
  [loadProducerInvitations.type.request]: state => {
    state.loading = true;
  },
  [loadProducerInvitations.type.success]: (state, action) => {
    state.invitations = action.payload.data;
    state.loading = false;
  },
  [loadProducerInvitations.type.error]: state => {
    state.loading = false;
  },
  [inviteTalent.type.success]: (state, action) => {
    const newInvitations = map(action.payload, invitation => invitation.data);

    state.invitations = unionBy(newInvitations, state.invitations, 'id');
    state.error = null;
  },
  [inviteTalent.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [removeInviteErrorAction]: state => {
    state.error = null;
  },

  // Talent
  [loadTalentInvitations.type.request]: state => {
    state.loading = true;
  },
  [loadTalentInvitations.type.success]: (state, action) => {
    const newInvitations = map(action.payload, invitation => invitation.data);

    state.loading = false;
    state.invitations = unionBy(newInvitations, state.invitations);
  },
  [loadTalentInvitations.type.error]: state => {
    state.loading = false;
  },
});

export const getInviteError = createSelector(['auditionInvitations.error']);
export const getInvitations = createSelector([
  'auditionInvitations.invitations',
]);
export const getInvitationsLoading = createSelector([
  'auditionInvitations.loading',
]);
export const getInvitationsByAuditionId = auditionId =>
  createSelector(
    [getInvitations],
    invitations =>
      filter(invitations, invitation => invitation.auditionId === auditionId),
  );
