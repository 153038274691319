import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';
import { Button, PhoneInput, TextInput } from 'modules/ui';
import Grid from '@material-ui/core/Grid';
import { Colors } from 'modules/theme';
import { Utils } from 'modules/core/services';
import classes from './ManagerInput.module.scss';

const styles = {
  label: {
    marginBottom: 28,
    display: 'inline-block',
  },
  removeManager: {
    fontSize: 11,
    marginTop: 8,
    color: Colors.secondary,
  },
};

function ManagerInput({
  managerAssignmentId,
  managerAssignment,
  onChange,
  onRemoveClick,
  label,
  error,
}) {
  function handleValueChange(event) {
    onChange(managerAssignmentId, {
      ...managerAssignment,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <div style={styles.managerInput}>
      <h3 style={styles.label}>{label}</h3>
      <Button
        small
        onClick={onRemoveClick}
        className={classes.removeManager}
        style={styles.removeManager}
        disabled={isEmpty(managerAssignment)}
      >
        {`${
          startsWith(managerAssignmentId, 'placeholder') ? 'Clear ' : 'Remove '
        } manager`}
      </Button>
      <Grid container spacing={0}>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <TextInput
              name="managerFirstName"
              value={
                managerAssignment ? managerAssignment.managerFirstName : ''
              }
              onChange={handleValueChange}
              label="First name"
              placeholder="E.g. Jane"
              error={get(error, 'managerFirstName')}
              autoComplete={Utils.PREVENT_AUTOCOMPLETE}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="managerLastName"
              value={managerAssignment ? managerAssignment.managerLastName : ''}
              onChange={handleValueChange}
              label="Last name"
              placeholder="E.g. Doe"
              error={get(error, 'managerLastName')}
              autoComplete={Utils.PREVENT_AUTOCOMPLETE}
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name="managerEmail"
            value={managerAssignment ? managerAssignment.managerEmail : ''}
            onChange={handleValueChange}
            label="Email"
            placeholder="E.g. name@email.com"
            error={get(error, 'managerEmail')}
            required
          />
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PhoneInput
              label="Phone Number"
              name="companyPhoneNumber"
              value={get(managerAssignment, 'companyPhoneNumber', '')}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label="Company Name"
              name="companyName"
              value={get(managerAssignment, 'companyName', '')}
              onChange={handleValueChange}
              error={get(error, 'companyName')}
              placeholder="e.g. Moby Voices Inc."
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

ManagerInput.propTypes = {
  managerAssignmentId: PropTypes.string.isRequired,
  managerAssignment: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
};
export default ManagerInput;
