import React, { memo, useState, useEffect, cloneElement } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { TextInput } from 'modules/ui';
import ListItemsString from './ListItemsString';

function TextListInput({
  onChange,
  onEraseItem,
  onFocus,
  disabled,
  selectionString,
  placeholder,
  value: initialValue,
  isMultiSelect,
  ...otherProps
}) {
  const [inputStyle, setInputStyle] = useState(null);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  function handleChangeList(width) {
    const paddingLeft = parseInt(width, 10) + 18;
    setInputStyle({ paddingLeft });
  }

  function handleKeyDown(event) {
    const newValue = get(event, 'target.value');
    const key = event.keyCode || event.charCode;

    if (key === 8 && newValue === '' && onEraseItem) {
      return onEraseItem();
    }
  }

  function handleChange(event) {
    if (!isMultiSelect && !isEmpty(selectionString)) {
      return;
    }
    const newValue = get(event, 'target.value');

    event.preventDefault();
    setValue(newValue);

    if (onChange) {
      onChange(event);
    }
  }

  const TextComponent = cloneElement(ListItemsString, {
    ref: null,
    selectionString,
    onChange: handleChangeList,
    disabled,
  });

  return (
    <TextInput
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      LeftTextComponent={TextComponent}
      style={inputStyle}
      placeholder={!selectionString && placeholder}
      {...otherProps}
    />
  );
}

TextListInput.propTypes = {
  ...TextInput.propTypes,
  selectionString: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};

export default memo(TextListInput);
