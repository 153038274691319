import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import { useLocationPageNames } from 'modules/core';
import { NavBar, NavBarLink } from 'modules/ui';
import {
  AccountAvatarMenu,
  AccountNavBarEnvelope,
  AGENT_PAGES,
} from 'modules/accounts';
import { getAgent } from '../redux';

function AgentNavBar({ avatar, onLogout, history }) {
  const location = useLocation();
  const currentPageName = useLocationPageNames(AGENT_PAGES.PAGES);
  const agent = useSelector(getAgent);

  const fullName = get(agent, 'fullName');
  const city = get(agent, 'city');
  const state = get(agent, 'state.name');
  const country = get(agent, 'country.name');

  function handleEditProfileClick() {
    history.push('/profile/edit');
  }

  function handleSettingsClick() {
    history.push('/settings/change-password');
  }

  return (
    <NavBar
      activePageName={currentPageName}
      rightIconComponent={<AccountNavBarEnvelope />}
      showMobile={false}
      className="pt-4"
    >
      <NavBarLink
        to="/"
        isActive={() => {
          return (
            location.pathname === '/' ||
            startsWith(location.pathname, '/clients')
          );
        }}
        id="clients-link"
      >
        My Clients
      </NavBarLink>
      <NavBarLink exact to="/auditions" id="auditions-link">
        Auditions
      </NavBarLink>
      <NavBarLink exact to="/offers" id="offers-link">
        Offers
      </NavBarLink>
      <NavBarLink exact to="/lists" id="lists-link">
        Lists
      </NavBarLink>
      <AccountNavBarEnvelope />
      <AccountAvatarMenu
        avatar={avatar}
        fullName={fullName}
        city={city}
        state={state}
        country={country}
        onLogout={onLogout}
        onEditProfile={handleEditProfileClick}
        onSettings={handleSettingsClick}
        isActive={startsWith(location.pathname, '/profile')}
        enableHoverBorder
      />
    </NavBar>
  );
}

AgentNavBar.propTypes = {
  avatar: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AgentNavBar);
