import { createAction, createReducer } from 'redux-starter-kit';
import createSelector from 'selectorator';
import { isBlob } from './services';

const INITIAL_STATE = {
  currentAudioUrl: null,
  previousAudioUrl: null,
  playlist: {},
  readyToPlay: false,
  readyToPlayBlob: false,
  audioSampleKey: '',
};

export const setCurrentAudioUrl = createAction('audio/download/set');
export const resetAudioUrls = createAction('audio/urls/reset');
export const setToPlaylist = createAction('audio/playlist/set');
export const setReadyToPlay = createAction('audio/readyToPlay/set');
export const setBlobReadyToPlay = createAction('action/readyToPlayBlob/set');
export const setAudioSampleKey = createAction('audio/setAudioSampleKey/set');

export const reducer = createReducer(INITIAL_STATE, {
  [setCurrentAudioUrl]: (state, action) => {
    state.previousAudioUrl = state.currentAudioUrl;
    state.currentAudioUrl = action.payload;
  },
  [setToPlaylist]: (state, action) => {
    const currentAudioUrl = action.payload.fileUrl;
    const localUrl = action.payload.url;

    state.playlist = { ...state.playlist, [currentAudioUrl]: localUrl };
  },
  [resetAudioUrls]: state => {
    state.previousAudioUrl = null;
    state.currentAudioUrl = null;
  },
  [setReadyToPlay]: (state, action) => {
    state.readyToPlayBlob = false;
    state.readyToPlay = action.payload;
  },
  [setBlobReadyToPlay]: (state, action) => {
    state.readyToPlay = false;
    state.readyToPlayBlob = action.payload;
  },
  [setAudioSampleKey]: (state, action) => {
    state.audioSampleKey = action.payload;
  },
});

export const getPlaylist = createSelector(['audio.playlist']);
export const getReadyToPlay = createSelector(['audio.readyToPlay']);
export const getReadyToPlayBlob = createSelector(['audio.readyToPlayBlob']);
export const getCurrentAudioUrl = createSelector(['audio.currentAudioUrl']);
export const getPreviousAudioUrl = createSelector(['audio.previousAudioUrl']);
export const getAudioSampleKey = createSelector(['audio.audioSampleKey']);

export const makeGetPlayingByUrl = url =>
  createSelector(
    [getCurrentAudioUrl],
    stateFileUrl => {
      if (!stateFileUrl) {
        return false;
      }
      if (isBlob(url)) {
        return true;
      }
      return stateFileUrl === url;
    },
  );
