import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';

function getRecordingExperienceDisplayOptions(experienceItems) {
  experienceItems[experienceItems.length - 1].label =
    experienceItems[experienceItems.length - 1].label.split('-')[0] + '+';

  return experienceItems;
}

function getDictionaryObjects(collection, dictionary) {
  return map(collection, id => {
    const dictionaryObj = find(dictionary, { id });
    return dictionaryObj;
  });
}

function getDictionaryLabel(value, options) {
  const dictionaryObj = find(options, { value });
  return get(dictionaryObj, 'label');
}

function getDictionaryLabels(collection, options) {
  return map(collection, value => {
    return getDictionaryLabel(value, options);
  });
}

function getLocationString(country, state) {
  const stateName = get(state, 'name');
  const countryName = get(country, 'name');

  if (!country) {
    return null;
  }

  if (!state) {
    return countryName;
  }

  return `${countryName}, ${stateName}`;
}

export default {
  getDictionaryObjects,
  getDictionaryLabels,
  getDictionaryLabel,
  getLocationString,
  getRecordingExperienceDisplayOptions,
};
