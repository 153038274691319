import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import {
  FormDialog,
  ManagedForm,
  Textarea,
  TextInput,
  ListSelect,
} from 'modules/ui';
import {
  Producer,
  loadSearchedProducers,
  getSearchedProducers,
  getProducersLoading,
} from 'modules/producers';
import { AccountType } from 'modules/accounts';
import { Notification, showNotification } from 'modules/notifications';
import { shareList, getListsError } from 'modules/lists';
import { Validator } from '../services';

const styles = {
  container: {
    height: 450,
  },
  title: {
    marginBottom: '10px',
  },
};

function generateSelectionString(selection) {
  return map(selection, 'label');
}

export default function ListShareDialog({
  onCancel,
  accountId,
  listId,
  accountType,
}) {
  const dispatch = useDispatch();
  const error = useSelector(getListsError);

  const initialValues = {
    sendTo: [],
  };

  const producers = useSelector(getSearchedProducers);
  const searching = useSelector(getProducersLoading);

  function dispatchSearchProducers(data) {
    if (data.searchTerm === '') return;

    dispatch(loadSearchedProducers(data));
  }

  const handleSearchProducers = useCallback(
    debounce(dispatchSearchProducers, 500, { leading: false, trailing: true }),
    [],
  );

  function handleResponse(response, values) {
    if (!response) {
      return;
    }

    dispatch(
      showNotification({
        message: `List shared with ${values.email || values.sendTo.email}`,
        type: Notification.TYPES.SUCCESS,
      }),
    );
    onCancel();
  }

  function handleSubmit(values) {
    if (AccountType.isProducer(accountType)) {
      const submitValues = { ...values, listId };
      return dispatch(shareList(accountId, submitValues, accountType)).then(
        res => handleResponse(res, values),
      );
    }
    const { sendTo, note } = values;
    const { email } = sendTo;
    const submitValues = {
      email,
      note,
      agentListId: listId,
    };

    return dispatch(shareList(accountId, submitValues, accountType)).then(res =>
      handleResponse(res, values),
    );
  }

  function handleProducerChange(searchValue) {
    const searchData = {
      searchTerm: searchValue,
    };
    return handleSearchProducers(searchData);
  }

  return (
    <FormDialog
      title="Share List"
      onClose={onCancel}
      containerStyle={styles.container}
      titleStyle={styles.title}
    >
      <ManagedForm
        error={error}
        validate={
          (AccountType.isProducer(accountType) &&
            Validator.validateListShareForm) ||
          undefined
        }
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitButtonLabel="Share"
        initialValues={initialValues}
      >
        {AccountType.isAgent(accountType) && (
          <ListSelect
            required
            options={Producer.createOptions(producers)}
            loading={searching}
            name="sendTo"
            label="Share with"
            theme="light"
            onChange={handleProducerChange}
            placeholder="Enter Content Creator's name"
            noResultsText="No Content Creator's found"
            generateSelectionString={generateSelectionString}
          />
        )}
        {AccountType.isProducer(accountType) && (
          <TextInput
            required
            label="Share with"
            name="email"
            placeholder="Enter recipient's mail"
          />
        )}
        <Textarea
          required
          label="Message"
          name="note"
          placeholder="Add a message"
        />
      </ManagedForm>
    </FormDialog>
  );
}

ListShareDialog.propTypes = {
  accountId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  listId: PropTypes.string,
  accountType: PropTypes.string,
};
