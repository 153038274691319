import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from 'modules/ui';
import { useDispatch } from 'react-redux';
import { removeTopCandidate } from 'modules/top-candidates';
import { showNotification, Notification } from 'modules/notifications';

export default function RemoveTopCandidateConfirmation({
  topCandidate,
  producer,
  project,
  role,
  onCancel,
  onRemoveTalent,
}) {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  function handleRemove() {
    setInProgress(true);
    const notification = `${topCandidate.talent.fullName} removed from Top Candidates for ${project.title}'s ${role.name} Role`;
    onRemoveTalent();
    dispatch(
      removeTopCandidate(producer.id, project.id, role.id, topCandidate.id),
    )
      .then(() =>
        dispatch(
          showNotification({
            message: notification,
            type: Notification.TYPES.SUCCESS,
          }),
        ),
      )
      .then(() => {
        setInProgress(false);
      })
      .then(() => onCancel());
  }
  const title = `Are you sure you want to remove ${topCandidate.talent.fullName} from Top Candidates?`;

  return (
    <ConfirmationDialog
      title={title}
      onAction={handleRemove}
      onCancel={onCancel}
      actionBtnLabel="Yes, I'm sure"
      cancelBtnLabel="Cancel"
      inProgress={inProgress}
      withOverlay
    />
  );
}

RemoveTopCandidateConfirmation.propTypes = {
  topCandidate: PropTypes.object.isRequired,
  producer: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemoveTalent: PropTypes.func.isRequired,
};
