import get from 'lodash/get';
import includes from 'lodash/includes';
import join from 'lodash/join';
import Assets from './assets';
import { ALLOWED_FORMATS, ALLOWED_EXTENSIONS, MAX_FILE_SIZE } from '../const';

function validateType(mimeType, resourceType) {
  const isValid = includes(ALLOWED_FORMATS[resourceType], mimeType);

  if (isValid) {
    return false;
  }

  const allowedExtensionsString = join(ALLOWED_EXTENSIONS[resourceType], ', ');
  return {
    title: 'Upload failed',
    subtitle: 'Wrong file format',
    message: `Allowed file formats: ${allowedExtensionsString}`,
  };
}

function validateSize(size, resourceType = 'default') {
  const maxSize = MAX_FILE_SIZE[resourceType];
  const maxSizeString = Assets.convertBytesToMegaBytes(maxSize);
  const isValid = size <= maxSize;

  if (isValid) {
    return false;
  }

  return {
    title: 'Upload failed',
    subtitle: 'File size too big',
    message: `Maximum allowed file size is ${maxSizeString}MB`,
  };
}

function validate(file, resourceType) {
  const size = get(file, 'size');
  const type = get(file, 'type');

  if (!size || !type) {
    return Promise.resolve();
  }

  const error =
    validateType(type, resourceType) || validateSize(size, resourceType);

  if (!error) {
    return false;
  }

  return error;
}

export default {
  validate,
};
