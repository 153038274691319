import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'modules/theme';

export default makeStyles({
  tooltip: () => ({
    zIndex: 100,
  }),
  loaderContainer: {
    display: 'flex',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
  },
  paper: ({ styles, width, showRightArrow, arrowRightPosition }) => ({
    marginTop: 12,
    width,
    background: 'white',
    overflow: 'visible',
    borderRadius: 7,
    boxShadow: '0px 2px 16px rgba(5, 21, 40, 0.15)',
    '&::before': {
      content: "''",
      display: showRightArrow ? 'block' : 'none',
      position: 'absolute',
      top: 7,
      right: arrowRightPosition || 25,
      width: 10,
      height: 10,
      background: Colors.white,
      boxShadow: '0px 2px 16px rgba(5, 21, 40, 0.15)',
      transform: 'rotate(-45deg)',
    },
    ...styles,
  }),
});
