import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import styles from './HorizontalTabLink.module.scss';

function HorizontalTabLink({
  disabled,
  active,
  light,
  className,
  ...otherProps
}) {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const classes = classNames(
    'body14',
    styles.link,
    disabled && styles['link--disabled'],
    isActive && styles['link--active'],
    isActive && light && styles['link--active--light'],
    className,
  );

  return <a className={classes} {...otherProps} />;
}

HorizontalTabLink.propTypes = {
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  className: PropTypes.string,
  ...Link.propTypes,
  active: PropTypes.bool,
  light: PropTypes.bool,
};

export default withRouter(memo(HorizontalTabLink));
