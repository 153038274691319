import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'modules/ui';
import styles from './ActionBanner.module.scss';

const contentClasses = classNames(
  'container',
  'container--1128',
  styles.content,
);

function ActionBanner({ text, buttonLabel, onButtonClick, textClassName }) {
  const textClasses = classNames('body16', styles.text, textClassName);

  return (
    <div className={styles.banner}>
      <div className={contentClasses}>
        <div className={textClasses}>{text}</div>
        {onButtonClick && (
          <Button onClick={onButtonClick} className={styles.button}>
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  );
}

ActionBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default memo(ActionBanner);
