import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useWindowSize } from 'modules/core';
import { loadAsset } from 'modules/assets';
import { PageHeader, AvatarDescription } from 'modules/ui';
import { getOfferById } from '../redux';
import OfferActionButtons from './OfferActionButtons';
import classes from './OfferHeader.module.scss';

function OfferHeader({
  offerId,
  isPreview,
  onRespondOpenClick,
  onDeclineOpenClick,
  hasAgent,
}) {
  const dispatch = useDispatch();
  const offer = useSelector(getOfferById(offerId));
  const projectRole = get(offer, 'projectRole');
  const roleName = get(projectRole, 'name');
  const { desktopWidth } = useWindowSize();

  const [avatar, setAvatar] = useState(null);

  const producer = get(offer, 'producer');
  const profileImage = get(producer, 'profileImage');

  const history = useHistory();
  function handleProducerProfileClick() {
    history.push(`/producers/${producer.id}`);
  }

  useEffect(() => {
    dispatch(loadAsset(profileImage, true)).then(asset => setAvatar(asset));
  }, [dispatch, profileImage]);

  const HeaderTitleComponent = (
    <div className={classes.titleContainer}>
      <h1>{roleName}</h1>
      <AvatarDescription
        verb="create"
        avatar={avatar}
        personName={producer.fullName}
        onClick={handleProducerProfileClick}
        className={classes.titleDescription}
      />
    </div>
  );

  return (
    <PageHeader
      breadcrumbPaths={
        desktopWidth
          ? !isPreview && [
              { title: 'Offers', route: '/offers' },
              { title: `${roleName}`, route: `/offers/${offerId}` },
            ]
          : false
      }
      LeftComponent={desktopWidth && HeaderTitleComponent}
      className={classes.container}
    >
      {!isPreview && (
        <div className={classes.headerContainer}>
          {!desktopWidth && HeaderTitleComponent}
          <div className={classes.actionContainer}>
            <OfferActionButtons
              offer={offer}
              talentHasAgent={hasAgent}
              onDeclineOpenClick={onDeclineOpenClick}
              onRespondOpenClick={onRespondOpenClick}
            />
          </div>
        </div>
      )}
    </PageHeader>
  );
}

OfferHeader.propTypes = {
  offerId: PropTypes.string.isRequired,
  onRespondOpenClick: PropTypes.func,
  onDeclineOpenClick: PropTypes.func,
  isPreview: PropTypes.bool,
  hasAgent: PropTypes.bool,
};
export default OfferHeader;
