import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import TableRow from '@material-ui/core/TableRow';
import {
  Table,
  TableCell,
  Section,
  Button,
  EmptyPlaceholder,
} from 'modules/ui';
import { getProjectById } from 'modules/projects';
import { ProjectRoleRow } from '../fragments';

const styles = {
  newRoleButton: {
    height: 32,
    width: 115,
  },
};

export default function ProjectRolesTable({
  projectRoles,
  onClick,
  loading,
  onNewClick,
  onEditClick,
  onDeleteClick,
  onAuditionClick,
}) {
  const params = useParams();
  const projectId = get(params, 'projectId');
  const project = useSelector(getProjectById(projectId));
  const finished = get(project, 'finished');

  const placeholder = (
    <EmptyPlaceholder
      className="m-24"
      title="You have not created any roles yet."
      description="Add your first Role to audition, select, or search for Talent."
      buttonLabel="Create role"
      onButtonClick={onNewClick}
      buttonDisabled={finished}
    />
  );

  const hasRoles = !isEmpty(projectRoles);

  return (
    <Section
      loading={loading}
      isEmpty={!hasRoles}
      PlaceholderComponent={placeholder}
      title="Roles"
      ActionComponent={
        hasRoles && (
          <Button
            disabled={finished}
            onClick={onNewClick}
            primary
            small
            style={styles.newRoleButton}
          >
            New role
          </Button>
        )
      }
    >
      <Table
        className="m-0"
        rows={projectRoles}
        renderHeader={() => (
          <TableRow>
            <TableCell>Role Name</TableCell>
            <TableCell>Auditions</TableCell>
            <TableCell>Top Candidates</TableCell>
            <TableCell>Talent Hired</TableCell>
            <TableCell align="right" />
          </TableRow>
        )}
        renderRow={role => (
          <ProjectRoleRow
            key={role.id}
            role={role}
            onClick={onClick}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            onAuditionClick={onAuditionClick}
            projectFinished={finished}
          />
        )}
      />
    </Section>
  );
}

ProjectRolesTable.propTypes = {
  projectRoles: PropTypes.array,
  onClick: PropTypes.func,
  onNewClick: PropTypes.func,
  loading: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAuditionClick: PropTypes.func,
};
