import { useState, useEffect } from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import has from 'lodash/has';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { FormUtil } from './services';

export default (
  onSubmit,
  onChangeValues,
  validate = noop,
  initialValues = {},
  expectedKeys = [],
  forceSubmit = false,
  removeErrorsKeys,
) => {
  const [touched, setTouched] = useState(false);
  const [savedValues, setSavedValues] = useState(initialValues);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [inProgress, setInProgress] = useState(false);

  function handleRemoveErrors() {
    if (!isEmpty(errors)) {
      const newErrors = omit(errors, removeErrorsKeys);
      setErrors(newErrors);
    }
  }

  useEffect(() => {
    if (!isEmpty(removeErrorsKeys) && !isEmpty(errors)) {
      handleRemoveErrors();
    }
  }, [removeErrorsKeys]);

  function resetForm() {
    setSavedValues(initialValues);
    setValues(initialValues);
    setTouched(false);
  }

  function getHasChanges() {
    return FormUtil.hasUnsavedChanges(values, savedValues);
  }

  function handleValuesChange(newValues, touch) {
    if (touch) {
      setTouched(true);
    }

    if (touch && onChangeValues) {
      onChangeValues(newValues);
    }
  }

  function setValue(name, value, touch = true) {
    const newValues = { ...values, [name]: value };
    setValues(newValues);

    handleValuesChange(newValues, touch);

    if (has(errors, name)) {
      const newErrors = omit(errors, name);
      setErrors(newErrors);
    }
  }

  function setMultipleValues(updatedValues, touch = true) {
    const newValues = { ...values, ...updatedValues };
    setValues(newValues);

    handleValuesChange(newValues, touch);

    const newErrors = omit(errors, Object.keys(updatedValues));
    setErrors(newErrors);
  }

  function setDefaultValue(name, value) {
    if (!touched && !values[name]) {
      setValue(name, value, false);
    }
  }

  function validateForm(validateValues) {
    const newErrors = validate(validateValues);
    const hasErrors = !isEmpty(newErrors);
    let firstElementName;
    let firstErrorElement;

    if (hasErrors) {
      firstElementName = Object.keys(newErrors)[0];
      firstErrorElement = document.querySelector(
        `input[name=${firstElementName}], select[name=${firstElementName}]`,
      );

      setErrors(newErrors);

      if (firstErrorElement) {
        firstErrorElement.focus();
        firstErrorElement.parentElement.scrollIntoView({
          block: 'center',
        });
      }
      return newErrors;
    }

    return null;
  }

  function handleSubmit(event, wizardValues, wizardSavedValues) {
    if (event) {
      event.preventDefault();
    }

    const currentValues = wizardValues || values;
    const currentSavedValues = wizardSavedValues || savedValues;
    const formErrors = validateForm(currentValues);

    if (formErrors) {
      return Promise.resolve({ error: true, errors: formErrors });
    }

    const diffValues = FormUtil.calculateDifferenceObject(
      currentValues,
      currentSavedValues,
    );

    if (isEmpty(diffValues) && !forceSubmit) {
      setInProgress(false);
      return Promise.resolve(currentSavedValues);
    }

    const filteredValues = pick(values, expectedKeys);
    const resolvedValues = { ...filteredValues, ...diffValues };

    setInProgress(true);

    return onSubmit(resolvedValues)
      .then(response => {
        if (!response || get(response, 'error')) {
          setSavedValues(currentSavedValues);
        } else {
          setSavedValues({ ...currentSavedValues, ...resolvedValues });
        }

        setInProgress(false);
        setTouched(false);

        return response;
      })
      .catch(() => setInProgress(false));
  }

  return {
    values,
    errors,
    resetForm,
    handleSubmit,
    validateForm,
    inProgress,
    setValue,
    setDefaultValue,
    touched,
    getHasChanges,
    setMultipleValues,
    hasErrors: !isEmpty(errors),
  };
};
