import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SwitchInput, ConfirmationDialog } from 'modules/ui';

export default function AuditionTypeSwitchInput({
  value,
  items,
  name,
  onChange,
  auditionName,
  setFormValue,
  withConfirm,
  ...otherProps
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [intended, setIntended] = useState(null);

  const handleClick = newValue => {
    setIntended(newValue);

    if (withConfirm) {
      setShowConfirm(true);
    }
  };

  function handleConfirm() {
    setShowConfirm(false);

    if (onChange) {
      onChange(intended);
    }

    if (setFormValue) {
      setFormValue(name, intended);
    }
  }

  function handleCancel() {
    setShowConfirm(false);
  }

  const message =
    value !== 'private'
      ? `By converting this audition to a closed audition talents who are not explicitly invited by you will not be able to find and see this audition. Are you sure you want to convert audition ${auditionName} to closed audition?`
      : `By converting this audition to a public audition all talents who match audition criteria will be notified and all talents who are using Ahab will be able to find and apply to this audition. Are you sure you want to convert audition ${auditionName} to public audition?`;

  const actionBtnLabel =
    value !== 'private' ? `Convert to Private` : `Convert to Open`;

  return (
    <>
      <SwitchInput
        name={name}
        value={value}
        items={items}
        onChange={handleClick}
        setFormValue={setFormValue}
        withConfirm={withConfirm}
        {...otherProps}
      />
      {showConfirm && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure?"
          message={message}
          actionBtnLabel={actionBtnLabel}
          cancelBtnLabel="Cancel"
          onAction={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </>
  );
}

AuditionTypeSwitchInput.propTypes = {
  value: PropTypes.string,
  auditionName: PropTypes.string,
  setFormValue: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  withConfirm: PropTypes.bool,
};
