import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDisablePreventClose } from 'modules/core';

const usePreventClose = loading => {
  const isDisabled = useSelector(getDisablePreventClose);
  function activatePreventClose() {
    window.onbeforeunload = () => '';
  }

  function deactivatePreventClose() {
    window.onbeforeunload = () => {};
  }

  if (isDisabled) deactivatePreventClose();

  useEffect(() => {
    if (!isDisabled) {
      if (loading) activatePreventClose();
      else deactivatePreventClose();
    }
    return deactivatePreventClose;
  }, [isDisabled, loading]);
};

export default usePreventClose;
