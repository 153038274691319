import React, { useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Page, FormWizard, SectionLoader } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getProducer } from 'modules/producers';
import { removeNDA } from 'modules/ndas';
import { getAuditionById } from 'modules/auditions';
import {
  loadAudition,
  createOrUpdateAudition,
  getAuditionLoading,
  getAuditionError,
} from '../redux';
import {
  AuditionDetailsForm,
  AuditionVoiceSkillsForm,
  AuditionRecordingDetailsForm,
  AuditionAdditionalDetailsForm,
  AuditionScriptForm,
} from '../components';
import auditions from 'modules/auditions/services/auditions';

const styles = {
  loader: {
    margin: '100px 0',
    width: '100%',
    maxWidth: '721px',
    border: 'none !important',
  },
};

export default function AuditionCreatePage({
  onSubmit,
  onChange,
  auditionId,
  role,
}) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const audition = useSelector(getAuditionById(auditionId));
  const auditionLoading = useSelector(getAuditionLoading);
  const error = useSelector(getAuditionError);

  const isDraft = get(audition, 'isDraft', true);

  useEffect(() => {
    if (!audition && !!producer && !!auditionId) {
      dispatch(loadAudition(producer.id, auditionId));
    }
  }, [audition, producer, auditionId, dispatch]);

  function handleValueChange(values) {
    if (onChange) {
      onChange(values);
    }
  }

  function handleFinalSubmit(result) {
    if (!!result) {
      const successMessage = auditionId
        ? 'Audition successfully updated.'
        : 'Audition successfully created.';

      dispatch(
        showNotification({
          message: successMessage,
          type: Notification.TYPES.SUCCESS,
        }),
      );
    }
    if (onSubmit) {
      onSubmit(result);
    }
  }

  function handleSaveAudition(values) {
    const roleId = get(audition, 'projectRole.id') || get(role, 'id');

    const oldNDA = get(audition, 'nonDisclosureAgreement');
    const newNDA = get(values, 'nonDisclosureAgreement');
    const NDAdeleted = !!oldNDA && !newNDA;

    const updateCall = dispatch(
      createOrUpdateAudition(producer.id, roleId, auditionId, values),
    );
    const NDAdelete = NDAdeleted
      ? dispatch(removeNDA(auditionId))
      : Promise.resolve();

    return Promise.all([NDAdelete, updateCall]).then(responses => {
      const newAuditionId = get(responses, '1.data.id');

      if (newAuditionId) {
        dispatch(
          showNotification({
            message: 'Audition successfully saved.',
            type: Notification.TYPES.SUCCESS,
          }),
        );
      }

      if (onSubmit) {
        onSubmit(newAuditionId);
      }

      return newAuditionId;
    });
  }

  if (!!auditionId && !!auditionLoading) {
    return <SectionLoader style={styles.loader} />;
  }

  const initialValues = {
    type: 'openCall',
    name: get(role, 'name'),
  };

  function resolveInitialValues() {
    if (audition) {
      return {
        ...audition,
        requiredVoiceAgeRanges: audition.requiredVoiceAgeRangesIds,
      };
    }
    return initialValues;
  }

  return (
    <Page showFooter={false}>
      <FormWizard
        initialValues={resolveInitialValues()}
        onSubmit={handleSaveAudition}
        onFinalSubmit={handleFinalSubmit}
        onValueChange={handleValueChange}
        finalButtonLabel={isDraft ? 'Save and run' : 'Save'}
        previousButtonLabel="Save as draft"
        hasPreviousButton={isDraft}
        error={error}
      >
        <AuditionDetailsForm label="Audition Details" />
        <AuditionScriptForm label="Script Details" />
        <AuditionVoiceSkillsForm label="Voice Skills" />
        <AuditionRecordingDetailsForm label="Recording Details" />
        <AuditionAdditionalDetailsForm label="Additional Details" />
      </FormWizard>
    </Page>
  );
}

AuditionCreatePage.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  auditionId: PropTypes.string,
  role: PropTypes.object,
};
