import { createAction, createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import unionBy from 'lodash/unionBy';
import find from 'lodash/find';
import { resolveErrorMessage } from 'modules/errors';
import {
  getApplications,
  getApplicationsByAuditionId,
  setAuditionApplicationUnreadStatus,
  loadApplicationForTalent,
  loadAuditionApplications,
} from 'modules/audition-applications';
import { openChatPopup, closeChatPopup, ChatType } from 'modules/chat';
import { createAudioClip } from '../audio-clips';
import {
  postAuditionMessage,
  fetchAllAuditionMessages,
  fetchApplicationMessages,
  fetchUnreadMessagesCount,
} from './api';
import {
  auditionApplicationChatFilter,
  auditionApplicationChatId,
} from './services';

const INITIAL_STATE = {
  messages: [],
  unreadMessagesCount: 0,
  loading: false,
  submitError: null,
  submissionsSort: '-createdAt',
};

export const createAuditionMessage = createThunk(
  postAuditionMessage,
  'create',
  'auditionMessages',
);

export const loadAllAuditionMessages = createThunk(
  fetchAllAuditionMessages,
  'loadMessages',
  'auditionMessages',
);

export const loadApplicationMessages = createThunk(
  fetchApplicationMessages,
  'loadApplicationMessages',
  'auditionMessages',
);

export const loadUnreadMessagesCount = createThunk(
  fetchUnreadMessagesCount,
  'loadUnreadMessagesCount',
  'auditionMessages',
);

export const setSubmissionsSort = createAction(
  'auditionMessages/sortSubmissions/set',
);

export const markAuditionApplicationAsRead = (
  auditionId,
  applicationId,
  talentId,
) => dispatch =>
  dispatch(
    setAuditionApplicationUnreadStatus(auditionId, applicationId, null),
  ).then(() =>
    Promise.all([
      dispatch(loadApplicationForTalent(auditionId, talentId)),
      dispatch(loadUnreadMessagesCount()),
    ]),
  );

export const loadAuditionApplicationMessaging = (
  accountId,
  auditionId,
  talentId,
) => dispatch =>
  dispatch(loadApplicationForTalent(auditionId, talentId)).then(application => {
    const applicationId = get(application, 'data.id');
    const unreadMessageAccountId = get(
      application,
      'data.unreadMessageAccountId',
    );

    return Promise.all([
      dispatch(loadApplicationMessages(auditionId, applicationId)),
      unreadMessageAccountId === accountId &&
        dispatch(
          markAuditionApplicationAsRead(auditionId, applicationId, talentId),
        ),
    ]);
  });

export const refreshAuditionMessagingWindow = (accountId, chatWindow) => {
  const { auditionId, talentId } = chatWindow;
  return loadAuditionApplicationMessaging(accountId, auditionId, talentId);
};

export function openAuditionApplicationChatPopup(auditionId, talentId) {
  return openChatPopup(
    auditionApplicationChatId(auditionId, talentId),
    ChatType.AUDITION,
    { auditionId, talentId },
    auditionApplicationChatFilter(auditionId, talentId),
  );
}

export function closeAuditionApplicationChatPopup(auditionId, talentId) {
  return closeChatPopup(auditionApplicationChatFilter(auditionId, talentId));
}

export const reducer = createReducer(INITIAL_STATE, {
  [createAuditionMessage.type.success]: (state, action) => {
    const newMessage = action.payload.data;

    state.messages = [...state.messages, newMessage];
    state.submitError = false;
  },
  [createAuditionMessage.type.error]: (state, action) => {
    state.submitError = resolveErrorMessage(action.error);
  },

  [loadAllAuditionMessages.type.request]: state => {
    state.loading = true;
  },
  [loadAllAuditionMessages.type.success]: (state, action) => {
    state.loading = false;

    if (action.payload === null) {
      return;
    }
    const auditionMessages = map(
      action.payload,
      application => application.data,
    );

    state.messages = unionBy(flatten(auditionMessages), state.messages, 'id');
  },
  [loadAllAuditionMessages.type.error]: state => {
    state.loading = false;
  },
  [loadApplicationMessages.type.success]: (state, action) => {
    const newMessages = action.payload.data;

    state.messages = unionBy(newMessages, state.messages, 'id');
  },
  [loadUnreadMessagesCount.type.success]: (state, action) => {
    state.unreadMessagesCount = get(
      action,
      'payload.data.unreadMessageApplicationCount',
    );
  },
  [setSubmissionsSort]: (state, action) => {
    state.submissionsSort = action.payload;
  },
  [createAudioClip.type.success]: (state, action) => {
    const auditionMessageId = get(action, 'payload.data.auditionMessageId');
    const messageAudioClip = get(action, 'payload.data');

    const messageIndex = findIndex(
      state.messages,
      msg => msg.id === auditionMessageId,
    );

    if (messageIndex !== -1) {
      state.messages[messageIndex].audioClip = { ...messageAudioClip };
    }
  },
  [loadAuditionApplications.type.success]: (state, action) => {
    const applications = action.payload.data;

    applications.forEach(application => {
      application.auditionMessages.forEach(auditionMessage => {
        if (auditionMessage.authorAccount.talentId) {
          auditionMessage.authorAccount.talent = application.talent;
        }
      });

      state.messages = unionBy(
        application.auditionMessages,
        state.messages,
        'id',
      );
    });
  },
});

export const getSubmitError = createSelector(['auditionMessages.submitError']);
export const getMessages = createSelector(['auditionMessages.messages']);
export const getUnreadMessagesCount = createSelector([
  'auditionMessages.unreadMessagesCount',
]);
export const getMessagesLoading = createSelector(['auditionMessages.loading']);
export const getSubmissions = createSelector(
  [getMessages],
  stateMessages =>
    filter(stateMessages, message => !!get(message, ['audioClip'])),
);
export const getSubmissionsSort = createSelector([
  'auditionMessages.submissionsSort',
]);

export const getExchangedMessages = (talentId, producerId, auditionId) =>
  createSelector(
    [getMessages, getApplications],
    (stateMessages, stateApplications) => {
      return filter(stateMessages, message => {
        if (!message || !message.authorAccount) {
          return null;
        }

        const application = find(
          stateApplications,
          app => app.id === message.auditionApplicationId,
        );

        if (!application) {
          return (
            message.authorAccount.talentId === talentId ||
            message.authorAccount.producerId === producerId
          );
        }

        return (
          message.auditionApplication.auditionId === auditionId &&
          application.talentId === talentId &&
          (message.authorAccount.talentId === talentId ||
            message.authorAccount.producerId === producerId)
        );
      });
    },
  );

export const getMessagesByApplicationId = applicationId =>
  createSelector(
    [getMessages],
    messages => {
      return filter(
        messages,
        message => message.auditionApplicationId === applicationId,
      );
    },
  );

export const getSubmissionsByAuditionId = auditionId =>
  createSelector(
    [getSubmissions, getApplicationsByAuditionId(auditionId)],
    (stateSubmissions, auditionApplications) => {
      const submissions = map(auditionApplications, auditionApplication =>
        filter(
          stateSubmissions,
          submission =>
            submission.auditionApplicationId === auditionApplication.id,
        ),
      );

      return flatten(submissions);
    },
  );
