import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Drawer } from 'modules/ui';
import { AuditionMessagingDropdownMenu } from 'modules/audition-messages';
import { GlobalMessagingDropdownMenu } from 'modules/global-messages-threads';
import { ChatDropdownTab, MessagingHorizontalMenu } from 'modules/chat';

import classes from './MessagingDrawer.module.scss';

function MessagingDrawer({ onClose, drawerOpen }) {
  const [activeTab, setActiveTab] = useState(ChatDropdownTab.GLOBAL);

  function handleTabChange(tabIndex) {
    setActiveTab(tabIndex);
  }

  function resolveTabComponent() {
    if (activeTab === ChatDropdownTab.GLOBAL)
      return (
        <GlobalMessagingDropdownMenu
          closeOnCardOpen={false}
          onClose={onClose}
          isResponsiveDrawer
        />
      );
    return (
      <AuditionMessagingDropdownMenu
        closeOnCardOpen={false}
        onClose={onClose}
        isResponsiveDrawer
      />
    );
  }

  return (
    <Drawer
      open={drawerOpen}
      onClose={onClose}
      paperClassName={classes.drawerContent}
      containerClassName={classes.drawerContainer}
      drawerTitle="Messaging"
    >
      <MessagingHorizontalMenu
        containerClassName={classes.tabs}
        tabClassName={classes.tab}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
      {resolveTabComponent()}
    </Drawer>
  );
}

MessagingDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  drawerOpen: PropTypes.isRequired,
};

export default MessagingDrawer;
