import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import styles from './TabLink.module.scss';

function TabLink({ disabled, active, ...otherProps }) {
  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const classes = classNames(
    'body14',
    styles.tabLink,
    disabled && styles['tabLink--disabled'],
    isActive && styles['tabLink--active'],
  );

  return <a className={classes} {...otherProps} />;
}

TabLink.propTypes = {
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  className: PropTypes.string,
  ...Link.propTypes,
  isActive: PropTypes.bool,
};

export default withRouter(memo(TabLink));
