import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeGetPublisherById,
  getPublishers,
  loadPublishers,
} from 'modules/publishers';
import get from 'lodash/get';
import { PastRecordingInput } from '../components';

function PastRecordingManagedInput({
  name,
  value,
  setFormValue,
  onChange,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const [recording, setRecording] = useState(value);
  const publishers = useSelector(getPublishers);
  const getPublisherById = useSelector(makeGetPublisherById);

  useEffect(() => {
    dispatch(loadPublishers());
  }, [dispatch]);

  useEffect(() => {
    setRecording(value);
  }, [value]);

  const handlePublisherChange = event => {
    const id = event.target.value;
    const selectedPublisher = getPublisherById(id);

    const newRecording = {
      name: null,
      publisher: selectedPublisher,
    };

    setRecording(newRecording);

    if (onChange) {
      onChange(newRecording);
    }
  };

  const handleNameChange = event => {
    const recordingName = event.target.value;
    const newRecording = {
      ...recording,
      name: recordingName,
    };

    setRecording(newRecording);

    if (onChange) {
      onChange(newRecording);
    }
  };

  const publisherId = get(recording, 'publisher.id', null);
  const recordingWithPublisherName = {
    ...recording,
    publisher: getPublisherById(publisherId),
  };

  return (
    <PastRecordingInput
      value={recordingWithPublisherName}
      publishers={publishers}
      onChangePublisher={handlePublisherChange}
      onChangeRecording={handleNameChange}
      {...otherProps}
    />
  );
}

PastRecordingManagedInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  setFormValue: PropTypes.func,
  onChange: PropTypes.func,
};

export default memo(PastRecordingManagedInput);
