import Loader from 'react-loader-spinner';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import styles from './SectionLoader.module.scss';

const customStyles = (style, height) => {
  return {
    loader: {
      ...style,
      display: 'flex',
      justifyContent: 'center',
      minHeight: height,
      alignItems: 'center',
    },
  };
};

export default function SectionLoader({ height, style, className }) {
  const containerClasses = classNames(styles.container, className);

  return (
    <div
      style={customStyles(style, height).loader}
      className={containerClasses}
    >
      <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
    </div>
  );
}

SectionLoader.propTypes = {
  style: PropTypes.object,
  height: PropTypes.number,
  className: PropTypes.string,
};
