import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtil } from 'modules/core/services';
import styles from './Label.module.scss';

function Label({
  error,
  label,
  disabled,
  className,
  RightLabelComponent,
  required,
  optional,
}) {
  if (!label) {
    return null;
  }

  const labelClasses = classNames(
    'label',
    styles.label,
    error && styles['label--error'],
    disabled && styles['label--disabled'],
    className,
  );

  return (
    <span className={styles.container}>
      {label && (
        <div className={labelClasses}>
          {label}
          {required && <span className={styles.required}> (required)</span>}
          {optional && <span className={styles.optional}> (optional)</span>}
        </div>
      )}
      {ReactUtil.resolveComponent(RightLabelComponent)}
    </span>
  );
}

Label.propTypes = {
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  RightLabelComponent: PropTypes.node,
  optional: PropTypes.bool,
};

export default memo(Label);
