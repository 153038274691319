import get from 'lodash/get';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import { ORDERS } from 'modules/ui/components/Table/const';
import Audition from './audition';

function applySortOptions(auditions, sortOption, sortOrder) {
  if (!sortOption) {
    return auditions;
  }

  return orderBy(
    auditions,
    [audition => audition[sortOption].toLowerCase()],
    sortOrder,
  );
}

function orderApplicationsAndSubmissions(rows, sortOption, sortOrder) {
  if (sortOption === 'talentName') {
    return orderBy(
      rows,
      [
        row => {
          const isApplication = Array.isArray(row);
          const talentName = isApplication
            ? row[0].authorAccount.talent.fullName
            : row.fullName;

          return talentName.toLowerCase();
        },
      ],
      sortOrder,
    );
  }

  const [applications, invitations] = partition(rows, Array.isArray);
  const sortedApplications = orderBy(
    applications,
    [
      application =>
        sortOrder === ORDERS.DESC
          ? application[size(application) - 1].createdAt
          : application[0].createdAt,
    ],
    sortOrder,
  );
  const sortedApplicationsWithSortedSubmissions = sortedApplications.map(
    unsortedSubmissions => {
      if (unsortedSubmissions.size === 1) {
        return unsortedSubmissions;
      }

      return orderBy(unsortedSubmissions, 'createdAt', sortOrder);
    },
  );

  return [...sortedApplicationsWithSortedSubmissions, ...invitations];
}

function hasActiveOrInactiveAudition(auditions) {
  return !isEmpty(
    auditions
      .map(audition => Audition.getAuditionStatus(audition))
      .filter(
        auditionStatus =>
          auditionStatus === Audition.STATUSES.ACTIVE ||
          auditionStatus === Audition.STATUSES.INACTIVE,
      ),
  );
}

function filterByProjectId(auditions, projectId) {
  return filter(
    auditions,
    audition => get(Audition.getAuditionProject(audition), 'id') === projectId,
  );
}

function filterByActiveStatus(auditions) {
  return filter(auditions, audition => Audition.isAuditionActive(audition));
}

export default {
  filterByProjectId,
  filterByActiveStatus,
  applySortOptions,
  orderApplicationsAndSubmissions,
  hasActiveOrInactiveAudition,
};
