export const ACCOUNT_TYPES = {
  PRODUCER: 'producer',
  TALENT: 'talent',
  AGENT: 'agent',
};

export const ACCOUNT_LABELS = {
  producer: 'Content Creator',
  talent: 'Talent',
  agent: 'Agent',
};

export const AGENT_PAGES = {
  HOME_PAGE_PATHNAME: '/clients',
  PAGES: {
    '/': 'My Clients',
    '/clients': 'My Clients',
    '/auditions': 'Auditions',
    '/offers': 'Offers',
    '/lists': 'Lists',
    '/profile': 'Profile',
    '/profile/edit': 'Edit Profile',
    '/settings': 'Settings',
    '/talent': 'Talent Profile',
    '/producer': 'Producer Profile',
  },
};

export const AGENT_HOME_PAGE_URLS = ['/', '/clients'];

export const PRODUCER_PAGES = {
  HOME_PAGE_PATHNAME: '/projects',
  PAGES: {
    '/': 'Projects',
    '/projects': 'Projects',
    '/search': 'Talent Search',
    '/lists': 'Lists',
    '/profile': 'Profile',
    '/profile/edit': 'Edit Profile',
    '/settings': 'Settings',
    '/agents': 'Agent Profile',
    '/talent': 'Talent Profile',
  },
};

export const TALENT_PAGES = {
  HOME_PAGE_PATHNAME: '/auditions',
  PAGES: {
    '/': 'Auditions',
    '/auditions': 'Auditions',
    '/submissions': 'My Submissions',
    '/offers': 'Offers',
    '/profile': 'Profile',
    '/profile/edit': 'Edit Profile',
    '/settings': 'Settings',
    '/agents': 'Agent Profile',
    '/producer': 'Producer Profile',
  },
};
