import { createAction, createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import find from 'lodash/find';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import createSelector from 'selectorator';
import { Mapper } from 'modules/core';
import Utils from 'modules/core/services/utils';
import { resolveErrorMessage } from 'modules/errors/services';
import {
  postList,
  fetchList,
  fetchLists,
  patchList,
  deleteList,
  postShareList,
  addTalentToList as addTalent,
  removeTalentFromList as removeTalent,
} from './api';

const INITIAL_STATE = {
  loading: false,
  sort: '-createdAt',
  lists: [],
  addTalentInProgress: false,
  filterData: null,
  error: null,
};
export const setSort = createAction('lists/sort/set');

export const loadList = createThunk(fetchList, 'getList', 'lists');
export const loadLists = createThunk(fetchLists, 'getLists', 'lists');
export const createList = createThunk(postList, 'create', 'lists');
export const updateList = createThunk(patchList, 'update', 'lists');
export const removeList = createThunk(deleteList, 'remove', 'lists');
export const addTalentToList = createThunk(addTalent, 'add', 'lists');
export const removeTalentFromList = createThunk(
  removeTalent,
  'removeTalent',
  'lists',
);
export const shareList = createThunk(postShareList, 'shareList', 'lists');
export const setListsFilterData = createAction('lists/setFilterData');
export const clearListsFilterData = createAction('lists/clearFilterData');

export const reducer = createReducer(INITIAL_STATE, {
  [loadList.type.request]: state => {
    state.loading = true;
  },
  [loadList.type.error]: (state, action) => {
    state.loading = false;
    state.error = resolveErrorMessage(action.error);
  },
  [loadList.type.success]: (state, action) => {
    state.loading = false;
    state.lists = Mapper.addOrReplace(state.lists, action.payload.data);
    state.error = null;
  },
  [loadLists.type.request]: state => {
    state.loading = true;
  },
  [loadLists.type.success]: (state, action) => {
    state.loading = false;
    state.lists = Mapper.addOrReplaceArray(state.lists, action.payload.data);
  },
  [loadLists.type.error]: (state, action) => {
    state.loading = false;
  },

  [createList.type.request]: state => {
    state.loading = true;
  },
  [createList.type.success]: (state, action) => {
    state.loading = false;
    state.lists = [...state.lists, action.payload.data];
  },
  [createList.type.error]: state => {
    state.loading = false;
  },
  [updateList.type.request]: state => {
    state.loading = true;
  },
  [updateList.type.success]: (state, action) => {
    state.loading = false;
    state.lists = state.lists.map(list => {
      return list.id !== action.payload.data.id ? list : action.payload.data;
    });
  },
  [updateList.type.error]: state => {
    state.loading = false;
  },
  [removeList.type.success]: (state, action) => {
    state.loading = false;
    state.lists = state.lists.filter(list => list.id !== action.request[1]);
  },
  [removeList.type.error]: state => {
    state.loading = false;
  },
  [addTalentToList.type.request]: state => {
    state.addTalentInProgress = true;
  },
  [addTalentToList.type.success]: (state, action) => {
    const listId = action.request[1];
    const talentId = action.request[2];

    state.addTalentInProgress = false;

    state.lists = state.lists.map(list => {
      if (list.id !== listId) {
        return list;
      }

      list.talentsIds.push(talentId);

      return list;
    });
  },
  [removeTalentFromList.type.success]: (state, action) => {
    const listId = action.request[1];
    const talentId = action.request[2];

    state.lists = state.lists.map(list => {
      if (list.id !== listId) {
        return list;
      }

      remove(list.talentsIds, id => id === talentId);

      return list;
    });
  },
  [setSort]: (state, action) => {
    state.sort = action.payload;
  },
  [setListsFilterData]: (state, action) => {
    state.filterData = action.payload;
  },
  [clearListsFilterData]: state => {
    state.filterData = null;
  },
  [shareList.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [shareList.type.success]: state => {
    state.error = null;
  },
});

export const getLists = createSelector(['lists.lists']);
export const getListsError = createSelector(['lists.error']);
export const getListsLoading = createSelector(['lists.loading']);

export const getListsByAccountId = Utils.memoize((accountId, accountType) =>
  createSelector(
    [getLists],
    lists => {
      if (accountType === 'producer') {
        return filter(lists, list => list.producerId === accountId);
      }
      if (accountType === 'agent') {
        return filter(lists, list => list.agentId === accountId);
      }
      return [];
    },
  ),
);
export const getListById = id =>
  createSelector(
    [getLists],
    lists => find(lists, list => list.id === id),
  );
export const getListsByTalentId = talentId =>
  createSelector(
    [getLists],
    lists => filter(lists, list => includes(list.talentsIds, talentId)),
  );

export const getSort = createSelector(['lists.sort']);
export const getAddTalentInProgress = createSelector([
  'lists.addTalentInProgress',
]);
export const getListsFilterData = createSelector(['lists.filterData']);
