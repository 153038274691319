import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { File } from 'modules/core';
import { Validator } from 'modules/assets';
import { ImageInputField } from '../ImageInputField';
import { ImageEditModal } from '../ImageEditModal';
import styles from './ManagedImageInput.module.scss';

export default function ManagedImageInput({
  name,
  label,
  initialImage,
  showGrid,
  cropShape,
  cropSize,
  imageStyle,
  title,
  setFormValue,
  className,
  style,
}) {
  const [error, setError] = useState(null);
  const [image, setImage] = useState(initialImage);
  const [file, setFile] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  const originalImage = file ? URL.createObjectURL(file) : null;

  function handleChangeFile(fileObject) {
    setFile(fileObject);

    if (!fileObject) {
      setImage(null);
      setFormValue(name, null);
      return;
    }

    const imageError = Validator.validate(fileObject, 'image');

    if (!isEmpty(imageError)) {
      setError(imageError[name]);
      return;
    }

    setError(null);
    setModalVisible(true);
  }

  const handleSaveClick = blob => {
    const { name: fileName } = file;

    const croppedImageFile = File.blobToFile(blob, fileName, 'image/jpeg');
    const imageUrl = URL.createObjectURL(blob);

    setImage(imageUrl);
    setFormValue(name, croppedImageFile);
    setModalVisible(false);
  };

  function handleDismiss() {
    setError(null);
  }

  const handleCancelClick = () => setModalVisible(false);
  const containerClasses = classNames(styles.container, className);

  return (
    <div style={style} className={containerClasses}>
      <ImageInputField
        name={name}
        label={label}
        image={image}
        onDismiss={handleDismiss}
        imageStyle={imageStyle}
        onChange={handleChangeFile}
      />
      {modalVisible && (
        <ImageEditModal
          image={originalImage}
          title={title}
          onSave={handleSaveClick}
          onCancel={handleCancelClick}
          cropShape={cropShape}
          cropSize={cropSize}
          showGrid={showGrid}
        />
      )}
    </div>
  );
}

ManagedImageInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  setFormValue: PropTypes.func,
  imageStyle: PropTypes.object,
  showGrid: PropTypes.bool,
  cropShape: PropTypes.string,
  cropSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  title: PropTypes.string,
  initialImage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  style: PropTypes.object,
};
