import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'modules/ui';
import classes from './GdprModal.module.scss';

const GDPR_ETHNICITY_COPY = type =>
  type === 'Cultural Background' ? (
    <>
      Some projects may benefit from having a narrator of a certain{' '}
      <strong>{type}</strong> for authenticity purposes. If you would like your{' '}
      <strong>{type}</strong>, including your national origin, ancestry,
      cultural experiences, or other linguistic characteristics, to be
      considered when assessing you as a potential candidate for an audio
      project, you may provide it. Whether you provide this information is
      entirely voluntary. If you provide it, it may be one of several factors in
      your profile that is taken into consideration when matching you to a
      potential project, but it will not be the only factor. This information
      will not be used or shared for any purpose other than in the consideration
      of audio projects within the Ahab platform.
      <br></br>
      <br></br>
      For purposes of this Consent Request, “Cultural Background” refers to your
      or your ancestors’ place of origin and the cultural or linguistic
      characteristics of that national origin group. <strong>
        {type}
      </strong>{' '}
      does not mean race or color and you may select any categories for which
      you believe you have relevant experience. For example, if you or your
      ancestors have spent a significant amount of time in Hawaii and believe
      you can lend an authentic voice to stories focused on Hawaiian heritage
      you can select Hawaiian or Pacific islander.
    </>
  ) : (
    <>
      If you would like your <strong>{type}</strong> to be a factor considered
      when assessing you as a potential candidate for an audio project, you may
      provide it. Provision of the information in this section is voluntary.
    </>
  );

const GDPR_EDIT_TEXT_COPY =
  'You may edit your information at any time. Removing your selection will serve as a request to withdraw your consent. More information about how we collect, store, use and share personal data, and your rights under applicable data protection laws, can be found at Penguin privacy policy.';
const GDPR_EDIT_TEXT_EXISTING_USERS_COPY = type => (
  <>
    Clicking “Cancel” and “Save” will withdraw your consent and remove your{' '}
    <strong>{type}</strong> selection from your profile. You may edit your
    information at any time. More information about how we collect, store, use
    and share personal data, and your rights under applicable data protection
    laws, can be found at Penguin privacy policy.
  </>
);

const GDPR_CONSENT_COPY = type => (
  <>
    By clicking “I consent” below you are consenting to Content Creators within
    the Ahab platform using <strong>{type}</strong> for the purpose of
    identifying audio projects for which you may be a fit.
  </>
);
export const GDPR_UNION = 'Union membership';
export const GDPR_ETHNICITY = 'Cultural Background';

const styles = {
  dialogContainer: {
    width: 720,
  },
  gdprFooter: {
    alignSelf: 'center',
  },
};
export default function GdprModal({
  onSubmitEthnicityGdprConsent,
  onCloseEthnicityGdprConsent,
  onSubmitUnionGdprConsent,
  onCloseUnionGdprConsent,
  handleModalOpen,
  type,
  isDataFilledWithoutConsent,
}) {
  function handleSubmitGdprConsent() {
    if (type === GDPR_ETHNICITY) {
      onSubmitEthnicityGdprConsent();
      return;
    }
    onSubmitUnionGdprConsent();
  }

  function handleCloseGdprConsent() {
    if (type === GDPR_ETHNICITY) {
      onCloseEthnicityGdprConsent();
      return;
    }
    onCloseUnionGdprConsent();
  }
  return (
    <Dialog
      title={
        isDataFilledWithoutConsent
          ? 'ACTION REQUIRED: Consent now required for this field.'
          : 'Consent Request'
      }
      actionBtnLabel="I consent"
      cancelBtnLabel="Cancel"
      onAction={() => {
        handleSubmitGdprConsent();
        handleModalOpen(false);
      }}
      onCancel={() => {
        handleCloseGdprConsent();
        handleModalOpen(false);
      }}
      footerStyle={styles.gdprFooter}
      containerStyle={styles.dialogContainer}
    >
      <div className={classes.gdprContainer}>
        <div className={classes.gdprTextContainer}>
          <p className={classes.gdprText}>{GDPR_ETHNICITY_COPY(type)}</p>
          <p className={classes.gdprText}>
            {isDataFilledWithoutConsent
              ? GDPR_EDIT_TEXT_EXISTING_USERS_COPY(type)
              : GDPR_EDIT_TEXT_COPY}
            <a
              href="https://www.ahabtalent.com/privacy-policy/"
              className={classes.gdprText}
              rel="noopener noreferrer"
              target="_blank"
            >
              Penguin Random House Privacy Policy
            </a>
          </p>
          <p className={classes.gdprText}>{GDPR_CONSENT_COPY(type)}</p>
        </div>
      </div>
    </Dialog>
  );
}

GdprModal.propTypes = {
  type: PropTypes.string.isRequired,
  onSubmitEthnicityGdprConsent: PropTypes.func,
  onSubmitUnionGdprConsent: PropTypes.func,
  handleModalOpen: PropTypes.func.isRequired,
  onCloseEthnicityGdprConsent: PropTypes.func.isRequired,
  onCloseUnionGdprConsent: PropTypes.func.isRequired,
  isDataFilledWithoutConsent: PropTypes.bool.isRequired,
};
