import React, { useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';

import { LineDivider, Dialog } from 'modules/ui';
import { SamplePreview } from 'modules/audio-samples';

import classes from './ReorderSamplesDialog.module.scss';

const styles = {
  container: {
    height: '80vh',
    maxHeight: 800,
    width: 600,
  },
};

export default function ReorderSamplesDialog({
  samples,
  onClose,
  error,
  onSaveOrder,
  isLoading,
}) {
  const [samplesList, setSamplesList] = useState(samples);
  const isSamplesListUnchanged = isEqual(samples, samplesList);

  function handleChangeSampleOrder(movedSampleId, shouldMoveUp) {
    const currentSamplesIdsOrder = map(samplesList, sample => {
      return sample.id;
    });
    const currentIndexOfMoved = indexOf(currentSamplesIdsOrder, movedSampleId);

    const newSamplesIdsOrder = map(currentSamplesIdsOrder, (sampleId, i) => {
      const moveToIndex = shouldMoveUp
        ? currentIndexOfMoved - 1
        : currentIndexOfMoved + 1;

      if (i === moveToIndex) {
        return movedSampleId;
      }
      if (i === currentIndexOfMoved) {
        return samplesList[moveToIndex].id;
      }
      return sampleId;
    });

    const reorderedSamples = map(newSamplesIdsOrder, orderedSampleId => {
      return find(samplesList, { id: orderedSampleId });
    });

    setSamplesList([...reorderedSamples]);
  }

  return (
    <Dialog
      cancelBtnLabel="Cancel"
      actionBtnLabel="Save"
      onAction={() => {
        onSaveOrder(samplesList);
      }}
      actionDisabled={isSamplesListUnchanged}
      onCancel={onClose}
      title="Reorder Samples"
      subtitleText="When your profile appears in a Search, only your first three Samples are visible."
      onClose={onClose}
      containerStyle={styles.container}
      loading={isLoading}
      errorMessage={error}
      error={!!error}
    >
      <div className={classes.content}>
        {samplesList.map((sample, i) => {
          return (
            <div key={sample.id}>
              <div className={classes.sampleRow}>
                <div className={classes.sampleNumber}>#{i + 1}</div>
                <SamplePreview
                  index={i}
                  isReorderUi
                  isFirstOnList={i === 0}
                  isLastOnList={i + 1 === samplesList.length}
                  isTalent
                  sample={sample}
                  onMoveSample={handleChangeSampleOrder}
                />
              </div>
              {i === 2 && <LineDivider className={classes.lineDivider} />}
            </div>
          );
        })}
      </div>
    </Dialog>
  );
}

ReorderSamplesDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  samples: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveOrder: PropTypes.func.isRequired,
  error: PropTypes.string,
};
