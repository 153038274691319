import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Colors } from 'modules/theme';
import classes from './InlineSections.module.scss';

const styles = {
  endingLine: {
    opacity: 0.2,
    border: `1px solid ${Colors.grayishBlueColor}`,
  },
};

function InlineSections({ className, displayEndingLine = false, children }) {
  return (
    <div className={className}>
      <div className={classes.container}>{children}</div>
      {displayEndingLine && <hr style={styles.endingLine} />}
    </div>
  );
}

InlineSections.propTypes = {
  className: PropTypes.string,
  displayEndingLine: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default memo(InlineSections);
