import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useRouteListener = (routeMatchRegex, onRouteMatch, depsArray) => {
  const history = useHistory();

  const unListen = history.listen(() => {
    const match = history.location.pathname.match(routeMatchRegex);
    if (match) {
      const resourceId = match[1];
      onRouteMatch(resourceId);
    }
  });

  useEffect(() => {
    return () => unListen();
  }, [depsArray, unListen]);
};

export default useRouteListener;
