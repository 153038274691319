import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import { Page, Button, ConfirmationDialog, TabLayout } from 'modules/ui';
import { logout } from 'modules/auth';

import { getAccount, removeAccount, getAccountType } from '../redux';
import { AccountType } from '../services';
import { SettingsTabs } from '../components';
import classes from './DeleteAccountPage.module.scss';

export default function DeleteAccountPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const account = useSelector(getAccount);
  const accountId = get(account, 'id');
  const accountType = useSelector(getAccountType);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  function handleDeleteAccount() {
    setShowConfirmationDialog(true);
  }

  function handleDeleteAccountConfirmation() {
    setInProgress(true);
    dispatch(removeAccount(accountId))
      .then(() => dispatch(logout()))
      .then(() => {
        history.push('/farewell', { isDeleted: true });
      });
  }

  function handleCancel() {
    setShowConfirmationDialog(false);
  }

  function resolveMessage() {
    if (AccountType.isProducer(accountType)) {
      return 'If you delete your account, you will permanently lose your profile, messages, offers and auditions.';
    }
    if (AccountType.isAgent(accountType)) {
      return 'If you delete your account, you will permanently lose your profile, messages and offers.';
    }
    return 'If you delete your account, you will permanently lose your profile, messages, offers, auditions and samples.';
  }

  return (
    <Page>
      <TabLayout tabs={<SettingsTabs />} showVisibilityPanel={false}>
        <h1>Delete account</h1>
        <p className={classes.deleteDescription}>
          Click the button below to delete your Ahab account.
        </p>
        <Button primary onClick={handleDeleteAccount}>
          Delete account
        </Button>
        {showConfirmationDialog && (
          <ConfirmationDialog
            title="Are you sure you want to delete your account?"
            message={resolveMessage()}
            onAction={handleDeleteAccountConfirmation}
            onCancel={handleCancel}
            actionBtnLabel="Delete"
            cancelBtnLabel="Cancel"
            inProgress={inProgress}
            withOverlay
          />
        )}
      </TabLayout>
    </Page>
  );
}
