import { createAction, createReducer } from 'redux-starter-kit';
import createSelector from 'selectorator';
import { Audition } from 'modules/auditions/services';

const INITIAL_STATE = {
  sort: {
    [Audition.STATUSES.ACTIVE]: 'dueDate',
    [Audition.STATUSES.INACTIVE]: 'name',
  },
};

export const setSort = createAction('submissions/sort/set');
export const reducer = createReducer(INITIAL_STATE, {
  [setSort]: (state, action) => {
    state.sort = { ...state.sort, ...action.payload };
  },
});

export const getSort = createSelector(['submissions.sort']);
