import ReactDOM from 'react-dom';
import React from 'react';
import { ConfirmationDialog } from 'modules/ui';

const getUserConfirmation = (message, callback) => {
  const modalContainer = document.createElement('div');
  modalContainer.classList.add('modalContainer');
  document.body.appendChild(modalContainer);

  const removeModal = answer => {
    ReactDOM.unmountComponentAtNode(modalContainer);
    document.body.removeChild(modalContainer);
    callback(answer);
  };

  ReactDOM.render(
    <ConfirmationDialog
      title={message}
      message="Changes made to this section won’t be saved unless you hit the save button at the bottom of the page."
      actionBtnLabel="Discard changes"
      cancelBtnLabel="Keep editing"
      onCancel={() => removeModal(false)}
      onAction={() => removeModal(true)}
    />,
    modalContainer,
  );
};

export default {
  getUserConfirmation,
};
