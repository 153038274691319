import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Message.module.scss';

function Message({ children, className }) {
  const messageClass = classNames(classes.message, className);
  return <div className={messageClass}>{children}</div>;
}

Message.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default Message;
