import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar, Icon } from 'modules/ui';
import { getAsset } from 'modules/assets';
import classes from './AvatarOptionUI.module.scss';

function AvatarOptionUI({
  title,
  description,
  avatarUrl,
  isCheckbox,
  selected,
  disabled,
  menuStyle,
  containerStyle,
  onClick,
  divider,
}) {
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    if (avatarUrl) {
      getAsset(avatarUrl).then(url => setProfileUrl(url));
    }
  }, [avatarUrl]);

  const containerClasses = classNames(classes.container, {
    [classes.disabled]: disabled,
    [classes['with-divider']]: divider,
    [classes['menu--selected']]: menuStyle && selected,
  });

  const iconClasses = classNames(classes.icon, {
    [classes['icon--multiselect']]: isCheckbox,
    [classes['icon--selected']]: selected,
  });

  return (
    <div
      type="button"
      style={containerStyle}
      className={containerClasses}
      onClick={disabled ? null : onClick}
      onKeyPress={disabled ? null : onClick}
    >
      <Avatar className={classes.avatar} size={40} photoUrl={profileUrl} aria-haspopup="true" />
      <div className={classes.labelContainer}>
        <span className={classes.label}>{title}</span>
        <span className={classes.locationLabel}>{description}</span>
      </div>
      <div className={iconClasses}>
        {selected && <Icon name="checked" color="#fff" size={24} />}
      </div>
    </div>
  );
}

AvatarOptionUI.propTypes = {
  talent: PropTypes.object.isRequired,
  isCheckbox: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  menuStyle: PropTypes.bool,
  containerStyle: PropTypes.object,
  onClick: PropTypes.func,
  divider: PropTypes.bool,
};

AvatarOptionUI.defaultProps = {
  isCheckbox: false,
  divider: false,
  hideIcon: false,
};

export default memo(AvatarOptionUI);
