import React, { Component, memo } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconButton } from 'modules/ui';
import { loadAsset } from 'modules/assets';
import { getIsIOS } from 'modules/core';
import {
  setToPlaylist,
  getPlaylist,
  getCurrentAudioUrl,
  setCurrentAudioUrl,
  makeGetPlayingByUrl,
  getPreviousAudioUrl,
  resetAudioUrls,
  setReadyToPlay,
  setBlobReadyToPlay,
  setAudioSampleKey,
  getAudioSampleKey,
} from '../redux';
import { isBlob } from '../services';
import styles from './AsyncPlayButton.module.scss';

const UNREGISTER_AFTER_FIRST_INVOCATION = {
  once: true,
};

class AsyncPlayButton extends Component {
  constructor(props) {
    super(props);

    this.audioRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.trackCurrentTime = this.trackCurrentTime.bind(this);

    this.state = {
      loading: false,
      playing: false,
      playingSource: null,
      downloadedSource: null,
      audioLoaded: false,
    };
  }

  componentWillReceiveProps(nextProps, props) {
    const { shouldPlay } = nextProps;

    const currentTime = Math.floor(this.audioRef.current.currentTime);
    const isSeeked = Math.abs(nextProps.secondsElapsed - currentTime) > 0;
    if (isSeeked) {
      this.audioRef.current.currentTime = nextProps.secondsElapsed;
    }

    if (!shouldPlay) {
      this.setState({ loading: false, playing: false });

      if (this.audioRef.current) {
        this.audioRef.current.pause();
      }
    }
  }

  componentWillUnmount() {
    this.setState({ loading: false, playing: false });

    const { playing, loading } = this.state;
    const { handleResetAudioUrls } = this.props;

    if (playing || loading) {
      handleResetAudioUrls();

      if (this.audioRef.current) {
        this.audioRef.current.pause();
      }
    }

    this.audioRef.current.removeEventListener(
      'timeupdate',
      this.trackCurrentTime,
    );
  }

  trackCurrentTime() {
    const { secondsElapsed, setSecondsElapsed } = this.props;

    const seconds = Math.floor(this.audioRef.current.currentTime);
    if (seconds > secondsElapsed) {
      setSecondsElapsed(seconds);
    }
  }

  registerAudioListeners() {
    const { setTotalSeconds, setSecondsElapsed } = this.props;
    if (this.audioRef.current) {
      this.audioRef.current.addEventListener(
        'loadedmetadata',
        () => {
          setTotalSeconds(Math.floor(this.audioRef.current.duration));
        },
        UNREGISTER_AFTER_FIRST_INVOCATION,
      );

      this.audioRef.current.addEventListener(
        'ended',
        () => setSecondsElapsed(0),
        UNREGISTER_AFTER_FIRST_INVOCATION,
      );

      this.audioRef.current.addEventListener(
        'timeupdate',
        this.trackCurrentTime,
      );
    }
  }

  playMedia = (url, lastPlayingUrl) => {
    const {
      fileUrl,
      toPlaylist,
      nowPlayingUrl,
      firstTimePlaying,
      handleSetReadyToPlay,
      handleSetReadyToPlayBlob,
    } = this.props;

    const { audioLoaded } = this.state;

    this.registerAudioListeners();

    handleSetReadyToPlay(true);
    if (isBlob(fileUrl)) {
      handleSetReadyToPlayBlob(true);
    }

    this.setState({ playingSource: url }, () => {
      toPlaylist({ fileUrl, url });
      this.setState({ playing: true });

      if (this.audioRef.current) {
        if (
          firstTimePlaying ||
          lastPlayingUrl !== nowPlayingUrl ||
          !audioLoaded
        ) {
          this.audioRef.current.load();
          this.setState({ audioLoaded: true });
        }
        this.audioRef.current.play();
        this.audioRef.current.addEventListener('ended', this.pauseMedia);
      }
    });
  };

  downloadMedia = () => {
    const {
      fileUrl,
      playlist,
      loadFile,
      token,
      handleSetReadyToPlay,
      setSecondsElapsed,
    } = this.props;

    // TODO: Does not work on Safari, fix
    // const audioExists = has(playlist, `${fileUrl}`);
    //
    // if (audioExists) {
    //   const existingLocalUrl = playlist[`${fileUrl}`];
    //   this.handleAssetLoaded(existingLocalUrl);
    //   return;
    // }

    handleSetReadyToPlay(false);
    this.setState({ loading: true });
    return loadFile(fileUrl, true, token).then(file => {
      this.setState({ loading: false, downloadedSource: file });
      setSecondsElapsed(0);
      return file;
    });
  };

  pauseMedia = () => {
    this.audioRef.current.pause();
    this.setState({ playing: false });
  };

  handleClick = async event => {
    event.stopPropagation();

    const { playing, downloadedSource, audioLoaded } = this.state;
    const {
      onClick,
      fileUrl,
      setUrl,
      isIOS,
      nowPlayingUrl,
      firstTimePlaying,
      handleAudioSampleKey,
      audioSampleKey,
    } = this.props;

    const lastPlayingUrl = nowPlayingUrl;
    // setting a specific key created in AudioPreview to redux store, so in case that there are more components with the same
    // file url, only the clicked component will play the file
    handleAudioSampleKey(audioSampleKey);

    // ios will disable media from playing if it did not happen immediately after the user's click (autoplay prevention)
    // as we need to wait for the promise to resolve (sample download from s3) we introduced a third state in case of ios
    // user initiates playing after the sample has been downloaded
    if (!isIOS) {
      if (!playing) {
        setUrl(fileUrl);
        if (firstTimePlaying || !audioLoaded) {
          const media = await this.downloadMedia();
          this.playMedia(media, lastPlayingUrl);
        } else {
          this.playMedia(downloadedSource, lastPlayingUrl);
        }
      } else {
        this.pauseMedia();
      }
    } else if (!playing && !downloadedSource) {
      this.downloadMedia();
    } else if (!playing && downloadedSource) {
      if (nowPlayingUrl !== fileUrl) {
        await setUrl(fileUrl);
      }
      this.playMedia(downloadedSource, lastPlayingUrl);
    } else {
      this.pauseMedia();
    }

    if (onClick) {
      onClick();
    }
  };

  getIconName = () => {
    const { playing, downloadedSource } = this.state;
    const { isIOS } = this.props;

    if (isIOS && downloadedSource === null) {
      return 'downloadMedia';
    }
    return !playing ? 'play' : 'pause';
  };

  render() {
    const { playingSource, loading } = this.state;
    const { className, disabled, containerClassName } = this.props;

    const iconName = this.getIconName();
    const btnClasses = classNames(styles.btn, className);

    return (
      <div className={containerClassName}>
        <audio ref={this.audioRef} preload="metadata">
          <source src={playingSource} type="audio/mp3" />
        </audio>
        <IconButton
          onClick={!loading ? this.handleClick : undefined}
          iconName={iconName}
          iconColor="white"
          className={btnClasses}
          loaderSize={15}
          loading={loading}
          disabled={disabled}
        />
      </div>
    );
  }
}

AsyncPlayButton.propTypes = {
  topPlaylist: PropTypes.func,
  setCurrentAudioUrl: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  nowPlayingUrl: PropTypes.string,
  lastPlayingUrl: PropTypes.string,
  playlist: PropTypes.object,
  shouldPlay: PropTypes.bool,
  disabled: PropTypes.bool,
  token: PropTypes.string,
  isIOS: PropTypes.bool,
  handleResetAudioUrls: PropTypes.func,
  handleSetReadyToPlay: PropTypes.func,
  secondsElapsed: PropTypes.number,
  setSecondsElapsed: PropTypes.func,
  setTotalSeconds: PropTypes.func,
  audioSampleKey: PropTypes.string,
  handleAudioSampleKey: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    setUrl: url => dispatch(setCurrentAudioUrl(url)),
    loadFile: (url, convertToURL, token) =>
      dispatch(loadAsset(url, convertToURL, token)),
    toPlaylist: (currentAudioUrl, source) =>
      dispatch(setToPlaylist(currentAudioUrl, source)),
    handleResetAudioUrls: () => dispatch(resetAudioUrls()),
    handleSetReadyToPlay: isReadyToPlay =>
      dispatch(setReadyToPlay(isReadyToPlay)),
    handleSetReadyToPlayBlob: isReadyToPlayBlob =>
      dispatch(setBlobReadyToPlay(isReadyToPlayBlob)),
    handleAudioSampleKey: key => dispatch(setAudioSampleKey(key)),
  };
};

const mapStateToProps = (state, props) => {
  const { fileUrl, audioSampleKey } = props;

  const getPlayingByUrl = makeGetPlayingByUrl(fileUrl);
  const currentAudioUrl = getCurrentAudioUrl(state);
  const lastAudioUrl = getPreviousAudioUrl(state);
  const currentAudioSampleKey = getAudioSampleKey(state);

  return {
    nowPlayingUrl: currentAudioUrl,
    firstTimePlaying: !currentAudioUrl && !lastAudioUrl,
    playlist: getPlaylist(state),
    shouldPlay:
      getPlayingByUrl(state) && currentAudioSampleKey === audioSampleKey,
    isIOS: getIsIOS(state),
  };
};

const Button = connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(AsyncPlayButton));

export default withRouter(Button);
