import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Validator } from 'modules/core';
import omit from 'lodash/omit';

function validateAuditionDetails({ type, name, description, dueDate }) {
  const errors = {
    type: Validator.validateNotEmpty(type, 'Type'),
    name:
      Validator.validateNotEmpty(name, 'Name') ||
      Validator.validateStringLength(name, 255, 'Name'),
    description: Validator.validateNotEmpty(description, 'Description'),
    dueDate:
      Validator.validateNotEmpty(dueDate, 'Due date') ||
      Validator.validateTimestamp(dueDate, 'Due date'),
  };

  return omitBy(errors, error => error === false);
}

function validateAuditionScript({ scriptUrl }) {
  const errors = {
    scriptUrl: Validator.validateNotEmpty(scriptUrl, 'Script'),
  };

  return omitBy(errors, error => error === false);
}

function validateAuditionSkills({
  requiredGender,
  requiredVoiceAgeRanges,
  requiredPrimaryLanguages,
}) {
  const errors = {
    requiredGender: Validator.validateNotEmpty(requiredGender, 'Gender'),
    requiredVoiceAgeRanges: Validator.validateNotEmpty(
      requiredVoiceAgeRanges,
      'Vocal Age Range',
    ),
    requiredPrimaryLanguages: Validator.validateNotEmpty(
      requiredPrimaryLanguages,
      'Primary language',
    ),
  };

  return omitBy(errors, error => error === false);
}

function validateAuditionRecordingDetails({
  recordingStartDate,
  recordingEndDate,
  recordingLength,
}) {
  const amount = get(recordingLength, 'amount');

  let errors = {
    recordingStartDate:
      isEmpty(recordingStartDate) &&
      !isEmpty(recordingEndDate) &&
      'Either enter both dates or neither.',
    recordingEndDate:
      (isEmpty(recordingEndDate) &&
        !isEmpty(recordingStartDate) &&
        'Either enter both dates or neither.') ||
      (!isEmpty(recordingStartDate) &&
        !isEmpty(recordingEndDate) &&
        Validator.validateTimestampsChronology(
          recordingStartDate,
          recordingEndDate,
        )),
    ...(!isEmpty(amount) && {
      recordingLength: {
        amount: Validator.validateIsFloat(amount, 'Duration'),
      },
    }),
  };

  if (!get(errors, 'recordingLength.amount')) {
    errors = omit(errors, 'recordingLength');
  }

  return omitBy(errors, error => error === false);
}

export default {
  validateAuditionDetails,
  validateAuditionScript,
  validateAuditionSkills,
  validateAuditionRecordingDetails,
};
