import Url from 'url-parse';
import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import size from 'lodash/size';
import includes from 'lodash/includes';
import DateUtil from './date';
import SocialMedia from './socialMedia';
import moment from 'moment';
import { Utils } from './index';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

function validateEmail(email) {
  const trimmedEmail = trim(email);

  if (!trimmedEmail) {
    return 'Email is required';
  }

  if (!EMAIL_REGEX.test(trimmedEmail)) {
    return 'This email is invalid';
  }

  return false;
}

function validatePassword(password) {
  if (!password) {
    return 'Password is required';
  }

  if (!PASSWORD_REGEX.test(password)) {
    return 'Password must contain at least one small-case letter, one capital letter, one number, one special character and be at least 8 characters long';
  }

  return false;
}

function formatUrl(url) {
  if (!url) {
    return '';
  }
  let formattedUrl = url;

  if (!formattedUrl.startsWith('www') && !formattedUrl.startsWith('http')) {
    formattedUrl = `www.${formattedUrl}`;
  }
  if (!formattedUrl.startsWith('http')) {
    formattedUrl = `https://${formattedUrl}`;
  }

  return formattedUrl;
}

function validateUrl(url) {
  if (!validator.isURL(url)) {
    return 'This URL is invalid';
  }

  return false;
}

function validateHostName(urlString, hostName) {
  const url = new Url(urlString);
  if (hostName === 'twitter') {
    // Special handling for Twitter due to name change to X.
    if (!includes(url.host, 'twitter') && !includes(url.host, 'x')) {
      return `This ${SocialMedia.formatName(hostName)} URL is invalid`;
    }
  } else if (!includes(url.host, hostName)) {
    return `This ${SocialMedia.formatName(hostName)} URL is invalid`;
  }

  return false;
}

function validateLink(urlString, hostName) {
  const formattedUrlString = formatUrl(urlString);

  return (
    validateUrl(formattedUrlString) ||
    validateHostName(formattedUrlString, hostName)
  );
}

function validateNotEmpty(value, name = 'Value') {
  const trimmedValue = trim(value);

  if (isEmpty(trimmedValue)) {
    return `${name} is required`;
  }

  return false;
}

function isEmail(email) {
  const emailError = validateNotEmpty(email, 'Email');
  if (emailError) {
    return emailError;
  }

  if (!validator.isEmail(trim(email))) {
    return 'This is not a valid email';
  }

  return false;
}

function validateRoleName(value, values, name = 'Value') {
  const trimmedValue = trim(value);

  if (values.includes(value)) {
    return `${name} already exists`;
  }

  return validateNotEmpty(trimmedValue, 'Role name');
}

function validateStringLength(value, maxLength, name = 'Value') {
  const trimmedValue = trim(value);

  if (size(trimmedValue) > maxLength) {
    return `${name} is too long`;
  }

  return false;
}

function validateTimestamp(value, name = 'Value') {
  const valid = new Date(value).getTime() > 0;

  if (valid) {
    return false;
  }

  return `${name} is not a valid date`;
}

function validateTimestampsChronology(
  before,
  after,
  beforeName = 'start date',
  afterName = 'end date',
) {
  if (!moment(before).isSame(after) && !DateUtil.isDateAfter(before, after)) {
    return `Please change ${afterName}`;
  }

  return false;
}

function validateDateNotPassed(value, name = 'Value') {
  if (DateUtil.hasDatePassed(value)) {
    return `${name} has already passed`;
  }

  return false;
}

function validatePositiveInteger(value, name = 'Value') {
  const parsedValue = parseInt(value, 10);

  if (parsedValue < 0) {
    return `${name} is not a positive number`;
  }

  return false;
}

function validateIsFloat(number, name = 'Value') {
  const isFloat = Utils.isFloat(number);
  if (!isFloat) {
    return `${name} is not a decimal number`;
  }

  return false;
}

export default {
  formatUrl,
  validateEmail,
  isEmail,
  validatePassword,
  validateUrl,
  validateLink,
  validateNotEmpty,
  validateRoleName,
  validateTimestamp,
  validateDateNotPassed,
  validatePositiveInteger,
  validateTimestampsChronology,
  validateStringLength,
  validateIsFloat,
};
