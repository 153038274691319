import { createReducer, createSelector } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import { resolveErrorMessage } from 'modules/errors';
import { fetchNDA, postNDA, deleteNDA } from './api';

const INITIAL_STATE = {
  nda: null,
  loading: false,
  loadError: false,
  createError: false,
  removeError: false,
};

export const loadNDA = createThunk(fetchNDA, 'load', 'ndas');
export const createNDA = createThunk(postNDA, 'post', 'ndas');
export const removeNDA = createThunk(deleteNDA, 'delete', 'ndas');

export const reducer = createReducer(INITIAL_STATE, {
  [loadNDA.type.request]: state => {
    state.loading = true;
  },
  [loadNDA.type.success]: (state, action) => {
    state.nda = action.payload.data;
    state.loading = false;
  },
  [loadNDA.type.error]: (state, action) => {
    state.loadError = resolveErrorMessage(action.error);
    state.loading = false;
  },
  [createNDA.type.success]: (state, action) => {
    state.nda = action.payload.data;
  },
  [createNDA.type.error]: (state, action) => {
    state.createError = resolveErrorMessage(action.error);
  },
  [removeNDA.type.success]: state => {
    state.nda = null;
  },
  [removeNDA.type.error]: (state, action) => {
    state.removeError = resolveErrorMessage(action.error);
  },
});

export const getAuditionNDA = createSelector(['ndas.nda']);
