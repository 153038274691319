import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FullScreenModal } from 'modules/ui';
import OfferPreviewPage from './OfferPreviewPage';

function OfferPreviewModal({ offerId, onClose, open }) {
  return (
    <FullScreenModal
      className="container container--fullWidth"
      title="Preview Offer"
      onClose={onClose}
      open={open}
    >
      <OfferPreviewPage offerId={offerId} />
    </FullScreenModal>
  );
}

OfferPreviewModal.propTypes = {
  offerId: PropTypes.string.isRequired,
  onClose: PropTypes.string.isRequired,
  open: PropTypes.string.isRequired,
};

export default memo(OfferPreviewModal);
