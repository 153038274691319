import { Validator } from 'modules/core';

function validateProject({ title, length, vertical }) {
  const titleError = Validator.validateNotEmpty(title, 'Title');
  const verticalError = Validator.validateNotEmpty(vertical, 'Category');

  let lengthError = null;
  if (length) {
    lengthError = Validator.validateIsFloat(length, 'Length');
  }

  return {
    ...(!!titleError && { title: titleError }),
    ...(!!lengthError && { length: lengthError }),
    ...(!!verticalError && { vertical: verticalError }),
  };
}

export default {
  validateProject,
};
