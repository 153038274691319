import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import endsWith from 'lodash/endsWith';
import isEmpty from 'lodash/isEmpty';
import { Utils } from './index';

const PREVENT_AUTOCOMPLETE = 'new-password';
const PREVENT_AUTOCOMPLETE_IN_MODAL = 'off';
const AUTOCOMPLETABLE_FIELDS = ['email', 'password'];
const INTEGER_REGEX = RegExp('^[0-9]*$');
const FLOAT_REGEX = RegExp('^[0-9]+(\\.[0-9]{1,2})?$');

function copyToClipboard(text) {
  return navigator.clipboard.writeText(text);
}

function hideAutocompleteOnFocus(
  ref,
  preventAutocompleteAttribute = PREVENT_AUTOCOMPLETE,
) {
  ref.current.setAttribute('autocomplete', preventAutocompleteAttribute);
}

function shouldPreventAutocomplete(fieldName) {
  return !includes(Utils.AUTOCOMPLETABLE_FIELDS, fieldName);
}

function applySortOptions(array, sortOption, sortOrder) {
  if (!sortOption) {
    return array;
  }

  return orderBy(
    array,
    [
      item =>
        get(item, sortOption)
          .toLowerCase()
          .trim(),
    ],
    sortOrder,
  );
}

// Source: https://addyosmani.com/blog/faster-javascript-memoization/
function memoize(f) {
  return function() {
    const args = Array.prototype.slice.call(arguments);

    f.memoize = f.memoize || {};

    return args in f.memoize
      ? f.memoize[args]
      : (f.memoize[args] = f.apply(this, args));
  };
}

function isInteger(number) {
  return INTEGER_REGEX.test(number);
}

//two decimal places allowed
function isFloat(number) {
  return FLOAT_REGEX.test(number);
}

function hasSingleDecimalPoint(str) {
  return str.split('').filter(s => s === '.').length === 1;
}

function isEnteringFloat(number) {
  return (
    isFloat(number) ||
    isEmpty(number) ||
    (endsWith(number, '.') &&
      number.length >= 2 &&
      hasSingleDecimalPoint(number))
  );
}

export function replaceHistoryWith(path) {
  return new Promise(() => {
    window.location.replace(path);
  });
}

export function isEmptyValues(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export default {
  copyToClipboard,
  PREVENT_AUTOCOMPLETE,
  PREVENT_AUTOCOMPLETE_IN_MODAL,
  AUTOCOMPLETABLE_FIELDS,
  hideAutocompleteOnFocus,
  shouldPreventAutocomplete,
  applySortOptions,
  memoize,
  isInteger,
  isFloat,
  isEnteringFloat,
  replaceHistoryWith,
  isEmptyValues,
};
