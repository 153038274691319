import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import { InlineSection, Countdown } from 'modules/ui';

function AnalyticsSection({ audition, ...otherProps }) {
  const dueDate = get(audition, 'dueDate');

  if (!dueDate) {
    return null;
  }

  const endsOn = moment(dueDate).format('MMM Do');
  const endsOnTitle = `Audition ends on ${endsOn}`;

  return (
    <div className="mt-72" {...otherProps}>
      <InlineSection title={endsOnTitle} placeholderText="Not provided.">
        <Countdown dueDate={dueDate} />
      </InlineSection>
    </div>
  );
}

AnalyticsSection.propTypes = {
  audition: PropTypes.object,
};

export default AnalyticsSection;
