import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
import classNames from 'classnames';
import Buttons from './RepeaterRowButtons';
import styles from './RepeaterRow.module.scss';

export default function RepeaterRow({
  error,
  value,
  index,
  onAdd,
  onRemove,
  children,
  className,
  ...otherProps
}) {
  const hasErrorMessage = typeof error === 'string';

  // TODO: Finish container styles
  const containerClasses = classNames(
    styles.container,
    error && styles.error,
    className,
  );

  return (
    <div className={containerClasses}>
      <Grid container spacing={0}>
        <Grid item container xs={10}>
          {children}
        </Grid>
        <Buttons
          value={value}
          index={index}
          onAdd={onAdd}
          onRemove={onRemove}
          {...otherProps}
        />
      </Grid>
      {hasErrorMessage && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
}

RepeaterRow.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  children: PropTypes.node,
};
