import { createAction, createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import find from 'lodash/find';
import { resolveErrorMessage } from '../errors/services';
import { Mapper } from '../core';
import { fetchOffer, fetchOffers, postOffer, offerAction } from './api';

const INITIAL_STATE = {
  offers: [],
  loading: false,
  error: null,
  sort: '-createdAt',
  filterData: null,
};

export const loadOffers = createThunk(fetchOffers, 'load', 'offers');
export const loadOffer = createThunk(fetchOffer, 'load', 'offer');

export const setOfferStatus = createThunk(offerAction, 'updateOffer', 'offers');

export const createOffer = createThunk(postOffer, 'create', 'offers');
export const setOffersSort = createAction('offers/sort/set');

export const setOffersFilterData = createAction('offers/setFilterData');
export const clearOffersFilterData = createAction('offers/clearFilterData');

export const reducer = createReducer(INITIAL_STATE, {
  [loadOffers.type.request]: state => {
    state.loading = true;
  },
  [loadOffers.type.success]: (state, action) => {
    state.offers = action.payload.data;
    state.loading = false;
  },
  [loadOffers.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [setOfferStatus.type.success]: (state, action) => {
    // Mapper.addOrReplace() is causing unnecessary rerenders here
    state.offers = state.offers.map(offer => {
      return offer.id !== action.payload.data.id ? offer : action.payload.data;
    });
  },
  [setOfferStatus.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [loadOffer.type.request]: state => {
    state.loading = true;
  },
  [loadOffer.type.success]: (state, action) => {
    state.offers = Mapper.addOrReplace(state.offers, action.payload.data);
    state.loading = false;
  },
  [createOffer.type.request]: state => {
    state.loading = true;
  },
  [createOffer.type.success]: (state, action) => {
    state.offers = [...state.offers, action.payload.data];
    state.loading = false;
  },
  [createOffer.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [setOffersSort]: (state, action) => {
    state.sort = action.payload;
  },
  [setOffersFilterData]: (state, action) => {
    state.filterData = action.payload;
  },
  [clearOffersFilterData]: state => {
    state.filterData = null;
  },
});

export const getOffers = createSelector(['offers.offers']);
export const getOfferById = id =>
  createSelector(
    [getOffers],
    offers => find(offers, offer => offer.id === id),
  );

export const getOffersSort = createSelector(['offers.sort']);
export const getOffersFilterData = createSelector(['offers.filterData']);
export const getOffersLoading = createSelector(['offers.loading']);
