import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';

import { HorizontalMenu } from 'modules/ui';
import { AuditionMessagingHorizontalMenuLabel } from 'modules/audition-messages';
import { GlobalMessagingHorizontalMenuLabel } from 'modules/global-messages';
import { AccountType, getAccountType } from 'modules/accounts';

import { ChatDropdownTab } from '../const';

function MessagingHorizontalMenu({
  containerClassName,
  tabClassName,
  activeTab,
  onTabChange,
}) {
  const accountType = useSelector(getAccountType);
  const isNotAgent = !AccountType.isAgent(accountType);

  const tabs = compact([
    {
      index: ChatDropdownTab.GLOBAL,
      label: <GlobalMessagingHorizontalMenuLabel />,
    },
    isNotAgent && {
      index: ChatDropdownTab.AUDITION,
      label: <AuditionMessagingHorizontalMenuLabel />,
    },
  ]);

  return (
    <HorizontalMenu
      containerClassName={containerClassName}
      tabClassName={tabClassName}
      tabs={tabs}
      initialTabIndex={activeTab}
      onTabChange={onTabChange}
    />
  );
}

MessagingHorizontalMenu.propTypes = {
  containerClassName: PropTypes.string,
  tabClassName: PropTypes.string,
  activeTab: PropTypes.number,
  onTabChange: PropTypes.func,
};

export default MessagingHorizontalMenu;
