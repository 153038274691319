import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ChatPopupOptions, closeAllChatWindows } from 'modules/chat';
import { closeGlobalMessagingChatPopup } from 'modules/global-messages-threads';

function GlobalChatPopupOptions({ oppositeAccountId, oppositeAccountType }) {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleCloseClick() {
    dispatch(closeGlobalMessagingChatPopup(oppositeAccountId));
  }

  function handleMaximizeClick() {
    handleCloseClick();
    dispatch(closeAllChatWindows());
    history.push(
      `/messaging/global/${oppositeAccountType}/${oppositeAccountId}`,
    );
  }

  return (
    <ChatPopupOptions
      onMaximizeClick={handleMaximizeClick}
      onCloseClick={handleCloseClick}
    />
  );
}

GlobalChatPopupOptions.propTypes = {
  oppositeAccountId: PropTypes.string.isRequired,
  oppositeAccountType: PropTypes.string.isRequired,
};

export default GlobalChatPopupOptions;
