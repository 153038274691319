import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { showNotification, Notification } from 'modules/notifications';
import { AccountType, getAccountType } from 'modules/accounts';
import {
  Dialog,
  ManagedForm,
  FormUtil,
  ConfirmationDialog,
  Textarea,
} from 'modules/ui';

import { getInviteError, removeInviteErrorAction } from '../redux';

function InviteTalentDialog({
  dialogHeaderComponent,
  dialogFooterComponent,
  title,
  loading = false,
  inProgress,
  errorMessage,
  onSubmit,
  initialValues,
  values,
  handleValueChange,
  validate,
  onClose,
  onCancel,
  auditionName,
  children,
}) {
  const dispatch = useDispatch();

  const error = useSelector(getInviteError);
  const accountType = useSelector(getAccountType);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => () => dispatch(removeInviteErrorAction()), [dispatch]);

  useEffect(() => {
    if (inProgress || error) {
      return;
    }

    dispatch(
      showNotification({
        message: 'Your invitation has been sent!',
        type: Notification.TYPES.SUCCESS,
      }),
    );
    onClose();
  }, [dispatch, error, inProgress, onClose, values]);

  function handleCloseClick() {
    const hasChanges = FormUtil.hasUnsavedChanges(values, initialValues);

    if (hasChanges) {
      setShowConfirmationDialog(true);
      return;
    }

    dispatch(removeInviteErrorAction());

    if (onClose) {
      onClose();
    }
  }

  function handleSubmit() {
    if (AccountType.isProducer(accountType)) {
      global.utag.link({ event_type: 'complete_invite' });
      return onSubmit();
    }
    global.utag.link({
      event_type: 'complete_invite',
      page_type: 'Auditions Detail Page',
      module_type: auditionName,
    });
    return onSubmit();
  }

  function handleDialogAnswer(answer) {
    setShowConfirmationDialog(answer);

    if (answer && onClose) {
      onClose();
    }
  }

  function handleCancelClick() {
    const hasChanges = FormUtil.hasUnsavedChanges(values, initialValues);

    if (hasChanges) {
      setShowConfirmationDialog(true);
      return;
    }

    dispatch(removeInviteErrorAction());

    if (onCancel) {
      onCancel();
    }
  }

  return (
    <Dialog
      title={title}
      loading={loading}
      inProgress={inProgress}
      error={!!errorMessage}
      errorMessage={errorMessage}
      onClose={handleCloseClick}
      renderFooterInfo={dialogFooterComponent}
    >
      {dialogHeaderComponent}
      <ManagedForm
        error={error}
        onChangeValues={handleValueChange}
        initialValues={initialValues}
        validate={validate}
        onCancel={handleCancelClick}
        onSubmit={handleSubmit}
        submitButtonLabel="Send invitation"
      >
        {children}
        <Textarea
          label="Message"
          name="message"
          placeholder="Add a message"
          disabled={!!errorMessage}
          maxChars={500}
        />
      </ManagedForm>
      {showConfirmationDialog && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure you want to cancel this invitation?"
          actionBtnLabel="Yes"
          cancelBtnLabel="No"
          onCancel={() => handleDialogAnswer(false)}
          onAction={() => handleDialogAnswer(true)}
        />
      )}
    </Dialog>
  );
}

InviteTalentDialog.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  inProgress: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleValueChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  initialValues: PropTypes.object,
  values: PropTypes.object,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  dialogHeaderComponent: PropTypes.node,
  dialogFooterComponent: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default InviteTalentDialog;
