import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormHeader from './FormHeader';
import FormFooter from './FormFooter';
import styles from './Form.module.scss';

function Form({
  title,
  description,
  onSubmit,
  children,
  style,
  footerStyle,
  className,
  showFooter,
  error,
  footerDescription,
  ...otherProps
}) {
  const formClasses = classNames(
    styles.form,
    error && styles['has-error'],
    className,
  );

  const showHeader = title || description;

  return (
    <form style={style} className={formClasses} onSubmit={onSubmit}>
      {showHeader && (
        <FormHeader title={title} description={description} {...otherProps} />
      )}
      {error && (
        <div id="general-error" className={styles.error}>
          {error}
        </div>
      )}
      {children}
      {showFooter && (
        <FormFooter
          style={footerStyle}
          onSubmit={onSubmit}
          error={error}
          description={footerDescription}
          {...otherProps}
        />
      )}
    </form>
  );
}

Form.propTypes = {
  showFooter: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  style: PropTypes.object,
  footerStyle: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  footerDescription: PropTypes.string,
};

Form.defaultProps = {
  onSubmit: () => {},
  showFooter: true,
};

export default memo(Form);
