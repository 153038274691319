import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';

function createRange(from, to) {
  if (from === to) {
    return `${from}`;
  }

  return `${from}-${to}`;
}

function mapOptionFromModel(model, valueKey = 'id', labelKey = 'name') {
  return {
    value: get(model, valueKey),
    label: get(model, labelKey),
  };
}

function mapOptionFromValue(value) {
  return {
    value,
    label: upperFirst(value),
  };
}

function mapOptionFromRange(rangeObj) {
  const { id, from, to } = rangeObj;

  return {
    value: id,
    label: createRange(from, to),
  };
}

const getOptionObject = (option, mapperFunction) => {
  if (!option) {
    return {
      value: null,
      label: '',
    };
  }

  if (!isFunction(mapperFunction)) {
    return option;
  }

  return mapperFunction(option);
};

const getOptions = (options, mapperFunction) => {
  if (!mapperFunction) {
    return null;
  }

  return map(options, option => getOptionObject(option, mapperFunction));
};

export default {
  mapOptionFromModel,
  mapOptionFromValue,
  mapOptionFromRange,
  getOptions,
};
