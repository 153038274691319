import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  FormDialog,
  ManagedForm,
  Textarea,
  TextInput,
  Drawer,
} from 'modules/ui';
import { useWindowSize } from 'modules/core';
import { getAccount, getAccountType } from 'modules/accounts';
import { showNotification, Notification } from 'modules/notifications';
import { getAgent } from 'modules/agents';
import { setOfferStatus, getOfferById } from '../redux';
import { Validator } from '../services';
import { OFFER_ACTIONS } from '../api';
import classes from './OfferPage.module.scss';

const styles = {
  dialogContainer: {
    maxHeight: 'calc(100vh - 148px)',
    overflowY: 'auto',
  },
  title: {
    marginBottom: 0,
  },
  subtitle: {
    marginBottom: 25,
  },
};

function DeclineOfferModal({ offerId, producer, onClose, isOpen }) {
  const dispatch = useDispatch();

  const loggedInAccount = useSelector(getAccount);
  const loggedInAgent = useSelector(getAgent);
  const accountType = useSelector(getAccountType);
  const agentFullName = get(loggedInAgent, 'fullName');
  const offer = useSelector(getOfferById(offerId));
  const role = get(offer, 'projectRole');
  const { desktopWidth } = useWindowSize();

  const handleSubmit = useCallback(
    values => {
      const roleName = get(role, 'name');
      const updatedValues = {
        ...values,
        fullName: agentFullName,
      };

      global.utag.link({
        event_type: 'decline_offer',
        page_type: 'Offer Page',
        module_type: roleName,
      });

      return dispatch(
        setOfferStatus(
          offerId,
          OFFER_ACTIONS.declined,
          updatedValues,
          accountType,
        ),
      ).then(() => {
        onClose();
        return dispatch(
          showNotification({
            message: 'Offer declined',
            type: Notification.TYPES.SUCCESS,
          }),
        );
      });
    },
    [dispatch, role, offerId],
  );

  if (!isOpen && desktopWidth) {
    return null;
  }

  const DeclineOfferForm = (
    <ManagedForm
      validate={Validator.validateDeclineOffer}
      onSubmit={handleSubmit}
      onCancel={onClose}
      footerClassName={classes.modalFormFooter}
      updatedValues={{ email: loggedInAccount.email }}
      title={
        !desktopWidth &&
        `Tell ${get(producer, 'fullName', 'producer')} Why You’re Declining`
      }
      description={
        !desktopWidth &&
        'Please let the Content Creator know why you are declining this Offer.'
      }
      titleContainerClassName={!desktopWidth && classes.modalFormHeader}
    >
      <TextInput
        required
        label="Email address"
        name="email"
        value={loggedInAccount.email}
        placeholder="julie@company.com"
        id="email"
      />
      <Textarea
        required
        label="Message"
        name="message"
        maxChars={500}
        placeholder="Write a message here"
      />
    </ManagedForm>
  );

  if (desktopWidth) {
    return (
      <FormDialog
        title={`Tell ${get(
          producer,
          'fullName',
          'producer',
        )} Why You’re Declining`}
        subtitleText="Please let the Content Creator know why you are declining this Offer."
        onClose={onClose}
        containerStyle={styles.dialogContainer}
        titleStyle={styles.title}
        subtitleStyle={styles.subtitle}
      >
        {DeclineOfferForm}
      </FormDialog>
    );
  }

  return (
    <Drawer
      open={isOpen}
      anchor="left"
      onClose={onClose}
      showHeader
      drawerTitle="Decline Offer"
    >
      {DeclineOfferForm}
    </Drawer>
  );
}

DeclineOfferModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  producer: PropTypes.object.isRequired,
  offerId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default memo(DeclineOfferModal);
