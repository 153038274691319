import React, { useState } from 'react';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import { Button } from 'modules/ui';
import { getAsset } from 'modules/assets';

export default function DownloadButton({
  children,
  fileUrl,
  onClick,
  ...otherProps
}) {
  const downloadUrl = isObject(fileUrl) ? null : fileUrl;

  const [downloading, setDownloading] = useState(false);
  const fileName =
    downloadUrl && downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1);

  function handleDownload() {
    if (onClick) {
      onClick();
    }

    setDownloading(true);
    getAsset(downloadUrl, true).then(asset => {
      const a = document.createElement('a');
      a.download = fileName;
      a.href = asset;
      a.click();

      setDownloading(false);
    });
  }

  return (
    <Button
      disabled={!downloadUrl}
      onClick={handleDownload}
      loading={downloading}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

DownloadButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  fileUrl: PropTypes.string,
};
