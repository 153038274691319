import find from 'lodash/find';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import unionBy from 'lodash/unionBy';
import { createAction, createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import { Mapper } from 'modules/core';
import { resolveErrorMessage } from 'modules/errors';
import {
  postRole,
  patchRole,
  deleteRole,
  fetchRole,
  fetchProjectRoles,
  fetchSharedProjectRole,
} from './api';

const INITIAL_STATE = {
  topCandidatesSort: 'fullName',
  auditionsSort: '-createdAt',
  loading: false,
  submitError: null,
  topCandidatesError: false,

  // TODO: turn arrays of data to object maps (AHAB-597)
  roles: [],
  sharedRole: null,
};

export const loadProjectRole = createThunk(
  fetchRole,
  'loadProjectRole',
  'projectRoles',
);
export const loadProjectRoles = createThunk(
  fetchProjectRoles,
  'loadProjectRoles',
  'projectRoles',
);

export const loadSharedProjectRole = createThunk(
  fetchSharedProjectRole,
  'loadShared',
  'projectRole',
);

export const createRole = createThunk(postRole, 'create', 'projectRoles');
export const updateRole = createThunk(patchRole, 'update', 'projectRoles');
export const removeRole = createThunk(deleteRole, 'delete', 'projectRoles');

export const setSubmitError = createThunk(
  error => {
    return error;
  },
  'set-error',
  'projectRoles',
);

export const setTopCandidatesSort = createAction(
  'projectRoles/topCandidatesSort/set',
);
export const setAuditionsSort = createAction('projectRoles/auditionsSort/set');

export const reducer = createReducer(INITIAL_STATE, {
  [loadProjectRole.type.request]: state => {
    state.loading = true;
  },
  [loadProjectRole.type.success]: (state, action) => {
    const loadedProjectRole = action.payload.data;

    state.loading = false;
    state.roles = Mapper.addOrReplace(state.roles, loadedProjectRole);
  },
  [loadSharedProjectRole.type.request]: state => {
    state.loading = true;
  },
  [loadSharedProjectRole.type.success]: (state, action) => {
    state.loading = false;
    state.sharedRole = action.payload.data;
  },
  [loadSharedProjectRole.type.error]: (state, action) => {
    state.submitError = resolveErrorMessage(action.error);
  },
  [loadProjectRoles.type.request]: state => {
    state.loading = true;
  },
  [loadProjectRoles.type.success]: (state, action) => {
    const loadedProjectRoles = action.payload.data;

    state.loading = false;
    state.roles = unionBy(loadedProjectRoles, state.roles, 'id');
  },
  [setSubmitError.type.success]: (state, action) => {
    state.submitError = action.payload;
  },
  [removeRole.type.success]: (state, action) => {
    const deletedRoleId = action.request[2];

    remove(state.roles, role => role.id === deletedRoleId);
  },
  [createRole.type.success]: (state, action) => {
    const newRole = action.payload.data;

    state.roles = [...state.roles, newRole];
    state.submitError = false;
  },
  [createRole.type.error]: (state, action) => {
    state.submitError = resolveErrorMessage(action.error);
  },
  [updateRole.type.success]: (state, action) => {
    const newRole = action.payload.data;

    state.roles = Mapper.addOrReplace(state.roles, newRole);
    state.submitError = false;
  },
  [updateRole.type.error]: (state, action) => {
    state.submitError = resolveErrorMessage(action.error);
  },
  [setTopCandidatesSort]: (state, action) => {
    state.topCandidatesSort = action.payload;
  },
  [setAuditionsSort]: (state, action) => {
    state.auditionsSort = action.payload;
  },
});

export const getRoles = createSelector(['projectRoles.roles']);
export const getSharedRole = createSelector(['projectRoles.sharedRole']);
export const getLoading = createSelector(['projectRoles.loading']);
export const getSubmitError = createSelector(['projectRoles.submitError']);

export const getRolesByProjectId = projectId =>
  createSelector(
    [getRoles],
    stateRoles => filter(stateRoles, role => role.projectId === projectId),
  );

export const getRoleById = roleId =>
  createSelector(
    [getRoles],
    stateRoles => find(stateRoles, { id: roleId }),
  );

export const getAuditionsSort = createSelector(['projectRoles.auditionsSort']);
export const getTopCandidatesSort = createSelector([
  'projectRoles.topCandidatesSort',
]);
