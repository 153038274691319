// 72 => 00:01:12
export function secondsToDuration(seconds) {
  const hoursCount = Math.floor(seconds / 3600);
  const minutesCount = Math.floor(seconds / 60) % 60;
  const secondsCount = seconds % 60;

  return [hoursCount, minutesCount, secondsCount]
    .map(v => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
}

export function isBlob(stringUrl) {
  if (stringUrl) {
    if (stringUrl.slice(0, 4) === 'blob') {
      return true;
    }
  }
  return false;
}
