import { useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function AhabReCaptchaComponent(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      // console.log('Execute recaptcha not yet available');
      window.grecaptcha = undefined;
      return;
    } else {
      // console.log('Execute recaptcha available');
    }

    await executeRecaptcha('talentSignup')
      .then(token => {
        props.func(token);
      })
      .catch(error => {
        console.error(error);
        console.log('Could not execute recaptcha; reloading page');
        // Known issue with react-google-recaptcha-v3 and routes; force a reload to fix. https://github.com/t49tran/react-google-recaptcha-v3/issues/192
        window.location.reload();
      });
  }, [executeRecaptcha]);

  // Use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return null;
}
export default AhabReCaptchaComponent;
