import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MessageGroup from './MessageGroup';
import MessageAvatar from './MessageAvatar';
import MessageList from './MessageList';
import SentMessage from './SentMessage';

import classes from './MessageGroupRight.module.scss';

function MessageGroupRight({ messages, photoUrl, displayTimestamps }) {
  return (
    <MessageGroup className={classes.messageGroupRight}>
      <MessageList className={classes.messageListRight}>
        {messages.map(message => {
          const messageId = get(message, 'id');
          return (
            <SentMessage
              key={messageId}
              message={message}
              displayTimestamps={displayTimestamps}
            />
          );
        })}
      </MessageList>
      <MessageAvatar photoUrl={photoUrl} />
    </MessageGroup>
  );
}

MessageGroupRight.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  photoUrl: PropTypes.string,
  displayTimestamps: PropTypes.bool,
};

export default MessageGroupRight;
