import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Assets, loadAsset } from 'modules/assets';
import { MenuItem, DropdownButton } from 'modules/ui';
import { SampleTopCandidateDialog } from 'modules/top-candidates';
import styles from './SampleDropdownOptions.module.scss';

const downloadLinkStyle = {
  display: 'none',
};

export default function SampleDropdownOptions({ file }) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const downloadLinkRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(false);

  const fileUrl = get(file, 'url');
  const fileName = get(file, 'name');

  useEffect(() => {
    dispatch(loadAsset(fileUrl, true)).then(asset => setDownloadUrl(asset));
  }, [fileUrl]);

  function handleDownloadSampleClick() {
    if (!downloadLinkRef) {
      return;
    }

    downloadLinkRef.current.click();
  }

  const fileExtension = Assets.getExtension(fileUrl);
  const downloadLink = `${fileName}.${fileExtension}`;

  return (
    <>
      <DropdownButton
        vertical
        className={styles.dropdownButton}
        ref={dropdownRef}
        iconColor="black"
        arrowRightPosition="7px"
      >
        <a
          ref={downloadLinkRef}
          href={downloadUrl}
          download={downloadLink}
          style={downloadLinkStyle}
        >
          Download
        </a>
        <MenuItem
          text="Add to Top Candidates"
          icon="add"
          onClick={() => setModalOpen(true)}
        />
        <MenuItem
          text="Download sample"
          icon="download"
          onClick={handleDownloadSampleClick}
        />
      </DropdownButton>
      {modalOpen && (
        <SampleTopCandidateDialog
          sample={file}
          onClose={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

SampleDropdownOptions.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};
