import omitBy from 'lodash/omitBy';
import includes from 'lodash/includes';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { Validator } from 'modules/core/services';
import Dictionary from 'modules/dictionary';

function validateStudioAddress(recordingLocations, studioAddress) {
  if (
    includes(recordingLocations, Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO)
  ) {
    return Validator.validateNotEmpty(studioAddress, 'Studio address');
  }

  return false;
}

function validateSendOffer(formValues, hasAgent) {
  const {
    recordingStartDate,
    recordingEndDate,
    recordingLength,
    recordingLocations,
    studioAddress,
    offerDetails,
    offerAmount,
    dueDate,
    producerEmailContact,
  } = formValues;

  const amount = get(recordingLength, 'amount');

  let errors = {
    recordingStartDate:
      Validator.validateNotEmpty(
        recordingStartDate,
        'Recording window start date',
      ) ||
      Validator.validateDateNotPassed(
        recordingStartDate,
        'Recording window start date',
      ),
    recordingEndDate:
      Validator.validateNotEmpty(
        recordingEndDate,
        'Recording window end date',
      ) ||
      Validator.validateDateNotPassed(
        recordingEndDate,
        'Recording window end date',
      ) ||
      Validator.validateTimestampsChronology(
        recordingStartDate,
        recordingEndDate,
      ),
    recordingLength: {
      amount:
        Validator.validateNotEmpty(amount, 'Duration') ||
        Validator.validateIsFloat(amount, 'Duration'),
    },
    recordingLocations: Validator.validateNotEmpty(
      recordingLocations,
      'Recording location',
    ),
    studioAddress: validateStudioAddress(recordingLocations, studioAddress),
    offerDetails: Validator.validateNotEmpty(offerDetails, 'Message'),
    offerAmount: Validator.validateNotEmpty(offerAmount, 'Offer amount'),
    dueDate: Validator.validateNotEmpty(dueDate, 'Response date'),
  };

  if (errors.recordingLength.amount === false) {
    errors = omit(errors, 'recordingLength');
  }

  if (hasAgent) {
    errors = {
      ...errors,
      producerEmailContact: Validator.isEmail(producerEmailContact),
    };
  }

  return omitBy(errors, error => error === false);
}

function validateDeclineOffer({ email, message }) {
  const errors = {
    email: Validator.validateEmail(email, 'Email'),
    message: Validator.validateNotEmpty(message, 'Message'),
  };

  return omitBy(errors, error => error === false);
}

function validateRespondToOffer({ email, phoneNumber }) {
  const errors = {
    email: Validator.isEmail(email),
    phoneNumber: Validator.validateNotEmpty(phoneNumber, 'Phone number'),
  };

  return omitBy(errors, error => error === false);
}

export default {
  validateSendOffer,
  validateDeclineOffer,
  validateRespondToOffer,
};
