import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Colors } from 'modules/theme';
import { IconButton } from '../IconButton';
import { Sort } from './services';
import { ORDERS } from './const';
import TableCell from './TableCell';
import styles from './TableCellSortable.module.scss';

export default function TableCellSortable({
  children,
  className,
  name,
  sort,
  onClick,
  ...otherProps
}) {
  const sortObj = Sort.toObject(sort);
  const [order, setOrder] = useState(ORDERS.ASC);

  if (sortObj.option === name && sortObj.order !== order) {
    setOrder(sortObj.order);
  }

  function getArrowName() {
    return order === ORDERS.ASC ? 'upwardArrow' : 'downwardArrow';
  }

  function getArrowColor() {
    return name === sortObj.option ? Colors.secondary : Colors.inputPlaceholder;
  }

  function handleClick() {
    const newSort = { option: name, order };

    if (sortObj.option === name) {
      newSort.order = order === ORDERS.ASC ? ORDERS.DESC : ORDERS.ASC;
    }

    if (onClick) {
      onClick(Sort.fromObject(newSort));
    }
  }

  const cellClasses = classNames(styles.cell, className);

  return (
    <TableCell className={cellClasses} onClick={handleClick} {...otherProps}>
      {children}
      <IconButton
        className={styles.arrow}
        iconName={getArrowName()}
        iconColor={getArrowColor()}
        iconSize={10}
      />
    </TableCell>
  );
}

TableCellSortable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
