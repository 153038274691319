import React from 'react';
import PropTypes from 'prop-types';

import { ChatPlaceholder } from 'modules/chat';

function AuditionApplicationChatPlaceholder({ className }) {
  return (
    <ChatPlaceholder
      className={className}
      title="No conversations, yet."
      subtitle="Your audition related conversations will appear here."
    />
  );
}

AuditionApplicationChatPlaceholder.propTypes = {
  className: PropTypes.string,
};

export default AuditionApplicationChatPlaceholder;
