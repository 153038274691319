import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';

function Card({ width, height, children, className, onClick }) {
  const style = { width, height };

  return (
    <div
      onClick={onClick}
      className={`${styles.card} ${className}`}
      style={style}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.node,
  onClick: PropTypes.func,
};

export default memo(Card);
