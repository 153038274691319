import { Api } from 'modules/core';

const TOP_CANDIDATES_TYPE = 'top-candidate-entries';
const TOP_CANDIDATE_INCLUDE =
  'projectRole.project.producer,talent,audioClip,audioSample';

const getRolesUrl = (producerId, projectId) =>
  `producers/${producerId}/projects/${projectId}/roles`;

export function fetchTopCandidates(producerId, projectId, roleId) {
  if (!projectId || !projectId || !roleId) {
    return Promise.resolve();
  }

  const params = { include: TOP_CANDIDATE_INCLUDE };
  const rolesUrl = getRolesUrl(producerId, projectId);

  return Api.find(`${rolesUrl}/${roleId}/top-candidates/all`, params);
}

export function fetchSharedTopCandidates(token) {
  if (!token) {
    return Promise.resolve();
  }

  const params = { include: TOP_CANDIDATE_INCLUDE };
  const headers = { headers: { 'auth-code': token } };
  return Api.find(`shared/top-candidates`, params, {}, headers);
}

export function postSharedTopCandidates(producerId, projectId, roleId, values) {
  if (!producerId || !projectId || !roleId) {
    return Promise.resolve(null);
  }

  const rolesUrl = getRolesUrl(producerId, projectId);
  const endpoint = `${rolesUrl}/${roleId}/top-candidates/actions/share`;
  const data = { resourceType: 'top-candidates-list-share-actions', ...values };

  const relationships = {
    projectRole: {
      data: {
        type: 'project-roles',
        id: roleId,
      },
    },
  };

  return Api.create(endpoint, data, relationships);
}

export function postTopCandidate(
  producerId,
  projectId,
  roleId,
  talentId,
  audioClipId,
  audioSampleId,
  note,
  source,
) {
  if (!producerId || !projectId || !roleId || !talentId) {
    return Promise.resolve();
  }

  const rolesUrl = getRolesUrl(producerId, projectId);
  const endpoint = `${rolesUrl}/${roleId}/top-candidates`;
  const params = { include: TOP_CANDIDATE_INCLUDE };

  const sampleNote = {
    resourceType: TOP_CANDIDATES_TYPE,
    note,
    source,
  };

  const relationships = {
    ...(audioSampleId && {
      audioSample: {
        data: { type: 'audio-samples', id: audioSampleId },
      },
    }),
    ...(audioClipId && {
      audioClip: {
        data: {
          type: 'audio-clips',
          id: audioClipId,
        },
      },
    }),
    ...(talentId && {
      talent: {
        data: { type: 'talents', id: talentId },
      },
    }),
    ...(roleId && {
      projectRole: {
        data: { type: 'project-roles', id: roleId },
      },
    }),
  };

  return Api.create(endpoint, sampleNote, relationships, params);
}

export function patchTopCandidate(
  producerId,
  projectId,
  projectRoleId,
  topCandidateId,
  audioClipId,
  audioSampleId,
  note,
  source,
) {
  const endpoint = getRolesUrl(producerId, projectId);

  const topCandidate = { note, source };
  const params = { include: TOP_CANDIDATE_INCLUDE };

  const relationships = {
    ...(audioSampleId && {
      audioSample: {
        data: {
          type: 'audio-samples',
          id: audioSampleId,
        },
      },
    }),
    ...(audioClipId && {
      audioClip: {
        data: {
          type: 'audio-clips',
          id: audioClipId,
        },
      },
    }),
  };

  return Api.update(
    `${endpoint}/${projectRoleId}/top-candidates/${topCandidateId}`,
    topCandidate,
    'top-candidate-entries',
    relationships,
    params,
  );
}

export function deleteTopCandidate(
  producerId,
  projectId,
  roleId,
  topCandidateId,
) {
  if (!producerId || !projectId || !roleId || !topCandidateId) {
    return Promise.resolve();
  }

  const rolesUrl = getRolesUrl(producerId, projectId);
  const endpoint = `${rolesUrl}/${roleId}/top-candidates/${topCandidateId}`;

  return Api.deleteResource(endpoint);
}
