import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  TablePagination,
  SectionLoader,
  EmptySearchPlaceholder,
} from 'modules/ui';
import { getAgentAssignments } from 'modules/agents';
import { getTalentLoading } from 'modules/talents';

const DEFAULT_ITEMS_PER_PAGE = [15, 30, 60, 90, 150];

const styles = {
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 10,
  },
};

export default function MyClientsList({
  TalentCards,
  page,
  count,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  itemsPerPage,
}) {
  const loading = useSelector(getTalentLoading);
  const agentAssignments = useSelector(getAgentAssignments);

  function resolveEmptyPlaceholders() {
    if (isEmpty(agentAssignments)) {
      return {
        title: `You don't have any clients at the moment.`,
        description:
          'Invite clients or suggest them to add you as their agent in their profile settings.',
      };
    }
    return {
      title: '0 talent found that match your search',
      description: "Try adjusting your search to find what you're looking for",
    };
  }

  if (loading) {
    return <SectionLoader />;
  }

  if (count === 0) {
    return (
      <EmptySearchPlaceholder
        title={resolveEmptyPlaceholders().title}
        description={resolveEmptyPlaceholders().description}
      />
    );
  }

  return (
    <div>
      <div style={styles.cardContainer}>{TalentCards}</div>
      <TablePagination
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={itemsPerPage}
        rowsPerPageLabel="Talents Per Page"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
}

MyClientsList.defaultProps = {
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

MyClientsList.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  TalentCards: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.array,
};
