import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { Card, TextButton } from 'modules/ui';
import { AudioPreview } from 'modules/audio';
import { TalentCardHeader } from 'modules/sharing';
import { AudioSamples } from 'modules/audio-samples/services';
import { Talent } from '../services';
import { Colors } from '../../theme';
import styles from './TalentCard.module.scss';

const customStyles = {
  audioPreview: {
    paddingBottom: 18,
  },
  footerButton: {
    color: Colors.secondary,
    paddingTop: 8,
  },
};

const DETAILS_PATHS = ['genre', 'vertical'];

export default function TalentCard({
  talent,
  avatar,
  onClick,
  cardClassName,
  selectedVerticals,
  selectedPreferredGenres,
  onAddToList,
  onRemoveFromList,
  onShareProfile,
  onInviteToAudition,
  showInviteToAudition,
  onSetVisibility,
  talentVisibleStatus,
}) {
  const { audioSamples } = talent;
  const profileLink = Talent.getProfileLink(talent);

  function handleClick() {
    if (onClick) {
      onClick(talent);
    }
  }
  function cardHeight() {
    if (!showInviteToAudition) return 345;
    return 401;
  }
  const prioritizedSamples = AudioSamples.prioritize(
    audioSamples,
    selectedVerticals,
    selectedPreferredGenres,
  );
  const firstSample = get(prioritizedSamples, '[0]');
  const secondSample = get(prioritizedSamples, '[1]');
  const thirdSample = get(prioritizedSamples, '[2]');

  const cardClasses = classNames(styles.card, cardClassName);

  return (
    <Card className={cardClasses} width={264} height={cardHeight()}>
      <div role="button" onClick={handleClick}>
        <TalentCardHeader
          talent={talent}
          avatar={avatar}
          onAddToList={onAddToList}
          onRemoveFromList={onRemoveFromList}
          onSetVisibility={onSetVisibility}
          onShareProfile={onShareProfile}
          talentVisibleStatus={talentVisibleStatus}
          profileLink={profileLink}
        />
        <div className={styles.details}>
          <AudioPreview
            simple
            detailsPaths={DETAILS_PATHS}
            truncateLength={18}
            details={firstSample}
            containerStyle={customStyles.audioPreview}
            detailsClassName={styles.audioPreview}
          />
          <AudioPreview
            simple
            detailsPaths={DETAILS_PATHS}
            truncateLength={18}
            details={secondSample}
            containerStyle={customStyles.audioPreview}
            detailsClassName={styles.audioPreview}
          />
          <AudioPreview
            simple
            detailsPaths={DETAILS_PATHS}
            truncateLength={18}
            details={thirdSample}
            containerStyle={customStyles.audioPreview}
            detailsClassName={styles.audioPreview}
          />
        </div>
      </div>
      {showInviteToAudition && (
        <div className={styles.footer}>
          <TextButton
            style={customStyles.footerButton}
            title="Invite to audition"
            onClick={onInviteToAudition}
          />
        </div>
      )}
    </Card>
  );
}

TalentCard.propTypes = {
  talent: PropTypes.object,
  cardClassName: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func,
  selectedVerticals: PropTypes.array,
  selectedPreferredGenres: PropTypes.array,
  onAddToList: PropTypes.func,
  onRemoveFromList: PropTypes.func,
  onShareProfile: PropTypes.func,
  onInviteToAudition: PropTypes.func,
  showInviteToAudition: PropTypes.bool,
  onSetVisibility: PropTypes.func,
  talentVisibleStatus: PropTypes.number,
};

TalentCard.defaultProps = {
  showInviteToAudition: true,
  cardClassName: styles['card--cols3'],
};
