import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SearchInput, LineDivider, TextButton } from 'modules/ui';

import classes from './MessagingMenuHeader.module.scss';

function MessagingMenuHeader({
  headerClassName,
  searchPlaceholder,
  onSearch,
  onFilterByUnreadClick,
  onViewAllClick,
  isFilterByUnreadActive,
  shouldShowHeaderButtons,
  onMarkAllAsReadClick,
  showMarkAllButton,
}) {
  const [searchQuery, setSearchQuery] = useState('');
  const searchTimeout = useRef();

  function handleSearchChange(event, value = null) {
    if (!event) {
      return;
    }

    const query = value || event.target.value;
    setSearchQuery(query);

    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => onSearch(query), 500);
  }

  const filterByUnreadButtonTitle = isFilterByUnreadActive
    ? 'View all'
    : 'Filter by unread';

  const onFilterClick = isFilterByUnreadActive
    ? onViewAllClick
    : onFilterByUnreadClick;

  return (
    <>
      {shouldShowHeaderButtons && (
        <div className={classes.textButtonsContainer}>
          <TextButton
            title={filterByUnreadButtonTitle}
            isPlainText
            onClick={onFilterClick}
          />
          {showMarkAllButton && (
            <TextButton
              title="Mark all as read"
              isPlainText
              onClick={onMarkAllAsReadClick}
            />
          )}
        </div>
      )}
      <LineDivider />
      <SearchInput
        containerClassName={classNames(
          classes.searchContainer,
          headerClassName,
        )}
        inputContainerClassName={classes.searchInputContainer}
        inputClassName={classes.searchInput}
        placeholder={searchPlaceholder}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <LineDivider />
    </>
  );
}

MessagingMenuHeader.propTypes = {
  searchPlaceholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  headerClassName: PropTypes.string,
  onFilterByUnreadClick: PropTypes.func,
  onViewAllClick: PropTypes.func,
  isFilterByUnreadActive: PropTypes.bool,
  shouldShowHeaderButtons: PropTypes.bool.isRequired,
  onMarkAllAsReadClick: PropTypes.func,
  showMarkAllButton: PropTypes.bool,
};

export default MessagingMenuHeader;
