import Dictionary, { GENRE_CATEGORIES } from '@prhdigitalgroup/ahab-shared-data';
import OptionUtil from 'modules/ui/components/Option/services/options';
import DictionaryUtil from './services';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';

const RECORDING_LOCATION_OPTIONS = [
  {
    value: Dictionary.RECORDING_LOCATIONS.TALENT_STUDIO,
    label: 'Home Studio',
  },
  {
    value: Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO,
    label: 'Recording Studio',
  },
];

const AUDITION_TYPE_OPTIONS = [
  {
    value: Dictionary.AUDITION_TYPES.OPEN_CALL,
    label: 'Open call',
  },
  {
    value: Dictionary.AUDITION_TYPES.PRIVATE,
    label: 'Private audition',
  },
];

const ACCENT_OPTIONS = OptionUtil.getOptions(
  Dictionary.ACCENTS,
  OptionUtil.mapOptionFromValue,
);

const GENRE_OPTIONS = OptionUtil.getOptions(
  Dictionary.GENRES,
  OptionUtil.mapOptionFromValue,
);

const AUDIOBOOKS_GENRES = pickBy(Dictionary.GENRES, value =>
  includes(GENRE_CATEGORIES.Audiobooks, value),
);

const AUDIOBOOK_GENRE_OPTIONS = OptionUtil.getOptions(
  AUDIOBOOKS_GENRES,
  OptionUtil.mapOptionFromValue,
);

const VIDEOGAMES_GENRES = pickBy(Dictionary.GENRES, value =>
  includes(GENRE_CATEGORIES['Video Games'], value),
);

const VIDEOGAME_GENRE_OPTIONS = OptionUtil.getOptions(
  VIDEOGAMES_GENRES,
  OptionUtil.mapOptionFromValue,
);

const ETHNICITY_OPTIONS = OptionUtil.getOptions(
  Dictionary.ETHNICITIES,
  OptionUtil.mapOptionFromValue,
);

const GENDER_OPTIONS = OptionUtil.getOptions(
  Dictionary.GENDERS,
  OptionUtil.mapOptionFromValue,
);

const GENDER_REQUIREMENTS_OPTIONS = OptionUtil.getOptions(
  Dictionary.GENDER_REQUIREMENTS,
  OptionUtil.mapOptionFromValue,
);

const PRONOUN_OPTIONS = OptionUtil.getOptions(
  Dictionary.PRONOUNS,
  OptionUtil.mapOptionFromValue,
);

const LANGUAGE_OPTIONS = OptionUtil.getOptions(
  Dictionary.LANGUAGE,
  OptionUtil.mapOptionFromValue,
);

const LOCATIONS_OPTIONS = OptionUtil.getOptions(
  Dictionary.LOCATIONS,
  OptionUtil.mapOptionFromModel,
);

const RECORDING_EXPERIENCE_OPTIONS = DictionaryUtil.getRecordingExperienceDisplayOptions(
  OptionUtil.getOptions(
    Dictionary.RECORDING_EXPERIENCES,
    OptionUtil.mapOptionFromRange,
  ),
);

const RECORDING_LENGTH_DURATION_TYPE_OPTIONS = sortBy(
  map(
    OptionUtil.getOptions(
      Dictionary.RECORDING_LENGTH_DURATION_TYPES,
      OptionUtil.mapOptionFromValue,
    ),
    option => ({ ...option, label: startCase(option.label) }),
  ),
  option =>
    option.value === Dictionary.RECORDING_LENGTH_DURATION_TYPES.STUDIO_HOURS
      ? 0
      : 1,
);

const VERTICAL_OPTIONS = OptionUtil.getOptions(
  Dictionary.VERTICALS,
  OptionUtil.mapOptionFromValue,
);

const OFFER_STATUSES_TALENT_OPTIONS = OptionUtil.getOptions(
  {
    ACTIVE: 'active',
    NEGOTIATING: 'negotiating',
    CLOSED: 'closed',
    DECLINED: 'declined',
    HIRED: 'hired',
    EXPIRED: 'expired',
  },
  OptionUtil.mapOptionFromValue,
);

const VOICE_RANGE_OPTIONS = OptionUtil.getOptions(
  Dictionary.VOICE_RANGES,
  OptionUtil.mapOptionFromRange,
);

const { NOT_LISTED, ...restDictionaryUnions } = Dictionary.UNIONS;
const UNION_OPTIONS = OptionUtil.getOptions(
  { ...restDictionaryUnions, NOT_LISTED },
  OptionUtil.mapOptionFromValue,
);

const AGENT_NOTIFICATIONS_OPTIONS = map(
  OptionUtil.getOptions(
    Dictionary.AGENT_NOTIFICATION_OPTIONS,
    OptionUtil.mapOptionFromValue,
  ),
  option => ({ ...option, label: startCase(option.label) }),
);

const TOP_CANDIDATE_SOURCE = {
  AUDITION: 'audition',
  PROFILE: 'profile',
  UNKNOWN: ' ',
};

export default {
  ACCENT_OPTIONS,
  AUDITION_TYPE_OPTIONS,
  GENRE_OPTIONS,
  AUDIOBOOKS_GENRES,
  AUDIOBOOK_GENRE_OPTIONS,
  VIDEOGAMES_GENRES,
  VIDEOGAME_GENRE_OPTIONS,
  ETHNICITY_OPTIONS,
  GENDER_OPTIONS,
  GENDER_REQUIREMENTS_OPTIONS,
  PRONOUN_OPTIONS,
  LANGUAGE_OPTIONS,
  LOCATIONS_OPTIONS,
  RECORDING_EXPERIENCE_OPTIONS,
  RECORDING_LENGTH_DURATION_TYPE_OPTIONS,
  VERTICAL_OPTIONS,
  VOICE_RANGE_OPTIONS,
  RECORDING_LOCATION_OPTIONS,
  UNION_OPTIONS,
  AGENT_NOTIFICATIONS_OPTIONS,
  OFFER_STATUSES_TALENT_OPTIONS,
  TOP_CANDIDATE_SOURCE,
  ...Dictionary,
};

export { default as DictionaryUtil } from './services';
