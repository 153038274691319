import React from 'react';
import { TextInput, ManagedForm } from 'modules/ui';
import { Validator } from '../services';

export default function SocialLinksForm(props) {
  return (
    <ManagedForm
      withPrompt
      title="Add Social Media Links"
      submitButtonLabel="Save links"
      validate={Validator.validateSocialLinks}
      {...props}
    >
      <TextInput
        autoFocus
        link
        label="Facebook"
        name="facebookLink"
        placeholder="facebook.com/username"
      />
      <TextInput
        link
        label="Instagram"
        name="instagramLink"
        placeholder="instagram.com/username"
      />
      <TextInput
        link
        label="X (formerly Twitter)"
        name="twitterLink"
        placeholder="x.com/username"
      />
      <TextInput
        link
        label="LinkedIn"
        name="linkedinLink"
        placeholder="linkedin.com/in/username"
      />
      <TextInput
        link
        label="IMDB"
        name="imdbLink"
        placeholder="imdb.com/user/username"
      />
    </ManagedForm>
  );
}
