import { Api } from 'modules/core';
import _ from 'lodash';

const audioSamplesEndpoint = talentId => {
  return `talents/${talentId}/audio-samples`;
};

export function postAudioSample(talentId, values) {
  const endpoint = audioSamplesEndpoint(talentId);
  const audioSample = { resourceType: 'audio-samples', ...values };

  return Api.create(endpoint, audioSample);
}

export function deleteAudioSample(talentId, audioSampleId) {
  const endpoint = audioSamplesEndpoint(talentId);

  return Api.deleteResource(`${endpoint}/${audioSampleId}`);
}

export function patchAudioSample(talentId, audioSampleId, values) {
  const relationshipPaths = ['voiceAgeRange'];
  const { voiceAgeRangeId } = values;
  const updateValues = _.omit(values, relationshipPaths);
  const endpoint = audioSamplesEndpoint(talentId);

  const relationships = {
    voiceAgeRange: {
      data: { type: 'voice-age-ranges', id: voiceAgeRangeId },
    },
    talent: {
      data: { type: 'talents', id: talentId },
    },
  };

  return Api.update(
    `${endpoint}/${audioSampleId}`,
    updateValues,
    'audio-samples',
    relationships,
  );
}
