import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';

const styles = {
  container: {
    paddingTop: 130,
    textAlign: 'center',
    color: Colors.darkGrey,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Noto Serif, serif',
    fontSize: 20,
    fontWeight: 700,
    paddingBottom: 12,
    color: 'black',
  },
  description: {
    fontSize: 14,
    alignSelf: 'center',
    textAlign: 'left',
    paddingBottom: 12,
  },
};

export default function EmptySearchPlaceholder({ title, description, description2 }) {
  return (
    <div style={styles.container}>
      <div style={styles.title}>{title}</div>
      <div style={styles.description}>{description}</div>
      <div style={styles.description}>{description2}</div>
    </div>
  );
}

EmptySearchPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
