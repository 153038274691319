import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import {
  RouterUtil,
  useScript,
  ScrollingProvider,
  TealiumScriptLoadedProvider,
  // ServerErrorProvider,
} from 'modules/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotFoundPage, PageLoader } from 'modules/ui';
import {
  AccountSwitch,
  ContentCreatorApprovedPage,
} from 'modules/accounts';
import { NotificationProvider } from 'modules/notifications';
import {
  LoginPage,
  ChooseSignUpPage,
  ContentCreatorApplicationPage,
  SignUpPage,
  WelcomePage,
  VerificationEmailPage,
  ForgotPasswordPage,
  SuccessPage,
  ResetPasswordPage,
  CompleteNewPasswordPage,
  FarewellPage,
  ProtectedRoute,
  AuthRoute,
  getIsAuthenticated,
  RedirectionProvider,
} from 'modules/auth';
import './fonts';
import TopCandidatesSharingPage from './modules/sharing/fragments/TopCandidatesSharingPage';

export default function App() {
  const authenticated = useSelector(getIsAuthenticated);

  // Tealium code
  // does not work, production quick fix, I have put script directly in public/index.html
  // const tealiumUrl = process.env.REACT_APP_TEALIUM_URL;
  // useScript(tealiumUrl, true, false);

  return (
    <Router getUserConfirmation={RouterUtil.getUserConfirmation}>
      <ScrollingProvider />
      {/* <ServerErrorProvider /> */}
      <Suspense fallback={<PageLoader />}>
        <NotificationProvider />
        <RedirectionProvider />
        <TealiumScriptLoadedProvider />
        <Switch>
          <AuthRoute path="/login" component={LoginPage} />
          <AuthRoute exact path="/signup" component={ChooseSignUpPage} />
          <AuthRoute path="/signup/talent" component={SignUpPage} />
          <AuthRoute
            path="/signup/content-creator/:firstName/:lastName/:email"
            component={SignUpPage}
          />
          <AuthRoute path="/signup/content-creator" component={SignUpPage} />
          <AuthRoute path="/signup/agent" component={SignUpPage} />
          <AuthRoute
            path="/signup/content-creator-application"
            component={ContentCreatorApplicationPage}
          />
          <AuthRoute path="/welcome" component={WelcomePage} />
          <AuthRoute path="/success" component={SuccessPage} />
          <AuthRoute path="/forgot-password" component={ForgotPasswordPage} />
          <AuthRoute
            path="/auth/verify-email"
            component={VerificationEmailPage}
          />
          <AuthRoute
            path="/auth/reset-password"
            component={ResetPasswordPage}
          />
          <AuthRoute
            path="/auth/complete-new-password"
            component={CompleteNewPasswordPage}
          />
          <Route
            exact
            path="/accounts/content-creator-approved/:name/:lastname/:email/:token"
            component={ContentCreatorApprovedPage}
          />
          <Route
            exact
            path="/shared/top-candidates/:token"
            component={TopCandidatesSharingPage}
          />
          <Route exact path="/farewell" component={FarewellPage} />
          {authenticated && <AccountSwitch />}
          <ProtectedRoute component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Router>
  );
}
