import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Textarea, ManagedForm } from 'modules/ui';
import {
  Assets,
  FileInput,
  MAX_FILE_SIZE,
  Validator as FileValidator,
} from 'modules/assets';
import AuditionScriptGrid from './form-grids/AuditionScriptGrid';
import Validator from '../services/validator';

export default function AuditionScriptForm({
  handleRef,
  initialValues,
  ...otherProps
}) {
  const formRef = useRef(null);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const refChanged = ref ? ref.current !== formRef.current : true;

    if (refChanged && handleRef) {
      handleRef(formRef.current);
      setRef(formRef);
    }
  }, [ref, handleRef]);

  return (
    <ManagedForm
      ref={formRef}
      title="Script"
      initialValues={{
        ...initialValues,
        nonDisclosureAgreement: get(
          initialValues,
          'nonDisclosureAgreement.url',
        ),
      }}
      TemplateComponent={AuditionScriptGrid}
      validate={Validator.validateAuditionScript}
      submitButtonLabel="Save script details"
      {...otherProps}
    >
      <FileInput
        required
        type="document"
        name="scriptUrl"
        label="Script"
        buttonLabel="Upload Script"
        description={`Supported formats: .pdf, .doc, .txt. Max size: ${Assets.convertBytesToMegaBytes(
          MAX_FILE_SIZE.document,
        )}MB`}
        validate={file => FileValidator.validate(file, 'document')}
      />
      <Textarea
        label="Script notes"
        name="scriptNotes"
        maxChars={1000}
        placeholder="Write additional notes about audition requirements such as: tone, accent, audience, markets..."
      />
      {/* TODO temp disabled, will be needed later */}
      {/* <FileInput
        type="document"
        name="nonDisclosureAgreement"
        label="Non-disclosure agreement (NDA)"
        buttonLabel="Upload NDA"
        description="Supported formats: .pdf, .doc, .txt"
        validate={file => FileValidator.validate(file, 'document')}
      /> */}
    </ManagedForm>
  );
}

AuditionScriptForm.propTypes = {
  handleRef: PropTypes.func,
  initialValues: PropTypes.object,
};
