import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import classNames from 'classnames';
import { Button } from 'modules/ui';
import FileUploadButton from './FileUploadButton';
import styles from './FileUpload.module.scss';

const customStyles = {
  moreSamplesBtn: {
    fontSize: 11,
  },
};

export default function FileUpload({
  file,
  details,
  description,
  buttonLabel,
  setRef,
  error,
  onSelectFile,
  uploadBtnStyle,
  uploadBtnClassName,
  moreSamplesBtn,
  noBorder,
  moreSamplesBtnDisabled,
  deleteInProgress,
  disabled,
  descriptionClassName,
  inputName,
}) {
  const handleFileSelect = event => {
    const newFile = get(event, 'target.files.0');
    const { name } = newFile;

    if (name.length > 240) {
      // S3  has a limit for string lengths of 255 chars.
      const truncatedName = truncate(name, {
        length: 240,
        omission: '...',
      });
      const nameSortedFile = new File([newFile], truncatedName, {
        type: newFile.type,
      });
      if (onSelectFile && !!nameSortedFile) {
        onSelectFile(nameSortedFile);
      }
    }

    if (onSelectFile && !!newFile && name.length < 241) {
      onSelectFile(newFile);
    }
  };

  function handleUploadClick() {
    setRef.current.click();
  }

  const containerClasses = classNames(
    !moreSamplesBtn && styles.container,
    error && styles['container--error'],
    noBorder && styles['container--noBorder'],
  );

  if (file || details) {
    return (
      <input
        ref={setRef}
        type="file"
        className="hidden"
        onChange={handleFileSelect}
      />
    );
  }

  if (deleteInProgress && !file) {
    return null;
  }

  return (
    <div className={containerClasses}>
      <input
        name={inputName}
        ref={setRef}
        type="file"
        className="hidden"
        onChange={handleFileSelect}
      />

      {!moreSamplesBtn ? (
        <FileUploadButton
          disabled={disabled}
          description={description}
          buttonLabel={buttonLabel}
          uploadBtnStyle={uploadBtnStyle}
          uploadBtnClassName={uploadBtnClassName}
          onClick={handleUploadClick}
          descriptionClassName={descriptionClassName}
        />
      ) : (
        <Button
          small
          onClick={handleUploadClick}
          className={styles.moreSamplesBtn}
          style={customStyles.moreSamplesBtn}
          disabled={moreSamplesBtnDisabled}
        >
          + Add more samples
        </Button>
      )}
    </div>
  );
}

FileUpload.propTypes = {
  setRef: PropTypes.object,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  onSelectFile: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  moreSamplesBtn: PropTypes.bool,
  moreSamplesBtnDisabled: PropTypes.bool,
  deleteInProgress: PropTypes.bool,
  uploadBtnStyle: PropTypes.object,
  uploadBtnClassName: PropTypes.string,
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  descriptionClassName: PropTypes.string,
  inputName: PropTypes.string,
};
