import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import TableRow from '@material-ui/core/TableRow';
import {
  TableWithPagination,
  TableCellSortable,
  TableCell,
  Section,
} from 'modules/ui';
import { getProducer } from 'modules/producers';
import { useRouteListener, useScrollRestoration } from 'modules/core';
import { Project } from '../services';
import {
  getLoading,
  getProjects,
  getProjectsFilterData,
  getProjectsStats,
  getSort,
  setProjectsFilterData,
} from '../redux';
import ProjectRow from './ProjectRow';

const SCROLL_OFFSET = 622;
const PROJECTS_REGEX = RegExp('/projects/(\\d+)');

export default function ProjectsTable({
  onRowClick,
  onSortClick,
  loadingStats,
}) {
  const dispatch = useDispatch();
  const projectsFilterData = useSelector(getProjectsFilterData);

  const projects = useSelector(getProjects);
  const projectsStats = useSelector(getProjectsStats);
  const sort = useSelector(getSort);
  const loading = useSelector(getLoading);
  const producer = useSelector(getProducer);

  const [page, setPage] = useState(get(projectsFilterData, 'page') || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(projectsFilterData, 'rowsPerPage') || 10,
  );
  const [scrollY] = useState(get(projectsFilterData, 'scrollY'));

  useRouteListener(
    PROJECTS_REGEX,
    projectId =>
      dispatch(
        setProjectsFilterData({
          projectId,
          page,
          rowsPerPage,
          scrollY: window.scrollY,
        }),
      ),
    [page, rowsPerPage],
  );

  useScrollRestoration(scrollY);

  function handlePageChange(newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(newRowsPerPage) {
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  }

  return (
    <Section title="My Projects" loading={loading}>
      <TableWithPagination
        count={projects.length}
        rows={projects}
        renderHeader={() => (
          <TableRow>
            <TableCellSortable name="title" sort={sort} onClick={onSortClick}>
              Project Name A-Z
            </TableCellSortable>
            <TableCell name="auditions" align="center">
              Auditions
            </TableCell>
            <TableCell align="center">Talent</TableCell>
            <TableCell align="center">Posted By</TableCell>
            <TableCellSortable
              name="createdAt"
              sort={sort}
              onClick={onSortClick}
              align="right"
            >
              Date Created
            </TableCellSortable>
          </TableRow>
        )}
        renderRow={project => (
          <ProjectRow
            key={project.id}
            project={project}
            loadingStats={loadingStats}
            stats={Project.resolveStatsForProject(projectsStats, project.id)}
            producer={producer}
            onRowClick={onRowClick}
          />
        )}
        rowsPerPageLabel="Projects Per Page"
        pageChangeScrollY={SCROLL_OFFSET}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        defaultPage={page}
        defaultRowsPerPage={rowsPerPage}
      />
    </Section>
  );
}

ProjectsTable.propTypes = {
  onRowClick: PropTypes.func,
  onSortClick: PropTypes.func,
  loadingStats: PropTypes.bool,
};
