import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { getProducer } from 'modules/producers';
import { loadAllAuditions } from 'modules/auditions-producers';
import { InlineSection, InlineSections, Select } from 'modules/ui';
import {
  loadProjectsAndItsStats,
  getProjectsWithActiveAuditions,
} from 'modules/projects/redux';
import { getAuditions } from 'modules/auditions/redux';
import { Project } from 'modules/projects';
import { Audition, Auditions } from 'modules/auditions';
import Validator from '../validator';

import InviteTalentDialog from './InviteTalentDialog';

import { inviteTalent } from '../redux';
import classes from './InviteTalentFromSearchDialog.module.scss';

function InviteTalentFromSearchDialog({ talent, onCancel, onClose }) {
  const dispatch = useDispatch();

  const producer = useSelector(getProducer);

  const auditions = useSelector(getAuditions);

  const projectsWithActiveAuditions = useSelector(
    getProjectsWithActiveAuditions,
  );
  const projectOptions = Project.createProjectOptions(
    projectsWithActiveAuditions,
  );

  const [loading, setLoading] = useState(true);
  const [auditionOptions, setAuditionOptions] = useState([]);

  const initialValues = {
    projectId: null,
    auditionId: null,
  };

  const [values, setValues] = useState(initialValues);
  const [inProgress, setInProgress] = useState(true);

  const { projectId: selectedProjectId } = values;
  const producerId = get(producer, 'id');
  const talentName = get(talent, 'fullName');

  useEffect(() => {
    // Load projects, its stats and auditions.
    Promise.all([
      dispatch(loadProjectsAndItsStats(producerId)),
      dispatch(loadAllAuditions(producerId)),
    ]).then(() => setLoading(false));
  }, [dispatch, producerId]);

  useEffect(() => {
    // Selected project has changed,
    // update audition options.
    const activeAuditions = sortBy(
      Auditions.filterByActiveStatus(
        Auditions.filterByProjectId(auditions, selectedProjectId),
      ),
      ['name'],
    );
    setAuditionOptions(Audition.createAuditionOptions(activeAuditions));
  }, [auditions, selectedProjectId]);

  function handleSubmit() {
    const { auditionId, message } = values;
    const talentIds = [get(talent, 'id')];

    return dispatch(
      inviteTalent(producerId, auditionId, talentIds, message),
    ).then(() => setInProgress(false));
  }

  function handleValueChange(formValues) {
    setValues(formValues);
  }

  const hasError = !loading && isEmpty(projectOptions);

  return (
    <InviteTalentDialog
      title="Invite to audition"
      loading={loading}
      errorMessage={
        hasError &&
        'You have no auditions at the moment. You will be able to invite people once you create an audition.'
      }
      inProgress={inProgress}
      initialValues={initialValues}
      values={values}
      handleValueChange={handleValueChange}
      validate={Validator.validateInviteTalentFromSearch}
      handleSubmit={handleSubmit}
      onSubmit={handleSubmit}
      onClose={onClose}
      onCancel={onCancel}
    >
      <InlineSections className={classes.container} displayEndingLine>
        <InlineSection title="Send to">
          <p className="body14 bold">{talentName}</p>
        </InlineSection>
      </InlineSections>
      <Select
        required
        label="Project"
        name="projectId"
        placeholder="Choose project"
        options={projectOptions}
      />
      <Select
        required
        label="Audition"
        name="auditionId"
        placeholder="Choose audition"
        options={auditionOptions}
      />
    </InviteTalentDialog>
  );
}

InviteTalentFromSearchDialog.propTypes = {
  talent: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

export default InviteTalentFromSearchDialog;
