import { useState } from 'react';

export default function useAnchorEl() {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  }
  return [anchorEl, handleClick, handleClose];
}
