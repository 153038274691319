import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { showNotification, Notification } from 'modules/notifications';
import {
  Dialog,
  ManagedForm,
  FormUtil,
  ConfirmationDialog,
  Textarea,
  TextInput,
} from 'modules/ui';
import { sendClientInvite } from '../api';
import { Validator } from '../services';

export default function InviteClientDialog({ onClose, onCancel }) {
  const dispatch = useDispatch();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [inProgress, setInProgress] = useState(true);
  const [values, setValues] = useState(null);

  const initialValues = {
    email: '',
    message: '',
  };

  useEffect(() => {
    if (!inProgress) {
      dispatch(
        showNotification({
          message: 'Your invitation has been sent!',
          type: Notification.TYPES.SUCCESS,
        }),
      );
      onClose();
    }
  }, [dispatch, inProgress, onClose, values]);

  function handleValueChange(value) {
    setValues(value);
  }

  function handleCloseClick() {
    const hasChanges = FormUtil.hasUnsavedChanges(values, initialValues);

    if (hasChanges) {
      setShowConfirmationDialog(true);
      return;
    }

    if (onClose) {
      onClose();
      setInProgress(false);
    }
  }

  function handleSubmit(data) {
    const { email, message } = data;

    global.utag.link({
      event_type: 'invite_talent',
      page_type: 'Clients Page',
    });

    return sendClientInvite(email, message).then(setInProgress(false));
  }

  function handleDialogAnswer(answer) {
    setShowConfirmationDialog(answer);

    if (answer && onClose) {
      onClose();
    }
  }

  function handleCancelClick() {
    const hasChanges = FormUtil.hasUnsavedChanges(values, initialValues);

    if (hasChanges) {
      setShowConfirmationDialog(true);
      return;
    }

    if (onCancel) {
      onCancel();
    }
  }

  return (
    <Dialog
      title="Invite Client to Ahab"
      inProgress={inProgress}
      onClose={handleCloseClick}
    >
      <ManagedForm
        onChangeValues={handleValueChange}
        initialValues={values}
        validate={Validator.validateClientInvite}
        onCancel={handleCancelClick}
        onSubmit={handleSubmit}
        submitButtonLabel="Send invitation"
      >
        <TextInput
          label="Email"
          name="email"
          placeholder="Enter clients email"
          isRequired
        />
        <Textarea
          label="Message"
          name="message"
          placeholder="Add a message"
          maxChars={500}
        />
      </ManagedForm>
      {showConfirmationDialog && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure you want to cancel this invitation?"
          actionBtnLabel="Yes"
          cancelBtnLabel="No"
          onCancel={() => handleDialogAnswer(false)}
          onAction={() => handleDialogAnswer(true)}
        />
      )}
    </Dialog>
  );
}

InviteClientDialog.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
};
