import React from 'react';

import { useWindowSize } from 'modules/core';

import classes from './ChatPopupWindow.module.scss';

function withPopupWindow(ChatWindow) {
  return props => {
    const { desktopWidth } = useWindowSize();
    const chatClasses = desktopWidth
      ? classes.popupWindow
      : classes.drawerWindow;
    return (
      <ChatWindow
        className={chatClasses}
        headerClassName={classes.popupHeader}
        footerClassName={classes.popupFooter}
        {...props}
      />
    );
  };
}

export default withPopupWindow;
