import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import {
  Collapsible,
  IconButton,
  AutoCompleteSelect,
  OptionUtil,
  TextInput,
} from 'modules/ui';
import { Colors } from 'modules/theme';
import Dictionary from 'modules/dictionary';
import styles from './SearchForm.module.scss';

export default function LocationSearchForm({
  handleSetFormValue,
  getValue,
  toggleLocationFormOpen,
  locationFormOpen,
  setFormValue,
}) {
  function handleCountryChange(name, value) {
    setFormValue({
      [name]: value,
      stateId: null,
    });
  }

  const country = find(Dictionary.LOCATIONS, {
    id: getValue('countryId'),
  });
  const showState = country && !isEmpty(country.states);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Location</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleLocationFormOpen}
        />
      </div>
      <Collapsible hideButton collapsed={!locationFormOpen}>
        <AutoCompleteSelect
          name="countryId"
          value={getValue('countryId')}
          placeholder="Country"
          setFormValue={handleCountryChange}
          containerClassName={styles.select}
          options={Dictionary.LOCATIONS_OPTIONS}
        />
        {showState && (
          <AutoCompleteSelect
            name="stateId"
            value={getValue('stateId')}
            placeholder="State"
            setFormValue={handleSetFormValue}
            containerClassName={styles.select}
            options={OptionUtil.getOptions(
              country.states,
              OptionUtil.mapOptionFromModel,
            )}
          />
        )}
        <TextInput
          name="city"
          placeholder="City"
          value={getValue('city')}
          setFormValue={handleSetFormValue}
          containerClassName={styles.cityContainer}
        />
      </Collapsible>
    </div>
  );
}

LocationSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleLocationFormOpen: PropTypes.func.isRequired,
  locationFormOpen: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
};
