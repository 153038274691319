import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ManagedForm, TextInput, Page, TabLayout, Label } from 'modules/ui';
import { SettingsTabs } from 'modules/accounts';
import { showNotification, Notification } from 'modules/notifications';
import { Validator } from '../services';
import {
  getAccount,
  getChangeEmailError,
  getChangeEmailSuccess,
  changeEmail,
  clearChangeEmailStatus,
  getChangedEmail,
} from '../redux';
import classes from './ChangeEmailPage.module.scss';

export default function ChangeEmailPage({ history }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const changeEmailError = useSelector(getChangeEmailError);
  const changeEmailSuccess = useSelector(getChangeEmailSuccess);
  const changedEmail = useSelector(getChangedEmail);
  const account = useSelector(getAccount);
  const currentEmail = get(account, 'email');

  useEffect(() => {
    dispatch(clearChangeEmailStatus());
  }, []);

  useEffect(() => {
    if (changeEmailSuccess && changedEmail) {
      dispatch(
        showNotification({
          message: `Email verification sent to ${changedEmail} `,
          type: Notification.TYPES.SUCCESS,
        }),
      );
    }
  }, [changeEmailSuccess, dispatch, history, changedEmail]);

  function handleChangeEmail(data) {
    const { newEmail } = data;
    return dispatch(changeEmail(newEmail));
  }

  return (
    <Page>
      <TabLayout tabs={<SettingsTabs />} showVisibilityPanel={false}>
        <ManagedForm
          ref={formRef}
          submitButtonLabel="Change"
          onSubmit={handleChangeEmail}
          validate={Validator.changeEmailData}
          error={changeEmailError}
          className="mt-48"
        >
          <h1>Change email</h1>
          <p className={classes.changeDescription}>
            A verification email will be sent to your new email address.
          </p>
          <Label label="Current email" />
          <p className={classes.email}>{currentEmail}</p>
          <TextInput
            label="New email"
            name="newEmail"
            placeholder="Add new email address"
          />
        </ManagedForm>
      </TabLayout>
    </Page>
  );
}

ChangeEmailPage.propTypes = {
  history: PropTypes.object,
};
