import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { Page, LinkWrapper, PageLoader } from 'modules/ui';
import { TokenExpiredPage } from 'modules/sharing';
import { Colors } from 'modules/theme';
import { AuthHeader, WelcomeLogo } from 'modules/auth';
import { getAuthCodeValidity, validateAuthCode } from '../redux';

const styles = {
  container: {
    paddingTop: 64,
    textAlign: 'center',
  },
  title: {
    paddingTop: 60,
    paddingBottom: 25,
    fontWeight: 'bold',
    fontFamily: 'Noto Serif',
    fontSize: 32,
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.darkBlue,
  },
  description: {
    marginBottom: 69,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 360,
    fontSize: 14,
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.darkGrey,
    lineHeight: '28px',
  },
  verificationEmail: {
    fontWeight: 'bold',
  },
  homepageLink: {
    fontWeight: 600,
    fontSize: 14,
    color: Colors.link,
  },
};

export const PLUS_SIGN_REGEX = /\+/g;

export default function ContentCreatorApprovedPage() {
  const dispatch = useDispatch();
  const params = useParams();

  const token = get(params, 'token');
  const tokenValid = useSelector(getAuthCodeValidity);
  const email = get(params, 'email');
  const firstName = get(params, 'name').replace(PLUS_SIGN_REGEX, ' ');
  const lastName = get(params, 'lastname').replace(PLUS_SIGN_REGEX, ' ');
  const fullName = [firstName, lastName];

  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (pageLoading && !tokenValid && token) {
      const data = {
        email,
        firstName,
        lastName,
      };
      dispatch(validateAuthCode(token, data, firstName, lastName)).then(() =>
        setPageLoading(false),
      );
    }
  }, [dispatch, token, email, tokenValid, firstName, lastName, pageLoading]);

  if (pageLoading) {
    return <PageLoader />;
  }

  if (!tokenValid) {
    return <TokenExpiredPage />;
  }

  return (
    <Page
      className="pt-64"
      showFooter={false}
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/login"
          linkLabel={`Return to ${window.location.hostname}`}
        />
      }
    >
      <div style={styles.container}>
        <WelcomeLogo />
        <div style={styles.title}>Approval successful!</div>
        <div style={styles.description}>
          You have successfully approved
          <span style={styles.verificationEmail}> {fullName.join(' ')} </span>
          as a Content Creator
        </div>
        <LinkWrapper to="/">
          <div style={styles.homepageLink}>Go to Homepage</div>
        </LinkWrapper>
      </div>
    </Page>
  );
}
