import React from 'react';
import PropTypes from 'prop-types';

import { useWindowSize, MOBILE_WIDTH } from 'modules/core';
import { SearchSort, IconButton } from 'modules/ui';
import { Colors } from 'modules/theme';

import {
  AUDITIONS_TALENT_SORT_OPTIONS as TALENT_SORT_OPTIONS,
  AUDITIONS_AGENT_SORT_OPTIONS as AGENT_SORT_OPTIONS,
} from '../const';
import classes from './SearchPageContentHeader.module.scss';

const styles = {
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 18,
    paddingTop: 53,
  },
  agentsContentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 18,
  },
  auditionsLengthTitle: {
    fontSize: 16,
    color: Colors.darkGrey,
    minWidth: 112,
    alignSelf: 'center',
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  tabletSortContainer: {
    width: 280,
  },
  mobileSortContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 300,
  },
};

export default function SearchPageContentHeader({
  isTalent,
  description,
  onExpandFilters,
  filterValues,
  onSortSet,
  sort,
}) {
  const { desktopWidth, size } = useWindowSize();
  const largeDesktopWidth = size[0] >= 1280;
  const tabletWidth = size[0] >= 760 && size[0] < 1280;

  const sortContainerStyle = !largeDesktopWidth
    ? styles.mobileSortContainer
    : styles.sortContainer;

  function resolveSortOptions() {
    if (isTalent) {
      return TALENT_SORT_OPTIONS;
    }
    return AGENT_SORT_OPTIONS;
  }

  function resolveFiltersStatus() {
    if (!filterValues) {
      return filterValues;
    }

    return !Object.values(filterValues).every(key => key === null);
  }

  return (
    <div
      style={(isTalent && styles.contentHeader) || styles.agentsContentHeader}
    >
      <div
        style={
          tabletWidth
            ? { ...sortContainerStyle, ...styles.tabletSortContainer }
            : sortContainerStyle
        }
      >
        {desktopWidth ? (
          <div style={styles.auditionsLengthTitle}>{description}</div>
        ) : (
          <IconButton
            iconName="filters"
            label="Filters"
            light
            size="12"
            iconColor="gray"
            className={classes.filtersButton}
            onClick={() => onExpandFilters(true)}
            showActiveIndicator={resolveFiltersStatus() && true}
          />
        )}
        <SearchSort
          options={resolveSortOptions()}
          setFormValue={onSortSet}
          value={sort}
          showSortBy={largeDesktopWidth && true}
          containerClassName={classes.selectContainer}
          selectClassName={classes.select}
        />
      </div>
    </div>
  );
}

SearchPageContentHeader.propTypes = {
  isTalent: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  onExpandFilters: PropTypes.func.isRequired,
  filterValues: PropTypes.object,
  onSortSet: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
};
