import pick from 'lodash/pick';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

function hasProfileInfo(producer) {
  const profileInfo = pick(producer, [
    'shortBio',
    'facebookLink',
    'instagramLink',
    'twitterLink',
    'linkedinLink',
  ]);

  return !every(profileInfo, isEmpty);
}

function createOptions(producers) {
  return producers.map(producer => ({
    ...producer,
    value: producer.id,
    label: producer.fullName,
  }));
}

export default {
  createOptions,
  hasProfileInfo,
};
