import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import { NotFoundPage } from 'modules/ui';
import { ProtectedRoute } from 'modules/auth';
import { TalentPreviewPage } from 'modules/talents';
import { ProfilePage } from 'modules/producers';
import { AuditionPage, AuditionSearchPage } from 'modules/auditions';
import { OfferPage } from 'modules/offers';
import {
  EditPersonalPage,
  EditSocialPage,
  EditAgencyPage,
  AgentProfilePage,
  MyClientsPage,
} from './fragments';

const ListPage = lazy(() => import('modules/lists/fragments/ListPage'));
const ListsPage = lazy(() => import('modules/lists/fragments/ListsPage'));
const OffersPage = lazy(() => import('modules/offers/fragments/OffersPage'));
const GlobalMessagingPage = lazy(() =>
  import('modules/global-messages/pages/GlobalMessagingPage'),
);

export default function AgentSwitch() {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={MyClientsPage} />
      <ProtectedRoute
        exact
        path="/auditions/:auditionId"
        component={AuditionPage}
      />
      <ProtectedRoute path="/auditions" component={AuditionSearchPage} />
      <ProtectedRoute exact path="/profile/edit" component={EditPersonalPage} />
      <ProtectedRoute exact path="/clients" component={MyClientsPage} />
      <ProtectedRoute path="/lists/:listId" component={ListPage} />
      <ProtectedRoute path="/lists" component={ListsPage} />
      <ProtectedRoute exact path="/offers" component={OffersPage} />
      <ProtectedRoute exact path="/offers/:offerId" component={OfferPage} />
      <ProtectedRoute
        exact
        path="/profile/edit/agency-info"
        component={EditAgencyPage}
      />
      <ProtectedRoute
        exact
        path="/profile/edit/social-media-links"
        component={EditSocialPage}
      />
      <ProtectedRoute
        exact
        path={['/profile', '/agents/:agentId']}
        component={AgentProfilePage}
      />
      <ProtectedRoute path="/talent/:talentId" component={TalentPreviewPage} />
      <ProtectedRoute path="/producers/:producerId" component={ProfilePage} />
      <ProtectedRoute
        path={[
          '/messaging/global/:oppositeAccountType/:oppositeAccountId',
          '/messaging/global',
        ]}
        component={GlobalMessagingPage}
      />
      <ProtectedRoute component={NotFoundPage} />
    </Switch>
  );
}
