import React from 'react';
import PropTypes from 'prop-types';
import { AvatarDescription, PageHeader } from 'modules/ui';
import { LodashUtil } from 'modules/core';
import get from 'lodash/get';
import styles from './TopCandidatesHeader.module.scss';

export default function TopCandidatesHeader({
  role,
  avatar,
  producerFullName,
}) {
  const roleName = get(role, 'name');

  const title = `Role: ${LodashUtil.truncateText(roleName, 40)}`;

  return (
    <PageHeader
      LeftComponent={
        <div className={styles.titleContainer}>
          <h1>{title}</h1>
          <AvatarDescription
            verb="create"
            avatar={avatar}
            personName={producerFullName}
            containerClassName={styles.subtitleContainer}
          />
        </div>
      }
    />
  );
}

TopCandidatesHeader.propTypes = {
  producerFullName: PropTypes.string.isRequired,
  role: PropTypes.object.isRequired,
  avatar: PropTypes.string,
};
