import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Section } from 'modules/ui';

const EMPTY_BIO_NOTICE = 'You haven’t added a biography yet.';

const styles = {
  content: {
    wordWrap: 'break-word',
  },
};

export default function BiographySection({
  shortBio,
  className,
  emptyBioNotice,
}) {
  return (
    <Section
      title="Biography"
      isEmpty={isEmpty(shortBio)}
      className={className}
      placeholderText={emptyBioNotice || EMPTY_BIO_NOTICE}
    >
      <p className="body16" style={styles.content}>
        {shortBio}
      </p>
    </Section>
  );
}

BiographySection.propTypes = {
  shortBio: PropTypes.string,
  className: PropTypes.string,
  emptyBioNotice: PropTypes.string,
};
