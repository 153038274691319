import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';

import { Api } from 'modules/core';

const resourceType = 'general-message-threads';

function globalMessagesThread(threadId) {
  if (isNil(threadId)) return resourceType;
  return `${resourceType}/${threadId}`;
}

export function fetchGlobalMessagesThread(accountId, sort, limit) {
  if (isNil(accountId)) return Promise.resolve();

  const params = {
    filter: {
      accountId,
    },
    include:
      'talentAccount.talent, producerAccount.producer, agentAccount.agent, lastMessage',
    page: { limit },
    sort,
  };

  return Api.find(globalMessagesThread(), params);
}

export function postGlobalMessagesThread(oppositeAccountData) {
  return Api.create(
    globalMessagesThread(),
    {
      resourceType,
      ...oppositeAccountData,
    },
    null,
    {
      include: 'producerAccount.producer',
    },
  );
}

export function postGlobalMessagesThreadUnreadStatus(
  threadId,
  unreadMessageForAccountId,
) {
  if (isNil(threadId) || isUndefined(unreadMessageForAccountId))
    return Promise.resolve();
  return Api.update(
    globalMessagesThread(threadId),
    {
      unreadMessageForAccountId,
    },
    resourceType,
  );
}

export function searchGlobalMessagesThreadsByName(
  query,
  accountId,
  shouldSearchUnread,
) {
  if (isNil(query)) {
    return Promise.resolve();
  }
  const endpoint = `${resourceType}/search`;
  const body = {
    resourceType: 'general-message-thread-search-actions',
    searchTerm: query,
  };
  const searchUnreadOnlyFilter = shouldSearchUnread && {
    filter: { unreadMessageForAccountId: accountId },
  };
  const params = {
    ...searchUnreadOnlyFilter,
    include: 'producerAccount.producer, talentAccount.talent, lastMessage',
  };
  return Api.create(endpoint, body, undefined, params);
}

export function fetchUnreadGlobalMessagesThreads(accountId, unreadMessageCount) {
  const params = {
    filter: {
      unreadMessageForAccountId: accountId,
    },
    page: { limit: unreadMessageCount },
    include:
      'talentAccount.talent, producerAccount.producer, agentAccount.agent, lastMessage',
  };

  return Api.find(globalMessagesThread(), params);
}

export function markAllMessagesAsRead() {
  const endpoint = 'general-message-threads/actions/markAllRead';
  const markAllReadResourceType = 'general-message-thread-mark-all-read-actions';

  return Api.update(endpoint, {}, markAllReadResourceType);
}
