import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import includes from 'lodash/includes';
import get from 'lodash/get';
import {
  TableWithPagination,
  SectionLoader,
  EmptySearchPlaceholder,
  ConfirmationDialog,
  Sort,
  EmptyPlaceholder,
} from 'modules/ui';
import {
  Audition as AuditionServices,
  Auditions,
} from 'modules/auditions/services';
import {
  getAuditionees,
  loadAuditionApplications,
} from 'modules/audition-applications';
import {
  getSubmissionsByAuditionId,
  loadAllAuditionMessages,
  openAuditionApplicationChatPopup,
} from 'modules/audition-messages';
import { loadAudition } from 'modules/auditions-producers/redux';
import { loadProducerInvitations } from 'modules/auditions-invitations';
import {
  getTopCandidates,
  loadTopCandidates,
  removeTopCandidate,
  SubmissionTopCandidateDialog,
} from 'modules/top-candidates';
import { getProducer } from 'modules/producers';
import { Submissions } from 'modules/submissions/services';
import AuditionsTableHeader from './AuditionsTableHeader';
import AuditionsTableRow from './AuditionsTableRow';
import AuditionsTableInfoHeader from './AuditionsTableInfoHeader';
import { getAuditionsSort, setAuditionsSort } from '../redux';
import styles from './AuditionsTable.module.scss';

const ITEMS_PER_PAGE_PAGINATION = [5, 10, 20, 50, 100];
const { ACTIVE, INACTIVE, DRAFT } = AuditionServices.STATUSES;
const PLACEHOLDERS = {
  [ACTIVE]: {
    title: "You don't have any active auditions",
    description:
      'Either re-run an audition that has previously been active or finish creating a previously drafted audition.',
    noSubmissionsDescription:
      'Invite talents to get more submissions for the audition.',
  },
  [INACTIVE]: {
    title: "You don't have any inactive auditions",
    description:
      'Once you stop an audition or its due date expires, it will appear here. Any inactive auditions can be made active again by moving the due date to the future.',
    noSubmissionsDescription:
      'Run this audition and invite talent to get submissions.',
  },
  [DRAFT]: {
    title: "You don't have any draft auditions",
    description: 'Draft auditions have been created but not run yet.',
    noSubmissionsDescription:
      'Finish and run this audition to get submissions.',
  },
};

function AuditionsTable({
  auditions,
  audition,
  activeTab,
  loading,
  onAuditionChange,
  onEditClick,
  onDeleteClick,
  onRunClick,
  onStopClick,
  onPreviewAuditionClick,
  onInviteTalentClick,
  inviteTalentDisabled,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const projectId = get(params, 'projectId');

  const [auditioneesLoading, setAuditioneesLoading] = useState(false);
  const [topCandidateModalOpen, setTopCandidateModalOpen] = useState(false);
  const [currentTopCandidate, setCurrentTopCandidate] = useState(null);
  const [currentSubmission, setCurrentSubmission] = useState(null);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const auditionId = get(audition, 'id');
  const projectRole = get(audition, 'projectRole');

  const auditionees = useSelector(getAuditionees(auditionId));
  const submissions = useSelector(getSubmissionsByAuditionId(auditionId));
  const producer = useSelector(getProducer);
  const topCandidates = useSelector(getTopCandidates);
  const sort = useSelector(getAuditionsSort);
  const sortObj = Sort.toObject(sort);

  const isTableEmpty = isEmpty(auditionees) && isEmpty(submissions);
  const producerId = get(producer, 'id');

  useEffect(() => {
    if (producerId && auditionId) {
      setAuditioneesLoading(true);
      dispatch(loadAudition(producerId, auditionId)).then(loadedAudition => {
        const roleId = get(loadedAudition, 'data.projectRoleId');

        const loadProducersCall = dispatch(
          loadProducerInvitations(producerId, auditionId),
        );
        const loadTopCandidatesCall = dispatch(
          loadTopCandidates(producerId, projectId, roleId),
        );
        const loadApplicationsCall = dispatch(
          loadAuditionApplications(auditionId),
        );

        Promise.all([
          loadProducersCall,
          loadTopCandidatesCall,
          loadApplicationsCall,
        ]).then(() => setAuditioneesLoading(false));
      });
    }
  }, [dispatch, producerId, projectId, auditionId]);

  function handleTopCandidateAddClick(event, submission) {
    event.stopPropagation();
    setCurrentSubmission(submission);
    setTopCandidateModalOpen(true);
  }

  function handleRemoveConfirmationClose() {
    setShowRemoveConfirmation(false);
  }

  function handleTopCandidateRemoveClick(event, topCandidate) {
    event.stopPropagation();
    setCurrentTopCandidate(topCandidate);
    setShowRemoveConfirmation(true);
  }

  function handleAuditionsSortClick(newSort) {
    dispatch(setAuditionsSort(newSort));
  }

  function navigateToTalentSearch() {
    history.push('/search');
  }

  function handlePageChange(newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(newRowsPerPage) {
    handlePageChange(0);
    setRowsPerPage(newRowsPerPage);
  }

  if (loading) {
    return <SectionLoader />;
  }

  const submittedTalentsIds = submissions.map(
    submission => submission.authorAccount.talentId,
  );

  const nonSubmittedAuditionees = auditionees.filter(
    talent => !includes(submittedTalentsIds, talent.id),
  );
  const aggregatedSubmissions = Submissions.aggregateByTalentId(submissions);
  const rows = [
    ...Object.values(aggregatedSubmissions),
    ...nonSubmittedAuditionees,
  ];

  const sortedRows = Auditions.orderApplicationsAndSubmissions(
    rows,
    sortObj.option,
    sortObj.order,
  );

  function handleRenderHeader() {
    return (
      <>
        <AuditionsTableInfoHeader
          audition={audition}
          auditions={auditions}
          onAuditionChange={onAuditionChange}
          onEditClick={() => onEditClick(audition)}
          onDeleteClick={() => onDeleteClick(audition)}
          onRunClick={() => onRunClick(audition)}
          onStopClick={() => onStopClick(audition)}
          onInviteTalentClick={
            activeTab === ACTIVE && (() => onInviteTalentClick(audition))
          }
          onPreviewAuditionClick={onPreviewAuditionClick}
          inviteTalentDisabled={inviteTalentDisabled}
        />
        {!isTableEmpty && (
          <AuditionsTableHeader
            sort={sort}
            onSortClick={handleAuditionsSortClick}
          />
        )}
      </>
    );
  }

  function handleRowClick(talentId) {
    history.push(`/talent/${talentId}`);
  }

  function handleMessagesClick(talentId) {
    dispatch(openAuditionApplicationChatPopup(auditionId, talentId));
  }

  function handleRemoveConfirmationAction() {
    const { projectRoleId, id } = currentTopCandidate;

    dispatch(removeTopCandidate(producer.id, projectId, projectRoleId, id));
    setShowRemoveConfirmation(false);
  }

  function handleRenderRow(row) {
    return (
      <AuditionsTableRow
        key={Array.isArray(row) ? row[0].authorAccount.talentId : row.id}
        row={row}
        producerId={producerId}
        auditionId={auditionId}
        topCandidates={topCandidates}
        onTopCandidateAddClick={handleTopCandidateAddClick}
        onTopCandidateRemoveClick={handleTopCandidateRemoveClick}
        onMessagesClick={handleMessagesClick}
        onRowClick={handleRowClick}
      />
    );
  }

  return !isEmpty(auditions) ? (
    <>
      <TableWithPagination
        rows={sortedRows}
        count={size(sortedRows)}
        renderHeader={handleRenderHeader}
        renderRow={handleRenderRow}
        RowsPlaceholderComponent={
          auditioneesLoading ? (
            <SectionLoader />
          ) : (
            <EmptyPlaceholder
              title="No Submissions"
              description={PLACEHOLDERS[activeTab].noSubmissionsDescription}
              {...(activeTab === ACTIVE && {
                buttonLabel: 'Invite by name',
                onButtonClick: () => onInviteTalentClick(audition),
                secondaryButtonLabel: 'Search for talent',
                onSecondaryButtonClick: navigateToTalentSearch,
              })}
              containerClassName={styles.emptyAuditionPlaceholderContainer}
            />
          )
        }
        rowsPerPageLabel="Talents Per Page"
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={ITEMS_PER_PAGE_PAGINATION}
        className={styles.card}
      />
      {showRemoveConfirmation && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure you want to remove from the list of top candidates?"
          actionBtnLabel="Yes, remove"
          cancelBtnLabel="Cancel"
          onAction={handleRemoveConfirmationAction}
          onCancel={handleRemoveConfirmationClose}
        />
      )}
      {topCandidateModalOpen && (
        <SubmissionTopCandidateDialog
          projectRole={projectRole}
          submission={currentSubmission}
          talent={currentSubmission.authorAccount.talent}
          onClose={() => setTopCandidateModalOpen(false)}
          onCancel={() => setTopCandidateModalOpen(false)}
        />
      )}
    </>
  ) : (
    <EmptySearchPlaceholder
      title={PLACEHOLDERS[activeTab].title}
      description={PLACEHOLDERS[activeTab].description}
    />
  );
}

export default memo(AuditionsTable);

AuditionsTable.propTypes = {
  loading: PropTypes.bool,
  auditions: PropTypes.array.isRequired,
  audition: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  onAuditionChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onRunClick: PropTypes.func.isRequired,
  onStopClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onPreviewAuditionClick: PropTypes.func.isRequired,
  onInviteTalentClick: PropTypes.func.isRequired,
  inviteTalentDisabled: PropTypes.bool.isRequired,
};
