import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import { Colors } from 'modules/theme';
import useClickAway from './useClickAway';
import Styles from './DropdownMenuStyles';

const listClasses = {
  root: {
    margin: 0,
    padding: 0,
    top: 0,
    borderRadius: '7px',
    overflowX: 'hidden',
    maxHeight: '800px',
    '&:focus': {
      outline: 'none',
    },
  },
};

const StyledMenuList = withStyles(listClasses)(props => (
  <MenuList className="scrollable" style={props.dropdownStyle} {...props} />
));

export default function DropdownMenu({
  styles,
  open,
  loading,
  children,
  onClick,
  onClose,
  anchorEl,
  placement,
  width,
  disablePortal,
  showRightArrow,
  arrowRightPosition,
  dropdownStyle,
  ...otherProps
}) {
  const classes = Styles({ styles, width, showRightArrow, arrowRightPosition });
  const ref = useRef(null);

  useClickAway(ref, onClose);

  return (
    <Popper
      ref={ref}
      placement={placement}
      open={open}
      onClose={onClose}
      classes={classes.tooltip}
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      {...otherProps}
    >
      <Paper className={classes.paper}>
        {loading ? (
          <div className={classes.loaderContainer}>
            <Loader
              type="Oval"
              color={Colors.darkBlue}
              width={20}
              height={20}
            />
          </div>
        ) : (
          <StyledMenuList onClick={onClick} dropdownStyle={dropdownStyle}>
            {children}
          </StyledMenuList>
        )}
      </Paper>
    </Popper>
  );
}

DropdownMenu.propTypes = {
  styles: PropTypes.object,
  open: PropTypes.bool,
  disablePortal: PropTypes.bool,
  placement: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  anchorEl: PropTypes.object,
  width: PropTypes.number,
  showRightArrow: PropTypes.bool,
  arrowRightPosition: PropTypes.string,
  dropdownStyle: PropTypes.object,
};

DropdownMenu.defaultProps = {
  placement: 'bottom-end',
  showRightArrow: true,
  disablePortal: true,
};
