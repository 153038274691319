import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundPage } from 'modules/ui';
import { ProtectedRoute } from 'modules/auth';
import { TalentPreviewPage } from 'modules/talents';
import { AgentPreviewPage } from 'modules/agents';
import {
  ProfilePage,
  EditPersonalPage,
  EditSocialPage,
  SearchPage,
} from './fragments';

const ProjectRolePage = lazy(() =>
  import('modules/project-roles/fragments/ProjectRolePage'),
);
const ProjectPage = lazy(() =>
  import('modules/projects/fragments/ProjectPage'),
);
const ProjectsPage = lazy(() =>
  import('modules/projects/fragments/ProjectsPage'),
);
const ListPage = lazy(() => import('modules/lists/fragments/ListPage'));
const ListsPage = lazy(() => import('modules/lists/fragments/ListsPage'));

const GlobalMessagingPage = lazy(() =>
  import('modules/global-messages/pages/GlobalMessagingPage'),
);

const AuditionsMessagingPage = lazy(() =>
  import('modules/audition-messages/pages/AuditionsMessagingPage'),
);

export default function ProducerSwitch() {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={ProjectsPage} />
      <ProtectedRoute
        path="/projects/:projectId/roles/:roleId"
        component={ProjectRolePage}
      />
      <ProtectedRoute path="/projects/:projectId" component={ProjectPage} />
      <ProtectedRoute path="/projects" component={ProjectsPage} />
      <ProtectedRoute exact path="/lists/:listId" component={ListPage} />
      <ProtectedRoute
        path="/lists/agent-list/:agentListId/:agentId"
        component={ListPage}
      />
      <ProtectedRoute exact path="/lists" component={ListsPage} />
      <ProtectedRoute path="/search" component={SearchPage} />
      <ProtectedRoute path="/talent/:talentId" component={TalentPreviewPage} />
      <ProtectedRoute path="/agents/:agentId" component={AgentPreviewPage} />
      <ProtectedRoute exact path="/profile" component={ProfilePage} />
      <ProtectedRoute exact path="/profile/edit" component={EditPersonalPage} />
      <ProtectedRoute
        exact
        path="/profile/edit/social-media-links"
        component={EditSocialPage}
      />
      <ProtectedRoute
        path="/messaging/auditions/:auditionId/:talentId"
        component={AuditionsMessagingPage}
      />
      <ProtectedRoute
        path="/messaging/auditions"
        component={AuditionsMessagingPage}
      />
      <ProtectedRoute
        path="/messaging/global/:oppositeAccountType/:oppositeAccountId"
        component={GlobalMessagingPage}
      />
      <ProtectedRoute
        path="/messaging/global"
        component={GlobalMessagingPage}
      />
      <ProtectedRoute component={NotFoundPage} />
    </Switch>
  );
}
