import moment from 'moment';

function convertBlobToFile(blob, filename, contentType) {
  const date = Date.now();
  const timestamp = new Date().getTime();
  const resolvedFileName = `${timestamp}_${filename}`;

  return new File([blob], resolvedFileName, {
    type: contentType,
    lastModified: date,
  });
}

function getReadableDuration(seconds) {
  return moment
    .utc(moment.duration(seconds, 'seconds').asMilliseconds())
    .format('HH:mm:ss');
}

export default {
  blobToFile: convertBlobToFile,
  getDuration: getReadableDuration,
};
