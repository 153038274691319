import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { Page, ConfirmationDialog, PageLoader } from 'modules/ui';
import { LodashUtil, useBackBtnListener } from 'modules/core';
import { getAvatar, useTealiumView } from 'modules/accounts';
import { showNotification, Notification } from 'modules/notifications';
import { getProducer } from 'modules/producers';
import {
  loadProject,
  updateProject,
  deleteProject,
  getProjectById,
  createRecentlyViewedProject,
  clearProjectsFilterData,
  removeError,
  getError,
} from '../redux';
import { ProjectHeader, ProjectDetails } from '../components';
import ProjectModal from './ProjectModal';

export default function ProjectPage({ history, match }) {
  const dispatch = useDispatch();
  const projectId = get(match, 'params.projectId');

  useTealiumView('Project Detail Page');

  const producer = useSelector(getProducer);
  const project = useSelector(getProjectById(projectId));
  const avatar = useSelector(getAvatar);
  const error = useSelector(getError);

  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const producerId = get(producer, 'id');
  const title = LodashUtil.truncateText(get(project, 'title'), 33);

  useEffect(() => {
    dispatch(
      showNotification({
        message: error,
        type: Notification.TYPES.WARNING,
      }),
    );

    return () => {
      if (error) {
        dispatch(removeError());
      }
    };
  }, [dispatch, error]);

  useEffect(() => {
    if (!producerId) {
      return;
    }

    dispatch(loadProject(producerId, projectId)).then(() => setLoading(false));
    dispatch(createRecentlyViewedProject(producerId, projectId));
  }, [dispatch, projectId, producerId]);

  useBackBtnListener(() => dispatch(clearProjectsFilterData()));

  function showModal() {
    setModalOpen(true);
  }

  function hideModal() {
    setModalOpen(false);
  }

  function handleEditClick() {
    return showModal();
  }

  function handleDeleteClick() {
    return setShowDeleteConfirmation(true);
  }

  function handleDeleteConfirmationClose() {
    setShowDeleteConfirmation(false);
  }

  function handleDeleteConfirmationAction() {
    setShowDeleteConfirmation(false);
    dispatch(deleteProject(producerId, projectId))
      .then(() => history.push('/projects'))
      .then(() =>
        dispatch(
          showNotification({
            message: `${project.title} Project deleted`,
            type: Notification.TYPES.SUCCESS,
          }),
        ),
      );
  }

  function handleToggleProjectStatus(finished) {
    const notification = `${project.title} Project is ${
      finished ? 'marked as done' : 'active'
    }`;
    return dispatch(updateProject(producerId, projectId, { finished })).then(
      res => {
        if (!res) {
          return;
        }

        dispatch(
          showNotification({
            message: notification,
            type: Notification.TYPES.SUCCESS,
          }),
        );
      },
    );
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Page
      HeaderComponent={
        <ProjectHeader
          projectId={projectId}
          avatar={avatar}
          onToggleProjectStatus={handleToggleProjectStatus}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
        />
      }
    >
      <ProjectDetails projectId={projectId} />
      {modalOpen && <ProjectModal onClose={hideModal} projectId={projectId} />}
      {showDeleteConfirmation && (
        <ConfirmationDialog
          withOverlay
          title={`Are you sure you want to delete ${title} Project.`}
          message="By deleting this project, all of the roles and auditions associated with it will be deleted."
          actionBtnLabel="Yes, delete"
          cancelBtnLabel="Cancel"
          onAction={handleDeleteConfirmationAction}
          onCancel={handleDeleteConfirmationClose}
        />
      )}
    </Page>
  );
}

ProjectPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
