import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect, Select } from 'modules/ui';
import get from 'lodash/get';
import SelectUI from './SelectUI';

function AutoCompleteSelect({
  label,
  name,
  value,
  placeholder,
  setFormValue,
  containerClassName,
  options,
  onChange,
  disabled,
  isMultiSelect,
  ...otherProps
}) {
  const selectedValue = options.find(o => o.value === value);
  const [filterPrefix, setFilterPrefix] = useState(
    isMultiSelect ? '' : get(selectedValue, 'label', ''),
  );

  useEffect(() => {
    setFilterPrefix(isMultiSelect ? '' : get(selectedValue, 'label', ''));
  }, [isMultiSelect, selectedValue]);

  useEffect(() => {
    if (disabled) {
      setFilterPrefix('');
    }
  }, [disabled]);

  function handleSetFilterPrefix(event) {
    setFilterPrefix(event.target.value);
  }

  function handleClearFilterPrefix() {
    setFilterPrefix('');
  }

  function filterOptions(allOptions) {
    if (filterPrefix === '') {
      return allOptions;
    }

    return allOptions.filter(option =>
      option.label.toLowerCase().startsWith(filterPrefix.toLowerCase()),
    );
  }

  const filteredOptions = filterOptions(options);

  if (isMultiSelect) {
    return (
      <MultiSelect
        isAutoComplete
        name={name}
        label={label}
        value={value}
        placeholder={placeholder}
        setFormValue={setFormValue}
        containerClassName={containerClassName}
        options={filteredOptions}
        inputValue={filterPrefix}
        onInputChange={handleSetFilterPrefix}
        clearFilterPrefix={handleClearFilterPrefix}
        disabled={disabled}
        showCaret
        {...otherProps}
      />
    );
  }

  return (
    <Select
      isAutoComplete
      name={name}
      label={label}
      value={value}
      placeholder={placeholder}
      setFormValue={setFormValue}
      containerClassName={containerClassName}
      options={filteredOptions}
      inputValue={filterPrefix}
      onInputChange={handleSetFilterPrefix}
      onChange={onChange}
      clearFilterPrefix={handleClearFilterPrefix}
      disabled={disabled}
      showCaret
      {...otherProps}
    />
  );
}

AutoCompleteSelect.propTypes = {
  ...SelectUI.propTypes,
  name: PropTypes.string,
  setFormValue: PropTypes.func,
  placeholder: PropTypes.string,
  isMultiSelect: PropTypes.bool,
};
export default AutoCompleteSelect;
