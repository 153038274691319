import { combineReducers } from 'redux';
import pick from 'lodash/pick';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import thunk from 'redux-thunk';
import FetchInterceptor from 'fetch-interceptor';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';
import { configureStore } from 'redux-starter-kit';
import { reducer as coreReducer, SET_SERVER_ERROR } from './modules/core';
import { reducer as authReducer, logout } from './modules/auth';
import { reducer as audioReducer } from './modules/audio';
import { reducer as assetReducer } from './modules/assets';
import { reducer as auditionReducer } from './modules/auditions';
import { reducer as auditionsTalentsReducer } from './modules/auditions-talents';
import { reducer as auditionsProducersReducer } from './modules/auditions-producers';
import { reducer as auditionInvitationsReducer } from './modules/auditions-invitations';
import { reducer as auditionApplicationReducer } from './modules/audition-applications';
import { reducer as auditionSuggestionsReducer } from './modules/audition-suggestions';
import { reducer as accountReducer } from './modules/accounts';
import { reducer as projectsReducer } from './modules/projects';
import { reducer as producerReducer } from './modules/producers';
import { reducer as talentReducer } from './modules/talents';
import { reducer as listsReducer } from './modules/lists';
import { reducer as pastRecordingsReducer } from './modules/past-recordings';
import { reducer as publishersReducer } from './modules/publishers';
import { reducer as projectRolesReducer } from './modules/project-roles';
import { reducer as topCandidatesReducer } from './modules/top-candidates';
import { reducer as notificationReducer } from './modules/notifications';
import { reducer as submissionsReducer } from './modules/submissions';
import { reducer as auditionMessageReducer } from './modules/audition-messages';
import { reducer as audioClipReducer } from './modules/audio-clips';
import { reducer as agentsReducer } from './modules/agents';
import { reducer as managersReducer } from './modules/managers';
import { reducer as sharingReducer } from './modules/sharing';
import { reducer as offersReducer } from './modules/offers';
import { reducer as chatReducer } from './modules/chat';
import { reducer as globalMessagingThreadsReducer } from './modules/global-messages-threads';
import { reducer as globalMessagingReducer } from './modules/global-messages';

const persistWhitelist = ['auth', 'accounts'];

const persistConfig = {
  key: 'root',
  storage: new CookieStorage(Cookies),
  whitelist: persistWhitelist.filter(path => !path.includes('.')),
  transforms: [
    createTransform((inboundState, key) => {
      const nestedWhitelistState = persistWhitelist
        .filter(path => path.startsWith(`${key}.`))
        .map(path => path.substr(key.length + 1));

      return pick(inboundState, ...nestedWhitelistState);
    }, null),
  ],
};

/* keep auth first; other reducers must be alphabetically/logically ordered: parent resource reducer (auditions) precedes child resource reducer (auditionApplications) despite the alphabet */
const appReducer = combineReducers({
  core: coreReducer,
  auth: authReducer,
  accounts: accountReducer,
  agents: agentsReducer,
  assets: assetReducer,
  audio: audioReducer,
  audioClips: audioClipReducer,
  auditions: auditionReducer,
  auditionApplications: auditionApplicationReducer,
  auditionInvitations: auditionInvitationsReducer,
  auditionSuggestions: auditionSuggestionsReducer,
  auditionMessages: auditionMessageReducer,
  auditionsProducers: auditionsProducersReducer,
  auditionsTalents: auditionsTalentsReducer,
  chat: chatReducer,
  globalMessaging: globalMessagingReducer,
  globalMessagingThreads: globalMessagingThreadsReducer,
  lists: listsReducer,
  managers: managersReducer,
  notifications: notificationReducer,
  offers: offersReducer,
  pastRecordings: pastRecordingsReducer,
  producers: producerReducer,
  projects: projectsReducer,
  projectRoles: projectRolesReducer,
  publishers: publishersReducer,
  submissions: submissionsReducer,
  sharing: sharingReducer,
  talents: talentReducer,
  topCandidates: topCandidatesReducer,
});

const rootReducer = (state, action) => {
  if (state !== undefined) {
    // Temporary solution for 503 error
    if (
      state.core.serverError === true &&
      action.type === logout.type.success
    ) {
      const temporaryState = state;
      // eslint-disable-next-line
      state = undefined;
      // eslint-disable-next-line
      state = { core: (serverError = temporaryState.core.serverError) };

      return appReducer(state, action);
    }
  }

  if (action.type === logout.type.success) {
    // eslint-disable-next-line
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
  });

  const persistor = persistStore(store);

  const interceptor = FetchInterceptor.register({
    onBeforeRequest(request, controller) {},
    onRequestFailure(response, request, controller) {
      const { dispatch } = store;

      if (response.status === 503) {
        dispatch({ type: SET_SERVER_ERROR });
        dispatch(logout());
      }

      if (response.status === 401) {
        dispatch(logout());
      }
    },
    onRequestSuccess(response, request, controller) {},
  });

  return { store, persistor, interceptor };
};
