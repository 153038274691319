import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import get from 'lodash/get';
import map from 'lodash/map';
import compact from 'lodash/compact';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import Grid from '@material-ui/core/Grid/Grid';
import { InfoListItem, PageLoader, Section, SocialLinks } from 'modules/ui';
import {
  searchTalents,
  getVisibleTalentsOnAgentsProfile,
} from 'modules/talents';
import { BiographySection } from 'modules/producers';
import { PronounSection } from 'modules/producers';
import { AccountType } from 'modules/accounts';
import { Validator } from 'modules/core';
import ClientCard from './ClientCard';
import {
  loadAgentAssignments,
  loadAgentAssignmentsById,
  getAgentAssignments,
} from '../redux';
import { Agent } from '../services';
import classes from './AgentDetails.module.scss';

export default function AgentDetails({ agent, viewerAccountType, isAgentLoading }) {
  const dispatch = useDispatch();
  const { id } = agent;
  const agentAssignments = useSelector(getAgentAssignments);
  const visibleClients = useSelector(
    getVisibleTalentsOnAgentsProfile(agentAssignments),
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!viewerAccountType) {
      dispatch(loadAgentAssignments());
      dispatch(searchTalents({ agentId: id })).then(setLoading(false));
    }
    if (AccountType.isProducer(viewerAccountType)) {
      dispatch(loadAgentAssignmentsById(id));
      dispatch(searchTalents({ agentId: id })).then(setLoading(false));
    }
    if (AccountType.isTalent(viewerAccountType)) {
      dispatch(loadAgentAssignments()).then(setLoading(false));
    }
  }, [dispatch, id, viewerAccountType]);

  const {
    aboutAgency,
    biography,
    title,
    agencyName,
    agencyWebsite,
    city,
    country,
    state,
    pronouns,
  } = agent;

  const websiteLinks = pick(agent, ['websiteLink']);
  const socialLinks = pick(agent, Agent.SOCIAL_MEDIA_LINKS);
  const populatedLinks = pickBy(socialLinks);

  const location = compact([
    get(country, 'name'),
    get(state, 'name'),
    city,
  ]).join(', ');

  const sectionContentClass = classNames('body16', classes.sectionContent);

  const ClientCards = map(visibleClients, client => {
    const key = `${client.id}`;
    return <ClientCard key={key} talent={client} />;
  });

  if (!agent || loading || isAgentLoading) {
    return <PageLoader />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <BiographySection
          emptyBioNotice={
            viewerAccountType
              ? 'No Info.'
              : 'Give us a short info about you and your work.'
          }
          shortBio={biography}
        />
        <Section
          isEmpty={!aboutAgency}
          title="About Agency"
          placeholderText={
            viewerAccountType
              ? 'No Info.'
              : 'Tell us something about your agency.'
          }
        >
          <p className={sectionContentClass}>{aboutAgency}</p>
        </Section>
      </Grid>
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={3}>
        <Section
          title="Info"
          placeholderText={
            viewerAccountType ? 'No Info.' : 'Add your personal info.'
          }
          className="mb-60"
          isEmpty={isEmpty(
            omitBy({ ...location, ...websiteLinks, ...populatedLinks }, isNull),
          )}
        >
          <InfoListItem small title={title} />
          <InfoListItem small title={agencyName} icon="company" />
          <InfoListItem small title={location} icon="location" />
          <InfoListItem
            small
            title={agencyWebsite}
            icon="websiteLink"
            link={Validator.formatUrl(agencyWebsite)}
          />
          <SocialLinks links={populatedLinks} />
        </Section>
        <PronounSection
            title="Pronouns"
            pronouns={join(pronouns, ', ')}
          />
      </Grid>
      {viewerAccountType !== 'talent' && (
        <Grid item xs={12} md={12}>
          <Section title="Clients">
            <div className={classes.cardsContainer}>{ClientCards}</div>
          </Section>
        </Grid>
      )}
    </Grid>
  );
}

AgentDetails.propTypes = {
  agent: PropTypes.object.isRequired,
  viewerAccountType: PropTypes.string,
  isAgentLoading: PropTypes.bool
};
