import React, { memo, cloneElement, Children } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ICONS } from './icons';
import styles from './Icon.module.scss';

function Icon({ name, size, color, className, style, isFlippedVertical }) {
  const paths = _.get(ICONS[name], 'paths');
  const viewBox = _.get(ICONS[name], 'viewBox', '0 0 24 24');
  const svgFill = _.get(ICONS[name], 'fill', null);

  if (!paths) {
    return null;
  }

  const containerStyle = {
    width: `${size}px`,
    height: `${size}px`,
    ...style,
  };

  const classes = classNames(styles.container, className, {
    [styles.verticalFlippedIcon]: isFlippedVertical,
  });

  const renderChild = child => {
    const {
      props: { stroke, fill },
    } = child;

    return cloneElement(child, {
      fill: fill && color,
      stroke: stroke && color,
    });
  };

  return (
    <div className={classes} style={containerStyle}>
      <svg
        width="100%"
        height="100%"
        viewBox={viewBox}
        fill={svgFill}
        className={styles.icon}
      >
        {Children.map(paths, renderChild)}
      </svg>
    </div>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  isFlippedVertical: PropTypes.bool,
};

Icon.defaultProps = {
  size: 24,
  color: '#051528', // TODO colors
  isFlippedVertical: false,
};

export default memo(Icon);
