import reduce from 'lodash/reduce';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import Denormalizer from './denormalizer';

function modelToView(model) {
  const denormalizer = new Denormalizer(model);
  return denormalizer.denormalize();
}

function mapErrors(errors) {
  return reduce(
    errors,
    (result, error) => ({
      ...result,
      [error.code]: error,
    }),
    {},
  );
}

function viewToModel(view, relationships) {
  if (!view) {
    return null;
  }

  const { id, resourceType, ...attributes } = view;

  return {
    data: {
      id,
      type: resourceType,
      attributes,
      relationships,
    },
  };
}

// TODO: Will not be needed when we change to object map
function addOrReplace(array, item) {
  if (!isArray(array)) {
    return [item];
  }

  const newArray = [...array];
  const i = array.findIndex(arrayItem => arrayItem.id === item.id);

  if (i > -1) {
    newArray[i] = item;
  } else {
    newArray.push(item);
  }

  return newArray;
}

function addOrReplaceArray(array, itemArray) {
  if (!isArray(itemArray)) {
    return array;
  }

  let newArray = [...array];

  forEach(itemArray, item => (newArray = addOrReplace(newArray, item)));

  return newArray;
}

export default {
  addOrReplace,
  addOrReplaceArray,
  modelToView,
  viewToModel,
  mapErrors,
};
