import find from 'lodash/find';
import get from 'lodash/get';
import omit from 'lodash/omit';
import remove from 'lodash/remove';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import union from 'lodash/union';
import filter from 'lodash/filter';
import hasIn from 'lodash/hasIn';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import { createReducer, createAction } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import { Mapper } from 'modules/core';
import { Assets, getAsset, uploadAsset } from 'modules/assets';
import { resolveErrorMessage } from 'modules/errors';
import {
  fetchProjects,
  fetchAllProjects,
  fetchRecentlyViewedProjects,
  postRecentlyViewedProject,
  postProject,
  fetchProject,
  patchProject,
  removeProject,
  fetchProjectsStats,
} from './api';

const INITIAL_STATE = {
  sort: '-createdAt',
  ownerAvatar: null,
  covers: {},
  loadingCovers: [],
  loadingRecentlyViewed: false,
  loading: false,
  error: false,

  // TODO: make project object in state deprecated
  project: null,

  // TODO: turn arrays of data to object maps (AHAB-597)
  projects: [],
  recentlyViewedProjects: [],
  stats: [],

  // TODO: Move stats and recently viewed projects into separate modules (AHAB-596)
  loadingProjectsStats: false,
  filterData: null,
};

export const loadAllProjects = createThunk(
  fetchAllProjects,
  'loadAll',
  'projects',
);
export const loadProjects = createThunk(fetchProjects, 'load', 'projects');
export const loadProjectsStats = createThunk(
  fetchProjectsStats,
  'load-stats',
  'projects',
);
export function loadProjectsAndItsStats(producerId) {
  return async dispatch => {
    const { data: projects } = await dispatch(loadAllProjects(producerId));
    await dispatch(
      loadProjectsStats(producerId, filter(projects, item => get(item, 'id'))),
    );
  };
}
export const loadProject = createThunk(
  fetchProject,
  'load-project',
  'projects',
);
export const deleteProject = createThunk(
  removeProject,
  'delete-project',
  'projects',
);
export const loadCover = createThunk(getAsset, 'loadCover', 'projects');
export function loadCovers(coversArray, loadedCoversObj) {
  return dispatch => {
    const loadedCovers = Object.keys(loadedCoversObj);
    const uniqueCovers = filter(uniq(coversArray), el => {
      if (find(loadedCovers, el)) {
        return null;
      }
      return el;
    });
    const nonLoadedCovers = filter(uniqueCovers, null);

    forEach(nonLoadedCovers, cover => {
      dispatch(loadCover(cover));
    });
  };
}

export const loadRecentlyViewedProjects = createThunk(
  fetchRecentlyViewedProjects,
  'load-recently-viewed',
  'projects',
);
export const createRecentlyViewedProject = createThunk(
  postRecentlyViewedProject,
  'create-recently-viewed',
  'projects',
);
export const removeError = createAction('projects/removeError');
export const setSort = createThunk(
  sort => {
    return sort;
  },
  'set-sort',
  'projects',
);

export function uploadProjectImage(producerId, projectId, imageFile) {
  if (!producerId || !projectId) {
    return Promise.resolve();
  }

  return dispatch => {
    const context = Assets.createProjectContext(producerId, projectId);
    return dispatch(uploadAsset(imageFile, 'image', 'projectImage', context));
  };
}

const update = createThunk(patchProject, 'update', 'projects');
export function updateProject(producerId, projectId, values) {
  return dispatch => {
    const { coverImage } = values;

    if (coverImage) {
      return dispatch(
        uploadProjectImage(producerId, projectId, coverImage),
      ).then(assetPath => {
        const projectValues = { ...values, coverImage: assetPath };
        return dispatch(update(producerId, projectId, projectValues));
      });
    }

    return dispatch(update(producerId, projectId, values));
  };
}

const create = createThunk(postProject, 'create', 'projects');
export function createProject(producerId, values) {
  return dispatch => {
    const { coverImage } = values;

    if (coverImage) {
      return dispatch(create(producerId, omit(values, 'coverImage'))).then(
        project => {
          const newProjectId = get(project, 'data.id');

          return dispatch(
            uploadProjectImage(producerId, newProjectId, coverImage),
          ).then(assetPath => {
            const projectValues = { ...values, coverImage: assetPath };
            return dispatch(update(producerId, newProjectId, projectValues));
          });
        },
      );
    }

    return dispatch(create(producerId, values));
  };
}

export function initializeProjectsPage(producerId) {
  if (!producerId) {
    return Promise.resolve();
  }

  return dispatch => {
    const recentlyViewedCall = dispatch(loadRecentlyViewedProjects(producerId));
    const projectsCall = dispatch(loadAllProjects(producerId));

    return Promise.all([projectsCall, recentlyViewedCall]).then(responses => {
      const loadedProjects = get(responses, '[0].data');
      const loadedRecentlyViewed = get(responses, '[1].data');

      const projectsIds = map(loadedProjects, 'id');
      const recentlyViewedProjectsIds = map(
        loadedRecentlyViewed,
        recentlyViewedProject => get(recentlyViewedProject, 'project.id'),
      );

      return dispatch(
        loadProjectsStats(
          producerId,
          union(projectsIds, recentlyViewedProjectsIds),
        ),
      );
    });
  };
}

export const setProjectsFilterData = createAction('projects/setFilterData');
export const clearProjectsFilterData = createAction('projects/clearFilterData');

export const reducer = createReducer(INITIAL_STATE, {
  [loadAllProjects.type.request]: state => {
    state.loading = true;
  },
  [loadAllProjects.type.success]: (state, action) => {
    state.loading = false;
    state.projects = action.payload.data;
  },
  [loadProjects.type.error]: state => {
    state.loading = false;
  },
  [loadProjects.type.request]: state => {
    state.loading = true;
  },
  [loadProjects.type.success]: (state, action) => {
    const newProjects = action.payload.data;

    state.loading = false;
    state.projects = unionBy(newProjects, state.projects, 'id');
  },
  [loadProjects.type.error]: state => {
    state.loading = false;
  },
  [create.type.success]: (state, action) => {
    const newProject = action.payload.data;

    state.project = newProject;
    state.projects = [...state.projects, newProject];
  },
  [create.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [loadProject.type.success]: (state, action) => {
    const newProject = action.payload.data;

    state.loading = false;
    state.projects = Mapper.addOrReplace(state.projects, newProject);
    state.project = action.payload.data;
  },
  [update.type.request]: state => {
    state.error = false;
  },
  [update.type.success]: (state, action) => {
    const newProject = action.payload.data;

    state.projects = Mapper.addOrReplace(state.projects, newProject);
    state.project = newProject;
    state.error = false;
  },
  [update.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [deleteProject.type.success]: (state, action) => {
    const deletedProjectId = action.request[1];

    remove(state.projects, project => project.id === deletedProjectId);
    remove(
      state.recentlyViewedProjects,
      project => project.projectId === deletedProjectId,
    );

    state.project = null;
    state.error = false;
  },
  [setSort.type.success]: (state, action) => {
    state.sort = action.payload;
  },

  // Recently viewed projects
  // TODO: Add error ?
  [loadRecentlyViewedProjects.type.request]: state => {
    state.loadingRecentlyViewed = true;
  },
  [loadRecentlyViewedProjects.type.success]: (state, action) => {
    state.recentlyViewedProjects = action.payload.data;
    state.loadingRecentlyViewed = false;
  },
  [loadRecentlyViewedProjects.type.error]: state => {
    state.loadingRecentlyViewed = false;
  },
  [createRecentlyViewedProject.type.success]: (state, action) => {
    const newProject = action.payload.data;

    state.recentlyViewedProjects = [
      ...state.recentlyViewedProjects,
      newProject,
    ];
  },

  // Stats
  // TODO: error?
  [loadProjectsStats.type.request]: state => {
    state.loadingProjectsStats = true;
  },
  [loadProjectsStats.type.success]: (state, action) => {
    state.loadingProjectsStats = false;
    state.stats = action.payload.data;
  },
  [loadProjectsStats.type.error]: state => {
    state.loadingProjectsStats = false;
  },
  [removeError]: state => {
    state.error = false;
  },
  [setProjectsFilterData]: (state, action) => {
    state.filterData = { ...action.payload };
  },
  [clearProjectsFilterData]: state => {
    state.filterData = null;
  },
  [loadCover.type.request]: (state, action) => {
    state.loadingCovers = [...state.loadingCovers, action.request[0]];
  },
  [loadCover.type.success]: (state, action) => {
    if (!hasIn(state.covers, action.request[0])) {
      const requestUrl = action.request[0];
      const fileBlob = action.payload;
      state.covers = {
        ...state.covers,
        [requestUrl]: fileBlob,
      };
    }

    remove(state.loadingCovers, el => {
      return el === action.request[0];
    });
  },
  [loadCover.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
    remove(state.loadingCovers, el => {
      return el === action.request[0];
    });
  },
});

export const getSort = createSelector(['projects.sort']);
export const getProjects = createSelector(['projects.projects']);
export const getProjectsStats = createSelector(['projects.stats']);
export const getLoadingProjectsStats = createSelector([
  'projects.loadingProjectsStats',
]);
export const getProject = createSelector(['projects.project']);
export const getRecentlyViewedProjects = createSelector([
  'projects.recentlyViewedProjects',
]);
export const getLoading = createSelector(['projects.loading']);
export const getLoadingRecentlyViewed = createSelector([
  'projects.loadingRecentlyViewed',
]);
export const getError = createSelector(['projects.error']);

export const getProjectById = projectId =>
  createSelector(
    [getProjects, getProject],
    (stateProjects, stateProject) => {
      if (stateProject && stateProject.id === projectId) {
        return stateProject;
      }

      return find(stateProjects, { id: projectId });
    },
  );

export const getProjectsFilterData = createSelector(['projects.filterData']);

export const getProjectsWithActiveAuditions = createSelector(
  [getProjects, getProjectsStats],
  (projects, projectStats) =>
    filter(
      projects,
      project =>
        get(
          find(
            projectStats,
            projectStat => get(projectStat, 'projectId') === get(project, 'id'),
          ),
          'activeAuditionCount',
        ) > 0,
    ),
);

export const getCovers = createSelector(['projects.covers']);
export const getCoverImage = coverPath =>
  createSelector(
    [getCovers],
    stateCovers => get(stateCovers, coverPath),
  );
export const getLoadingCovers = createSelector(['projects.loadingCovers']);
