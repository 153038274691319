import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '../IconButton';
import classes from './DrawerHeader.module.scss';

function NavBarMobile({ drawerTitle, closeDrawer, rightHeaderComponent }) {
  return (
    <div className={classes.drawerHeader}>
      <div className={classes.backButton}>
        <IconButton
          onClick={closeDrawer}
          iconName="backArrow"
          iconColor="white"
          iconSize={16}
        />
      </div>
      <h3 className={classes.drawerTitle}>{drawerTitle}</h3>
      <div className={classes.rightComponent}>{rightHeaderComponent}</div>
    </div>
  );
}

NavBarMobile.propTypes = {
  drawerTitle: PropTypes.string.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

export default memo(NavBarMobile);
