import React, {
  useState,
  memo,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { Label } from '../Label';
import styles from './Textarea.module.scss';

const MAX_ROW_CHARS = 81;
const MAX_CHARS_MESSAGE = 'You have reached maximum character limit';

function getCharacterLength(value) {
  if (!_.isString(value)) {
    return 0;
  }

  return value.length;
}

const Textarea = forwardRef((props, ref) => {
  const {
    name,
    disabled,
    error,
    required,
    optional,
    label,
    setFormValue,
    onChange,
    maxChars,
    value,
    className,
    style,
    textareaStyle,
    textareaClassnames,
    handleMaxChars,
    ...otherProps
  } = props;

  useImperativeHandle(ref, () => ({
    reset,
    focus,
  }));

  const inputRef = useRef(null);
  const [text, setText] = useState(value || '');
  const [maxCharsReached, setMaxCharsReached] = useState(false);
  const hasLimit = maxChars !== 0;

  function reset() {
    setText('');
  }

  function focus() {
    inputRef.current.focus();
  }

  const containerClasses = classNames(
    styles.container,
    maxCharsReached && styles['container--error'],
    className,
  );

  const textareaClasses = classNames(
    styles.textarea,
    textareaClassnames,
    disabled && styles.disabled,
    error && styles.error,
  );

  function handleChange(event) {
    const textValue = event.target.value;
    const charCount = getCharacterLength(textValue);

    if (!hasLimit) {
      setText(textValue);

      if (setFormValue) {
        setFormValue(name, text);
      }

      if (onChange) {
        onChange(textValue);
      }
      return;
    }

    const maxCharsTyped = charCount > maxChars;
    const truncatedText = _.truncate(textValue, {
      length: maxChars,
      omission: '',
    });
    
    if (handleMaxChars) {
      handleMaxChars(maxCharsTyped);
    }
    setMaxCharsReached(maxCharsTyped);
    setText(truncatedText);

    if (setFormValue) {
      setFormValue(name, text);
    }

    if (onChange) {
      onChange(truncatedText);
    }
  }

  function handleBlur() {
    const isTouched = value !== text;
    if (setFormValue) {
      setFormValue(name, text, isTouched);
    }
  }

  const numberOfRows = Math.ceil(maxChars / MAX_ROW_CHARS);
  const charsLength = getCharacterLength(text);
  const charCounterText = `${charsLength}/${maxChars}`;
  const hasErrorMessage = typeof error === 'string';

  return (
    <div className={containerClasses} style={style}>
      <div className={styles.header}>
        <Label
          label={label}
          required={required}
          optional={optional}
          error={error}
          disabled={disabled}
        />
        {hasLimit && (
          <div className={styles.charCounter}>{charCounterText}</div>
        )}
      </div>
      <textarea
        ref={inputRef}
        name={name}
        className={textareaClasses}
        disabled={disabled}
        rows={numberOfRows}
        value={text}
        style={textareaStyle}
        {...otherProps}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasErrorMessage && <div className={styles.errorMessage}>{error}</div>}
      {maxCharsReached && (
        <div className={styles.errorMessage}>{MAX_CHARS_MESSAGE}</div>
      )}
    </div>
  );
});

Textarea.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  setFormValue: PropTypes.func,
  maxChars: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  textareaStyle: PropTypes.object,
  textareaClassnames: PropTypes.string,
  resetValue: PropTypes.func,
  onChange: PropTypes.func,
  handleMaxChars: PropTypes.func,
};

Textarea.defaultProps = {
  maxChars: 0,
};

export default memo(Textarea);
