import React from 'react';
import upperFirst from 'lodash/upperFirst';
import tensify from 'tensify';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Avatar } from 'modules/ui';
import styles from './AvatarDescription.module.scss';

export default function AvatarDescription({
  avatar,
  personName,
  verb,
  className,
  containerClassName,
  onClick,
}) {
  const pastParticiple = tensify(verb).past_participle;
  const action = upperFirst(pastParticiple);
  const textClasses = classNames(styles.subTitle, className);
  const containerClasses = classNames(styles.subContainer, containerClassName, {
    [styles['subContainer--clickable']]: onClick,
  });

  return (
    <div className={containerClasses} onClick={onClick} role="button">
      <Avatar size={25} photoUrl={avatar} />
      <div className={textClasses}>{`${action} By ${personName}`}</div>
    </div>
  );
}

AvatarDescription.propTypes = {
  avatar: PropTypes.string,
  personName: PropTypes.string,
  verb: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onClick: PropTypes.func,
};
