import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Section, InlineSection } from 'modules/ui';
import DownloadButton from './DownloadButton';

export default function AdditionalDetailsSection({ audition, ...otherProps }) {
  const notes = get(audition, 'additionalNotes');
  const additionalMediaUrl = get(audition, 'additionalMediaUrl');
  const hasAdditionalInfo = additionalMediaUrl || notes;

  if (!hasAdditionalInfo) {
    return null;
  }

  return (
    <Section title="Additional details" {...otherProps}>
      {additionalMediaUrl && (
        <DownloadButton primary fileUrl={additionalMediaUrl}>
          Download additional media
        </DownloadButton>
      )}
      {notes && (
        <InlineSection
          title="Additional notes"
          text={notes}
          className="mt-36"
        />
      )}
    </Section>
  );
}

AdditionalDetailsSection.propTypes = {
  audition: PropTypes.object,
};
