import { useEffect } from 'react';

const useBackBtnListener = onBackBtnClick => {
  useEffect(() => {
    window.onpopstate = () => {
      onBackBtnClick();
      window.onpopstate = () => {};
    };
    return onBackBtnClick;
  }, []);
};

export default useBackBtnListener;
