import orderBy from 'lodash/orderBy';

const SORT_OPTIONS = {
  NAME: 'name',
  DATE: 'createdAt',
};
const MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

function isNameTaken(listName, lists) {
  if (!listName) {
    return false;
  }
  return lists.map(list => list.name).includes(listName);
}

function validateName(listName, savedListName, lists) {
  const mode = savedListName === '' ? MODE.CREATE : MODE.EDIT;
  if (listName.length === 0) {
    return 'This field cannot be left blank.';
  }
  if (mode === MODE.EDIT && listName === savedListName) {
    return null;
  }
  if (isNameTaken(listName, lists)) {
    return 'List name already taken.';
  }
  return null;
}

function formatName(listName, maxLength) {
  if (listName.length < maxLength) {
    return listName;
  }
  return `${listName.substring(0, maxLength - 3).trimEnd()}...`;
}

function extractName(lists, listId) {
  return lists.find(list => list.id === listId).name;
}

function applySortOptions(lists, sortOption, sortOrder) {
  if (!sortOption) {
    return lists;
  }

  return orderBy(lists, [list => list[sortOption].toLowerCase()], sortOrder);
}

function filter(lists, prefix) {
  if (!prefix) {
    return lists;
  }
  return lists.filter(list =>
    list.name.toUpperCase().includes(prefix.toUpperCase()),
  );
}

export default {
  validateName,
  formatName,
  extractName,
  applySortOptions,
  filter,
  MODE,
  SORT_OPTIONS,
};
