import React, { Children, cloneElement, useState } from 'react';
import is from 'is_js';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Wizard } from '../Wizard';
import { MODAL_ID, MODAL_HEADER_HEIGHT } from '../FullScreenModal';

export default function FormWizard({
  error,
  children,
  onSubmit,
  onValueChange,
  onFinalSubmit,
  initialValues = {},
  ...otherProps
}) {
  const [values, setValues] = useState(initialValues);
  const [savedValues, setSavedValues] = useState({});
  const [formRef, setFormRef] = useState(null);

  function handleChangeValues(newValues) {
    const allValues = { ...values, ...newValues };
    setValues(allValues);

    if (onValueChange) {
      onValueChange(allValues);
    }
  }

  function validateForm() {
    const errors = formRef.validate(values);

    if (isEmpty(errors)) {
      return Promise.resolve();
    }

    return Promise.reject();
  }

  function submitForm(resolvedValues) {
    return formRef
      .submit(null, resolvedValues, savedValues)
      .then(auditionId => {
        setSavedValues(resolvedValues);
        return auditionId;
      });
  }

  function handleSubmit() {
    const resolvedValues = { ...values, isDraft: false };
    return submitForm(resolvedValues).then(result => onFinalSubmit(result));
  }

  function handleDraftSubmit() {
    const resolvedValues = { ...values, isDraft: true };
    return submitForm(resolvedValues).finally(() => Promise.reject());
  }

  function handleFormRef(ref) {
    setFormRef(ref);

    // react-responsive-modal's parent element is srolling element
    if (is.not.ie()) {
      document
        .getElementById(MODAL_ID)
        .parentElement.scrollTo(0, MODAL_HEADER_HEIGHT);
    }
  }

  const renderChild = child => {
    return cloneElement(child, {
      onSubmit,
      error,
      initialValues: values,
      handleRef: handleFormRef,
      showFooter: false,
      onChangeValues: handleChangeValues,
    });
  };

  return (
    <Wizard
      finalButtonLabel="Save"
      onSubmit={handleSubmit}
      onPageNavigation={validateForm}
      onPreviousPage={handleDraftSubmit}
      {...otherProps}
    >
      {Children.map(children, renderChild)}
    </Wizard>
  );
}

FormWizard.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  children: PropTypes.node,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onFinalSubmit: PropTypes.func,
  onValueChange: PropTypes.func,
};
