import { useEffect, useRef } from 'react';
import forEach from 'lodash/forEach';

const defaultEvents = ['mousedown', 'touchstart'];

function addEventListeners(obj, ...args) {
  return obj.addEventListener(...args);
}
function removeEventListeners(obj, ...args) {
  return obj.removeEventListener(...args);
}

const useClickAway = (ref, onClickAway, events = defaultEvents) => {
  const savedCallback = useRef(onClickAway);

  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);

  useEffect(() => {
    function handler(event) {
      const { current: el } = ref;

      if (el && !el.contains(event.target) && savedCallback.current) {
        savedCallback.current(event);
      }
    }

    forEach(events, eventName =>
      addEventListeners(document, eventName, handler),
    );

    return () => {
      forEach(events, eventName =>
        removeEventListeners(document, eventName, handler),
      );
    };
  }, [events, ref]);
};
export default useClickAway;
