import { Api } from 'modules/core';

export function fetchNDA(auditionId) {
  const endpoint = `auditions/${auditionId}/nda`;

  if (!auditionId) {
    return Promise.resolve(null);
  }

  return Api.find(endpoint);
}

export function postNDA(auditionId, url) {
  const endpoint = `auditions/${auditionId}/nda`;
  const nda = { resourceType: 'ndas', url };

  if (!auditionId || !url) {
    return Promise.resolve(null);
  }

  return Api.create(endpoint, nda);
}

export function deleteNDA(auditionId) {
  const endpoint = `auditions/${auditionId}/nda`;

  if (!auditionId) {
    return Promise.resolve(null);
  }

  return Api.deleteResource(endpoint);
}
