import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid/Grid';
import { LodashUtil } from 'modules/core';
import { Section, InlineSection } from 'modules/ui';
import { Project } from '../services';

export default function ProjectOverview({ project, sectionTitle }) {
  const title = LodashUtil.truncateText(get(project, 'title'), 33);
  const category = get(project, 'vertical');
  const description = get(project, 'description');
  const genre = get(project, 'genre');
  const length = get(project, 'length');
  const lengthType = lowerCase(get(project, 'lengthType'));
  const lengthTitle = `${length} ${lengthType}`;

  return (
    <Section title={sectionTitle}>
      <Grid container item spacing={2}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={4}>
            <InlineSection
              title="Title"
              isEmpty={isEmpty(title)}
              placeholderText="Title not provided."
            >
              <p className="body16">{title}</p>
            </InlineSection>
          </Grid>
          <Grid item xs={12} md={4}>
            <InlineSection
              title="Category"
              isEmpty={isEmpty(category)}
              placeholderText="Category not provided."
            >
              <p className="body16">{category}</p>
            </InlineSection>
          </Grid>
        </Grid>
        {Project.verticalHasGenres(category) && (
          <Grid item xs={12} md={4}>
            <InlineSection
              title="Genre"
              isEmpty={isEmpty(genre)}
              placeholderText="Genre not provided."
            >
              <p className="body16">{genre}</p>
            </InlineSection>
          </Grid>
        )}
        <Grid item xs={12}>
          <InlineSection
            title="Description"
            isEmpty={isEmpty(description)}
            placeholderText="Description not provided."
          >
            <p className="body16">{description}</p>
          </InlineSection>
        </Grid>
      </Grid>
    </Section>
  );
}

ProjectOverview.propTypes = {
  project: PropTypes.object,
  sectionTitle: PropTypes.string,
};
