import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer } from 'modules/ui';
import { Colors } from 'modules/theme';
import { IconButton } from '../IconButton';
import { Logo } from '../Logo';
import classes from './NavBarMobile.module.scss';

function NavBarMobile({
  activePageName,
  rightIconComponent,
  handleExpandDrawer,
  expanded,
  children,
  homePagePathname,
}) {
  const history = useHistory();
  const { location } = history;
  const isHomePage =
    location.pathname === '/' || location.pathname === homePagePathname;

  return (
    <>
      <div className={classes.navBar}>
        {isHomePage ? (
          <div className={classes.mobileLogo}>
            <Logo width={80} dark={false} />
          </div>
        ) : (
          <div className={classes.backButton}>
            <IconButton
              onClick={() => history.goBack()}
              iconName="backArrow"
              iconColor="white"
              iconSize={16}
            />
          </div>
        )}

        {!expanded && (
          <h3 className={classes.mobilePageTitle}>{activePageName}</h3>
        )}
        <div className={classes.mobileIconContainer}>
          <div className={classes.mobileIcon}>{rightIconComponent}</div>
          <IconButton
            iconSize={24}
            iconName={expanded ? 'close' : 'hamburger'}
            iconColor={Colors.white}
            iconClassName={classes.mobileIcon}
            onClick={() => handleExpandDrawer(!expanded)}
          />
        </div>
      </div>
      <Drawer
        anchor="top"
        open={expanded}
        onClose={() => handleExpandDrawer(false)}
        className={classes.drawer}
        paperClassName={classes.drawerPaper}
        containerClassName={classes.drawerContainer}
        showHeader={false}
      >
        {children}
        <div />
      </Drawer>
    </>
  );
}

NavBarMobile.propTypes = {
  activePageName: PropTypes.string.isRequired,
  rightIconComponent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpandDrawer: PropTypes.func.isRequired,
  homePagePathname: PropTypes.string.isRequired,
};

export default memo(NavBarMobile);
