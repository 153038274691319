import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Dictionary from 'modules/dictionary';
import map from 'lodash/map';
import {
  ManagedForm,
  AutoCompleteSelect,
  RadioGroup,
  Radio,
  MultiSelect,
} from 'modules/ui';
import AuditionTalentSkillsGrid from './form-grids/AuditionTalentSkillsGrid';
import Validator from '../services/validator';

export default function AuditionVoiceSkillsForm({ handleRef, ...otherProps }) {
  const formRef = useRef(null);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const refChanged = ref ? ref.current !== formRef.current : true;

    if (refChanged && handleRef) {
      handleRef(formRef.current);
      setRef(formRef);
    }
  }, [ref, handleRef]);

  return (
    <ManagedForm
      ref={formRef}
      title="Required Voice Skills"
      TemplateComponent={AuditionTalentSkillsGrid}
      validate={Validator.validateAuditionSkills}
      submitButtonLabel="Save talent skills"
      {...otherProps}
    >
      <RadioGroup label="Gender" name="requiredGender" required>
        {map(Dictionary.GENDER_REQUIREMENTS, value => (
          <Radio value={value} label={value} />
        ))}
      </RadioGroup>
      <MultiSelect
        required
        autoFocus
        label="Vocal Age Range"
        name="requiredVoiceAgeRanges"
        placeholder="Select age range"
        collection={Dictionary.VOICE_RANGES}
        options={Dictionary.VOICE_RANGE_OPTIONS}
      />
      <AutoCompleteSelect
        required
        isMultiSelect
        label="Primary Language"
        name="requiredPrimaryLanguages"
        placeholder="Select language"
        options={Dictionary.LANGUAGE_OPTIONS}
      />
      <AutoCompleteSelect
        isMultiSelect
        label="Language Fluencies"
        name="requiredForeignLanguages"
        placeholder="Select languages"
        collection={Dictionary.LANGUAGE}
        options={Dictionary.LANGUAGE_OPTIONS}
      />
      <AutoCompleteSelect
        isMultiSelect
        label="Accents"
        name="requiredAccents"
        placeholder="Select accent"
        collection={Dictionary.ACCENTS}
        options={Dictionary.ACCENT_OPTIONS}
      />
      <MultiSelect
        label="Character cultural background"
        name="requiredEthnicities"
        placeholder="Select cultural background"
        collection={Dictionary.ETHNICITIES}
        options={Dictionary.ETHNICITY_OPTIONS}
      />
      <AutoCompleteSelect
        label="Audiobook experience"
        name="requiredRecordingExperiences"
        placeholder="Select audiobook experience"
        collection={Dictionary.RECORDING_EXPERIENCES}
        options={Dictionary.RECORDING_EXPERIENCE_OPTIONS}
      />
    </ManagedForm>
  );
}

AuditionVoiceSkillsForm.propTypes = {
  handleRef: PropTypes.func,
};
