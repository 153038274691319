import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { getAccount } from 'modules/accounts';
import { ChatWindow } from 'modules/chat';
import {
  getThreadId,
  getGlobalMessagingThreadWith,
  createGlobalMessagingThread,
  markGlobalMessagingThreadUnread,
  getOppositeUser,
  loadGlobalMessagingThread,
} from 'modules/global-messages-threads';
import {
  loadGlobalMessagesWith,
  sendGlobalMessage,
  getMessages,
} from '../redux';

function GlobalChatWindow({
  className,
  oppositeAccountId,
  oppositeAccountType,
  options,
  ...otherProps
}) {
  const dispatch = useDispatch();

  const account = useSelector(getAccount);
  const accountId = get(account, 'id');

  const thread = useSelector(
    getGlobalMessagingThreadWith(oppositeAccountId, oppositeAccountType),
  );
  const threadId = getThreadId(thread);
  const oppositeAccount = useSelector(
    getOppositeUser(oppositeAccountId, oppositeAccountType),
  );

  const fullName = get(oppositeAccount, 'fullName');
  const profileImage = get(oppositeAccount, 'profileImage');

  const messages = useSelector(getMessages(threadId));
  const [loadingMessages, setLoadingMessages] = useState(true);

  useEffect(() => {
    dispatch(
      loadGlobalMessagesWith(accountId, oppositeAccountId, oppositeAccountType),
    ).then(() => setLoadingMessages(false));
  }, [dispatch, accountId, oppositeAccountId, oppositeAccountType]);

  function createMessagingThread() {
    if (thread) {
      return Promise.resolve(threadId);
    }

    return dispatch(
      createGlobalMessagingThread({
        [`${oppositeAccountType}AccountId`]: oppositeAccountId,
      }),
    ).then(({ data }) => getThreadId(data));
  }

  function handleSubmitClick({ text }) {
    return createMessagingThread().then(createdThreadId =>
      dispatch(sendGlobalMessage(createdThreadId, text)).then(() =>
        dispatch(
          markGlobalMessagingThreadUnread(createdThreadId, oppositeAccountId),
        ),
      ).then(() => dispatch(loadGlobalMessagingThread(accountId))),
    );
  }

  return (
    <ChatWindow
      className={className}
      headingTitle={fullName}
      photoUrl={profileImage}
      messages={messages}
      onSubmitClick={handleSubmitClick}
      isLoadingMessages={loadingMessages}
      enableSampleUploading={false}
      options={options}
      {...otherProps}
    />
  );
}

GlobalChatWindow.propTypes = {
  oppositeAccountId: PropTypes.string.isRequired,
  oppositeAccountType: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.node,
};

export default GlobalChatWindow;
