import get from 'lodash/get';
import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import find from 'lodash/find';

const MAX_DEPTH = 4;

export default class Denormalizer {
  constructor(model) {
    this.data = get(model, 'data');
    this.included = get(model, 'included');
    this.meta = get(model, 'meta');
  }

  denormalize = (data = this.data, checkIncluded = false) => {
    if (!data) {
      return null;
    }

    const denormalizedData = this.denormalizeData(data, checkIncluded);
    const payload = {
      data: denormalizedData,
      meta: this.meta,
    };

    return payload;
  };

  denormalizeData = (
    data = this.data,
    checkIncluded = false,
    numberOfCalls,
  ) => {
    if (!data) {
      return null;
    }

    if (isArray(data)) {
      return this.denormalizeArray(data, checkIncluded, numberOfCalls);
    }

    return this.denormalizeOne(data, checkIncluded, numberOfCalls);
  };

  denormalizeId = (data = this.data, key) => {
    if (!data) {
      return null;
    }

    if (isArray(data)) {
      return { [`${key}Ids`]: map(data, item => item.id) };
    }

    return { [`${key}Id`]: data.id };
  };

  denormalizeOne = (data, checkIncluded, numberOfCalls = 0) => {
    const { id, type, relationships, attributes, meta } = data;
    const newNumberOfCalls = numberOfCalls + 1;

    if (!checkIncluded && numberOfCalls <= MAX_DEPTH) {
      return {
        id,
        type,
        meta,
        ...attributes,
        ...this.denormalizeRelationships(relationships, newNumberOfCalls),
      };
    }

    const included = find(this.included, { id, type });
    if (included && numberOfCalls <= MAX_DEPTH) {
      return this.denormalizeOne(included, false, newNumberOfCalls);
    }

    return { type, id };
  };

  denormalizeArray = (dataArray, checkIncluded, numberOfCalls) => {
    return map(dataArray, item =>
      this.denormalizeOne(item, checkIncluded, numberOfCalls),
    );
  };

  denormalizeRelationships = (relationships, numberOfCalls) => {
    return reduce(
      relationships,
      (result, relationship, key) => {
        return {
          ...result,
          [key]: this.denormalizeData(relationship.data, true, numberOfCalls),
          ...this.denormalizeId(relationship.data, key),
        };
      },
      {},
    );
  };
}
