import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import Dictionary from 'modules/dictionary';
import {
  ManagedForm,
  TextInput,
  DatePicker,
  OptionGroup,
  LengthInput,
} from 'modules/ui';
import OptionUI from 'modules/ui/components/Option/OptionUI/OptionUI';
import Validator from '../services/validator';
import AuditionRecordingDetailsGrid from './form-grids/AuditionRecordingDetailsGrid';
import classes from './AuditionRecordingDetailsForm.module.scss';

const styles = {
  noLabel: {
    marginTop: 34,
  },
};

function onSiteIncluded(values) {
  return includes(values, Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO);
}

const OPTION_COMPONENTS = map(Dictionary.RECORDING_LOCATION_OPTIONS, option => {
  const key = `recording-${option.value}`;
  return <OptionUI key={key} {...option} />;
});

export default function AuditionRecordingDetailsForm({
  handleRef,
  initialValues,
  ...otherProps
}) {
  const recordingLocations = get(initialValues, 'recordingLocations');
  const formRef = useRef(null);
  const [ref, setRef] = useState(null);
  const [addressDisabled, setAddressDisabled] = useState(
    !onSiteIncluded(recordingLocations),
  );
  const defaultLength = {
    type: Dictionary.RECORDING_LENGTH_DURATION_TYPES.STUDIO_HOURS,
  };

  useEffect(() => {
    const refChanged = ref ? ref.current !== formRef.current : true;

    if (refChanged && handleRef) {
      handleRef(formRef.current);
      setRef(formRef);
    }
  }, [ref, handleRef]);

  const toggleAddressDisabled = values => {
    if (!onSiteIncluded(values)) {
      setAddressDisabled(true);
      return;
    }

    setAddressDisabled(false);
  };

  return (
    <ManagedForm
      ref={formRef}
      title="Recording Details"
      TemplateComponent={AuditionRecordingDetailsGrid}
      initialValues={initialValues}
      validate={Validator.validateAuditionRecordingDetails}
      submitButtonLabel="Save recording details"
      {...otherProps}
    >
      <DatePicker
        name="recordingStartDate"
        label="Recording window"
        placeholder="Select start date"
      />
      <DatePicker
        name="recordingEndDate"
        placeholder="Select end date"
        style={styles.noLabel}
        arrowClassName={classes.noLabel}
      />
      <LengthInput
        name="recordingLength"
        label="Recording duration"
        defaultValue={defaultLength}
        options={Dictionary.RECORDING_LENGTH_DURATION_TYPE_OPTIONS}
      />
      <OptionGroup
        name="recordingLocations"
        label="Recording location"
        onChange={toggleAddressDisabled}
      >
        {OPTION_COMPONENTS}
      </OptionGroup>
      {!addressDisabled && (
        <TextInput
          name="studioAddress"
          label="Studio location"
          placeholder="Enter studio address"
        />
      )}
    </ManagedForm>
  );
}

AuditionRecordingDetailsForm.propTypes = {
  handleRef: PropTypes.func,
  initialValues: PropTypes.object,
};
