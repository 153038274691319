import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ProducerSwitch } from 'modules/producers';
import { TalentSwitch } from 'modules/talents';
import { AgentSwitch } from 'modules/agents';
import { ProtectedRoute, ChangePasswordPage } from 'modules/auth';
import { Chat } from 'modules/chat';
import { AccountNavBar } from './components';
import {
  DeleteAccountPage,
  ChangeEmailPage,
  ChangedEmailVerificationPage,
} from './pages';
import { getAccountType } from './redux';
import { AccountType } from './services';

export default function AccountSwitch() {
  const accountType = useSelector(getAccountType);

  return (
    <>
      <Route path="/" render={() => <AccountNavBar />} />
      <Switch>
        <ProtectedRoute
          path="/verify-changed-email/:email"
          component={ChangedEmailVerificationPage}
        />
        <ProtectedRoute
          path="/settings/change-password"
          component={ChangePasswordPage}
        />
        <ProtectedRoute
          path="/settings/change-email"
          component={ChangeEmailPage}
        />
        <ProtectedRoute
          path="/settings/delete-account"
          component={DeleteAccountPage}
        />
        {AccountType.isProducer(accountType) && <ProducerSwitch />}
        {AccountType.isTalent(accountType) && <TalentSwitch />}
        {AccountType.isAgent(accountType) && <AgentSwitch />}
      </Switch>
      <Chat />
    </>
  );
}
