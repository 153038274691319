import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import includes from 'lodash/includes';
import classNames from 'classnames';
import Offer from '../services/offer';
import styles from './OfferStatusDisplay.module.scss';

const agentActorLabelStatuses = [
  Offer.RESOLVED_STATUSES_PRODUCER.SENT,
  Offer.RESOLVED_STATUSES_PRODUCER.NEGOTIATING,
];

export default function OfferStatusDisplay({
  resolvedStatus,
  hasAgent,
  showHasAgent,
  statusClassName,
}) {
  const className = classNames(
    styles.status,
    styles[`status--${resolvedStatus}`],
    statusClassName,
  );

  return (
    <div className={className}>
      {startCase(resolvedStatus)}
      {showHasAgent &&
        (includes(agentActorLabelStatuses, resolvedStatus) &&
          (hasAgent ? ' (Agent)' : ' (Actor)'))}
    </div>
  );
}

OfferStatusDisplay.propTypes = {
  resolvedStatus: PropTypes.string.isRequired,
  hasAgent: PropTypes.bool,
  showHasAgent: PropTypes.bool.isRequired,
  statusClassName: PropTypes.string,
};
