import React from 'react';
import PropTypes from 'prop-types';
import { Collapsible, IconButton, CheckboxInput } from 'modules/ui';
import { Colors } from 'modules/theme';
import styles from './SearchForm.module.scss';

export default function StudioCapabilitesSearchForm({
  handleSetFormValue,
  getValue,
  toggleStudioCapabilitiesFormOpen,
  studioCapabilitiesFormOpen,
}) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Studio Capabilities</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleStudioCapabilitiesFormOpen}
        />
      </div>
      <Collapsible hideButton collapsed={!studioCapabilitiesFormOpen}>
        <CheckboxInput
          name="ownsHomestudio"
          text="Home studio"
          value={getValue('ownsHomestudio')}
          setFormValue={handleSetFormValue}
          containerClassName={styles.searchFormCheckboxInput}
        />
        <CheckboxInput
          name="homeStudioEditing"
          text="Home studio editing"
          value={getValue('homeStudioEditing')}
          setFormValue={handleSetFormValue}
          containerClassName={styles.searchFormCheckboxInput}
        />
      </Collapsible>
    </div>
  );
}

StudioCapabilitesSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleStudioCapabilitiesFormOpen: PropTypes.func.isRequired,
  studioCapabilitiesFormOpen: PropTypes.func.isRequired,
};
