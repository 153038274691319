import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import get from 'lodash/get';

import { getAccountType } from 'modules/accounts';
import { closeAllChatWindows, MessagingMenu } from 'modules/chat';
import { EmptyPlaceholder, ConfirmationDialog, TextButton } from 'modules/ui';
import { loadUnreadMessagesCount } from 'modules/global-messages';

import { GlobalMessagingCard } from '../components';
import {
  getGlobalMessagingThreadsSortedByLatestMessages,
  searchGlobalMessagingThreads,
  clearSearchedGlobalMessagingThreads,
  getSearchedThreads,
  getLatestGlobalMessagingThread,
  getLoadingThreads,
  markAllMessagesAsRead,
} from '../redux';
import { getGlobalMessageThreadOppositeAccountTypeAndId } from '../services';
import classes from './GlobalMessagingMenu.module.scss';

function GlobalMessagingMenu({
  loadGlobalMessagingThreads,
  onCardClick,
  onClose,
  isResponsiveDrawer,
  onLoadMoreClick,
  onFilterByUnreadClick,
  accountId,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingThreads, setLoadingThreads] = useState(true);
  const [filteredByUnread, setFilteredByUnread] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const isLoadingMore = useSelector(getLoadingThreads);
  const globalMessagingThreads = useSelector(
    getGlobalMessagingThreadsSortedByLatestMessages,
  );
  const searchedThreads = useSelector(getSearchedThreads);

  const latestThread = useSelector(getLatestGlobalMessagingThread);

  const displayableThreads =
    searchedThreads.length > 0 || filteredByUnread
      ? searchedThreads
      : globalMessagingThreads;

  const gotAllMessages =
    displayableThreads.length > 0 ? displayableThreads.length % 20 !== 0 : true;

  const allMessagesAreNotRead = some(globalMessagingThreads, thread => {
    const unreadMessageAccountId = get(thread, 'unreadMessageAccountId');
    return unreadMessageAccountId === accountId;
  });

  const accountType = useSelector(getAccountType);

  useEffect(() => {
    dispatch(loadGlobalMessagingThreads).then(() => {
      setLoadingThreads(false);
    });
  }, [dispatch, loadGlobalMessagingThreads]);

  useEffect(() => {
    if (!searchValue && !filteredByUnread) {
      dispatch(clearSearchedGlobalMessagingThreads());
    }
  }, [dispatch, searchValue, filteredByUnread]);

  function handleSearch(searchQuery) {
    setSearchValue(searchQuery);

    if (isEmpty(searchQuery)) {
      if (filteredByUnread) {
        setLoadingThreads(true);

        return onFilterByUnreadClick().then(() => {
          setLoadingThreads(false);
        });
      }

      return dispatch(loadGlobalMessagingThreads).then(() => {
        setLoadingThreads(false);
        dispatch(clearSearchedGlobalMessagingThreads());
      });
    }

    setLoadingThreads(true);

    return dispatch(
      searchGlobalMessagingThreads(searchQuery, accountId, filteredByUnread),
    ).then(() => {
      setLoadingThreads(false);
    });
  }

  function handleSeeAllClick() {
    dispatch(closeAllChatWindows());

    if (!latestThread) {
      return history.push('/messaging/global');
    }

    const {
      type: oppositeAccountType,
      account: oppositeAccount,
    } = getGlobalMessageThreadOppositeAccountTypeAndId(
      latestThread,
      accountType,
    );

    return history.push(
      `/messaging/global/${oppositeAccountType}/${oppositeAccount.id}`,
    );
  }

  function handleViewAllClick() {
    setLoadingThreads(true);

    if (searchValue) {
      return dispatch(searchGlobalMessagingThreads(searchValue)).then(() => {
        setFilteredByUnread(false);
        setLoadingThreads(false);
      });
    }

    return dispatch(loadGlobalMessagingThreads).then(() => {
      setFilteredByUnread(false);
      dispatch(clearSearchedGlobalMessagingThreads());
      setLoadingThreads(false);
    });
  }

  function handleFilterByUnread() {
    setLoadingThreads(true);

    if (!isEmpty(searchedThreads)) {
      return dispatch(
        searchGlobalMessagingThreads(searchValue, accountId, true),
      ).then(() => {
        setFilteredByUnread(true);
        setLoadingThreads(false);
      });
    }
    return onFilterByUnreadClick().then(() => {
      setFilteredByUnread(true);
      setLoadingThreads(false);
    });
  }

  function handleMarkAllAsRead() {
    setLoadingThreads(true);
    setShowConfirmationDialog(false);

    dispatch(markAllMessagesAsRead()).then(() => {
      dispatch(loadUnreadMessagesCount());
      if (searchValue) {
        return dispatch(
          searchGlobalMessagingThreads(
            searchValue,
            filteredByUnread,
            accountId,
          ).then(() => {
            setLoadingThreads(false);
          }),
        );
      }
      if (filteredByUnread) {
        return onFilterByUnreadClick().then(() => {
          setLoadingThreads(false);
        });
      }

      return dispatch(loadGlobalMessagingThreads).then(() => {
        setLoadingThreads(false);
      });
    });
  }

  const noUnreadPlaceholder = filteredByUnread ? (
    <EmptyPlaceholder
      title="No unread messages"
      containerClassName={classes.emptyPlaceholderContainer}
      description={
        <div className={classes.viewAllContainer}>
          <div className={classes.rowContainer}>
            Click 
            <TextButton
              title="View all"
              isPlainText
              isInlineWithText
              onClick={handleViewAllClick}
            />
            to view all of your 
          </div>
          messages 
        </div>}
    />
  ) : null;

  return (
    <>
      {showConfirmationDialog && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure you want to mark all messages as read?"
          actionBtnLabel="Yes"
          cancelBtnLabel="Cancel"
          onCancel={() => {
            setShowConfirmationDialog(false);
          }}
          onAction={handleMarkAllAsRead}
        />
      )}
      <MessagingMenu
        isLoading={loadingThreads}
        searchPlaceholder="Search messages by name"
        onSearch={handleSearch}
        onSeeAllClick={handleSeeAllClick}
        isResponsiveDrawer={isResponsiveDrawer}
        onClose={onClose}
        loadMoreDisabled={gotAllMessages}
        onLoadMoreClick={onLoadMoreClick}
        isLoadingMore={isLoadingMore}
        onFilterByUnreadClick={handleFilterByUnread}
        onViewAllClick={handleViewAllClick}
        isFilterByUnreadActive={filteredByUnread}
        cardsPlaceholder={noUnreadPlaceholder}
        onMarkAllAsReadClick={() => {
          setShowConfirmationDialog(true);
        }}
        showMarkAllButton={allMessagesAreNotRead}
        {...otherProps}
      >
        {displayableThreads.map(thread => (
          <GlobalMessagingCard
            key={thread.id}
            thread={thread}
            onClick={onCardClick}
            onClose={onClose}
          />
        ))}
      </MessagingMenu>
    </>
  );
}

GlobalMessagingMenu.propTypes = {
  loadGlobalMessagingThreads: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isResponsiveDrawer: PropTypes.bool,
  onLoadMoreClick: PropTypes.func.isRequired,
  onFilterByUnreadClick: PropTypes.func.isRequired,
  accountId: PropTypes.string,
};

export default GlobalMessagingMenu;

