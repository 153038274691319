import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import Grid from '@material-ui/core/Grid';
import { getAuditionsLoading } from 'modules/auditions-producers';
import {
  Page,
  PageHeader,
  AvatarHeader,
  EmptyPlaceholder,
  PageLoader,
  Button,
  InfoBanner,
  InfoListItem,
  Section, 
} from 'modules/ui';
import {
  useTealiumView,
  getAccountType,
  ACCOUNT_TYPES,
} from 'modules/accounts';
import { DictionaryUtil } from 'modules/dictionary';
import { AccountType } from 'modules/accounts/services';
import { AuditionPreviewModal } from 'modules/auditions/components';
import { LetterIcon } from 'modules/chat';
import { openGlobalMessagingChatPopup } from 'modules/global-messages-threads';
import {
  getProducer,
  getActiveAuditionsByProducerId,
  getProducerStats,
  loadProducer,
  loadProducerAuditions,
  loadProducerStats,
} from '../redux';
import {
  AuditionsSection,
  BiographySection,
  SocialLinksSection,
  PronounSection,
} from '../components';
import { Producer } from '../services';
import ProducerStatsSection from '../components/ProducerStatsSection';
import classes from './ProfilePage.module.scss';

const customStyles = {
  placeholder: {
    marginTop: '54px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 100,
  },
  infoSection: {
    height: 70,
  },
};

const PRODUCER_MESSAGES = {
  placeholderText:
    'Add profile details so actors can have a better overview of who is hiring them.',
  biographySection: "You haven't added a biography yet.",
  socialLinksSection: "You haven't added any profile details.",
  emptyActiveAuditionsText: 'You have no active auditions at the moment',
};

const TALENT_MESSAGES = {
  placeholderText: "Content creator hasn't added any profile details",
  biographySection: "Content creator hasn't added a biography yet.",
  socialLinksSection: "Content creator hasn't added any profile details.",
  emptyActiveAuditionsText:
    'Content creator has no active auditions at the moment',
};

export default function ProfilePage({ history, match }) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const auditionsLoading = useSelector(getAuditionsLoading);

  const [isLoading, setIsLoading] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [currentAudition, setCurrentAudition] = useState(null);

  const producerAvatar = get(producer, 'data.profileImage');
  const producerId = get(match, 'params.producerId') || get(producer, 'id');
  const auditions = useSelector(getActiveAuditionsByProducerId(producerId));

  const accountType = useSelector(getAccountType);
  const isProducer = AccountType.isProducer(accountType);

  const producerStats = useSelector(getProducerStats);

  useTealiumView('Profile Page');

  useEffect(() => {
    setIsLoading(true);

    if (!producerId) {
      return;
    }

    const producerCall = dispatch(loadProducer(producerId));
    const producerAuditionsCall = dispatch(
      loadProducerAuditions({ producerId }, 1000),
    );
    const statsCall = dispatch(loadProducerStats(producerId));

    Promise.all([producerCall, producerAuditionsCall, statsCall]).then(() =>
      setIsLoading(false),
    );
  }, [dispatch, producerId]);

  function handleProfileEditClick() {
    history.push('profile/edit');
  }

  function handleAuditionClick(audition) {
    const { id } = audition;
    if (isProducer) {
      setPreviewModalOpen(true);
      return setCurrentAudition(audition);
    }
    return history.push(`/auditions/${id}`);
  }

  function handleSendMessage() {
    dispatch(
      openGlobalMessagingChatPopup(producer.accountId, ACCOUNT_TYPES.PRODUCER),
    );
  }

  const HeaderComponent = (
    <PageHeader
      large
      actionClassName="mb-60"
      LeftComponent={
        <AvatarHeader avatar={producerAvatar} person={producer} size={158} />
      }
    >
      {isProducer && (
        <Button primary onClick={handleProfileEditClick}>
          Edit profile
        </Button>
      )}
      {(AccountType.isAgent(accountType) || AccountType.isTalent(accountType)) &&(
        <Button
          gray
          className={classes.sendMessageButton}
          onClick={handleSendMessage}
        >
          <LetterIcon />
          Message
        </Button>
      )}
    </PageHeader>
  );
  const hasProfileDetails = Producer.hasProfileInfo(producer);
  const showPlaceholder = !hasProfileDetails && !auditionsLoading;

  const activeOpenAuditionsCount = get(
    producerStats,
    'activeOpenCallAuditionsCount',
  );
  const activePrivateAuditionsCount = get(
    producerStats,
    'activePrivateAuditionsCount',
  );

  const messages = isProducer ? PRODUCER_MESSAGES : TALENT_MESSAGES;

  const personalInfo = {
    location: DictionaryUtil.getLocationString(
      get(producer, 'country'),
      get(producer, 'state'),
    ),
    city: get(producer, 'city'),
    pronouns: get(producer, 'pronouns'),
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      HeaderComponent={HeaderComponent}
      BannerComponent={
        isProducer && (
          <InfoBanner text="This is how your profile looks to talent." />
        )
      }
    >
      {showPlaceholder && (
        <EmptyPlaceholder
          style={customStyles.placeholder}
          title={messages.placeholderText}
        />
      )}
      {hasProfileDetails && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <BiographySection
              emptyBioNotice={messages.biographySection}
              shortBio={get(producer, 'shortBio')}
            />
          </Grid>
          <Grid item xs={0} md={1} />
          <Grid item xs={12} md={3}>
            <Section
              title="Info"
              placeholderText="Add your personal info."
              className="mb-60"
              isEmpty={isEmpty(filter(personalInfo, info => info))}
              style={customStyles.infoSection}
            >
              <InfoListItem
                small
                title={personalInfo.location}
                subtitle={personalInfo.city}
                icon="location"
              />
            </Section>
            <PronounSection
              title="Pronouns"
              pronouns={join(personalInfo.pronouns, ', ')}
            />
            <SocialLinksSection
              showTitle={false}
              emptyLinksNotice={messages.socialLinksSection}
              person={producer}
            />
            <ProducerStatsSection
              openAuditions={activeOpenAuditionsCount}
              privateAuditions={activePrivateAuditionsCount}
            />
          </Grid>
          <Grid item xs={12}>
            <AuditionsSection
              loading={auditionsLoading}
              activeAuditions={auditions}
              onAuditionClick={handleAuditionClick}
              placeholderText={messages.emptyActiveAuditionsText}
            />
          </Grid>
        </Grid>
      )}
      {previewModalOpen && (
        <AuditionPreviewModal
          audition={currentAudition}
          onClose={() => setPreviewModalOpen(false)}
          open={previewModalOpen}
        />
      )}
    </Page>
  );
}

ProfilePage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
