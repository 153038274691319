import React, { memo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Button,
  DropdownButton,
  MenuItem,
  PageHeader,
  AvatarHeader,
  HorizontalMenu,
  PageLoader,
} from 'modules/ui';
import { getProducer } from 'modules/producers';
import { getTalentById, loadTalent } from 'modules/talents';
import { ACCOUNT_TYPES, AccountType, getAccountType } from 'modules/accounts';
import { showNotification, Notification } from 'modules/notifications';
import { getAsset } from 'modules/assets';
import { InviteTalentFromSearchDialog } from 'modules/auditions-invitations';
import { getAddTalentInProgress, AddTalentDialog } from 'modules/lists';
import { LetterIcon } from 'modules/chat';
import { openGlobalMessagingChatPopup } from 'modules/global-messages-threads';

import { Talent } from '../services';

import classes from './TalentPreviewHeader.module.scss';

function TalentPreviewHeader({
  talentId,
  tabs,
  tabIndex,
  showInvite,
  onTabChange,
}) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const talent = useSelector(getTalentById(talentId));
  const producer = useSelector(getProducer);
  const addInProgress = useSelector(getAddTalentInProgress);
  const accountType = useSelector(getAccountType);

  const profileLink = Talent.getProfileLink(talent);
  const talentAccountId = get(talent, 'accountId');
  const profileImage = get(talent, 'profileImage');
  const producerId = get(producer, 'id');

  const [avatar, setAvatar] = useState(null);
  const [addTalentToListDialogOpen, setAddTalentToListDialogOpen] = useState(
    false,
  );
  const [inviteTalentDialogOpen, setInviteTalentDialogOpen] = useState(false);

  useEffect(() => {
    getAsset(profileImage).then(url => setAvatar(url));
  }, [profileImage]);

  useEffect(() => {
    if (!talent) {
      dispatch(loadTalent(talentId));
    }
  }, [talentId]);

  function handleInviteToAudition() {
    setInviteTalentDialogOpen(true);
  }

  function handleSendMessage() {
    dispatch(
      openGlobalMessagingChatPopup(talentAccountId, ACCOUNT_TYPES.TALENT),
    );
  }

  function handleAddToListClick() {
    setAddTalentToListDialogOpen(true);
  }

  function handleAddTalentToListCancel() {
    setAddTalentToListDialogOpen(false);
  }

  function handleShareProfileClick() {
    global.utag.link({ event_type: 'share_profile' });

    return dispatch(
      showNotification({
        message: 'Profile link copied',
        type: Notification.TYPES.SUCCESS,
      }),
    );
  }

  function handleInviteTalentToAuditionCancel() {
    setInviteTalentDialogOpen(false);
  }

  if (!talent) {
    return <PageLoader />;
  }

  return (
    <>
      <PageHeader
        large
        actionClassName="mb-60"
        LeftComponent={
          <AvatarHeader person={talent} avatar={avatar} size={152} />
        }
        MenuComponent={
          <HorizontalMenu
            tabs={tabs}
            onTabChange={onTabChange}
            initialTabIndex={tabIndex}
            light
          />
        }
      >
        {showInvite && AccountType.isProducer(accountType) && (
          <Button primary onClick={handleInviteToAudition}>
            Invite to audition
          </Button>
        )}
        {AccountType.isProducer(accountType) && (
          <Button
            gray
            className={classes.sendMessageButton}
            onClick={handleSendMessage}
          >
            <LetterIcon />
            Message
          </Button>
        )}
        <DropdownButton
          header
          loading={addInProgress}
          arrowPosition="15px"
          ref={dropdownRef}
        >
          <MenuItem
            text="Add to list"
            icon="edit"
            onClick={handleAddToListClick}
          />
          <CopyToClipboard text={profileLink} onCopy={handleShareProfileClick}>
            <MenuItem
              text="Share profile"
              icon="share"
              onClick={handleShareProfileClick}
            />
          </CopyToClipboard>
        </DropdownButton>
      </PageHeader>
      {addTalentToListDialogOpen && (
        <AddTalentDialog
          producerId={producerId}
          talentId={talentId}
          onCancel={handleAddTalentToListCancel}
        />
      )}
      {inviteTalentDialogOpen && (
        <InviteTalentFromSearchDialog
          onClose={handleInviteTalentToAuditionCancel}
          talent={talent}
          onCancel={handleInviteTalentToAuditionCancel}
        />
      )}
    </>
  );
}

TalentPreviewHeader.propTypes = {
  talentId: PropTypes.string,
  showInvite: PropTypes.bool,
  tabs: PropTypes.array,
  tabIndex: PropTypes.number,
  onTabChange: PropTypes.func,
};

TalentPreviewHeader.defaultProps = {
  showInvite: true,
};

export default memo(TalentPreviewHeader);
