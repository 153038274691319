import React, { memo } from 'react';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactUtil } from 'modules/core';
import { IconButton } from '../IconButton';
import styles from './FullScreenModal.module.scss';

export const MODAL_ID = 'MODAL_ID';
export const MODAL_HEADER_HEIGHT = 64;

const classes = windowHeight => {
  return {
    modal: classNames(
      styles.modal,
      windowHeight && styles['modal--windowHeight'],
    ),
    closeIcon: styles['modal__close-icon--default'],
    overlay: styles.overlay,
  };
};

function FullScreenModal({
  open,
  withHeader,
  title,
  children,
  onClose,
  windowHeight,
  className,
  LeftComponent,
  HeaderComponent,
  hideCloseIcon,
}) {
  const contentClasses = classNames(
    styles.modal__content,
    className,
    windowHeight && styles['modal__content--windowHeight'],
  );
  const modalClassNames = classes(windowHeight);
  const iconButtonClassName = hideCloseIcon
    ? styles['modal__close-icon--hidden']
    : styles['modal__close-icon'];

  return (
    <Modal
      classNames={modalClassNames}
      open={open}
      onClose={onClose}
      modalId={MODAL_ID}
    >
      {withHeader && (
        <div className={styles.modal__header}>
          <div className={styles['modal__left-container']}>
            {ReactUtil.resolveComponent(LeftComponent)}
          </div>
          {title}
          <IconButton
            small
            className={iconButtonClassName}
            iconName="close"
            iconColor="white"
            iconSize={32}
            onClick={onClose}
            disabled={!onClose}
          />
        </div>
      )}
      <div className={styles.header}>
        {HeaderComponent && ReactUtil.resolveComponent(HeaderComponent)}
      </div>
      <div className={contentClasses}>{children}</div>
    </Modal>
  );
}

FullScreenModal.propTypes = {
  open: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  withHeader: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  height: PropTypes.number,
  className: PropTypes.string,
  LeftComponent: PropTypes.node,
};

FullScreenModal.defaultProps = {
  withHeader: true,
  hideCloseIcon: false,
};

export default memo(FullScreenModal);
