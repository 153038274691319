import { createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import get from 'lodash/get';
import { Assets, uploadAssetActions } from 'modules/assets';
import { resolveErrorMessage } from 'modules/errors';
import { postAudioClip } from './api';

const INITIAL_STATE = {
  clips: {},
  loading: false,
  submitError: null,
};

// S3 upload
function uploadAudioClip(auditionId, applicationId, messageId, file) {
  const context = Assets.createAuditionMessageContext(
    auditionId,
    applicationId,
    messageId,
  );

  return uploadAssetActions(file, 'audio', 'auditionAudioClip', context);
}

function postClip(auditionId, applicationId, messageId, values) {
  const file = get(values, 'file');

  if (!file) {
    return Promise.resolve();
  }

  // S3 upload, returns finalAssetPath
  return uploadAudioClip(auditionId, applicationId, messageId, file).then(
    assetPath => {
      const title = get(values, 'details.name');
      const clip = { title, url: assetPath };

      return postAudioClip(auditionId, applicationId, messageId, clip); // DB posting
    },
  );
}

export const createAudioClip = createThunk(postClip, 'create', 'audioClips');

export const reducer = createReducer(INITIAL_STATE, {
  [createAudioClip.type.success]: (state, action) => {
    const auditionId = action.request[0];
    const newAudio = action.payload.data;

    if (state.clips[auditionId]) {
      state.clips[auditionId] = [newAudio, ...state.clips[auditionId]];
    } else {
      state.clips[auditionId] = [newAudio];
    }
    state.submitError = false;
  },
  [createAudioClip.type.error]: (state, action) => {
    state.submitError = resolveErrorMessage(action.error);
  },
});

export const getSubmitError = createSelector(['audioClips.submitError']);
