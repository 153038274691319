import find from 'lodash/find';
import get from 'lodash/get';
import Dictionary from 'modules/dictionary';
import { OptionUtil } from 'modules/ui';
import { AUDITION_STATUSES } from '../const';

const STATUSES = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  DRAFT: 'Draft',
  ALL: 'All',
};

const TYPES = {
  OPEN_CALL: 'Open Call',
  PRIVATE: 'Private Audition',
};

const SORT_OPTIONS = {
  NAME: 'name',
  DUE_DATE: 'dueDate',
};

const ACTIONS = {
  DELETE: 'delete',
  RUN: 'run',
  STOP: 'stop',
};

function hasAuditionEnded(audition) {
  const dueDate = get(audition, 'dueDate');
  return dueDate && new Date(dueDate) < new Date();
}

function getInactiveAuditionLabel(audition) {
  if (hasAuditionEnded(audition)) {
    return 'Finished';
  }
  return 'Stopped';
}

function getAuditionStatus(audition) {
  if (!audition) {
    return 'No audition';
  }

  const hasStarted = get(audition, 'hasStarted');
  const draft = get(audition, 'isDraft');
  const hasEnded = hasAuditionEnded(audition);

  if (draft) {
    return STATUSES.DRAFT;
  }

  if (!hasStarted || hasEnded) {
    return STATUSES.INACTIVE;
  }

  return STATUSES.ACTIVE;
}

function getAuditionType(audition) {
  if (!audition) {
    return 'No audition';
  }

  return audition.type === 'openCall' ? TYPES.OPEN_CALL : TYPES.PRIVATE;
}

function getAuditionProject(audition) {
  return get(get(audition, 'projectRole'), 'project');
}

function isAuditionActive(audition) {
  return getAuditionStatus(audition) === STATUSES.ACTIVE;
}

function resolveAuditionTalentStatus(
  auditionId,
  savedAuditions,
  invitedAuditions,
  appliedAuditions,
) {
  const appliedAudition = find(appliedAuditions, { id: auditionId });
  if (appliedAudition) {
    return AUDITION_STATUSES.APPLIED;
  }

  const invitedAudition = find(invitedAuditions, { id: auditionId });
  if (invitedAudition) {
    return AUDITION_STATUSES.INVITED;
  }

  const savedAudition = find(savedAuditions, { id: auditionId });
  if (savedAudition) {
    return AUDITION_STATUSES.SAVED;
  }

  return null;
}

function createEmptyAudition() {
  return { type: Dictionary.AUDITION_TYPES.OPEN_CALL };
}

function createAuditionOptions(auditions) {
  return OptionUtil.getOptions(auditions, OptionUtil.mapOptionFromModel);
}

function extractRecordingDetails(audition) {
  if (!audition) {
    return null;
  }

  const {
    recordingStartDate,
    recordingEndDate,
    recordingLength,
    recordingLocations,
    studioAddress,
  } = audition;

  return {
    recordingStartDate,
    recordingEndDate,
    recordingLength,
    recordingLocations,
    studioAddress,
  };
}

function aggregateByAuditionStatuses(auditions) {
  const { ACTIVE, DRAFT, INACTIVE } = STATUSES;

  return {
    [ACTIVE]: auditions.filter(
      audition => getAuditionStatus(audition) === ACTIVE,
    ),
    [INACTIVE]: auditions.filter(
      audition => getAuditionStatus(audition) === INACTIVE,
    ),
    [DRAFT]: auditions.filter(
      audition => getAuditionStatus(audition) === DRAFT,
    ),
  };
}

export default {
  getAuditionStatus,
  getAuditionType,
  getAuditionProject,
  STATUSES,
  TYPES,
  SORT_OPTIONS,
  ACTIONS,
  resolveAuditionTalentStatus,
  hasAuditionEnded,
  createEmptyAudition,
  createAuditionOptions,
  getInactiveAuditionLabel,
  extractRecordingDetails,
  isAuditionActive,
  aggregateByAuditionStatuses,
};
