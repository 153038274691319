import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getAsset } from 'modules/assets';
import { Avatar } from '../Avatar';
import classes from './AvatarNamed.module.scss';

function AvatarNamed({ avatarUrl, name }) {
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    if (avatarUrl) {
      getAsset(avatarUrl).then(url => setProfileUrl(url));
    }
  }, [avatarUrl]);

  return (
    <div className={classes.container}>
      <Avatar size={40} photoUrl={profileUrl} aria-haspopup="true" />
      <div className={classes.name}>{name}</div>
    </div>
  );
}

AvatarNamed.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default memo(AvatarNamed);
