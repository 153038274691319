import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import shortid from 'shortid';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import find from 'lodash/find';
import omit from 'lodash/omit';
import some from 'lodash/some';
import filter from 'lodash/filter';
import map from 'lodash/map';
import values from 'lodash/values';
import get from 'lodash/get';
import toPairs from 'lodash/toPairs';
import size from 'lodash/size';
import keys from 'lodash/keys';
import { Button, ConfirmationDialog, FormFooter } from 'modules/ui';
import {
  getAgentAssignments,
  getAgentsLoading,
  loadAgentAssignments,
} from 'modules/agents';
import Dictionary from 'modules/dictionary';
import { Colors } from 'modules/theme';
import { Agent } from 'modules/agents/services';
import {
  createAgentAssignment,
  deleteAgentAssignment,
  updateAgentAssignment,
} from 'modules/agents/redux';
import { showNotification, Notification } from 'modules/notifications';
import AgentInput from '../components/AgentInput';
import classes from './EditAgentsManagersPage.module.scss';

const AGENT_KEYS = [
  'agentFirstName',
  'agentLastName',
  'agentEmail',
  'notificationSetting',
  'verticals',
  'companyName',
  'companyPhoneNumber',
];

const styles = {
  addAnotherAgentContainer: {
    height: 40,
  },
  agentInput: {
    marginBottom: 70,
  },
  lastAgentInput: {
    marginBottom: 15,
  },
  loader: {
    marginLeft: 261,
  },
};
export default function AgentsForm({
  talentId,
  agentAssignmentsTouched,
  setAgentAssignmentsTouched,
}) {
  const dispatch = useDispatch();
  const [agentValues, setAgentValues] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentAssignmentId, setCurrentAssignmentId] = useState(null);
  const [currentAssignmentName, setCurrentAssignmentName] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const agentAssignments = useSelector(getAgentAssignments);
  const agentsLoading = useSelector(getAgentsLoading);

  useEffect(() => {
    dispatch(loadAgentAssignments());
  }, [dispatch]);

  useEffect(() => {
    if (!agentsLoading && !submitInProgress) {
      let reducedValues = reduce(
        [...agentAssignments],
        (accumulator, assignment) => {
          return Object.assign(accumulator, {
            [assignment.id]: pick(assignment, AGENT_KEYS),
          });
        },
        {},
      );

      const placeholderInputs = pickBy(agentValues, (value, key) =>
        startsWith(key, 'placeholder'),
      );

      if (isEmpty(reducedValues) && isEmpty(placeholderInputs)) {
        reducedValues = {
          [`placeholder${shortid.generate()}`]: {},
        };
        setAgentAssignmentsTouched([]);
      } else {
        reducedValues = {
          ...reducedValues,
          ...placeholderInputs,
          ...pickBy(agentValues, (value, key) =>
            includes(agentAssignmentsTouched, key),
          ),
        };
      }

      setAgentValues(reducedValues);
      setLoading(false);
    }
  }, [dispatch, agentsLoading, submitInProgress]);

  useEffect(() => {
    setSelectedVerticals(
      map(values(agentValues), agent => get(agent, 'verticals')),
    );
  }, [agentValues]);

  function handleAgentValueChange(assignmentKey, assignmentValue) {
    if (!includes(agentAssignmentsTouched, assignmentKey)) {
      setAgentAssignmentsTouched(agentValuesTouched => [
        ...agentValuesTouched,
        assignmentKey,
      ]);
    }

    if (errors) {
      setErrors({
        ...errors,
        [assignmentKey]: omit(errors[assignmentKey], keys(assignmentValue)),
      });
    }
    setAgentValues({ ...agentValues, [assignmentKey]: assignmentValue });
  }

  function showAgentPlaceholderInput() {
    setAgentValues({
      ...agentValues,
      [`placeholder${shortid.generate()}`]: {},
    });
  }

  function getAssignmentName(assignmentId) {
    const currentAgent = find(agentAssignments, { id: assignmentId });
    return `agent ${currentAgent.agentFirstName} ${currentAgent.agentLastName}`;
  }

  function handleRemoveAssignmentClick(assignmentId) {
    if (some(agentAssignments, { id: assignmentId })) {
      setCurrentAssignmentId(assignmentId);
      setCurrentAssignmentName(getAssignmentName(assignmentId));
      setConfirmationOpen(true);
    } else {
      if (size(keys(agentValues)) === 1) {
        setAgentAssignmentsTouched([]);
        setAgentValues({ [assignmentId]: {} });
      } else {
        setAgentAssignmentsTouched(
          filter([...agentAssignmentsTouched], id => id !== assignmentId),
        );
        setAgentValues(omit({ ...agentValues }, assignmentId));
      }

      setErrors(omit({ ...errors }, assignmentId));
    }
  }

  function handleRemoveAssignment() {
    setDeleteInProgress(true);
    dispatch(deleteAgentAssignment(currentAssignmentId)).then(() => {
      dispatch(
        showNotification({
          message: `${currentAssignmentName} successfully removed`,
          type: Notification.TYPES.SUCCESS,
        }),
      );
      setErrors(omit({ ...errors }, [currentAssignmentId]));
      setConfirmationOpen(false);
      setCurrentAssignmentName(null);
      setCurrentAssignmentId(null);
      setAgentAssignmentsTouched(
        filter([...agentAssignmentsTouched], id => id !== currentAssignmentId),
      );

      setDeleteInProgress(false);
    });
  }

  async function handleSubmit() {
    let currentErrors = {};

    map(agentAssignmentsTouched, agentAssignmentKey => {
      const error = Agent.validateAgentAssignment(
        get(agentValues, agentAssignmentKey),
        selectedVerticals,
      );
      if (!isEmpty(error)) {
        currentErrors = { ...currentErrors, [agentAssignmentKey]: error };
      } else {
        currentErrors = omit({ ...currentErrors }, [agentAssignmentKey]);
      }
    });

    setErrors(currentErrors);

    const updatePromises = [];
    const createPromises = [];
    const submittedAgentsManagers = [];

    function updateTouchedAssignments(agentAssignmentKey) {
      setAgentAssignmentsTouched(
        filter([...agentAssignmentsTouched], key => key !== agentAssignmentKey),
      );

      submittedAgentsManagers.push(agentAssignmentKey);
    }

    if (isEmpty(currentErrors)) {
      setSubmitInProgress(true);

      setAgentAssignmentsTouched([]);

      map(agentAssignmentsTouched, agentAssignmentKey => {
        if (some(agentAssignments, { id: agentAssignmentKey })) {
          return updatePromises.push(
            dispatch(
              updateAgentAssignment(
                talentId,
                agentAssignmentKey,
                get(agentValues, agentAssignmentKey),
              ),
            ),
          );
        }
        updateTouchedAssignments(agentAssignmentKey);
      });

      await Promise.all(updatePromises);

      map(agentAssignmentsTouched, agentAssignmentKey => {
        if (!some(agentAssignments, { id: agentAssignmentKey })) {
          return createPromises.push(
            dispatch(
              createAgentAssignment(
                talentId,
                get(agentValues, agentAssignmentKey),
              ),
            ),
          );
        }
        updateTouchedAssignments(agentAssignmentKey);
      });

      await Promise.all(createPromises);

      setAgentValues(
        pickBy(
          agentValues,
          (value, key) => !includes(submittedAgentsManagers, key),
        ),
      );

      global.utag.link({
        event_type: 'edit_profile',
        module_type: 'Edit profile',
        module_variation: 'Agents & Managers',
      });

      dispatch(
        showNotification({
          message: 'Agents successfully saved',
          type: Notification.TYPES.SUCCESS,
        }),
      );
      return setSubmitInProgress(false);
    }

    return null;
  }

  return (
    <>
      {loading && (
        <div style={styles.loader}>
          <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
        </div>
      )}
      {!loading && (
        <>
          {toPairs(agentValues).map(([assignmentId, assignment], index) => (
            <AgentInput
              key={assignmentId}
              agentAssignmentId={assignmentId}
              agentAssignment={assignment}
              onChange={handleAgentValueChange}
              onRemoveClick={() => handleRemoveAssignmentClick(assignmentId)}
              label={`Agent #${index + 1}`}
              error={get(errors, assignmentId)}
              removeDisabled={size(agentValues) === 1 && isEmpty(assignment)}
              style={
                index !== size(agentValues) - 1
                  ? styles.agentInput
                  : styles.lastAgentInput
              }
            />
          ))}
          <div style={styles.addAnotherAgentContainer}>
            <Button
              small
              onClick={showAgentPlaceholderInput}
              className={classes.addAnotherAgentBtn}
              disabled={
                size(keys(agentValues)) >= size(Dictionary.VERTICAL_OPTIONS)
              }
            >
              + Add another agent
            </Button>
          </div>
          <FormFooter
            onSubmit={handleSubmit}
            inProgress={submitInProgress}
            submitDisabled={isEmpty(agentAssignmentsTouched)}
            submitButtonLabel="Save agents"
          />
        </>
      )}
      {confirmationOpen && (
        <ConfirmationDialog
          title={`Are you sure you want to remove ${currentAssignmentName}`}
          onAction={handleRemoveAssignment}
          onCancel={() => setConfirmationOpen(false)}
          actionBtnLabel="Yes, remove"
          cancelBtnLabel="Cancel"
          inProgress={deleteInProgress}
          withOverlay
        />
      )}
    </>
  );
}

AgentsForm.propTypes = {
  talentId: PropTypes.string.isRequired,
  agentAssignmentsTouched: PropTypes.array.isRequired,
  setAgentAssignmentsTouched: PropTypes.func.isRequired,
};
