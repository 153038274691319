import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { Page, PageLoader, TabLayout } from 'modules/ui';

import {
  getTalent,
  getTalentLoading,
  loadTalent,
  updateTalent,
  updateSamplesOrder,
  getReorderSamplesError,
  clearReorderSamplesError,
} from '../redux';
import {
  AudioSampleUploadForm,
  TalentProfileTabs,
  ReorderSamplesDialog,
} from '../components';

export default function EditSamplesPage() {
  const dispatch = useDispatch();
  const talent = useSelector(getTalent);
  const isTalentLoading = useSelector(getTalentLoading);
  const samplesReorderError = useSelector(getReorderSamplesError);

  const audioSamples = orderBy(
    get(talent, 'audioSamples'),
    ['ordinalNumber'],
    ['asc'],
  );

  const [isReorderModalOpen, setIsReorderModalOpen] = useState(false);

  function handleSubmitSamples(audioSamplesIds) {
    return dispatch(
      updateTalent(talent.account.id, talent.id, { audioSamplesIds }),
    ).then(() => {
      global.utag.link({
        event_type: 'edit_profile',
        module_type: 'Edit profile',
        module_variation: 'Upload Sample',
      });
    });
  }

  function handleSampleReordering(samplesList) {
    const newSamplesIdsOrder = map(samplesList, sample => {
      return sample.id;
    });

    dispatch(updateSamplesOrder(newSamplesIdsOrder)).then(res => {
      const isErrorFree = !!res;
      if (isErrorFree) {
        dispatch(loadTalent(talent.id));
        setIsReorderModalOpen(false);
      }
    });
  }

  function handleCloseReorderingModal() {
    dispatch(clearReorderSamplesError());
    setIsReorderModalOpen(false);
  }

  return (
    <Page>
      <TabLayout tabs={<TalentProfileTabs />}>
        {isTalentLoading ? (
          <PageLoader />
        ) : (
          <AudioSampleUploadForm
            onReorderSamplesClick={() => {
              setIsReorderModalOpen(true);
            }}
            talent={talent}
            onSubmit={handleSubmitSamples}
          />
        )}
        {isReorderModalOpen && (
          <ReorderSamplesDialog
            isLoading={isTalentLoading}
            error={samplesReorderError}
            onSaveOrder={handleSampleReordering}
            onClose={handleCloseReorderingModal}
            samples={audioSamples}
          />
        )}
      </TabLayout>
    </Page>
  );
}
