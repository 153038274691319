import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import { getAccount, getAvatar } from 'modules/accounts';

import { resolveMessageGroups } from '../services';
import { useScrollToBottom } from '../hooks';

import classes from './Messages.module.scss';
import MessageGroupLeft from './MessageGroupLeft';
import MessageGroupRight from './MessageGroupRight';

function Messages({ className, messages, photoUrl, displayTimestamps }) {
  const account = useSelector(getAccount);
  const accountId = get(account, 'id');
  const accountAvatar = useSelector(getAvatar);

  const messagesRef = useScrollToBottom(messages);
  const groups = resolveMessageGroups(messages);

  return (
    <div className={classNames(classes.messages, className)} ref={messagesRef}>
      {groups.map(group => {
        const groupId = get(group, 'groupId');
        const authorAccountId = get(group, 'authorAccountId');
        const groupMessages = get(group, 'messages');
        return authorAccountId !== accountId ? (
          <MessageGroupLeft
            key={groupId}
            messages={groupMessages}
            photoUrl={photoUrl}
            displayTimestamps={displayTimestamps}
          />
        ) : (
          <MessageGroupRight
            key={groupId}
            messages={groupMessages}
            photoUrl={accountAvatar}
            displayTimestamps={displayTimestamps}
          />
        );
      })}
    </div>
  );
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  photoUrl: PropTypes.string,
  displayTimestamps: PropTypes.bool,
};

export default Messages;
