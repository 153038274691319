import createSelector from 'selectorator';
import { ACCOUNT_TYPES, ACCOUNT_LABELS } from '../const';

function getType(account) {
  if (account.producer) {
    return ACCOUNT_TYPES.PRODUCER;
  }

  if (account.talent) {
    return ACCOUNT_TYPES.TALENT;
  }

  if (account.agent) {
    return ACCOUNT_TYPES.AGENT;
  }

  return null;
}

function getLabel(account) {
  if (account.producer) {
    return ACCOUNT_LABELS.producer;
  }

  if (account.talent) {
    return ACCOUNT_LABELS.talent;
  }
  
  if (account.agent) {
    return ACCOUNT_LABELS.agent;
  }

  return null;
}

// TODO: Move back to redux once circ dependency is resolved in build (SubscribeButton)
const getAccount = createSelector(['accounts.account']);

export default {
  getType,
  getLabel,
  getAccount,
};
