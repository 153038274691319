import React from 'react';
import PropTypes from 'prop-types';
import { Section } from 'modules/ui';
import pluralize from 'pluralize';

export default function ProducerStatsSection({
  privateAuditions,
  openAuditions,
}) {
  const activeOpenCallAuditionsString = `${openAuditions} active open call ${pluralize(
    'audition',
    openAuditions,
  )}`;
  const activePrivateAuditionsString = `${privateAuditions} active private ${pluralize(
    'audition',
    privateAuditions,
  )}`;

  return (
    <Section title="Auditions">
      <div className="body16">{activeOpenCallAuditionsString}</div>
      <div className="body16">{activePrivateAuditionsString}</div>
    </Section>
  );
}

ProducerStatsSection.propTypes = {
  openAuditions: PropTypes.number,
  privateAuditions: PropTypes.number,
};
