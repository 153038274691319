import { createAction } from 'redux-starter-kit';
import get from 'lodash/get';
import has from 'lodash/has';
import { createThunk } from 'redux-scope';
import { Assets, uploadAssetActions } from 'modules/assets';
import { postNDA } from 'modules/ndas';
import {
  fetchAudition,
  fetchAuditions,
  fetchAllAuditions,
  postAudition,
  patchAudition,
  deleteAudition,
} from '../api';

export const loadAudition = createThunk(
  fetchAudition,
  'loadSingle',
  'auditionsProducers',
);
export const loadAllAuditions = createThunk(
  fetchAllAuditions,
  'loadAll',
  'auditionsProducers',
);
export const loadAuditions = createThunk(
  fetchAuditions,
  'load',
  'auditionsProducers',
);
export const removeAuditionAction = createAction(
  'auditionsProducers/audition/remove',
);
export const removeErrorsAction = createAction(
  'auditionsProducers/audition/removeErrors',
);
export const removeAudition = createThunk(
  deleteAudition,
  'delete',
  'auditionsProducers',
);

function uploadFiles(producerId, auditionId, values) {
  const scriptUrl = get(values, 'scriptUrl');
  const nonDisclosureAgreement = get(values, 'nonDisclosureAgreement');
  const additionalMediaUrl = get(values, 'additionalMediaUrl');

  const context = Assets.createAuditionContext(auditionId, producerId);

  const script =
    has(values, 'scriptUrl') &&
    uploadAssetActions(scriptUrl, 'document', 'auditionScript', context);

  const NDA =
    has(values, 'nonDisclosureAgreement') &&
    uploadAssetActions(
      nonDisclosureAgreement,
      'document',
      'auditionNonDisclosureAgreement',
      context,
    );

  const additional =
    has(values, 'additionalMediaUrl') &&
    uploadAssetActions(
      additionalMediaUrl,
      'media',
      'auditionAdditionalMedia',
      context,
    );

  return Promise.all([script, NDA, additional]);
}

const uploadAndUpdateAudition = (producerId, roleId, auditionId, values) => {
  const fileUpload = uploadFiles(producerId, auditionId, values);

  return fileUpload.then(responses => {
    const scriptUrl = get(responses, '[0]');
    const ndaUrl = get(responses, '[1]');
    const additionalMediaUrl = get(responses, '[2]');

    return postNDA(auditionId, ndaUrl).then(newNDA => {
      const audition = {
        ...values,
        id: auditionId,
        ...(has(values, 'nonDisclosureAgreement') && {
          ndaId: get(newNDA, 'id', null),
        }),
        ...(has(values, 'scriptUrl') && { scriptUrl }),
        ...(has(values, 'additionalMediaUrl') && { additionalMediaUrl }),
      };

      return patchAudition(producerId, roleId, audition);
    });
  });
};

const postOrPatchAudition = (producerId, roleId, auditionId, values) => {
  if (!auditionId) {
    return postAudition(producerId, roleId, null).then(newAudition => {
      const newAuditionId = newAudition.data.id;
      const patchValues = { id: newAuditionId, ...values };

      return uploadAndUpdateAudition(
        producerId,
        roleId,
        newAuditionId,
        patchValues,
      );
    });
  }

  return uploadAndUpdateAudition(producerId, roleId, auditionId, values);
};

export const createOrUpdateAudition = createThunk(
  postOrPatchAudition,
  'create&update',
  'auditionsProducers',
);
