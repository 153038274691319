import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select } from 'modules/ui';
import styles from './SearchSort.module.scss';

export default function SearchSort({
  options,
  value,
  setFormValue,
  showSortBy,
  containerClassName,
  selectClassName,
  containerStyles,
}) {
  const containerClasses = classNames(containerClassName, styles.container);
  const selectClasses = classNames(selectClassName, styles.select);

  return (
    <div className={containerClasses} style={containerStyles}>
      {showSortBy && <div className={styles.title}>Sort By</div>}
      <Select
        name="sort"
        required
        lightInput
        value={value}
        menuStyle
        className={selectClasses}
        containerClassName={styles.containerSelect}
        setFormValue={setFormValue}
        options={options}
      />
    </div>
  );
}

SearchSort.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ),
  value: PropTypes.string,
  setFormValue: PropTypes.func,
  showSortBy: PropTypes.bool,
  containerClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  containerStyles: PropTypes.object,
};

SearchSort.defaultProps = {
  showSortBy: true,
};
