import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { TableCell, TableRow } from '@material-ui/core';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { AudioPreview } from 'modules/audio';
import { OfferStatusDisplay } from 'modules/offers';
import { Button, AvatarNamed } from 'modules/ui';
import Offer from 'modules/offers/services/offer';
import Talent from 'modules/talents/services/talent';
import { Colors } from 'modules/theme';
import { getTalentById } from 'modules/talents';
import Dictionary from 'modules/dictionary';
import TopCandidateManagerMenu from './TopCandidateManagerMenu';
import { TopCandidates } from '../services';
import classes from './TopCandidatesTable.module.scss';

export default function TopCandidatesTableRow({
  topCandidate,
  onTalentClick,
  onMarkAsHiredClick,
  offer,
  offersLoading,
  project,
  onRemoveTalent,
  ...otherProps
}) {
  const { fullName, profileImage } = topCandidate.talent;

  const audioSample = topCandidate.audioSample
    ? topCandidate.audioSample
    : topCandidate.audioClip;

  const talent = useSelector(getTalentById(get(topCandidate, 'talentId')));

  const hasAgent = Talent.hasAgent(talent, get(project, 'vertical'));

  return (
    <TableRow>
      <TableCell
        align="left"
        component={Link}
        to={`/talent/${topCandidate.talent.id}`}
        scope="row"
        onClick={onTalentClick}
        className={classes.nameCell}
      >
        <AvatarNamed avatarUrl={profileImage} name={fullName} />
      </TableCell>
      <TableCell align="left" className={classes.noteCell}>
        {topCandidate.note}
      </TableCell>
      <TableCell align="left">
        <AudioPreview
          details={audioSample}
          description={
            // this conditions checks for old top candidates with the unknown source
            // every newly added top candidate should have this property
            topCandidate.source === Dictionary.TOP_CANDIDATE_SOURCE.UNKNOWN
              ? ''
              : `${startCase(topCandidate.source)} Sample`
          }
        />
      </TableCell>
      <TableCell align="center">
        {offersLoading && (
          <div>
            <Loader
              type="Oval"
              color={Colors.darkBlue}
              width={24}
              height={24}
              class
            />
          </div>
        )}
        {!offersLoading && !offer && (
          <Button
            onClick={onMarkAsHiredClick}
            className={classes.sendOfferButton}
            secondary
          >
            {hasAgent ? 'Offer to Agent' : 'Offer to Actor'}
          </Button>
        )}
        {!offersLoading && offer && (
          <OfferStatusDisplay
            resolvedStatus={Offer.getResolvedStatus(offer)}
            hasAgent={hasAgent}
            showHasAgent
          />
        )}
      </TableCell>
      <TableCell align="right">
        <TopCandidateManagerMenu
          topCandidate={topCandidate}
          project={project}
          onRemoveTalent={onRemoveTalent}
          selectedMenuOptions={TopCandidates.resolveTopCandidateMenuOptions(
            Offer.getResolvedStatus(offer, false),
            hasAgent,
          )}
          offerId={get(offer, 'id')}
          disabled={offersLoading}
          {...otherProps}
        />
      </TableCell>
    </TableRow>
  );
}

TopCandidatesTableRow.propTypes = {
  topCandidate: PropTypes.object.isRequired,
  onMarkAsHiredClick: PropTypes.func.isRequired,
  onTalentClick: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  offer: PropTypes.object,
  offersLoading: PropTypes.bool,
  onRemoveTalent: PropTypes.func.isRequired,
};
