import React from 'react';
import { NavigationTabLink } from 'modules/ui';

export default function AgentProfileTabs() {
  return (
    <>
      <NavigationTabLink exact to="/profile/edit">
        Personal Info
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/agency-info">
        Agency Info
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/social-media-links">
        Social Media
      </NavigationTabLink>
    </>
  );
}
