import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SingleBar.module.scss';

export default function SingleBar({ barClassName, active }) {
  const classes = classNames(
    styles.bar,
    active && styles['bar--active'],
    barClassName,
  );
  return <div className={classes} />;
}

SingleBar.propTypes = {
  barClassName: PropTypes.string,
  active: PropTypes.bool,
};
