import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Page, FormEditWizard, SectionLoader } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getProducer } from 'modules/producers';
import { removeNDA } from 'modules/ndas';
import { getAuditionById } from 'modules/auditions';
import {
  loadAudition,
  createOrUpdateAudition,
  getAuditionLoading,
  getAuditionError,
} from '../redux';
import {
  AuditionDetailsForm,
  AuditionVoiceSkillsForm,
  AuditionRecordingDetailsForm,
  AuditionAdditionalDetailsForm,
  AuditionScriptForm,
} from '../components';

const styles = {
  loader: {
    margin: '100px 0',
    width: '100%',
    maxWidth: '721px',
    border: 'none !important',
  },
};

export default function AuditionEditPage({
  onSubmit,
  onChange,
  auditionId,
  role,
  resetChanges,
}) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const audition = useSelector(getAuditionById(auditionId));
  const auditionLoading = useSelector(getAuditionLoading);
  const error = useSelector(getAuditionError);

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (!audition && !!producer && !!auditionId) {
      dispatch(loadAudition(producer.id, auditionId));
    }
  }, [audition, producer, auditionId, dispatch]);

  function handleChangeValue(values) {
    if (!hasChanges) {
      setHasChanges(true);
    }

    if (onChange) {
      onChange(values);
    }
  }

  function handleSaveAudition(values) {
    const roleId = get(audition, 'projectRole.id') || get(role, 'id');

    const recordingAmountDeleted =
      values.recordingLength && isEmpty(values.recordingLength.amount);

    const updatedValues = recordingAmountDeleted
      ? { ...values, recordingLength: null }
      : values;

    return dispatch(
      createOrUpdateAudition(producer.id, roleId, auditionId, updatedValues),
    ).then(() => {
      if (onSubmit) {
        onSubmit();
      }

      setHasChanges(false);
      dispatch(
        showNotification({
          message: 'Audition successfully updated.',
          type: Notification.TYPES.SUCCESS,
        }),
      );
    });
  }

  function handleSaveScriptTab(values) {
    const oldNDA = get(audition, 'nonDisclosureAgreement');
    const newNDA = get(values, 'nonDisclosureAgreement');
    const NDAdeleted = !!oldNDA && !newNDA;

    if (!NDAdeleted) {
      return handleSaveAudition(values);
    }

    return dispatch(removeNDA(auditionId)).then(() =>
      handleSaveAudition(values),
    );
  }

  if (!!auditionId && !!auditionLoading) {
    return <SectionLoader style={styles.loader} />;
  }

  return (
    <Page showFooter={false}>
      <FormEditWizard
        hasChanges={hasChanges}
        resetChanges={() => {
          resetChanges();
          setHasChanges(false);
        }}
      >
        <AuditionDetailsForm
          withPrompt
          withAuditionTypeConfirm
          label="Audition Details"
          initialValues={audition}
          onSubmit={handleSaveAudition}
          onChangeValues={handleChangeValue}
          error={error}
        />
        <AuditionScriptForm
          withPrompt
          label="Script Details"
          initialValues={audition}
          onSubmit={handleSaveScriptTab}
          onChangeValues={handleChangeValue}
          error={error}
        />
        <AuditionVoiceSkillsForm
          withPrompt
          initialValues={{
            ...audition,
            requiredVoiceAgeRanges: audition.requiredVoiceAgeRangesIds,
            requiredRecordingExperiences:
              audition.requiredRecordingExperiencesIds,
          }}
          label="Voice Skills"
          onSubmit={handleSaveAudition}
          onChangeValues={handleChangeValue}
          error={error}
        />
        <AuditionRecordingDetailsForm
          withPrompt
          initialValues={audition}
          label="Recording Details"
          onSubmit={handleSaveAudition}
          onChangeValues={handleChangeValue}
          error={error}
        />
        <AuditionAdditionalDetailsForm
          withPrompt
          initialValues={audition}
          label="Additional Details"
          onSubmit={handleSaveAudition}
          onChangeValues={handleChangeValue}
          error={error}
        />
      </FormEditWizard>
    </Page>
  );
}

AuditionEditPage.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  resetChanges: PropTypes.func,
  auditionId: PropTypes.string,
  role: PropTypes.object,
};
