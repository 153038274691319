import React from 'react';
import PropTypes from 'prop-types';

import { DropdownMenu } from '../DropdownMenu';

const styles = {
  marginTop: 18,
  border: '1px solid rgba(5, 21, 40, 0.07);',
  borderRadius: 8,
  boxShadow: 'none',
};

function NavBarDropdown({ children, ...props }) {
  return (
    <DropdownMenu styles={styles} {...props}>
      {children}
    </DropdownMenu>
  );
}

NavBarDropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default NavBarDropdown;
