import URI from 'urijs';
import get from 'lodash/get';
import { USER_LOADED, getCognitoUser } from 'modules/auth';
import {
  isAuthenticated,
  getCurrentAuthenticatedUser,
  isAuthenticatedWith,
  LOGIN_SUCCESS,
} from '../api';
import { fromStore } from '../../../store';

const REDIRECT_PARAM = 'redirect';

export function initializeAuth() {
  return dispatch =>
    isAuthenticated().then(authenticated => {
      if (authenticated)
        getCurrentAuthenticatedUser().then(cognitoUser => {
          dispatch({ type: USER_LOADED, payload: cognitoUser });
          dispatch({ type: LOGIN_SUCCESS });
        });
    });
}

export async function isAuthenticationValid() {
  const cognitoUser = fromStore(getCognitoUser);
  return isAuthenticatedWith(cognitoUser);
}

export function hasRedirection(location) {
  const url = URI(location);
  return url.hasQuery(REDIRECT_PARAM);
}

export function getRedirection(location) {
  const url = URI(location);
  const queries = url.query(true);

  return get(queries, REDIRECT_PARAM);
}

export function getLocationFullPath() {
  const pathname = get(window, 'location.pathname');
  const search = get(window, 'location.search');
  const fullPath = `${pathname}${search}`;

  if (fullPath === '/') {
    return null;
  }

  return fullPath;
}

export function resolveRedirectionPath(location, redirection) {
  if (!redirection) {
    return location;
  }

  if (hasRedirection(redirection)) {
    const oldRedirection = getRedirection(redirection);

    return URI(location)
      .removeSearch(REDIRECT_PARAM)
      .setSearch(REDIRECT_PARAM, oldRedirection)
      .toString();
  }

  return URI(location)
    .removeSearch(REDIRECT_PARAM)
    .setSearch(REDIRECT_PARAM, redirection)
    .toString();
}
