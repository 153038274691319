import { AUTH_CODES } from '../const';

function resolveDescription(code) {
  switch (code) {
    case AUTH_CODES.RECOVERY_LINK_EXPIRED: {
      return 'Enter your email address associated with Ahab account and we will send you a new link to reset your password.';
    }
    case AUTH_CODES.VERIFICATION_LINK_EXPIRED: {
      return 'Enter your email address associated with Ahab account and we will send you a new link to verify your account.';
    }
    default: {
      return 'Enter the email address associated with your Ahab account and we will send you a link to reset your password. The link is valid for 24 hours.';
    }
  }
}

function resolveTitle(code) {
  switch (code) {
    case AUTH_CODES.RECOVERY_LINK_EXPIRED: {
      return 'Recovery link expired';
    }
    case AUTH_CODES.VERIFICATION_LINK_EXPIRED: {
      return 'Verification link expired';
    }
    default: {
      return 'Forgot password?';
    }
  }
}

function resolveButtonTitle(code) {
  switch (code) {
    case AUTH_CODES.VERIFICATION_LINK_EXPIRED: {
      return 'Resend verification email';
    }
    default: {
      return 'Request password reset';
    }
  }
}

export default {
  resolveDescription,
  resolveTitle,
  resolveButtonTitle,
};
