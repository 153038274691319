import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import get from 'lodash/get';
import {
  Page,
  PageHeader,
  Button,
  AvatarHeader,
  IconButton,
  PageLoader,
  NotFoundPage,
} from 'modules/ui';
import { getAvatar, useTealiumView } from 'modules/accounts';
import { Notification, showNotification } from 'modules/notifications';
import { AgentDetails } from '../components';
import { getAgent } from '../redux';
import { Agent } from '../services';
import classes from './ProfilePage.module.scss';

export default function AgentProfilePage() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const agent = useSelector(getAgent);
  const avatar = useSelector(getAvatar);
  const agentId = get(params, 'agentId');

  function handleAddSampleClick() {
    history.push('profile/edit/samples');
  }

  function handleEditButtonClick() {
    history.push('profile/edit');
  }

  useTealiumView('Profile Page');

  function showLinkCopiedNotification() {
    return dispatch(
      showNotification({
        message: 'Profile link copied',
        type: Notification.TYPES.SUCCESS,
      }),
    );
  }

  if (!agent) {
    return <PageLoader />;
  }

  if (agentId && agentId !== agent.id) {
    return <NotFoundPage />;
  }

  const HeaderComponent = (
    <PageHeader
      large
      actionClassName="mb-68"
      LeftComponent={<AvatarHeader avatar={avatar} person={agent} size={158} />}
    >
      <>
        <Button primary onClick={handleEditButtonClick}>
          Edit profile
        </Button>
        <CopyToClipboard
          text={Agent.getProfileLink(agent)}
          onCopy={showLinkCopiedNotification}
        >
          <IconButton
            iconName="share"
            iconSize={16}
            onClick={() => {}}
            className={classes.shareButton}
            iconClassName={classes.shareIcon}
            iconColor="white"
          />
        </CopyToClipboard>
      </>
    </PageHeader>
  );

  return (
    <Page HeaderComponent={HeaderComponent}>
      <AgentDetails agent={agent} onAddSampleClick={handleAddSampleClick} />
    </Page>
  );
}
