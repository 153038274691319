export {
  reducer,
  getProducer,
  loadProducer,
  createProducer,
  updateProducer,
  getProducerId,
  loadSearchedProducers,
  getSearchedProducers,
  getProducersLoading,
} from './redux';
export { postProducer } from './api';
export { NavBar, BiographySection, SearchTalentList, PronounSection } from './components';
export { default as ProducerSwitch } from './Switch';
export { TALENTS_REGEX, ProfilePage } from './fragments';
export { Producer } from './services';
