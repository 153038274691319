import React, { useState, useEffect } from 'react';
import take from 'lodash/take';
import PropTypes from 'prop-types';
import ListSelectInput from './ListSelectInput';

export default function ListSelect({
  setFormValue,
  name,
  loading,
  onChange,
  value: initialSelection,
  options,
  collection,
  isMultiSelect,
  noResultsText,
  generateSelectionString,
  transformSelection,
  ...otherProps
}) {
  const [selection, setSelection] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const newSelection = initialSelection === null ? [] : initialSelection;

    setSelection(newSelection);
  }, [initialSelection]);

  function handleSelectChange(event, newValue) {
    if (setFormValue) {
      setFormValue(name, newValue);
    }
  }

  function handleInputChange(event, value) {
    setInputValue(value);

    if (onChange) {
      onChange(value);
    }
  }

  function handleEraseItem() {
    if (!selection) {
      return Promise.resolve(true);
    }

    const newSelection = take(selection, selection.length - 1);

    if (setFormValue) {
      setFormValue(name, newSelection);
    }

    return Promise.resolve(true);
  }

  function handleRemoveAll() {
    setSelection([]);

    if (setFormValue) {
      setFormValue(name, null);
    }
  }

  return (
    <ListSelectInput
      name={name}
      isMultiSelect={isMultiSelect}
      isListSelect
      loading={loading}
      onRemoveAll={handleRemoveAll}
      onEraseItem={handleEraseItem}
      onChange={handleSelectChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      value={selection}
      noResultsText={noResultsText}
      {...otherProps}
    />
  );
}

ListSelect.propTypes = {
  ...ListSelectInput.propTypes,
  setFormValue: PropTypes.func,
  generateSelectionString: PropTypes.func.isRequired,
  transformSelection: PropTypes.func,
};
