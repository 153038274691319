import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'modules/ui';
import styles from './CheckboxUI.module.scss';

function CheckboxUI({ selected, disabled, label, onClick }) {
  const containerClasses = classNames(styles.container, {
    [styles.disabled]: disabled,
  });

  const iconClasses = classNames(styles.icon, {
    [styles['icon--selected']]: selected,
  });

  return (
    <div
      type="button"
      className={containerClasses}
      onClick={disabled ? null : onClick}
      onKeyPress={disabled ? null : onClick}
    >
      <div>
        <div className={iconClasses}>
          {selected && <Icon name="checked" color="#fff" size={24} />}
        </div>
      </div>
      <span>{label}</span>
    </div>
  );
}

CheckboxUI.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

export default memo(CheckboxUI);
