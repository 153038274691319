import React from 'react';
import PropTypes from 'prop-types';
import { FileControls } from 'modules/assets';
import styles from './AudioInput.module.scss';
import AudioPreview from './AudioPreview';

export function FormAudioPreview({
  value,
  detailsPaths,
  file,
  handleRemoveFileClick,
  handleReplaceClick,
  deleteInProgress,
  setDeleteInProgress,
}) {
  return (
    <div className={styles.fileContainer}>
      <AudioPreview details={value} file={file} detailsPaths={detailsPaths} />
      <FileControls
        file={value}
        onRemoveFile={handleRemoveFileClick}
        onReplaceFile={handleReplaceClick}
        deleteInProgress={deleteInProgress}
        setDeleteInProgress={setDeleteInProgress}
      />
    </div>
  );
}

FormAudioPreview.propTypes = {
  value: PropTypes.object,
  file: PropTypes.object,
  detailsPaths: PropTypes.array,
  handleRemoveFileClick: PropTypes.func,
  handleReplaceClick: PropTypes.func,
  setDeleteInProgress: PropTypes.func,
  deleteInProgress: PropTypes.bool,
};
