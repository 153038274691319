import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { getAccount } from 'modules/accounts';
import {
  GlobalChatPlaceholder,
  getUnreadMessagesCount,
} from 'modules/global-messages';

import {
  openGlobalMessagingChatPopup,
  loadGlobalMessagingThread,
  loadUnreadGlobalMessagingThreads,
} from '../redux';
import { GlobalMessagingMenu } from '../fragments';

import classes from './GlobalMessagingDropdownMenu.module.scss';

function GlobalMessagingDropdownMenu({
  onClose,
  closeOnCardOpen,
  isResponsiveDrawer,
}) {
  const dispatch = useDispatch();
  const [messagesLimit, setMessagesLimit] = useState(20);
  const account = useSelector(getAccount);
  const accountId = get(account, 'id');
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);

  function handleLoadMore() {
    setMessagesLimit(messagesLimit + 20);
  }

  function handleCardClick(oppositeAccountId, oppositeAccountType) {
    dispatch(
      openGlobalMessagingChatPopup(oppositeAccountId, oppositeAccountType),
    );
    if (closeOnCardOpen) {
      onClose();
    }
  }

  return (
    <GlobalMessagingMenu
      className={classes.dropdownMenu}
      loadGlobalMessagingThreads={loadGlobalMessagingThread(
        accountId,
        '-updatedAt',
        messagesLimit,
      )}
      onCardClick={handleCardClick}
      onClose={onClose}
      cardsPlaceholder={
        <GlobalChatPlaceholder className={classes.dropdownPlaceholder} />
      }
      onFilterByUnreadClick={() =>
        dispatch(
          loadUnreadGlobalMessagingThreads(accountId, unreadMessagesCount),
        )
      }
      onLoadMoreClick={handleLoadMore}
      isResponsiveDrawer={isResponsiveDrawer}
      accountId={accountId}
      displaySeeAll
    />
  );
}

GlobalMessagingDropdownMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  closeOnCardOpen: PropTypes.bool,
  isResponsiveDrawer: PropTypes.bool,
};

GlobalMessagingDropdownMenu.defaultProps = {
  closeOnCardOpen: true,
};

export default GlobalMessagingDropdownMenu;
