import { Api } from 'modules/core';
import isEmpty from 'lodash/isEmpty';

const projectsType = 'projects';
const recentlyViewedProjectsType = 'recently-viewed-projects';

function getProjectsUrl(producerId) {
  return `producers/${producerId}/projects`;
}

export function fetchAllProjects(producerId, sort) {
  if (!producerId) {
    return Promise.resolve();
  }

  const projectsUrl = getProjectsUrl(producerId);
  // TODO: Remove page limit
  const params = { include: 'producer', sort, 'page[limit]': 1000 };

  // TODO: Backend will add this (AHAB-631)
  // return Api.find(`${projectsUrl}/all`, params);
  return Api.find(projectsUrl, params);
}

export function fetchProjects(producerId, sort) {
  if (!producerId) {
    return Promise.resolve();
  }

  const projectsUrl = getProjectsUrl(producerId);
  const params = { include: 'producer', sort };

  return Api.find(projectsUrl, params);
}

export function fetchProjectsStats(producerId, projectIds) {
  if (!producerId || isEmpty(projectIds)) {
    return Promise.resolve();
  }

  const projectsUrl = getProjectsUrl(producerId);
  const params = { 'filter[project.id]': projectIds.toString() };
  const options = {
    stringifyOpt: {
      encode: false,
    },
  };

  return Api.find(`${projectsUrl}/stats`, params, options);
}

export function fetchProject(producerId, projectId) {
  if (!producerId || !projectId) {
    return Promise.resolve();
  }

  const projectsUrl = getProjectsUrl(producerId);
  const params = { include: 'producer' };

  return Api.find(`${projectsUrl}/${projectId}`, params);
}

export function fetchRecentlyViewedProjects(producerId) {
  const projectsUrl = getProjectsUrl(producerId);
  const params = { include: 'project,producer', 'page[limit]': 3 };

  return Api.find(`${projectsUrl}/recently-viewed`, params);
}

export function postRecentlyViewedProject(producerId, projectId) {
  if (!producerId || !projectId) {
    return Promise.resolve();
  }

  const projectsUrl = getProjectsUrl(producerId);
  const recentlyViewedProject = {
    resourceType: recentlyViewedProjectsType,
    ...{ projectId },
  };

  return Api.create(`${projectsUrl}/recently-viewed`, recentlyViewedProject);
}

export function postProject(producerId, values) {
  const endpoint = getProjectsUrl(producerId);
  const project = { resourceType: projectsType, ...values };

  return Api.create(endpoint, project);
}

export function patchProject(producerId, projectId, values) {
  const endpoint = getProjectsUrl(producerId);
  const params = {
    include: 'producer',
  };

  const isLengthUpdated = values.length === '';
  // postgres stores data as BigDecimal so passing empty string returns a NaN
  const updatedValues = isLengthUpdated ? { ...values, length: null } : values;

  return Api.update(
    `${endpoint}/${projectId}`,
    updatedValues,
    projectsType,
    null,
    params,
  );
}

export function removeProject(producerId, projectId) {
  const endpoint = getProjectsUrl(producerId);

  return Api.deleteResource(`${endpoint}/${projectId}`);
}
