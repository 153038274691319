import React, { useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  TextInput,
  Textarea,
  ManagedForm,
  AutoCompleteSelect,
  OptionUtil,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';
import { Validator } from '../services';
import { AgencyInfoGrid } from './form-grids';
import styles from './ProfileDetailsForm.module.scss';

const style = {
  noLabel: {
    marginTop: 34,
  },
};

export default function AgencyInfoForm({
  onSubmit,
  initialValues,
  ...otherProps
}) {
  const countryId = get(initialValues, 'country.id');
  const [country, setCountry] = useState(
    find(Dictionary.LOCATIONS, { id: countryId }),
  );
  const states = country ? country.states : null;
  const [availableStates, setAvailableStates] = useState(states || null);

  function handleCountryChange(event) {
    const id = event.target.value;
    const currentCountry = find(Dictionary.LOCATIONS, { id });

    const foundStates = currentCountry ? currentCountry.states : null;

    setAvailableStates(foundStates);
    setCountry(currentCountry);
  }

  const stateOptions = OptionUtil.getOptions(
    availableStates,
    OptionUtil.mapOptionFromModel,
  );

  function handleValueChange(values) {
    if (!values.countryId) {
      setAvailableStates([]);
    }
  }

  return (
    <ManagedForm
      withPrompt
      title="Agency Info"
      description="Tell us about your agency"
      submitButtonLabel="Save Agency Info"
      onSubmit={onSubmit}
      titleContainerClassName={styles.titleContainer}
      onChangeValues={handleValueChange}
      TemplateComponent={AgencyInfoGrid}
      validate={Validator.validateAgencyDetails}
      initialValues={initialValues}
      {...otherProps}
    >
      <TextInput
        autoFocus
        label="Agency"
        name="agencyName"
        placeholder="Enter agency name"
      />
      <AutoCompleteSelect
        label="Address"
        name="countryId"
        placeholder="Enter country"
        onChange={handleCountryChange}
        options={Dictionary.LOCATIONS_OPTIONS}
      />
      <AutoCompleteSelect
        name="stateId"
        placeholder="Enter state"
        options={stateOptions}
        disabled={isEmpty(stateOptions)}
        style={style.noLabel}
        arrowStyle={style.noLabel}
      />
      <TextInput name="city" placeholder="City" />
      <TextInput name="zipCode" placeholder="ZIP Code" />
      <TextInput
        label="Company Website"
        name="agencyWebsite"
        placeholder="Add a link"
      />
      <Textarea
        label="About my agency"
        name="aboutAgency"
        maxChars={500}
        placeholder="Share some information about your agency that you think is relevant."
        className={styles.textarea}
      />
    </ManagedForm>
  );
}

AgencyInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};
