import React from 'react';
import PropTypes from 'prop-types';
import {
  Collapsible,
  IconButton,
  MultiSelect,
  AutoCompleteSelect,
} from 'modules/ui';
import { Colors } from 'modules/theme';
import Dictionary from 'modules/dictionary';
import styles from './SearchForm.module.scss';

export default function VoiceTraitsSearchForm({
  handleSetFormValue,
  getValue,
  toggleVoiceSkillsFormOpen,
  voiceSkillsFormOpen,
}) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Voice Traits</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleVoiceSkillsFormOpen}
        />
      </div>
      <Collapsible hideButton collapsed={!voiceSkillsFormOpen}>
        <AutoCompleteSelect
          isMultiSelect
          name="primaryLanguages"
          value={getValue('primaryLanguages')}
          placeholder="Primary Language"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.LANGUAGE}
          options={Dictionary.LANGUAGE_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          name="foreignLanguages"
          value={getValue('foreignLanguages')}
          placeholder="Language Fluencies"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.LANGUAGE}
          options={Dictionary.LANGUAGE_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          name="accents"
          value={getValue('accents')}
          placeholder="Accent"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.ACCENTS}
          options={Dictionary.ACCENT_OPTIONS}
        />
        <MultiSelect
          name="voiceAgeRanges"
          value={getValue('voiceAgeRanges')}
          placeholder="Vocal Age Range"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.VOICE_RANGES}
          options={Dictionary.VOICE_RANGE_OPTIONS}
        />
      </Collapsible>
    </div>
  );
}

VoiceTraitsSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleVoiceSkillsFormOpen: PropTypes.func.isRequired,
  voiceSkillsFormOpen: PropTypes.func.isRequired,
};
