import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  TablePagination,
  SectionLoader,
  EmptySearchPlaceholder,
} from 'modules/ui';
import { getTalentLoading } from 'modules/talents';

const DEFAULT_ITEMS_PER_PAGE = [15, 30, 60, 90, 150];

const styles = {
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 10,
  },
};

export default function SearchTalentList({
  TalentCards,
  page,
  count,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  itemsPerPage,
}) {
  const loading = useSelector(getTalentLoading);

  if (loading) {
    return <SectionLoader />;
  }

  if (count === 0) {
    return (
      <EmptySearchPlaceholder
        title="0 talents found that match your search"
        description="Try adjusting your search to find what you're looking for"
      />
    );
  }

  return (
    <div>
      <div style={styles.cardContainer}>{TalentCards}</div>
      <TablePagination
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={itemsPerPage}
        rowsPerPageLabel="Talents Per Page"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
}

SearchTalentList.defaultProps = {
  itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

SearchTalentList.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  itemsPerPage: PropTypes.array,
};
