import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import startsWith from 'lodash/startsWith';

import { useWindowSize } from 'modules/core';
import { MessagingEnvelope } from 'modules/chat';
import { getUnreadMessagesCount as getUnreadAuditionsCount } from 'modules/audition-messages';
import { getUnreadMessagesCount as getUnreadGlobalCount } from 'modules/global-messages';
import { clearSearchedGlobalMessagingThreads } from 'modules/global-messages-threads';
import { clearSearchedApplications } from 'modules/audition-applications';

import { useAnchorEl } from '../hooks';

import MessagingDrawer from './MessagingDrawer';
import MessagingDropdown from './MessagingDropdown';
import classes from './AccountNavBarEnvelope.module.scss';
import { AccountType } from '../services';
import { getAccountType } from '../redux';

const SEE_ALL_MESSAGES_PATHNAME_PREFIX = '/messaging';

function AccountNavBarEnvelope() {
  const [anchorEl, handleDropdownClick, handleClose] = useAnchorEl();
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const { desktopWidth } = useWindowSize();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const unreadAuditionMessagesCount = useSelector(getUnreadAuditionsCount);
  const unreadGlobalMessagesCount = useSelector(getUnreadGlobalCount);
  const accountType = useSelector(getAccountType);

  function handleMessagingOpen(event) {
    dispatch(clearSearchedApplications());
    dispatch(clearSearchedGlobalMessagingThreads());

    if (!desktopWidth) {
      return setDrawerExpanded(true);
    }
    return handleDropdownClick(event);
  }

  if (startsWith(pathname, SEE_ALL_MESSAGES_PATHNAME_PREFIX)) {
    return null;
  }

  const totalUnreadMessages = AccountType.isAgent(accountType)
    ? unreadGlobalMessagesCount
    : unreadGlobalMessagesCount + unreadAuditionMessagesCount;

  return (
    <>
      <MessagingEnvelope
        className={classes.envelope}
        unreadMessages={totalUnreadMessages}
        onClick={handleMessagingOpen}
      />
      {desktopWidth ? (
        <MessagingDropdown
          anchorEl={anchorEl}
          drawerOpen={drawerExpanded}
          onClose={handleClose}
          isDesktopWidth={desktopWidth}
        />
      ) : (
        <MessagingDrawer
          drawerOpen={drawerExpanded}
          onClose={() => setDrawerExpanded(false)}
        />
      )}
    </>
  );
}

export default AccountNavBarEnvelope;
