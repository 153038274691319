import { Api } from 'modules/core';
import { ACCOUNT_TYPES } from 'modules/accounts';

const PRODUCER_LISTS_TYPE = 'lists';
const AGENT_LISTS_TYPE = 'agent-lists';
const LIST_SHARE_TYPE = 'list-share-actions';
const AGENT_LIST_SHARE_TYPE = 'agent-list-share-actions';
const TALENTS_TYPE = 'talents';

const producerParams = { include: 'producer' };
const agentParams = { include: 'agent' };

const getParams = accountType => {
  if (accountType === ACCOUNT_TYPES.PRODUCER) {
    return producerParams;
  }
  return agentParams;
};

const getList = (accountType, listName) => {
  if (accountType === ACCOUNT_TYPES.PRODUCER) {
    return { resourceType: PRODUCER_LISTS_TYPE, name: listName };
  }
  return { resourceType: AGENT_LISTS_TYPE, name: listName };
};

const getListType = accountType => {
  if (accountType === ACCOUNT_TYPES.PRODUCER) {
    return PRODUCER_LISTS_TYPE;
  }
  return AGENT_LISTS_TYPE;
};

const getListsBaseEndpoint = (accountType, accountId) => {
  if (accountType === ACCOUNT_TYPES.PRODUCER) {
    return `producers/${accountId}/lists`;
  }
  return `agents/${accountId}/lists`;
};

export function fetchList(accountId, listId, accountType) {
  if (!accountId || !listId) {
    return Promise.resolve();
  }

  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/${listId}`;
  const params = getParams(accountType);

  return Api.find(endpoint, params);
}

export function fetchLists(accountId, accountType) {
  if (!accountId) {
    return Promise.resolve();
  }

  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/all`;
  const params = getParams(accountType);

  return Api.find(endpoint, params);
}

export function postList(accountId, listName, accountType) {
  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}`;
  const list = getList(accountType, listName);
  const params = getParams(accountType);

  return Api.create(endpoint, list, null, params);
}

export function patchList(accountId, listId, listName, accountType) {
  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/${listId}`;
  const list = getList(accountType, listName);
  const listType = getListType(accountType);
  const params = getParams(accountType);

  return Api.update(endpoint, list, listType, null, params);
}

export function deleteList(accountId, listId, accountType) {
  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/${listId}`;

  return Api.deleteResource(endpoint);
}

export function addTalentToList(accountId, listId, talentId, accountType) {
  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/${listId}/relationships/talents`;
  const body = { data: [{ type: TALENTS_TYPE, id: talentId }] };

  return Api.createRaw(endpoint, body);
}

export function removeTalentFromList(accountId, listId, talentId, accountType) {
  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/${listId}/relationships/talents`;
  const body = { data: [{ type: TALENTS_TYPE, id: talentId }] };

  return Api.deleteRaw(endpoint, body);
}

export function postShareList(accountId, values, accountType) {
  if (!accountId) {
    return Promise.resolve();
  }

  const getType = () => {
    if (accountType === ACCOUNT_TYPES.PRODUCER) {
      return LIST_SHARE_TYPE;
    }
    return AGENT_LIST_SHARE_TYPE;
  };

  const baseEndpoint = getListsBaseEndpoint(accountType, accountId);
  const endpoint = `${baseEndpoint}/actions/share`;
  const type = getType();
  const assignment = {
    resourceType: type,
    ...values,
  };

  return Api.create(endpoint, assignment);
}
