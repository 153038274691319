export const MAX_NUM_OF_OPENED_CHATS = 2;
export const CHAT_REFRESH_RATE = 30000;

export const MAX_MESSAGE_LENGTH = 500;

export const ChatType = {
  AUDITION: 'audition-application',
  GLOBAL: 'global-messaging',
};

export const ChatDropdownTab = {
  GLOBAL: 0,
  AUDITION: 1,
};

export const relativeTime = {
  future: 'in %s',
  past: '%s ago',
  s: '1s',
  ss: '%ds',
  m: '1min',
  mm: '%dmin',
  h: '1h',
  hh: '%dh',
  d: '1d',
  dd: '%dd',
  w: '1w',
  ww: '%dw',
  M: '1m',
  MM: '%dm',
  y: '1y',
  yy: '%dy',
};
