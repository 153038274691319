export {
  Chat,
  ChatWindow,
  ChatPopupOptions,
  MessagingEnvelope,
  MessagingMenu,
  MessagingCard,
  MessagingHorizontalMenu,
  MessagingHorizontalMenuLabel,
  ChatPlaceholder,
  withPopupWindow,
} from './components';

export { MessagingPage } from './pages';

export {
  reducer,
  openChatPopup,
  closeChatPopup,
  getChatWindows,
  closeAllChatWindows,
} from './redux';

export { LetterIcon } from './assets';

export { ChatType, ChatDropdownTab } from './const';
