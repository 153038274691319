import React from 'react';
import PropTypes from 'prop-types';
import { AsyncPlayButton } from 'modules/audio';
import classes from './AudioPreviewPlaceholder.module.scss';

function AudioPreviewPlaceholder({ title, description }) {
  return (
    <div>
      <AsyncPlayButton
        disabled
        containerClassName={classes.buttonContainer}
        className={classes.button}
      />
      <div className={classes.info}>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
}

AudioPreviewPlaceholder.defaultProps = {
  title: 'Invited',
  description: 'Waiting for submission',
};

AudioPreviewPlaceholder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default AudioPreviewPlaceholder;
