import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import AuditionMessagingCard from './AuditionMessagingCard';

function ProducerAuditionMessagingCard({ application, onCardClick }) {
  const talent = get(application, 'talent');

  const audition = get(application, 'audition');
  const auditionId = get(audition, 'id');

  const talentId = get(talent, 'id');
  const talentFullName = get(talent, 'fullName');
  const talentProfileImage = get(talent, 'profileImage');

  function handleCardClick() {
    onCardClick(auditionId, talentId);
  }

  return (
    <AuditionMessagingCard
      application={application}
      subtitle={talentFullName}
      profileImage={talentProfileImage}
      onClick={handleCardClick}
    />
  );
}

ProducerAuditionMessagingCard.propTypes = {
  application: PropTypes.object.isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default ProducerAuditionMessagingCard;
