import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, CloseButton } from 'modules/ui';
import styles from './ConfirmationDialog.module.scss';

export default function ConfirmationDialog({
  onAction,
  onCancel,
  message,
  actionBtnLabel,
  cancelBtnLabel,
  title,
  withOverlay,
  inProgress,
  subtitleText,
  titleStyle,
  subtitleClassName,
}) {
  const buttonClasses = classNames(styles.button);
  const textClasses = classNames('body16', styles.text);
  const subtitleClasses = classNames(styles.subtitle, subtitleClassName);
  const overlayClass = withOverlay && 'modalContainer';

  return (
    <div className={overlayClass}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2 style={titleStyle}>{title}</h2>
          <div className={styles.closeButtonContainer}>
            <CloseButton onClick={onCancel} />
          </div>
        </div>
        {subtitleText && <div className={subtitleClasses}>{subtitleText}</div>}
        <div className={textClasses}>{message}</div>
        <div className={styles.footer}>
          <Button
            secondary
            autoFocus
            className={buttonClasses}
            onClick={onCancel}
          >
            {cancelBtnLabel}
          </Button>
          <Button
            primary
            className={buttonClasses}
            onClick={onAction}
            loading={inProgress}
          >
            {actionBtnLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

ConfirmationDialog.propTypes = {
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  actionBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  message: PropTypes.string,
  withOverlay: PropTypes.bool,
  inProgress: PropTypes.bool,
  titleStyle: PropTypes.object,
  subtitleText: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  actionBtnLabel: 'Discard',
  cancelBtnLabel: 'Keep editing',
  message: '',
};
