import React, { Children } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const styles = {
  inputFirst: {
    flex: 1,
    paddingLeft: 38,
  },
};

export default function ProjectGrid({ children }) {
  const items = Children.toArray(children);

  return (
    <Grid container spacing={0}>
      <Grid container>
        <Grid item xs="auto">
          {items[0]}
        </Grid>
        <div style={styles.inputFirst}>
          <Grid container xs="auto">
            <Grid item xs={12}>
              {items[1]}
            </Grid>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={6}>
                {items[2]}
              </Grid>
              <Grid item xs={6}>
                {items[3]}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        {items[4]}
      </Grid>
      <Grid item xs={12}>
        {items[5]}
      </Grid>
      <Grid item xs={12}>
        {items[6]}
      </Grid>
      <Grid item xs={12}>
        {items[7]}
      </Grid>
    </Grid>
  );
}

ProjectGrid.propTypes = {
  children: PropTypes.node,
};
