import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';
import { Colors } from 'modules/theme';
import styles from './Button.module.scss';

const Button = forwardRef(
  (
    {
      primary,
      secondary,
      header,
      dark,
      light,
      gray,
      disabled,
      className,
      loading,
      children,
      loaderSize,
      small,
      ...otherProps
    },
    ref,
  ) => {
    const classes = classNames(
      styles.btn,
      primary && styles['btn--primary'],
      secondary && styles['btn--secondary'],
      disabled && styles['btn--disabled'],
      header && styles['btn--header'],
      small && styles['btn--small'],
      dark && styles['btn--dark'],
      light && styles['btn--light'],
      gray && styles['btn--gray'],
      className,
    );

    const loaderColor = secondary ? Colors.darkBlue : Colors.white;
    const showChildren = !loading;

    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled || loading}
        className={classes}
        {...otherProps}
      >
        {loading && (
          <div className={styles.loader}>
            <Loader
              type="Oval"
              color={loaderColor}
              width={loaderSize}
              height={loaderSize}
            />
          </div>
        )}
        {showChildren && children}
      </button>
    );
  },
);

Button.propTypes = {
  loaderSize: PropTypes.number,
  primary: PropTypes.bool,
  header: PropTypes.bool,
  secondary: PropTypes.bool,
  dark: PropTypes.bool,
  light: PropTypes.bool,
  gray: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  small: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

Button.defaultProps = {
  loaderSize: 20,
};

export default memo(Button);
