import { Api } from 'modules/core';

export function fetchAuthCodeValidity(authCode) {
  if (!authCode) {
    return Promise.resolve();
  }

  const headers = { headers: { 'auth-code': authCode } };

  return Api.find('auth-codes/actions/verify', {}, {}, headers);
}
