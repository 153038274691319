import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './MessageList.module.scss';

function MessageList({ className, children }) {
  return (
    <div className={classNames(classes.messageList, className)}>{children}</div>
  );
}

MessageList.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default MessageList;
