import React from 'react';
import PropTypes from 'prop-types';

import { LineDivider, NavBarDropdown } from 'modules/ui';
import DropdownMenuHeading from './DropdownMenuHeading';

import classes from './AccountDropdown.module.scss';
import AccountDropdownMenuItem from './AccountDropdownMenuItem';

export default function AccountDropdown({
  anchorEl,
  fullName,
  city,
  state,
  country,
  onProfile,
  onEditProfile,
  onSettings,
  onLogout,
  onClose,
}) {
  return (
    <NavBarDropdown
      open={!!anchorEl}
      width={264}
      onClose={onClose}
      showRightArrow={false}
      anchorEl={anchorEl}
    >
      <DropdownMenuHeading
        className={classes.dropdownHeading}
        fullName={fullName}
        city={city}
        state={state}
        country={country}
        onProfile={onProfile}
      />
      <LineDivider />
      <AccountDropdownMenuItem text="Edit Profile" onClick={onEditProfile} />
      <AccountDropdownMenuItem text="Settings" onClick={onSettings} />
      <LineDivider />
      <AccountDropdownMenuItem text="Sign out" onClick={onLogout} />
    </NavBarDropdown>
  );
}

AccountDropdown.propTypes = {
  fullName: PropTypes.string.isRequired,
  onProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onSettings: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.object.isRequired,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
};
