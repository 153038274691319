import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import { useLocationPageNames, useWindowSize } from 'modules/core';
import { NavBar, NavBarLink } from 'modules/ui';
import {
  AccountAvatarMenu,
  AccountNavBarEnvelope,
  MobileNavBarAccountCard,
  TALENT_PAGES,
} from 'modules/accounts';
import { getTalent } from '../redux';

function TalentNavBar({ avatar, onLogout, history }) {
  const location = useLocation();
  const currentPage = useLocationPageNames(TALENT_PAGES.PAGES);
  const { desktopWidth, mobileWidth } = useWindowSize();
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const talent = useSelector(getTalent);

  const fullName = get(talent, 'fullName');
  const city = get(talent, 'city');
  const state = get(talent, 'state.name');
  const country = get(talent, 'country.name');

  function handleLogoutClick() {
    onLogout();
    setDrawerExpanded(false);
  }

  function handleProfileClick() {
    setDrawerExpanded(false);
    history.push('/profile');
  }

  function handleEditProfileClick() {
    setDrawerExpanded(false);
    history.push('/profile/edit');
  }

  function handleSettingsClick() {
    setDrawerExpanded(false);
    history.push('/settings/change-password');
  }

  function NavBarComponents() {
    return (
      <>
        <NavBarLink
          exact
          to="/"
          showUnderline={desktopWidth}
          isActive={(match, location) => {
            return (
              location.pathname === '/' ||
              startsWith(location.pathname, '/auditions')
            );
          }}
          onClick={() => setDrawerExpanded(false)}
        >
          Auditions
        </NavBarLink>
        {desktopWidth && (
          <NavBarLink
            showUnderline={desktopWidth}
            exact
            to="/submissions"
            onClick={() => setDrawerExpanded(false)}
          >
            My Submissions
          </NavBarLink>
        )}
        <NavBarLink
          showUnderline={desktopWidth}
          exact
          to="/offers"
          onClick={() => setDrawerExpanded(false)}
        >
          Offers
        </NavBarLink>
        {!desktopWidth ? (
          <MobileNavBarAccountCard
            avatar={avatar}
            fullName={fullName}
            city={city}
            state={state}
            country={country}
            onLogout={handleLogoutClick}
            onProfile={handleProfileClick}
            onEditProfile={handleEditProfileClick}
            onSettings={handleSettingsClick}
          />
        ) : (
          <>
            <AccountNavBarEnvelope />
            <AccountAvatarMenu
              avatar={avatar}
              fullName={fullName}
              city={city}
              state={state}
              country={country}
              onLogout={onLogout}
              onEditProfile={handleEditProfileClick}
              onSettings={handleSettingsClick}
              isActive={startsWith(location.pathname, '/profile')}
              enableHoverBorder
            />
          </>
        )}
      </>
    );
  }

  return (
    <NavBar
      activePageName={currentPage}
      rightIconComponent={<AccountNavBarEnvelope />}
      expanded={drawerExpanded}
      handleExpandDrawer={setDrawerExpanded}
      className="pt-4"
      homePagePathname={TALENT_PAGES.HOME_PAGE_PATHNAME}
    >
      <NavBarComponents />
    </NavBar>
  );
}

TalentNavBar.propTypes = {
  avatar: PropTypes.string,
  onLogout: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(TalentNavBar);
