import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

export function withinChatWindows(getState) {
  return get(getState(), 'chat.windows');
}

function messageGroup(groupId, authorAccountId, message) {
  return {
    groupId,
    authorAccountId,
    messages: [message],
  };
}

export function resolveMessageGroups(messages) {
  return messages.reduce((groups, message) => {
    const messageAccountId = get(message, 'authorAccountId');

    if (groups.length === 0) {
      groups.push(messageGroup(1, messageAccountId, message));
      return groups;
    }

    const lastGroupAccountId = get(
      groups[groups.length - 1],
      'authorAccountId',
    );

    if (lastGroupAccountId === messageAccountId) {
      const groupMessages = get(groups[groups.length - 1], 'messages');
      groupMessages.push(message);
    } else {
      const lastGroupId = get(groups[groups.length - 1], 'groupId');
      groups.push(messageGroup(lastGroupId + 1, messageAccountId, message));
    }
    return groups;
  }, []);
}

export function findChatPopup(windows, filter) {
  return windows.find(filter);
}

export function existsChatPopup(windows, filter) {
  return !isUndefined(findChatPopup(windows, filter));
}
