import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { setOfferStatus } from '../redux';
import { OFFER_ACTIONS } from '../api';
import { Offer } from '../services';

export default function SetOfferStatusConfirmation({
  topCandidate,
  nextOfferStatus,
  offerId,
  project,
  role,
  onCancel,
}) {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  function handleSetOfferStatus() {
    setInProgress(true);
    const notification = `Offer to ${topCandidate.talent.fullName}
     as ${role.name} for ${project.title} set as ${nextOfferStatus}`;

    dispatch(setOfferStatus(offerId, OFFER_ACTIONS[`${nextOfferStatus}`]))
      .then(() => setInProgress(false))
      .then(onCancel)
      .then(() =>
        dispatch(
          showNotification({
            message: notification,
            type: Notification.TYPES.SUCCESS,
          }),
        ),
      );
  }

  function getTitle() {
    if (nextOfferStatus === Offer.RESOLVED_STATUSES_PRODUCER.CANCELED) {
      return `Are you sure you want to cancel this offer to ${topCandidate.talent.fullName}?`;
    }
    if (nextOfferStatus === Offer.RESOLVED_STATUSES_PRODUCER.HIRED) {
      return `Are you sure you want to mark ${topCandidate.talent.fullName} as hired?`;
    }

    return `Are you sure you want to mark offer to ${topCandidate.talent.fullName} as ${nextOfferStatus}?`;
  }

  function getSubtitle() {
    if (nextOfferStatus === Offer.RESOLVED_STATUSES_PRODUCER.CANCELED) {
      return `This action will send a cancellation email to ${topCandidate.talent.fullName} and cannot be undone.`;
    }
    if (nextOfferStatus === Offer.RESOLVED_STATUSES_PRODUCER.HIRED) {
      return ``;
    }

    return `This will result in marking this offer as ${nextOfferStatus}`;
  }

  return (
    <ConfirmationDialog
      title={getTitle()}
      subtitleText={getSubtitle()}
      onAction={handleSetOfferStatus}
      onCancel={onCancel}
      actionBtnLabel="Yes, I'm sure"
      cancelBtnLabel="Cancel"
      inProgress={inProgress}
      withOverlay
    />
  );
}

SetOfferStatusConfirmation.propTypes = {
  topCandidate: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  offerId: PropTypes.string.isRequired,
  nextOfferStatus: PropTypes.string.isRequired,
};
