import createSelector from 'selectorator';
import { createThunk } from 'redux-scope';
import { createReducer, createAction } from 'redux-starter-kit';
import filter from 'lodash/filter';
import union from 'lodash/unionBy';
import forEach from 'lodash/forEach';
import remove from 'lodash/remove';
import find from 'lodash/find';
import { Assets, uploadAsset } from 'modules/assets';
import {
  patchAgent,
  fetchAgent,
  postAgent,
  fetchAgentAssignments,
  patchAgentAssignment,
  postAgentAssignment,
  removeAgentAssignment,
  fetchAgentAssignmentsByAgentId,
} from './api';

const INITIAL_STATE = {
  agentAssignments: [],
  loading: true,
  agent: null,
  agents: [],
  loadingAgents: [],
};

export const loadAgent = createThunk(fetchAgent, 'load', 'agents');

export function loadAgents(agentAssignments) {
  return dispatch => {
    forEach(agentAssignments, agentAssignment => {
      const { agentId } = agentAssignment;
      if (agentId) {
        return dispatch(loadAgent(agentId));
      }
    });
  };
}

export const createAgent = createThunk(postAgent, 'create', 'agents');

export function uploadAgentProfileImage(accountId, imageFile) {
  return dispatch => {
    const context = Assets.createAccountContext(accountId);
    return dispatch(uploadAsset(imageFile, 'image', 'profileImage', context));
  };
}

const update = createThunk(patchAgent, 'update', 'agents');
export function updateAgent(accountId, agentId, values) {
  return dispatch => {
    const { profileImage } = values;

    if (profileImage) {
      return dispatch(uploadAgentProfileImage(accountId, profileImage)).then(
        assetPath => {
          const agentValues = {
            ...values,
            profileImage: assetPath,
          };

          return dispatch(update(agentId, agentValues));
        },
      );
    }

    return dispatch(update(agentId, values));
  };
}

function removeAgentFromLoading(loadingAgents, loadedAgent) {
  remove(loadingAgents, loadingAgent => {
    return loadingAgent === loadedAgent;
  });
  return loadingAgents;
}

export const loadAgentAssignments = createThunk(
  fetchAgentAssignments,
  'fetchAgentAssignments',
  'agentAssignments',
);
export const createAgentAssignment = createThunk(
  postAgentAssignment,
  'postAgentAssignment',
  'agentAssignments',
);
export const updateAgentAssignment = createThunk(
  patchAgentAssignment,
  'patchAgentAssignment',
  'agentAssignments',
);
export const deleteAgentAssignment = createThunk(
  removeAgentAssignment,
  'removeAgentAssignment',
  'agentAssignments',
);

export const loadAgentAssignmentsById = createThunk(
  fetchAgentAssignmentsByAgentId,
  'fetchAgentAssignmentsById',
  'agentAssignments',
);

export const clearAgentsData = createAction('agents/clearAgentsData');

export const reducer = createReducer(INITIAL_STATE, {
  [loadAgent.type.request]: (state, action) => {
    state.loading = true;
    state.loadingAgents = [...state.loadingAgents, action.request[0]];
  },
  [loadAgent.type.success]: (state, action) => {
    state.loading = false;
    state.agent = action.payload.data;
    state.agents = union([action.payload.data], state.agents);
    state.loadingAgents = removeAgentFromLoading(
      [...state.loadingAgents],
      action.request[0],
    );
  },
  [loadAgent.type.error]: state => {
    state.loading = false;
  },
  [createAgent.type.request]: state => {
    state.loading = true;
  },
  [createAgent.type.success]: (state, action) => {
    state.loading = false;
    state.agent = action.payload.data;
  },
  [update.type.request]: state => {
    state.loading = true;
  },
  [update.type.success]: (state, action) => {
    const updatedAgent = action.payload.data;

    state.loading = false;
    state.agent = updatedAgent;
  },
  [loadAgentAssignments.type.request]: state => {
    state.loading = true;
  },
  [loadAgentAssignments.type.success]: (state, action) => {
    state.agentAssignments = action.payload.data;
    state.loading = false;
  },
  [createAgentAssignment.type.request]: state => {
    state.loading = true;
  },
  [createAgentAssignment.type.success]: (state, action) => {
    state.loading = false;
    state.agentAssignments = [...state.agentAssignments, action.payload.data];
  },
  [updateAgentAssignment.type.request]: state => {
    state.loading = true;
  },
  [updateAgentAssignment.type.success]: (state, action) => {
    state.loading = false;
    state.agentAssignments = state.agentAssignments.map(assignment =>
      assignment.id !== action.payload.data.id
        ? assignment
        : action.payload.data,
    );
  },
  [deleteAgentAssignment.type.request]: state => {
    state.loading = true;
  },
  [deleteAgentAssignment.type.success]: (state, action) => {
    state.loading = false;
    state.agentAssignments = filter(
      state.agentAssignments,
      assignment => assignment.id !== action.request[0],
    );
  },
  [loadAgentAssignmentsById.type.request]: state => {
    state.loading = true;
  },
  [loadAgentAssignmentsById.type.success]: (state, action) => {
    state.agentAssignments = action.payload.data;
    state.loading = false;
  },
  [clearAgentsData]: state => {
    state.agent = null;
  },
});

export const getAgent = createSelector(['agents.agent']);
export const getAgentId = createSelector(['agents.agent.id']);
export const getAgentAssignments = createSelector(['agents.agentAssignments']);
export const getAgentsLoading = createSelector(['agents.loading']);
export const getAgents = createSelector(['agents.agents']);
export const getLoadingAgents = createSelector(['agents.loadingAgents']);
export const getAgentById = agentId =>
  createSelector(
    [getAgents],
    agents => find(agents, { id: agentId }),
  );
