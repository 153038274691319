import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { CardTag, SegmentedProgressBars } from 'modules/ui';
import { Colors } from 'modules/theme';
import { AuditionCard } from 'modules/auditions';
import { AUDITION_STATUSES } from '../const';
import styles from './SearchAuditionCard.module.scss';

function resolveTagBackgroundColor(status) {
  switch (status) {
    case AUDITION_STATUSES.SAVED:
      return 'none';
    case AUDITION_STATUSES.INVITED:
      return Colors.secondary;
    case AUDITION_STATUSES.APPLIED:
      return Colors.auditionAppliedTag;
    case AUDITION_STATUSES.SUGGESTED:
      return Colors.secondary;
    default:
      return null;
  }
}

function resolveTagTextColor(status) {
  switch (status) {
    case AUDITION_STATUSES.SAVED:
      return Colors.darkGrey;
    case AUDITION_STATUSES.INVITED:
      return 'white';
    case AUDITION_STATUSES.APPLIED:
      return 'white';
    case AUDITION_STATUSES.SUGGESTED:
      return 'white';
    default:
      return null;
  }
}

export default function SearchAuditionCard({
  audition,
  maxMatchRank,
  status,
  onClick,
}) {
  function resolveHeaderComponent() {
    const matchRank = get(audition, 'meta.matchRank');
    const titleCount = `${matchRank}/${maxMatchRank}`;
    const title = ` voice skills match`;

    const bgColor = resolveTagBackgroundColor(status);
    const txtColor = resolveTagTextColor(status);

    function resolveIcon() {
      if (status === AUDITION_STATUSES.SUGGESTED) {
        return AUDITION_STATUSES.INVITED;
      }
      return status;
    }
    
    return (
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <strong>{titleCount}</strong>
            {title}
          </div>
          <CardTag
            icon={resolveIcon()}
            text={status}
            background={bgColor}
            color={txtColor}
          />
          <SegmentedProgressBars
            maxBarsCount={maxMatchRank}
            activeBarsCount={matchRank}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.card}>
      <AuditionCard
        containerClassName={styles.cardContainer}
        audition={audition}
        HeaderComponent={resolveHeaderComponent()}
        onClick={onClick}
      />
    </div>
  );
}

SearchAuditionCard.propTypes = {
  audition: PropTypes.object,
  maxMatchRank: PropTypes.number,
  status: PropTypes.string,
  onClick: PropTypes.func,
};
