import { Api } from 'modules/core';
import toString from 'lodash/join';

const PRODUCER_INCLUDES = [
  'producer',
  'projectRole.project',
  'country',
  'state',
];

export function fetchProducer(producerId) {
  if (!producerId) {
    return null;
  }

  const endpoint = `producers/${producerId}`;
  const params = { include: toString(PRODUCER_INCLUDES) };

  return Api.find(endpoint, params);
}

export function fetchSharedProducer(token) {
  if (!token) {
    return Promise.resolve();
  }

  const headers = { headers: { 'auth-code': token } };

  return Api.find(`shared/producers`, {}, {}, headers);
}

export function fetchProducerStats(producerId) {
  if (!producerId) {
    return null;
  }

  const endpoint = `producers/${producerId}/stats`;

  return Api.find(endpoint);
}

export function fetchProducerAuditions(filter, pageLimit = 1000) {
  const endpoint = `auditions`;
  const params = {
    include: toString(PRODUCER_INCLUDES),
    filter,
    'page[limit]': pageLimit,
  };

  return Api.find(endpoint, params);
}

export function postProducer(firstName, lastName) {
  const endpoint = 'producers';
  const producer = { resourceType: 'producers', firstName, lastName };

  return Api.create(endpoint, producer);
}

export function patchProducer(producerId, values) {
  const endpoint = `producers/${producerId}`;

  return Api.update(endpoint, values, 'producers');
}

export function searchProducers(searchOptions) {
  const endpoint = 'producers/actions/searchByName';
  const data = {
    resourceType: 'producer-name-search-actions',
    ...searchOptions,
  };

  return Api.create(endpoint, data);
}
