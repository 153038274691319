import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import delay from 'lodash/delay';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Colors } from 'modules/theme';
import { Page } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { WelcomeLogo, AuthHeader, AUTH_CODES, logout } from 'modules/auth';
import {
  verifyChangedEmail,
  getVerifyChangedEmailError,
  getVerifyChangedEmailSuccess,
} from '../redux';

const styles = {
  container: {
    paddingTop: 64,
    textAlign: 'center',
  },
  title: {
    paddingTop: 60,
    paddingBottom: 25,
    fontWeight: 'bold',
    fontSize: 24,
    alignItems: 'center',
    textAlign: 'center',
  },
  error: {
    paddingTop: 60,
    color: Colors.error,
  },
};

export default function ChangedEmailVerificationPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const verificationError = useSelector(getVerifyChangedEmailError);
  const verificationSuccess = useSelector(getVerifyChangedEmailSuccess);

  useEffect(() => {
    const email = get(params, 'email');

    dispatch(verifyChangedEmail(email));
  }, [dispatch, params]);

  useEffect(() => {
    if (verificationSuccess) {
      dispatch(
        showNotification({
          message: 'Your new email address is verified',
          type: Notification.TYPES.SUCCESS,
        }),
      );
      delay(() => {
        dispatch(logout());
      }, 3000);
    }
  }, [verificationSuccess, history, dispatch]);

  useEffect(() => {
    if (verificationError) {
      return history.push({
        pathname: '/settings/change-email',
        state: {
          code: AUTH_CODES.VERIFICATION_LINK_EXPIRED,
        },
      });
    }
  }, [verificationError, history]);

  return (
    <Page
      className="pt-64"
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/"
          linkLabel={`Return to ${window.location.hostname}`}
        />
      }
    >
      <div style={styles.container}>
        <WelcomeLogo />
        <div style={styles.title}>Your new email is being verified</div>
        <Loader type="Oval" color={Colors.darkBlue} width={40} height={40} />
      </div>
    </Page>
  );
}
