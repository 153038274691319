import React from 'react';
import { pickBy, pick, isEmpty } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Section, SocialLinks } from 'modules/ui';

function getSocialMediaLinks(person) {
  return pick(person, [
    'facebookLink',
    'instagramLink',
    'twitterLink',
    'linkedinLink',
    'imdbLink',
  ]);
}

const EMPTY_LINKS_NOTICE = 'You haven’t added any social links.';

export default function SocialLinksSection({
  person,
  showTitle,
  emptyLinksNotice,
}) {
  const socialMediaLinks = getSocialMediaLinks(person);
  const populatedLinks = pickBy(socialMediaLinks);

  const textClasses = classNames(
    'body16',
    isEmpty(populatedLinks) && 'grayishBlueColor',
  );

  return (
    <Section
      title={showTitle && 'Social Networks'}
      isEmpty={isEmpty(populatedLinks)}
      PlaceholderComponent={
        <p className={textClasses}>{emptyLinksNotice || EMPTY_LINKS_NOTICE}</p>
      }
    >
      <SocialLinks links={populatedLinks} />
    </Section>
  );
}

SocialLinksSection.propTypes = {
  person: PropTypes.object,
  showTitle: PropTypes.bool,
  emptyLinksNotice: PropTypes.string,
};

SocialLinksSection.defaultProps = {
  showTitle: true,
};
