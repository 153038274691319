import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  resolveRedirectionPath,
  getRedirection,
  hasRedirection,
} from '../services';
import { getIsAuthenticated } from '../redux';

function RedirectionProvider({ history }) {
  const nextAuthenticated = useSelector(getIsAuthenticated);
  const nextLocation = history.location.pathname + history.location.search;

  const [prevLocation, setPrevLocation] = useState(nextLocation);
  const [prevAuthenticated, setPrevAuthenticated] = useState(nextAuthenticated);

  useEffect(() => {
    // sign out action
    if (!nextAuthenticated && prevAuthenticated !== nextAuthenticated) {
      setPrevAuthenticated(nextAuthenticated);
      return history.push('/login');
    }

    if (prevAuthenticated !== nextAuthenticated) {
      setPrevAuthenticated(nextAuthenticated);
    }

    if (!hasRedirection(prevLocation) && !hasRedirection(nextLocation)) {
      return setPrevLocation(nextLocation);
    }

    if (hasRedirection(prevLocation) && nextAuthenticated) {
      const redirection = getRedirection(prevLocation);
      setPrevLocation(redirection);

      return history.push(redirection);
    }

    if (hasRedirection(prevLocation) && !hasRedirection(nextLocation)) {
      const redirection = getRedirection(prevLocation);
      const path = resolveRedirectionPath(nextLocation, redirection);
      setPrevLocation(path);

      return history.push(path);
    }

    return setPrevLocation(nextLocation);
  }, [
    history,
    nextAuthenticated,
    nextLocation,
    prevAuthenticated,
    prevLocation,
  ]);

  return null;
}

export default withRouter(RedirectionProvider);
