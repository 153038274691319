import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Section, EmptyPlaceholder } from 'modules/ui';
import { AuditionCard } from 'modules/auditions';
import classes from './AuditionsSection.module.scss';

function AuditionsSection({
  activeAuditions,
  onAuditionClick,
  loading,
  placeholderText,
  ...otherProps
}) {
  const hasAuditions = !isEmpty(activeAuditions);

  const placeholder = (
    <EmptyPlaceholder className="mt-36" description={placeholderText} />
  );

  return (
    <Section
      title="Active Open Call Auditions"
      isEmpty={!hasAuditions}
      loading={loading}
      PlaceholderComponent={placeholder}
      className="mb-36"
      {...otherProps}
    >
      <Grid container spacing={2}>
        {map(activeAuditions, activeAudition => {
          const key = get(activeAudition, 'id');
          return (
            <Grid item xs={3}>
              <AuditionCard
                key={key}
                audition={activeAudition}
                onClick={onAuditionClick}
                containerClassName={classes.cardContainer}
              />
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
}

AuditionsSection.propTypes = {
  onAuditionClick: PropTypes.func,
  loading: PropTypes.bool,
  placeholderText: PropTypes.string,
  activeAuditions: PropTypes.arrayOf(PropTypes.object),
};

export default AuditionsSection;
