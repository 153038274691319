import React, { Children, memo } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavigationTabLink, TabLayout } from 'modules/ui';

function NavigationTabs({ children, history }) {
  const renderTabLink = (tabRoute, key) => {
    const {
      props: { label, path },
    } = tabRoute;

    return (
      <NavigationTabLink
        exact
        to={path}
        key={key}
        onClick={() => history.push()}
      >
        {label}
      </NavigationTabLink>
    );
  };

  const tabs = Children.map(children, renderTabLink);

  return (
    <TabLayout tabs={tabs}>
      <Switch>{children}</Switch>
    </TabLayout>
  );
}

NavigationTabs.propTypes = {
  history: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default memo(withRouter(NavigationTabs));
