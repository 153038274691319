import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import values from 'lodash/values';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';

import { Page, SearchHeader, HorizontalMenu, ActionBanner } from 'modules/ui';
import {
  useRouteListener,
  useScrollRestoration,
  useWindowSize,
  MOBILE_WIDTH,
} from 'modules/core';
import { loadCovers, getCovers } from 'modules/projects';
import { useTealiumView, getAccountType, AccountType } from 'modules/accounts';
import { getTalent, Talent } from 'modules/talents';

import {
  searchAuditions,
  getAuditionsFilterData,
  setAuditionsFilterData,
} from '../redux';

import {
  AUDITIONS_DEFAULT_ROWS_PER_PAGE as DEFAULT_ROWS_PER_PAGE,
  AUDITIONS_DEFAULT_ROWS_PER_PAGE_MOBILE as DEFAULT_ROWS_PER_PAGE_MOBILE,
  AUDITIONS_TALENT_DEFAULT_SORT as TALENT_DEFAULT_SORT,
  AUDITIONS_AGENT_DEFAULT_SORT as AGENT_DEFAULT_SORT,
  AUDITIONS_TABS as TABS,
  AUDITIONS_PLACEHOLDERS as PLACEHOLDERS,
  AUDITIONS_DESCRIPTIONS as DESCRIPTIONS,
  AUDITIONS_REGEX,
} from '../const';
import {
  SearchForm,
  SearchAuditionList,
  SearchPageContentHeader,
} from '../components';

const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 78,
  },
  content: {
    paddingLeft: 55,
    width: '100%',
    minHeight: '100vh',
  },
  noPadding: {
    paddingTop: 0,
    paddingLeft: 0,
    width: '90%',
    margin: 'auto',
    justifyContent: 'center',
  },
  mobileContainer: {
    width: 300,
  },
};

const resolveSearchData = data => {
  const { offset, limit } = data;
  const minLimit = limit + offset;
  return [
    data,
    {
      ...data,
      limit: minLimit,
      offset: 0,
      invited: true,
      applied: false,
    },
    { ...data, limit: minLimit, offset: 0, saved: true, applied: false },
    { ...data, limit: minLimit, offset: 0, applied: true },
    { suggested: true, applied: false },
  ];
};

const resolveSearchPlaceholderText = activeTab => {
  return PLACEHOLDERS[activeTab];
};

export default function AuditionSearchPage({ history }) {
  const dispatch = useDispatch();
  const { initialWindowWidth, desktopWidth, size } = useWindowSize();
  const accountType = useSelector(getAccountType);

  const initialRowsPerPage =
    initialWindowWidth <= MOBILE_WIDTH
      ? DEFAULT_ROWS_PER_PAGE_MOBILE
      : DEFAULT_ROWS_PER_PAGE;

  function resolveDefaultSort() {
    if (AccountType.isTalent(accountType)) {
      return TALENT_DEFAULT_SORT;
    }
    return AGENT_DEFAULT_SORT;
  }
  const flippedMobileWidth = size[0] <= 760;

  const contentContainerStyle = !desktopWidth
    ? { ...styles.contentContainer, ...styles.noPadding }
    : styles.contentContainer;
  const contentStyleDesktop = !desktopWidth
    ? { ...styles.content, ...styles.noPadding }
    : styles.content;
  const contentStyle = flippedMobileWidth
    ? { ...contentStyleDesktop, ...styles.mobileContainer }
    : contentStyleDesktop;

  const auditionsFilterData = useSelector(getAuditionsFilterData);
  const talent = useSelector(getTalent);
  const coverImages = useSelector(getCovers);
  const [filterValues, setFilterValues] = useState(
    get(auditionsFilterData, 'filterValues') || null,
  );
  const [searchValue, setSearchValue] = useState(
    get(auditionsFilterData, 'searchValue') || null,
  );
  const [activeTab, setActiveTab] = useState(
    get(auditionsFilterData, 'activeTab') || TABS.ALL,
  );
  const [sort, setSort] = useState(
    get(auditionsFilterData, 'sort') || resolveDefaultSort(),
  );
  const [page, setPage] = useState(get(auditionsFilterData, 'page') || 0);

  const [rowsPerPage, setRowsPerPage] = useState(
    get(auditionsFilterData, 'rowsPerPage') || initialRowsPerPage,
  );
  const [loading, setLoading] = useState(false);

  const [searchedAuditions, setSearchedAuditions] = useState({});
  const [searchedAuditionsMeta, setSearchedAuditionsMeta] = useState({});
  const [counts, setCounts] = useState({});
  const [maxMatchRanks, setMaxMatchRanks] = useState({});
  const [tabs, setTabs] = useState({});
  const [scrollY, setScrollY] = useState(get(auditionsFilterData, 'scrollY'));
  const [canApply, setCanApply] = useState(true);
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  useTealiumView('Auditions Page');

  useRouteListener(
    AUDITIONS_REGEX,
    auditionId =>
      dispatch(
        setAuditionsFilterData({
          auditionId,
          page,
          filterValues,
          sort,
          searchValue,
          activeTab,
          rowsPerPage,
          scrollY: window.scrollY,
        }),
      ),
    [page, filterValues, sort, searchValue, activeTab, rowsPerPage],
  );

  useScrollRestoration(scrollY, [
    dispatch,
    page,
    filterValues,
    sort,
    searchValue,
    activeTab,
    rowsPerPage,
    loading,
  ]);

  function resetScrollY() {
    dispatch(
      setAuditionsFilterData({
        ...auditionsFilterData,
        scrollY: 0,
      }),
    );
    setScrollY(0);
  }

  const searchAuditionsByCategory = useCallback(
    data => {
      const allSearchData = resolveSearchData(data);
      return map(allSearchData, searchData =>
        dispatch(searchAuditions(searchData)),
      );
    },
    [dispatch],
  );

  function resolveNewSearchData(payload) {
    let auditions = {};
    let meta = {};
    let suggestedAuditionsIds = [];
    const indexes = values(TABS);

    map(payload, (result, index) => {
      const tabIndex = indexes[index];

      // Suggested auditions via Agent are pushed inside the invited array
      if (tabIndex === TABS.SUGGESTED) {
        const suggestedAuditions = get(result, 'data');
        suggestedAuditionsIds = map(
          suggestedAuditions,
          audition => audition.id,
        );

        const suggestedAuditionsCount = get(result, 'meta').count;

        auditions[TABS.INVITED].push(...suggestedAuditions);

        meta[TABS.INVITED] = {
          ...meta[TABS.INVITED],
          count: meta[TABS.INVITED].count + suggestedAuditionsCount,
        };
      } else {
        auditions = {
          ...auditions,
          [tabIndex]: get(result, `data`, []),
        };

        meta = {
          ...meta,
          [tabIndex]: get(result, `meta`, []),
        };
      }
    });

    // Suggested tag added to suggested via Agent auditions
    const auditionsFinal = {
      ...auditions,
      [TABS.ALL]: [
        ...map(auditions[TABS.ALL], audition => {
          if (includes(suggestedAuditionsIds, audition.id)) {
            return { ...audition, suggested: true };
          }
          return audition;
        }),
      ],
      [TABS.INVITED]: [
        ...map(auditions[TABS.INVITED], audition => {
          if (includes(suggestedAuditionsIds, audition.id)) {
            return { ...audition, suggested: true };
          }
          return audition;
        }),
      ],
    };

    setSearchedAuditions(auditionsFinal);
    setSearchedAuditionsMeta(meta);
  }

  useEffect(() => {
    const newCounts = mapValues(searchedAuditionsMeta, metaData =>
      get(metaData, 'count', 0),
    );
    const newMaxMatchRanks = mapValues(searchedAuditionsMeta, metaData =>
      get(metaData, 'maxMatchRank', 0),
    );

    setCounts(newCounts);
    setMaxMatchRanks(newMaxMatchRanks);
  }, [searchedAuditionsMeta]);

  // Search auditions
  useEffect(() => {
    const newTabs = [
      { index: TABS.ALL, label: 'All Auditions' },
      {
        index: TABS.INVITED,
        label: `Invitations (${counts[TABS.INVITED] || 0})`,
      },
      {
        index: TABS.SAVED,
        label: `Saved (${counts[TABS.SAVED] || 0})`,
      },
      {
        index: TABS.APPLIED,
        label: `Applied (${counts[TABS.APPLIED] || 0})`,
      },
    ];

    setTabs(newTabs);
  }, [counts]);

  // Search auditions
  useEffect(() => {
    const searchData = {
      sort,
      query: searchValue,
      offset: rowsPerPage * page,
      limit: rowsPerPage,
      ...filterValues,
    };

    const apiCalls = searchAuditionsByCategory(searchData);
    setLoading(true);

    Promise.all(apiCalls)
      .then(resolveNewSearchData)
      .catch(error => {
        // do something with an error
      })
      .finally(() => setLoading(false));
  }, [
    dispatch,
    filterValues,
    searchValue,
    rowsPerPage,
    page,
    sort,
    searchAuditionsByCategory,
  ]);

  useEffect(() => {
    if (talent) {
      setCanApply(Talent.isProfileVisible(talent));
    }
  }, [talent]);

  useEffect(() => {
    if (searchedAuditions) {
      const coverImagePathsArray = map(searchedAuditions.all, audition => {
        const project = get(audition, 'projectRole.project');
        if (project.coverImage) {
          const { coverImage } = project;
          return coverImage;
        }
        return null;
      });
      const filteredImagePaths = filter(coverImagePathsArray, el => {
        return el != null;
      });
      
      dispatch(loadCovers(filteredImagePaths, coverImages));
    }
  }, [dispatch, searchedAuditions, coverImages]);

  function resetPagination() {
    setPage(0);
  }

  function handleSetFormValue(value) {
    const newValues = { ...filterValues, ...value };
    resetPagination();

    global.utag.link({
      event_type: 'filter_click',
      page_type: 'Auditions Page',
      module_type: 'Filter Click',
      module_variation: `${Object.keys(value)[0]} | ${Object.values(value)[0]}`,
    });
    resetScrollY();
    setFilterValues(newValues);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    resetScrollY();
    window.scrollTo(0, 0);
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  const debouncedHandleSearchChange = debounce(value => {
    resetPagination();
    setSearchValue(value);
  }, 500);

  function handleSortSet(name, value) {
    resetPagination();
    resetScrollY();
    setSort(value);
  }

  function handleClearSearch() {
    resetPagination();
    setSearchValue(null);
  }

  function handleReset() {
    resetPagination();
    resetScrollY();
    setFilterValues(null);
  }

  function handleTabChange(index) {
    resetPagination();
    resetScrollY();
    setActiveTab(index);
  }

  function handleAuditionClick(audition) {
    history.push(`auditions/${audition.id}`);
  }

  function resolveAuditionsForActiveTab() {
    return searchedAuditions[activeTab];
  }

  function resolveDescription() {
    const activeTabCount = counts[activeTab] || 0;

    if (activeTabCount === 0) return '';

    return `${DESCRIPTIONS[activeTab]} ${activeTabCount} ${
      activeTab === TABS.APPLIED ? 'active ' : ''
    }${pluralize('audition', activeTabCount)}`;
  }

  function onEditProfileClick() {
    history.push('/profile/edit');
  }

  function resolveFiltersStatus() {
    if (filterValues || searchValue) {
      return true;
    }
    return false;
  }

  return (
    <Page
      contentStyle={contentContainerStyle}
      HeaderComponent={
        <SearchHeader
          title={!flippedMobileWidth && 'Ahab Auditions'}
          description={
            desktopWidth &&
            (AccountType.isTalent(accountType)
              ? 'Here is the list of the available Auditions. Find the ones that best fit your skill set and apply.'
              : 'Here is the list of the available Auditions. Find the ones that best fit your clients’ skill sets.')
          }
          placeholder="Search Audition"
          defaultValue={searchValue}
          onChange={debouncedHandleSearchChange}
          clear={handleClearSearch}
        />
      }
      BannerComponent={
        !canApply && (
          <ActionBanner
            text={
              <p>
                <strong>Alert!</strong> Your profile isn’t yet visible on Ahab.
                Complete the required fields to audition.
              </p>
            }
            onButtonClick={desktopWidth && onEditProfileClick}
            buttonLabel={desktopWidth && 'Edit profile'}
          />
        )
      }
    >
      <SearchForm
        values={filterValues}
        setFormValue={handleSetFormValue}
        reset={handleReset}
        filtersExpanded={filtersExpanded}
        onCloseFilters={() => setFiltersExpanded(false)}
      />
      <div style={contentStyle}>
        {AccountType.isTalent(accountType) && desktopWidth && (
          <HorizontalMenu
            tabs={tabs}
            initialTabIndex={activeTab}
            onTabChange={handleTabChange}
          />
        )}
        <SearchPageContentHeader
          isTalent={AccountType.isTalent(accountType)}
          description={resolveDescription()}
          onExpandFilters={() => setFiltersExpanded(true)}
          filterValues={filterValues}
          onSortSet={handleSortSet}
          sort={sort}
        />
        <SearchAuditionList
          loading={loading}
          page={page}
          count={counts[activeTab] || 0}
          maxMatchRank={maxMatchRanks[activeTab] || 0}
          rowsPerPage={rowsPerPage}
          filterStatus={resolveFiltersStatus()}
          auditions={resolveAuditionsForActiveTab()}
          savedAuditionIds={map(searchedAuditions[TABS.SAVED], 'id')}
          invitedAuditionIds={map(searchedAuditions[TABS.INVITED], 'id')}
          appliedAuditionIds={map(searchedAuditions[TABS.APPLIED], 'id')}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onClick={handleAuditionClick}
          placeholderTitle={get(
            resolveSearchPlaceholderText(activeTab),
            'title',
          )}
          placeholderDescription={get(
            resolveSearchPlaceholderText(activeTab),
            'description',
          )}
          desktopWidth={desktopWidth}
        />
      </div>
    </Page>
  );
}

AuditionSearchPage.propTypes = {
  history: PropTypes.object,
};
