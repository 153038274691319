import { createReducer } from 'redux-starter-kit';
import createSelector from 'selectorator';
import _, { memoize } from 'lodash';
import { createThunk } from 'redux-scope';
import { fetchPublishers, postPublisher } from './api';

const INITIAL_STATE = {
  publishers: [],
};

export const loadPublishers = createThunk(
  fetchPublishers,
  'load',
  'publishers',
);
export const createPublisher = createThunk(
  postPublisher,
  'create',
  'publishers',
);

export const reducer = createReducer(INITIAL_STATE, {
  [loadPublishers.type.request]: state => {
    state.loading = true;
  },
  [loadPublishers.type.success]: (state, action) => {
    state.loading = false;
    state.publishers = action.payload.data;
  },
  [createPublisher.type.request]: state => {
    state.loading = true;
  },
});

export const getPublishers = createSelector(['publishers.publishers']);

export const makeGetPublisherById = createSelector(
  ['publishers.publishers'],
  items => memoize(id => _.find(items, item => item.id === id)),
);
