import React from 'react';
import { NavigationTabLink } from 'modules/ui';

export default function ProducerProfileTabs() {
  return (
    <>
      <NavigationTabLink exact to="/profile/edit">
        Profile Details
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/social-media-links">
        Social Media Links
      </NavigationTabLink>
    </>
  );
}
