import React from 'react';
import PropTypes from 'prop-types';

import { LineDivider, Card, Avatar } from 'modules/ui';
import DropdownMenuHeading from './DropdownMenuHeading';

import AccountDropdownMenuItem from './AccountDropdownMenuItem';
import classes from './MobileNavBarAccountCard.module.scss';

export default function MobileNavBarAccountCard({
  avatar,
  fullName,
  city,
  state,
  country,
  onProfile,
  onEditProfile,
  onSettings,
  onLogout,
}) {
  return (
    <Card className={classes.card}>
      <div className={classes.cardHeader}>
        <Avatar photoUrl={avatar} size={80} />
        <DropdownMenuHeading
          className={classes.dropdownHeading}
          fullName={fullName}
          city={city}
          state={state}
          avatar={avatar}
          country={country}
          onProfile={onProfile}
        />
      </div>

      {/* When we add responsivnes to edit profile and settings pages
      <LineDivider />
      <AccountDropdownMenuItem text="Edit Profile" onClick={onEditProfile} />
      <AccountDropdownMenuItem text="Settings" onClick={onSettings} /> 
      */}
      <LineDivider />
      <AccountDropdownMenuItem text="Sign out" onClick={onLogout} />
    </Card>
  );
}

MobileNavBarAccountCard.propTypes = {
  fullName: PropTypes.string.isRequired,
  onProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onSettings: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  avatar: PropTypes.string,
};
