import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { useDispatch, useSelector } from 'react-redux';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { getAvatar, loadAvatar } from 'modules/accounts';
import { showNotification, Notification } from 'modules/notifications';
import { getTalent, updateTalent } from '../redux';
import { PersonalInfoForm, TalentProfileTabs } from '../components';
import Validator from '../validator';

export default function EditPersonalPage() {
  const dispatch = useDispatch();
  const talent = useSelector(getTalent);
  const avatar = useSelector(getAvatar);

  function handleSubmitEthnicityGdprConsent() {
    return dispatch(
      updateTalent(talent.account.id, talent.id, {
        ethnicityGdprAcceptedAt: true,
      }),
    );
  }

  function handleCloseEthnicityGdprConsent() {
    return dispatch(
      updateTalent(talent.account.id, talent.id, {
        ethnicities: null,
      }),
    );
  }

  function handleSubmitPersonalInfo(values) {
    const { firstName, lastName, city } = values;

    function resolveCity() {
      if (!city || trim(city) === '') {
        return null;
      }
      return trim(city);
    }

    return dispatch(
      updateTalent(talent.account.id, talent.id, {
        ...values,
        ...(firstName && { firstName: trim(firstName) }),
        ...(lastName && { lastName: trim(lastName) }),
        ...(city !== undefined && { city: resolveCity() }),
      }),
    )
      .then(payload => {
        const profileImage = get(payload, 'data.profileImage');
        dispatch(loadAvatar(profileImage));

        global.utag.link({
          event_type: 'edit_profile',
          module_type: 'Edit profile',
          module_variation: 'Personal Info',
        });

        dispatch(
          showNotification({
            message: 'Personal info successfully saved',
            type: Notification.TYPES.SUCCESS,
          }),
        );
        return payload;
      })
      .catch(payload => payload);
  }

  if (!talent) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<TalentProfileTabs />}>
        <PersonalInfoForm
          updatedValues={talent}
          talent={talent}
          image={avatar}
          validate={Validator.validatePersonalInfo}
          onSubmit={handleSubmitPersonalInfo}
          onSubmitEthnicityGdprConsent={handleSubmitEthnicityGdprConsent}
          onCloseEthnicityGdprConsent={handleCloseEthnicityGdprConsent}
        />
      </TabLayout>
    </Page>
  );
}
