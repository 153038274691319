import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useWindowSize } from 'modules/core';

import classes from './DropdownMenuHeading.module.scss';

function DropdownMenuHeading({
  className,
  fullName,
  city,
  state,
  country,
  onProfile,
}) {
  const address = [city, state, country].filter(Boolean).join(', ');
  const { desktopWidth } = useWindowSize();
  return (
    <div className={className}>
      <p className={classes.name}>{fullName}</p>
      {address && <p className={classes.location}>{address}</p>}
      {desktopWidth && (
        <Link
          to="/profile"
          className={classes.viewProfileLink}
          onClick={onProfile}
        >
          View Profile
        </Link>
      )}
    </div>
  );
}

DropdownMenuHeading.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  onProfile: PropTypes.func,
};

export default memo(DropdownMenuHeading);
