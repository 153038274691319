import moment from 'moment';
import toUpper from 'lodash/toUpper';
import Dictionary from 'modules/dictionary';

const RESOLVED_STATUSES_TALENT = {
  ACTIVE: 'active',
  NEGOTIATING: 'negotiating',
  CLOSED: 'closed',
  DECLINED: 'declined',
  HIRED: 'hired',
  EXPIRED: 'expired',
};

const RESOLVED_STATUSES_PRODUCER = {
  ...Dictionary.OFFER_STATUSES,
  HIRED: 'hired',
  EXPIRED: 'expired',
};

function isOfferExpired(offer) {
  const { dueDate } = offer;
  const dueDatePassed = moment(dueDate).isBefore(moment(), 'day');

  return (
    dueDatePassed &&
    (offer.status === Dictionary.OFFER_STATUSES.SENT ||
      offer.status === Dictionary.OFFER_STATUSES.NEGOTIATING)
  );
}

function getStatusForProducer(status) {
  if (status === Dictionary.OFFER_STATUSES.ACCEPTED) {
    return RESOLVED_STATUSES_PRODUCER.HIRED;
  }

  return RESOLVED_STATUSES_PRODUCER[toUpper(status)];
}

function getStatusForTalent(status) {
  switch (status) {
    case Dictionary.OFFER_STATUSES.NEGOTIATING:
      return RESOLVED_STATUSES_TALENT.NEGOTIATING;
    case Dictionary.OFFER_STATUSES.SENT:
      return RESOLVED_STATUSES_TALENT.ACTIVE;

    case Dictionary.OFFER_STATUSES.CANCELED:
      return RESOLVED_STATUSES_TALENT.CLOSED;

    case Dictionary.OFFER_STATUSES.DECLINED:
      return RESOLVED_STATUSES_TALENT[toUpper(status)];

    case Dictionary.OFFER_STATUSES.ACCEPTED:
      return RESOLVED_STATUSES_TALENT.HIRED;

    default:
      return 'Invalid status';
  }
}

function getResolvedStatus(offer, isTalent) {
  if (!offer) {
    return undefined;
  }

  if (isOfferExpired(offer)) {
    return RESOLVED_STATUSES_PRODUCER.EXPIRED;
  }

  if (isTalent) {
    return getStatusForTalent(offer.status);
  }

  return getStatusForProducer(offer.status);
}

function getTalentBannerText(offer, talentHasAgent, talentAgentName) {
  if (isOfferExpired(offer)) {
    return 'This Offer has expired.';
  }

  switch (offer.status) {
    case Dictionary.OFFER_STATUSES.SENT:
      return talentHasAgent
        ? `This Offer has been sent to your agent ${talentAgentName}. Please reach out to your agent to see the status of this Offer.`
        : '';

    case Dictionary.OFFER_STATUSES.NEGOTIATING:
      return talentHasAgent
        ? `This Offer has been sent to your agent ${talentAgentName}. Please reach out to your agent to see the status of this Offer.`
        : 'You responded to this Offer. Please look out for an email or phone call from the Content Creator.';

    case Dictionary.OFFER_STATUSES.CANCELED:
      return 'This Offer is closed.';

    case Dictionary.OFFER_STATUSES.DECLINED:
      return 'You have declined this Offer.';

    case Dictionary.OFFER_STATUSES.ACCEPTED:
      return 'You are hired for this role!';

    default:
      return '';
  }
}

function getAgentBannerText(offer) {
  if (isOfferExpired(offer)) {
    return 'This Offer has expired.';
  }

  switch (offer.status) {
    case Dictionary.OFFER_STATUSES.NEGOTIATING:
      return 'You responded to this Offer. Please look out for an email or phone call from the Content Creator.';

    case Dictionary.OFFER_STATUSES.CANCELED:
      return 'This Offer is closed.';

    case Dictionary.OFFER_STATUSES.DECLINED:
      return 'You have declined this Offer.';

    case Dictionary.OFFER_STATUSES.ACCEPTED:
      return 'Your Client is hired for this role!';

    default:
      return '';
  }
}

export default {
  isOfferExpired,
  getResolvedStatus,
  getStatusForProducer,
  getTalentBannerText,
  RESOLVED_STATUSES_TALENT,
  RESOLVED_STATUSES_PRODUCER,
  getAgentBannerText,
};
