import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import { MenuItem, DropdownButton } from 'modules/ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = {
  moreButton: {
    height: 'auto',
    minWidth: 'auto',
    background: 'transparent',
  },
};

export const TALENT_VISIBILITY_STATUS = {
  VISIBLE: 0,
  NOTVISIBLE: 1,
};

function TalentCardDropdown({
  profileLink,
  onAddToList,
  onRemoveFromList,
  onShareProfile,
  onSetVisibility,
  talentVisibleStatus,
}) {
  const dropdownRef = useRef(null);

  return (
    <DropdownButton
      ref={dropdownRef}
      iconColor={Colors.darkGrey}
      buttonStyle={styles.moreButton}
      arrowRightPosition="11px"
    >
      {onAddToList && (
        <MenuItem text="Add to list" icon="addUser" onClick={onAddToList} />
      )}
      {talentVisibleStatus === TALENT_VISIBILITY_STATUS.VISIBLE && (
        <MenuItem
          text="Hide Client"
          icon="eye_blocked"
          onClick={onSetVisibility}
        />
      )}
      {talentVisibleStatus === TALENT_VISIBILITY_STATUS.NOTVISIBLE && (
        <MenuItem text="Add to my Profile" icon="eye" onClick={onSetVisibility} />
      )}
      {onShareProfile && (
        <CopyToClipboard text={profileLink} onCopy={onShareProfile}>
          <MenuItem
            text="Copy Profile link"
            icon="share"
            onClick={onShareProfile}
          />
        </CopyToClipboard>
      )}
      {onRemoveFromList && (
        <MenuItem
          text="Remove from list"
          icon="trash"
          onClick={onRemoveFromList}
        />
      )}
    </DropdownButton>
  );
}

TalentCardDropdown.propTypes = {
  profileLink: PropTypes.string.isRequired,
  onAddToList: PropTypes.func.isRequired,
  onRemoveFromList: PropTypes.func,
  onShareProfile: PropTypes.func.isRequired,
  onSetVisibility: PropTypes.func,
  talentVisibleStatus: PropTypes.number,
};

export default TalentCardDropdown;
