import React from 'react';
import PropTypes from 'prop-types';

import styles from './Quotation.module.scss';

export default function Quotation({ quote, author, className }) {
  return (
    <div className={className}>
      <p className={styles.quote}>{`"${quote}"`}</p>
      <p className={styles.author}>{author}</p>
    </div>
  );
}

Quotation.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  className: PropTypes.string,
};
