import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import Loader from 'react-loader-spinner';
import { TablePagination, EmptySearchPlaceholder } from 'modules/ui';
import { Colors } from 'modules/theme';
import { TalentAudition } from 'modules/auditions-talents';
import { NO_OPEN_AUDITIONS_PLACEHOLDER } from '../const';
import SearchAuditionCard from './SearchAuditionCard';

const ITEMS_PER_PAGE_PAGINATION = [15, 30, 60, 90, 150];

const styles = {
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 10,
    margin: -12,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 50,
  },
  containerMobileWidth: {
    width: '300px',
  },
};

export default function SearchAuditionList({
  loading,
  page,
  count,
  maxMatchRank,
  rowsPerPage,
  auditions,
  savedAuditionIds,
  invitedAuditionIds,
  appliedAuditionIds,
  onChangePage,
  onChangeRowsPerPage,
  onClick,
  placeholderTitle,
  placeholderDescription,
  desktopWidth,
  filterStatus,
}) {
  function resolveEmptyPlaceholders() {
    if (filterStatus) {
      return {
        title: placeholderTitle,
        description: placeholderDescription,
      };
    }
    return NO_OPEN_AUDITIONS_PLACEHOLDER;
  }

  if (loading) {
    return (
      <div style={styles.loader}>
        <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
      </div>
    );
  }

  if (count === 0) {
    return (
      <EmptySearchPlaceholder
        title={resolveEmptyPlaceholders().title}
        description={resolveEmptyPlaceholders().description}
        description2={resolveEmptyPlaceholders().description2}
      />
    );
  }

  return (
    <div>
      <div style={styles.cardContainer}>
        {map(auditions, audition => {
          const status = TalentAudition.resolveAuditionStatus(
            audition,
            savedAuditionIds,
            invitedAuditionIds,
            appliedAuditionIds,
          );
          return (
            <SearchAuditionCard
              audition={audition}
              maxMatchRank={maxMatchRank}
              status={status}
              onClick={onClick}
            />
          );
        })}
      </div>
      <TablePagination
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={!desktopWidth ? 0 : ITEMS_PER_PAGE_PAGINATION}
        rowsPerPageLabel="Auditions Per Page"
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
}

SearchAuditionList.propTypes = {
  loading: PropTypes.bool,
  page: PropTypes.number,
  count: PropTypes.number,
  maxMatchRank: PropTypes.number,
  rowsPerPage: PropTypes.number,
  auditions: PropTypes.array,
  savedAuditionIds: PropTypes.array,
  invitedAuditionIds: PropTypes.array,
  appliedAuditionIds: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onClick: PropTypes.func,
  placeholderTitle: PropTypes.string,
  placeholderDescription: PropTypes.string,
  desktopWidth: PropTypes.bool.isRequired,
  filterStatus: PropTypes.bool.isRequired,
};
