import find from 'lodash/find';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import { ChatType } from 'modules/chat';
import { ACCOUNT_TYPES } from 'modules/accounts';
import remove from 'lodash/remove';

export function getThreadId(thread) {
  const threadId = get(thread, 'id');
  return threadId;
}

export function findGlobalMessagingThreadWith(accountId, ofType, threads) {
  return find(threads, thread => {
    return get(thread, `${ofType.toLowerCase()}AccountId`) === accountId;
  });
}

export function globalMessagingChatFilter(accountId) {
  return chatWindow =>
    chatWindow.type === ChatType.GLOBAL &&
    chatWindow.oppositeAccountId === accountId;
}

export function globalMessagingChatId(accountId) {
  return `${ChatType.GLOBAL}-${accountId}`;
}

export function applySortOptions(threads, sortOption, sortOrder) {
  if (!sortOption) {
    return threads;
  }

  return orderBy(
    threads,
    [thread => thread[sortOption].toLowerCase()],
    sortOrder,
  );
}

export function getGlobalMessageThreadOppositeAccountTypeAndId(
  globalMessageThread,
  loggedInUserAccountType,
) {
  const accountTypes = [
    ACCOUNT_TYPES.PRODUCER,
    ACCOUNT_TYPES.TALENT,
    ACCOUNT_TYPES.AGENT,
  ];

  remove(accountTypes, acc => acc === loggedInUserAccountType);

  const oppositeAccountType = accountTypes.find(
    accType => !!globalMessageThread[`${accType}Account`],
  );
  const oppositeAccount = globalMessageThread[`${oppositeAccountType}Account`];

  return { type: oppositeAccountType, account: oppositeAccount };
}

export function enlargeGlobalMessagingChat(thread, accountType, history) {
  if (!thread) {
    return history.push('/messaging/global');
  }

  const {
    type: oppositeAccountType,
    account: oppositeAccount,
  } = getGlobalMessageThreadOppositeAccountTypeAndId(thread, accountType);
  const oppositeAccountId = get(oppositeAccount, 'id');

  return history.push(
    `/messaging/global/${oppositeAccountType}/${oppositeAccountId}`,
  );
}
