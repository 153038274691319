import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './MessageIndicator.module.scss';

const maximumNumberOfMessages = 99;

function MessageIndicator({ className, unreadMessages }) {
  const tooManyMessages = unreadMessages > maximumNumberOfMessages;

  return (
    <div className={classNames(className, classes.indicator)}>
      {!tooManyMessages ? unreadMessages : `${maximumNumberOfMessages}+`}
    </div>
  );
}

MessageIndicator.propTypes = {
  className: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number.isRequired,
};

export default MessageIndicator;
