import React from 'react';
import shortid from 'shortid';
import { find, findIndex, get, size, orderBy } from 'lodash';
import PropTypes from 'prop-types';

import { SampleManager } from 'modules/audio-samples';
import { FormHeader, Button } from 'modules/ui';

const styles = {
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginBottom: '-12px',
  },
  reorderContainer: {
    height: '80vh',
    maxHeight: 800,
    width: 600,
  },
};

export default function AudioSampleUploadForm({
  onSubmit,
  talent,
  onReorderSamplesClick,
}) {
  const audioSamples = orderBy(
    get(talent, 'audioSamples'),
    ['ordinalNumber'],
    ['asc'],
  );

  function handleSubmit(audioSample) {
    const existingAudioSample = find(audioSamples, audioSample);

    if (existingAudioSample) {
      const index = findIndex(audioSamples, { id: audioSample.id });
      const updatedAudioSamples = audioSamples.splice(index, 1, audioSample);
      const ids = updatedAudioSamples.map(sample => sample.id);

      return onSubmit(ids);
    }

    const updatedAudioSamples = [...audioSamples, audioSample];
    const ids = updatedAudioSamples.map(sample => sample.id);

    return onSubmit(ids);
  }

  function handleRemove(removedSampleId) {
    const updatedAudioSamples = audioSamples.filter(
      sample => sample.id !== removedSampleId,
    );
    const ids = updatedAudioSamples.map(sample => sample.id);

    return onSubmit(ids);
  }
  function renderSamples() {
    const samples = [];

    for (let i = 0; i < Math.max(2, size(audioSamples)); i += 1) {
      samples.push(
        <SampleManager
          key={audioSamples[i] ? audioSamples[i].id : shortid.generate()}
          talent={talent}
          label={`Sample #${i + 1}`}
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          initialValues={audioSamples[i]}
          placeholderVisible={size(audioSamples) < 2}
        />,
      );
    }

    return samples;
  }

  return (
    <>
      <FormHeader
        title="Upload Audio Samples"
        description={`Your profile becomes visible to content creators after you upload 2 samples.         
        Max size 15 MB per sample.`}
      />
      {audioSamples.length > 1 && (
        <div style={styles.buttonContainer}>
          <Button primary onClick={onReorderSamplesClick}>
            Reorder Samples
          </Button>
        </div>
      )}
      {renderSamples()}
      <SampleManager
        key={`Sample #${size(audioSamples) + 1}`}
        talent={talent}
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        moreSamplesBtn
        moreSamplesBtnDisabled={size(audioSamples) < 2}
      />
    </>
  );
}

AudioSampleUploadForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  talent: PropTypes.object.isRequired,
  onReorderSamplesClick: PropTypes.func.isRequired,
};
