import React from 'react';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

import { Button } from 'modules/ui';
import { Colors } from 'modules/theme';
import styles from './FileUploadButton.module.scss';

export default function FileUploadButton({
  onClick,
  loading,
  description,
  buttonLabel,
  uploadBtnStyle,
  uploadBtnClassName,
  disabled,
  descriptionClassName,
}) {
  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader type="Oval" color={Colors.darkBlue} width="30" height="30" />
      </div>
    );
  }

  const btnClassNames = classNames(uploadBtnClassName, styles.button);

  const descriptionClasses = classNames(
    'body14',
    'grayishBlueColor',
    styles.description,
    descriptionClassName,
  );

  return (
    <div role="button" className={styles.fieldButton} onClick={onClick}>
      <p className={descriptionClasses}>{description}</p>
      <Button
        disabled={disabled}
        primary
        type="button"
        className={btnClassNames}
        style={uploadBtnStyle}
      >
        {buttonLabel}
      </Button>
    </div>
  );
}

FileUploadButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  description: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  uploadBtnStyle: PropTypes.object,
  uploadBtnClassName: PropTypes.string,
  descriptionClassName: PropTypes.string,
};
