import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '../Button';
import styles from './EmptyPlaceholder.module.scss';

const descriptionClasses = classNames('body16', styles.description);

export default function EmptyPlaceholder({
  title,
  description,
  buttonLabel,
  secondaryButtonLabel,
  buttonDisabled,
  onButtonClick,
  onSecondaryButtonClick,
  className,
  imageSrc,
  style,
  containerClassName,
  ...otherProps
}) {
  const containerClasses = classNames(styles.container, containerClassName);
  const contentClasses = classNames(
    styles.content,
    imageSrc && styles['content--withImage'],
    className,
  );

  return (
    <div className={containerClasses} style={style}>
      {imageSrc && <div className={styles.image} />}
      <div className={contentClasses} {...otherProps}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <span className={descriptionClasses}>{description}</span>
        <div>
          {onButtonClick && (
            <Button
              primary
              disabled={buttonDisabled}
              className={styles.button}
              onClick={onButtonClick}
            >
              {buttonLabel}
            </Button>
          )}
          {onSecondaryButtonClick && (
            <Button
              primary
              className={styles.secondaryButton}
              onClick={onSecondaryButtonClick}
            >
              {secondaryButtonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

EmptyPlaceholder.propTypes = {
  title: PropTypes.string,
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
};
