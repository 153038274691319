import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { getIsAuthenticated } from 'modules/auth';
import { Page, NotFoundPage, Button, NavBar } from 'modules/ui';
import { ReactUtil } from 'modules/core';

import classes from './FarewellPage.module.scss';

function FarewellPage() {
  const location = useLocation();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const isDeleted = get(location, 'state.isDeleted');

  if (isAuthenticated || !isDeleted) {
    return ReactUtil.resolveComponent(NotFoundPage);
  }

  return (
    <Page HeaderComponent={<NavBar />} showFooter={false}>
      <div className={classes.farewell}>
        <h1>We're sorry to see you go!</h1>
        <p className={classes.deletedText}>
          Your Ahab account and data have been deleted.
        </p>
        <p className={classes.improvementText}>
          How can we improve your Ahab experience?
        </p>
        <a href="mailto:customerservice@ahabtalent.com">
          <Button primary>Send us your feedback</Button>
        </a>
        <Link to="/" className={classes.homepageLink}>
          Go to Homepage
        </Link>
      </div>
    </Page>
  );
}

export default memo(FarewellPage);
