import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import trim from 'lodash/trim';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { TextInput, ManagedForm, Page } from 'modules/ui';
import { AuthHeader } from '../components';
import { Validator, AuthCode } from '../services';
import {
  forgotPassword,
  resendVerificationEmail,
  getForgotPasswordError,
  getForgotPasswordSuccess,
  clearForgotPasswordStatus,
} from '../redux';
import { AUTH_CODES } from '../const';

const styles = {
  container: {
    flexBasis: 360,
  },
  title: {
    height: 'auto',
  },
  description: {
    marginTop: 25,
    fontSize: 16,
  },
  resetButton: {
    width: '100%',
  },
  footer: {
    marginTop: 3,
  },
};

function resolveSubmit(code) {
  switch (code) {
    case AUTH_CODES.VERIFICATION_LINK_EXPIRED: {
      return resendVerificationEmail;
    }
    default: {
      return forgotPassword;
    }
  }
}

export default function ForgotPasswordPage({ history, location }) {
  const dispatch = useDispatch();
  const code = get(location, 'state.code');

  const forgotPasswordError = useSelector(getForgotPasswordError);
  const forgotPasswordSuccess = useSelector(getForgotPasswordSuccess);

  useEffect(() => () => dispatch(clearForgotPasswordStatus()), []);

  useEffect(() => {
    if (forgotPasswordSuccess) {
      return history.push({
        pathname: '/success',
        state: {
          title: 'Email sent',
          description:
            'We have sent a verification code to your inbox. PS: Check your spam folder just in case.',
        },
      });
    }
  }, [forgotPasswordSuccess, history]);

  const handleSubmit = ({ email }) => {
    const submit = resolveSubmit(code);
    return dispatch(submit(trim(email.toLowerCase())));
  };

  const title = AuthCode.resolveTitle(code);
  const description = AuthCode.resolveDescription(code);
  const buttonTitle = AuthCode.resolveButtonTitle(code);

  return (
    <Page
      className="pt-64"
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/login"
          linkLabel={`Return to ${window.location.hostname}`}
        />
      }
    >
      <ManagedForm
        title={title}
        submitButtonLabel={buttonTitle}
        description={description}
        titleStyle={styles.title}
        descriptionStyle={styles.description}
        btnStyle={styles.resetButton}
        footerStyle={styles.footer}
        onSubmit={handleSubmit}
        validate={Validator.forgotPasswordData}
        error={forgotPasswordError}
        style={styles.container}
        className="mt-48"
      >
        <TextInput
          autoFocus
          label="Email address"
          name="email"
          placeholder="julie@company.com"
        />
      </ManagedForm>
    </Page>
  );
}

ForgotPasswordPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
