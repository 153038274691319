import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavigationTabLink.module.scss';

function NavigationTabLink({ match, disabled, ...otherProps }) {
  const [isActive, setIsActive] = useState(false);

  function isLinkActive(linkMatch) {
    if (!linkMatch) {
      setIsActive(false);
      return false;
    }

    setIsActive(true);
    return true;
  }

  const classes = classNames(
    'body14',
    styles.tabLink,
    disabled && styles['tabLink--disabled'],
    isActive && styles['tabLink--active'],
  );

  return (
    <NavLink
      disabled={disabled}
      activeClassName={styles['tabLink--active']}
      className={classes}
      isActive={isLinkActive}
      {...otherProps}
    />
  );
}

NavigationTabLink.propTypes = {
  disabled: PropTypes.bool,
  layout: PropTypes.string,
  className: PropTypes.string,
  ...NavLink.propTypes,
  isActive: PropTypes.bool,
  to: PropTypes.string,
};

NavigationTabLink.defaultProps = {
  to: '#',
};

export default withRouter(memo(NavigationTabLink));
