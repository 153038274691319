import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './SimpleButtonAudioPreview.module.scss';

export default function SimpleButtonAudioPreview({
  details,
  containerStyle,
  className,
  controlOptionsComponent,
}) {
  const containerClassnames = classNames(styles.container, className);
  const { name } = details;
  return (
    <div className={containerClassnames} style={containerStyle}>
      <div className={styles.audioPreviewContainer}>{name}</div>
      <div className={styles.controlOptions}>{controlOptionsComponent}</div>
    </div>
  );
}

SimpleButtonAudioPreview.propTypes = {
  containerStyle: PropTypes.string,
  details: PropTypes.object,
  controlOptionsComponent: PropTypes.object,
  className: PropTypes.string,
};
