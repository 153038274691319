import moment from 'moment';

const DATE_FORMAT = 'MMM DD, YYYY';
const DATE_TIME_FORMAT = 'MMM DD, YYYY\u00A0\u00A0\u00A0\u00A0\u00A0hh:mma';

function formatDate(date) {
  if (!date) {
    return date;
  }

  return moment
    .utc(date)
    .local()
    .format(DATE_FORMAT);
}

function formatDateAndTime(date) {
  if (!date) {
    return date;
  }

  return moment
    .utc(date)
    .local()
    .format(DATE_TIME_FORMAT);
}

function hasDatePassed(date) {
  const now = moment(new Date());
  const end = moment(date);

  return !now.isSameOrBefore(end, 'day');
}

function isDateAfter(before, after) {
  const previous = moment(before);
  const following = moment(after);

  return following.diff(previous) > 0;
}

export default {
  hasDatePassed,
  formatDate,
  formatDateAndTime,
  isDateAfter,
};
