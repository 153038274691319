import { createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import filter from 'lodash/filter';
import {
  fetchManagerAssignments,
  patchManagerAssignment,
  postManagerAssignment,
  removeManagerAssignment,
} from './api';

const INITIAL_STATE = {
  managerAssignments: [],
  loading: true,
};

export const loadManagerAssignments = createThunk(
  fetchManagerAssignments,
  'fetchManagerAssignments',
  'managerAssignments',
);
export const createManagerAssignment = createThunk(
  postManagerAssignment,
  'postManagerAssignment',
  'managerAssignments',
);
export const updateManagerAssignment = createThunk(
  patchManagerAssignment,
  'patchManagerAssignment',
  'managerAssignments',
);
export const deleteManagerAssignment = createThunk(
  removeManagerAssignment,
  'removeManagerAssignment',
  'managerAssignments',
);
export const reducer = createReducer(INITIAL_STATE, {
  [loadManagerAssignments.type.request]: state => {
    state.loading = true;
  },
  [loadManagerAssignments.type.success]: (state, action) => {
    state.managerAssignments = action.payload.data;
    state.loading = false;
  },
  [createManagerAssignment.type.request]: state => {
    state.loading = true;
  },
  [createManagerAssignment.type.success]: (state, action) => {
    state.loading = false;
    state.managerAssignments = [
      ...state.managerAssignments,
      action.payload.data,
    ];
  },
  [updateManagerAssignment.type.request]: state => {
    state.loading = true;
  },
  [updateManagerAssignment.type.success]: (state, action) => {
    state.loading = false;
    state.managerAssignments = state.managerAssignments.map(assignment =>
      assignment.id !== action.payload.data.id
        ? assignment
        : action.payload.data,
    );
  },
  [deleteManagerAssignment.type.request]: state => {
    state.loading = true;
  },
  [deleteManagerAssignment.type.success]: (state, action) => {
    state.loading = false;
    state.managerAssignments = filter(
      state.managerAssignments,
      assignment => assignment.id !== action.request[0],
    );
  },
});

export const getManagerAssignments = createSelector([
  'managers.managerAssignments',
]);
export const getManagersLoading = createSelector(['managers.loading']);
