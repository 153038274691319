import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import { Validator } from 'modules/core';

function validateSample(values) {
  const projectId = get(values, 'projectId');
  const roleId = get(values, 'roleId');

  const errors = {
    projectId: Validator.validateNotEmpty(projectId, 'Project name'),
    roleId: Validator.validateNotEmpty(roleId, 'Role name'),
  };

  return omitBy(errors, error => error === false);
}

export default {
  validateSample,
};
