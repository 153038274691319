import { Api } from 'modules/core';
import { postProducer } from 'modules/producers';
import { postTalent } from 'modules/talents';
import { postAgent } from 'modules/agents';
import { AccountType } from './services';

export function fetchLoggedInAccount() {
  const endpoint = 'accounts/me';
  return Api.find(endpoint);
}

export function postAccount(firstName, lastName, accountType) {
  const endpoint = 'accounts';

  return Api.create(endpoint).then(() => {
    if (AccountType.isProducer(accountType)) {
      return postProducer(firstName, lastName);
    }

    if (AccountType.isTalent(accountType)) {
      return postTalent(firstName, lastName);
    }

    if (AccountType.isAgent(accountType)) {
      return postAgent(firstName, lastName);
    }

    return Promise.resolve();
  });
}

export function deleteAccount(accountId) {
  const endpoint = `accounts/delete/${accountId}`;
  return Api.deleteResource(endpoint);
}

export function resetTemporaryPassword(email) {
  const endpoint = 'accounts/resendTemporaryPassword';
  const resetPassword = {
    resourceType: 'resend-temporary-password-actions',
    email,
  };
  return Api.create(endpoint, resetPassword);
}

export function fetchApprovalCodeValidity(authCode, data) {
  if (!authCode) {
    return Promise.resolve();
  }
  const { email, firstName, lastName } = data;
  const body = {
    resourceType: 'approve-email-actions',
    email,
    firstName,
    lastName,
  };
  const headers = { 'auth-code': authCode };

  return Api.create('accounts/approveEmail', body, {}, {}, headers);
}

export function postChangedEmail(email) {
  const body = {
    resourceType: 'change-email-actions',
    email,
  };
  return Api.create('accounts/requestEmailChange', body);
}

export function postConfirmChangedEmail(email) {
  const body = {
    resourceType: 'change-email-actions',
    email,
  };
  return Api.create('accounts/confirmEmailChange', body);
}
