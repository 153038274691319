import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { useWindowSize } from 'modules/core';
import { getAccountType } from 'modules/accounts';
import { getTalent, Talent } from 'modules/talents';
import { ACCOUNT_TYPES } from 'modules/accounts/const';
import {
  getIsAuthenticated,
  getPasswordResetRequired,
  getPendingVerification,
} from '../redux';

export default function AuthRoute({ component, ...routeProps }) {
  const authenticated = useSelector(getIsAuthenticated);
  const accountType = useSelector(getAccountType);
  const talent = useSelector(getTalent);
  const pendingVerification = useSelector(getPendingVerification);
  const passwordResetRequired = useSelector(getPasswordResetRequired);
  const { desktopWidth } = useWindowSize();

  if (authenticated) {
    const redirectPath =
      accountType === ACCOUNT_TYPES.TALENT && desktopWidth // TODO : remove desktopWidth when talents edit profile page is responsive
        ? Talent.getLoginRedirectPath(talent)
        : '/';
    return (
      <Route {...routeProps} render={() => <Redirect to={redirectPath} />} />
    );
  }

  if (
    !passwordResetRequired &&
    routeProps.path === '/auth/complete-new-password'
  ) {
    return <Route {...routeProps} render={() => <Redirect to="/login" />} />;
  }

  if (!pendingVerification && routeProps.path === '/welcome') {
    return <Route {...routeProps} render={() => <Redirect to="/login" />} />;
  }

  return <Route component={component} {...routeProps} />;
}

AuthRoute.propTypes = {
  ...Route.propTypes,
};
