import { Api } from 'modules/core';
import { AccountType } from 'modules/accounts';
import Dictionary from 'modules/dictionary';

export const OFFER_ACTIONS = {
  hired: 'accept',
  declined: 'decline',
  canceled: 'cancel',
  negotiating: 'negotiate',
};

const OFFERS_ENDPOINT = 'offers';
const OFFERS_TYPE = 'offers';
const OFFERS_ACTION_TYPE = 'offer-action-payloads';
const AGENT_OFFERS_INCLUDE = 'talent,producer,project,projectRole.project';
const OFFERS_INCLUDE = 'producer,project,projectRole.project';

function resolveOffersInclude(accountType) {
  if (AccountType.isAgent(accountType)) {
    return AGENT_OFFERS_INCLUDE;
  }
  return OFFERS_INCLUDE;
}

export function fetchOffers(filter, accountType) {
  const params = { include: OFFERS_INCLUDE, filter, 'page[limit]': 1000 };

  if (AccountType.isAgent(accountType)) {
    const agentParams = {
      include: AGENT_OFFERS_INCLUDE,
      filter,
      'page[limit]': 1000,
    };
    return Api.find('offers/findForAgent', agentParams);
  }

  return Api.find(OFFERS_ENDPOINT, params);
}

export function fetchOffer(offerId, accountType) {
  const params = { include: resolveOffersInclude(accountType) };

  return Api.find(`${OFFERS_ENDPOINT}/${offerId}`, params);
}

export function offerAction(offerId, action, values = {}, accountType) {
  if (!offerId) {
    return null;
  }

  const params = { include: resolveOffersInclude(accountType) };

  const offer = {
    resourceType: OFFERS_ACTION_TYPE,
    ...values,
  };

  const endpoint = `${OFFERS_ENDPOINT}/${offerId}/actions/${action}`;

  return Api.create(endpoint, offer, {}, params);
}

export function patchOffer(offerId, values) {
  if (!offerId) {
    return null;
  }

  const endpoint = `${OFFERS_ENDPOINT}/${offerId}`;

  return Api.update(endpoint, values, OFFERS_TYPE);
}

export function postOffer(talentId, projectRoleId, producerId, offerValues) {
  const offer = {
    resourceType: OFFERS_TYPE,
    ...offerValues,
    status: Dictionary.OFFER_STATUSES.SENT,
  };

  const relationships = {
    ...(talentId && {
      talent: {
        data: {
          type: 'talents',
          id: talentId,
        },
      },
    }),
    ...(projectRoleId && {
      projectRole: {
        data: {
          type: 'project-roles',
          id: projectRoleId,
        },
      },
    }),
    ...(producerId && {
      producer: {
        data: {
          type: 'producers',
          id: producerId,
        },
      },
    }),
  };
  return Api.create(OFFERS_ENDPOINT, offer, relationships);
}
