import uniq from 'lodash/uniq';
import map from 'lodash/map';
import get from 'lodash/get';
import some from 'lodash/some';
import pick from 'lodash/pick';
import difference from 'lodash/difference';
import find from 'lodash/find';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import size from 'lodash/size';

import Dictionary from 'modules/dictionary';
import { TABLET_WIDTH } from 'modules/core';

const SOCIAL_MEDIA_LINKS = [
  'imdbLink',
  'youtubeLink',
  'twitterLink',
  'instagramLink',
  'facebookLink',
  'linkedinLink',
];

const PROFILE_FIELDS_MAPPINGS = {
  genders: 'Gender',
  pronouns: 'Pronoun',
  city: 'City',
  country: 'Country',
  recordingExperience: 'Audiobooks Recorded',
  voiceAgeRanges: 'Vocal Age Range',
  primaryLanguages: 'Primary Languages',
  accents: 'Accents',
  foreignLanguages: 'Language Fluencies',
  nativeRegionalAccents: 'Regional Accents',
};

const PERSONAL_INFO_REQUIRED_FIELDS = ['genders', 'city', 'country'];
const PROFESSIONAL_INFO_REQUIRED_FIELDS = ['recordingExperience'];
const SKILLS_REQUIRED_FIELDS = ['primaryLanguages'];
// does not include samples
const REQUIRED_FIELDS = [
  ...PERSONAL_INFO_REQUIRED_FIELDS,
  ...PROFESSIONAL_INFO_REQUIRED_FIELDS,
  ...SKILLS_REQUIRED_FIELDS,
];

const REQUIRED_SAMPLES_COUNT = 2;

function getRelativeProfileLink(talent) {
  const talentId = get(talent, 'id');
  return `/talent/${talentId}`;
}

function getProfileLink(talent) {
  const origin = get(window, 'location.origin');
  return `${origin}${getRelativeProfileLink(talent)}`;
}

function getPopulatedFieldRequirements(talent) {
  const requiredFields = pick(talent, REQUIRED_FIELDS);
  return pickBy(requiredFields, field => field !== null && !isEmpty(field));
}

// checks for required fields and required samples threshold
function countPopulatedVisibilityRequirements(talent) {
  if (!talent) {
    return undefined;
  }

  const populatedFieldsCount = size(getPopulatedFieldRequirements(talent));
  const populatedSamplesCount = size(talent.audioSamples);

  return (
    populatedFieldsCount +
    (populatedSamplesCount <= 2
      ? populatedSamplesCount
      : REQUIRED_SAMPLES_COUNT)
  );
}

function countAllVisibilityRequirements() {
  return size(REQUIRED_FIELDS) + REQUIRED_SAMPLES_COUNT;
}

// talent's profile is visible(producers can see him and he can apply to auditions) if he has at least two samples uploaded and required fields populated
function isProfileVisible(talent) {
  return (
    countPopulatedVisibilityRequirements(talent) ===
    countAllVisibilityRequirements()
  );
}

function isUnionsGdprNotAccepted(talent) {
  const { unionGdprAcceptedAt, unions } = talent;
  if (unionGdprAcceptedAt) {
    return false;
  }
  return (unionGdprAcceptedAt === null) === !isEmpty(unions);
}

function isEthnicityGdprNotAccepted(talent) {
  const { ethnicityGdprAcceptedAt, ethnicities } = talent;
  if (ethnicityGdprAcceptedAt) {
    return false;
  }
  return (ethnicityGdprAcceptedAt === null) === !isEmpty(ethnicities);
}

function getLoginRedirectPath(talent) {
  // currently edit profile page is unresponsive, so the gdpr consent redirect will happen only on desktops.
  if (window.innerWidth > TABLET_WIDTH) {
    if (isEthnicityGdprNotAccepted(talent)) {
      return '/profile/edit';
    }

    if (isUnionsGdprNotAccepted(talent)) {
      return '/profile/edit/professional-info';
    }
  }

  if (isProfileVisible(talent)) {
    return '/';
  }
  const populatedFieldRequirementsKeys = Object.keys(
    getPopulatedFieldRequirements(talent),
  );
  if (
    !isEmpty(
      difference(PERSONAL_INFO_REQUIRED_FIELDS, populatedFieldRequirementsKeys),
    )
  ) {
    return '/profile/edit';
  }
  if (
    !isEmpty(
      difference(
        PROFESSIONAL_INFO_REQUIRED_FIELDS,
        populatedFieldRequirementsKeys,
      ),
    )
  ) {
    return '/profile/edit/professional-info';
  }
  if (size(talent.audioSamples) < REQUIRED_SAMPLES_COUNT) {
    return '/profile/edit/samples';
  }
  if (
    !isEmpty(difference(SKILLS_REQUIRED_FIELDS, populatedFieldRequirementsKeys))
  ) {
    return '/profile/edit/skills';
  }

  return '/profile/edit';
}

function createOptions(talents) {
  return talents.map(talent => ({
    ...talent,
    value: talent.id,
    label: talent.fullName,
  }));
}

function getSamplesVerticals(talent) {
  if (!talent) {
    return [];
  }

  return uniq(map(talent.audioSamples, sample => sample.vertical));
}

function hasAgent(talent, selectedVertical) {
  const agentAssignments = get(talent, 'agentAssignments');

  return some(
    agentAssignments,
    assignment =>
      includes(assignment.verticals, selectedVertical) &&
      assignment.notificationSetting !==
        Dictionary.AGENT_NOTIFICATION_OPTIONS.NEVER,
  );
}

function getAgentAssignmentByVertical(talent, selectedVertical) {
  const agentAssignments = get(talent, 'agentAssignments');

  return find(
    agentAssignments,
    agentAssignment =>
      includes(agentAssignment.verticals, selectedVertical) &&
      agentAssignment.notificationSetting !==
        Dictionary.AGENT_NOTIFICATION_OPTIONS.NEVER,
  );
}

function mapFieldNamesToLabels(fieldNames) {
  return fieldNames.map(fieldName => PROFILE_FIELDS_MAPPINGS[fieldName]);
}

export default {
  getRelativeProfileLink,
  getProfileLink,
  createOptions,
  getSamplesVerticals,
  SOCIAL_MEDIA_LINKS,
  hasAgent,
  getAgentAssignmentByVertical,
  countAllVisibilityRequirements,
  countPopulatedVisibilityRequirements,
  isProfileVisible,
  PERSONAL_INFO_REQUIRED_FIELDS,
  PROFESSIONAL_INFO_REQUIRED_FIELDS,
  SKILLS_REQUIRED_FIELDS,
  REQUIRED_SAMPLES_COUNT,
  mapFieldNamesToLabels,
  getPopulatedFieldRequirements,
  getLoginRedirectPath,
  isEthnicityGdprNotAccepted,
  isUnionsGdprNotAccepted,
};
