import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import Grid from '@material-ui/core/Grid';

import { InfoBanner, Page } from 'modules/ui';
import { getProducer } from 'modules/producers';
import { loadAsset } from 'modules/assets';
import { ProjectOverview, getCoverImage, getLoadingCovers, loadCover } from 'modules/projects';

import {
  AuditionPreviewHeader,
  DetailsSection,
  VoiceSkillsSection,
  AnalyticsSection,
  AdditionalDetailsSection,
  RecordingSection,
} from '../components';

export default function AuditionPreviewPage({
  audition,
  project: initialProject,
}) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const [image, setImage] = useState();
  const project = get(audition, 'projectRole.project') || initialProject;
  const Placeholder = <p className="body14 grayishBlueColor">Not defined</p>;


  const loadingCovers = useSelector(getLoadingCovers);
  const coverImage = get(project, 'coverImage')
  const isCoverLoading = !!find(loadingCovers, cover => {
    return cover === coverImage;
  });

  const coverImageSrc = useSelector(getCoverImage(coverImage));

  const showBanner = !!producer;

  useEffect(() => {
    const { profileImage } = producer;
    dispatch(loadAsset(profileImage, true)).then(asset => setImage(asset));
  }, [producer, dispatch]);

  useEffect(() => {
    dispatch(loadCover(coverImage))
  }, [dispatch, coverImage]);

  return (
    <Page
      HeaderComponent={
        <AuditionPreviewHeader
          avatar={image}
          audition={audition}
          producer={producer}
          isCoverLoading={isCoverLoading}
          coverImageSrc={coverImageSrc}
        />
      }
      BannerComponent={
        showBanner && (
          <InfoBanner text="This is how the audition looks to talent." />
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <DetailsSection audition={audition} Placeholder={Placeholder} />
          <VoiceSkillsSection audition={audition} Placeholder={Placeholder} />
          <ProjectOverview project={project} sectionTitle="Project Details" />
          <AdditionalDetailsSection audition={audition} />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={3}>
          <AnalyticsSection audition={audition} />
          <RecordingSection audition={audition} Placeholder={Placeholder} />
        </Grid>
      </Grid>
    </Page>
  );
}

AuditionPreviewPage.propTypes = {
  audition: PropTypes.object,
  project: PropTypes.object,
};
