import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactUtil } from 'modules/core';
import { Button } from '../Button';
import styles from './FormFooter.module.scss';

export default function FormFooter({
  inProgress,
  showFooterDivider,
  showFooterDescription,
  style,
  btnClassName,
  footerClassName,
  description,
  submitDisabled,
  submitButtonLabel,
  onSubmit,
  onCancel,
  onBack,
  backBtnDisabled,
  btnStyle,
  FooterTemplateComponent,
}) {
  const btnClasses = classNames(styles.btn, btnClassName);
  const footerClasses = classNames(styles.footer, footerClassName);

  const footerComponents = (
    <>
      {showFooterDescription && description && (
        <div className={styles.description}>{description}</div>
      )}
      {onCancel && (
        <Button secondary onClick={onCancel} className={styles.cancelBtn}>
          Cancel
        </Button>
      )}
      {onBack && (
        <Button
          secondary
          onClick={onBack}
          className={styles.backBtn}
          disabled={backBtnDisabled}
        >
          Back
        </Button>
      )}
      <div className={styles.btnContainer}>
        <Button
          primary
          onClick={onSubmit}
          style={btnStyle}
          className={btnClasses}
          disabled={submitDisabled}
          loading={inProgress}
          id="submit-button"
          type="submit"
        >
          {submitButtonLabel}
        </Button>
      </div>
    </>
  );

  return (
    <div style={style} className={footerClasses}>
      {showFooterDivider && (
        <div className={styles.dividerContainer}>
          <div className={styles.divider} />
        </div>
      )}
      <div className={styles.container}>
        {FooterTemplateComponent &&
          ReactUtil.resolveComponent(FooterTemplateComponent, {
            children: footerComponents.props.children,
          })}
        {!FooterTemplateComponent && footerComponents.props.children}
      </div>
    </div>
  );
}

FormFooter.propTypes = {
  inProgress: PropTypes.bool,
  showFooterDivider: PropTypes.bool,
  showFooterDescription: PropTypes.bool,
  description: PropTypes.string,
  style: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  backBtnDisabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  btnStyle: PropTypes.object,
  btnClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  FooterTemplateComponent: PropTypes.elementType,
};

FormFooter.defaultProps = {
  showFooterDescription: true,
  submitButtonLabel: 'Submit',
};
