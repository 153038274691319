import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, PhoneInput, Select, TextInput, MultiSelect } from 'modules/ui';
import Dictionary from 'modules/dictionary';
import Grid from '@material-ui/core/Grid';
import { Colors } from 'modules/theme';
import classes from './AgentInput.module.scss';

const styles = {
  label: {
    marginBottom: 28,
    display: 'inline-block',
  },
  removeAgent: {
    fontSize: 11,
    marginTop: 8,
    color: Colors.secondary,
  },
  agentInput: {
    marginBottom: 70,
  },
};

function AgentInput({
  agentAssignmentId,
  agentAssignment,
  onChange,
  onRemoveClick,
  label,
  error,
  removeDisabled,
  style,
}) {
  function handleValueChange(name, values) {
    onChange(agentAssignmentId, {
      ...agentAssignment,
      [name]: values,
    });
  }

  function handleInputValueChange(event) {
    handleValueChange(event.target.name, event.target.value);
  }

  return (
    <div style={style}>
      <h3 style={styles.label}>{label}</h3>
      <Button
        small
        onClick={onRemoveClick}
        className={classes.removeAgent}
        style={styles.removeAgent}
        disabled={removeDisabled}
      >
        Remove agent
      </Button>
      <Grid container spacing={0}>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <TextInput
              name="agentFirstName"
              value={agentAssignment ? agentAssignment.agentFirstName : ''}
              onChange={handleInputValueChange}
              label="First name"
              placeholder="E.g. Jane"
              error={get(error, 'agentFirstName')}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name="agentLastName"
              value={agentAssignment ? agentAssignment.agentLastName : ''}
              onChange={handleInputValueChange}
              label="Last name"
              placeholder="E.g. Doe"
              error={get(error, 'agentLastName')}
              required
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            name="agentEmail"
            value={agentAssignment ? agentAssignment.agentEmail : ''}
            onChange={handleInputValueChange}
            label="Email"
            placeholder="E.g. name@email.com"
            error={get(error, 'agentEmail')}
            required
          />
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Select
              name="notificationSetting"
              value={agentAssignment ? agentAssignment.notificationSetting : ''}
              onChange={handleInputValueChange}
              placeholder="Choose when to notify agent"
              options={Dictionary.AGENT_NOTIFICATIONS_OPTIONS}
              label="Contact through Agent"
              error={get(error, 'notificationSetting')}
              required
              autoFocus
            />
          </Grid>
          <Grid item xs={6}>
            <MultiSelect
              name="verticals"
              value={agentAssignment ? agentAssignment.verticals : ''}
              setFormValue={handleValueChange}
              placeholder="Choose a Category"
              options={Dictionary.VERTICAL_OPTIONS}
              label="Choose a Category"
              error={get(error, 'verticals')}
              required
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <PhoneInput
              label="Phone Number"
              name="companyPhoneNumber"
              value={get(agentAssignment, 'companyPhoneNumber', '')}
              onChange={handleInputValueChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label="Company Name"
              name="companyName"
              value={get(agentAssignment, 'companyName', '')}
              onChange={handleInputValueChange}
              error={get(error, 'companyName')}
              placeholder="e.g. Moby Voices Inc."
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

AgentInput.propTypes = {
  agentAssignmentId: PropTypes.string.isRequired,
  agentAssignment: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  removeDisabled: PropTypes.bool,
  style: PropTypes.object,
};
export default AgentInput;
