import { createReducer, createAction } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import get from 'lodash/get';
import { getAsset } from 'modules/assets';
import { loadProducer, createProducer } from 'modules/producers';
import { loadTalent, createTalent } from 'modules/talents';
import { loadAgent, createAgent } from 'modules/agents';
import { Account, AccountType } from './services';
import {
  fetchLoggedInAccount,
  postAccount,
  deleteAccount,
  fetchApprovalCodeValidity as apiFetchApprovalCodeValidity,
  postChangedEmail as apiChangeEmail,
  postConfirmChangedEmail as apiVerifyChangedEmail,
} from './api';
import { ACCOUNT_TYPES, ACCOUNT_LABELS } from './const';
import { loadAgentAssignments } from '../agents';
import { loadManagerAssignments } from '../managers';

const INITIAL_STATE = {
  account: null,
  avatar: null,
  loading: false,
  accountType: null,
  errors: [],
  authCodeValid: false,
  changeEmailSuccess: false,
  changeEmailError: false,
  changedEmail: null,
  verifyChangedEmailSuccess: false,
  verifyChangedEmailError: false,
};

export const loadLoggedInAccount = createThunk(
  fetchLoggedInAccount,
  'fetchMine',
  'accounts',
);
export const createAccount = createThunk(postAccount, 'create', 'accounts');
export const removeAccount = createThunk(deleteAccount, 'delete', 'accounts');
export const loadAvatar = createThunk(getAsset, 'loadAvatar', 'accounts');

export function preloadAccount() {
  return async dispatch => {
    const accountPayload = await dispatch(loadLoggedInAccount());
    const account = get(accountPayload, 'data');

    if (!account) {
      return account;
    }

    const accountType = Account.getType(account);

    if (AccountType.isProducer(accountType)) {
      const { id: producerId } = account.producer;
      const producer = await dispatch(loadProducer(producerId));

      // TODO: load producer with account relationship
      await dispatch(loadAvatar(producer.data.profileImage));
    }

    if (AccountType.isAgent(accountType)) {
      const { id: agentId } = account.agent;
      const agent = await dispatch(loadAgent(agentId));

      await dispatch(loadAvatar(agent.data.profileImage));
    }

    if (AccountType.isTalent(accountType)) {
      const { id: talentId } = account.talent;
      const talent = await dispatch(loadTalent(talentId));
      dispatch(loadAgentAssignments());
      dispatch(loadManagerAssignments());

      // TODO: load talent with account relationship
      await dispatch(loadAvatar(talent.data.profileImage));
    }

    return account;
  };
}

export const validateAuthCode = createThunk(
  apiFetchApprovalCodeValidity,
  'validate',
  'authCode',
);

export const changeEmail = createThunk(
  apiChangeEmail,
  'changeEmail',
  'accounts',
);

export const clearChangeEmailStatus = createAction(
  'accounts/clearChangeEmailStatus',
);

export const verifyChangedEmail = createThunk(
  apiVerifyChangedEmail,
  'verifyChangeEmail',
  'accounts',
);

export const reducer = createReducer(INITIAL_STATE, {
  [loadLoggedInAccount.type.request]: state => {
    state.loading = true;
  },
  [loadLoggedInAccount.type.success]: (state, action) => {
    const { data } = action.payload;

    state.loading = false;
    state.account = data;
    state.accountType = Account.getType(data);
  },
  [loadLoggedInAccount.type.error]: (state, action) => {
    state.loading = false;
  },
  [createAccount.type.request]: state => {
    state.loading = true;
  },
  [createAccount.type.success]: (state, action) => {
    state.loading = false;
    state.account = action.payload.data;
  },
  [createProducer.type.success]: state => {
    state.accountType = ACCOUNT_TYPES.PRODUCER;
  },
  [createTalent.type.success]: state => {
    state.accountType = ACCOUNT_TYPES.TALENT;

    global.utag.link({ account_type: ACCOUNT_LABELS.TALENT });
  },
  [createAgent.type.success]: state => {
    state.accountType = ACCOUNT_TYPES.AGENT;
  },
  [loadAvatar.type.success]: (state, action) => {
    state.avatar = action.payload;
  },
  [validateAuthCode.type.success]: state => {
    state.authCodeValid = true;
  },
  [validateAuthCode.type.error]: state => {
    state.authCodeValid = false;
  },
  [changeEmail.type.success]: (state, action) => {
    state.changeEmailError = false;
    state.changeEmailSuccess = true;
    state.changedEmail = action.payload.data.email;
  },
  [changeEmail.type.error]: state => {
    state.changeEmailError = true;
    state.changeEmailSuccess = false;
  },
  [clearChangeEmailStatus]: state => {
    state.changeEmailError = false;
    state.changeEmailSuccess = false;
    state.changedEmail = null;
  },
  [verifyChangedEmail.type.success]: (state, action) => {
    state.verifyChangedEmailError = false;
    state.verifyChangedEmailSuccess = true;
    state.changedEmail = action.payload.data.email;
  },
  [verifyChangedEmail.type.error]: state => {
    state.verifyChangedEmailError = true;
    state.verifyChangedEmailSuccess = false;
  },
});

export const getAccount = createSelector(['accounts.account']);
export const getAccountType = createSelector(['accounts.accountType']);
export const getAccountTypeLabel = createSelector(
  ['accounts.account'],
  account => {
    if (account) {
      return Account.getLabel(account);
    }

    return null;
  },
);
export const getAccountErrors = createSelector(['accounts.errors']);
export const getAvatar = createSelector(['accounts.avatar']);
export const getAuthCodeValidity = createSelector(['accounts.authCodeValid']);
export const getAccountByType = createSelector(
  [getAccountType, 'producers.producer', 'agents.agent', 'talents.talent'],
  (accountType, producer, agent, talent) => {
    if (accountType === ACCOUNT_TYPES.PRODUCER) {
      return producer;
    }
    if (accountType === ACCOUNT_TYPES.AGENT) {
      return agent;
    }
    if (accountType === ACCOUNT_TYPES.TALENT) {
      return talent;
    }
    return null;
  },
);
export const getAccountIdByType = createSelector(
  [getAccountType, 'producers.producer', 'agents.agent', 'talents.talent'],
  (accountType, producer, agent, talent) => {
    if (accountType === ACCOUNT_TYPES.PRODUCER) {
      return get(producer, 'id');
    }
    if (accountType === ACCOUNT_TYPES.AGENT) {
      return get(agent, 'id');
    }
    if (accountType === ACCOUNT_TYPES.TALENT) {
      return get(talent, 'id');
    }
    return null;
  },
);
export const getChangeEmailError = createSelector([
  'accounts.changeEmailError',
]);
export const getChangeEmailSuccess = createSelector([
  'accounts.changeEmailSuccess',
]);
export const getChangedEmail = createSelector(['accounts.changedEmail']);
export const getVerifyChangedEmailSuccess = createSelector([
  'accounts.verifyChangedEmailSuccess',
]);
export const getVerifyChangedEmailError = createSelector([
  'accounts.verifyChangedEmailError',
]);
