import React from 'react';
import { useHistory } from 'react-router-dom';
import { AuthPage } from '../components';
import SignUpPageForm from './SignUpPageForm';

export default function SignUpPage() {
  const history = useHistory();

  const signupAccountType = history.location.pathname.slice(8);

  if (signupAccountType.includes('content-creator')) {
    return (
      <AuthPage
        light
        headerLink="/login"
        headerLinkLabel="Sign in"
        headerLinkDescription="Already have an account?"
      >
        <SignUpPageForm signupAccountType="producer" />
      </AuthPage>
    );
  }

  return (
    <AuthPage
      light
      headerLink="/login"
      headerLinkLabel="Sign in"
      headerLinkDescription="Already have an account?"
    >
      <SignUpPageForm signupAccountType={signupAccountType} />
    </AuthPage>
  );
}
