import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SectionLoader, LineDivider } from 'modules/ui';
import { getAsset } from 'modules/assets';

import { SendMessageChatSection } from '../fragments';

import ChatHeader from './ChatHeader';
import Messages from './Messages';

import classes from './ChatWindow.module.scss';

function ChatWindow({
  className,
  headerClassName,
  messagesClassName,
  footerClassName,
  isLoadingMessages,
  headingTitle,
  headingTitleClassName,
  headingSubtitle,
  headingSubtitleClassName,
  photoUrl,
  onSubmitClick,
  options,
  messages,
  displayTimestamps,
  enableSampleUploading,
  displayAvatar,
}) {
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    getAsset(photoUrl).then(url => setAvatar(url));
  }, [photoUrl]);

  return (
    <div className={classNames(classes.window, className)}>
      {isLoadingMessages ? (
        <SectionLoader />
      ) : (
        <>
          <ChatHeader
            className={headerClassName}
            title={headingTitle}
            titleClassName={headingTitleClassName}
            subtitle={headingSubtitle}
            subtitleClassName={headingSubtitleClassName}
            photoUrl={avatar}
            options={options}
            displayAvatar={displayAvatar}
          />
          <LineDivider />
          <Messages
            className={messagesClassName}
            messages={messages}
            photoUrl={avatar}
            displayTimestamps={displayTimestamps}
          />
          <LineDivider />
          <SendMessageChatSection
            className={footerClassName}
            onSubmitClick={onSubmitClick}
            enableSampleUploading={enableSampleUploading}
          />
        </>
      )}
    </div>
  );
}

ChatWindow.propTypes = {
  headingTitle: PropTypes.string.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
  headingTitleClassName: PropTypes.string,
  options: PropTypes.node,
  headingSubtitle: PropTypes.string,
  headingSubtitleClassName: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  messagesClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  photoUrl: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.object),
  displayTimestamps: PropTypes.bool,
  enableSampleUploading: PropTypes.bool,
  displayAvatar: PropTypes.bool,
};

export default ChatWindow;
