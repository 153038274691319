import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Dictionary from 'modules/dictionary';
import { Textarea, ManagedForm, TextInput, DatePicker } from 'modules/ui';
import AuditionTypeSwitchInput from './AuditionTypeSwitchInput';
import AuditionDetailsGrid from './form-grids/AuditionDetailsGrid';
import Validator from '../services/validator';

export default function AuditionDetailsForm({
  handleRef,
  error,
  initialValues,
  withAuditionTypeConfirm,
  ...otherProps
}) {
  const formRef = useRef(null);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    const refChanged = ref ? ref.current !== formRef.current : true;

    if (refChanged && handleRef) {
      handleRef(formRef.current);
      setRef(formRef);
    }
  }, [ref, handleRef]);

  const auditionName = get(initialValues, 'name');

  return (
    <ManagedForm
      ref={formRef}
      title={`Audition Details: ${auditionName}`}
      TemplateComponent={AuditionDetailsGrid}
      error={error}
      initialValues={initialValues}
      validate={Validator.validateAuditionDetails}
      submitButtonLabel="Save audition details"
      {...otherProps}
    >
      <AuditionTypeSwitchInput
        required
        name="type"
        label="Audition Type"
        auditionName={auditionName}
        items={Dictionary.AUDITION_TYPE_OPTIONS}
        defaultValue={Dictionary.AUDITION_TYPES.OPEN_CALL}
        withConfirm={withAuditionTypeConfirm}
      />
      <TextInput
        required
        name="name"
        label="Audition name"
        value={auditionName}
        placeholder="E.g. Moby Dick"
      />
      <Textarea
        required
        label="Short description"
        name="description"
        maxChars={224}
        placeholder="Write a short audition description."
      />
      <DatePicker
        required
        label="Audition due date"
        name="dueDate"
        placeholder="Choose a date"
      />
    </ManagedForm>
  );
}

AuditionDetailsForm.propTypes = {
  ...ManagedForm.propTypes,
  handleRef: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  withAuditionTypeConfirm: PropTypes.bool,
};
