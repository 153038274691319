import React from 'react';
import { useSelector } from 'react-redux';

import { MessagingHorizontalMenuLabel } from 'modules/chat';
import { getUnreadMessagesCount } from 'modules/audition-messages';

function AuditionMessagingHorizontalMenuLabel() {
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);
  return (
    <MessagingHorizontalMenuLabel
      label="Auditions"
      unreadMessages={unreadMessagesCount}
    />
  );
}

export default AuditionMessagingHorizontalMenuLabel;
