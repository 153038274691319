import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SingleBar from './SingleBar';
import styles from './SegmentedProgressBars.module.scss';

export default function SegmentedProgressBars({
  containerClassName,
  barClassName,
  activeBarsCount,
  maxBarsCount,
}) {
  const barsDifference = maxBarsCount - activeBarsCount;
  const classes = classNames(styles.container, containerClassName);
  return (
    <div className={classes}>
      {[...Array(Number(activeBarsCount))].map(() => {
        return <SingleBar barClassName={barClassName} active />;
      })}
      {[...Array(barsDifference)].map(() => {
        return <SingleBar barClassName={barClassName} active={false} />;
      })}
    </div>
  );
}

SegmentedProgressBars.propTypes = {
  containerClassName: PropTypes.string,
  barClassName: PropTypes.string,
  activeBarsCount: PropTypes.number.isRequired,
  maxBarsCount: PropTypes.number.isRequired,
};
