import React from 'react';
import PropTypes from 'prop-types';

import { PageLoader } from 'modules/ui';

import classes from './MessagingCards.module.scss';

function MessagingCards({ loading, placeholder, children, loadMoreButton }) {
  if (loading) {
    return <PageLoader />;
  }
  
  return (
    <div className={classes.cards}>
      {children.length > 0 ? children : placeholder}
      {loadMoreButton}
    </div>
  );
}

MessagingCards.propTypes = {
  loading: PropTypes.bool.isRequired,
  placeholder: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.node),
  loadMoreButton: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
};

export default MessagingCards;
