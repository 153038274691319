import React, { memo, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import toInteger from 'lodash/toInteger';
import Grid from '@material-ui/core/Grid/Grid';
import { Select, TextInput, Input } from 'modules/ui';
import { useDidMount, Utils } from 'modules/core';
import styles from './LengthInput.module.scss';

const EXPONENT_KEY_CODE = 69;

function LengthInput({
  value,
  name,
  error,
  disabled,
  options,
  defaultValue,
  setFormValue,
  onChange,
  ...otherProps
}) {
  const didMount = useDidMount();

  const [length, setLength] = useState(value || defaultValue);

  const containerClassName = classNames(
    styles.container,
    error && styles.error,
  );

  useEffect(() => {
    setLength(value || defaultValue);
  }, [defaultValue, value]);

  function changeValue(newLength) {
    setLength(newLength);

    if (setFormValue) {
      setFormValue(name, newLength);
    }

    if (onChange) {
      onChange(newLength);
    }
  }

  useEffect(() => {
    if (didMount && value) {
      changeValue(value);
    }
  }, [value]);

  const handleAmountChange = event => {
    const newLength = {
      ...length,
      amount: event.target.value,
    };

    changeValue(newLength);
  };

  const handleTypeChange = event => {
    const newLength = {
      ...length,
      type: event.target.value,
    };

    changeValue(newLength);
  };

  function handleKeyDown(event) {
    if (event.keyCode === EXPONENT_KEY_CODE) {
      event.preventDefault();
    }
  }

  const amount = get(length, 'amount');
  const type = get(length, 'type');

  return (
    <Input
      labelClassName={styles.label}
      error={error}
      containerClassName={containerClassName}
      {...otherProps}
    >
      <Grid container item spacing={2}>
        <Grid item xs={8}>
          <TextInput
            value={amount}
            disabled={disabled}
            error={error && error.amount}
            onChange={handleAmountChange}
            containerClassName={disabled && 'mb-0'}
            type="number"
            onKeyDown={handleKeyDown}
            placeholder="Enter duration"
            min="0"
            maskFunction={Utils.isEnteringFloat}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            required
            value={type}
            name="lengthType"
            onChange={handleTypeChange}
            error={error && error.type}
            lightInput
            options={options}
          />
        </Grid>
      </Grid>
    </Input>
  );
}

LengthInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  setFormValue: PropTypes.func,
};

export default memo(LengthInput);
