import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { ConfirmationDialog, FullScreenModal, IconButton } from 'modules/ui';
import { AuditionPreviewModal, Audition } from 'modules/auditions';
import { removeErrorsAction } from '../redux';
import AuditionEditPage from './AuditionEditPage';
import AuditionCreatePage from './AuditionCreatePage';
import styles from './AuditionEditModal.module.scss';

export default function AuditionEditModal({
  onClose,
  onSubmit,
  audition,
  role,
  project,
}) {
  const dispatch = useDispatch();
  const initialAudition = audition || Audition.createEmptyAudition();

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [auditionValues, setAuditionValues] = useState(initialAudition);
  const [touched, setTouched] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const auditionId = get(audition, 'id');
  const name = get(audition, 'name');

  const isEditMode = !isEmpty(auditionId);
  const title = isEditMode ? `Edit ${name} Audition` : 'Create new audition';

  const isDraft = get(audition, 'isDraft', true);
  const AuditionFlowComponent = isDraft ? AuditionCreatePage : AuditionEditPage;

  const showPreviewModal = () => setPreviewModalOpen(true);
  const hidePreviewModal = () => setPreviewModalOpen(false);

  function handleFormChange(values) {
    setTouched(true);
    setAuditionValues({
      ...auditionValues,
      ...values,
      requiredRecordingExperiencesIds: values.requiredRecordingExperiences,
      requiredVoiceAgeRangesIds: values.requiredVoiceAgeRanges,
    });
  }

  function handleFormSubmit(newAudition) {
    setTouched(false);

    if (onSubmit) {
      onSubmit(newAudition);
    }
  }

  function handleModalCloseClick() {
    if (touched) {
      setShowConfirmation(true);
    } else {
      dispatch(removeErrorsAction());
      onClose();
    }
  }

  function handleModalCloseConfirm() {
    setShowConfirmation(false);
    dispatch(removeErrorsAction());
    return onClose();
  }

  function handleModalCloseCancel() {
    setShowConfirmation(false);
  }

  return (
    <>
      <FullScreenModal
        open
        title={title}
        onClose={handleModalCloseClick}
        LeftComponent={
          <IconButton
            header
            onClick={showPreviewModal}
            className={styles.previewButton}
            iconName="eye"
            iconColor="white"
            iconSize={16}
            iconClassName={styles.previewIcon}
            label="Preview"
          />
        }
      >
        <AuditionFlowComponent
          onChange={handleFormChange}
          onSubmit={handleFormSubmit}
          resetChanges={() => setTouched(false)}
          auditionId={auditionId}
          role={role}
        />
        {showConfirmation && (
          <ConfirmationDialog
            withOverlay
            message="Changes made to audition details won’t be saved."
            title="Discard changes?"
            actionBtnLabel="Discard changes"
            onCancel={handleModalCloseCancel}
            onAction={handleModalCloseConfirm}
          />
        )}
      </FullScreenModal>
      <AuditionPreviewModal
        audition={auditionValues}
        project={project}
        open={previewModalOpen}
        onClose={hidePreviewModal}
      />
    </>
  );
}

AuditionEditModal.propTypes = {
  project: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  audition: PropTypes.object,
  role: PropTypes.object,
};
