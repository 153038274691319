import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { InlineSections, InlineSection } from 'modules/ui';

import classes from './TargetAuditionLabel.module.scss';

function TargetAuditionLabel({ projectName, auditionName }) {
  return (
    <InlineSections className={classes.container} displayEndingLine>
      <InlineSection title="Project">
        <p className="body14 bold">{projectName}</p>
      </InlineSection>
      <InlineSection title="Audition">
        <p className="body14 bold">{auditionName}</p>
      </InlineSection>
    </InlineSections>
  );
}

TargetAuditionLabel.propTypes = {
  projectName: PropTypes.string.isRequired,
  auditionName: PropTypes.string.isRequired,
};

export default memo(TargetAuditionLabel);
