import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LetterIcon } from '../assets';

import MessageIndicator from './MessageIndicator';
import classes from './MessagingEnvelope.module.scss';

function MessagingEnvelope({ className, unreadMessages, onClick }) {
  const hasUnreadMessages = unreadMessages > 0;
  return (
    <div
      className={classNames(classes.envelope, className)}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <LetterIcon />
      {hasUnreadMessages && (
        <MessageIndicator
          className={classes.indicator}
          unreadMessages={unreadMessages}
        />
      )}
    </div>
  );
}

MessagingEnvelope.propTypes = {
  className: PropTypes.string,
  unreadMessages: PropTypes.number,
  onClick: PropTypes.func,
};

export default MessagingEnvelope;
