import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { loadTalent } from 'modules/talents';
import { loadProjects, getProjects, Project } from 'modules/projects';
import { getProducer } from 'modules/producers';
import { showNotification, Notification } from 'modules/notifications';
import {
  FormDialog,
  ManagedForm,
  OptionUtil,
  Select,
  Textarea,
} from 'modules/ui';
import { getRolesByProjectId, loadProjectRoles } from 'modules/project-roles';
import Dictionary from 'modules/dictionary';
import { Colors } from 'modules/theme';
import {
  addTopCandidate,
  updateTopCandidate,
  getTopCandidatesError,
  removeErrorAction,
  getTopCandidatesByRoleId,
  loadTopCandidates,
} from '../redux';
import { Validator } from '../services';

const RESOURCE_LOAD = 3;

const styles = {
  noteDescription: {
    color: Colors.grayishBlue,
    marginBottom: '20px',
    marginTop: '-30px',
    textAlign: 'justify',
  },
};

export default function SampleTopCandidateDialog({
  sample,
  onCancel,
  onClose,
}) {
  const dispatch = useDispatch();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const roles = useSelector(getRolesByProjectId(selectedProjectId));
  const projects = useSelector(getProjects);
  const producer = useSelector(getProducer);
  const error = useSelector(getTopCandidatesError);
  const topCandidates = useSelector(getTopCandidatesByRoleId(selectedRoleId));

  const initialValues = {
    projectId: null,
    roleId: null,
    message: null,
  };

  const [resourcesLoaded, setResourcesLoaded] = useState(0);
  const [talent, setTalent] = useState(null);
  const [values, setValues] = useState(initialValues);

  const producerId = get(producer, 'id');
  const talentId = get(sample, 'talentId');
  const sampleId = get(sample, 'id');

  useEffect(() => () => dispatch(removeErrorAction()), [dispatch]);

  useEffect(() => {
    dispatch(loadTalent(talentId)).then(loadedTalent => {
      const data = get(loadedTalent, 'data');
      setTalent(data);
      setResourcesLoaded(loaded => loaded + 1);
    });
  }, [dispatch, talentId]);

  useEffect(() => {
    dispatch(
      loadTopCandidates(producerId, selectedProjectId, selectedRoleId),
    ).then(() => setResourcesLoaded(loaded => loaded + 1));
  }, [dispatch, producerId, selectedProjectId, selectedRoleId]);

  useEffect(() => {
    dispatch(loadProjects(producerId)).then(() =>
      setResourcesLoaded(loaded => loaded + 1),
    );
  }, [dispatch, producerId]);

  useEffect(() => {
    dispatch(loadProjectRoles(producerId, selectedProjectId));
  }, [dispatch, selectedProjectId, producerId]);

  function handleResponse(response) {
    if (!response) {
      return;
    }

    dispatch(
      showNotification({
        message: 'Sample added to top candidates!',
        type: Notification.TYPES.SUCCESS,
      }),
    );
    onClose();
  }

  function handleSubmit() {
    const { projectId, roleId, message } = values;

    const existingTopCandidate = find(topCandidates, { talentId });

    if (existingTopCandidate) {
      return dispatch(
        updateTopCandidate(
          producerId,
          projectId,
          roleId,
          existingTopCandidate.id,
          null,
          sampleId,
          message,
          Dictionary.TOP_CANDIDATE_SOURCE.PROFILE,
        ),
      ).then(response => handleResponse(response));
    }

    return dispatch(
      addTopCandidate(
        producerId,
        projectId,
        roleId,
        talentId,
        null,
        sampleId,
        message,
        Dictionary.TOP_CANDIDATE_SOURCE.PROFILE,
      ),
    ).then(response => handleResponse(response));
  }

  function handleProjectChange(event) {
    const projectId = get(event, 'target.value');
    setSelectedProjectId(projectId);
  }

  function handleRoleChange(event) {
    const roleId = get(event, 'target.value');
    setSelectedRoleId(roleId);
  }

  function handleValueChange(formValues) {
    setValues(formValues);
  }

  const talentName = get(talent, 'fullName', '');
  const dialogTitle = `Add ${talentName}’s sample to Top Candidates`;

  const loading = resourcesLoaded < RESOURCE_LOAD;
  const loadingError = isEmpty(projects) && !loading;

  return (
    <FormDialog
      loading={loading}
      title={!loading && dialogTitle}
      onClose={onClose}
      error={loadingError}
      errorMessage="You have no projects at the moment. You can only add talent to top candidates for a project role."
    >
      <ManagedForm
        error={error}
        initialValues={values}
        onChangeValues={handleValueChange}
        validate={Validator.validateSample}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitButtonLabel="Add"
      >
        <Select
          required
          onChange={handleProjectChange}
          name="projectId"
          label="Choose a project"
          placeholder="Choose a project"
          options={Project.createProjectOptions(projects)}
          disabled={!projects}
        />
        <Select
          required
          onChange={handleRoleChange}
          disabled={!selectedProjectId || !projects}
          name="roleId"
          label="Choose a role"
          placeholder="Choose a role"
          options={OptionUtil.getOptions(roles, OptionUtil.mapOptionFromModel)}
        />
        <Textarea
          disabled={!projects}
          label="Sample notes"
          name="message"
          placeholder="Add notes about this sample"
        />
        <div style={styles.noteDescription}>
          Notes will be included if you share Top Candidates.
        </div>
      </ManagedForm>
    </FormDialog>
  );
}

SampleTopCandidateDialog.propTypes = {
  sample: PropTypes.object,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
