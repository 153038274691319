import React from 'react';
import PropTypes from 'prop-types';
import { FullScreenModal } from 'modules/ui';
import { AuditionPreviewPage } from '../fragments';

export default function AuditionPreviewModal({
  onClose,
  audition,
  project,
  open,
}) {
  return (
    <FullScreenModal
      className="container container--fullWidth"
      title="Preview Audition"
      onClose={onClose}
      open={open}
    >
      <AuditionPreviewPage audition={audition} project={project} />
    </FullScreenModal>
  );
}

AuditionPreviewModal.propTypes = {
  onClose: PropTypes.func,
  audition: PropTypes.object,
  project: PropTypes.object,
  open: PropTypes.bool,
};
