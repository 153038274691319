import { createThunk } from 'redux-scope';
import { createReducer, createSelector } from 'redux-starter-kit';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import {
  generateAssetURL,
  signUploadURL,
  uploadFileToServer,
  signDownloadURL,
  downloadAsset,
  signProducerApplicationUploadUrl,
} from './api';
import { Assets } from './services';

const INITIAL_STATE = {
  loading: false,
};

export function uploadAssetActions( // S3 upload
  file, // file to upload
  assetType, //'audio'
  assetClass, //'auditionAudioClip'
  context, // { auditionId, applicationId, messageId }
  isProducerApplication, // null
) {
  if (!file) {
    return Promise.resolve(null);
  }

  if (!isObject(file)) {
    return Promise.resolve(file);
  }

  const { name: assetFileName } = file;
  const truncatedAssetFileName = () => {
    if (assetFileName.length > 190) {
      return truncate(assetFileName, {
        length: 180,
        omission: '',
      });
    }
    return assetFileName;
  };
  let finalAssetPath = null;

  // generate S3 URL for clip
  return generateAssetURL(assetType, assetClass, context)
    .then(payload => {
      const assetFolderPath = get(payload, 'data.assetData.assetPath');
      // section for Producer user signup
      if (isProducerApplication) {
        return signProducerApplicationUploadUrl(
          truncatedAssetFileName(),
          assetFolderPath,
        );
      }

      return signUploadURL(truncatedAssetFileName(), assetFolderPath); // ????
    })
    .then(payload => {
      const assetPath = get(payload, 'data.assetPath');
      const signedUploadUrl = get(payload, 'data.signedUploadUrl');
      finalAssetPath = assetPath; // return var assignment!

      return uploadFileToServer(signedUploadUrl, file); // S3 upload, returns a Promise with a response
    })
    .then(result => {
      if (result.status !== 200) {
        return Promise.reject(result);
      } else {
        return finalAssetPath;
      }
    });
}

export const uploadAsset = createThunk(uploadAssetActions, 'upload', 'assets');

export function getAsset(assetPath, convertToUrl, token = null) {
  if (!assetPath) {
    return Promise.resolve(null);
  }

  const headers = {};

  if (token) {
    headers['auth-code'] = token;
  }

  if (Assets.isBlobUrl(assetPath)) {
    return downloadAsset(assetPath, convertToUrl);
  }

  return signDownloadURL(assetPath, headers)
    .then(payload => {
      const currentAudioUrl = get(payload, 'data.signedDownloadUrl', null);

      if (!currentAudioUrl) {
        return Promise.reject(new Error('Download URL could not be created.'));
      }

      return downloadAsset(currentAudioUrl, convertToUrl);
    })
    .catch(err => {
      Promise.reject(err);
    });
}

export const loadAsset = createThunk(getAsset, 'download', 'assets');

export const reducer = createReducer(INITIAL_STATE, {
  [loadAsset.type.request]: state => {
    state.loading = true;
  },
  [loadAsset.type.error]: state => {
    state.loading = false;
  },
  [loadAsset.type.success]: state => {
    state.loading = false;
  },
});

export const getLoading = createSelector(['assets.loading']);
