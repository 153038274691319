import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './LineDivider.module.scss';

function LineDivider({ className }) {
  const dividerClasses = classNames(className, classes.divider);
  return <div className={dividerClasses} />;
}
LineDivider.propTypes = {
  className: PropTypes.string,
};

export default memo(LineDivider);
