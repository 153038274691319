import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../Icon';
import styles from './Radio.module.scss';

function Radio({
  checked,
  disabled,
  label,
  name,
  onClick,
  className,
  labelClassName,
}) {
  const handleClick = event => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    onClick();
  };

  const inputId = `radio-${name}-${label}`;
  const classes = classNames(styles.container, disabled && styles.disabled);

  const radioClasses = classNames(
    styles.radio,
    checked && styles['radio--checked'],
    className,
  );

  return (
    <label htmlFor={inputId} className={classes} onClick={handleClick}>
      <div className={radioClasses}>
        {checked && <Icon name="checked" color="#fff" size={30} />}
      </div>
      <input id={inputId} type="radio" name={name} className={styles.label} />
      <span className={labelClassName}>{label}</span>
    </label>
  );
}

Radio.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default memo(Radio);
