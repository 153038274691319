import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TalentProfileVisibilityPanel } from 'modules/talents';
import styles from './TabLayout.module.scss';

function TabLayout({
  children,
  tabs,
  containerClassName,
  menuClassName,
  showVisibilityPanel,
}) {
  const tabContainerClasses = classNames(styles.tabLayout, containerClassName);
  const tabMenuClasses = classNames(styles.tabMenu, menuClassName);

  return (
    <div className={tabContainerClasses}>
      <div className={tabMenuClasses}>{tabs}</div>
      <div className={styles.tabContent}>{children}</div>
      {showVisibilityPanel && (
        <div className={styles.requirementContent}>
          <TalentProfileVisibilityPanel />
        </div>
      )}
    </div>
  );
}

TabLayout.propTypes = {
  containerClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  tabs: PropTypes.node,
  showVisibilityPanel: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

TabLayout.defaultProps = {
  showVisibilityPanel: true,
};

export default memo(TabLayout);
