import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import join from 'lodash/join';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid/Grid';
import { InfoListItem, PageLoader, Section, SocialLinks } from 'modules/ui';
import { DictionaryUtil } from 'modules/dictionary';
import { Validator } from 'modules/core/services';
import {
  getAgentAssignments,
  loadAgents,
  getAgents,
  loadAgentAssignments,
} from 'modules/agents';
import {
  getManagerAssignments,
  loadManagerAssignments,
} from 'modules/managers';
import BiographySection from 'modules/producers/components/BiographySection';
import {
  AudioSamplesSection,
  VoiceSkillsSection,
  PersonalInfoSection,
} from './index';
import { Talent } from '../services';
import AssignmentListItem from './AssignmentListItem';
import AdditionalInfoSection from './AdditionalInfoSection';
import TalentAgentsList from './TalentAgentsList';
import classes from './TalentDetails.module.scss';

const styles = {
  sidebar: {
    fontSize: 14,
  },
};

function TalentDetails({ talent, onAddSampleClick }) {
  const dispatch = useDispatch();
  const agentAssignments = useSelector(getAgentAssignments);
  const managerAssignments = useSelector(getManagerAssignments);
  const agents = useSelector(getAgents);

  useEffect(() => {
    if (!agentAssignments) {
      dispatch(loadAgentAssignments());
    }
    if (agentAssignments) {
      dispatch(loadAgents(agentAssignments));
    }
    if (!managerAssignments) {
      dispatch(loadManagerAssignments());
    }
  }, [dispatch, agentAssignments]);

  if (!talent) {
    return <PageLoader />;
  }

  const {
    generalKnowledge,
    biography,
    unions,
    professionalAwards,
    city,
  } = talent;

  const personalInfo = {
    location: DictionaryUtil.getLocationString(talent.country, talent.state),
    city,
  };

  const websiteLinks = pick(talent, ['websiteLink']);
  const additionalWebsites = pickBy(
    pick(talent, ['customUrl1', 'customUrl2', 'customUrl3', 'customUrl4']),
    value => value !== null,
  );
  const socialLinks = pick(talent, Talent.SOCIAL_MEDIA_LINKS);
  const populatedLinks = pickBy(socialLinks);

  const sectionContentClass = classNames('body16', classes.sectionContent);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <AudioSamplesSection talent={talent} onAddClick={onAddSampleClick} />
        <PersonalInfoSection talent={talent} />
        <VoiceSkillsSection talent={talent} />
        <AdditionalInfoSection talent={talent} />
        <BiographySection
          emptyBioNotice="Give us a short info about you and your work."
          shortBio={biography}
        />
        <Section
          isEmpty={!generalKnowledge}
          title="General Knowledge"
          placeholderText="Tell us about topics that interest you."
        >
          <p className={sectionContentClass}>{generalKnowledge}</p>
        </Section>
      </Grid>
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={3}>
        <Section
          title="Info"
          placeholderText="Add your personal info."
          className="mb-60"
          isEmpty={isEmpty(
            omitBy(
              { ...personalInfo, ...websiteLinks, ...populatedLinks },
              isNull,
            ),
          )}
        >
          <InfoListItem
            small
            title={personalInfo.location}
            subtitle={personalInfo.city}
            icon="location"
          />
          {map(websiteLinks, (link, name) => (
            <InfoListItem
              small
              title={link}
              icon={name}
              link={Validator.formatUrl(link)}
            />
          ))}
          <SocialLinks links={populatedLinks} />
        </Section>
        <Section
          title="Additional Links"
          placeholderText="Add your additional links."
          style={styles.sidebar}
          isEmpty={isEmpty(additionalWebsites)}
        >
          {map(additionalWebsites, website => {
            return (
              <InfoListItem
                small
                title={website.name}
                icon="websiteLink"
                link={Validator.formatUrl(website.url)}
              />
            );
          })}
        </Section>
        <Section
          title="Awards"
          placeholderText="Add your professional awards."
          className="mb-60"
        >
          {!isEmpty(professionalAwards) &&
            map(professionalAwards, award => (
              <InfoListItem
                description={award.year}
                title={award.name}
                icon="award"
              />
            ))}
        </Section>
        <Section
          title="Unions"
          placeholderText="Add unions you're a member of."
          style={styles.sidebar}
        >
          {join(unions, ', ')}
        </Section>
        <Section
          title="Agents & Managers"
          placeholderText="Tell us who represents you."
          style={styles.sidebar}
          isEmpty={isEmpty(managerAssignments) && isEmpty(agentAssignments)}
        >
          {!isEmpty(managerAssignments) && (
            <h3 className={classes.sectionTitle}>Manager</h3>
          )}
          {map(managerAssignments, assignment => (
            <AssignmentListItem
              name={`${assignment.managerFirstName} ${assignment.managerLastName}`}
              company={assignment.companyName}
            />
          ))}
          {!isEmpty(agentAssignments) && (
            <h3 className={classes.sectionTitle}>Agents</h3>
          )}
          <TalentAgentsList
            agentAssignments={agentAssignments}
            agents={agents}
          />
        </Section>
      </Grid>
    </Grid>
  );
}

export default TalentDetails;
