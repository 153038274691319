import { useState, useLayoutEffect, useRef } from 'react';
import { MOBILE_WIDTH, TABLET_WIDTH } from '../../const';

const screenSizes = {
  mobileWidth: 0,
  tabletWidth: 1,
  desktopWidth: 2,
};

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const [currentWidth, setCurrentWidth] = useState(screenSizes.desktopWidth);
  const initialWindowSize = useRef([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth <= MOBILE_WIDTH) {
        setCurrentWidth(screenSizes.mobileWidth);
      }
      if (
        window.innerWidth <= TABLET_WIDTH &&
        window.innerWidth > MOBILE_WIDTH
      ) {
        setCurrentWidth(screenSizes.tabletWidth);
      }
      if (window.innerWidth > TABLET_WIDTH) {
        setCurrentWidth(screenSizes.desktopWidth);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return {
    size,
    mobileWidth: currentWidth === screenSizes.mobileWidth,
    tabletWidth: currentWidth === screenSizes.tabletWidth,
    desktopWidth: currentWidth === screenSizes.desktopWidth,
    initialWindowWidth: initialWindowSize.current[0],
    initialWindowHeight: initialWindowSize.current[1]
  };
}
