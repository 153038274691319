import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

export default function ProfileDetailsGrid({ children }) {
  const items = Children.toArray(children);

  return (
    <Grid container spacing={0}>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[0]}
        </Grid>
        <Grid item xs={6}>
          {items[1]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
      <Grid item xs={6}>
          {items[2]}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        {items[3]}
      </Grid>
      <Grid container item xs={8}>
        <Grid item xs={12}>
          {items[4]}
        </Grid>
        <Grid item xs={12}>
          {items[5]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[6]}
        </Grid>
        <Grid item xs={6}>
          {items[7]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[8]}
        </Grid>
        <Grid item xs={6}>
          {items[9]}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {items[10]}
      </Grid>
    </Grid>
  );
}

ProfileDetailsGrid.propTypes = {
  children: PropTypes.node,
};
