import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import Grid from '@material-ui/core/Grid/Grid';
import { TablePagination } from 'modules/ui';
import OfferCard from './OfferCard';
import OffersEmptyPlaceholder from './OffersEmptyPlaceholder';

const styles = {
  cardsContainer: {
    display: 'flex',
    paddingBottom: 10,
  },
  gridContainer: {
    margin: 0,
  },
};

function OffersCardsList({
  offers,
  page,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  accountType,
  openInviteClient,
  hasNoOffers,
  searchedTalent,
  pageChangeScrollY,
}) {
  const history = useHistory();

  if (isEmpty(offers)) {
    return (
      <OffersEmptyPlaceholder
        accountType={accountType}
        openInviteClient={openInviteClient}
        hasNoOffers={hasNoOffers}
        searchedTalent={searchedTalent}
      />
    );
  }

  function handleCardClick(offerId) {
    history.push({
      pathname: `/offers/${offerId}`,
    });
  }

  const currentPageOffers = offers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <>
      <div style={styles.cardsContainer}>
        <Grid container spacing={2} xs={12} style={styles.gridContainer}>
          {map(currentPageOffers, offer => (
            <Grid item xs={12} sm={6} md={4}>
              <OfferCard
                key={offer.id}
                offer={offer}
                onClick={() => handleCardClick(offer.id)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <TablePagination
        page={page}
        count={offers.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={0}
        pageChangeScrollY={pageChangeScrollY}
        onChangePage={onPageChange}
        onChangeRowsPerPage={onRowsPerPageChange}
        rowsPerPageLabel="Offers Per Page"
      />
    </>
  );
}

OffersCardsList.propTypes = {
  offers: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  openInviteClient: PropTypes.func.isRequired,
  pageChangeScrollY: PropTypes.number.isRequired,
  hasNoOffers: PropTypes.bool,
  searchedTalent: PropTypes.object,
};

export default memo(OffersCardsList);
