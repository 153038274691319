import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import { Icon } from 'modules/ui';
import styles from './SelectArrow.module.scss';

export default function SelectArrow({ className, ...props }) {
  const classes = classNames(styles.arrow, className);
  return <Icon className={classes} name="selectArrow" {...props} />;
}

SelectArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

SelectArrow.defaultProps = {
  size: 12,
  color: Colors.darkBlue,
};
