import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Collapsible.module.scss';

export default function Collapsible({ collapsed, children }) {
  const [showChildren, setShowChildren] = useState(!collapsed);

  useEffect(() => {
    setShowChildren(!collapsed);
  }, [collapsed]);

  const childrenContainerClasses = classNames(
    styles.childrenContainer,
    showChildren && styles['childrenContainer--expanded'],
  );

  return (
    <div className={styles.container}>
      <div className={childrenContainerClasses}>{children}</div>
    </div>
  );
}

Collapsible.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.node,
};

Collapsible.defaultProps = {
  collapsed: true,
};
