import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Story.module.scss';

function Story({ title, dark, children, small, style }) {
  const classes = classNames(
    styles.story,
    dark && styles.dark,
    small && styles['story--small'],
  );

  return (
    <div className={classes} style={style}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

Story.propTypes = {
  title: PropTypes.string,
  dark: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  style: PropTypes.object,
  small: PropTypes.bool,
};

export default memo(Story);
