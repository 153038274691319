import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import classes from './LinkWrapper.module.scss';

function LinkWrapper({ to, children }) {
  function preventClickPropagation(e) {
    e.stopPropagation();
  }

  return (
    <Link
      to={to}
      onClick={preventClickPropagation}
      className={classes.talentLink}
    >
      {children}
    </Link>
  );
}

LinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(LinkWrapper);
