import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'modules/theme';

const useStyles = makeStyles({
  root: {
    minHeight: 0,
    padding: '12px 24px',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: Colors.darkBlue,
    '&::after': {
      background: 'none',
    },
  },
});

function AccountDropdownMenuItem({ text, onClick }) {
  const classes = useStyles();
  return (
    <MenuItem className={classes.root} onClick={onClick}>
      {text}
    </MenuItem>
  );
}

AccountDropdownMenuItem.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default memo(AccountDropdownMenuItem);
