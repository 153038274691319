import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormHeader, Page, PageLoader, TabLayout } from 'modules/ui';
import { getTalent } from '../redux';
import { TalentProfileTabs } from '../components';
import AgentsForm from './AgentsForm';
import ManagersForm from './ManagersForm';

export default function EditAgentsManagersPage() {
  const [agentAssignmentsTouched, setAgentAssignmentsTouched] = useState([]);
  const [managerAssignmentsTouched, setManagerAssignmentsTouched] = useState(
    [],
  );

  const talent = useSelector(getTalent);

  if (!talent) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<TalentProfileTabs />}>
        <FormHeader
          title="Agents and Managers"
          description="Keep your agents and managers up to date"
        />
        <AgentsForm
          talentId={talent.id}
          agentAssignmentsTouched={agentAssignmentsTouched}
          setAgentAssignmentsTouched={setAgentAssignmentsTouched}
        />
        <ManagersForm
          talentId={talent.id}
          managerAssignmentsTouched={managerAssignmentsTouched}
          setManagerAssignmentsTouched={setManagerAssignmentsTouched}
        />
        <Prompt
          when={
            !isEmpty(agentAssignmentsTouched) ||
            !isEmpty(managerAssignmentsTouched)
          }
          message="Discard changes?"
        />
      </TabLayout>
    </Page>
  );
}
