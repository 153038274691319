import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ManagedForm, Page, PageLoader, PasswordInput } from 'modules/ui';
import { Colors } from 'modules/theme';
import { AuthHeader } from '../components';
import { Validator } from '../services';
import {
  getCompleteNewPasswordError,
  getCompleteNewPasswordSuccess,
  completeNewPassword,
  login,
  getCognitoUser,
} from '../redux';
import { Notification, showNotification } from '../../notifications';
import { PASSWORD_NOT_REQUIRED } from '../api';

const styles = {
  container: {
    flexBasis: 360,
  },
  changeButton: {
    width: '100%',
  },
  footer: {
    marginTop: 3,
  },
};

export default function CompleteNewPasswordPage({ location }) {
  const dispatch = useDispatch();
  const error = useSelector(getCompleteNewPasswordError);
  const success = useSelector(getCompleteNewPasswordSuccess);
  const user = useSelector(getCognitoUser);

  const [newPassword, setNewPassword] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const email = get(location, 'state.email');

  useEffect(() => {
    if (success) {
      setLoginLoading(true);
      dispatch(login({ password: newPassword, email }))
        .then(() =>
          dispatch(
            showNotification({
              message: `New password successfully set, welcome!`,
              type: Notification.TYPES.SUCCESS,
            }),
          ),
        )
        .then(() => setLoginLoading(false));
    }
  }, [success, dispatch, newPassword, email]);

  const handleCompleteNewPassword = ({ password }) => {
    setNewPassword(password);
    return dispatch(completeNewPassword(user, password, email));
  };

  if (loginLoading) {
    return <PageLoader />;
  }

  return (
    <Page
      className="pt-64"
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/login"
          linkLabel={`Return to ${window.location.hostname}`}
          onClick={() => dispatch({ type: PASSWORD_NOT_REQUIRED })}
        />
      }
    >
      <ManagedForm
        title="Set new password"
        submitButtonLabel="Set password"
        btnStyle={styles.changeButton}
        onSubmit={handleCompleteNewPassword}
        validate={Validator.completeNewPasswordData}
        footerStyle={styles.footer}
        error={error}
        style={styles.container}
        className="mt-48"
      >
        <PasswordInput
          showHint
          label="Password"
          name="password"
          placeholder="Enter password"
        />
        <PasswordInput
          label="Confirm password"
          name="confirmPassword"
          placeholder="Enter password"
        />
      </ManagedForm>
    </Page>
  );
}

CompleteNewPasswordPage.propTypes = {
  location: PropTypes.object,
};
