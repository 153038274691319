import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import { AuditionApplicationChatPopup } from 'modules/audition-messages';
import { GlobalChatPopup } from 'modules/global-messages';
import { getAccount } from 'modules/accounts';
import { useWindowSize } from 'modules/core';

import { useChatRefresh } from '../hooks';
import { getChatWindows } from '../redux';
import { ChatType } from '../const';

import classes from './Chat.module.scss';

function Chat() {
  const chatWindows = useSelector(getChatWindows);
  const { desktopWidth } = useWindowSize();

  const account = useSelector(getAccount);
  const accountId = get(account, 'id');

  useChatRefresh(accountId);

  function resolveChatWindowType(chatWindow) {
    const { id, type, ...rest } = chatWindow;
    if (type === ChatType.GLOBAL) {
      return (
        <GlobalChatPopup isDesktopWidth={desktopWidth} key={id} {...rest} />
      );
    }
    return (
      <AuditionApplicationChatPopup
        isDesktopWidth={desktopWidth}
        key={id}
        {...rest}
      />
    );
  }

  if (desktopWidth) {
    return (
      <div className={classes.chat}>
        {chatWindows.map(resolveChatWindowType)}
      </div>
    );
  }

  return chatWindows.map(resolveChatWindowType);
}

export default Chat;
