import React, { Children, cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import without from 'lodash/without';
import concat from 'lodash/concat';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import xor from 'lodash/xor';
import includes from 'lodash/includes';
import { Input } from 'modules/ui';
import { useDidMount } from 'modules/core/services/hooks';

export default function OptionGroup({
  setFormValue,
  value,
  name,
  disabled,
  children,
  onChange,
  ...otherProps
}) {
  const [optionValues, setOptionValues] = useState(value || []);
  const didMount = useDidMount();

  function handleSetOptionValues(resolvedValues) {
    const compactedValues = compact(resolvedValues);
    setOptionValues(compactedValues);

    if (setFormValue) {
      setFormValue(name, compactedValues);
    }

    if (onChange) {
      onChange(compactedValues);
    }
  }

  function hasChange() {
    return !isEmpty(xor(value, optionValues));
  }

  useEffect(() => {
    if (didMount && hasChange()) {
      handleSetOptionValues(value);
    }
  }, [value]);

  function handleChange(clickedValue) {
    const resolvedValues = includes(optionValues, clickedValue)
      ? without(optionValues, clickedValue)
      : concat(optionValues, clickedValue);

    handleSetOptionValues(resolvedValues);
  }

  function renderChild(child) {
    const {
      props: { value: childValue },
    } = child;

    const selected = includes(optionValues, childValue);

    return cloneElement(child, {
      name,
      selected,
      disabled,
      isCheckbox: true,
      onClick: () => handleChange(childValue),
    });
  }

  return <Input {...otherProps}>{Children.map(children, renderChild)}</Input>;
}

OptionGroup.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  setFormValue: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
};

OptionGroup.defaultProps = {
  value: false,
};
