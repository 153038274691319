import React from 'react';
import map from 'lodash/map';
import normalizeUrl from 'normalize-url';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import { IconButton } from 'modules/ui';
import styles from './SocialLinks.module.scss';

function handleLinkClick(link) {
  window.open(normalizeUrl(link), '_blank');
}

export default function SocialLinks({ links }) {
  return (
    <div className={styles.container}>
      {map(links, (link, socialMediaName) => (
        <IconButton
          small
          iconSize={24}
          iconName={socialMediaName}
          iconColor={Colors.darkGrey}
          onClick={() => handleLinkClick(link)}
          className={styles.link}
          key={socialMediaName}
        />
      ))}
    </div>
  );
}

SocialLinks.propTypes = {
  links: PropTypes.object,
};
