import React from 'react';
import PropTypes from 'prop-types';
import styles from './CloseButton.module.scss';

function CloseButton({ light, onClick }) {
  const stroke = light ? 'rgba(36, 45, 75, 0.35)' : '#051528';
  const strokeWidth = light ? '1' : '3';
  return (
    <button className={styles.closeButton} type="button" onClick={onClick}>
      <svg
        className={styles.closeSVG}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}

CloseButton.propTypes = {
  light: PropTypes.bool,
  onClick: PropTypes.func,
};

CloseButton.defaultProps = {
  light: false,
};

export default CloseButton;
