import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Page } from 'modules/ui';
import { Colors } from 'modules/theme';
import { showNotification, Notification } from 'modules/notifications';
import { WelcomeLogo } from '../components/AuthPage/assets';
import { AuthHeader } from '../components';
import {
  clearResendVerificationEmailStatus,
  resendVerificationEmail,
  getPendingVerification,
  getResendVerificationEmailError,
  getResendVerificationEmailSuccess,
} from '../redux';

const styles = {
  container: {
    paddingTop: 64,
    textAlign: 'center',
  },
  title: {
    paddingTop: 60,
    paddingBottom: 25,
    fontWeight: 'bold',
    fontFamily: 'Noto Serif',
    fontSize: 32,
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.darkBlue,
  },
  description: {
    marginBottom: 69,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 360,
    fontSize: 16,
    alignItems: 'center',
    textAlign: 'center',
    color: Colors.darkGrey,
    lineHeight: '28px',
  },
  verificationEmail: {
    fontWeight: 'bold',
  },
  resendContainer: {
    lineHeight: '25px',
    paddingLeft: 15,
    paddingRight: 15,
  },
  resendPlaceholder: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: Colors.darkGrey,
  },
  resendButton: {
    fontWeight: 600,
    fontSize: 14,
    color: Colors.secondary,
    cursor: 'pointer',
  },
};

// TODO: We don't handle a resend error
export default function WelcomePage({ location, history }) {
  const dispatch = useDispatch();
  const email = _.get(location, 'state.email');
  const pendingVerification = useSelector(getPendingVerification);

  const resendVerificationEmailError = useSelector(
    getResendVerificationEmailError,
  );
  const resendVerificationEmailSuccess = useSelector(
    getResendVerificationEmailSuccess,
  );

  useEffect(() => {
    if (resendVerificationEmailSuccess) {
      dispatch(
        showNotification({
          message: 'We’ve resent you a verification mail.',
          type: Notification.TYPES.SUCCESS,
        }),
      );
    }
  }, [dispatch, resendVerificationEmailSuccess]);

  useEffect(() => {
    if (resendVerificationEmailError) {
      dispatch(
        showNotification({
          message: resendVerificationEmailError,
          type: Notification.TYPES.ERROR,
        }),
      );
    }
  }, [dispatch, resendVerificationEmailError]);

  const handleResendEmail = () => {
    dispatch(clearResendVerificationEmailStatus());
    dispatch(resendVerificationEmail(email));
  };

  if (!pendingVerification || !email) {
    history.push('/login');
  }

  useEffect(() => () => dispatch(clearResendVerificationEmailStatus()), [
    dispatch,
  ]);

  return (
    <Page
      className="pt-64"
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/login"
          linkLabel={`Return to ${window.location.hostname}`}
        />
      }
    >
      <div style={styles.container}>
        <WelcomeLogo />
        <div style={styles.title}>Welcome to Ahab!</div>
        <div style={styles.description}>
          <span style={styles.verificationEmail}>A verification email </span>
          <div>
            has been sent to your email address. Please confirm your account to
            access the platform.
          </div>
        </div>
        <div style={styles.resendContainer}>
          <div style={styles.resendPlaceholder}>
            Didn’t get the email? Check your spam folder or{' '}
          </div>
          <div style={styles.resendButton} onClick={handleResendEmail}>
            Resend verification email
          </div>
        </div>
      </div>
    </Page>
  );
}

WelcomePage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
