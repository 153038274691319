import { createReducer, createAction } from 'redux-starter-kit';
import createSelector from 'selectorator';
import is from 'is_js';
import { SET_SERVER_ERROR, RESET_SERVER_ERROR } from './api';

const INITIAL_STATE = {
  serverError: false,
  isIOS: is.ios(),
  disablePreventClose: false,
  tealiumScriptLoaded: false,
};

export const setDisablePreventClose = createAction(
  '@core/disablePreventClose/set',
);

export const setTealiumScriptLoaded = createAction('core/tealiumScriptLoaded');

const reducer = createReducer(INITIAL_STATE, {
  [SET_SERVER_ERROR]: (state, action) => {
    state.serverError = true;
  },
  [RESET_SERVER_ERROR]: (state, action) => {
    state.serverError = false;
  },
  [setDisablePreventClose]: (state, action) => {
    state.disablePreventClose = action.payload;
  },
  [setTealiumScriptLoaded]: (state, action) => {
    state.tealiumScriptLoaded = action.payload;
  },
});

export const getServerError = createSelector(['core.serverError']);
export const getIsIOS = createSelector(['core.isIOS']);
export const getDisablePreventClose = createSelector([
  'core.disablePreventClose',
]);
export const getTealiumScriptLoaded = createSelector([
  'core.tealiumScriptLoaded',
]);

export default reducer;
