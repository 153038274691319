import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  FormDialog,
  ManagedForm,
  PhoneInput,
  Textarea,
  TextInput,
  Drawer,
} from 'modules/ui';
import { useWindowSize } from 'modules/core';
import { showNotification, Notification } from 'modules/notifications';
import { getAccountType, getAccount, AccountType } from 'modules/accounts';
import { getAgent } from 'modules/agents';
import { getTalent } from 'modules/talents';
import { Validator } from '../services';
import { setOfferStatus, getOfferById } from '../redux';
import { OFFER_ACTIONS } from '../api';
import classes from './OfferPage.module.scss';

const styles = {
  dialogContainer: {
    maxHeight: 'calc(100vh - 148px)',
    overflowY: 'auto',
  },
  title: {
    marginBottom: 0,
  },
  subtitle: {
    marginBottom: 25,
  },
};

function RespondToOfferModal({ offerId, producer, onClose, isOpen }) {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType);
  const account = useSelector(getAccount);
  const accountEmail = get(account, 'email');
  const { desktopWidth } = useWindowSize();

  const offer = useSelector(getOfferById(offerId));
  const role = get(offer, 'projectRole');
  const loggedInTalent = useSelector(getTalent);
  const loggedInAgent = useSelector(getAgent);
  const agentFullName = get(loggedInAgent, 'fullName');

  function resolvePhoneNumber() {
    if (AccountType.isAgent(accountType)) {
      return get(loggedInAgent, 'phoneNumber');
    }
    return get(loggedInTalent, 'contactNumber');
  }

  const handleSubmit = useCallback(
    values => {
      const roleName = get(role, 'name');
      const updatedValues = {
        ...values,
        fullName: agentFullName,
      };

      global.utag.link({
        event_type: 'negotiate_offer',
        page_type: 'Offer Page',
        module_type: roleName,
      });

      return dispatch(
        setOfferStatus(
          offerId,
          OFFER_ACTIONS.negotiating,
          updatedValues,
          accountType,
        ),
      ).then(() => {
        onClose();
        return dispatch(
          showNotification({
            message: 'Response sent!',
            type: Notification.TYPES.SUCCESS,
          }),
        );
      });
    },
    [dispatch, role, offerId],
  );

  if (!isOpen && desktopWidth) {
    return null;
  }

  const respondToOfferForm = (
    <ManagedForm
      validate={Validator.validateRespondToOffer}
      onSubmit={handleSubmit}
      onCancel={onClose}
      footerClassName={classes.modalFormFooter}
      updatedValues={{
        email: accountEmail,
        phoneNumber: resolvePhoneNumber(),
      }}
      title={
        !desktopWidth &&
        `Respond to ${get(producer, 'fullName', 'producer')}'s Offer`
      }
      description={
        !desktopWidth &&
        'Please enter your contact information and respond to this offer to finalize recording details.'
      }
      titleContainerClassName={!desktopWidth && classes.modalFormHeader}
    >
      <TextInput
        required
        label="Email"
        name="email"
        placeholder="julie@company.com"
        value={accountEmail}
        id="email"
      />
      <PhoneInput
        required
        autoFocus
        label="Phone number"
        name="phoneNumber"
        value={resolvePhoneNumber()}
        placeholder="Add your phone number here"
      />
      <Textarea
        label="Message"
        name="message"
        maxChars={500}
        placeholder="Write a message here"
      />
    </ManagedForm>
  );

  if (desktopWidth) {
    return (
      <FormDialog
        title={`Respond to ${get(producer, 'fullName', 'producer')}'s Offer`}
        subtitleText="Please enter your contact information and respond to this offer to finalize recording details."
        onClose={onClose}
        containerStyle={styles.dialogContainer}
        titleStyle={styles.title}
        subtitleStyle={styles.subtitle}
      >
        {respondToOfferForm}
      </FormDialog>
    );
  }

  return (
    <Drawer
      open={isOpen}
      anchor="left"
      onClose={onClose}
      showHeader
      drawerTitle="Offer Respond"
    >
      {respondToOfferForm}
    </Drawer>
  );
}

RespondToOfferModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  producer: PropTypes.object.isRequired,
  offerId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default memo(RespondToOfferModal);
