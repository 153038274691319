import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

export default function LoginFormFooterGrid({ children }) {
  const items = Children.toArray(children);

  return (
    <Grid container justify="flex-end" xs={12}>
      <Grid item sm={4} xs={12}>
        {items[0]}
      </Grid>
    </Grid>
  );
}

LoginFormFooterGrid.propTypes = {
  children: PropTypes.node,
};
