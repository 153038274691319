import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog, Input } from 'modules/ui';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { Validator } from '../services';
import FileControls from './FileControls';
import FileUpload from './FileUpload';
import FilePreview from './FilePreview';
import styles from './FileInput.module.scss';

export default function FileInput({
  value,
  name,
  type,
  validate,
  detailsPaths,
  setFormValue,
  description,
  onRemoveFileClick,
  buttonLabel,
  onSelectFile,
  ...otherProps
}) {
  const inputRef = useRef(null);
  const [fileError, setFileError] = useState(false);

  function handleReplaceClick() {
    inputRef.current.click();
  }

  function handleSelectFile(newFile) {
    const newFileError = Validator.validate(newFile, type);

    if (!isEmpty(newFileError)) {
      setFileError(newFileError);
      return;
    }

    setFileError(false);

    if (setFormValue) {
      setFormValue(name, newFile);
    }

    if (onSelectFile) {
      onSelectFile(newFile);
    }
  }

  const handleDialogAnswer = wantsUpload => {
    if (!wantsUpload) {
      inputRef.current.value = null;
      setFileError(false);
      return;
    }

    inputRef.current.click();
  };

  function handleRemoveFileClick() {
    if (setFormValue) {
      setFormValue(name, null);
    }

    if (onRemoveFileClick) {
      return onRemoveFileClick();
    }

    return Promise.resolve();
  }

  const containerClasses = classNames(
    styles.container,
    fileError && styles['container--error'],
  );

  return (
    <Input {...otherProps}>
      <div className={containerClasses}>
        <FileUpload
          file={value}
          error={fileError}
          setRef={inputRef}
          description={description}
          buttonLabel={buttonLabel}
          onSelectFile={handleSelectFile}
          validate={validate}
        />
        {value && (
          <div className={styles.fileContainer}>
            <FilePreview file={value} detailsPaths={detailsPaths} />
            <FileControls
              file={value}
              onRemoveFile={handleRemoveFileClick}
              onReplaceFile={handleReplaceClick}
            />
          </div>
        )}
        {fileError && (
          <ConfirmationDialog
            withOverlay
            title={fileError.title}
            subtitleText={fileError.subtitle}
            subtitleClassName={styles.dialogSubtitle}
            message={fileError.message}
            actionBtnLabel="Retry upload"
            onCancel={() => handleDialogAnswer(false)}
            onAction={() => handleDialogAnswer(true)}
          />
        )}
      </div>
    </Input>
  );
}

FileInput.propTypes = {
  buttonLabel: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  validate: PropTypes.func,
  detailsPaths: PropTypes.array,
  setFormValue: PropTypes.func,
  description: PropTypes.string,
  onRemoveFileClick: PropTypes.func,
  onSelectFile: PropTypes.func,
};
