import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAsset } from 'modules/assets';
import { TalentCardHeader } from 'modules/sharing';

function ClientCard({ talent }) {
  const history = useHistory();
  const { profileImage } = talent;
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    if (profileImage) {
      getAsset(profileImage).then(url => setProfileUrl(url));
    }
  }, [profileImage, setProfileUrl]);

  function handleClick() {
    const talentPath = `/talent/${talent.id}`;
    history.push(talentPath);
  }

  return (
    <TalentCardHeader
      onClick={handleClick}
      talent={talent}
      avatar={profileUrl}
      showOnlyHeader
    />
  );
}

ClientCard.propTypes = {
  talent: PropTypes.object.isRequired,
};

export default withRouter(ClientCard);
