import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtil } from 'modules/core';
import { Breadcrumbs } from '../Breadcrumbs';
import styles from './PageHeader.module.scss';

export default function PageHeader({
  children,
  breadcrumbPaths,
  className,
  large,
  LeftComponent,
  MenuComponent,
  actionClassName,
  contentClassName,
}) {
  const containerClasses = classNames(
    styles.container,
    large && styles['container--large'],
    className,
  );

  const contentClasses = classNames(styles.content_container, contentClassName);

  const actionClasses = classNames(styles.actions, actionClassName);

  return (
    <div className={containerClasses}>
      <div className={contentClasses}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs breadcrumbPaths={breadcrumbPaths} />
        </div>
        <div className={styles.content}>
          {ReactUtil.resolveComponent(LeftComponent)}
          <div className={actionClasses}>{children}</div>
        </div>
        {ReactUtil.resolveComponent(MenuComponent)}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  children: PropTypes.node,
  breadcrumbPaths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ),
  large: PropTypes.bool,
  className: PropTypes.string,
  actionClassName: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
  SearchInputComponent: PropTypes.node,
  contentClassName: PropTypes.string,
};
