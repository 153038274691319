import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem, IconButton } from 'modules/ui';
import { Colors } from 'modules/theme';
import styles from './TopCandidatesActionButtons.module.scss';

export default function TopCandidatesActionButtons({
  onAddByNameClick,
  onShareClick,
  projectFinished,
}) {
  const history = useHistory();

  function handleAddFromSearchClick() {
    history.push('/search');
  }

  return (
    /* This div here is important so that flex doesn't apply on buttons */
    <div>
      <IconButton
        label="Share list"
        iconName="shareTopCandidates"
        iconSize={20}
        iconClassName={styles.shareIcon}
        className={styles['actionButton--secondary']}
        onClick={onShareClick}
      />
      <DropdownButton
        primary
        disabled={projectFinished}
        buttonClassName={styles.actionButton}
        label="Add talent"
        iconName="downArrow"
        iconSize={10}
        iconColor={Colors.white}
        iconStyle={{ left: 30 }}
        arrowRightPosition="10px"
      >
        <MenuItem
          text="Add from search"
          icon="plus"
          onClick={handleAddFromSearchClick}
        />
        <MenuItem text="Add by name" icon="plus" onClick={onAddByNameClick} />
      </DropdownButton>
    </div>
  );
}

TopCandidatesActionButtons.propTypes = {
  onAddByNameClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  projectFinished: PropTypes.bool,
};
