import React, { Children, cloneElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Label } from '../Label';
import styles from './RadioGroup.module.scss';

export default function RadioGroup({
  name,
  label,
  disabled,
  children,
  value,
  className,
  required,
  error,
  vertical,
  setFormValue,
  labelClassName,
  onChange,
}) {
  function handleChange(newValue) {
    if (value !== newValue) {
      setFormValue(name, newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  }

  const renderChild = child => {
    const {
      props: { value: childValue, disabled: childDisabled },
    } = child;

    return cloneElement(child, {
      onClick: () => handleChange(childValue),
      checked: value === childValue,
      disabled: disabled || childDisabled,
    });
  };

  const classes = classNames(
    'mt-16',
    styles.container,
    vertical && styles.vertical,
    className,
  );

  return (
    <div className="mt-16 mb-36">
      <Label
        label={label}
        className={labelClassName}
        required={required}
        error={error}
      />
      <span className={classes}>{Children.map(children, renderChild)}</span>
    </div>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  setFormValue: PropTypes.func,
  vertical: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
};
