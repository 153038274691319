import { Children } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';
import compact from 'lodash/compact';

function calculateChanges(newObject, object) {
  return transform(newObject, (result, newValue, key) => {
    const value = object[key];

    if (isEqual(newValue, value)) {
      return;
    }

    if (isObject(newValue) && isObject(value)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = calculateChanges(newValue, value);
    }

    // eslint-disable-next-line no-param-reassign
    result[key] = newValue;
  });
}

/**
 * Calculates diff object between object and new object containing
 * only new stuff as difference
 * @param {*} newObject
 * @param {*} object
 */
function calculateDifferenceObject(newObject, object) {
  return calculateChanges(newObject, object);
}

function hasUnsavedChanges(values, savedValues) {
  const diffValues = calculateDifferenceObject(values, savedValues);

  return !isEmpty(diffValues);
}

function extractFromChildren(initialValues, children) {
  if (!children) {
    return { expectedKeys: [], filteredValues: [] };
  }

  if (!isArray(children)) {
    const { name, expected } = children;

    return {
      expectedKeys: [expected && name],
      filteredValues: pick(initialValues, [name]),
    };
  }

  const registeredFields = [];
  const expectedKeys = [];

  Children.map(compact(children), ({ props }) => {
    const { name, expected } = props;
    registeredFields.push(name);

    if (expected) {
      expectedKeys.push(name);
    }
  });

  return {
    expectedKeys,
    filteredValues: pick(initialValues, registeredFields),
  };
}

export default {
  hasUnsavedChanges,
  calculateDifferenceObject,
  extractFromChildren,
};
