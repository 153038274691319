import React from 'react';
import PropTypes from 'prop-types';
import { Collapsible, IconButton, MultiSelect } from 'modules/ui';
import Dictionary from 'modules/dictionary';
import { Colors } from 'modules/theme';
import styles from './SearchForm.module.scss';

export default function RepresentationSearchForm({
  handleSetFormValue,
  getValue,
  toggleUnionsFormOpen,
  unionsFormOpen,
}) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Representation</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleUnionsFormOpen}
        />
      </div>
      <Collapsible hideButton collapsed={!unionsFormOpen}>
        <MultiSelect
          name="unions"
          value={getValue('unions')}
          placeholder="Unions"
          setFormValue={handleSetFormValue}
          collection={Dictionary.UNIONS}
          options={Dictionary.UNION_OPTIONS}
          containerClassName={styles.select}
        />
      </Collapsible>
    </div>
  );
}

RepresentationSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleUnionsFormOpen: PropTypes.func.isRequired,
  unionsFormOpen: PropTypes.func.isRequired,
};
