import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import trim from 'lodash/trim';
import includes from 'lodash/includes';
import {
  TextInput,
  Textarea,
  AutoCompleteSelect,
  ManagedForm,
  OptionGroup,
  PhoneInput,
  OptionUtil,
  MultiSelect,
  RadioGroup,
  Radio,
  OptionUI,
  Label,
} from 'modules/ui';
import {
  Validator as AssetValidator,
  Assets,
  MAX_FILE_SIZE,
} from 'modules/assets';
import { AudioInput } from 'modules/audio';
import Dictionary from 'modules/dictionary';
import { Validator } from '../services';
import {
  getProducerApprovalError,
  getProducerApprovalSuccess,
  producerApproving,
  uploadApprovalAudioClip,
} from '../redux';
import { ContentCreatorApplicationGrid, AuthPage } from '../components';
import classes from './ContentCreatorApplication.module.scss';

const style = {
  noLabel: {
    marginTop: 34,
  },
  labelInput: {
    marginTop: -12,
  },
  noLabelInput: {
    marginTop: -36,
  },
};

export default function ContentCreatorApplicationForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [file, setFile] = useState(null);
  const [details, setDetails] = useState(null);

  const [country, setCountry] = useState('');
  const states = country ? country.states : null;
  const [availableStates, setAvailableStates] = useState(states);

  const [otherOption3, setOtherOption3] = useState('');
  const [otherInput3Inactive, setOtherInput3Inactive] = useState(true);
  const [otherOption4, setOtherOption4] = useState('');
  const [otherInput4Inactive, setOtherInput4Inactive] = useState(true);

  const size = Assets.convertBytesToMegaBytes(MAX_FILE_SIZE.audio);
  const description = `Supported formats: .mp3, .wav. Max size ${size}MB`;

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const history = useHistory();
  const applicationError = useSelector(getProducerApprovalError);
  const applicationSuccess = useSelector(getProducerApprovalSuccess);

  useEffect(() => {
    if (formSubmitted && applicationSuccess) {
      history.push({
        pathname: '/success',
        state: {
          title: 'Thank you for applying!',
          description:
            'Your application has been successfully sent to the Ahab Team for review.',
        },
      });
    }
  }, [formSubmitted, applicationSuccess, history]);

  function handleCountryChange(event) {
    const id = event.target.value;
    const currentCountry = find(Dictionary.LOCATIONS, { id });

    const foundStates = currentCountry ? currentCountry.states : null;

    setAvailableStates(foundStates);
    setCountry(currentCountry);
  }

  const stateOptions = OptionUtil.getOptions(
    availableStates,
    OptionUtil.mapOptionFromModel,
  );

  function handleOtherValueChange(value, questionNumber) {
    if (questionNumber === 4) {
      return setOtherOption4(value);
    }
    return setOtherOption3(value);
  }

  function handleValueChange(values) {
    if (!values.country) {
      setAvailableStates([]);
    }
  }

  function handleOtherInputFieldsDisabled(groupValue, groupNumber) {
    const otherInputStates = {
      3: {
        value: otherInput3Inactive,
        func: val => setOtherInput3Inactive(val),
      },
      4: {
        value: otherInput4Inactive,
        func: val => setOtherInput4Inactive(val),
      },
    };

    if (includes(groupValue, `OtherOptionValue${groupNumber}`)) {
      if (otherInputStates[groupNumber].value) {
        otherInputStates[groupNumber].func(false);
      }
    } else {
      otherInputStates[groupNumber].func(true);
    }
  }

  function handleSelectFile(fileObject) {
    const isReplacement = !!get(details, 'id', null);
    const { name } = fileObject;
    const filename = Assets.getNameWithoutExtension(name);
    setFile(fileObject);

    if (isReplacement) {
      setDetails({ ...details, name: filename, isReplacement });
      return;
    }
    setDetails({ name: filename });
  }

  function handleRemoveFile() {
    setFile(null);
    setDetails(null);

    return Promise.resolve();
  }

  const handleSubmit = producerData => {
    const { firstName, lastName, question4 } = producerData;
    const trimmedFirstName = trim(firstName);
    const trimmedLastName = trim(lastName);
    const context = { firstName: trimmedFirstName, lastName: trimmedLastName };

    const countryName = find(Dictionary.LOCATIONS, {
      id: producerData.country,
    });
    let state;

    if (producerData.state) {
      state = find(countryName.states, {
        id: producerData.state.toString(),
      });
    }
    function resolveQuestionThree() {
      if (producerData.question3 === 'OtherOptionValue3') {
        return otherOption3;
      }
      return producerData.question3;
    }

    const applicationData = {
      ...producerData,
      firstName: trimmedFirstName,
      lastName: trimmedLastName,
      country: countryName.name,
      state: !state ? undefined : state.name,
      question3: resolveQuestionThree(),
      question4: map(question4, answer => {
        if (answer === 'OtherOptionValue4') {
          return otherOption4;
        }
        return answer;
      }),
    };

    return dispatch(uploadApprovalAudioClip(file, context)).then(res =>
      dispatch(producerApproving({ ...applicationData, clipUrl: res })).then(
        setFormSubmitted(true),
      ),
    );
  };

  return (
    <AuthPage
      light
      headerLink="/Login"
      headerLinkLabel="Sign in"
      headerLinkDescription="Already have an account?"
    >
      <ManagedForm
        title="Start creating projects"
        description={
          <>
            <strong>Apply as a Content Creator</strong> and
            <strong> start</strong> creating projects & finding talent
          </>
        }
        footerDescription={
          'By clicking "Agree & Continue" I agree to Ahab’s Terms of Service and Privacy Policy.'
        }
        submitButtonLabel="Agree & Continue"
        onSubmit={handleSubmit}
        validate={Validator.producerApplicationData}
        onChangeValues={handleValueChange}
        TemplateComponent={ContentCreatorApplicationGrid}
        className={classes.form}
        error={applicationError}
      >
        <TextInput
          autoFocus
          label="First name"
          name="firstName"
          placeholder="E.g. Julie"
        />
        <TextInput label="Last name" name="lastName" placeholder="E.g. Doe" />
        <TextInput
          label="Email address"
          name="email"
          placeholder="julie@company.com"
          id="email"
        />
        <PhoneInput label="Contact number" name="phone" />
        <AutoCompleteSelect
          label="Address"
          name="country"
          placeholder="Enter country"
          onChange={handleCountryChange}
          options={Dictionary.LOCATIONS_OPTIONS}
        />
        <AutoCompleteSelect
          name="state"
          placeholder="Enter state"
          options={stateOptions}
          disabled={isEmpty(stateOptions)}
          style={style.noLabel}
          arrowStyle={style.noLabel}
        />
        <TextInput name="city" placeholder="City" />
        <TextInput name="zip" placeholder="ZIP Code" />
        <Textarea
          label="Biography"
          name="shortBio"
          maxChars={500}
          placeholder="Tell us about yourself."
          className={classes.textarea}
        />
        <TextInput
          label="Company"
          name="company"
          placeholder="Enter your company name"
        />
        <TextInput
          link
          label="Your website (optional)"
          name="website"
          placeholder="Add a link"
        />
        <Label label="Content You've produced" />
        <Label label="Add Link" />
        <TextInput
          style={style.labelInput}
          link
          name="contentNameOne"
          placeholder="Content name #1"
        />
        <TextInput
          style={style.labelInput}
          link
          name="contentLinkOne"
          placeholder="Add a link #1"
        />
        <TextInput
          style={style.noLabelInput}
          link
          name="contentNameTwo"
          placeholder="Content name #2 (optional)"
        />
        <TextInput
          style={style.noLabelInput}
          link
          name="contentLinkTwo"
          placeholder="Add a link #2 (optional)"
        />
        <TextInput
          style={style.noLabelInput}
          link
          name="contentNameThree"
          placeholder="Content name #3 (optional)"
        />
        <TextInput
          style={style.noLabelInput}
          link
          name="contentLinkThree"
          placeholder="Add a link #3 (optional)"
        />
        <AudioInput
          label="Sample"
          name="clipUrl"
          value={details}
          file={file}
          setRef={inputRef}
          description={description}
          buttonLabel="Upload sample"
          onSelectFile={handleSelectFile}
          validate={newFile => AssetValidator.validate(newFile, 'audio')}
          onRemoveFileClick={handleRemoveFile}
          descriptionClassName={classes.sample}
        />
        <MultiSelect
          label="Categories you’ve worked in"
          name="categories"
          placeholder="E.g. Audiobooks"
          collection={Dictionary.GENRE_CATEGORIES}
          options={Dictionary.VERTICAL_OPTIONS}
        />
        <TextInput
          label="Referred by (optional)"
          name="referredBy"
          placeholder="Enter name"
        />
        <RadioGroup
          name="question1"
          label="How often do you cast projects?"
          vertical
          labelClassName={classes.radioLabel}
        >
          <Radio
            label="Weekly"
            name="question1"
            value="Weekly"
            id="weekly"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="A few times a month"
            value="A few times a month"
            id="monthly"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="A few times a year"
            value="A few times a year"
            id="yearly"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
        </RadioGroup>
        <RadioGroup
          name="question2"
          label="How long have you been producing content?"
          vertical
          labelClassName={classes.radioLabel}
        >
          <Radio
            name="question2"
            label="1 - 2 years"
            value="1 - 2 years"
            id="oneTwo"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="3 - 10 years"
            value="3 - 10 years"
            id="threeSix"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="More than 10 years"
            value="More than 10 years"
            id="more"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
        </RadioGroup>
        <RadioGroup
          name="question3"
          label="How did you find out about us?"
          vertical
          labelClassName={classes.radioLabel}
          onChange={optionValue =>
            handleOtherInputFieldsDisabled(optionValue, 3)
          }
        >
          <Radio
            name="question3"
            label="Search Engine"
            value="Search Engine"
            id="website"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="Social Media"
            value="Social Media"
            id="socialNetworks"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="Referral"
            value="Referral"
            id="friend"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="Ahab Breakdown"
            value="Ahab Breakdown"
            id="ahabBreakdown"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="Advertisement"
            value="Advertisement"
            id="advertisement"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
          <Radio
            label="Other"
            value="OtherOptionValue3"
            id="other3"
            className={classes.radio}
            labelClassName={classes.radioLabel}
          />
        </RadioGroup>
        <TextInput
          name="otherOptionInput3"
          value={otherOption3}
          onChange={event => handleOtherValueChange(event.target.value, 3)}
          placeholder="Enter other"
          className={classes.otherInput}
          disabled={otherInput3Inactive}
        />
        <OptionGroup
          name="question4"
          label="What do you hope to accomplish by using Ahab?"
          inputContainerClassName={classes.checkboxContainer}
          labelClassName={classes.radioLabel}
          onChange={optionsValuesArray => {
            handleOtherInputFieldsDisabled(optionsValuesArray, 4);
          }}
        >
          <OptionUI
            name="question4"
            label="Discover new talent"
            value="Discover new talent"
            id="newTalent"
          />
          <OptionUI
            label="Discover talent with specific and/or underrepresented backgrounds in the VO industry"
            value="Discover talent with specific and/or underrepresented backgrounds in the VO industry"
            id="industry"
          />
          <OptionUI
            label="Discover talent with home studios"
            value="Discover talent with home studios"
            id="home"
          />
          <OptionUI
            label="Audition talent"
            value="Audition talent"
            id="auditionTalent"
          />
          <OptionUI
            label="Connect with Agents"
            value="Connect with Agents"
            id="agentConnections"
          />
          <OptionUI
            label="Cast for multiple voicover categories"
            id="multipleCategories"
            value="Cast for multiple voiceover categories"
          />
          <OptionUI label="Other" id="other4" value="OtherOptionValue4" />
        </OptionGroup>
        <TextInput
          name="otherOptionInput4"
          value={otherOption4}
          onChange={event => handleOtherValueChange(event.target.value, 4)}
          placeholder="Enter other"
          className={classes.otherInput}
          disabled={otherInput4Inactive}
        />
      </ManagedForm>
    </AuthPage>
  );
}

ContentCreatorApplicationGrid.propTypes = {
  signupAccountType: PropTypes.string.isRequired,
};
