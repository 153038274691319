import React, { memo, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Validator } from 'modules/assets';
import { ConfirmationDialog, Label } from 'modules/ui';
import styles from './ImageInputField.module.scss';

const uploadClasses = classNames('instruction', styles.instructionText);
const changeClasses = classNames(
  'instruction',
  styles.instructionText,
  styles.tintedBackground,
);

function ImageInputField({
  name,
  label,
  image,
  required,
  onChange,
  imageStyle,
  imageClass,
}) {
  const fileInput = useRef(null);
  const [error, setError] = useState(false);

  function handleImageUpload(event) {
    const imageFile = get(event, 'target.files.0');
    const imageError = Validator.validate(imageFile, 'image');

    if (!isEmpty(imageError)) {
      setError(imageError);
      return;
    }

    if(error) {
      setError(false);
    }
    onChange(imageFile);
  }

  function handleRemoveClick() {
    fileInput.current.value = null;
    onChange(null);
  }

  const handleDialogAnswer = wantsUpload => {
    if (!wantsUpload) {
      fileInput.current.value = null;
      setError(false);
      return;
    }

    fileInput.current.click();
  };

  const hasImage = !!image;

  const editButtonClasses = classNames(styles.editButton, imageClass);
  const imageClasses = classNames(styles.image, imageClass);

  return (
    <>
      <Label label={label} required={required} error={error} />
      <input
        name={name}
        type="file"
        className="hidden"
        ref={fileInput}
        onChange={handleImageUpload}
      />
      <button
        type="button"
        onClick={() => fileInput.current.click()}
        className={editButtonClasses}
        style={imageStyle}
      >
        {!hasImage && <div className={uploadClasses}>Upload photo</div>}
        {hasImage && (
          <img
            src={image}
            alt="cover"
            className={imageClasses}
            style={imageStyle}
          />
        )}
        {hasImage && <div className={changeClasses}>Change</div>}
      </button>
      {hasImage && (
        <button
          type="button"
          onClick={handleRemoveClick}
          className={styles.removeButton}
        >
          Remove
        </button>
      )}
      {error && (
        <ConfirmationDialog
          withOverlay
          title={error.title}
          message={error.message}
          actionBtnLabel="Retry upload"
          onCancel={() => handleDialogAnswer(false)}
          onAction={() => handleDialogAnswer(true)}
        />
      )}
    </>
  );
}

ImageInputField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  imageStyle: PropTypes.object,
  imageClass: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default memo(ImageInputField);
