import { Api } from 'modules/core';
import map from 'lodash/map';
import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import toString from 'lodash/join';

const TALENT_INCLUDES = [
  'pastRecordings',
  'audioSamples',
  'professionalAwards',
  'voiceAgeRanges',
  'recordingExperiences',
  'country',
  'state',
  'agentAssignments',
  'managerAssignments',
];

export function search(searchOptions) {
  const endpoint = 'talents/actions/search';
  const data = { resourceType: 'talent-search-actions', ...searchOptions };
  const includeArray = ['country', 'state', 'audioSamples'];
  const params = { include: toString(includeArray) };
  const sort = get(data, 'sort');

  if (sort) {
    params.sort = sort;
    delete data.sort;
  }

  if (has(data, 'offset')) {
    params['page[offset]'] = get(data, 'offset');
    delete data.offset;
  }

  if (has(data, 'limit')) {
    params['page[limit]'] = get(data, 'limit');
    delete data.limit;
  }

  return Api.create(endpoint, data, null, params);
}

export function fetchTalent(talentId) {
  if (!talentId) {
    return Promise.resolve();
  }

  const endpoint = `talents/${talentId}`;
  const params = { include: toString(TALENT_INCLUDES) };

  return Api.find(endpoint, params);
}

export function fetchTalents(filter, pageLimit = 20) {
  const endpoint = `talents`;
  const params = {
    include: toString(TALENT_INCLUDES),
    filter,
    'page[limit]': pageLimit,
  };

  return Api.find(endpoint, params);
}

export function postTalent(firstName, lastName) {
  const endpoint = 'talents';
  const talent = { resourceType: 'talents', firstName, lastName };

  return Api.create(endpoint, talent);
}

export function patchTalent(talentId, values) {
  const relationshipPaths = [
    'pastRecordings',
    'voiceAgeRanges',
    'recordingExperience',
    'country',
    'state',
  ];
  const {
    pastRecordingsIds,
    voiceAgeRangesIds,
    recordingExperienceId,
    countryId,
    stateId,
  } = values;

  const pastRecordings = map(pastRecordingsIds, id => ({
    type: 'past-recordings',
    id,
  }));
  const voiceAgeRanges = map(voiceAgeRangesIds, id => ({
    type: 'voice-age-ranges',
    id,
  }));

  const updateValues = omit(values, relationshipPaths);
  const relationships = {
    ...(pastRecordingsIds && {
      pastRecordings: {
        data: pastRecordings,
      },
    }),
    ...(voiceAgeRangesIds && {
      voiceAgeRanges: {
        data: voiceAgeRanges,
      },
    }),
    ...(recordingExperienceId && {
      recordingExperience: {
        data: { type: 'recording-experiences', id: recordingExperienceId },
      },
    }),
    ...(countryId && {
      country: {
        data: { type: 'countries', id: countryId },
      },
    }),
    ...(stateId && {
      state: {
        data: { type: 'states', id: stateId },
      },
    }),
  };

  const endpoint = `talents/${talentId}`;
  const params = { include: toString(TALENT_INCLUDES) };

  return Api.update(endpoint, updateValues, 'talents', relationships, params);
}

export function patchSamplesOrder(newOrder) {
  const endpoint = `talents/actions/reorderSamples`;
  const body = {
    newOrder,
  };
  return Api.update(endpoint, body, 'talent-sample-reorder-actions');
}
