import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { InlineSection, Section } from 'modules/ui';

function ProjectOverview({ project, role, inlineSectionClassName }) {
  return (
    <Section title="Project Details">
      <Grid container item spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <InlineSection
            title="Project name"
            className={inlineSectionClassName}
          >
            <p className="body16">{get(project, 'title')}</p>
          </InlineSection>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InlineSection title="Role name">
            <p className="body16">{get(role, 'name')}</p>
          </InlineSection>
        </Grid>
        <Grid item xs={12}>
          <InlineSection
            title="Description"
            className={inlineSectionClassName}
            isEmpty={isEmpty(get(project, 'description'))}
            placeholderText="Description not provided"
          >
            <p className="body16">{get(project, 'description')}</p>
          </InlineSection>
        </Grid>
      </Grid>
    </Section>
  );
}

ProjectOverview.propTypes = {
  project: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  inlineSectionClassName: PropTypes.string,
};
export default ProjectOverview;
