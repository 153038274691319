import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import isNull from 'lodash/isNull';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  SearchSort,
  PageLoader,
  HorizontalMenu,
  HEADER_SCROLL_OFFSET,
} from 'modules/ui';
import {
  getTalentsFilterData,
  setTalentsFilterData,
  searchTalents,
  getVisibleTalentsOnAgentsProfile,
  getNotVisibleTalentsOnAgentsProfile,
  TALENT_VISIBILITY_STATUS,
} from 'modules/talents';
import { Colors } from 'modules/theme';
import { useRouteListener, useScrollRestoration } from 'modules/core';
import { SearchForm } from 'modules/sharing';
import { TALENTS_REGEX } from 'modules/producers';
import { useTealiumView } from 'modules/accounts';
import { loadLists, AddTalentDialog } from 'modules/lists';
import {
  getAgent,
  getAgentAssignments,
  loadAgentAssignments,
  updateAgentAssignment,
} from '../redux';
import {
  MyClientsHeader,
  MyClientsList,
  MyClientsCard,
  InviteClientDialog,
} from '../components';
import classes from './MyClientsPage.module.scss';

const styles = {
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 78,
  },
  content: {
    paddingLeft: 55,
    width: '100%',
    minHeight: '100vh',
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 10,
  },
  talentsLengthTitle: {
    fontSize: 16,
    color: Colors.darkGrey,
  },
  sortContainer: {
    display: 'flex',
  },
  visibilityTabs: {
    width: 200,
  },
};

const DEFAULT_ROWS_PER_PAGE = 15;
const DEFAULT_SORT = '-updatedAt';

const SORT_OPTIONS = [
  { label: 'Recently Updated', value: DEFAULT_SORT },
  { label: 'Recently Added', value: '-createdAt' },
  { label: 'Alphabetical', value: 'lastName,firstName' },
];

const handleDebounceSearchTalents = debounce((dispatch, data) => {
  dispatch(searchTalents(data));
}, 500);

export default function MyClientsPage() {
  const dispatch = useDispatch();
  const talentsFilterData = useSelector(getTalentsFilterData);

  const agentAssignments = useSelector(getAgentAssignments);

  const agent = useSelector(getAgent);
  const agentId = get(agent, 'id');
  const visibleClients = useSelector(
    getVisibleTalentsOnAgentsProfile(agentAssignments),
  );

  const notVisibleClients = useSelector(
    getNotVisibleTalentsOnAgentsProfile(agentAssignments),
  );

  const [filterValues, setFilterValues] = useState(
    get(talentsFilterData, 'filterValues') || null,
  );

  const [searchValue, setSearchValue] = useState(
    get(talentsFilterData, 'searchValue') || null,
  );
  const [sort, setSort] = useState(
    get(talentsFilterData, 'sort') || DEFAULT_SORT,
  );

  const [page, setPage] = useState(get(talentsFilterData, 'page') || 0);

  const [rowsPerPage, setRowsPerPage] = useState(
    get(talentsFilterData, 'rowsPerPage') || DEFAULT_ROWS_PER_PAGE,
  );

  const [showInviteTalentModal, setShowInviteTalentModal] = useState(false);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [selectedPreferredGenres, setSelectedPreferredGenres] = useState([]);
  const [scrollY] = useState(get(talentsFilterData, 'scrollY'));
  const [visibilityTabIndex, setVisibilityTabIndex] = useState(0);
  const [addTalentDialogOpen, setAddTalentDialogOpen] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState(null);

  const [pageLoader, setPageLoader] = useState(true);

  const getSearchData = useCallback(() => {
    return {
      sort,
      query: searchValue,
      offset: rowsPerPage * page,
      limit: rowsPerPage,
      agentId,
      ...filterValues,
    };
  }, [sort, searchValue, rowsPerPage, page, filterValues, agentId]);

  useTealiumView('Clients Page');

  useLayoutEffect(() => {
    if (agent && !(visibleClients && notVisibleClients)) {
      handleDebounceSearchTalents(dispatch, getSearchData());
    }
  }, [
    searchValue,
    dispatch,
    agent,
    getSearchData,
    pageLoader,
    visibleClients,
    notVisibleClients,
  ]);

  useLayoutEffect(() => {
    if (agent) {
      dispatch(loadAgentAssignments());
      dispatch(searchTalents(getSearchData())).then(setPageLoader(false));
    }
  }, [
    page,
    filterValues,
    rowsPerPage,
    sort,
    dispatch,
    agent,
    getSearchData,
    pageLoader,
  ]);

  useScrollRestoration(scrollY);

  useRouteListener(
    TALENTS_REGEX,
    talentId =>
      dispatch(
        setTalentsFilterData({
          talentId,
          page,
          sort,
          searchValue,
          agentId,
          scrollY: window.scrollY,
        }),
      ),
    [page, sort, searchValue],
  );

  useEffect(() => {
    dispatch(loadLists(agentId, 'agent'));
  }, [dispatch, agentId]);

  function resetPagination() {
    setPage(0);
  }

  function handleSetFormValue(value) {
    const newValues = { ...filterValues, ...value };
    resetPagination();
    setFilterValues(newValues);
    const verticals = get(newValues, 'verticals');
    const preferredGenres = get(newValues, 'preferredGenres');
    if (verticals || isNull(verticals)) {
      setSelectedVerticals(isNull(verticals) ? [] : verticals);
    }
    if (preferredGenres || isNull(preferredGenres)) {
      setSelectedPreferredGenres(
        isNull([preferredGenres]) ? [] : preferredGenres,
      );
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    window.scrollTo(0, HEADER_SCROLL_OFFSET);
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);
  }

  function handleSearchChange(value) {
    resetPagination();
    setSearchValue(value);
  }

  function handleSortSet(name, value) {
    resetPagination();
    setSort(value);
  }

  function handleClearSearch() {
    resetPagination();
    setSearchValue('');
  }

  function handleReset() {
    resetPagination();
    setFilterValues(null);
  }

  function handleClientsType(index) {
    setVisibilityTabIndex(index);
  }

  function handleAddToList(talent) {
    setSelectedTalent(talent);
    setAddTalentDialogOpen(true);
  }

  function handleAddTalentDialogCancel() {
    setSelectedTalent(null);
    setAddTalentDialogOpen(false);
  }

  function handleVisibilityStatus(talentId) {
    const filteredAgentAssignement = filter(
      agentAssignments,
      assignment => assignment.talentId === talentId,
    );

    const visibleStatus = () => {
      if (filteredAgentAssignement[0].visibleOnAgentProfile) return false;
      return true;
    };

    dispatch(
      updateAgentAssignment(talentId, filteredAgentAssignement[0].id, {
        visibleOnAgentProfile: visibleStatus(),
      }),
    ).then(setPageLoader(true));
  }

  const tabs = [
    {
      label: `Added to my Profile (${visibleClients.length})`,
    },
    {
      label: `Hidden (${notVisibleClients.length})`,
    },
  ];

  const talents = () => {
    if (visibilityTabIndex === TALENT_VISIBILITY_STATUS.VISIBLE) {
      return visibleClients;
    }
    return notVisibleClients;
  };

  const count = talents().length;
  const selectedTalentId = get(selectedTalent, 'id');

  const TalentCards = map(talents(), (talent, index) => {
    const key = `talent-card-${index}-${talent.id}`;
    return (
      <MyClientsCard
        key={key}
        talent={talent}
        selectedVerticals={selectedVerticals}
        selectedPreferredGenres={selectedPreferredGenres}
        talentVisibleStatus={visibilityTabIndex}
        handleClientVisibility={handleVisibilityStatus}
        addToList={handleAddToList}
      />
    );
  });

  if (pageLoader) {
    return <PageLoader />;
  }

  return (
    <Page
      contentStyle={styles.contentContainer}
      HeaderComponent={
        <MyClientsHeader
          onClickInviteClient={() => setShowInviteTalentModal(true)}
          defaultValue={searchValue}
          onChange={handleSearchChange}
          onClear={handleClearSearch}
        />
      }
    >
      <SearchForm
        values={filterValues}
        setFormValue={handleSetFormValue}
        reset={handleReset}
      />
      <div style={styles.content}>
        <div style={styles.contentHeader}>
          <HorizontalMenu
            tabs={tabs}
            onTabChange={handleClientsType}
            containerClassName={classes.visibilityTabs}
            initialTabIndex={visibilityTabIndex}
          />
          <SearchSort
            options={SORT_OPTIONS}
            setFormValue={handleSortSet}
            value={sort}
          />
        </div>
        <MyClientsList
          TalentCards={TalentCards}
          page={page}
          count={count}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      {addTalentDialogOpen && (
        <AddTalentDialog
          talentId={selectedTalentId}
          onCancel={handleAddTalentDialogCancel}
        />
      )}
      {showInviteTalentModal && (
        <InviteClientDialog
          onClose={() => setShowInviteTalentModal(false)}
          onCancel={() => setShowInviteTalentModal(false)}
        />
      )}
    </Page>
  );
}
