import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getProducer, updateProducer } from '../redux';
import { ProducerProfileTabs, SocialLinksForm } from '../components';

export default function EditSocialPage() {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);

  function handleSubmitSocialLinks(values) {
    return dispatch(
      updateProducer(producer.account.id, producer.id, values),
    ).then(() =>
      dispatch(
        showNotification({
          message: 'Social media links successfully saved',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );
  }

  if (!producer) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<ProducerProfileTabs />}>
        <SocialLinksForm
          initialValues={producer}
          onSubmit={handleSubmitSocialLinks}
        />
      </TabLayout>
    </Page>
  );
}
