import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { useSelector, useDispatch } from 'react-redux';
import { getAvatar, loadAvatar } from 'modules/accounts';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getProducer, updateProducer } from '../redux';
import { ProfileDetailsForm, ProducerProfileTabs } from '../components';

export default function EditPersonalPage() {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const avatar = useSelector(getAvatar);

  function handleSubmitPersonalInfo(values) {
    const { firstName, lastName } = values;

    return dispatch(
      updateProducer(producer.account.id, producer.id, {
        ...values,
        ...(firstName && { firstName: trim(firstName) }),
        ...(lastName && { lastName: trim(lastName) }),
      }),
    )
      .then(payload => {
        const profileImage = get(payload, 'data.profileImage');

        dispatch(loadAvatar(profileImage));
        dispatch(
          showNotification({
            message: 'Personal info successfully saved',
            type: Notification.TYPES.SUCCESS,
          }),
        );
        return payload;
      })
      .catch(payload => payload);
  }

  if (!producer) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<ProducerProfileTabs />}>
        <ProfileDetailsForm
          updatedValues={producer}
          initialValues={producer}
          image={avatar}
          onSubmit={handleSubmitPersonalInfo}
        />
      </TabLayout>
    </Page>
  );
}
