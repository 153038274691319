import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { OptionUtil, TextButton, MultiSelect } from 'modules/ui';
import { getListsByAccountId } from 'modules/lists/redux';
import { getProducer } from 'modules/producers';
import SampleSearchForm from './SampleSearchForm';
import VoiceTraitsSearchForm from './VoiceTraitsSearchForm';
import AdditionalInfoSearchForm from './AdditionalInfoSearchForm';
import LocationSearchForm from './LocationSearchForm';
import ExperienceSearchForm from './ExperienceSearchForm';
import StudioCapabilitiesSearchForm from './StudioCapabilitesSearchForm';
import RepresentationSearchForm from './RepresentationSearchForm';
import styles from './SearchForm.module.scss';

export default function SearchForm({
  values,
  setFormValue,
  reset,
  showProducersFilters,
}) {
  const producer = useSelector(getProducer);
  const producerId = get(producer, 'id');

  const lists = useSelector(getListsByAccountId(producerId, 'producer'));

  const [samplesFormOpen, setSamplesFormOpen] = useState(true);
  const [voiceSkillsFormOpen, setVoiceSkillsFormOpen] = useState(true);
  const [additionalInfoFormOpen, setAdditionalInfoFormOpen] = useState(true);
  const [locationFormOpen, setLocationFormOpen] = useState(true);
  const [experienceFormOpen, setExperienceFormOpen] = useState(true);
  const [studioCapabilitiesFormOpen, setStudioCapabilitiesFormOpen] = useState(
    true,
  );
  const [unionsFormOpen, setUnionsFormOpen] = useState(true);
  const [listOptions, setListOptions] = useState(
    OptionUtil.getOptions(lists, OptionUtil.mapOptionFromModel),
  );

  useEffect(() => {
    setListOptions(OptionUtil.getOptions(lists, OptionUtil.mapOptionFromModel));
  }, [lists]);

  function toggleSamplesForm() {
    setSamplesFormOpen(!samplesFormOpen);
  }

  function toggleVoiceSkillsFormOpen() {
    setVoiceSkillsFormOpen(!voiceSkillsFormOpen);
  }

  function toggleAdditionalInfoFormOpen() {
    setAdditionalInfoFormOpen(!additionalInfoFormOpen);
  }

  function toggleLocationFormOpen() {
    setLocationFormOpen(!locationFormOpen);
  }

  function toggleExperienceFormOpen() {
    setExperienceFormOpen(!experienceFormOpen);
  }

  function toggleStudioCapabilitiesFormOpen() {
    setStudioCapabilitiesFormOpen(!studioCapabilitiesFormOpen);
  }

  function toggleUnionsFormOpen() {
    setUnionsFormOpen(!unionsFormOpen);
  }

  function getValue(name) {
    return get(values, name);
  }

  function handleSetFormValue(name, value) {
    if (!isEmpty(value) || value === true) {
      setFormValue({ [name]: value });
      return;
    }

    setFormValue({ [name]: null });
  }

  return (
    <div className={styles.container}>
      <div className={styles.listTitleContainer}>
        <span className={styles.listTitle}>Filters</span>
        <TextButton
          title="Reset"
          onClick={reset}
          className={styles.resetButton}
        />
      </div>
      {showProducersFilters && (
        <div className={styles.listSelect}>
          <MultiSelect
            name="lists"
            value={getValue('lists')}
            placeholder="Filter By List"
            setFormValue={handleSetFormValue}
            containerClassName={styles.select}
            options={listOptions}
          />
        </div>
      )}
      <SampleSearchForm
        toggleSamplesForm={toggleSamplesForm}
        samplesFormOpen={samplesFormOpen}
        handleSetFormValue={handleSetFormValue}
        getValue={getValue}
      />
      <VoiceTraitsSearchForm
        handleSetFormValue={handleSetFormValue}
        getValue={getValue}
        toggleVoiceSkillsFormOpen={toggleVoiceSkillsFormOpen}
        voiceSkillsFormOpen={voiceSkillsFormOpen}
      />
      <AdditionalInfoSearchForm
        handleSetFormValue={handleSetFormValue}
        getValue={getValue}
        toggleAdditionalInfoFormOpen={toggleAdditionalInfoFormOpen}
        additionalInfoFormOpen={additionalInfoFormOpen}
      />

      <LocationSearchForm
        handleSetFormValue={handleSetFormValue}
        getValue={getValue}
        toggleLocationFormOpen={toggleLocationFormOpen}
        locationFormOpen={locationFormOpen}
        setFormValue={setFormValue}
      />

      {showProducersFilters && (
        <ExperienceSearchForm
          handleSetFormValue={handleSetFormValue}
          getValue={getValue}
          toggleExperienceFormOpen={toggleExperienceFormOpen}
          experienceFormOpen={experienceFormOpen}
        />
      )}
      <StudioCapabilitiesSearchForm
        handleSetFormValue={handleSetFormValue}
        getValue={getValue}
        toggleStudioCapabilitiesFormOpen={toggleStudioCapabilitiesFormOpen}
        studioCapabilitiesFormOpen={studioCapabilitiesFormOpen}
      />
      {showProducersFilters && (
        <RepresentationSearchForm
          handleSetFormValue={handleSetFormValue}
          getValue={getValue}
          toggleUnionsFormOpen={toggleUnionsFormOpen}
          unionsFormOpen={unionsFormOpen}
        />
      )}
    </div>
  );
}

SearchForm.propTypes = {
  values: PropTypes.object,
  setFormValue: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  showProducersFilters: PropTypes.func,
};
