import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import pick from 'lodash/pick';
import map from 'lodash/map';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import Grid from '@material-ui/core/Grid/Grid';
import { Validator } from 'modules/core/services';
import { AccountType, getAccountType } from 'modules/accounts';
import { Section, InfoListItem, SocialLinks } from 'modules/ui';
import { DictionaryUtil } from 'modules/dictionary';
import { loadAgents, getAgents } from 'modules/agents';
import { BiographySection } from 'modules/producers';
import { Talent } from '../services';
import { getTalentById } from '../redux';
import {
  AudioSamplesSection,
  PersonalInfoSection,
  VoiceSkillsSection,
} from './index';
import AssignmentListItem from './AssignmentListItem';
import AdditionalInfoSection from './AdditionalInfoSection';
import TalentAgentsList from './TalentAgentsList';
import classes from './ProducerTalentDetails.module.scss';

const styles = {
  sidebar: {
    fontSize: 14,
  },
};

function ProducerTalentDetails({ talentId }) {
  const talent = useSelector(getTalentById(talentId));
  const dispatch = useDispatch();

  const generalKnowledge = get(talent, 'generalKnowledge');
  const biography = get(talent, 'biography');
  const professionalAwards = get(talent, 'professionalAwards');
  const unions = get(talent, 'unions');
  const managerAssignments = get(talent, 'managerAssignments');
  const agentAssignments = get(talent, 'agentAssignments');
  const accountType = useSelector(getAccountType);
  const agents = useSelector(getAgents);

  useEffect(() => {
    if (agentAssignments) {
      dispatch(loadAgents(agentAssignments));
    }
  }, [dispatch, agentAssignments]);

  const personalInfo = {
    location:
      talent && DictionaryUtil.getLocationString(talent.country, talent.state),
    city: get(talent, 'city'),
  };

  const websiteLinks = pick(talent, ['audibleLink', 'websiteLink']);
  const additionalWebsites = pickBy(
    pick(talent, ['customUrl1', 'customUrl2', 'customUrl3', 'customUrl4']),
    value => value !== null,
  );
  const socialLinks = pick(talent, Talent.SOCIAL_MEDIA_LINKS);
  const populatedSocialLinks = pickBy(socialLinks);

  const sectionContentClass = classNames('body16', classes.sectionContent);

  if (!talent) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <AudioSamplesSection talent={talent} />
        <PersonalInfoSection talent={talent} placeholderText="No info." />
        <VoiceSkillsSection talent={talent} placeholderText="No info." />
        <AdditionalInfoSection talent={talent} />
        <BiographySection
          emptyBioNotice="Talent hasn’t provided biography yet."
          shortBio={biography}
        />
        <Section
          isEmpty={!generalKnowledge}
          title="General Knowledge"
          placeholderText="Talent hasn’t provided general knowledge yet."
        >
          <p className={sectionContentClass}>{generalKnowledge}</p>
        </Section>
      </Grid>
      <Grid item xs={0} md={1} />
      <Grid item xs={12} md={3}>
        <Section
          title="Info"
          placeholderText="No info."
          className="mb-60"
          isEmpty={isEmpty(
            omitBy(
              { ...personalInfo, ...websiteLinks, ...populatedSocialLinks },
              isNull,
            ),
          )}
        >
          <InfoListItem
            small
            title={personalInfo.location}
            subtitle={personalInfo.city}
            icon="location"
          />
          {map(websiteLinks, (link, name) => (
            <InfoListItem
              small
              title={link}
              icon={name}
              link={Validator.formatUrl(link)}
            />
          ))}
          <SocialLinks links={populatedSocialLinks} />
        </Section>
        <Section
          title="Additional Links"
          placeholderText="No info."
          style={styles.sidebar}
          isEmpty={isEmpty(additionalWebsites)}
        >
          {map(additionalWebsites, website => {
            return (
              <InfoListItem
                small
                title={website.name}
                icon="websiteLink"
                link={Validator.formatUrl(website.url)}
              />
            );
          })}
        </Section>
        <Section title="Awards" placeholderText="No info." className="mb-60">
          {!isEmpty(professionalAwards) &&
            map(professionalAwards, award => (
              <InfoListItem
                description={award.year}
                title={award.name}
                icon="award"
              />
            ))}
        </Section>
        <Section
          title="Unions"
          placeholderText="No info."
          style={styles.sidebar}
        >
          {join(unions, ', ')}
        </Section>
        {!AccountType.isAgent(accountType) && (
          <Section
            title="Agents & Managers"
            placeholderText="No info."
            style={styles.sidebar}
            isEmpty={isEmpty(managerAssignments) && isEmpty(agentAssignments)}
          >
            {!isEmpty(managerAssignments) && (
              <h3 className={classes.sectionTitle}>Manager</h3>
            )}
            {map(managerAssignments, assignment => (
              <AssignmentListItem
                name={`${assignment.managerFirstName} ${assignment.managerLastName}`}
                company={assignment.companyName}
              />
            ))}
            {!isEmpty(agentAssignments) && (
              <h3 className={classes.sectionTitle}>Agents</h3>
            )}
            <TalentAgentsList
              agentAssignments={agentAssignments}
              agents={agents}
            />
          </Section>
        )}
      </Grid>
    </Grid>
  );
}

ProducerTalentDetails.propTypes = {
  talentId: PropTypes.string,
};

export default ProducerTalentDetails;
