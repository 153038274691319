import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import { useWindowSize } from 'modules/core';
import { AccountType, getAccountType } from 'modules/accounts';
import { Page, ActionBanner, PageLoader } from 'modules/ui';
import { getTalent, Talent } from 'modules/talents';
import { useBackBtnListener } from 'modules/core/services/hooks';
import OfferHeader from '../components/OfferHeader';
import OfferDetailsSection from '../components/OfferDetailsSection';
import ProjectOverview from '../components/ProjectOverview';
import { clearOffersFilterData, getOfferById, loadOffer } from '../redux';
import { Offer } from '../services';
import RecordingDetailsSection from '../components/RecordingDetailsSection';
import DeclineOfferModal from './DeclineOfferModal';
import RespondToOfferModal from './RespondToOfferModal';
import classes from './OfferPage.module.scss';

const styles = {
  container: { width: '90vw', margin: 'auto' },
};

function OfferPage() {
  const dispatch = useDispatch();

  const { offerId } = useParams();
  const offer = useSelector(getOfferById(offerId));
  const projectRole = get(offer, 'projectRole');
  const loggedInTalent = useSelector(getTalent);
  const vertical = get(projectRole, 'project.vertical');
  const hasAgent = Talent.hasAgent(loggedInTalent, vertical);
  const agentAssignment = Talent.getAgentAssignmentByVertical(
    loggedInTalent,
    vertical,
  );
  const agentName = `${get(agentAssignment, 'agentFirstName')} ${get(
    agentAssignment,
    'agentLastName',
  )}`;

  const accountType = useSelector(getAccountType);
  const { desktopWidth } = useWindowSize();
  const [respondModalOpen, setRespondModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);

  useEffect(() => {
    if (!offer) {
      dispatch(loadOffer(offerId, accountType));
    }
  }, [dispatch, offer, offerId, accountType]);

  useBackBtnListener(() => dispatch(clearOffersFilterData()));

  if (!offer) {
    return <PageLoader />;
  }

  const bannerText = Offer.getTalentBannerText(offer, hasAgent, agentName);
  const agentBannerText = Offer.getAgentBannerText(offer);

  function resolveBannerText() {
    if (AccountType.isAgent(accountType)) {
      return agentBannerText;
    }
    if (AccountType.isTalent(accountType)) {
      return bannerText;
    }
    return '';
  }

  return (
    <Page
      HeaderComponent={
        <OfferHeader
          offerId={offerId}
          onRespondOpenClick={() => setRespondModalOpen(true)}
          onDeclineOpenClick={() => setDeclineModalOpen(true)}
          hasAgent={hasAgent}
        />
      }
      BannerComponent={
        resolveBannerText() && (
          <ActionBanner
            text={resolveBannerText()}
            textClassName={classes.bannerText}
          />
        )
      }
    >
      <Grid
        container
        spacing={2}
        style={!desktopWidth ? styles.container : null}
      >
        <Grid item xs={12} md={8}>
          <OfferDetailsSection offer={offer} />
          <ProjectOverview
            role={offer.projectRole}
            project={offer.projectRole.project}
          />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={3}>
          <RecordingDetailsSection offer={offer} />
        </Grid>
      </Grid>
      <RespondToOfferModal
        offerId={offerId}
        onClose={() => setRespondModalOpen(false)}
        producer={get(offer, 'producer')}
        isOpen={respondModalOpen}
      />
      <DeclineOfferModal
        offerId={offerId}
        onClose={() => setDeclineModalOpen(false)}
        producer={get(offer, 'producer')}
        isOpen={declineModalOpen}
      />
    </Page>
  );
}

export default memo(OfferPage);
