import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ChatPopupOptions, closeAllChatWindows } from 'modules/chat';

import { closeAuditionApplicationChatPopup } from '../redux';

function AuditionApplicationChatPopupOptions({ auditionId, talentId }) {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleCloseClick() {
    dispatch(closeAuditionApplicationChatPopup(auditionId, talentId));
  }

  function handleMaximizeClick() {
    handleCloseClick();
    dispatch(closeAllChatWindows());
    history.push(`/messaging/auditions/${auditionId}/${talentId}`);
  }

  return (
    <ChatPopupOptions
      onMaximizeClick={handleMaximizeClick}
      onCloseClick={handleCloseClick}
    />
  );
}

AuditionApplicationChatPopupOptions.propTypes = {
  auditionId: PropTypes.string.isRequired,
  talentId: PropTypes.string.isRequired,
};

export default AuditionApplicationChatPopupOptions;
