import { Api } from 'modules/core';

export function fetchPublishers() {
  const endpoint = `publishers`;

  return Api.find(endpoint);
}

export function postPublisher(fullName) {
  const endpoint = 'publishers';
  const publisher = { resourceType: 'publishers', fullName };

  return Api.create(endpoint, publisher);
}
