import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  EmptyPlaceholder,
  SectionLoader,
  TableWithPagination,
} from 'modules/ui';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';
import { getTalentsFilterData, setTalentsFilterData } from 'modules/talents';
import { TALENTS_REGEX } from 'modules/producers';
import { getProjectById } from 'modules/projects';
import { useRouteListener, useScrollRestoration } from 'modules/core';
import TopCandidatesTableHeader from './TopCandidatesTableHeader';
import TopCandidatesTableRow from './TopCandidatesTableRow';
import styles from './TopCandidatesTable.module.scss';

const SCROLL_OFFSET = 1165;

function TopCandidatesTable({
  topCandidates,
  sort,
  loading,
  onSortClick,
  onRemoveTopCandidateClick,
  onMarkAsHiredClick,
  onAddClick,
  onTalentClick,
  offers,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const talentsFilterData = useSelector(getTalentsFilterData);

  const projectId = get(params, 'projectId');
  const roleId = get(params, 'roleId');
  const project = useSelector(getProjectById(projectId));
  const [talentIsDeleted, setTalentIsDeleted] = useState(false);

  const [page, setPage] = useState(get(talentsFilterData, 'page') || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(talentsFilterData, 'rowsPerPage') || 10,
  );
  const [scrollY] = useState(get(talentsFilterData, 'scrollY'));

  useRouteListener(
    TALENTS_REGEX,
    topCandidateId =>
      dispatch(
        setTalentsFilterData({
          topCandidateId,
          page,
          rowsPerPage,
          scrollY: window.scrollY,
        }),
      ),
    [dispatch, history, page, rowsPerPage],
  );

  useScrollRestoration(scrollY);

  if (loading) {
    return <SectionLoader />;
  }

  function renderHeader() {
    return <TopCandidatesTableHeader sort={sort} onSortClick={onSortClick} />;
  }

  function handlePageChange(newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(newRowsPerPage) {
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  }

  function renderRow(topCandidate) {
    const topCandidateOffer = find(
      offers,
      offer =>
        get(offer, 'projectRoleId') === roleId &&
        get(offer, 'talentId') === topCandidate.talentId,
    );

    // TODO update key in case single top candidate ends up occupying multiple rows
    return (
      <TopCandidatesTableRow
        key={topCandidate.id}
        topCandidate={topCandidate}
        offer={topCandidateOffer}
        onRemoveTopCandidateClick={onRemoveTopCandidateClick}
        onMarkAsHiredClick={() => onMarkAsHiredClick(topCandidate)}
        onTalentClick={() => onTalentClick(topCandidate.talent.id)}
        onRemoveTalent={() => {
          setTalentIsDeleted(true);
        }}
        {...otherProps}
      />
    );
  }

  if (isEmpty(topCandidates)) {
    return null;
  }

  return (
    <TableWithPagination
      count={topCandidates.length}
      rows={topCandidates}
      renderHeader={renderHeader}
      renderRow={renderRow}
      rowsPerPageLabel="Talent Per Page"
      className={styles.card}
      containerClassName={styles.containerClassName}
      pageChangeScrollY={SCROLL_OFFSET}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      defaultPage={page}
      defaultRowsPerPage={rowsPerPage}
      rowIsRemoved={talentIsDeleted}
      resetRowIsRemoved={() => setTalentIsDeleted(false)}
    />
  );
}

TopCandidatesTable.propTypes = {
  topCandidates: PropTypes.array.isRequired,
  sort: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onRemoveTopCandidateClick: PropTypes.func.isRequired,
  onMarkAsHiredClick: PropTypes.func.isRequired,
  onTalentClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  offers: PropTypes.array,
};

export default memo(TopCandidatesTable);
