import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, Button, PageHeaderTitle } from 'modules/ui';

function ProjectsHeader({ handleCreateProject }) {
  return (
    <PageHeader
      LeftComponent={
        <PageHeaderTitle
          title="Projects"
          description="Here is your list of Projects. View the status of your Projects,
    Auditions, and Hires."
        />
      }
    >
      <Button onClick={handleCreateProject} primary>
        New project
      </Button>
    </PageHeader>
  );
}

ProjectsHeader.propTypes = {
  handleCreateProject: PropTypes.func,
};

export default memo(ProjectsHeader);
