import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { showNotification, Notification } from 'modules/notifications';
import { Dialog, ManagedForm, Textarea, Select, OptionUtil } from 'modules/ui';
import { getProducer } from 'modules/producers';
import Dictionary from 'modules/dictionary';
import {
  addTopCandidate,
  getTopCandidatesByRoleId,
  getTopCandidatesError,
  removeErrorAction,
  updateTopCandidate,
} from '../redux';
import { Colors } from '../../theme';

const styles = {
  noteDescription: {
    color: Colors.grayishBlue,
    marginBottom: '20px',
    marginTop: '-30px',
    textAlign: 'justify',
  },
};

export default function SubmissionTopCandidateDialog({
  projectRole,
  submission,
  talent,
  onCancel,
  onClose,
}) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const error = useSelector(getTopCandidatesError);

  const roleId = get(projectRole, 'id');
  const projectId = get(projectRole, 'projectId');
  const clip = get(submission, 'audioClip');
  const clipId = get(clip, 'id');
  const talentId = get(talent, 'id');
  const talentName = get(talent, 'fullName');
  const producerId = get(producer, 'id');

  const initialValues = {
    clipId,
    message: null,
  };

  const topCandidates = useSelector(getTopCandidatesByRoleId(roleId));

  function handleResponse(response) {
    if (!response) {
      return;
    }

    dispatch(
      showNotification({
        message: 'Sample added to top candidates!',
        type: Notification.TYPES.SUCCESS,
      }),
    );
    onClose();
  }

  function handleSubmit(values) {
    const { message } = values;

    const existingTopCandidate = find(topCandidates, { talentId });

    if (existingTopCandidate) {
      return dispatch(
        updateTopCandidate(
          producerId,
          projectId,
          roleId,
          existingTopCandidate.id,
          clipId,
          null,
          message,
          Dictionary.TOP_CANDIDATE_SOURCE.AUDITION,
        ),
      ).then(response => handleResponse(response));
    }

    return dispatch(
      addTopCandidate(
        producerId,
        projectId,
        roleId,
        talentId,
        clipId,
        null,
        message,
        Dictionary.TOP_CANDIDATE_SOURCE.AUDITION,
      ),
    ).then(response => handleResponse(response));
  }

  function handleOnCancel() {
    dispatch(removeErrorAction());

    if (onCancel) {
      onCancel();
    }
  }

  const title = `Add ${talentName}’s sample to Top Candidates`;

  return (
    <Dialog title={title} onClose={onClose}>
      <ManagedForm
        error={error}
        initialValues={initialValues}
        onCancel={handleOnCancel}
        onSubmit={handleSubmit}
        submitButtonLabel="Add"
        forceSubmit
      >
        <Select
          disabled
          name="clipId"
          label="Sample"
          options={[OptionUtil.mapOptionFromModel(clip, 'id', 'title')]}
        />
        <Textarea
          label="Add a sample note"
          name="message"
          placeholder="Add notes about this sample"
        />
        <div style={styles.noteDescription}>
          Notes will be included if you share Top Candidates.
        </div>
      </ManagedForm>
    </Dialog>
  );
}

SubmissionTopCandidateDialog.propTypes = {
  talent: PropTypes.object,
  submission: PropTypes.object,
  projectRole: PropTypes.object,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
