import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import includes from 'lodash/includes';
import get from 'lodash/get';
import map from 'lodash/map';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import {
  DatePicker,
  FormDialog,
  LengthInput,
  ManagedForm,
  OptionGroup,
  OptionUI,
  Select,
  Textarea,
  TextInput,
  InfoText,
  PhoneInput,
} from 'modules/ui';
import { getProjectById } from 'modules/projects';
import { Colors } from 'modules/theme';
import {
  Audition,
  getAuditionById,
  getAuditionsByRoleId,
} from 'modules/auditions';
import Dictionary from 'modules/dictionary';
import Talent from 'modules/talents/services/talent';
import { getTalentById, getTalentLoading } from 'modules/talents';
import { getRoleById } from 'modules/project-roles/redux';
import { getProducer } from 'modules/producers';
import { OfferTargetLabel, ProjectOverview } from '../components';
import { Validator } from '../services';
import SendOfferGrid from '../components/form-grids/SendOfferGrid';
import classes from './SendOfferModal.module.scss';

const styles = {
  heading: {
    fontFamily: 'Inter',
    fontWeight: 600,
    marginTop: 56,
    marginBottom: 35,
  },
  dialogContainer: {
    maxHeight: 'calc(100vh - 148px)',
    overflowY: 'auto',
  },
  title: {
    marginBottom: 0,
  },
  subtitle: {
    marginBottom: 25,
  },
  infoText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.grayishBlue,
  },
  infoHeading: {
    marginBottom: 4,
  },
  noLabel: {
    marginTop: 34,
  },
};
const DEFAULT_RECORDING_LENGTH = {
  type: Dictionary.RECORDING_LENGTH_DURATION_TYPES.STUDIO_HOURS,
};

function onSiteIncluded(values) {
  return includes(values, Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO);
}

function SendOfferModal({ talentId, projectId, roleId, onSubmit, onClose }) {
  const producer = useSelector(getProducer);
  const talent = useSelector(getTalentById(talentId));
  const project = useSelector(getProjectById(projectId));
  const hasAgent = Talent.hasAgent(talent, get(project, 'vertical'));

  const [selectedAuditionId, setSelectedAuditionId] = useState(null);
  const [updatedValues, setUpdatedValues] = useState(
    hasAgent ? { producerEmailContact: get(producer, 'email') } : null,
  );
  const [addressDisabled, setAddressDisabled] = useState(true);
  const [removeErrorsKeys, setRemoveErrorsKeys] = useState([]);

  const role = useSelector(getRoleById(roleId));
  const auditions = useSelector(getAuditionsByRoleId(roleId));
  const selectedAudition = useSelector(getAuditionById(selectedAuditionId));
  const talentLoading = useSelector(getTalentLoading);

  const auditionOptions = Audition.createAuditionOptions(auditions);
  const recordingLocationOptions = map(
    Dictionary.RECORDING_LOCATION_OPTIONS,
    option => <OptionUI key={`recording-${option.value}`} {...option} />,
  );

  const loading = !talent && talentLoading;

  useEffect(() => {
    if (selectedAudition) {
      const recordingDetails = Audition.extractRecordingDetails(
        selectedAudition,
      );

      setUpdatedValues({ ...recordingDetails });

      if (!isEmpty(recordingDetails.studioAddress)) {
        setAddressDisabled(false);
      }
    }
  }, [selectedAudition]);

  function handleAuditionChange(event) {
    setSelectedAuditionId(event.target.value);
  }

  function toggleAddressDisabled(values) {
    if (!onSiteIncluded(values)) {
      setAddressDisabled(true);
      return;
    }

    setAddressDisabled(false);
  }

  function handleChangeValues(formValues) {
    const studioAddress = get(formValues, 'studioAddress');
    const recordingLocations = get(formValues, 'recordingLocations');
    const recordingLength = get(formValues, 'recordingLength');
    const recordingStartDate = get(formValues, 'recordingStartDate');
    const recordingEndDate = get(formValues, 'recordingEndDate');

    if (
      !includes(
        recordingLocations,
        Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO,
      )
    ) {
      if (!isEmpty(studioAddress)) {
        setUpdatedValues({
          ...updatedValues,
          studioAddress: '',
          recordingLocations: without(
            recordingLocations,
            Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO,
          ),
          recordingLength: { ...recordingLength },
          recordingStartDate,
          recordingEndDate,
        });
      } else {
        setRemoveErrorsKeys(['studioAddress']);
      }
    }
  }

  return (
    <FormDialog
      title="Send an offer to"
      dialogMessage="You offer will not be sent."
      onClose={onClose}
      loading={loading}
      containerStyle={styles.dialogContainer}
      titleStyle={styles.title}
    >
      <OfferTargetLabel
        hasAgent={hasAgent}
        actorName={get(talent, 'fullName')}
        agentName={`${get(
          Talent.getAgentAssignmentByVertical(talent, get(project, 'vertical')),
          'agentFirstName',
        )} ${get(
          Talent.getAgentAssignmentByVertical(talent, get(project, 'vertical')),
          'agentLastName',
        )}`}
        sectionClassname={classes.projectOverviewItem}
      />
      <ManagedForm
        onChangeValues={handleChangeValues}
        updatedValues={updatedValues}
        validate={formValues =>
          Validator.validateSendOffer(formValues, hasAgent)
        }
        onSubmit={onSubmit}
        removeErrorsKeys={removeErrorsKeys}
        TemplateComponent={SendOfferGrid}
        submitButtonLabel="Send an offer"
        footerClassName={classes.formFooter}
      >
        <ProjectOverview
          project={project}
          role={role}
          sectionClassName={classes.projectOverview}
          inlineSectionClassName={classes.projectOverviewItem}
        />
        <h3 style={styles.heading}>Recording Details</h3>
        <Select
          name="auditionId"
          label="Audition"
          placeholder="Choose an audition"
          options={auditionOptions}
          onChange={handleAuditionChange}
        />
        <DatePicker
          name="recordingStartDate"
          label="Recording window"
          placeholder="Select start date"
          required
          setAsDayStart
        />
        <DatePicker
          name="recordingEndDate"
          placeholder="Select end date"
          style={styles.noLabel}
          arrowClassName={classes.noLabel}
          required
        />
        <LengthInput
          name="recordingLength"
          label="Recording duration"
          defaultValue={DEFAULT_RECORDING_LENGTH}
          options={Dictionary.RECORDING_LENGTH_DURATION_TYPE_OPTIONS}
          required
        />
        <OptionGroup
          name="recordingLocations"
          label="Recording location"
          onChange={toggleAddressDisabled}
          required
        >
          {recordingLocationOptions}
        </OptionGroup>
        <TextInput
          name="studioAddress"
          label="Studio location"
          placeholder="Enter studio address"
          disabled={addressDisabled}
          required={!addressDisabled}
        />
        <h3 style={styles.heading}>Offer Details</h3>
        <Textarea
          label="Message"
          name="offerDetails"
          maxChars={255}
          placeholder="Write a message about your offer"
          className={styles.textarea}
          required
        />
        <TextInput
          name="offerAmount"
          label="Offer"
          placeholder="How much will the talent be compensated?"
          required
        />
        <DatePicker
          name="dueDate"
          label="Response date"
          placeholder="Response due back by"
          required
        />
        {hasAgent && (
          <>
            <h3 style={{ ...styles.heading, ...styles.infoHeading }}>
              Contact information
            </h3>
            <InfoText
              text="This Talent works only through agent. "
              detailsText="Please add your contact information so agent can reach out to you."
            />
          </>
        )}
        {hasAgent && (
          <TextInput
            label="Email"
            name="producerEmailContact"
            id="producerEmailContact"
            placeholder="Add your email here"
            required
          />
        )}
        {hasAgent && (
          <PhoneInput
            autoFocus
            label="Contact number"
            name="producerPhoneContact"
            placeholder="Add your phone number here"
          />
        )}
      </ManagedForm>
    </FormDialog>
  );
}

SendOfferModal.propTypes = {
  talentId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default memo(SendOfferModal);
