import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LineDivider, TextButton, Button } from 'modules/ui';

import MessagingMenuHeader from './MessagingMenuHeader';
import MessagingCards from './MessagingCards';
import classes from './MessagingMenu.module.scss';

function MessagingMenu({
  className,
  isLoading,
  cardsPlaceholder,
  onSeeAllClick,
  onClose,
  children,
  isResponsiveDrawer,
  loadMoreDisabled,
  onLoadMoreClick,
  isLoadingMore,
  displaySeeAll,
  isFilterByUnreadActive,
  ...otherProps
}) {
  function handleSeeAllClick(event) {
    onClose(event);
    onSeeAllClick();
  }

  const shouldShowHeaderButtons =
    (isResponsiveDrawer || !displaySeeAll) &&
    (children.length > 0 || isFilterByUnreadActive);

  const LoadMoreButton = (
    <>
      {!loadMoreDisabled && isResponsiveDrawer && (
        <Button
          secondary
          className={classes.loadMoreButton}
          onClick={onLoadMoreClick}
          loading={isLoadingMore}
        >
          Load More
        </Button>
      )}
    </>
  );

  return (
    <div className={classNames(classes.messagingMenu, className)}>
      <MessagingMenuHeader
        shouldShowHeaderButtons={shouldShowHeaderButtons}
        isFilterByUnreadActive={isFilterByUnreadActive}
        {...otherProps}
      />
      <MessagingCards
        loading={isLoading}
        placeholder={cardsPlaceholder}
        loadMoreButton={LoadMoreButton}
      >
        {children}
      </MessagingCards>
      {!isResponsiveDrawer && displaySeeAll && (
        <>
          <LineDivider />
          <TextButton
            title="See all"
            className={classes.seeAllButton}
            onClick={handleSeeAllClick}
          />
        </>
      )}
    </div>
  );
}

MessagingMenu.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSeeAllClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  cardsPlaceholder: PropTypes.node,
  children: PropTypes.arrayOf(PropTypes.node),
  isResponsiveDrawer: PropTypes.bool,
  loadMoreDisabled: PropTypes.bool.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  displaySeeAll: PropTypes.bool,
  isFilterByUnreadActive: PropTypes.bool,
};

export default MessagingMenu;
