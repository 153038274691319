import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'modules/ui';
import { AudioPreview } from 'modules/audio/components';
import { Colors } from 'modules/theme';
import classes from './AudioOptionUI.module.scss';

const titleStyle = {
  fontWeight: 'normal',
  fontFamily: 'Inter',
  fontSize: '14px',
  color: Colors.darkBlue,
  lineHeight: '18px',
};

function AudioOptionUI({ selected, label, name, onClick, detailsPaths }) {
  const inputId = `checkbox-${name}-${label.id}`;

  const optionClasses = classNames(classes.option, {
    [classes['option--selected']]: selected,
  });

  return (
    <label className={classes.container} htmlFor={inputId} onClick={onClick}>
      <AudioPreview
        details={label}
        detailsPaths={detailsPaths}
        detailsClassName={classes['option-details']}
        truncateLength={false}
        separator="dot"
        titleStyle={titleStyle}
      />
      <div>
        <div className={optionClasses}>
          {selected && <Icon name="checked" color="#fff" size={24} />}
        </div>
      </div>
    </label>
  );
}

AudioOptionUI.propTypes = {
  selected: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.object,
  onClick: PropTypes.func,
  detailsPaths: PropTypes.array,
};

export default memo(AudioOptionUI);
