import { Api } from 'modules/core';

const MANAGER_ASSIGNMENT_TYPE = 'talent-manager-assignments';

function getManagerAssignmentsURL() {
  return MANAGER_ASSIGNMENT_TYPE;
}
export function fetchManagerAssignments() {
  const endpoint = `${getManagerAssignmentsURL()}/all`;

  return Api.find(endpoint);
}

export function postManagerAssignment(talentId, values) {
  const endpoint = getManagerAssignmentsURL();
  const assignment = {
    resourceType: MANAGER_ASSIGNMENT_TYPE,
    ...values,
  };
  const relationships = {
    ...(talentId && {
      talent: {
        data: {
          type: 'talents',
          id: talentId,
        },
      },
    }),
  };

  return Api.create(endpoint, assignment, relationships);
}

export function patchManagerAssignment(talentId, assignmentId, values) {
  const endpoint = `${getManagerAssignmentsURL()}/${assignmentId}`;

  const relationships = {
    ...(talentId && {
      talent: {
        data: {
          type: 'talents',
          id: talentId,
        },
      },
    }),
  };

  return Api.update(endpoint, values, MANAGER_ASSIGNMENT_TYPE, relationships);
}

export function removeManagerAssignment(assignmentId) {
  const endpoint = `${getManagerAssignmentsURL()}/${assignmentId}`;

  return Api.deleteResource(endpoint);
}
