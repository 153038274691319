import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { Page, ActionBanner, PageLoader } from 'modules/ui';
import { loadAsset } from 'modules/assets';
import { ProjectOverview, getCoverImage, getLoadingCovers, loadCover } from 'modules/projects';
import { getTalent, Talent } from 'modules/talents';
import {
  getApplicationsLoading,
  loadAllTalentApplications,
} from 'modules/audition-applications';
import { InviteTalentFromRoleDialog as ClientInviteDialog } from 'modules/auditions-invitations';
import {
  loadAudition,
  getAuditionById,
  DetailsSection,
  VoiceSkillsSection,
  AnalyticsSection,
  AdditionalDetailsSection,
  RecordingSection,
  getAppliedAuditionsByTalentId,
  clearAuditionsFilterData,
} from 'modules/auditions';
import { useBackBtnListener, useWindowSize } from 'modules/core';
import { useTealiumView, ACCOUNT_TYPES } from 'modules/accounts';
import {
  getSavedAuditions,
  getLoadingSavedAuditions,
  loadSavedAuditions,
  saveAudition,
  unsaveAudition,
  AuditionApplyModal,
} from 'modules/auditions-talents';
import { openGlobalMessagingChatPopup } from 'modules/global-messages-threads';
import { showNotification, Notification } from 'modules/notifications';
import { loadProducer } from 'modules/producers';

import { AuditionHeader } from '../components';

export const AUDITION_REGEX = RegExp('/auditions/(\\d+)');

const styles = {
  container: {
    margin: 16,
  },
};

function isAuditionIn(auditionId, auditions) {
  return !!find(auditions, { id: auditionId });
}

export default function AuditionPage({ history, match }) {
  const dispatch = useDispatch();
  const auditionId = get(match, 'params.auditionId');
  const talent = useSelector(getTalent);
  const talentId = get(talent, 'id');

  const { mobileWidth, desktopWidth } = useWindowSize();

  const audition = useSelector(getAuditionById(auditionId));
  const appliedAuditions = useSelector(getAppliedAuditionsByTalentId(talentId));
  const applyLoading = useSelector(getApplicationsLoading);
  const savedAuditions = useSelector(getSavedAuditions);
  const saveLoading = useSelector(getLoadingSavedAuditions);

  const [loading, setLoading] = useState(true);
  const [producerAvatar, setProducerAvatar] = useState(null);
  const [applyModalOpen, setApplyModalOpen] = useState(false);
  const [canApply, setCanApply] = useState(true);
  const [clientInviteOpen, setClientInviteOpen] = useState(false);

  const project = get(audition, 'projectRole.project');
  const Placeholder = <p className="body14 grayishBlueColor">Not defined</p>;

  const saved = isAuditionIn(auditionId, savedAuditions);
  const applied = isAuditionIn(auditionId, appliedAuditions);

  const loadingCovers = useSelector(getLoadingCovers);
  const coverImage = get(project, 'coverImage')
  const isCoverLoading = !!find(loadingCovers, cover => {
    return cover === coverImage;
  });

  const coverImageSrc = useSelector(getCoverImage(coverImage));

  const producerId = get(audition, "producer.id")

  useTealiumView('Audition Details Page');

  useEffect(() => {
    if (talent) {
      setCanApply(Talent.isProfileVisible(talent));
    }
  }, [talent]);

  useEffect(() => {
    setLoading(true);
    dispatch(loadAudition(auditionId)).then(() => setLoading(false));
  }, [dispatch, auditionId]);

  useEffect(() => {
    dispatch(loadAllTalentApplications(talentId));
  }, [dispatch, talentId]);

  useEffect(() => {
    dispatch(loadSavedAuditions(talentId, auditionId));
  }, [dispatch, talentId, auditionId]);

  useEffect(() => {
    const profileImage = get(audition, 'producer.profileImage');

    dispatch(loadAsset(profileImage, true)).then(asset =>
      setProducerAvatar(asset),
    );

  }, [dispatch, audition]);

  useEffect(() => {
    dispatch(loadProducer(producerId));
  }, [dispatch, producerId])

  useEffect(() => {
    dispatch(loadCover(coverImage))
  }, [dispatch, coverImage]);

  useBackBtnListener(() => dispatch(clearAuditionsFilterData()));

  function handleApply() {
    setApplyModalOpen(true);
  }

  function hideApplyModal() {
    setApplyModalOpen(false);
  }

  function handleClientInvite() {
    setClientInviteOpen(true);
  }

  function handleToggleSave() {
    if (saved) {
      return dispatch(unsaveAudition(talentId, auditionId));
    }

    global.utag.link({
      event_type: 'save_audition',
      page_type: 'Audition Detail Page',
      module_type: get(audition, 'name'),
    });

    return dispatch(saveAudition(talentId, auditionId)).then(() =>
      dispatch(
        showNotification({
          message: 'Audition saved',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );
  }

  function handleEditButtonClick() {
    history.push('/profile/edit');
  }

  function handleSendMessage() {
    const producerAccountId = get(audition, 'producer.accountId');
    dispatch(
      openGlobalMessagingChatPopup(producerAccountId, ACCOUNT_TYPES.PRODUCER),
    );
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Page
      HeaderComponent={
        <AuditionHeader
          avatar={producerAvatar}
          applied={applied}
          canApply={canApply}
          saved={saved}
          applyLoading={applyLoading}
          saveLoading={saveLoading}
          audition={audition}
          onApply={handleApply}
          onToggleSave={handleToggleSave}
          onOpenClientInvite={handleClientInvite}
          onSendMessage={handleSendMessage}
          isCoverLoading={isCoverLoading}
          coverImageSrc={coverImageSrc}
        />
      }
      BannerComponent={
        !canApply && (
          <ActionBanner
            text={
              <p>
                <strong>Alert!</strong> Your profile isn’t yet visible on Ahab.
                Complete the required fields to audition.
              </p>
            }
            onButtonClick={desktopWidth && handleEditButtonClick}
            buttonLabel={desktopWidth && 'Edit profile'}
          />
        )
      }
    >
      <Grid
        container
        spacing={2}
        style={!desktopWidth ? styles.container : null}
      >
        <Grid item xs={12} md={8}>
          <DetailsSection audition={audition} Placeholder={Placeholder} />
          {!desktopWidth && <AnalyticsSection audition={audition} />}
          <VoiceSkillsSection audition={audition} Placeholder={Placeholder} />
          <ProjectOverview
            project={project}
            sectionTitle="Project Details"
            mobileWidth={mobileWidth}
          />
          <AdditionalDetailsSection audition={audition} />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={3}>
          {desktopWidth && <AnalyticsSection audition={audition} />}
          <RecordingSection
            audition={audition}
            Placeholder={Placeholder}
            desktopWidth={desktopWidth}
          />
        </Grid>
      </Grid>
      <AuditionApplyModal
        audition={audition}
        history={history}
        open={applyModalOpen}
        onClose={hideApplyModal}
      />
      {clientInviteOpen && (
        <ClientInviteDialog
          audition={audition}
          onClose={() => setClientInviteOpen(false)}
          onCancel={() => setClientInviteOpen(false)}
        />
      )}
    </Page>
  );
}

AuditionPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};
