import React from 'react';
import PropTypes from 'prop-types';

import MessagePreview from './MessagePreview';
import MessageHeading from './MessageHeading';

function ChatHeader({ className, options, ...otherProps }) {
  return (
    <MessagePreview className={className}>
      <MessageHeading {...otherProps} />
      {options}
    </MessagePreview>
  );
}

ChatHeader.propTypes = {
  className: PropTypes.string,
  options: PropTypes.node,
  ...MessageHeading.propTypes,
};

export default ChatHeader;
