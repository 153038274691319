import React from 'react';
import PropTypes from 'prop-types';
import { Collapsible, IconButton, MultiSelect } from 'modules/ui';
import { Colors } from 'modules/theme';
import Dictionary from 'modules/dictionary';
import styles from './SearchForm.module.scss';

export default function AdditionalInfoSearchForm({
  handleSetFormValue,
  getValue,
  toggleAdditionalInfoFormOpen,
  additionalInfoFormOpen,
}) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Additional info</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleAdditionalInfoFormOpen}
        />
      </div>
      <Collapsible hideButton collapsed={!additionalInfoFormOpen}>
        <MultiSelect
          name="genders"
          value={getValue('genders')}
          placeholder="Gender"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          options={Dictionary.GENDER_OPTIONS}
        />
        <MultiSelect
          name="ethnicities"
          value={getValue('ethnicities')}
          placeholder="Cultural Background"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.ETHNICITIES}
          options={Dictionary.ETHNICITY_OPTIONS}
        />
      </Collapsible>
    </div>
  );
}

AdditionalInfoSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleAdditionalInfoFormOpen: PropTypes.func.isRequired,
  additionalInfoFormOpen: PropTypes.func.isRequired,
};
