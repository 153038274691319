import React from 'react';
import PropTypes from 'prop-types';

import MessageIndicator from './MessageIndicator';
import classes from './MessagingHorizontalMenuLabel.module.scss';

function MessagingHorizontalMenuLabel({ label, unreadMessages }) {
  const hasUnreadMessages = unreadMessages > 0;
  return (
    <div className={classes.label}>
      {label}
      {hasUnreadMessages && (
        <MessageIndicator
          className={classes.indicator}
          unreadMessages={unreadMessages}
        />
      )}
    </div>
  );
}

MessagingHorizontalMenuLabel.propTypes = {
  label: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number.isRequired,
};

export default MessagingHorizontalMenuLabel;
