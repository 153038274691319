import React, { memo, Children } from 'react';
import PropTypes from 'prop-types';
import styles from './ProfileMenu.module.scss';

function ProfileMenu({ show, children }) {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.dropdown}>
      <ul className={styles.ul}>
        {Children.map(children, child => (
          <li>{child}</li>
        ))}
      </ul>
    </div>
  );
}

ProfileMenu.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
};

export default memo(ProfileMenu);
