import React, { useState, memo, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Input, Button } from 'modules/ui';
import styles from './SwitchInput.module.scss';

function SwitchInput({
  value,
  items,
  label,
  name,
  disabled,
  error,
  onChange,
  withConfirm,
  setFormValue,
}) {
  const [selected, setSelected] = useState(value || items[0].value);
  const cellWidth = 12 / _.size(items);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleClick = event => {
    const itemValue = event.target.value;

    if (selected === itemValue) {
      return;
    }

    if (withConfirm && onChange) {
      onChange(itemValue);
      return;
    }

    setSelected(itemValue);

    if (onChange) {
      onChange(itemValue);
    }

    if (setFormValue) {
      setFormValue(name, itemValue);
    }
  };

  const renderItem = (item, index) => {
    const key = `${item.name}_${index}`;
    const isSelected = selected === item.value;

    return (
      <Grid item xs={cellWidth} key={key}>
        <Button
          className={styles.btn}
          disabled={disabled}
          secondary={!isSelected}
          primary={isSelected}
          value={item.value}
          onClick={handleClick}
        >
          {item.label}
        </Button>
      </Grid>
    );
  };

  return (
    <Input label={label} error={error}>
      <Grid container spacing={2}>
        {_.map(items, renderItem)}
      </Grid>
    </Input>
  );
}

SwitchInput.propTypes = {
  value: PropTypes.string,
  setFormValue: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  withConfirm: PropTypes.bool,
};

export default memo(SwitchInput);
