import React, { useState } from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import omitBy from 'lodash/omitBy';
import includes from 'lodash/includes';
import { useDispatch, useSelector } from 'react-redux';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { removePastRecording, getDeleteError } from 'modules/past-recordings';
import { showNotification, Notification } from 'modules/notifications';
import {
  getTalent,
  updateTalentProfessionalInfo,
  updateTalent,
} from '../redux';
import { ProfessionalInfoForm, TalentProfileTabs } from '../components';
import Validator from '../validator';

const DEFAULT_ADDITIONAL_WEBSITES = {
  customUrl1: null,
  customUrl2: null,
  customUrl3: null,
  customUrl4: null,
};

export default function EditProfessionalPage() {
  const dispatch = useDispatch();
  const talent = useSelector(getTalent);
  const removeError = useSelector(getDeleteError);

  const [removedPastRecordingFields, setRemovedPastRecordingFields] = useState(
    [],
  );

  function handleSubmitProfessionalInfo(values) {
    const removeCalls = map(removedPastRecordingFields, recording =>
      dispatch(removePastRecording(talent.id, recording)),
    );
    const oldPastRecordingIds = map(
      talent.pastRecordings,
      recording => recording.id,
    );

    const resolvedPastRecordings = omitBy(values.pastRecordings, recording =>
      includes(oldPastRecordingIds, recording.id),
    );

    const { additionalWebsites, ...restValues } = values;

    const formattedWebsites = reduce(
      additionalWebsites,
      (accumulator, website, index) => {
        return Object.assign(accumulator, {
          [`customUrl${index + 1}`]: website,
        });
      },
      { ...DEFAULT_ADDITIONAL_WEBSITES },
    );

    const updateTalentCall = dispatch(
      updateTalentProfessionalInfo(talent.id, {
        ...restValues,
        ...formattedWebsites,
        pastRecordings: resolvedPastRecordings,
      }),
    );

    return Promise.all([...removeCalls, updateTalentCall]).then(() => {
      if (removeError) {
        return null;
      }

      global.utag.link({
        event_type: 'edit_profile',
        module_type: 'Edit profile',
        module_variation: 'Professional Info',
      });

      return dispatch(
        showNotification({
          message: 'Professional info successfully saved',
          type: Notification.TYPES.SUCCESS,
        }),
      );
    });
  }

  function handleSubmitUnionGdprConsent() {
    return dispatch(
      updateTalent(talent.account.id, talent.id, {
        unionGdprAcceptedAt: true,
      }),
    );
  }

  function handleCloseUnionGdprConsent() {
    return dispatch(
      updateTalent(talent.account.id, talent.id, {
        unions: null,
      }),
    );
  }

  function handleRemovePastRecordingField(value) {
    const pastRecordings = get(talent, 'pastRecordingsIds');

    if (includes(pastRecordings, value.id)) {
      setRemovedPastRecordingFields([...removedPastRecordingFields, value.id]);
    }
  }

  if (!talent) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<TalentProfileTabs />}>
        <ProfessionalInfoForm
          talent={talent}
          validate={Validator.validateProfessionalInfo}
          onSubmit={handleSubmitProfessionalInfo}
          onRemovePastRecordingField={handleRemovePastRecordingField}
          onSubmitUnionGdprConsent={handleSubmitUnionGdprConsent}
          onCloseUnionGdprConsent={handleCloseUnionGdprConsent}
        />
      </TabLayout>
    </Page>
  );
}
