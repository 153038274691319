import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableCellSortable } from 'modules/ui';
import { TableRow } from '@material-ui/core';
import classes from './AuditionsTableHeader.module.scss';

function AuditionsTableHeader({ sort, onSortClick }) {
  return (
    <TableRow>
      <TableCellSortable
        align="left"
        name="talentName"
        sort={sort}
        onClick={onSortClick}
      >
        Talent Name
      </TableCellSortable>
      <TableCell align="left" className={classes.mediumCell}>
        Activity
      </TableCell>
      <TableCellSortable
        align="left"
        name="createdAt"
        sort={sort}
        onClick={onSortClick}
        className={classes.largeCell}
      >
        Submission Date
      </TableCellSortable>
      <TableCell />
    </TableRow>
  );
}

AuditionsTableHeader.propTypes = {
  sort: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
};

export default memo(AuditionsTableHeader);
