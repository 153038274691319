import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';
import { TableCellSortable } from 'modules/ui';
import styles from './TopCandidatesSharingTable.module.scss';

function TopCandidatesSharingTableHeader({ sort, onSortClick }) {
  return (
    <TableRow>
      <TableCellSortable
        align="left"
        name="talent"
        sort={sort}
        onClick={onSortClick}
      >
        Talent
      </TableCellSortable>
      <TableCell
        align="left"
        className={classNames(styles.header, styles.largeCell)}
      >
        Note
      </TableCell>
      <TableCell
        align="left"
        className={classNames(styles.header, styles.largeCell)}
      >
        Sample
      </TableCell>
    </TableRow>
  );
}

TopCandidatesSharingTableHeader.propTypes = {
  sort: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
};
export default memo(TopCandidatesSharingTableHeader);
