import createSelector from 'selectorator';

export const getAuditionLoading = createSelector([
  'auditionsProducers.loading',
]);
export const getAuditionsLoading = createSelector([
  'auditionsProducers.auditionsLoading',
]);
export const getAuditionDeleting = createSelector([
  'auditionsProducers.deleting',
]);
export const getAuditionError = createSelector(['auditionsProducers.error']);
