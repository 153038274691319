import { preloadAccount, createAccount } from 'modules/accounts/redux';
import { signUpToNewsletter } from 'modules/newsletter';
import get from 'lodash/get';
import { Account } from 'modules/accounts';
import { uploadAssetActions } from 'modules/assets';
import { Api } from 'modules/core';
import { resetTemporaryPassword } from 'modules/accounts/api';
import { Cognito } from './services';

export const USER_LOADED = '@@auth/user';
export const PASSWORD_REQUIRED = '@@auth/password';
export const PASSWORD_NOT_REQUIRED = '@@auth/passwordNotRequired';
export const LOGIN_SUCCESS = '@@auth/login/success';
export const LOGIN_ERROR = '@@auth/login/error';

export function isAuthenticated() {
  return Cognito.isAuthenticated();
}

export function isAuthenticatedWith(cognitoUser) {
  return Cognito.isAuthenticatedWith(cognitoUser);
}

function createNewUser() {
  return async dispatch => {
    const {
      firstName,
      lastName,
      accountType,
      newsletterSignup,
    } = await Cognito.getUserAttributes();

    await dispatch(createAccount(firstName, lastName, accountType));
    const account = await dispatch(preloadAccount());

    // If the user creation fails with Cognito, don't sign the user to the newsletter
    // If the newsletter signUp fails -- we ignore the error, not important for now
    if (newsletterSignup) {
      await signUpToNewsletter(account);
    }

    return account;
  };
}

export function logout() {
  return Cognito.logout();
}

export function login(user) {
  const { email, password } = user;

  return async dispatch => {
    try {
      // Authentication cookies might already be present
      // (e.g. from another tab). We should log out first
      // to avoid overloading our cookie store (error 431).
      const isAuth = await isAuthenticated();
      if (isAuth) {
        await logout();
      }

      const cognitoUser = await Cognito.login(email, password);

      if (cognitoUser) {
        dispatch({ type: USER_LOADED, payload: cognitoUser });
      }

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return dispatch({ type: PASSWORD_REQUIRED });
      }

      let account = await dispatch(preloadAccount());

      if (!account) {
        account = await dispatch(createNewUser());
      }

      const accountType = Account.getLabel(account);

      global.utag.link({
        login_success: true,
        account_type: accountType,
      });

      return dispatch({ type: LOGIN_SUCCESS, payload: account });
    } catch (error) {
      if (
        get(error, 'code') === Cognito.CODES.NOT_AUTHORIZED &&
        get(error, 'message') === Cognito.ERROR_MESSAGES.MAIL_NOT_VERIFIED
      ) {
        await resetTemporaryPassword(email);
        return dispatch({
          type: LOGIN_ERROR,
          error: {
            ...error,
            message:
              'Your temporary password has expired. We have just sent you an email with a new temporary password. This password will expire after 7 days.',
          },
        });
      }
      return dispatch({ type: LOGIN_ERROR, error });
    }
  };
}

export function signUp(account) {
  return Cognito.signUp(account);
}

export function forgotPassword(email) {
  return Cognito.forgotPassword(email).catch(error => {
    if (
      get(error, 'code') === Cognito.CODES.NOT_AUTHORIZED &&
      get(error, 'message') ===
        Cognito.ERROR_MESSAGES.CANNOT_RESET_PASSWORD_UNTIL_VERIFIED
    ) {
      resetTemporaryPassword(email);
      // eslint-disable-next-line no-throw-literal
      throw {
        ...error,
        message:
          'When logging in for the first time, check your email inbox and use the temporary password we have provided. You are not able to update your password until after you have entered the temporary password.',
      };
    } else {
      throw error;
    }
  });
}

export function requestProducerApproval(producer) {
  const endpoint = 'accounts/requestProducerApproval';
  const body = {
    data: { type: 'request-producer-approval-actions', attributes: producer },
  };
  return Api.createRaw(endpoint, body);
}

export function changePassword(oldPassword, newPassword) {
  return Cognito.changePassword(oldPassword, newPassword);
}

export function resetPassword(email, code, password) {
  return Cognito.resetPassword(email, code, password);
}

export function completeNewPassword(user, password, email) {
  return Cognito.completeNewPassword(user, password, email);
}

export function verifyEmail(email, code) {
  return Cognito.confirmSignUpEmail(email, code);
}

export function getToken() {
  return Cognito.getToken();
}

export function getCurrentAuthenticatedUser() {
  return Cognito.currentAuthenticatedUser();
}

export function resendVerificationEmail(email) {
  return Cognito.resendVerificationEmail(email);
}

export function uploadApplicationAudioClip(file, context) {
  const isProducerApplication = true;
  return uploadAssetActions(
    file,
    'audio',
    'producerApprovalClip',
    context,
    isProducerApplication,
  );
}
