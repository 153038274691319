import React from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import { IconButton } from '../IconButton';
import styles from './PaginationActions.module.scss';

export default function TablePagination({
  count,
  page,
  rowsPerPage,
  onChangePage,
}) {
  const lastPage = Math.ceil(count / rowsPerPage) - 1;

  function handleFirstPageClick(event) {
    onChangePage(event, 0);
  }

  function handlePreviousPageClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextPageClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageClick(event) {
    onChangePage(event, Math.max(0, lastPage));
  }

  const isFirstPage = page === 0;
  const isLastPage = page === lastPage;
  const currentPageLabel = `${page + 1} / ${lastPage + 1}`;

  return (
    <div className={styles.root}>
      <IconButton
        onClick={handleFirstPageClick}
        disabled={page === 0}
        aria-label="First Page"
        iconName="firstPage"
        iconColor={isFirstPage ? Colors.grayishBlue : Colors.darkBlue}
      />
      <IconButton
        onClick={handlePreviousPageClick}
        disabled={page === 0}
        aria-label="Previous Page"
        iconName="previousPage"
        iconColor={isFirstPage ? Colors.grayishBlue : Colors.darkBlue}
      />
      <span className={styles['page-number']}>{currentPageLabel}</span>
      <IconButton
        onClick={handleNextPageClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
        iconName="nextPage"
        iconColor={isLastPage ? Colors.grayishBlue : Colors.darkBlue}
      />
      <IconButton
        onClick={handleLastPageClick}
        disabled={page >= lastPage}
        aria-label="Last Page"
        iconName="lastPage"
        iconColor={isLastPage ? Colors.grayishBlue : Colors.darkBlue}
      />
    </div>
  );
}

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
