import get from 'lodash/get';
import filter from 'lodash/filter';

export function byThread(threadId) {
  return message => get(message, 'generalMessageThreadId') === threadId;
}

export function filterGlobalMessages(messages, messageFilter) {
  return filter(messages, messageFilter);
}
