import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, AvatarHeader, PageLoader, Button } from 'modules/ui';
import { LetterIcon } from 'modules/chat';
import { getAccountType, AccountType, ACCOUNT_TYPES } from 'modules/accounts';
import { openGlobalMessagingChatPopup } from 'modules/global-messages-threads';
import classes from './AgentPreviewHeader.module.scss';

function AgentPreviewHeader({ agent, isAgentLoading }) {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType);

  function handleSendMessage() {
    dispatch(
      openGlobalMessagingChatPopup(agent.accountId, ACCOUNT_TYPES.AGENT),
    );
  }

  if (!agent || isAgentLoading) {
    return <PageLoader />;
  }

  return (
    <PageHeader
      large
      actionClassName="mb-60"
      LeftComponent={<AvatarHeader person={agent} size={152} />}
    >
      {AccountType.isProducer(accountType) && (
        <Button
          gray
          className={classes.sendMessageButton}
          onClick={handleSendMessage}
        >
          <LetterIcon />
          Message
        </Button>
      )}
    </PageHeader>
  );
}

AgentPreviewHeader.propTypes = {
  agent: PropTypes.object,
  isAgentLoading: PropTypes.bool,
};

export default memo(AgentPreviewHeader);
