import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import BreadcrumbItem from './BreadcrumbItem';
import styles from './Breadcrumps.module.scss';

function Breadcrumbs({ history, breadcrumbPaths }) {
  function handlePathClick(path) {
    if (path) {
      history.push(path.route);
    }
  }

  function renderPath(path, index) {
    const last = breadcrumbPaths.length === index + 1;
    const key = `${index}-${path}`;

    return (
      <BreadcrumbItem
        key={key}
        path={path}
        last={last}
        onClick={handlePathClick}
      />
    );
  }

  return (
    <div className={styles.breadcrumps__container}>
      {map(breadcrumbPaths, renderPath)}
    </div>
  );
}

Breadcrumbs.propTypes = {
  history: PropTypes.object,
  breadcrumbPaths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ),
};

export default withRouter(Breadcrumbs);
