import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import is from 'is_js';
import repeat from 'lodash/repeat';
import { Icon, IconButton, TextInput } from 'modules/ui';
import { Colors } from 'modules/theme';
import { Utils } from 'modules/core/services';
import styles from './SearchInput.module.scss';

const FIREFOX_PLACEHOLDER_SEPARATOR = repeat(' ', 7);

export default function SearchInput({
  name,
  placeholder,
  theme,
  inputClassName,
  containerClassName,
  inputContainerClassName,
  onChange,
  value,
  setFormValue,
  disabled,
  LeftIconComponent,
  RightIconComponent,
  onClear,
  ...otherProps
}) {
  const [isFocused, setIsFocused] = useState(false);

  function handleClearClick(event) {
    if (event) {
      event.preventDefault();
    }

    if (onChange) {
      onChange(event, null);
    }

    if (setFormValue) {
      setFormValue(name, null);
    }

    if (onClear) {
      onClear();
    }
  }

  function removeFocus() {
    setIsFocused(false);

    if (!value) {
      handleClearClick();
    }
  }

  const inputClasses = classNames(
    styles.input,
    styles[`${theme}__input`],
    value && styles[`${theme}__input--hasValue`],
    inputClassName,
  );

  const containerClasses = classNames(
    styles.inputContainer,
    containerClassName,
  );

  const inputContainerClasses = classNames(
    styles.flowContainer,
    inputContainerClassName,
  );

  const searchIconClasses = classNames(styles.icon, styles['icon--left'], {
    [styles['icon--hidden']]: value || isFocused,
    [styles['icon--disabled']]: disabled,
  });

  const clearIconClasses = classNames(styles.icon, styles['icon--right'], {
    [styles['icon--hidden']]: !value,
  });

  const SearchIcon = (
    <Icon
      name="search"
      color={Colors.grayishBlue}
      className={searchIconClasses}
    />
  );

  const clearIconColor =
    theme === 'dark' ? Colors.darkGray : Colors.grayishBlue;
  const clearButtonDisabled = !isFocused && !value;
  const ClearSearchIconBtn = (
    <IconButton
      iconName="clearSearch"
      iconColor={clearIconColor}
      className={clearIconClasses}
      onMouseDown={handleClearClick}
      disabled={clearButtonDisabled}
    />
  );

  const RightIcon = RightIconComponent || ClearSearchIconBtn;
  const LeftIcon = LeftIconComponent || SearchIcon;

  return (
    <TextInput
      name={name}
      className={inputClasses}
      containerClassName={containerClasses}
      value={value}
      inputContainerClassName={inputContainerClasses}
      LeftIconComponent={LeftIcon}
      RightIconComponent={RightIcon}
      // applying padding/indent to placeholder with css pseudoclass does not seem to work on firefox
      placeholder={
        !is.firefox()
          ? placeholder
          : `${FIREFOX_PLACEHOLDER_SEPARATOR}${placeholder}`
      }
      onChange={onChange}
      onFocus={() => setIsFocused(true)}
      onBlur={removeFocus}
      disabled={disabled}
      setFormValue={setFormValue}
      autoComplete={Utils.PREVENT_AUTOCOMPLETE_IN_MODAL}
      {...otherProps}
    />
  );
}

SearchInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  RightIconComponent: PropTypes.node,
  LeftIconComponent: PropTypes.node,
  disabled: PropTypes.bool,
  theme: PropTypes.string,
  setFormValue: PropTypes.func,
  onClear: PropTypes.func,
};
