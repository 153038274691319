import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import size from 'lodash/size';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import {
  Page,
  Section,
  PageLoader,
  Sort,
  NavBar,
  InfoBanner,
} from 'modules/ui';
import {
  getTopCandidates,
  loadSharedTopCandidates,
} from 'modules/top-candidates';
import {
  loadSharedProjectRole,
  getSharedRole,
} from 'modules/project-roles/redux';
import { getAsset } from 'modules/assets';
import { getProducer, loadSharedProducer } from 'modules/producers/redux';
import TokenExpiredPage from './TokenExpiredPage';
import TopCandidatesSharingTable from '../components/TopCandidatesSharingTable';
import TopCandidatesHeader from '../components/TopCandidatesHeader';
import { getAuthCodeValidity, validateAuthCode } from '../redux';

const styles = {
  rolesContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 50,
    width: '100%',
  },
  actionButton: {
    height: 32,
    width: 131,
    minWidth: 131,
  },
  section: {
    marginTop: '30px',
  },
  logo: {
    display: 'block',
    position: 'absolute',
    left: '20px',
    top: '15px',
  },
  banner: {
    padding: '1% 0',
    height: 'unset',
    display: 'flex',
    justifyContent: 'center',
  },
  bannerText: {
    lineHeight: '20px',
    maxWidth: '850px',
  },
};

export default function TopCandidatesSharingPage() {
  const dispatch = useDispatch();
  const params = useParams();

  const token = get(params, 'token');
  const tokenValid = useSelector(getAuthCodeValidity);
  const topCandidates = useSelector(getTopCandidates);
  const role = useSelector(getSharedRole);
  const producer = useSelector(getProducer);
  const profileImage = get(producer, 'profileImage');

  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  const orderedTopCandidates = sort
    ? orderBy(
        topCandidates,
        [candidate => candidate.talent.fullName.toLowerCase()],
        Sort.toObject(sort).order,
      )
    : topCandidates;

  useEffect(() => {
    dispatch(validateAuthCode(token)).then(() => setLoading(false));
  }, [dispatch, token]);

  useEffect(() => {
    setLoading(true);
    const topCandidatesCall = dispatch(loadSharedTopCandidates(token));
    const roleCall = dispatch(loadSharedProjectRole(token));
    const producerCall = dispatch(loadSharedProducer(token));

    Promise.all([topCandidatesCall, roleCall, producerCall]).then(() =>
      setLoading(false),
    );
  }, [dispatch, token]);

  useEffect(() => {
    getAsset(profileImage, true, token).then(url => setProfileImageUrl(url));
  }, [profileImage, token]);

  function handleTopCandidatesSortClick(newSort) {
    setSort(newSort);
  }

  const topCandidateCount = size(topCandidates);

  if (loading) {
    return <PageLoader />;
  }

  if (!tokenValid) {
    return <TokenExpiredPage />;
  }

  return (
    <>
      <NavBar />
      <Page
        contentStyle={styles.rolesContainer}
        HeaderComponent={
          <TopCandidatesHeader
            avatar={profileImageUrl}
            producerFullName={get(producer, 'fullName')}
            role={role}
          />
        }
        BannerComponent={
          <InfoBanner
            containerStyle={styles.banner}
            textStyle={styles.bannerText}
            text="Please be advised that the information contained on this page is Confidential Personally Identifiable Information to be used solely for the purpose of casting. Do not disclose this information to any third parties."
          />
        }
        hideSubscribeButton
      >
        <Section
          style={styles.section}
          title={`Top candidates (${topCandidateCount})`}
        >
          <TopCandidatesSharingTable
            loading={loading}
            topCandidates={orderedTopCandidates}
            sort={sort}
            onSortClick={handleTopCandidatesSortClick}
            token={token}
          />
        </Section>
      </Page>
    </>
  );
}
