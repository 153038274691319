import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { DropdownMenu, MenuItem, IconButton } from 'modules/ui';
import { getProjectById } from 'modules/projects';
import styles from 'modules/lists/components/ListsTable.module.scss';

const customStyles = {
  run: {
    marginLeft: -4,
  },
};

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
};

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
};

export default function AuditionRowMenu({
  onEditClick,
  onDeleteClick,
  onRunClick,
  onStopClick,
  onPreviewClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const params = useParams();
  const projectId = get(params, 'projectId');
  const project = useSelector(getProjectById(projectId));
  const projectFinished = get(project, 'finished');

  function handleOptionsClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(handleAction) {
    handleClose();
    handleAction();
  }

  return (
    <div>
      <IconButton
        className={styles.iconButton}
        onClick={handleOptionsClick}
        iconName="moreVertical"
        iconColor="black"
        iconSize={24}
      />
      <DropdownMenu
        open={open}
        width={172}
        onClose={handleClose}
        anchorEl={anchorEl}
        transformOrigin={TRANSFORM_ORIGIN}
        anchorOrigin={ANCHOR_ORIGIN}
        arrowRightPosition="14px"
      >
        {onPreviewClick && (
          <MenuItem
            text="Preview audition"
            icon="eye"
            onClick={() => handleClick(onPreviewClick)}
          />
        )}
        <MenuItem
          disabled={projectFinished}
          text="Edit audition"
          icon="edit"
          onClick={() => handleClick(onEditClick)}
        />
        {onDeleteClick && (
          <MenuItem
            text="Delete"
            icon="trash"
            onClick={() => handleClick(onDeleteClick)}
          />
        )}
        {onRunClick && (
          <MenuItem
            disabled={projectFinished}
            text="Run audition"
            icon="run"
            onClick={() => handleClick(onRunClick)}
            iconSize={20}
            iconStyle={customStyles.run}
            textStyle={customStyles.run}
          />
        )}
        {onStopClick && (
          <MenuItem
            disabled={projectFinished}
            text="Stop audition"
            icon="stop"
            onClick={() => handleClick(onStopClick)}
          />
        )}
      </DropdownMenu>
    </div>
  );
}

AuditionRowMenu.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
  onRunClick: PropTypes.func,
  onStopClick: PropTypes.func,
  onPreviewClick: PropTypes.func,
};
