import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import map from 'lodash/map';
import debounce from 'lodash/debounce';

import { getAccountType, AccountType } from 'modules/accounts';
import { getAgent } from 'modules/agents';
import { getProducer } from 'modules/producers';
import {
  getSearchedTalentsForInvite,
  searchTalentsForInvite,
  getSearchingForInvite,
} from 'modules/talents';

import { ListSelect } from 'modules/ui';

import { NavigateToTalentSearchFooterOption } from 'modules/project-roles';
import { getAuditionees } from 'modules/audition-applications';
import Audition from 'modules/auditions/services/audition';
import {
  sendSuggestion,
  getSuggestionError,
} from 'modules/audition-suggestions';
import Talent from 'modules/talents/services/talent';
import Validator from '../validator';

import { inviteTalent } from '../redux';

import TargetAuditionLabel from '../components/TargetAuditionLabel';

import InviteTalentDialog from './InviteTalentDialog';

function generateSelectionString(selection) {
  return map(selection, 'label');
}

export default function InviteTalentFromRoleDialog({
  audition,
  onCancel,
  onClose,
}) {
  const dispatch = useDispatch();

  const accountType = useSelector(getAccountType);
  const producer = useSelector(getProducer);
  const producerId = get(producer, 'id');
  const agent = useSelector(getAgent);
  const agentId = get(agent, 'id');

  const auditionId = get(audition, 'id');
  const auditionName = get(audition, 'name');
  const auditionees = useSelector(getAuditionees(auditionId));

  const talents = useSelector(getSearchedTalentsForInvite(auditionees));
  const searching = useSelector(getSearchingForInvite);

  const suggestionError = useSelector(getSuggestionError);

  const initialValues = {
    sendTo: [],
  };

  const [values, setValues] = useState(initialValues);
  const [inProgress, setInProgress] = useState(true);

  const [error, setError] = useState(false);

  const project = Audition.getAuditionProject(audition);
  const projectName = get(project, 'title');

  function dispatchSearchTalents(data) {
    if (data.query === '') {
      return;
    }
    if (AccountType.isAgent(accountType)) {
      dispatch(searchTalentsForInvite({ ...data, agentId }));
    } else if (AccountType.isProducer(accountType)) {
      dispatch(searchTalentsForInvite(data));
    }
  }

  const handleSearchTalents = useCallback(
    debounce(dispatchSearchTalents, 500, { leading: false, trailing: true }),
    [],
  );

  function handleSubmit() {
    const { sendTo, message } = values;
    const talentIds = map(sendTo, 'value');

    if (AccountType.isProducer(accountType)) {
      return dispatch(
        inviteTalent(producerId, auditionId, talentIds, message),
      ).then(() => setInProgress(false));
    }

    const talentId = sendTo.value;
    return dispatch(sendSuggestion(auditionId, talentId, message)).then(res => {
      if (!res) {
        return setError(true);
      }
      setError(false);
      return setInProgress(false);
    });
  }

  function handleTalentChange(searchValue) {
    const searchData = {
      sort: 'lastName',
      query: searchValue,
    };
    setError(false);
    return handleSearchTalents(searchData);
  }

  function handleValueChange(formValues) {
    setValues(formValues);
  }

  function resolvePlaceholderText() {
    if (AccountType.isProducer(accountType)) {
      return 'Enter recipients name';
    }
    return "Enter client's name";
  }

  function resolveTitleText() {
    if (AccountType.isProducer(accountType)) {
      return 'Invite Talent to Audition';
    }
    return 'Invite Client to Audition';
  }

  return (
    <InviteTalentDialog
      title={resolveTitleText()}
      inProgress={inProgress}
      initialValues={initialValues}
      values={values}
      handleValueChange={handleValueChange}
      validate={Validator.validateInviteTalent}
      onSubmit={handleSubmit}
      onClose={onClose}
      onCancel={onCancel}
      auditionName={auditionName}
      errorMessage={error ? true && suggestionError : false}
      dialogHeaderComponent={
        <TargetAuditionLabel
          projectName={projectName}
          auditionName={auditionName}
        />
      }
      dialogFooterComponentb={
        (AccountType.isProducer(accountType) &&
          NavigateToTalentSearchFooterOption) ||
        null
      }
    >
      <ListSelect
        required
        isMultiSelect={AccountType.isProducer(accountType) || false}
        options={Talent.createOptions(talents)}
        loading={searching}
        name="sendTo"
        label="Send to"
        theme="light"
        onChange={handleTalentChange}
        placeholder={resolvePlaceholderText()}
        noResultsText="No talents found"
        generateSelectionString={generateSelectionString}
      />
    </InviteTalentDialog>
  );
}

InviteTalentFromRoleDialog.propTypes = {
  audition: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};
