import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { EmptyPlaceholder } from 'modules/ui';
import { AccountType } from 'modules/accounts';

const customStyles = {
  emptyPlaceholder: {
    marginTop: 15,
    maxHeight: 524,
    marginBottom: 78,
  },
};

function OffersEmptyPlaceholder({
  accountType,
  openInviteClient,
  hasNoOffers,
  searchedTalent,
}) {
  const history = useHistory();

  if (AccountType.isTalent(accountType)) {
    return (
      <EmptyPlaceholder
        title="Offers from content creators"
        description="When content creator finds you suitable for a role, they will send you an offer. Offers will be visible here."
        style={customStyles.emptyPlaceholder}
      />
    );
  }
  if (hasNoOffers) {
    return (
      <EmptyPlaceholder
        title="Your clients don't have any Offers yet."
        description="Invite clients to Ahab or send Auditions to clients."
        buttonLabel="Invite client"
        secondaryButtonLabel="Search auditions"
        onButtonClick={openInviteClient}
        onSecondaryButtonClick={() => history.push('/auditions')}
        style={customStyles.emptyPlaceholder}
      />
    );
  }
  if (searchedTalent) {
    return (
      <EmptyPlaceholder
        title="This client doesn’t have any Offers yet."
        style={customStyles.emptyPlaceholder}
      />
    );
  }
  return (
    <EmptyPlaceholder
      title="0 offers found that match your criteria"
      description="Try adjusting your criteria to find what you're looking for"
      style={customStyles.emptyPlaceholder}
    />
  );
}

OffersEmptyPlaceholder.propTypes = {
  accountType: PropTypes.string.isRequired,
  openInviteClient: PropTypes.func.isRequired,
  hasNoOffers: PropTypes.bool,
  searchedTalent: PropTypes.object,
};

export default memo(OffersEmptyPlaceholder);
