import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { PageHeader, AvatarDescription, EmptyImagePlaceholder, EMPTY_IMAGE_PLACEHOLDER_SIZES } from 'modules/ui';

import styles from './AuditionPreviewHeader.module.scss';

function AuditionPreviewHeader({ avatar, audition, producer, coverImageSrc, isCoverLoading }) {
  const name = get(audition, 'name');
  const resolvedProducer = get(audition, 'producer') || producer;
  const producerName = get(resolvedProducer, 'fullName');

  return (
    <PageHeader>
      <div className={styles.container}>
        {coverImageSrc ? (
          <div className={styles.imageContainer}>
            <img
              src={coverImageSrc}
              alt="cover"
              className={styles.coverImage}
            />
          </div>
            ) : (
              <EmptyImagePlaceholder
                className={styles.coverImageAlt}
                size={EMPTY_IMAGE_PLACEHOLDER_SIZES.large}
                isLoading={isCoverLoading}
              />
          )}
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{name || 'Unknown title'}</h1>
          <AvatarDescription
            avatar={avatar}
            personName={producerName}
            verb="publish"
          />
        </div>
      </div>
    </PageHeader>
  );
}

AuditionPreviewHeader.propTypes = {
  avatar: PropTypes.string,
  audition: PropTypes.object,
  producer: PropTypes.object,
  coverImageSrc: PropTypes.string,
  isCoverLoading: PropTypes.bool,
};

export default memo(AuditionPreviewHeader);
