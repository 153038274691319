import React from 'react';
import { useHistory } from 'react-router-dom';
import { Page, Button, NavBar } from 'modules/ui';
import styles from './TokenExpiredPage.module.scss';

export default function TokenExpiredPage() {
  const history = useHistory();

  function handleButtonClick() {
    history.push('/');
  }

  return (
    <>
      <NavBar />
      <Page className={styles['page--tokenExpired']} hideSubscribeButton>
        <h1>The link is invalid or has expired.</h1>
        <div className="body16 darkGrey">
          Contact producer for more information.
        </div>
        <Button primary className="mt-64" onClick={handleButtonClick}>
          Go to Ahab
        </Button>
      </Page>
    </>
  );
}
