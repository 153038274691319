import React from 'react';
import PropTypes from 'prop-types';
import { AvatarDescription, Button, IconButton, PageHeader } from 'modules/ui';
import { LodashUtil } from 'modules/core';
import get from 'lodash/get';
import styles from './ProjectRoleHeader.module.scss';

const customStyles = {
  title: {
    marginTop: 10,
  },
};

export default function ProjectRoleHeader({
  project,
  role,
  avatar,
  onEditClick,
  onDeleteClick,
}) {
  const projectId = get(project, 'id');
  const projectTitle = get(project, 'title');
  const roleId = get(role, 'id');
  const roleName = get(role, 'name');
  const projectOwnerName = get(project, 'producer.fullName');

  const title = `Role: ${LodashUtil.truncateText(roleName, 40)}`;
  const buttonLabel = 'Edit role';
  const breadcrumbPaths = [
    { title: 'Projects', route: '/projects' },
    { title: projectTitle, route: `/projects/${projectId}` },
    { title: roleName, route: `/projects/${projectId}/roles/${roleId}` },
  ];

  const TitleComponent = (
    <div className={styles.titleContainer}>
      <h1 style={customStyles.title}>{title}</h1>
      <AvatarDescription
        verb="create"
        avatar={avatar}
        personName={projectOwnerName}
      />
    </div>
  );

  return (
    <PageHeader
      breadcrumbPaths={breadcrumbPaths}
      LeftComponent={TitleComponent}
    >
      <div className={styles.contentContainer}>
        <div className={styles.actionContainer}>
          <Button primary onClick={onEditClick} className={styles.editButton}>
            {buttonLabel}
          </Button>
          <IconButton
            className={styles.deleteButton}
            iconName="trash"
            iconColor="white"
            iconSize={20}
            onClick={onDeleteClick}
            disabled={!onDeleteClick}
          />
        </div>
      </div>
    </PageHeader>
  );
}

ProjectRoleHeader.propTypes = {
  project: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  avatar: PropTypes.string,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};
