import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AudioPreview } from 'modules/audio';
import { IconButton } from 'modules/ui';
import { Colors } from 'modules/theme';

import { SampleDropdownOptions } from '../fragments';
import EmptySamplePlaceholder from './EmptySamplePlaceholder';
import styles from './SamplePreview.module.scss';

const PATHS = ['ageRange', 'genre', 'vertical', 'primaryLanguage'];

export default function SamplePreview({
  sample,
  index,
  onAddClick,
  paths,
  containerClassName,
  isTalent,
  isReorderUi,
  onMoveSample,
  isFirstOnList,
  isLastOnList,
}) {
  const displayIndex = index + 1;
  const containerClasses = classNames(styles.container, {
    [styles.reorderContainer]: isReorderUi,
  });

  if (!sample && isTalent) {
    return (
      <EmptySamplePlaceholder
        index={displayIndex}
        onAddClick={onAddClick}
        containerClassName={containerClassName}
      />
    );
  }

  if (!sample) {
    return null;
  }

  return (
    <div className={containerClasses}>
      {isReorderUi && (
        <div className={styles.sortingIconsContainer}>
          <IconButton
            iconName="downArrow"
            isFlippedVertical
            iconColor={isFirstOnList ? Colors.darkBlue05 : Colors.grayishBlue}
            iconSize={12}
            onClick={() => !isFirstOnList && onMoveSample(sample.id, true)}
          />
          <IconButton
            iconName="downArrow"
            iconColor={isLastOnList ? Colors.darkBlue05 : Colors.grayishBlue}
            iconSize={12}
            onClick={() => !isLastOnList && onMoveSample(sample.id, false)}
          />
        </div>
      )}
      <AudioPreview
        details={sample}
        detailsPaths={paths}
        truncateLength={false}
        separator="dot"
        showTitleWhilePlaying
        informationClassName={styles.informationContainer}
      />
      {!isTalent && <SampleDropdownOptions file={sample} />}
    </div>
  );
}

SamplePreview.defaultProps = {
  paths: PATHS,
  isTalent: false,
};

SamplePreview.propTypes = {
  sample: PropTypes.object,
  index: PropTypes.number,
  onAddClick: PropTypes.func,
  paths: PropTypes.array,
  containerClassName: PropTypes.string,
  isTalent: PropTypes.bool,
  isReorderUi: PropTypes.bool,
  onMoveSample: PropTypes.func,
  isFirstOnList: PropTypes.bool,
  isLastOnList: PropTypes.bool,
};
