import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from 'modules/theme';
import { Page } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { WelcomeLogo } from '../components/AuthPage/assets';
import { AuthHeader } from '../components';
import {
  verifyEmail,
  getVerificationError,
  getVerificationSuccess,
} from '../redux';
import { AUTH_CODES } from '../const';

const styles = {
  container: {
    paddingTop: 64,
    textAlign: 'center',
  },
  title: {
    paddingTop: 60,
    paddingBottom: 25,
    fontWeight: 'bold',
    fontSize: 24,
    alignItems: 'center',
    textAlign: 'center',
  },
  error: {
    paddingTop: 60,
    color: Colors.error,
  },
};

export default function VerificationEmailPage({ location, history }) {
  const dispatch = useDispatch();
  const verificationError = useSelector(getVerificationError);
  const verificationSuccess = useSelector(getVerificationSuccess);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const username = urlParams.get('username');
    const code = urlParams.get('code');

    dispatch(verifyEmail(username, code));
  }, [dispatch, location]);

  useEffect(() => {
    if (verificationSuccess) {
      dispatch(
        showNotification({
          message: 'Your account is verified',
          type: Notification.TYPES.SUCCESS,
        }),
      );
      return history.push({
        pathname: '/login',
      });
    }
  }, [verificationSuccess, history, dispatch]);

  useEffect(() => {
    if (verificationError) {
      return history.push({
        pathname: '/forgot-password',
        state: {
          code: AUTH_CODES.VERIFICATION_LINK_EXPIRED,
        },
      });
    }
  }, [verificationError, history]);

  return (
    <Page
      className="pt-64"
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/login"
          linkLabel={`Return to ${window.location.hostname}`}
        />
      }
    >
      <div style={styles.container}>
        <WelcomeLogo />
        <div style={styles.title}>Your account is being verified</div>
        <Loader type="Oval" color={Colors.darkBlue} width={40} height={40} />
      </div>
    </Page>
  );
}

VerificationEmailPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};
