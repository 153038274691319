import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import Dictionary from 'modules/dictionary';

function getRangeString(ranges) {
  if (isEmpty(ranges) || !ranges[0].from) {
    return '';
  }

  return map(ranges, range => `${range.from}-${range.to}`).join(', ');
}

function getGenderAndAgeString(gender, ageRanges) {
  let values = [];

  if (gender) {
    values.push(upperFirst(gender));
  }

  if (!isEmpty(ageRanges)) {
    const ageString = getRangeString(ageRanges);
    values.push(`${ageString} y/o`);
  }

  return values.join(', ');
}

function getExperienceDisplayString(experienceObject) {
  const experienceDisplayObject = find(
    Dictionary.RECORDING_EXPERIENCE_OPTIONS,
    experienceOption => experienceOption.value === experienceObject.id,
  );

  return experienceDisplayObject.label;
}

function getRecordingExperienceString(experienceObjsArray) {
  if (isEmpty(experienceObjsArray)) {
    return null;
  }

  const experienceStringsArray = map(
    experienceObjsArray,
    getExperienceDisplayString,
  );

  return `${experienceStringsArray.join(', ')} audiobooks`;
}

export default {
  getGenderAndAgeString,
  getRecordingExperienceString,
  getRangeString,
};
