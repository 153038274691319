import React from 'react';
import pick from 'lodash/pick';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Section, InlineSection } from 'modules/ui';
import DateUtil from 'modules/core/services/date';
import RecordingLocationInlineSection from './RecordingLocationInlineSection';

function RecordingSection({ audition, ...otherProps }) {
  const recordingFields = [
    'recordingDate',
    'recordingLength',
    'recordingLocations',
    'studioAddress',
  ];
  const recording = pick(audition, recordingFields);
  const amount = get(recording.recordingLength, 'amount');
  const type = capitalize(startCase(get(recording.recordingLength, 'type')));
  const recordingDuration = !amount || !type ? null : `${amount} ${type}`;

  const rangeSelected =
    !isEmpty(audition.recordingStartDate) &&
    !isEmpty(audition.recordingEndDate);

  const formattedDateRange = `${DateUtil.formatDate(
    audition.recordingStartDate,
  )} - ${DateUtil.formatDate(audition.recordingEndDate)}`;

  return (
    <Section title="Recording Details" {...otherProps}>
      <InlineSection
        title="Recording window"
        text={rangeSelected && formattedDateRange}
        placeholderText="Any"
      />
      <RecordingLocationInlineSection
        recordingLocations={recording.recordingLocations}
        studioAddress={recording.studioAddress}
      />
      <InlineSection
        title="Recording duration"
        text={recordingDuration}
        placeholderText="Any"
      />
    </Section>
  );
}

RecordingSection.propTypes = {
  audition: PropTypes.object,
  Placeholder: PropTypes.node,
};

export default RecordingSection;
