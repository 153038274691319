import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Grid from '@material-ui/core/Grid/Grid';
import { Select, OptionUtil, TextInput } from 'modules/ui';

const styles = {
  publisher: {
    width: 252,
    paddingRight: 40,
  },
};
function PastRecordingInput({
  value,
  error,
  disabled,
  publishers,
  onChangePublisher,
  onChangeRecording,
}) {
  const [recording, setRecording] = useState(value);

  useEffect(() => {
    setRecording(value);
  }, [value]);

  const publisher = get(recording, 'publisher', null);
  const name = get(recording, 'name', '');

  const publisherChangeDisabled =
    disabled || !onChangePublisher || isEmpty(publishers);
  const recordingChangeDisabled = disabled || !onChangeRecording || !publisher;
  const publisherOptions = OptionUtil.getOptions(publishers, publisher =>
    OptionUtil.mapOptionFromModel(publisher, 'id', 'name'),
  );

  return (
    <Grid container item spacing={2}>
      <Grid item xs={7}>
        <Select
          error={error && error.publisher}
          name="publisher"
          placeholder="Publisher"
          value={get(publisher, 'id', null)}
          onChange={onChangePublisher}
          displayValue={get(publisher, 'name', '')}
          disabled={publisherChangeDisabled}
          containerClassName={disabled && 'mb-0'}
          options={publisherOptions}
          style={styles.publisher}
        />
      </Grid>
      <Grid item xs={5}>
        <TextInput
          error={error && error.name}
          name="name"
          placeholder="Recording"
          value={name}
          disabled={recordingChangeDisabled}
          onChange={onChangeRecording}
          containerClassName={disabled && 'mb-0'}
        />
      </Grid>
    </Grid>
  );
}

PastRecordingInput.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  publishers: PropTypes.array,
  onChangePublisher: PropTypes.func,
  onChangeRecording: PropTypes.func,
};

export default memo(PastRecordingInput);
