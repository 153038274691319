import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import has from 'lodash/has';

import { Icon } from 'modules/ui';
import { Colors } from 'modules/theme';

import { EMPTY_IMAGE_PLACEHOLDER_SIZES } from './consts';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.darkBlue,
  },
  opaqueBackground: {
    backgroundColor: Colors.darkGrey,
    opacity: '30%',
  },
  small: {
    borderRadius: 4,
    height: 40,
    width: 40,
  },
  medium: {
    borderRadius: 4,
    height: 64,
    width: 64,
  },
  large: {
    borderRadius: 8,
    height: 120,
    width: 120,
  },
};

export default function EmptyImagePlaceholder({
  size,
  className,
  isDarkBackground,
  iconName,
  isLoading,
}) {
  function resolveIconSize() {
    switch (size) {
      case EMPTY_IMAGE_PLACEHOLDER_SIZES.small:
        return 17;
      case EMPTY_IMAGE_PLACEHOLDER_SIZES.medium:
        return 28;
      case EMPTY_IMAGE_PLACEHOLDER_SIZES.large:
        return 52;
      default:
        return 28;
    }
  }

  const validSizeIsProvided = has(EMPTY_IMAGE_PLACEHOLDER_SIZES, size);
  const sizeStyles = {
    ...(validSizeIsProvided ? styles[size] : styles.medium),
  };

  function resolveContainerStyle() {
    if (!isDarkBackground) {
      return {
        ...styles.container,
        ...sizeStyles,
      };
    }
    return {
      ...styles.container,
      ...styles.opaqueBackground,
      ...sizeStyles,
    };
  }

  const iconSize = resolveIconSize();
  const resolvedIconName = iconName || 'microphone';
  const containerStyle = resolveContainerStyle();

  return (
    <div className={className} style={containerStyle}>
      {!isLoading ? (
        <Icon
          size={iconSize}
          name={resolvedIconName}
          color={isDarkBackground ? Colors.white : Colors.grayishBlue}
        />
      ) : (
        <Loader
          type="Oval"
          color={Colors.white}
          width={iconSize}
          height={iconSize}
        />
      )}
    </div>
  );
}

EmptyImagePlaceholder.propTypes = {
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDarkBackground: PropTypes.bool,
  iconName: PropTypes.string,
  isLoading: PropTypes.bool,
};
