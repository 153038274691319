import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import includes from 'lodash/includes';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { Project } from 'modules/projects';

const ALL_VERTICALS = { value: 'All', label: 'All' };

function orderByOrdinalNumber(
  firstSamplesArray,
  secondSamplesArray,
  thirdSamplesArray,
) {
  const unorderedSamples = filter(
    [firstSamplesArray, secondSamplesArray, thirdSamplesArray],
    samplesPartition => !isEmpty(samplesPartition),
  );

  const reorderedSamples = flatten(
    map(unorderedSamples, samplesPartition => {
      return orderBy(samplesPartition, ['ordinalNumber'], ['asc']);
    }),
  );

  return reorderedSamples;
}

function verticalNotSelected(vertical) {
  return vertical === null || vertical === ALL_VERTICALS.value;
}

function filterByVertical(samples, vertical) {
  if (verticalNotSelected(vertical)) {
    return orderByOrdinalNumber(samples);
  }

  return orderByOrdinalNumber(
    filter(samples, sample => sample.vertical === vertical),
  );
}

function prioritize(samples, priorityVerticals, priorityGenres) {
  if (isEmpty(priorityVerticals) && isEmpty(priorityGenres)) {
    return orderByOrdinalNumber(samples);
  }
  if (isEmpty(priorityGenres)) {
    const prioritized = partition(samples, sample =>
      includes(priorityVerticals, sample.vertical),
    );

    return orderByOrdinalNumber(prioritized[0], prioritized[1]);
  }

  const byVerticalAndGenre = partition(
    samples,
    sample =>
      includes(priorityVerticals, sample.vertical) &&
      includes(priorityGenres, sample.genre),
  );
  const verticalAndGenrePrioritized = [...byVerticalAndGenre[0]];

  const byVerticalOrGenre = partition(
    byVerticalAndGenre[1],
    sample =>
      includes(priorityVerticals, sample.vertical) ||
      includes(priorityGenres, sample.genre),
  );
  const verticalOrGenrePrioritized = [...byVerticalOrGenre[0]];
  const unprioritized = [...byVerticalOrGenre[1]];

  return orderByOrdinalNumber(
    verticalAndGenrePrioritized,
    verticalOrGenrePrioritized,
    unprioritized,
  );
}

function getGenresFromVertical(vertical) {
  return Project.getGenresFromVertical(vertical);
}

export default {
  orderByOrdinalNumber,
  filterByVertical,
  prioritize,
  verticalNotSelected,
  getGenresFromVertical,
  ALL_VERTICALS,
};
