import { Api } from 'modules/core';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { loadTopCandidates } from '../top-candidates';
import { Auditions } from '../auditions/services';
import { loadOffers } from '../offers/redux';

const PROJECT_ROLE_TYPE = 'project-roles';
const PROJECT_ROLE_INCLUDE = 'hiredTalents';

function getRolesUrl(producerId, projectId) {
  return `producers/${producerId}/projects/${projectId}/roles`;
}

export function fetchRole(producerId, projectId, roleId) {
  if (!producerId || !projectId || !roleId) {
    return Promise.resolve();
  }

  const rolesUrl = getRolesUrl(producerId, projectId);
  const params = { include: PROJECT_ROLE_INCLUDE };

  return Api.find(`${rolesUrl}/${roleId}`, params);
}

export function fetchProjectRoles(producerId, projectId) {
  if (!producerId || !projectId) {
    return Promise.resolve();
  }

  const rolesUrl = getRolesUrl(producerId, projectId);
  const params = {
    include: PROJECT_ROLE_INCLUDE,
  };

  return Api.find(`${rolesUrl}/all`, params);
}

export function fetchSharedProjectRole(token) {
  if (!token) {
    return Promise.resolve();
  }

  const params = { include: 'project' };
  const headers = { headers: { 'auth-code': token } };
  return Api.find(`shared/roles`, params, {}, headers);
}

export function postRole(producerId, projectId, values) {
  const endpoint = getRolesUrl(producerId, projectId);
  const role = { resourceType: PROJECT_ROLE_TYPE, ...values };

  return Api.create(endpoint, role);
}

export function patchRole(producerId, projectId, roleId, values) {
  const endpoint = getRolesUrl(producerId, projectId);
  const role = { type: PROJECT_ROLE_TYPE, ...values };
  const params = {
    include: PROJECT_ROLE_INCLUDE,
  };

  return Api.update(
    `${endpoint}/${roleId}`,
    role,
    PROJECT_ROLE_TYPE,
    null,
    params,
  );
}

export function deleteRole(producerId, projectId, roleId) {
  const endpoint = getRolesUrl(producerId, projectId);

  return Api.deleteResource(`${endpoint}/${roleId}`);
}

export function checkCanDeleteRole(producerId, projectId, roleId, auditions) {
  return async dispatch => {
    const { data: topCandidates } = await dispatch(
      loadTopCandidates(producerId, projectId, roleId),
    );
    const hasActiveOrInactiveAuditions = Auditions.hasActiveOrInactiveAudition(
      auditions,
    );

    const talentsIds = map(
      topCandidates,
      topCandidate => topCandidate.talentId,
    ).join(',');

    if (!isEmpty(talentsIds)) {
      const { data: offers } = await dispatch(
        loadOffers({ talentId: talentsIds, projectRoleId: roleId }),
      );

      const hasOffers = !isEmpty(offers);

      return Promise.resolve(!hasActiveOrInactiveAuditions && !hasOffers);
    }

    return Promise.resolve(!hasActiveOrInactiveAuditions);
  };
}
