import { Validator } from 'modules/core';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';

function validateRole({ name, names }) {
  const nameError = Validator.validateRoleName(name, names, 'Role name');

  return {
    ...(!!nameError && { title: nameError }),
  };
}

function validateAddTopCandidate(values) {
  const talentId = get(values, 'talentId');
  const sampleId = get(values, 'sampleId');

  const errors = {
    talentId: Validator.validateNotEmpty(talentId, "Talent's name"),
    sampleId: Validator.validateNotEmpty(sampleId, 'Sample'),
  };

  return omitBy(errors, error => error === false);
}

export default {
  validateRole,
  validateAddTopCandidate,
};
