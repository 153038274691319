import React, { Children } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

export default function ProfessionalInfoGrid({ children }) {
  const items = Children.toArray(children);

  return (
    <Grid container spacing={0}>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[0]}
        </Grid>
        <Grid item xs={6}>
          {items[1]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[2]}
        </Grid>
        <Grid item xs={6}>
          {items[3]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[4]}
        </Grid>
        <Grid item xs={6}>
          {items[5]}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {items[6]}
      </Grid>
      <Grid item xs={12}>
        {items[7]}
      </Grid>
      <Grid item xs={12}>
        {items[8]}
      </Grid>
      <Grid item xs={12}>
        {items[9]}
      </Grid>
      <h3>Social Media</h3>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[10]}
        </Grid>
        <Grid item xs={6}>
          {items[11]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[12]}
        </Grid>
        <Grid item xs={6}>
          {items[13]}
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[14]}
        </Grid>
        <Grid item xs={6}>
          {items[15]}
        </Grid>
      </Grid>
    </Grid>
  );
}

ProfessionalInfoGrid.propTypes = {
  children: PropTypes.node,
};
