import React, { Children, memo } from 'react';
import PropTypes from 'prop-types';
import { TabLink, TabLayout } from 'modules/ui';

function WizardTabs({ children, onClick }) {
  const renderTabLink = child => {
    const {
      props: { label, pageIndex, isActive, disabled },
    } = child;

    const clickHandler = disabled ? null : () => onClick(pageIndex);
    return (
      <TabLink
        disabled={disabled}
        key={pageIndex}
        onClick={clickHandler}
        active={isActive}
      >
        {label}
      </TabLink>
    );
  };

  const renderComponent = child => {
    const {
      props: { isActive },
    } = child;

    if (!isActive) {
      return null;
    }

    return child;
  };

  const tabs = Children.map(children, renderTabLink);

  return (
    <TabLayout tabs={tabs}>{Children.map(children, renderComponent)}</TabLayout>
  );
}

WizardTabs.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default memo(WizardTabs);
