import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { Api } from 'modules/core';

const TYPE = 'audition-messages';

// TODO: Remove authorAccount from the list of includes
// /accounts/{accountId} endpoint on backend needs to be unrestricted
const INCLUDE =
  'authorAccount.talent,authorAccount.producer,audioClip,auditionApplication';

function getMessagesUrl(auditionId, applicationId) {
  return `auditions/${auditionId}/applications/${applicationId}/messages`;
}

export function postAuditionMessage(
  auditionId,
  applicationId,
  authorId,
  message,
) {
  const endpoint = `${getMessagesUrl(auditionId, applicationId)}`;
  const newMessage = { resourceType: TYPE, text: message };

  const params = {
    include: INCLUDE,
  };

  const relationships = {
    auditionApplication: {
      data: {
        type: 'audition-applications',
        id: applicationId,
      },
    },
    authorAccount: {
      data: {
        type: 'accounts',
        id: authorId,
      },
    },
  };

  return Api.create(endpoint, newMessage, relationships, params);
}

export function fetchApplicationMessages(auditionId, applicationId) {
  if (!auditionId || !applicationId) {
    return Promise.resolve(null);
  }

  const params = {
    include: INCLUDE,
  };

  const applicationEndpoint = getMessagesUrl(auditionId, applicationId);
  const endpoint = `${applicationEndpoint}/all`;
  return Api.find(endpoint, params);
}

export function fetchAllAuditionMessages(auditionId, applications) {
  if (!auditionId) {
    return Promise.resolve(null);
  }

  if (isEmpty(applications)) {
    return Promise.resolve(null);
  }

  const messages = map(applications, application =>
    fetchApplicationMessages(auditionId, application.id),
  );
  return Promise.all(messages);
}

export function fetchUnreadMessagesCount() {
  const endpoint = 'auditions/actions/countUnread';
  return Api.find(endpoint);
}
