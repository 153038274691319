import { createReducer, createAction } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import find from 'lodash/find';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import some from 'lodash/some';
import orderBy from 'lodash/orderBy';
import slice from 'lodash/slice';
import unionBy from 'lodash/unionBy';

import { Mapper } from 'modules/core';
import {
  createOrUpdateAudition as createOrUpdateProducerAudition,
  loadAuditions as loadProducerAuditions,
  loadAudition as loadProducerAudition,
  removeAudition,
  loadAllAuditions as loadAllProducerAuditions,
} from 'modules/auditions-producers';
import { getApplications } from 'modules/audition-applications';
import { loadSavedAuditions } from 'modules/auditions-talents';

import { search, fetchAudition } from './api';
import { Audition } from './services';
import { resolveErrorMessage } from '../errors/services';

const INITIAL_STATE = {
  auditions: [],
  loading: false,
  error: false,
  auditionsFilterData: null,
};

export const loadAudition = createThunk(fetchAudition, 'load', 'auditions');
export const searchAuditions = createThunk(search, 'search', 'auditions');
export const setAuditionsFilterData = createAction('auditions/setRedirectData');
export const clearAuditionsFilterData = createAction(
  'auditions/clearRedirectData',
);

export const reducer = createReducer(INITIAL_STATE, {
  [loadAudition.type.request]: state => {
    state.loading = true;
  },
  [loadAudition.type.success]: (state, action) => {
    const newAudition = action.payload.data;

    state.loading = false;
    state.auditions = Mapper.addOrReplace(state.auditions, newAudition);
  },
  [loadAudition.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
    state.loading = false;
  },
  [searchAuditions.type.request]: state => {
    state.loading = true;
  },
  [searchAuditions.type.success]: (state, action) => {
    const newSearchResults = action.payload.data;

    state.loading = false;
    state.auditions = unionBy(newSearchResults, state.auditions, 'id');
  },
  [searchAuditions.type.error]: state => {
    state.loading = false;
  },

  // Producer auditions
  [loadProducerAudition.type.success]: (state, action) => {
    const newAudition = action.payload.data;

    state.auditions = Mapper.addOrReplace(state.auditions, newAudition);
  },
  [createOrUpdateProducerAudition.type.success]: (state, action) => {
    const newAudition = action.payload.data;

    state.auditions = Mapper.addOrReplace(state.auditions, newAudition);
    state.error = false;
  },
  [loadProducerAuditions.type.success]: (state, action) => {
    const loadedAuditions = action.payload.data;

    state.auditions = unionBy(loadedAuditions, state.auditions, 'id');
  },
  [loadAllProducerAuditions.type.success]: (state, action) => {
    const loadedAuditions = action.payload.data;
    state.auditions = unionBy(loadedAuditions, state.auditions, 'id');
  },
  [removeAudition.type.success]: (state, action) => {
    const deletedAuditionId = action.request[1];

    remove(state.auditions, audition => audition.id === deletedAuditionId);
  },

  // Talent auditions
  [loadSavedAuditions.type.success]: (state, action) => {
    const loadedAuditions = action.payload.data;

    state.auditions = unionBy(loadedAuditions, state.auditions, 'id');
  },
  [setAuditionsFilterData]: (state, action) => {
    state.auditionsFilterData = { ...action.payload };
  },
  [clearAuditionsFilterData]: state => {
    state.auditionsFilterData = null;
  },
});

export const getAuditionLoading = createSelector(['auditions.loading']);
export const getAuditions = createSelector(['auditions.auditions']);

function filterActiveAuditionsByRoleId(auditions, roleId, limit) {
  const activeAuditions = filter(
    auditions,
    audition =>
      audition.projectRoleId === roleId &&
      Audition.getAuditionStatus(audition) === Audition.STATUSES.ACTIVE,
  );
  const orderedAuditions = orderBy(activeAuditions, ['updatedAt'], ['desc']);

  if (limit === 0) {
    return orderedAuditions;
  }

  return slice(orderedAuditions, 0, limit);
}

export const getActiveAuditionsByRoleId = (roleId, limit = 0) =>
  createSelector(
    [getAuditions],
    stateAuditions =>
      filterActiveAuditionsByRoleId(stateAuditions, roleId, limit),
  );

export const getAuditionById = auditionId =>
  createSelector(
    [getAuditions],
    stateAuditions => find(stateAuditions, { id: auditionId }),
  );

export const getAuditionsByRoleId = roleId =>
  createSelector(
    [getAuditions],
    stateAuditions =>
      filter(
        stateAuditions,
        stateAudition => stateAudition.projectRoleId === roleId,
      ),
  );

export const getAppliedAuditionsByTalentId = talentId =>
  createSelector(
    [getAuditions, getApplications],
    (stateAuditions, stateApplications) =>
      filter(stateAuditions, audition =>
        some(stateApplications, { talentId, auditionId: audition.id }),
      ),
  );

export const getAuditionsFilterData = createSelector([
  'auditions.auditionsFilterData',
]);
