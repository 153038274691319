import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './InfoText.module.scss';

function InfoText({ text, detailsText, className }) {
  const containerClasses = classNames(classes.container, className);

  return (
    <div className={containerClasses}>
      <div>{text}</div>
      <div>{detailsText}</div>
    </div>
  );
}

InfoText.propTypes = {
  text: PropTypes.string.isRequired,
  detailsText: PropTypes.string,
  className: PropTypes.string,
};
export default InfoText;
