import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './ChatPlaceholder.module.scss';

function ChatPlaceholder({ className, title, subtitle }) {
  return (
    <div className={classNames(classes.placeholder, className)}>
      <p className={classNames(classes.text, classes.title)}>{title}</p>
      <p className={classes.text}>{subtitle}</p>
    </div>
  );
}

ChatPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ChatPlaceholder;
