import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import get from 'lodash/get';
import find from 'lodash/find';
import Loader from 'react-loader-spinner';

import { DateUtil, LodashUtil } from 'modules/core';
import { Colors } from 'modules/theme';
import {
  EmptyImagePlaceholder,
  EMPTY_IMAGE_PLACEHOLDER_SIZES,
} from 'modules/ui';

import { getCoverImage, getLoadingCovers } from '../redux';

const styles = {
  tableRow: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  projectNameRow: {
    display: 'flex',
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
  },
  projectName: {
    paddingLeft: 20,
  },
  lastCell: {
    paddingRight: 45,
  },
  image: {
    height: 40,
    width: 40,
    borderRadius: 4,
  },
};

export default function ProjectRow({
  project,
  producer,
  onRowClick,
  stats,
  loadingStats,
}) {
  const { id, title, createdAt, coverImage } = project;
  const { fullName } = producer;

  const coverImageSrc = useSelector(getCoverImage(coverImage));
  const loadingCovers = useSelector(getLoadingCovers);

  const hiredTalentCount = get(stats, 'hiredTalentCount', '-');
  const activeAuditionCount = get(stats, 'activeAuditionCount', '-');

  const isImageLoading = !!find(loadingCovers, cover => {
    return cover === coverImage;
  });

  const displayDate = DateUtil.formatDate(createdAt);
  const auditionsLabel = `${activeAuditionCount} active`;
  const talentsHiredLabel = `${hiredTalentCount} hired`;

  function handleRowClick() {
    onRowClick(project);
  }

  function renderStats(label, loading) {
    if (loading) {
      return (
        <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
      );
    }

    return label;
  }

  return (
    <TableRow
      hover
      component={Link}
      to={`/projects/${project.id}`}
      key={id}
      style={styles.tableRow}
      onClick={handleRowClick}
    >
      <TableCell component="th" scope="row" style={styles.tableRow}>
        <div style={styles.projectNameRow}>
          {coverImageSrc ? (
            <img src={coverImageSrc} alt="cover" style={styles.image} />
          ) : (
            <EmptyImagePlaceholder
              size={EMPTY_IMAGE_PLACEHOLDER_SIZES.small}
              isLoading={isImageLoading}
            />
          )}
          <div style={styles.projectName}>
            {LodashUtil.truncateText(title, 33)}
          </div>
        </div>
      </TableCell>
      <TableCell align="center">
        {renderStats(auditionsLabel, loadingStats)}
      </TableCell>
      <TableCell align="center">
        {renderStats(talentsHiredLabel, loadingStats)}
      </TableCell>
      <TableCell align="center">{fullName}</TableCell>
      <TableCell align="right" style={styles.lastCell}>
        {displayDate}
      </TableCell>
    </TableRow>
  );
}

ProjectRow.propTypes = {
  project: PropTypes.object,
  producer: PropTypes.object,
  onRowClick: PropTypes.func,
  stats: PropTypes.object,
  loadingStats: PropTypes.bool,
};
