import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getTealiumScriptLoaded } from 'modules/core';
import { getAccountTypeLabel } from '../redux';

function useTealiumView(pageName) {
  const accountTypeLabel = useSelector(getAccountTypeLabel);
  // used for preventing global.utag sometimes being undefined on reload (first render) due to race condition with utag script download in index.html
  const utagScriptLoaded = useSelector(getTealiumScriptLoaded);

  // prevents running sideEffect twice when props change due to user's interaction
  const didTrigger = useRef(false);

  useEffect(() => {
    if (utagScriptLoaded && global.utag && pageName && !didTrigger.current) {
      global.utag.view({
        page_type: pageName,
        ...(accountTypeLabel && { account_type: accountTypeLabel }),
      });
      didTrigger.current = true;
    }
  }, [accountTypeLabel, pageName, utagScriptLoaded]);
}

export default useTealiumView;
