import React from 'react';
import PropTypes from 'prop-types';

import { Quotation } from 'modules/ui';
import { useWindowSize } from 'modules/core';

import AuthHeader from '../AuthHeader';
import classes from './AuthPage.module.scss';

function AuthPage({
  light,
  dark,
  headerLink,
  headerLinkLabel,
  headerLinkDescription,
  children,
}) {
  const { desktopWidth } = useWindowSize();

  return (
    <div className={classes.authPage}>
      <AuthHeader
        light={light}
        dark={dark}
        className={classes.authHeader}
        lightLogo={desktopWidth}
        link={headerLink}
        linkLabel={headerLinkLabel}
        linkDescription={desktopWidth && headerLinkDescription}
      />
      {desktopWidth && (
        <div className={classes.sideBar}>
          <Quotation
            className={classes.quotation}
            quote="Words mean more than what is set down on paper. It takes the human voice to infuse them with deeper meaning."
            author="Maya Angelou"
          />
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
}

AuthPage.propTypes = {
  children: PropTypes.node.isRequired,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  headerLink: PropTypes.string,
  headerLinkLabel: PropTypes.string,
  headerLinkDescription: PropTypes.string,
};

export default AuthPage;
