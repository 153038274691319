import React from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'modules/core';
import { Page } from 'modules/ui';
import { useTealiumView } from 'modules/accounts';
import { AuthHeader, ChooseSignUpPageCard } from '../components';
import {
  TalentIcon,
  CreatorIcon,
  AgentIcon,
} from '../components/AuthPage/assets';
import styles from './ChooseSignUpPage.module.scss';

export default function ChooseSignUpPage() {
  const history = useHistory();
  const { mobileWidth } = useWindowSize();

  const style = {
    page: {
      background: 'none',
    },
    pageContent: { padding: 0 },
  };

  useTealiumView('Signup Landing Page');

  return (
    <div className={styles.pageContainer}>
      <div className={styles.layer}>
        <Page
          showFooter={false}
          style={style.page}
          contentStyle={style.pageContent}
          HeaderComponent={
            <AuthHeader
              dark
              lightLogo
              link="/login"
              linkLabel="Sign in"
              linkDescription={!mobileWidth && 'Already have an account?'}
            />
          }
        >
          <div className={styles.container}>
            <h1 className={styles.title}>Sign up for Ahab</h1>
            <div className={styles.subtitle}>I want to sign up as:</div>
            <div className={styles.cardsContainer}>
              <ChooseSignUpPageCard
                Icon={TalentIcon}
                onClick={() => history.push({ pathname: 'signup/talent' })}
                cardTitle="Voice Talent"
                cardSubtitle="Create a profile, audition, and get hired!"
                buttonText="Sign Up"
              />
              <ChooseSignUpPageCard
                Icon={CreatorIcon}
                onClick={() =>
                  history.push({
                    pathname: 'signup/content-creator-application',
                  })
                }
                cardTitle="Content Creator"
                cardSubtitle="Manage projects, host auditions, and find the perfect voice in a sea of Talent!"
                buttonText="Apply now"
              />
              <ChooseSignUpPageCard
                Icon={AgentIcon}
                onClick={() => history.push({ pathname: 'signup/agent' })}
                cardTitle="Agent"
                cardSubtitle="Find jobs for your clients, connect directly with Content Creators, and negotiate offers easily!"
                buttonText="Sign Up"
              />
            </div>
          </div>
        </Page>
      </div>
    </div>
  );
}
