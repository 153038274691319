import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'modules/ui';
import Page from './Page';
import styles from './Page.module.scss';

function ServerErrorPage({ error }) {
  return (
    <Page className={styles['page--error500']}>
      <Logo dark width={150} />
      <h1>Oops, something went wrong!</h1>
      <div className="body14">{error}</div>
    </Page>
  );
}

ServerErrorPage.propTypes = {
  error: PropTypes.string,
};

ServerErrorPage.defaultProps = {
  error: 'Our servers are down, please try again later.',
};

export default ServerErrorPage;
