import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import pluralize from 'pluralize';
import find from 'lodash/find';
import get from 'lodash/get';

import { DateUtil } from 'modules/core';
import {
  Card,
  EmptyImagePlaceholder,
  EMPTY_IMAGE_PLACEHOLDER_SIZES,
} from 'modules/ui';
import { Colors } from 'modules/theme';

import { getCoverImage, getLoadingCovers } from '../redux';
import styles from './ProjectCard.module.scss';

export default function ProjectCard({ project, onClick, stats, loadingStats }) {
  const { title, createdAt, recordingDueDate, coverImage, vertical } = project;

  const coverImageSrc = useSelector(getCoverImage(coverImage));
  const loadingCovers = useSelector(getLoadingCovers);

  const coverIsLoading = !!find(loadingCovers, cover => {
    return cover === coverImage;
  });

  const hiredTalentCount = get(stats, 'hiredTalentCount', '-');
  const activeAuditionCount = get(stats, 'activeAuditionCount', '-');

  const displayDate = `Date created: ${DateUtil.formatDate(createdAt)}`;
  const endDate = recordingDueDate
    ? `End date: ${DateUtil.formatDate(recordingDueDate)}`
    : null;
  const activeAuditions = pluralize(
    'Active audition',
    activeAuditionCount,
    true,
  );
  const hiredTalents = `${pluralize('Actor', hiredTalentCount, true)} Hired`;

  function handleClick() {
    onClick(project);
  }

  return (
    <Link className={styles.link} to={`/projects/${project.id}`}>
      <Card
        onClick={handleClick}
        className={styles.card}
        width={358}
        height={254}
      >
        <h2 className={styles.title}>{title}</h2>
        <div className={`darkBlueColor ${styles.details}`}>
          {loadingStats ? (
            <Loader
              type="Oval"
              color={Colors.darkBlue}
              width={35}
              height={35}
            />
          ) : (
            <div className={styles.detailsContent}>
              {coverImageSrc ? (
                <img
                  src={coverImageSrc}
                  alt="cover"
                  className={styles.coverImage}
                />
              ) : (
                <EmptyImagePlaceholder
                  className={styles.coverImageAlt}
                  isLoading={coverIsLoading}
                  size={EMPTY_IMAGE_PLACEHOLDER_SIZES.medium}
                />
              )}
              <div className={styles.dateContainer}>
                <div className={styles.vertical}>{vertical}</div>
                <div className={styles.date}>{displayDate}</div>
                <div className={styles.date}>{endDate}</div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div>{activeAuditions}</div>
          <div className={styles.bullet}>•</div>
          <div>{hiredTalents}</div>
        </div>
      </Card>
    </Link>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  stats: PropTypes.object,
  loadingStats: PropTypes.bool,
};
