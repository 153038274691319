import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';

import { Button, SmallLogo } from 'modules/ui';
import { Colors } from 'modules/theme';

import { AuthPage } from '../components';

const styles = {
  container: {
    alignItems: 'center',
    textAlign: 'center',
  },
  descriptionTitle: {
    marginTop: '-10px',
    fontSize: '32px',
  },
  description: {
    color: Colors.darkGrey,
    lineHeight: '28px',
    maxWidth: '360px',
    margin: '25px 0 50px',
    fontSize: '16px',
  },
};

export default function SuccessPage({ location, history }) {
  const title = get(location, 'state.title');
  const description = get(location, 'state.description');

  const handleBackToAhab = () => history.push('/');

  if (!title && !description) {
    history.push('/login');
    return null;
  }

  return (
    <AuthPage
      className="pt-64"
      headerLink="/login"
      headerLinkLabel="Sign in"
      headerLinkDescription="Already have an account?"
      light
    >
      <div style={styles.container}>
        <SmallLogo />
        <h2 className="pt-48" style={styles.descriptionTitle}>
          {title}
        </h2>
        <p style={styles.description}>{description}</p>
        <Button primary style={styles.backButton} onClick={handleBackToAhab}>
          Go to Homepage
        </Button>
      </div>
    </AuthPage>
  );
}

SuccessPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};
