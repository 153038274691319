import React from 'react';
import PropTypes from 'prop-types';
import MaterialCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const StyledCell = withStyles({
  root: {
    fontSize: 14,
  },
})(MaterialCell);

export default function TableCell({ children, ...otherProps }) {
  return <StyledCell {...otherProps}>{children}</StyledCell>;
}

TableCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
