import React from 'react';
import PropTypes from 'prop-types';
import Dictionary from 'modules/dictionary';
import {
  MultiSelect,
  AutoCompleteSelect,
  ManagedForm,
  Textarea,
} from 'modules/ui';
import { SkillsGrid } from './form-grids';

export default function SkillsForm({ onSubmit, talent, ...otherProps }) {
  return (
    <ManagedForm
      withPrompt
      title="Your Skills"
      description="Tell us about your voice skills and languages"
      submitButtonLabel="Save skills"
      onSubmit={onSubmit}
      TemplateComponent={SkillsGrid}
      initialValues={talent}
      {...otherProps}
    >
      <MultiSelect
        autoFocus
        label="Vocal Age Ranges"
        name="voiceAgeRangesIds"
        placeholder="Select Vocal Age Ranges"
        collection={Dictionary.VOICE_RANGES}
        options={Dictionary.VOICE_RANGE_OPTIONS}
      />
      <AutoCompleteSelect
        isMultiSelect
        label="Primary Languages"
        name="primaryLanguages"
        placeholder="Select languages"
        collection={Dictionary.LANGUAGE}
        options={Dictionary.LANGUAGE_OPTIONS}
      />
      <AutoCompleteSelect
        isMultiSelect
        label="Accents"
        name="accents"
        placeholder="Select accent"
        collection={Dictionary.ACCENTS}
        options={Dictionary.ACCENT_OPTIONS}
      />
      <AutoCompleteSelect
        isMultiSelect
        label="Language fluencies"
        name="foreignLanguages"
        placeholder="Select languages"
        collection={Dictionary.LANGUAGE}
        options={Dictionary.LANGUAGE_OPTIONS}
      />
      <Textarea
        label="Regional Accents"
        name="nativeRegionalAccents"
        maxChars={350}
        placeholder="Write down your regional accents here..."
      />
    </ManagedForm>
  );
}

SkillsForm.propTypes = {
  onSubmit: PropTypes.func,
  talent: PropTypes.object,
};
