import get from 'lodash/get';

import { ChatType } from 'modules/chat';

export function auditionApplicationChatFilter(auditionId, talentId) {
  return window =>
    window.type === ChatType.AUDITION &&
    window.auditionId === auditionId &&
    window.talentId === talentId;
}

export function auditionApplicationChatId(auditionId, talentId) {
  return `${ChatType.AUDITION}-${auditionId}-${talentId}`;
}

export function enlargeAuditionApplicationChat(auditionApplication, history) {
  if (!auditionApplication) {
    return history.push('/messaging/auditions');
  }

  const auditionId = get(auditionApplication, 'auditionId');
  const talentId = get(auditionApplication, 'talentId');

  return history.push(`/messaging/auditions/${auditionId}/${talentId}`);
}
