import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import partition from 'lodash/partition';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import { Audition } from 'modules/auditions/services';

const SORT_OPTIONS = {
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
};

function partitionByAuditionStatus(applications, auditions) {
  const appliedAuditionIds = map(
    applications,
    application => application.auditionId,
  );

  const appliedAuditions = filter(auditions, audition =>
    includes(appliedAuditionIds, audition.id),
  );

  return partition(applications, application => {
    const applicationAudition = find(appliedAuditions, {
      id: application.auditionId,
    });

    return (
      Audition.getAuditionStatus(applicationAudition) ===
      Audition.STATUSES.ACTIVE
    );
  });
}

function orderByAudition(applications, orderedAuditions) {
  const orderedApplications = [];

  forEach(orderedAuditions, audition => {
    const application = find(applications, { auditionId: audition.id });
    if (application) {
      orderedApplications.push(application);
    }
  });

  return orderedApplications;
}

function applySortOptions(
  filteredApplications,
  auditions,
  sortOption,
  sortOrder,
) {
  if (!sortOption) {
    return filteredApplications;
  }

  if (
    sortOption === SORT_OPTIONS.CREATED_AT ||
    sortOption === SORT_OPTIONS.UPDATED_AT
  ) {
    return orderBy(
      filteredApplications,
      [application => application[sortOption].toLowerCase()],
      sortOrder,
    );
  }

  return orderByAudition(filteredApplications, auditions);
}

export default {
  partitionByAuditionStatus,
  applySortOptions,
  SORT_OPTIONS,
};
