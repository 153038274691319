import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import { TextInput, PasswordInput, ManagedForm } from 'modules/ui';
import { getServerError } from 'modules/core';
import { Colors } from 'modules/theme';
import { useTealiumView } from 'modules/accounts';
import { Validator } from '../services';
import {
  getLoginError,
  getPendingVerification,
  login,
  getPasswordResetRequired,
} from '../redux';
import { AuthPage, LoginFormFooterGrid } from '../components';

const styles = {
  forgotPasswordButton: {
    fontWeight: 500,
    fontSize: 12,
    color: Colors.inputPlaceholder,
    cursor: 'pointer',
  },
  form: {
    marginBottom: 120,
  },
};

export default function LoginPage({ history }) {
  const dispatch = useDispatch();
  const loginError = useSelector(getLoginError);
  const pendingVerification = useSelector(getPendingVerification);
  const passwordResetRequired = useSelector(getPasswordResetRequired);
  const getError = useSelector(getServerError);

  const [email, setEmail] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useTealiumView('Login Page');

  const error = () => {
    if (
      loginError === 'An account with this email does not exist.' ||
      loginError === 'Incorrect username or password.'
    )
      return loginError;
    if (getError)
      return 'Service is temporarily not available. Please try again later.';
    return undefined;
  };

  useEffect(() => {
    if (passwordResetRequired) {
      history.push({
        pathname: '/auth/complete-new-password',
        state: { email },
      });
    }
  }, [email, history, passwordResetRequired]);

  function handleForgotPassword() {
    history.push('/forgot-password');
  }

  function handleSubmit(user) {
    const updatedUser = {
      ...user,
      email: trim(user.email.toLowerCase()),
    };
    const values = { email, ...updatedUser };

    if (updatedUser.email) {
      setEmail(updatedUser.email);
    }

    setFormSubmitted(true);

    return dispatch(login(values));
  }

  useEffect(() => {
    if (formSubmitted && pendingVerification) {
      history.push({
        pathname: '/welcome',
        state: { email },
      });
    }
  }, [formSubmitted, pendingVerification, dispatch, history, email]);

  function renderForgotPasswordLabel() {
    return (
      <div
        role="button"
        tabIndex="0"
        style={styles.forgotPasswordButton}
        onClick={handleForgotPassword}
        onKeyPress={handleForgotPassword}
      >
        Forgot password?
      </div>
    );
  }

  return (
    <AuthPage
      light
      headerLink="/signup"
      headerLinkLabel="Sign up"
      headerLinkDescription="Don't have an account?"
    >
      <ManagedForm
        title="Log in to Ahab"
        submitButtonLabel="Log in"
        onSubmit={handleSubmit}
        validate={Validator.loginData}
        className="mt-48"
        style={styles.form}
        error={error()}
        FooterTemplateComponent={LoginFormFooterGrid}
      >
        <TextInput autoFocus label="Email" name="email" id="email" />
        <PasswordInput
          id="password"
          name="password"
          label="Password"
          RightLabelComponent={renderForgotPasswordLabel()}
        />
      </ManagedForm>
    </AuthPage>
  );
}

LoginPage.propTypes = {
  history: PropTypes.object,
};
