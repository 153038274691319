import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import without from 'lodash/without';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import { TagList } from '../TagList';
import SelectUI from './SelectUI';
import styles from './Select.module.scss';

const getLabel = selection => {
  const selectNumber = size(selection);

  if (isEmpty(selection)) {
    return '';
  }

  if (selectNumber === 1) {
    return selection[0].label;
  }

  return `${selectNumber} selected`;
};

function getInitialSelection(values, options) {
  return filter(options, option => includes(values, option.value));
}

function getSelection(selection, selected) {
  const values = map(selection, 'value');
  const containsSelected = includes(values, selected.value);

  if (containsSelected) {
    return reject(selection, { value: selected.value });
  }

  return [...selection, selected];
}

export default function MultiSelect({
  setFormValue,
  name,
  value: valueArray,
  options,
  collection,
  isAutoComplete,
  inputValue,
  showCaret,
  clearFilterPrefix,
  ...otherProps
}) {
  const [selection, setSelection] = useState(
    getInitialSelection(valueArray, options),
  );

  useEffect(() => {
    setSelection(getInitialSelection(valueArray, options));
  }, [valueArray]);

  function handleChange(event) {
    const selected = {
      value: get(event, 'target.value'),
      label: get(event, 'target.label'),
    };

    const resolvedSelection = getSelection(selection, selected);
    setSelection(resolvedSelection);

    if (isAutoComplete && clearFilterPrefix) {
      clearFilterPrefix();
    }

    if (setFormValue) {
      const newValues = map(resolvedSelection, 'value');
      setFormValue(name, newValues);
    }
  }

  function handleRemoveAll() {
    setSelection([]);

    if (setFormValue) {
      setFormValue(name, null);
    }
  }

  function handleRemoveTag(removedObject) {
    const newSelection = without(selection, removedObject);
    setSelection(newSelection);

    if (setFormValue) {
      const newValues = map(newSelection, 'value');
      setFormValue(name, newValues);
    }
  }

  const containerClasses = classNames(styles.container, {
    [styles['container--tag']]: !isEmpty(selection),
  });

  const displayValue = isAutoComplete ? inputValue : getLabel(selection);
  const values = map(selection, 'value');

  return (
    <>
      <SelectUI
        name={name}
        isMultiSelect
        value={values}
        onRemoveAll={handleRemoveAll}
        onChange={handleChange}
        displayValue={displayValue}
        containerClassName={containerClasses}
        options={options}
        showCaret={showCaret}
        // clearFilterPrefix={clearFilterPrefix}
        isAutoComplete={isAutoComplete}
        {...otherProps}
      />
      <TagList
        selection={selection}
        collection={collection}
        onRemoveClick={handleRemoveTag}
        className={styles.tagListContainer}
      />
    </>
  );
}

MultiSelect.propTypes = {
  ...SelectUI.propTypes,
  setFormValue: PropTypes.func,
  clearFilterPrefix: PropTypes.func,
  isAutoComplete: PropTypes.bool,
  showCaret: PropTypes.bool,
  inputValue: PropTypes.string,
};
