import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Button } from 'modules/ui';
import Dictionary from 'modules/dictionary';
import Offer from '../services/offer';
import classes from './OfferActionButtons.module.scss';

function OfferActionButtons({
  onRespondOpenClick,
  onDeclineOpenClick,
  offer,
  talentHasAgent,
}) {
  const lightActionButtonClassName = classNames(
    classes.actionButton,
    classes['actionButton--light'],
  );

  const negotiatingButtonClassName = classNames(
    classes.negotiatingButton,
    classes['actionButton--light'],
  );

  const offerStatus = get(offer, 'status');

  const {
    DECLINED,
    CANCELED,
    SENT,
    NEGOTIATING,
    ACCEPTED,
  } = Dictionary.OFFER_STATUSES;

  if (Offer.isOfferExpired(offer)) {
    return (
      <Button disabled className={lightActionButtonClassName}>
        {startCase(Offer.RESOLVED_STATUSES_TALENT.EXPIRED)}
      </Button>
    );
  }

  if (offerStatus === DECLINED || offerStatus === CANCELED) {
    return (
      <Button disabled className={lightActionButtonClassName}>
        {offerStatus === DECLINED
          ? startCase(Offer.RESOLVED_STATUSES_TALENT.DECLINED)
          : startCase(Offer.RESOLVED_STATUSES_TALENT.CLOSED)}
      </Button>
    );
  }
  if (offerStatus === SENT && !talentHasAgent) {
    return (
      <>
        <Button
          onClick={onDeclineOpenClick}
          className={lightActionButtonClassName}
        >
          Decline offer
        </Button>
        <Button
          primary
          onClick={onRespondOpenClick}
          className={classes.actionButton}
        >
          Respond to offer
        </Button>
      </>
    );
  }
  if ((offerStatus === SENT && talentHasAgent) || offerStatus === NEGOTIATING) {
    return (
      <>
        {!talentHasAgent && (
          <Button
            onClick={onDeclineOpenClick}
            className={lightActionButtonClassName}
          >
            Decline offer
          </Button>
        )}
        <Button disabled className={negotiatingButtonClassName}>
          Negotiation in progress
        </Button>
      </>
    );
  }
  if (offerStatus === ACCEPTED) {
    return (
      <Button primary disabled onClick={onRespondOpenClick}>
        {startCase(Offer.RESOLVED_STATUSES_TALENT.HIRED)}
      </Button>
    );
  }

  return null;
}

OfferActionButtons.propTypes = {
  offer: PropTypes.string.isRequired,
  talentHasAgent: PropTypes.bool.isRequired,
  onRespondOpenClick: PropTypes.func.isRequired,
  onDeclineOpenClick: PropTypes.func.isRequired,
};

export default OfferActionButtons;
