import get from 'lodash/get';
import Amplify, { Auth } from 'aws-amplify';
import AppConfig from 'modules/config';
import { ACCOUNT_LABELS } from 'modules/accounts';

const CUSTOM_ATTRIBUTES = {
  NEWSLETTER: 'custom:newsletterSignup',
  ACCOUNT_TYPE: 'custom:accountType',
  FULL_NAME: 'custom:fullName',
  FIRST_NAME: 'custom:firstName',
  LAST_NAME: 'custom:lastName',
  RECATPCHA_TOKEN: 'custom:recaptchaToken',
  RECATPCHA_TOKEN_2: 'custom:notes',
};

const CODES = {
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
  USER_NOT_FOUND: 'UserNotFoundException',
  USER_EXISTS: 'UsernameExistsException',
  CODE_MISMATCH: 'CodeMismatchException',
  CODE_EXPIRED: 'ExpiredCodeException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  INVALID_PARAMETER: 'InvalidParameterException',
  LAMBDA_VALIDATION_EXCEPTION: 'UserLambdaValidationException',
};

const ERROR_MESSAGES = {
  MAIL_NOT_VERIFIED:
    'Temporary password has expired and must be reset by an administrator.',
  CANNOT_RESET_PASSWORD_UNTIL_VERIFIED:
    'User password cannot be reset in the current state.',
};

function initialize() {
  const amplifyConfig = {
    Auth: {
      storage: localStorage,
      mandatorySignIn: true,
      region: AppConfig.cognito.region,
      userPoolId: AppConfig.cognito.userPoolId,
      userPoolWebClientId: AppConfig.cognito.appClientId,
    },
  };

  Amplify.configure(amplifyConfig);
}

function login(email, password) {
  return Auth.signIn(email, password);
}

function logout() {
  // https://github.com/aws-amplify/amplify-js/issues/3540
  return Auth.signOut({ global: true }).catch(() => Auth.signOut());
}

function signUp({
  email,
  password,
  firstName,
  lastName,
  accountType,
  newsletterSignup,
  recaptchaToken,
}) {
  // the reason fullName is still here is that Cognito doesn't allow removal of custom attributes
  // and we want to avoid having to delete all users :(
  const fullName = `${firstName} ${lastName}`;

  if (newsletterSignup) {
    global.utag.link({
      event_type: 'account_created',
      account_type: ACCOUNT_LABELS[accountType],
      nl_signup: 'yes',
    });
  } else {
    global.utag.link({
      event_type: 'account_created',
      account_type: ACCOUNT_LABELS[accountType],
      nl_signup: 'no',
    });
  }

  return Auth.signUp({
    username: email,
    password,
    attributes: {
      email,
      [CUSTOM_ATTRIBUTES.NEWSLETTER]: newsletterSignup.toString(),
      [CUSTOM_ATTRIBUTES.FIRST_NAME]: firstName,
      [CUSTOM_ATTRIBUTES.LAST_NAME]: lastName,
      [CUSTOM_ATTRIBUTES.FULL_NAME]: fullName,
      [CUSTOM_ATTRIBUTES.ACCOUNT_TYPE]: accountType,
      [CUSTOM_ATTRIBUTES.RECATPCHA_TOKEN]: '',
      [CUSTOM_ATTRIBUTES.RECATPCHA_TOKEN_2]: '',
    },
    validationData: [
      {
        Name: 'recaptchaToken',
        Value: recaptchaToken,
      },
    ],
  });
}

function resendVerificationEmail(email) {
  return Auth.resendSignUp(email);
}

function forgotPassword(email) {
  return Auth.forgotPassword(email);
}

async function changePassword(oldPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  return Auth.changePassword(user, oldPassword, newPassword);
}

function resetPassword(email, code, password) {
  return Auth.forgotPasswordSubmit(email, code, password);
}

function completeNewPassword(user, password, email) {
  return Auth.completeNewPassword(user, password, { email });
}

function confirmSignUpEmail(username, code) {
  return Auth.confirmSignUp(username, code);
}

async function isAuthenticated() {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });

    return !!user;
  } catch (error) {
    return false;
  }
}

async function getUserAttributes() {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  const attributes = {
    email: get(user.attributes, 'email'),
    fullName: get(user.attributes, CUSTOM_ATTRIBUTES.FULL_NAME),
    firstName: get(user.attributes, CUSTOM_ATTRIBUTES.FIRST_NAME),
    lastName: get(user.attributes, CUSTOM_ATTRIBUTES.LAST_NAME),
    accountType: get(user.attributes, CUSTOM_ATTRIBUTES.ACCOUNT_TYPE),
    newsletterSignup: get(user.attributes, CUSTOM_ATTRIBUTES.NEWSLETTER),
  };

  return attributes;
}

async function getToken() {
  try {
    const data = await Auth.currentSession();
    const accessToken = get(data, 'accessToken.jwtToken');

    return accessToken;
  } catch (error) {
    // only temporary to check why sometimes Bearer token is null
    console.error(error);
    return null;
  }
}

// TODO: After removing restrictions on email signups, the code that handles
// this error case can be removed
const userInvalidMessages = {
  'PreSignUp failed with error Signup source unauthorized.':
    'Your email is not on the approved Content Creator list. Please contact customerservice@ahabtalent.com for assistance.',
};

const cannotResetPasswordUntilAccountVerifiedMessage =
  'User password cannot be reset in the current state.';

function getCognitoErrorMessage(
  error,
  signUpForm = false,
  changePasswordForm = false,
) {
  const code = get(error, 'code');
  const message = get(error, 'message');

  switch (code) {
    case CODES.LAMBDA_VALIDATION_EXCEPTION: {
      const specialMessage = userInvalidMessages[message];
      return 'Your account cannot be created. Please email customerservice@ahabtalent.com for assistance.';
    }
    case CODES.USER_EXISTS: {
      if (signUpForm)
        return 'An account with this email already exists. Please use another email to create your Ahab account.';
      return 'An account with the given email already exists.';
    }
    case CODES.USER_NOT_FOUND: {
      return 'An account with this email does not exist.';
    }
    case CODES.CODE_EXPIRED: {
      return 'Expired link provided, please try again.';
    }
    case CODES.CODE_MISMATCH: {
      return 'Expired link provided, please try again.';
    }
    case CODES.NOT_AUTHORIZED: {
      if (message === cannotResetPasswordUntilAccountVerifiedMessage) {
        return 'Please use the temporary password provided in the email when logging in for the first time.';
      } else if (changePasswordForm) {
        return 'Incorrect old password.';
      }
    }
    default: {
      return message;
    }
  }
}

function currentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser();
}

async function isAuthenticatedWith({ username }) {
  const { username: cognitoUsername } = await currentAuthenticatedUser();
  return cognitoUsername === username;
}

export default {
  CODES,
  ERROR_MESSAGES,
  initialize,
  login,
  logout,
  signUp,
  changePassword,
  completeNewPassword,
  resendVerificationEmail,
  forgotPassword,
  resetPassword,
  confirmSignUpEmail,
  isAuthenticated,
  getUserAttributes,
  getToken,
  currentAuthenticatedUser,
  isAuthenticatedWith,
  getCognitoErrorMessage,
};
