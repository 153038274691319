import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { resolveRedirectionPath, getLocationFullPath } from '../services';
import { getIsAuthenticated } from '../redux';

function ProtectedRoute({ component, ...routeProps }) {
  const authenticated = useSelector(getIsAuthenticated);

  if (authenticated) {
    return <Route component={component} {...routeProps} />;
  }

  const redirection = getLocationFullPath();
  const path = resolveRedirectionPath('/login', redirection);

  return <Route {...routeProps} render={() => <Redirect to={path} />} />;
}

ProtectedRoute.propTypes = {
  ...Route.propTypes,
  authenticated: PropTypes.bool,
};

export default memo(ProtectedRoute);
