import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './TextButton.module.scss';

export default function TextButton({
  title,
  className,
  isPlainText,
  isInlineWithText,
  ...otherProps
}) {
  const btnClasses = classNames(className, {
    [styles.textButton]: !isPlainText,
    [styles.plainTextButton]: isPlainText,
    [styles.inlineWithText ]: isInlineWithText,
  });

  return (
    <div role="button" className={btnClasses} {...otherProps}>
      {title}
    </div>
  );
}

TextButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isPlainText: PropTypes.bool,
  isInlineWithText: PropTypes.bool,
};