import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// TODO: Import through a barrel file once circular dependencies resolved.
import withPopupWindow from 'modules/chat/components/ChatPopupWindow';
import { closeGlobalMessagingChatPopup } from 'modules/global-messages-threads';
import { Drawer } from 'modules/ui';
import { useWindowSize } from 'modules/core';

import { GlobalChatPopupOptions } from '../components';

import GlobalChatWindow from './GlobalChatWindow';
import classes from './GlobalChatPopup.module.scss';

const GlobalChatPopupWindow = withPopupWindow(GlobalChatWindow);

function GlobalChatPopup(props) {
  const { oppositeAccountId } = props;
  const dispatch = useDispatch();
  const { desktopWidth } = useWindowSize();

  function handleCloseDrawer() {
    dispatch(closeGlobalMessagingChatPopup(oppositeAccountId));
  }

  if (desktopWidth) {
    return (
      <GlobalChatPopupWindow
        {...props}
        options={<GlobalChatPopupOptions {...props} />}
      />
    );
  }

  return (
    <Drawer
      open
      onClose={handleCloseDrawer}
      drawerTitle="Chat"
      containerClassName={classes.drawerContainer}
    >
      <GlobalChatPopupWindow {...props} />
    </Drawer>
  );
}

GlobalChatPopup.propTypes = {
  oppositeAccountId: PropTypes.string.isRequired,
  oppositeAccountType: PropTypes.string.isRequired,
};

export default GlobalChatPopup;
