import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

export default function ContentCreatorApplicationGrid({ children }) {
  const items = Children.toArray(children);

  return (
    <Grid container spacing={0}>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          {items[0]}
        </Grid>
        <Grid item xs={6}>
          {items[1]}
        </Grid>
        <Grid item xs={6}>
          {items[2]}
        </Grid>
        <Grid item xs={6}>
          {items[3]}
        </Grid>
        <Grid item xs={6}>
          {items[4]}
        </Grid>
        <Grid item xs={6}>
          {items[5]}
        </Grid>
        <Grid item xs={6}>
          {items[6]}
        </Grid>
        <Grid item xs={6}>
          {items[7]}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {items[8]}
      </Grid>
      <Grid item xs={12}>
        {items[9]}
      </Grid>
      <Grid item xs={12}>
        {items[10]}
      </Grid>
      <Grid container item spacing={0}>
        <Grid item xs={6}>
          {items[11]}
        </Grid>
        <Grid item xs={6}>
          {items[12]}
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            {items[13]}
          </Grid>
          <Grid item xs={6}>
            {items[14]}
          </Grid>
          <Grid item xs={6}>
            {items[15]}
          </Grid>
          <Grid item xs={6}>
            {items[16]}
          </Grid>
          <Grid item xs={6}>
            {items[17]}
          </Grid>
          <Grid item xs={6}>
            {items[18]}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {items[19]}
      </Grid>
      <Grid item xs={12}>
        {items[20]}
      </Grid>
      <Grid item xs={12}>
        {items[21]}
      </Grid>
      <Grid item xs={12}>
        {items[22]}
      </Grid>
      <Grid item xs={12}>
        {items[23]}
      </Grid>
      <Grid item xs={12}>
        {items[24]}
      </Grid>
      <Grid item xs={12}>
        {items[25]}
      </Grid>
      <Grid item xs={12}>
        {items[26]}
      </Grid>
      <Grid item xs={12}>
        {items[27]}
      </Grid>
      <Grid item xs={12}>
        {items[28]}
      </Grid>
      <Grid item xs={12}>
        {items[29]}
      </Grid>
      <Grid item xs={12}>
        {items[30]}
      </Grid>
      <Grid item xs={12}>
        {items[31]}
      </Grid>
    </Grid>
  );
}

ContentCreatorApplicationGrid.propTypes = {
  children: PropTypes.node,
};
