import concat from 'lodash/concat';

export const ALLOWED_FORMATS = {
  video: ['video/mp4'],
  audio: ['audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/x-wav'],
  document: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
  ],
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  get media() {
    return concat(this.video, this.audio, this.image, this.document);
  },
};

export const ALLOWED_EXTENSIONS = {
  video: ['mp4'],
  audio: ['mp3', 'wav'],
  document: ['pdf', 'txt', 'doc', 'docx'],
  image: ['jpeg', 'jpg', 'png'],
  get media() {
    return concat(this.video, this.audio, this.image, this.document);
  },
};

export const MAX_FILE_SIZE = {
  default: 10000000, // 10 mb
  media: 10000000, // 10 mb
  document: 50000000, // 50 mb
  audio: 15000000, // 15 mb
  image: 10000000, // 10 mb
  script: 20000000, // 20 mb
};
