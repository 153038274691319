import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import {
  useWindowSize,
  useRouteListener,
  useScrollRestoration,
  MOBILE_WIDTH,
} from 'modules/core';
import {
  Page,
  PageLoader,
  PageHeader,
  Select,
  Sort,
  ListSelect,
  useForm,
  PageHeaderTitle,
} from 'modules/ui';
import { useTealiumView, AccountType, getAccountType } from 'modules/accounts';
import { getAgent, InviteClientDialog } from 'modules/agents';
import { searchTalents, getSearchedTalents, Talent } from 'modules/talents';
import {
  getOffers,
  getOffersFilterData,
  getOffersSort,
  loadOffers,
  setOffersFilterData,
  setOffersSort,
} from '../redux';
import { OffersTable, OffersCardsList } from '../components';
import Offers from '../services/offers';
import classes from './OffersPage.module.scss';

export const OFFERS_MOBILE_HEADER_OFFSET = 20;
export const OFFERS_REGEX = RegExp('/offers/(\\d+)');

export default function OffersPage() {
  const dispatch = useDispatch();
  const { desktopWidth, initialWindowWidth } = useWindowSize();
  const offersFilterData = useSelector(getOffersFilterData);
  const initialRowsPerPage = initialWindowWidth <= MOBILE_WIDTH ? 6 : 10;

  const accountType = useSelector(getAccountType);
  const agent = useSelector(getAgent);
  const agentId = get(agent, 'id');
  const talents = useSelector(getSearchedTalents);

  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState(
    get(offersFilterData, 'filterValue') || null,
  );
  const [page, setPage] = useState(get(offersFilterData, 'page') || 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    get(offersFilterData, 'rowsPerPage') || initialRowsPerPage,
  );
  const [scrollY] = useState(get(offersFilterData, 'scrollY'));
  const [talentLoading, setTalentLoading] = useState(false);
  const [showInviteClientModal, setShowInviteClientModal] = useState(false);

  const { values, setValue } = useForm();
  const searchedTalent = get(values, 'search');
  const searchedTalentName = get(searchedTalent, 'fullName');

  const offers = useSelector(getOffers);
  const sort = useSelector(getOffersSort);
  const sortObj = Sort.toObject(sort);

  const filteredOffers = Offers.filterByStatus(offers, filterValue, true);
  const sortedOffers = Offers.applySortOptions(
    filteredOffers,
    sortObj.option,
    sortObj.order,
  );
  const agentOffers = filter(sortedOffers, {
    talent: { fullName: searchedTalentName },
  });

  function resolveHeaderDescription() {
    if (AccountType.isTalent(accountType)) {
      return 'Manage all of your Offers in one place.';
    }
    return 'Manage all of your clients’ Offers in one place.';
  }

  function resolvedOffers() {
    if (searchedTalentName) {
      return agentOffers;
    }
    return sortedOffers;
  }

  const offerStatusOptions = Offers.getTalentStatusOptions();
  const activeFilterOptions = [
    Offers.ALL_OFFERS.value,
    ...Offers.getUniqueStatuses(offers),
  ];

  useTealiumView('Offers Page');

  useEffect(() => {
    if (agentId) {
      dispatch(searchTalents({ agentId, limit: 10, offset: 0 }));
    }
    dispatch(loadOffers(undefined, accountType)).then(() => setLoading(false));
  }, [dispatch, accountType, agentId]);

  useRouteListener(
    OFFERS_REGEX,
    offerId => {
      dispatch(
        setOffersFilterData({
          offerId,
          filterValue,
          page,
          rowsPerPage,
          scrollY: window.scrollY,
        }),
      );
    },
    [filterValue, page, rowsPerPage],
  );

  useScrollRestoration(scrollY, [filterValue, page, rowsPerPage]);

  function handleFilterValueChange(event) {
    setFilterValue(event.target.value);
    setPage(0);
  }

  function handleSortClick(newSort) {
    dispatch(setOffersSort(newSort));
  }

  function handlePageChange(event, newPage) {
    setPage(newPage);
  }

  function handleRowsPerPageChange(newRowsPerPage) {
    setPage(0);
    setRowsPerPage(newRowsPerPage);
  }

  function dispatchSearchTalents(data) {
    setTalentLoading(true);
    return dispatch(searchTalents(data)).then(() => setTalentLoading(false));
  }

  const handleSearchTalents = useCallback(
    debounce(dispatchSearchTalents, 500, { leading: false, trailing: true }),
    [],
  );

  function handleSearchInputChange(value) {
    const searchData = {
      sort: 'lastName',
      query: value,
      agentId,
    };

    if (value.length > 2) {
      return handleSearchTalents(searchData);
    }
    return null;
  }

  function generateSelectionString(selection) {
    return map(selection, 'label');
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Page
      className={classes.offersPage}
      HeaderComponent={
        desktopWidth && (
          <PageHeader
            LeftComponent={
              <PageHeaderTitle
                title="Offers"
                description={resolveHeaderDescription()}
              />
            }
          />
        )
      }
    >
      <div className={classes.container}>
        <div className={classes.searchContainer}>
          {AccountType.isAgent(accountType) && (
            <ListSelect
              setFormValue={setValue}
              options={Talent.createOptions(talents)}
              isMultiSelect={false}
              loading={talentLoading}
              value={searchedTalent || null}
              name="search"
              theme="light"
              onChange={handleSearchInputChange}
              placeholder="Search Clients"
              noResultsText="No Clients Found"
              generateSelectionString={generateSelectionString}
            />
          )}
        </div>
        <div className={classes.filterContainer}>
          Filters
          <Select
            name="offerStatus"
            value={filterValue}
            options={offerStatusOptions}
            onChange={handleFilterValueChange}
            placeholder="Offer Status"
            activeOptions={activeFilterOptions}
            lightInput
            required
            menuStyle
            containerClassName={classes.filter}
            isInModal
          />
        </div>
      </div>
      {desktopWidth ? (
        <OffersTable
          offers={resolvedOffers()}
          hasNoOffers={isEmpty(offers)}
          searchedTalent={searchedTalent}
          sort={sort}
          onSortClick={handleSortClick}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          defaultPage={page}
          defaultRowsPerPage={rowsPerPage}
          accountType={accountType}
          openInviteClient={() => setShowInviteClientModal(true)}
        />
      ) : (
        <OffersCardsList
          offers={resolvedOffers()}
          hasNoOffers={isEmpty(offers)}
          searchedTalent={searchedTalent}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPage={rowsPerPage}
          page={page}
          accountType={accountType}
          openInviteClient={() => setShowInviteClientModal(true)}
          pageChangeScrollY={OFFERS_MOBILE_HEADER_OFFSET}
        />
      )}
      {showInviteClientModal && (
        <InviteClientDialog
          onClose={() => setShowInviteClientModal(false)}
          onCancel={() => setShowInviteClientModal(false)}
        />
      )}
    </Page>
  );
}
