import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MessageGroup from './MessageGroup';
import MessageAvatar from './MessageAvatar';
import MessageList from './MessageList';
import ReceivedMessage from './ReceivedMessage';

import classes from './MessageGroupLeft.module.scss';

function MessageGroupLeft({ messages, photoUrl, displayTimestamps }) {
  return (
    <MessageGroup className={classes.messageGroupLeft}>
      <MessageAvatar photoUrl={photoUrl} />
      <MessageList className={classes.messageListLeft}>
        {messages.map(message => {
          const messageId = get(message, 'id');
          return (
            <ReceivedMessage
              key={messageId}
              message={message}
              displayTimestamps={displayTimestamps}
            />
          );
        })}
      </MessageList>
    </MessageGroup>
  );
}

MessageGroupLeft.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  photoUrl: PropTypes.string,
  displayTimestamps: PropTypes.bool,
};

export default MessageGroupLeft;
