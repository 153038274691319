import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './MessageGroup.module.scss';

function MessageGroup({ children, className }) {
  const messageClass = classNames(classes.messageGroup, className);
  return <div className={messageClass}>{children}</div>;
}

MessageGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export default MessageGroup;
