import { createReducer } from 'redux-starter-kit';
import { resolveErrorMessage } from 'modules/errors';
import {
  loadAudition,
  loadAuditions,
  loadAllAuditions,
  createOrUpdateAudition,
  removeAudition,
  removeAuditionAction,
  removeErrorsAction,
} from './actions';

const INITIAL_STATE = {
  auditionsLoading: false,
  loading: false,
  deleting: false,
  error: false,
};

export const reducer = createReducer(INITIAL_STATE, {
  [loadAudition.type.request]: state => {
    state.loading = true;
  },
  [loadAudition.type.success]: state => {
    state.loading = false;
  },
  [loadAudition.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
    state.loading = false;
  },
  [createOrUpdateAudition.type.success]: state => {
    state.error = false;
  },
  [createOrUpdateAudition.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [removeAudition.type.request]: state => {
    state.deleting = true;
  },
  [removeAudition.type.success]: state => {
    state.deleting = false;
  },
  [removeAudition.type.error]: state => {
    state.deleting = false;
  },
  [loadAuditions.type.request]: state => {
    state.auditionsLoading = true;
  },
  [loadAuditions.type.success]: state => {
    state.auditionsLoading = false;
  },
  [loadAuditions.type.error]: state => {
    state.auditionsLoading = false;
  },
  [loadAllAuditions.type.request]: state => {
    state.auditionsLoading = true;
  },
  [loadAuditions.type.success]: state => {
    state.auditionsLoading = false;
  },
  [loadAuditions.type.error]: state => {
    state.auditionsLoading = false;
  },
  [removeAuditionAction]: state => {
    state.audition = null;
  },
  [removeErrorsAction]: state => {
    state.error = null;
  },
});
