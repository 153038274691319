import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Page, PageLoader } from 'modules/ui';
import { useBackBtnListener } from 'modules/core';
import { AccountType, getAccountType, ACCOUNT_TYPES } from 'modules/accounts';
import { AgentDetails, AgentPreviewHeader } from '../components';
import { getAgent, loadAgent, clearAgentsData, getAgentsLoading } from '../redux';

export default function AgentPreviewPage({ match }) {
  const dispatch = useDispatch();
  const agentId = get(match, 'params.agentId');
  const accType = useSelector(getAccountType);

  const agent = useSelector(getAgent);
  const agentLoading = useSelector(getAgentsLoading)

  useEffect(() => {
    dispatch(loadAgent(agentId));
  }, [dispatch, agentId]);

  function setTalentViewing() {
    if (AccountType.isTalent(accType)) {
      return ACCOUNT_TYPES.TALENT;
    }
    return ACCOUNT_TYPES.PRODUCER;
  }

  useBackBtnListener(() => dispatch(clearAgentsData()));

  if (!agent) {
    return <PageLoader />;
  }

  return (
    <Page HeaderComponent={<AgentPreviewHeader agent={agent} isAgentLoading={agentLoading} />}>
      <AgentDetails agent={agent} isAgentLoading={agentLoading} viewerAccountType={setTalentViewing()} />
    </Page>
  );
}
AgentPreviewPage.propTypes = {
  match: PropTypes.object.isRequired,
};
