import { createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import { fetchAuthCodeValidity } from './api';

const INITIAL_STATE = {
  authCodeValid: false,
};

export const validateAuthCode = createThunk(
  fetchAuthCodeValidity,
  'validate',
  'authCode',
);

export const reducer = createReducer(INITIAL_STATE, {
  [validateAuthCode.type.success]: state => {
    state.authCodeValid = true;
  },
  [validateAuthCode.type.error]: state => {
    state.authCodeValid = false;
  },
});

export const getAuthCodeValidity = createSelector(['sharing.authCodeValid']);
