import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { showNotification, Notification } from 'modules/notifications';
import { ConfirmationDialog } from 'modules/ui';
import { default as Account } from 'modules/accounts/services/account';
import { getServerError } from 'modules/core';
import { getIsAuthenticated } from 'modules/auth';
import signUpToNewsletter from '../api';
import styles from './SubscribeButton.module.scss';

export default function SubscribeButton() {
  const dispatch = useDispatch();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const account = useSelector(Account.getAccount);
  const serverError = useSelector(getServerError);
  const authenticated = useSelector(getIsAuthenticated);

  function handleNewsletterConfirm() {
    signUpToNewsletter(account).then(() =>
      dispatch(
        showNotification({
          message: 'You are now subscribed to the Ahab newsletter!',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );

    setShowConfirmation(false);
  }

  function handleNewsletterClick() {
    setShowConfirmation(true);
  }

  const hideButton = serverError || !authenticated;

  if (hideButton) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className={styles.button}
        onClick={handleNewsletterClick}
      >
        Subscribe To Our Newsletter
      </button>
      {showConfirmation && (
        <ConfirmationDialog
          withOverlay
          title="Stay informed!"
          message="Sign up for news and other communications from Ahab."
          actionBtnLabel="Sign me up"
          cancelBtnLabel="Cancel"
          onCancel={() => setShowConfirmation(false)}
          onAction={handleNewsletterConfirm}
        />
      )}
    </>
  );
}
