import { Api } from 'modules/core';

export function suggestAudition(auditionId, talentId, note) {
  const endpoint = 'audition-suggestions';
  const body = {
    data: {
      type: 'audition-suggestions',
      attributes: { auditionId, talentId, note },
    },
  };

  return Api.createRaw(endpoint, body);
}
