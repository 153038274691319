import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import { ManagedForm, Page, PasswordInput, TextInput } from 'modules/ui';
import { AuthHeader } from '../components';
import { Validator } from '../services';
import { AUTH_CODES } from '../const';
import {
  clearResetPasswordStatus,
  getResetPasswordError,
  getResetPasswordSuccess,
  resetPassword,
} from '../redux';

const styles = {
  container: {
    flexBasis: 360,
  },
  changeButton: {
    width: '100%',
  },
  footer: {
    marginTop: 3,
  },
};

export default function ResetPasswordPage({ location, history }) {
  const dispatch = useDispatch();
  const resetPasswordError = useSelector(getResetPasswordError);
  const resetPasswordSuccess = useSelector(getResetPasswordSuccess);

  const urlParams = new URLSearchParams(location.search);
  const email = urlParams.get('email');

  useEffect(() => {
    if (resetPasswordSuccess) {
      return history.push({
        pathname: '/success',
        state: {
          title: 'Password changed',
          description: `Your password has been changed. Please login with your new password.`,
        },
      });
    }
  }, [resetPasswordSuccess, history]);

  useEffect(() => () => dispatch(clearResetPasswordStatus()), []);

  const handleResetPassword = ({ code, password }) => {
    const trimmedCode = trim(code);
    return dispatch(resetPassword(email, trimmedCode, password));
  };

  return (
    <Page
      className="pt-64"
      HeaderComponent={
        <AuthHeader
          showLogo={false}
          link="/login"
          linkLabel={`Return to ${window.location.hostname}`}
        />
      }
    >
      <ManagedForm
        title="Add new password"
        submitButtonLabel="Change"
        btnStyle={styles.changeButton}
        onSubmit={handleResetPassword}
        validate={Validator.resetPasswordData}
        footerStyle={styles.footer}
        error={resetPasswordError}
        style={styles.container}
        className="mt-48"
      >
        <TextInput
          required
          label="Code"
          name="code"
          placeholder="Enter code provided in email"
        />
        <PasswordInput
          label="New password"
          name="password"
          placeholder="Enter password"
        />
        <PasswordInput
          label="Confirm password"
          name="confirmPassword"
          placeholder="Enter password"
        />
      </ManagedForm>
    </Page>
  );
}

ResetPasswordPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};
