import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { Api } from 'modules/core';
import { AuditionForm } from './services';

const auditionsEndpoint = producerId => {
  return `producers/${producerId}/auditions`;
};

const RELATIONSHIP_PATHS = [
  'requiredVoiceAgeRange',
  'requiredRecordingExperience',
  'producer',
  'projectRole',
  'nonDisclosureAgreement',
];

const INCLUDE = 'producer,projectRole.project,nonDisclosureAgreement';

const getAuditionRelationships = (producerId, roleId, values) => {
  const requiredVoiceAgeRangesIds = get(values, 'requiredVoiceAgeRanges');
  const requiredRecordingExperiencesIds = get(
    values,
    'requiredRecordingExperiences',
  );
  const ndaId = get(values, 'ndaId');

  const relationships = {
    requiredVoiceAgeRange: {
      data: { type: 'voice-age-ranges', id: requiredVoiceAgeRangesIds },
    },
    requiredRecordingExperience: {
      data: {
        type: 'recording-experiences',
        id: requiredRecordingExperiencesIds,
      },
    },
    producer: {
      data: {
        type: 'producers',
        id: producerId,
      },
    },
    projectRole: {
      data: {
        type: 'project-roles',
        id: roleId,
      },
    },
    nonDisclosureAgreement: {
      data: {
        type: 'ndas',
        id: ndaId,
      },
    },
  };

  return omitBy(relationships, relationship => isEmpty(relationship.data.id));
};

export function fetchAudition(producerId, auditionId) {
  const endpoint = `${auditionsEndpoint(producerId)}/${auditionId}`;
  const params = { include: INCLUDE };

  if (!producerId || !auditionId) {
    return Promise.resolve(null);
  }

  return Api.find(endpoint, params);
}

export function fetchAuditions(producerId, filter) {
  if (!producerId) {
    return Promise.resolve(null);
  }

  const endpoint = `${auditionsEndpoint(producerId)}`;
  const params = { include: INCLUDE, filter };

  return Api.find(endpoint, params);
}

export function fetchAllAuditions(producerId) {
  if (!producerId) {
    return Promise.resolve();
  }

  const endpoint = `${auditionsEndpoint(producerId)}/all`;
  const params = { include: INCLUDE };

  return Api.find(endpoint, params);
}

export function deleteAudition(producerId, auditionId) {
  if (!producerId || !auditionId) {
    return Promise.resolve(null);
  }

  const endpoint = `${auditionsEndpoint(producerId)}/${auditionId}`;

  return Api.deleteResource(endpoint);
}

export function postAudition(producerId, roleId, values) {
  const newValues = AuditionForm.resolveStudioAddressField(values);
  const updateValues = omit(newValues, RELATIONSHIP_PATHS);
  const endpoint = auditionsEndpoint(producerId);
  const audition = {
    resourceType: 'auditions',
    hasStarted: true,
    ...updateValues,
  };

  const relationships = getAuditionRelationships(producerId, roleId, newValues);
  const params = { include: INCLUDE };

  return Api.create(endpoint, audition, relationships, params);
}

export function patchAudition(producerId, roleId, values) {
  const newValues = AuditionForm.resolveStudioAddressField(values);
  const verifiedRecordingLengthValues = AuditionForm.resolveRecordingDuration(
    newValues,
  );

  const { id } = verifiedRecordingLengthValues;

  const relationships = getAuditionRelationships(
    producerId,
    roleId,
    verifiedRecordingLengthValues,
  );
  const endpoint = `${auditionsEndpoint(producerId)}/${id}`;

  const updateValues = omit(verifiedRecordingLengthValues, RELATIONSHIP_PATHS);
  const params = { include: INCLUDE };

  return Api.update(endpoint, updateValues, 'auditions', relationships, params);
}
