import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import some from 'lodash/some';
import debounce from 'lodash/debounce';
import {
  FormDialog,
  Select,
  Textarea,
  ListSelect,
  ManagedForm,
} from 'modules/ui';
import { Colors } from 'modules/theme';
import { getSearchedTalents, searchTalents, Talent } from 'modules/talents';
import { showNotification, Notification } from 'modules/notifications';
import { AudioOptionUI } from 'modules/audio';
import Dictionary from 'modules/dictionary';
import {
  getTopCandidatesError,
  addTopCandidate,
  removeTopCandidatesError,
  updateTopCandidate,
  getTopCandidatesByRoleId,
} from 'modules/top-candidates';
import { Validator } from '../services';
import NavigateToTalentSearchFooterOption from './NavigateToTalentSearchFooterOption';

const styles = {
  container: {
    height: 654,
  },
  title: {
    marginBottom: 0,
  },
  noteDescription: {
    color: Colors.grayishBlue,
    marginBottom: '20px',
    marginTop: '-30px',
    textAlign: 'justify',
  },
};

function AddTopCandidateDialog({
  producerId,
  projectId,
  role,
  onCancel,
  onClose,
  onAdd,
}) {
  const dispatch = useDispatch();
  const initialValues = {
    talentId: null,
    sampleId: null,
    note: null,
  };

  const [loading, setLoading] = useState(false);
  const [sampleOptions, setSampleOptions] = useState(null);
  const [values, setValues] = useState(initialValues);
  const [maxCharsError, setMaxCharsError] = useState(false);

  const error = useSelector(getTopCandidatesError);

  const roleId = get(role, 'id');
  const topCandidates = useSelector(getTopCandidatesByRoleId(roleId));

  const talents = filter(
    useSelector(getSearchedTalents),
    talent => !some(topCandidates, { talentId: talent.id }),
  );

  useEffect(() => {
    dispatch(removeTopCandidatesError);
  }, [dispatch]);

  function dispatchSearchTalents(data) {
    setLoading(true);
    return dispatch(searchTalents(data)).then(() => setLoading(false));
  }

  const handleSearchTalents = useCallback(
    debounce(dispatchSearchTalents, 500, { leading: false, trailing: true }),
    [],
  );

  function handleTalentChange(searchValue) {
    const searchData = {
      sort: 'lastName',
      query: searchValue,
    };

    return handleSearchTalents(searchData);
  }

  function handleSetSampleOptions(talentId) {
    const talent = find(talents, { id: talentId });

    if (talent) {
      setSampleOptions(
        map(talent.audioSamples, sample => {
          return {
            value: sample.id,
            label: {
              name: sample.name,
              url: sample.url,
              vertical: sample.vertical,
              genre: sample.genre,
            },
          };
        }),
      );
    }
  }

  function handleValueChange(formValues) {
    const talent = formValues.talentId;

    if (!isEmpty(talent)) {
      handleSetSampleOptions(talent.value);

      setValues({ ...formValues, talentId: talent.value });
    }
    if (isEmpty(formValues.talentId) && !isEmpty(sampleOptions)) {
      setSampleOptions(null);
    }
  }

  function handleResponse(response) {
    if (!response) {
      return;
    }

    if (onAdd) {
      onAdd();
    }

    dispatch(
      showNotification({
        message: 'Talent added to Top Candidates!',
        type: Notification.TYPES.SUCCESS,
      }),
    );
    onClose();
  }

  function handleSubmit() {
    const { talentId, note, sampleId } = values;

    const existingTopCandidate = find(topCandidates, { talentId });

    if (existingTopCandidate) {
      return dispatch(
        updateTopCandidate(
          producerId,
          projectId,
          roleId,
          existingTopCandidate.id,
          null,
          sampleId,
          note,
          Dictionary.TOP_CANDIDATE_SOURCE.PROFILE,
        ),
      ).then(response => handleResponse(response));
    }

    return dispatch(
      addTopCandidate(
        producerId,
        projectId,
        role.id,
        talentId,
        null,
        sampleId,
        note,
        Dictionary.TOP_CANDIDATE_SOURCE.PROFILE,
      ),
    ).then(response => handleResponse(response));
  }

  function generateSelectionString(selection) {
    return map(selection, 'label');
  }

  function displaySelectedSample(sample) {
    return sample ? `${sample.name}` : '';
  }

  return (
    <FormDialog
      title="Add Talent to Top Candidates"
      subtitleText="This Talent will be added to your Top Candidates for this Role."
      dialogMessage="This Talent will not be added to Top Candidates."
      onClose={onCancel}
      containerStyle={styles.container}
      titleStyle={styles.title}
      renderFooterInfo={NavigateToTalentSearchFooterOption}
    >
      <ManagedForm
        error={error}
        initialValues={values}
        onChangeValues={handleValueChange}
        validate={Validator.validateAddTopCandidate}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitButtonLabel="Add"
      >
        <ListSelect
          required
          options={Talent.createOptions(talents)}
          loading={loading}
          name="talentId"
          label="Talent's name"
          theme="light"
          onChange={handleTalentChange}
          placeholder="Write name"
          noResultsText="No talents found"
          generateSelectionString={generateSelectionString}
        />
        <Select
          required
          name="sampleId"
          label="Sample"
          placeholder="Choose a sample"
          options={sampleOptions}
          customComponent={
            <AudioOptionUI detailsPaths={['genre', 'vertical']} />
          }
          changeDisplayValue={displaySelectedSample}
        />
        <Textarea
          label="Sample notes"
          name="note"
          maxChars={90}
          placeholder="Add notes about this sample"
          handleMaxChars={isMaxChars => setMaxCharsError(isMaxChars)}
        />
        {!maxCharsError && (
          <div style={styles.noteDescription}>
            Notes will be included if you share Top Candidates.
          </div>
        )}
      </ManagedForm>
    </FormDialog>
  );
}

AddTopCandidateDialog.propTypes = {
  producerId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
};

export default withRouter(AddTopCandidateDialog);
