import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import trim from 'lodash/trim';
import remove from 'lodash/remove';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import { LodashUtil, Utils } from 'modules/core';
import { Dialog, TextInput, OptionUI } from 'modules/ui';
import {
  getAccountType,
  getAccountIdByType,
  AccountType,
} from 'modules/accounts';
import {
  createList,
  loadLists,
  Lists,
  addTalentToList,
  removeTalentFromList,
} from 'modules/lists';
import { Colors } from 'modules/theme';
import { getListsByAccountId } from '../redux';

const styles = {
  dialogContainer: { height: 534 },
  optionsContainer: {
    border: '1px solid rgba(5, 21, 40, 0.07)',
    borderRadius: 8,
    height: 384,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

const ENTER_KEY = 'Enter';

function resolveSelectedListIds(lists, talentId) {
  return map(
    filter(lists, list => includes(list.talentsIds, talentId)),
    list => list.id,
  );
}

function resolveDialogTitle(lists, listMode, accountType) {
  if (listMode) {
    if (AccountType.isProducer(accountType)) {
      return 'Add talent to list';
    }
    return 'Add client to list';
  }

  if (isEmpty(lists)) {
    return 'Create your first list';
  }

  return 'Create new list';
}

export default function AddTalentDialog({ talentId, onCancel }) {
  const dispatch = useDispatch();

  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const accountType = useSelector(getAccountType);
  const accountId = useSelector(getAccountIdByType);

  const lists = useSelector(getListsByAccountId(accountId, accountType));

  const initialSelectedIds = resolveSelectedListIds(lists, talentId);
  const [selectedListIds, setSelectedListIds] = useState(initialSelectedIds);
  const [listMode, setListMode] = useState(!isEmpty(lists));
  const [listsLoading, setListsLoading] = useState(true);

  useEffect(() => {
    setListsLoading(true);

    dispatch(loadLists(accountId, accountType)).then(() =>
      setListsLoading(false),
    );
  }, [dispatch, accountId, accountType]);

  useEffect(() => {
    setListMode(!isEmpty(lists));
  }, [lists]);

  function handleOnCancel() {
    if (onCancel) {
      onCancel();
    }
  }

  function handleOptionChange(listId, selected) {
    if (selected) {
      return setSelectedListIds([listId, ...selectedListIds]);
    }

    const newSelectedIds = [...selectedListIds];
    remove(newSelectedIds, id => id === listId);
    return setSelectedListIds(newSelectedIds);
  }

  function handleCreateList() {
    setInProgress(true);
    dispatch(createList(accountId, trim(name), accountType)).then(payload => {
      const listId = get(payload, 'data.id');
      if (listId) {
        handleOptionChange(listId, true);
      }

      setInProgress(false);
      setListMode(true);
      setName(null);
    });
  }

  function handleTalentListsUpdate() {
    setInProgress(true);
    const addedIds = difference(selectedListIds, initialSelectedIds);
    const removedIds = difference(initialSelectedIds, selectedListIds);

    const promises = [];
    map(removedIds, listId =>
      promises.push(
        dispatch(
          removeTalentFromList(accountId, listId, talentId, accountType),
        ),
      ),
    );
    map(addedIds, listId =>
      promises.push(
        dispatch(addTalentToList(accountId, listId, talentId, accountType)),
      ),
    );

    return Promise.all(promises)
      .then(() => handleOnCancel())
      .then(() => setInProgress(false));
  }

  function handleNameChange(event) {
    const value = event.target.value.trimStart();
    setName(value);
    const errorMessage = Lists.validateName(value.trimEnd(), null, lists);

    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    setError(null);
  }

  function handleAction() {
    if (listMode) {
      return handleTalentListsUpdate();
    }

    return handleCreateList();
  }

  function handleSecondaryAction() {
    if (isEmpty(lists)) {
      handleOnCancel();
    }

    setListMode(!listMode);
  }

  function handleKeyPressed(event) {
    if (event.key === ENTER_KEY && !error) {
      handleAction();
    }
  }

  function isActionDisabled() {
    if (listMode) {
      return isEmpty(
        LodashUtil.symmetricDifference(selectedListIds, initialSelectedIds),
      );
    }

    return error !== null || isEmpty(name);
  }

  function renderOption(list, selected) {
    const { id: value, name: optionName } = list;

    return (
      <OptionUI
        key={`${optionName}-${value}`}
        label={optionName}
        value={value}
        isCheckbox
        selected={selected}
        onClick={() => handleOptionChange(value, !selected)}
      />
    );
  }

  function renderOptions() {
    const selectedLists = LodashUtil.applyIdOrder(
      filter(lists, list => includes(selectedListIds, list.id)),
      selectedListIds,
    );
    const notSelectedLists = filter(
      lists,
      list => !includes(selectedListIds, list.id),
    );

    return (
      <div style={styles.optionsContainer} className="scrollable">
        {map(selectedLists, list => renderOption(list, true))}
        {!isEmpty(selectedLists) && <div className="divider" />}
        {map(notSelectedLists, list => renderOption(list, false))}
      </div>
    );
  }

  function renderInput() {
    return (
      <TextInput
        autoFocus
        label="List name"
        placeholder="E.g. Cool voices"
        onKeyPress={event => handleKeyPressed(event)}
        error={error}
        onChange={handleNameChange}
        value={name}
        autoComplete={Utils.PREVENT_AUTOCOMPLETE_IN_MODAL}
      />
    );
  }

  return (
    <Dialog
      title={!listsLoading && resolveDialogTitle(lists, listMode)}
      onAction={!listsLoading && handleAction}
      onCancel={!listsLoading && handleOnCancel}
      onSecondaryAction={handleSecondaryAction}
      actionBtnLabel={listMode ? 'Save' : 'Create'}
      secondaryActionBtnLabel={listMode && 'Create new list'}
      containerStyle={styles.dialogContainer}
      actionDisabled={isActionDisabled()}
      inProgress={inProgress}
    >
      {listsLoading ? (
        <div style={styles.loader}>
          <Loader
            style={styles.loader}
            type="Oval"
            color={Colors.darkBlue}
            width="40"
            height="40"
          />
        </div>
      ) : listMode ? (
        renderOptions()
      ) : (
        renderInput()
      )}
    </Dialog>
  );
}

AddTalentDialog.propTypes = {
  talentId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
};
