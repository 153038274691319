import React from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toArray from 'lodash/toArray';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Colors } from 'modules/theme';
import { IconButton } from 'modules/ui';
import styles from './TagList.module.scss';

export default function TagList({
  selection,
  collection,
  onRemoveClick,
  className,
}) {
  const hasValue = !isEmpty(selection);

  if (!hasValue) {
    return null;
  }

  const containerClasses = classNames(
    styles.container,
    hasValue && styles['container--margin'],
    className,
  );

  const sortedSelection = collection
    ? sortBy(selection, item => toArray(collection).indexOf(item.value))
    : selection;

  return (
    <div className={containerClasses}>
      {map(sortedSelection, selected => {
        return (
          <div key={selected.value} className={styles.label}>
            {selected.label}
            <IconButton
              small
              className={styles.button}
              iconName="close"
              iconSize={12}
              iconColor={Colors.grayishBlue}
              onClick={() => onRemoveClick(selected)}
            />
          </div>
        );
      })}
    </div>
  );
}

TagList.propTypes = {
  selection: PropTypes.array,
  onRemoveClick: PropTypes.func,
  className: PropTypes.string,
  collection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
