import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Colors } from 'modules/theme';
import { IconButton } from '../IconButton';
import { TextInput } from '../TextInput';
import classes from './PasswordInput.module.scss';

const styles = {
  passwordRequirement: {
    color: Colors.grayishBlue,
    marginBottom: '20px',
    marginTop: '-30px',
    textAlign: 'justify',
  },
};

function PasswordInput({ disabled, error, showHint, ...otherProps }) {
  const [visible, setVisible] = useState(false);

  const inputRef = useRef(null);

  function handleToggle() {
    inputRef.current.focus();
    setVisible(!visible);
  }

  const type = visible ? 'text' : 'password';
  const iconName = visible ? 'eye' : 'eye_blocked';

  return (
    <>
      <TextInput
        disabled={disabled}
        type={type}
        setRef={inputRef}
        RightIconComponent={
          <IconButton
            iconName={iconName}
            iconColor={Colors.grayishBlue}
            onClick={handleToggle}
            className={classNames(classes.icon, classes['icon--right'])}
            disabled={disabled}
          />
        }
        error={error}
        {...otherProps}
      />
      {showHint && !error && (
        <div style={styles.passwordRequirement}>
          Password must contain at least one small-case letter, one capital
          letter, one number, one special character and be at least 8 characters
          long
        </div>
      )}
    </>
  );
}

PasswordInput.propTypes = {
  ...TextInput.propTypes,
  showHint: PropTypes.bool,
};

PasswordInput.defaultProps = {
  showHint: false,
};

export default memo(PasswordInput);
