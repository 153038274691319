import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'modules/ui';
import { Colors } from 'modules/theme';
import styles from './InfoListItem.module.scss';

function InfoListItem({ title, subtitle, description, icon, small, link }) {
  const titleClasses = classNames('body16', styles.title);

  const iconSize = small ? 24 : 32;
  const showIcon = icon && title;

  if (!title) {
    return null;
  }

  return (
    <div className={styles.container}>
      {showIcon && (
        <Icon
          className={styles.icon}
          name={icon}
          color={Colors.grayishBlue}
          size={iconSize}
        />
      )}
      <div>
        {link && (
          <a
            href={link}
            rel="noopener noreferrer"
            target="_blank"
            className={styles.link}
          >
            {title && <h3 className={titleClasses}>{title}</h3>}
          </a>
        )}
        {!link && (
          <h3 className={titleClasses}>
            {title}
            {subtitle && <div>{subtitle}</div>}
          </h3>
        )}
        {description && <div className="grayishBlueColor">{description}</div>}
      </div>
    </div>
  );
}

InfoListItem.propTypes = {
  link: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  small: PropTypes.bool,
};

export default memo(InfoListItem);
