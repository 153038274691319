import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountType } from 'modules/accounts';
import { ActionBanner, Page, PageLoader } from 'modules/ui';
import Grid from '@material-ui/core/Grid';
import { getOfferById, loadOffer } from '../redux';
import OfferHeader from './OfferHeader';
import OfferDetailsSection from './OfferDetailsSection';
import ProjectOverview from './ProjectOverview';
import RecordingDetailsSection from './RecordingDetailsSection';
import classes from './OfferPreviewPage.module.scss';

function OfferPreviewPage({ offerId }) {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType);

  const offer = useSelector(getOfferById(offerId));

  // TODO revert once backend is fixed in
  // https://fiveminutes.jira.com/browse/AHAB-1092
  useEffect(() => {
    // if (!offer) {
    dispatch(loadOffer(offerId, accountType));
    // }
  }, [dispatch, /* offer,*/ offerId, accountType]);

  if (!offer) {
    return <PageLoader />;
  }

  // TODO CHANGE OFFER ACTIVE
  return (
    <Page
      HeaderComponent={<OfferHeader offerId={offerId} isPreview />}
      BannerComponent={
        <ActionBanner
          text="This is how your offer looks to actors"
          textClassName={classes.bannerText}
        />
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <OfferDetailsSection offer={offer} />
          <ProjectOverview
            role={offer.projectRole}
            project={offer.projectRole.project}
          />
        </Grid>
        <Grid item xs={0} md={1} />
        <Grid item xs={12} md={3}>
          <RecordingDetailsSection offer={offer} />
        </Grid>
      </Grid>
    </Page>
  );
}

OfferPreviewPage.propTypes = {
  offerId: PropTypes.string.isRequired,
};
export default OfferPreviewPage;
