import React, { Children, memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtil } from 'modules/core/services';
import { Label } from '../Label';
import styles from './Input.module.scss';

function Input({
  setRef,
  error,
  label,
  disabled,
  style,
  inputContainerClassName,
  containerClassName,
  LeftIconComponent,
  LeftTextComponent,
  RightLabelComponent,
  RightIconComponent,
  name,
  children,
  required,
  labelDisabled,
  labelClassName,
  ...otherProps
}) {
  const containerClasses = classNames(
    styles.container,
    error && styles.error,
    containerClassName,
  );

  const inputContainerClasses = classNames(
    !inputContainerClassName && styles.inputContainer,
    inputContainerClassName && inputContainerClassName,
  );

  const hasErrorMessage = typeof error === 'string';

  const renderChild = child => {
    return cloneElement(child, {
      ref: setRef,
      disabled,
      ...otherProps,
    });
  };

  return (
    <div style={style} className={containerClasses}>
      <Label
        disabled={labelDisabled !== undefined ? labelDisabled : disabled}
        label={label}
        required={required}
        error={error}
        RightLabelComponent={RightLabelComponent}
        className={labelClassName}
      />
      <span className={inputContainerClasses}>
        {ReactUtil.resolveComponent(LeftIconComponent)}
        {ReactUtil.resolveComponent(LeftTextComponent)}
        {Children.map(children, renderChild)}
        {ReactUtil.resolveComponent(RightIconComponent)}
      </span>
      {hasErrorMessage && (
        <div id={`${name}-error`} className={styles.errorMessage}>
          {error}
        </div>
      )}
    </div>
  );
}

Input.propTypes = {
  setRef: PropTypes.object,
  required: PropTypes.bool,
  lightInput: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  LeftTextComponent: PropTypes.node,
  LeftIconComponent: PropTypes.node,
  RightIconComponent: PropTypes.node,
  RightLabelComponent: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  labelDisabled: PropTypes.bool,
  labelClassName: PropTypes.string,
};

export default memo(Input);
