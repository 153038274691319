import React, { memo } from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';
import some from 'lodash/some';
import compact from 'lodash/compact';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { Colors } from 'modules/theme';
import AvatarOptionUI from '../Option/AvatarOptionUI/AvatarOptionUI';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: Colors.darkBlue,
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '24px',
    fontFamily: 'Inter, sans-serif',
    minHeight: '40px',
    border: `1px solid ${Colors.inputBorder}`,
    backgroundColor: Colors.inputBackground,
    borderRadius: '6px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  label: {
    color: Colors.darkGrey,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    fontFamily: 'Inter, sans-serif',
    textTransform: 'uppercase',
  },
  'label--required': {
    fontWeight: 600,
    fontSize: '13px',
    color: Colors.grayishBlue,
    textTransform: 'none',
  },
}));

function ListSelectInput({
  name,
  value,
  loading,
  onInputChange,
  options,
  onChange,
  disabled,
  required,
  isMultiSelect,
  hideIcon,
  label,
  error,
  placeholder,
}) {
  function renderOption(userOption, { selected }) {
    const locationDescriptionArray = [
      get(userOption, 'city'),
      get(userOption, 'state.name'),
      get(userOption, 'country.name'),
    ];

    return (
      <AvatarOptionUI
        divider
        isCheckbox={isMultiSelect}
        key={`${name}-${userOption.value}`}
        label={userOption.label}
        hideIcon={hideIcon}
        selected={selected}
        containerStyle={{ width: '100%', padding: '5px 0' }}
        title={userOption.label}
        description={compact(locationDescriptionArray).join(', ')}
        avatarUrl={userOption.profileImage}
      />
    );
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple={!!isMultiSelect}
        getOptionLabel={option => option.label}
        disabled={disabled}
        loading={loading}
        id="tags-standard"
        classes={classes}
        options={options}
        value={value}
        onInputChange={onInputChange}
        onChange={onChange}
        getOptionSelected={(option, value) => value.id === option.id}
        renderInput={params => (
          <>
            <InputLabel
              error={error}
              className={classes.label}
              shrink
              htmlFor="custom-css-standard-input"
            >
              {label}{' '}
              {required && (
                <span
                  style={error ? { color: Colors.error } : {}}
                  className={classes['label--required']}
                >
                  {' '}
                  (required)
                </span>
              )}
            </InputLabel>
            <TextField
              {...params}
              required
              error={error}
              helperText={error}
              id="custom-css-standard-input"
              variant="outlined"
              placeholder={placeholder || 'Enter recipients name'}
            />
          </>
        )}
        renderOption={renderOption}
      />
    </div>
  );
}

ListSelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  hideIcon: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
};

export default memo(ListSelectInput);
