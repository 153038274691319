import { useEffect } from 'react';

const useScrollRestoration = (scrollY, depsArray) => {
  useEffect(() => {
    if (scrollY) {
      window.scrollTo(0, scrollY);
    }
  }, [scrollY, depsArray]);
};

export default useScrollRestoration;
