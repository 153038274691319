import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { Dialog, TextInput } from 'modules/ui';
import { LodashUtil, Utils } from 'modules/core';
import { showNotification, Notification } from 'modules/notifications';
import { Validator } from '../services';
import {
  createRole,
  updateRole,
  getSubmitError,
  setSubmitError,
} from '../redux';

const ENTER_KEY = 'Enter';

export default function ProjectRoleDialog({
  producerId,
  projectId,
  projectRole,
  projectRoles,
  onCancel,
}) {
  const dispatch = useDispatch();
  const roleId = get(projectRole, 'id');
  const roleName = get(projectRole, 'name');

  const [name, setName] = useState(roleName);
  const [touched, setTouched] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const error = useSelector(getSubmitError);

  function handleCreateRole() {
    setInProgress(true);
    dispatch(createRole(producerId, projectId, { name })).then(role => {
      setInProgress(false);
      dispatch(
        showNotification({
          message: 'Role created successfully',
          type: Notification.TYPES.SUCCESS,
        }),
      );

      if (role) {
        onCancel();
      }
    });
  }

  function handleEditRole() {
    setInProgress(true);
    dispatch(updateRole(producerId, projectId, roleId, { name })).then(role => {
      setInProgress(false);
      dispatch(
        showNotification({
          message: `${LodashUtil.truncateText(
            role.data.name,
          )} Role successfully updated`,
          type: Notification.TYPES.SUCCESS,
        }),
      );

      if (role) {
        onCancel();
      }
    });
  }

  const handleAction = !roleId ? handleCreateRole : handleEditRole;

  function handleNameChange(event) {
    const value = event.target.value.trimStart();
    setName(value);

    const values = projectRoles.map(role => trim(role.name));
    const validationError = Validator.validateRole({
      name: value,
      names: values,
    });
    const nameError = get(validationError, 'title');

    if (nameError) {
      dispatch(setSubmitError(nameError));
    } else {
      dispatch(setSubmitError(null));
    }
    setTouched(true);
  }

  function handleKeyPressed(event) {
    if (event.key === ENTER_KEY && !error) {
      handleAction();
    }
  }

  function handleOnCancel() {
    dispatch(setSubmitError(null));

    if (onCancel) {
      onCancel();
    }
  }

  const title = !roleId
    ? 'Create a New Role'
    : `Edit ${LodashUtil.truncateText(roleName)} Role`;
  const actionBtnLabel = !roleId ? 'Create' : 'Save changes';

  return (
    <Dialog
      title={title}
      onAction={handleAction}
      onCancel={handleOnCancel}
      actionBtnLabel={actionBtnLabel}
      actionDisabled={!touched || error !== null}
      inProgress={inProgress}
    >
      <TextInput
        autoFocus
        label="Role name"
        name="name"
        placeholder="E.g. Moby Dick"
        onChange={handleNameChange}
        onKeyPress={handleKeyPressed}
        value={name}
        error={error}
        autoComplete={Utils.PREVENT_AUTOCOMPLETE_IN_MODAL}
      />
    </Dialog>
  );
}

ProjectRoleDialog.propTypes = {
  projectRole: PropTypes.object,
  projectRoles: PropTypes.object,
  producerId: PropTypes.object.isRequired,
  projectId: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
};
