import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ManagedForm, PasswordInput, Page, TabLayout } from 'modules/ui';
import { SettingsTabs } from 'modules/accounts';
import { showNotification, Notification } from 'modules/notifications';
import { Validator } from '../services';
import {
  getChangePasswordError,
  getChangePasswordSuccess,
  changePassword,
  clearChangePasswordStatus,
} from '../redux';

export default function ChangePasswordPage({ history }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const changePasswordError = useSelector(getChangePasswordError);
  const changePasswordSuccess = useSelector(getChangePasswordSuccess);

  useEffect(() => () => dispatch(clearChangePasswordStatus()), []);

  useEffect(() => {
    if (changePasswordSuccess) {
      dispatch(
        showNotification({
          message: 'Password changed',
          type: Notification.TYPES.SUCCESS,
        }),
      );
      //TODO: Why?
      history.push('/');
    }
  }, [changePasswordSuccess, dispatch, history]);

  function handleChangePassword({ oldPassword, newPassword }) {
    return dispatch(changePassword(oldPassword, newPassword));
  }

  return (
    <Page>
      <TabLayout tabs={<SettingsTabs />} showVisibilityPanel={false}>
        <ManagedForm
          ref={formRef}
          title="Change password"
          submitButtonLabel="Change"
          onSubmit={handleChangePassword}
          validate={Validator.changePasswordData}
          error={changePasswordError}
          cleanOnSubmitSuccess
          className="mt-48"
        >
          <PasswordInput
            label="Old password"
            name="oldPassword"
            placeholder="Enter password"
          />
          <PasswordInput
            showHint
            label="New password"
            name="newPassword"
            placeholder="Enter password"
          />
          <PasswordInput
            label="Confirm password"
            name="confirmPassword"
            placeholder="Enter password"
          />
        </ManagedForm>
      </TabLayout>
    </Page>
  );
}

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
};
