import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FormHeader.module.scss';

function FormHeader({
  error,
  title,
  titleStyle,
  titleContainerClassName,
  description,
  descriptionStyle,
  descriptionClassName,
}) {
  const titleContainerClasses = classNames(
    styles.titleContainer,
    error && styles['has-error'],
    titleContainerClassName,
  );

  const titleClasses = classNames('m-0', styles.title);

  const descriptionClasses = classNames(
    'body14',
    styles.description,
    descriptionClassName,
  );
  return (
    <div style={titleStyle} className={titleContainerClasses}>
      <h1 className={titleClasses}>{title}</h1>
      {description && (
        <div style={descriptionStyle} className={descriptionClasses}>
          {description}
        </div>
      )}
    </div>
  );
}

FormHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  titleStyle: PropTypes.object,
  titleContainerClassName: PropTypes.string,
  descriptionStyle: PropTypes.object,
  descriptionClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default memo(FormHeader);
