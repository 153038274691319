import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ListItemsString.module.scss';

function ListItemsString({ selectionString, onChange, disabled }) {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    const width = ref.current.offsetWidth;

    if (onChange) {
      onChange(width);
    }
  }, [selectionString]);

  const stringClasses = classNames(
    styles.list,
    disabled && styles['list--disabled'],
  );

  return (
    <div ref={ref} className={stringClasses}>
      {selectionString}
    </div>
  );
}

ListItemsString.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectionString: PropTypes.string,
};

export default memo(ListItemsString);
