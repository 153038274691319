import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import { useSelector, useDispatch } from 'react-redux';
import { Page, EmptyPlaceholder } from 'modules/ui';
import { getProducer } from 'modules/producers';
import { useTealiumView } from 'modules/accounts';
import {
  setSort,
  getSort,
  loadAllProjects,
  getProjects,
  getLoading,
  getLoadingRecentlyViewed,
  getRecentlyViewedProjects,
  initializeProjectsPage,
  getLoadingProjectsStats,
  getCovers,
  loadCovers,
} from '../redux';
import {
  ProjectsTable,
  ProjectsHeader,
  RecentlyViewedProjects,
} from '../components';
import ProjectModal from './ProjectModal';

const styles = {
  emptyPlaceholder: {
    marginTop: 80,
    marginBottom: 80,
    marginLeft: 24,
    marginRight: 24,
    maxHeight: 524,
  },
  projectsContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
};

function ProjectsPage({ history }) {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const projects = useSelector(getProjects);
  const loadedCoverImages = useSelector(getCovers);
  const loadingProjects = useSelector(getLoading);
  const recentlyViewedProjects = useSelector(getRecentlyViewedProjects);
  const loadingRecentlyViewed = useSelector(getLoadingRecentlyViewed);
  const producer = useSelector(getProducer);
  const sort = useSelector(getSort);
  const loadingStats = useSelector(getLoadingProjectsStats);

  const producerId = get(producer, 'id');
  const hasProjects = !isEmpty(projects);
  const hasRecentProjects = !isEmpty(recentlyViewedProjects);

  const [pageLoading, setPageLoading] = useState(true);

  useTealiumView('Projects Page');

  useEffect(() => {
    if (producerId) {
      dispatch(initializeProjectsPage(producerId)).then(() =>
        setPageLoading(false),
      );
    }
  }, [dispatch, producerId]);

  const handleLoadProjectCovers = useCallback(
    projectsData => {
      const coverImagePaths = map(projectsData, project => {
        const { coverImage } = project;
        return coverImage;
      });
      dispatch(loadCovers(coverImagePaths, loadedCoverImages));
    },
    [dispatch, loadedCoverImages],
  );

  useEffect(() => {
    if (producerId) {
      dispatch(loadAllProjects(producerId, sort)).then(res => {
        handleLoadProjectCovers(res.data);
      });
    }
  }, [dispatch, producerId, sort, handleLoadProjectCovers]);

  function showModal() {
    setModalOpen(true);

    global.utag.link({ event_type: 'new_project' });
  }

  function hideModal() {
    setModalOpen(false);
  }

  function handleProjectClick(project) {
    history.push({
      pathname: `/projects/${project.id}`,
    });
  }

  function handleProjectCreate(payload) {
    const id = get(payload, 'data.id');

    if (id) {
      history.push({
        pathname: `/projects/${id}`,
      });
    }
  }

  function handleSortClick(newSort) {
    dispatch(setSort(newSort));
    dispatch(loadAllProjects(producerId, newSort)).then(res => {
      handleLoadProjectCovers(res.data);
    });
  }

  const showPlaceholder = !hasProjects && !hasRecentProjects && !pageLoading;

  if (!producerId) {
    return null;
  }

  return (
    <Page
      HeaderComponent={<ProjectsHeader handleCreateProject={showModal} />}
      contentStyle={styles.projectsContainer}
    >
      {showPlaceholder && (
        <EmptyPlaceholder
          title="Create your first Project"
          description="Use Ahab to cast the perfect voice"
          buttonLabel="New project"
          onButtonClick={showModal}
          style={styles.emptyPlaceholder}
        />
      )}
      {!showPlaceholder && (
        <>
          <RecentlyViewedProjects
            loading={loadingRecentlyViewed}
            onProjectClick={handleProjectClick}
          />
          <ProjectsTable
            loading={loadingProjects}
            loadingStats={loadingStats}
            onRowClick={handleProjectClick}
            onSortClick={handleSortClick}
          />
        </>
      )}
      {modalOpen && (
        <ProjectModal
          onClose={hideModal}
          onProjectCreate={handleProjectCreate}
        />
      )}
    </Page>
  );
}

ProjectsPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ProjectsPage);
