import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { PageHeader, SearchInput, Button, PageHeaderTitle } from 'modules/ui';

export default function MyClientsHeader({
  onChange,
  defaultValue,
  onClickInviteClient,
  onClear,
}) {
  const [value, setValue] = useState(defaultValue || '');

  function handleChange(event) {
    const newValue = get(event, 'target.value');
    setValue(newValue);

    onChange(newValue);
  }

  function handleClear() {
    setValue('');
    onClear();
  }

  return (
    <PageHeader
      LeftComponent={
        <PageHeaderTitle
          title="My Clients"
          description="Manage your clients and choose which ones are visible on your Agent Profile."
        />
      }
    >
      <Button primary onClick={onClickInviteClient}>
        Invite talent
      </Button>
      <SearchInput
        theme="dark"
        name="query"
        placeholder="Search clients by name or keyword"
        onChange={handleChange}
        value={value}
        onClear={handleClear}
      />
    </PageHeader>
  );
}

MyClientsHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onClickInviteClient: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};
