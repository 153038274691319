import { Validator } from 'modules/core';

function validateForgotPassword({ email }) {
  const emailError = Validator.validateEmail(email);

  return {
    ...(!!emailError && { email: emailError }),
  };
}

function validatePasswordReset({ code, password, confirmPassword }) {
  const codeError = Validator.validateNotEmpty(code, 'Code');
  const passwordError = Validator.validatePassword(password);
  let confirmPasswordError = false;

  if (password !== confirmPassword) {
    confirmPasswordError =
      'Your password and confirmation password do not match';
  }

  return {
    ...(!!codeError && { code: codeError }),
    ...(!!passwordError && { password: passwordError }),
    ...(!!confirmPasswordError && { confirmPassword: confirmPasswordError }),
  };
}

function validateCompleteNewPassword({ password, confirmPassword }) {
  const passwordError = Validator.validatePassword(password);
  let confirmPasswordError = false;

  if (password !== confirmPassword) {
    confirmPasswordError =
      'Your password and confirmation password do not match';
  }

  return {
    ...(!!passwordError && { password: passwordError }),
    ...(!!confirmPasswordError && { confirmPassword: confirmPasswordError }),
  };
}

function validateChangePassword({ newPassword, confirmPassword }) {
  const newPasswordError = Validator.validatePassword(newPassword);
  let confirmPasswordError = false;

  if (newPassword !== confirmPassword) {
    confirmPasswordError =
      'Your new password and confirmation password do not match';
  }

  return {
    ...(!!newPasswordError && { newPassword: newPasswordError }),
    ...(!!confirmPasswordError && { confirmPassword: confirmPasswordError }),
  };
}

function validateProducerApplication({
  firstName,
  lastName,
  email,
  phone,
  categories,
  country,
  city,
  zip,
  shortBio,
  company,
  contentNameOne,
  contentLinkOne,
  clipUrl,
  question1,
  question2,
  question3,
  question4,
}) {
  const firstNameError = Validator.validateNotEmpty(firstName, 'First name');
  const lastNameError = Validator.validateNotEmpty(lastName, 'Last name');
  const emailError = Validator.isEmail(email);
  const phoneError = Validator.validateNotEmpty(phone, 'Phone');
  const countryError = Validator.validateNotEmpty(country, 'Address');
  const cityError = Validator.validateNotEmpty(city, 'City');
  const zipError = Validator.validateNotEmpty(zip, 'Zip');
  const shortBioError = Validator.validateNotEmpty(shortBio, 'Biography');
  const companyError = Validator.validateNotEmpty(company, 'Company name');
  const contentNameError = Validator.validateNotEmpty(
    contentNameOne,
    'Content name',
  );
  const contentLinkError = Validator.validateNotEmpty(
    contentLinkOne,
    'Content link',
  );
  const clipUrlError = Validator.validateNotEmpty(clipUrl, 'Sample');
  const categoriesError = Validator.validateNotEmpty(
    categories,
    'Category you have worked in',
  );
  const question1Error = Validator.validateNotEmpty(question1, 'Answer');
  const question2Error = Validator.validateNotEmpty(question2, 'Answer');
  const question3Error = Validator.validateNotEmpty(question3, 'Answer');
  const question4Error = Validator.validateNotEmpty(question4, 'Answer');
  return {
    ...(!!firstNameError && { firstName: firstNameError }),
    ...(!!lastNameError && { lastName: lastNameError }),
    ...(!!emailError && { email: emailError }),
    ...(!!phoneError && { phone: phoneError }),
    ...(!!countryError && { country: countryError }),
    ...(!!cityError && { city: cityError }),
    ...(!!zipError && { zip: zipError }),
    ...(!!shortBioError && { shortBio: shortBioError }),
    ...(!!companyError && { company: companyError }),
    ...(!!contentNameError && { contentNameOne: contentNameError }),
    ...(!!contentLinkError && { contentLinkOne: contentLinkError }),
    ...(!!clipUrlError && { clipUrl: clipUrlError }),
    ...(!!question1Error && { question1: question1Error }),
    ...(!!question2Error && { question2: question2Error }),
    ...(!!question3Error && { question3: question3Error }),
    ...(!!question4Error && { question4: question4Error }),
    ...(!!categoriesError && { categories: categoriesError }),
  };
}

function validateLogin({ email, password }) {
  const emailError = Validator.validateEmail(email);
  const passwordError = Validator.validateNotEmpty(password, 'Password');

  return {
    ...(!!emailError && { email: emailError }),
    ...(!!passwordError && { password: passwordError }),
  };
}

function validateSignUp({ firstName, lastName, email, password }) {
  const firstNameError = Validator.validateNotEmpty(firstName, 'First name');
  const lastNameError = Validator.validateNotEmpty(lastName, 'Last name');
  const emailError = Validator.validateEmail(email);
  const passwordError = Validator.validatePassword(password);

  return {
    ...(!!firstNameError && { firstName: firstNameError }),
    ...(!!lastNameError && { lastName: lastNameError }),
    ...(!!emailError && { email: emailError }),
    ...(!!passwordError && { password: passwordError }),
  };
}

export default {
  loginData: validateLogin,
  signUpData: validateSignUp,
  producerApplicationData: validateProducerApplication,
  forgotPasswordData: validateForgotPassword,
  resetPasswordData: validatePasswordReset,
  completeNewPasswordData: validateCompleteNewPassword,
  changePasswordData: validateChangePassword,
};
