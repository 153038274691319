import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getTalent, updateTalent } from '../redux';
import { SkillsForm, TalentProfileTabs } from '../components';

export default function EditSkillsPage() {
  const dispatch = useDispatch();
  const talent = useSelector(getTalent);

  function handleSubmitUpdate(values) {
    return dispatch(updateTalent(talent.account.id, talent.id, values)).then(
      () => {
        dispatch(
          showNotification({
            message: 'Skills successfully saved',
            type: Notification.TYPES.SUCCESS,
          }),
        );

        global.utag.link({
          event_type: 'edit_profile',
          module_type: 'Edit profile',
          module_variation: 'Your Skills',
        });
      },
    );
  }

  if (!talent) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<TalentProfileTabs />}>
        <SkillsForm talent={talent} onSubmit={handleSubmitUpdate} />
      </TabLayout>
    </Page>
  );
}
