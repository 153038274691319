import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';
import { TableCellSortable } from 'modules/ui';
import styles from './TopCandidatesTable.module.scss';

function TopCandidatesTableHeader({ sort, onSortClick }) {
  return (
    <TableRow>
      <TableCellSortable
        align="left"
        name="fullName"
        sort={sort}
        onClick={onSortClick}
      >
        Talent
      </TableCellSortable>
      <TableCell
        align="left"
        className={classNames(styles.header, styles.mediumCell)}
      >
        Notes
      </TableCell>
      <TableCell
        align="left"
        className={classNames(styles.header, styles.largeCell)}
      >
        Sample
      </TableCell>
      <TableCell align="center" className={styles.mediumCell}>
        Offer Status
      </TableCell>
      <TableCell align="right" className={styles.smallCell} />
    </TableRow>
  );
}

TopCandidatesTableHeader.propTypes = {
  sort: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
};
export default memo(TopCandidatesTableHeader);
