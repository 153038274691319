import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactAvatar from 'react-avatar';
import classNames from 'classnames';
import placeholder from './assets/placeholder.png';
import classes from './Avatar.module.scss';

function Avatar({
  photoUrl,
  onClick,
  size,
  className,
  isActive,
  enableHoverBorder,
}) {
  const src = photoUrl || placeholder;

  const customClasses = classNames(
    className,
    enableHoverBorder && classes.avatar,
    isActive && enableHoverBorder && classes['avatar--border'],
  );

  return (
    <ReactAvatar
      round
      src={src}
      size={size}
      onClick={onClick}
      className={customClasses}
    />
  );
}

Avatar.propTypes = {
  photoUrl: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.number,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  enableHoverBorder: PropTypes.bool,
};

Avatar.defaultProps = {
  size: 32,
  enableHoverBorder: false,
};

export default memo(Avatar);
