import truncate from 'lodash/truncate';
import map from 'lodash/map';
import find from 'lodash/find';
import difference from 'lodash/difference';
import union from 'lodash/union';

export function truncateText(text, length = 25, separator = ' ') {
  if (!length) {
    return text;
  }

  return truncate(text, { length, separator });
}

export function applyIdOrder(items, ids) {
  return map(ids, id => find(items, ['id', id]));
}

export function symmetricDifference(array1, array2) {
  return union(difference(array1, array2), difference(array2, array1));
}

export default {
  truncateText,
  applyIdOrder,
  symmetricDifference,
};
