import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { useWindowSize } from 'modules/core';
import { getAccountType, AccountType } from 'modules/accounts';
import { PageHeader, Button, AvatarDescription, EmptyImagePlaceholder, EMPTY_IMAGE_PLACEHOLDER_SIZES } from 'modules/ui';
import { LetterIcon } from 'modules/chat';

import { BookmarkOutlineSVG, BookmarkSVG } from './assets';
import styles from './AuditionHeader.module.scss';

export default function AuditionHeader({
  audition,
  applied,
  canApply,
  saved,
  avatar,
  onApply,
  onToggleSave,
  applyLoading,
  saveLoading,
  onOpenClientInvite,
  onSendMessage,
  isCoverLoading,
  coverImageSrc,
}) {
  const auditionId = get(audition, 'id');
  const auditionProducerName = get(audition, 'producer.fullName');
  const auditionProducerId = get(audition, 'producer.id');
  const title = get(audition, 'name');

  const accountType = useSelector(getAccountType);
  const { desktopWidth } = useWindowSize();

  const breadcrumbPaths = [
    { title: 'Auditions', route: '/auditions' },
    { title, route: `/auditions/${auditionId}` },
  ];

  const applyButtonStyle =
    applied || !canApply ? styles.appliedButton : styles.submitButton;

  const applyButtonLabel = applied ? 'Applied' : 'Apply';

  const savedButtonStyle = saved ? styles.savedButton : styles.saveButton;
  const savedButtonLabel = saved ? 'Saved' : 'Save';

  const history = useHistory();

  function handleProducerProfileClick() {
    history.push(`/producers/${auditionProducerId}`);
  }

  const titleComponent = (
    <>
      {coverImageSrc ? (
        <div className={styles.imageContainer}>
          <img
            src={coverImageSrc}
            alt="cover"
            className={styles.coverImage}
          />
        </div>
              ) : (
                <EmptyImagePlaceholder
                  className={styles.coverImageAlt}
                  size={EMPTY_IMAGE_PLACEHOLDER_SIZES.large}
                  isLoading={isCoverLoading}
                />
            )}
      <div className={styles.textContainer}>
        <h1 className={styles.title}>{title}</h1>
        <AvatarDescription
          verb="create"
          avatar={avatar}
          personName={auditionProducerName}
          onClick={desktopWidth && handleProducerProfileClick}
        />
      </div>
    </>
  );

  const mobileTitleComponent = (
    <>
      <div className={styles.imageWithTitleContainer}>
        {coverImageSrc ? (
          <div className={styles.imageContainer}>
            <img
              src={coverImageSrc}
              alt="cover"
              className={styles.coverImage}
            />
          </div>

                ) : (
                  <EmptyImagePlaceholder
                    className={styles.coverImageAlt}
                    size={EMPTY_IMAGE_PLACEHOLDER_SIZES.small}
                    isLoading={isCoverLoading}
                  />
              )}
        <h1 className={styles.title}>{title}</h1>
      </div>
      <AvatarDescription
        verb="create"
        avatar={avatar}
        personName={auditionProducerName}
        onClick={desktopWidth && handleProducerProfileClick}
      />
    </>
  );

  const saveButton = (
    <Button
      loading={saveLoading}
      primary
      onClick={onToggleSave}
      className={savedButtonStyle}
    >
      {saved ? <BookmarkSVG /> : <BookmarkOutlineSVG />}
      {!desktopWidth && <div className={styles.savedButtonLabel}>{savedButtonLabel}</div>}
    </Button>
  );

  const messageButton = ( 
    <Button
      gray
      className={styles.sendMessageButton}
      onClick={onSendMessage}
    >
      <LetterIcon />
      <div className={styles.messageButtonLabel}>
        Message
      </div>
    </Button>
  );

  return (
    <PageHeader
      breadcrumbPaths={desktopWidth && breadcrumbPaths}
      LeftComponent={
        desktopWidth && (
          <div className={styles.titleContainer}>{titleComponent}</div>
        )
      }
    >
      <div className={styles.actionContainer}>
        {AccountType.isTalent(accountType) && (
          <>
            {!desktopWidth ? (
              <div className={styles.mobileContainer}>
                {mobileTitleComponent}
                <div className={styles.buttonsContainer}>
                  {messageButton}
                  {saveButton}
                </div>
              </div>
            ) : (
              <>
                <Button
                  loading={applyLoading}
                  disabled={applied || !canApply}
                  primary
                  onClick={onApply}
                  className={applyButtonStyle}
                >
                  {applyButtonLabel}
                </Button>
                {messageButton}
                {saveButton}
              </>
            )}
          </>
        )}
        {AccountType.isAgent(accountType) && (
          <Button
            loading={saveLoading}
            onClick={onOpenClientInvite}
            primary
            className={applyButtonStyle}
          >
            Send to Client
          </Button>
        )}
      </div>
    </PageHeader>
  );
}

AuditionHeader.propTypes = {
  audition: PropTypes.object,
  applyLoading: PropTypes.bool,
  saveLoading: PropTypes.bool,
  avatar: PropTypes.string,
  applied: PropTypes.bool,
  canApply: PropTypes.bool,
  saved: PropTypes.bool,
  onApply: PropTypes.func,
  onToggleSave: PropTypes.func,
  onOpenClientInvite: PropTypes.func,
  onSendMessage: PropTypes.func,
  isCoverLoading: PropTypes.bool,
  coverImageSrc: PropTypes.string
};
