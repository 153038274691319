import { ACCOUNT_TYPES } from '../const';

function isProducer(accountType) {
  return accountType === ACCOUNT_TYPES.PRODUCER;
}

function isTalent(accountType) {
  return accountType === ACCOUNT_TYPES.TALENT;
}

function isAgent(accountType) {
  return accountType === ACCOUNT_TYPES.AGENT;
}

export default {
  isProducer,
  isTalent,
  isAgent,
};
