import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavBarLink.module.scss';
import './NavBarLink.plain.scss';

function NavBarLink({
  disabled,
  dark,
  className,
  onClick,
  showUnderline,
  ...otherProps
}) {
  const activeClassName = showUnderline
    ? 'nav-bar-link'
    : 'nav-bar-link-no-line';

  const classes = classNames(
    activeClassName,
    styles.link,
    dark && styles.dark,
    disabled && styles.disabled,
    className,
  );

  return (
    <NavLink
      disabled={disabled}
      className={classes}
      onClick={onClick}
      {...otherProps}
    />
  );
}

NavBarLink.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  showUnderline: PropTypes.bool,
  ...NavLink.propTypes,
};

NavBarLink.defaultProps = {
  showUnderline: true,
};

export default memo(NavBarLink);
