import React, { Children } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

export default function AuditionAdditionalDetailsGrid({ children }) {
  const items = Children.toArray(children);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {items[0]}
      </Grid>
      <Grid item xs={12}>
        {items[1]}
      </Grid>
    </Grid>
  );
}

AuditionAdditionalDetailsGrid.propTypes = {
  children: PropTypes.node,
};
