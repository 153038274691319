import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, TableRow } from '@material-ui/core';
import { AudioPreview } from 'modules/audio';
import { LodashUtil } from 'modules/core';
import styles from './TopCandidatesSharingTable.module.scss';

const DETAILS_PATHS = ['genre', 'pointOfView'];

export default function TopCandidatesSharingTableRow({ topCandidate, token }) {
  const { fullName } = topCandidate.talent;

  const audioSample = topCandidate.audioSample
    ? topCandidate.audioSample
    : topCandidate.audioClip;

  const noteCellClassNames = classNames('body16', styles.noteCell);

  return (
    <TableRow>
      <TableCell
        align="left"
        component="th"
        scope="row"
        className={styles.nameCell}
      >
        <h3>{LodashUtil.truncateText(fullName, 28, '...')}</h3>
      </TableCell>
      <TableCell className={noteCellClassNames} align="left">
        {topCandidate.note || 'No note'}
      </TableCell>
      <TableCell className={styles.sampleCell} align="left">
        <AudioPreview
          details={audioSample}
          detailsPaths={DETAILS_PATHS}
          token={token}
        />
      </TableCell>
    </TableRow>
  );
}

TopCandidatesSharingTableRow.propTypes = {
  topCandidate: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};
