import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  Textarea,
  ManagedForm,
  ManagedImageInput,
  PhoneInput,
  MultiSelect,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';
import { Validator } from '../services';
import { ProfileDetailsGrid } from './form-grids';
import classes from './ProfileDetailsForm.module.scss';

export default function ProfileDetailsForm({
  onSubmit,
  initialValues,
  image,
  ...otherProps
}) {
  return (
    <ManagedForm
      className={classes.form}
      withPrompt
      title="Personal Info"
      description="Tell us about yourself."
      submitButtonLabel="Save Personal Info"
      onSubmit={onSubmit}
      titleContainerClassName={classes.titleContainer}
      validate={Validator.validateProfileDetails}
      TemplateComponent={ProfileDetailsGrid}
      initialValues={initialValues}
      {...otherProps}
    >
      <TextInput
        autoFocus
        label="First Name"
        name="firstName"
        placeholder="E.g. John"
      />
      <TextInput
        autoFocus
        label="Last Name"
        name="lastName"
        placeholder="E.g. Doe"
      />

      <ManagedImageInput
        name="profileImage"
        label="Profile photo"
        title="Edit profile photo"
        initialImage={image}
        cropShape="round"
        showGrid={false}
        imageClass={classes.image}
        className={classes.avatar}
      />
      <MultiSelect
        label="Pronouns"
        name="pronouns"
        placeholder="Select pronoun"
        options={Dictionary.PRONOUN_OPTIONS}
      />
      <TextInput
        autoFocus
        label="Professional Tagline/Title"
        name="title"
        placeholder="E.g. Talent Agent"
      />
      <PhoneInput label="Contact number" name="phoneNumber" placeholder="+" />
      <Textarea
        label="Biography"
        name="biography"
        maxChars={500}
        placeholder="Tell us about yourself."
        className={classes.textarea}
      />
    </ManagedForm>
  );
}

ProfileDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
