import { createReducer, createAction } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';

import unionBy from 'lodash/unionBy';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import head from 'lodash/head';

import { openChatPopup, closeChatPopup, ChatType } from 'modules/chat';

import { AccountType } from 'modules/accounts';
import { getTalentByAccountId } from 'modules/talents';
import { getAgent } from 'modules/agents';
import { getProducer } from 'modules/producers';
import {
  fetchGlobalMessagesThread,
  fetchUnreadGlobalMessagesThreads,
  postGlobalMessagesThread,
  postGlobalMessagesThreadUnreadStatus,
  searchGlobalMessagesThreadsByName,
  markAllMessagesAsRead as apiMarkAllMessagesAsRead,
} from './api';
import {
  findGlobalMessagingThreadWith,
  globalMessagingChatFilter,
  globalMessagingChatId,
} from './services';

const initialState = {
  threads: [],
  searchedThreads: [],
  loadingThreads: false,
};

export const loadGlobalMessagingThread = createThunk(
  fetchGlobalMessagesThread,
  'loadThread',
  'globalMessagingThreads',
);

export const createGlobalMessagingThread = createThunk(
  postGlobalMessagesThread,
  'createThread',
  'globalMessagingThreads',
);

export const markGlobalMessagingThreadUnread = createThunk(
  postGlobalMessagesThreadUnreadStatus,
  'markUnread',
  'globalMessagingThreads',
);

export const searchGlobalMessagingThreads = createThunk(
  searchGlobalMessagesThreadsByName,
  'search',
  'globalMessagingThreads',
);

export const clearSearchedGlobalMessagingThreads = createAction(
  'globalMessagingThreads/clearSearchedThreads',
);

export const loadUnreadGlobalMessagingThreads = createThunk(
  fetchUnreadGlobalMessagesThreads,
  'loadUnreadThreads',
  'globalMessagingThreads',
);

export function openGlobalMessagingChatPopup(
  oppositeAccountId,
  oppositeAccountType,
) {
  return openChatPopup(
    globalMessagingChatId(oppositeAccountId),
    ChatType.GLOBAL,
    { oppositeAccountId, oppositeAccountType },
    globalMessagingChatFilter(oppositeAccountId),
  );
}

export function closeGlobalMessagingChatPopup(accountId) {
  return closeChatPopup(globalMessagingChatFilter(accountId));
}

export const markAllMessagesAsRead = createThunk(
  apiMarkAllMessagesAsRead,
  'markAllMessagesRead',
  'globalMessagingThreads',
);

export const reducer = createReducer(initialState, {
  [loadGlobalMessagingThread.type.request]: state => {
    state.loadingThreads = true;
  },
  [loadGlobalMessagingThread.type.success]: (state, action) => {
    state.loadingThreads = false;
    state.threads = unionBy(action.payload.data, state.threads, 'id');
  },
  [createGlobalMessagingThread.type.success]: (state, action) => {
    state.threads = [...state.threads, action.payload.data];
  },
  [searchGlobalMessagingThreads.type.request]: state => {
    state.loadingThreads = true;
  },
  [searchGlobalMessagingThreads.type.success]: (state, action) => {
    state.loadingThreads = false;
    state.searchedThreads = action.payload.data;
  },
  [searchGlobalMessagingThreads.type.error]: state => {
    state.loadingThreads = false;
  },
  [clearSearchedGlobalMessagingThreads]: state => {
    state.searchedThreads = [];
  },
  [loadUnreadGlobalMessagingThreads.type.request]: state => {
    state.loadingThreads = true;
  },
  [loadUnreadGlobalMessagingThreads.type.success]: (state, action) => {
    state.loadingThreads = false;
    state.searchedThreads = action.payload.data;
  },
  [loadUnreadGlobalMessagingThreads.type.error]: state => {
    state.loadingThreads = false;
  },
  [markAllMessagesAsRead.type.request]: state => {
    state.loadingThreads = true;
  },
  [markAllMessagesAsRead.type.success]: state => {
    state.loadingThreads = false;
  },
  [markAllMessagesAsRead.type.error]: state => {
    state.loadingThreads = false;
  },
});

export const getGlobalMessagingThreads = createSelector([
  'globalMessagingThreads.threads',
]);

export const getGlobalMessagingThreadsSortedByLatestMessages = createSelector(
  [getGlobalMessagingThreads],
  threads =>
    orderBy(threads, [thread => thread.updatedAt.toLowerCase()], 'desc'),
);

export const getLatestGlobalMessagingThread = createSelector(
  [getGlobalMessagingThreadsSortedByLatestMessages],
  head,
);

export const getGlobalMessagingThreadWith = (accountId, ofType) =>
  createSelector(
    [getGlobalMessagingThreads],
    threads => findGlobalMessagingThreadWith(accountId, ofType, threads),
  );

export const getOppositeUser = (accountId, accountType) => state => {
  const threads = getGlobalMessagingThreads(state);
  const thread = findGlobalMessagingThreadWith(accountId, accountType, threads);
  const accountInfo = get(thread, `${accountType}Account.${accountType}`);
  if (accountInfo !== undefined) {
    return accountInfo;
  }
  if (AccountType.isAgent(accountType)) {
    return getAgent(state);
  }
  if (AccountType.isTalent(accountType)) {
    return getTalentByAccountId(accountId)(state);
  }
  if (AccountType.isProducer(accountType)) {
    return getProducer(state);
  }
};

export const getLoadingThreads = createSelector([
  'globalMessagingThreads.loadingThreads',
]);

export const getSearchedThreads = createSelector([
  'globalMessagingThreads.searchedThreads',
]);
