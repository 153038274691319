import { Api } from 'modules/core';

export default function signUpToNewsletter(account) {
  const { id } = account;

  const endpoint = `newsletters/actions/subscribe`;
  const data = { resourceType: 'newsletter-subscribe-actions' };

  const relationships = {
    account: {
      data: {
        type: 'accounts',
        id,
      },
    },
  };

  return Api.create(endpoint, data, relationships);
}
