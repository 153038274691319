import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import truncate from 'lodash/truncate';

import { Textarea, ManagedForm } from 'modules/ui';
import {
  Validator as AssetValidator,
  Assets,
  MAX_FILE_SIZE,
} from 'modules/assets';
import { AudioInput } from 'modules/audio';
import Dictionary from 'modules/dictionary';

import { Validator } from '../services';

const styles = {
  container: {
    paddingTop: 84,
    paddingBottom: 12,
    display: 'flex',
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    width: 552,
  },
  emptyContent: {
    height: '100%',
  },
  audioInput: {
    marginTop: -10,
  },
};

export default function ApplyModalForm({
  audition,
  onSubmit,
  onCancel,
  onBack,
  backBtnDisabled,
}) {
  const inputRef = useRef(null);

  const auditionName = truncate(get(audition, 'name'), { length: 34 });
  const formTitle = `Apply to ${auditionName} audition`;

  const [file, setFile] = useState(null);
  const [details, setDetails] = useState(null);

  function handleSelectFile(fileObject) {
    const isReplacement = !!get(details, 'id', null);
    const { name } = fileObject;
    const filename = Assets.getNameWithoutExtension(name);

    setFile(fileObject);

    if (isReplacement) {
      setDetails({ ...details, name: filename, isReplacement });
      return;
    }

    setDetails({ name: filename });
  }

  function handleRemoveFile() {
    setFile(null);
    setDetails(null);

    return Promise.resolve();
  }

  function handleSubmit(form) {

    const data = {
      ...form,
      message: form.message || Dictionary.DEFAULT_MESSAGES.AUDITION_SUBMISSION,
      details,
    };
    
    if (onSubmit) {
      return onSubmit(data);
    }

    return Promise.resolve();
  }

  const size = Assets.convertBytesToMegaBytes(MAX_FILE_SIZE.audio);
  const description = `Supported formats: .mp3, .wav. Max size ${size}MB`;

  return (
    <div style={styles.container}>
      <ManagedForm
        style={styles.form}
        title={formTitle}
        description="Upload your sample and include a message about your audition."
        forceSubmit
        showFooterDivider
        onSubmit={handleSubmit}
        onCancel={onCancel}
        onBack={onBack}
        validate={Validator.validateApplyOnAudition}
        submitButtonLabel="Submit"
        backBtnDisabled={backBtnDisabled}
      >
        <AudioInput
          required
          label="Sample"
          name="file"
          style={styles.audioInput}
          value={details}
          file={file}
          setRef={inputRef}
          description={description}
          buttonLabel="Upload sample"
          onSelectFile={handleSelectFile}
          validate={newFile => AssetValidator.validate(newFile, 'audio')}
          onRemoveFileClick={handleRemoveFile}
        />
        <Textarea
          optional
          maxChars={350}
          name="message"
          label="Add a message"
          placeholder="Any additional information you want to share about your audition?"
        />
        <span style={styles.emptyContent} />
      </ManagedForm>
    </div>
  );
}

ApplyModalForm.propTypes = {
  audition: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  backBtnDisabled: PropTypes.bool,
};
