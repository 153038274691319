import React, { memo, useEffect, useState } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import PropTypes from 'prop-types';
import { useDidMount } from 'modules/core/services/hooks';
import SelectUI from './SelectUI';

function findSelectedOptionLabel(options, value) {
  const selectedOption = find(
    options,
    option => toString(option.value) === value,
  );

  if (!selectedOption) {
    return null;
  }

  return get(selectedOption, 'label');
}

function Select({
  options,
  setFormValue,
  name,
  value,
  onChange,
  inputValue,
  onInputChange,
  clearFilterPrefix,
  changeDisplayValue,
  showCaret,
  isAutoComplete,
  ...otherProps
}) {
  const didMount = useDidMount();
  const label = findSelectedOptionLabel(options, value);
  const [displayValue, setDisplayValue] = useState(label || inputValue);

  function handleRemoveAll() {
    setDisplayValue(null);

    if (setFormValue) {
      setFormValue(name, null);
    }

    if (clearFilterPrefix) {
      clearFilterPrefix();
    }
  }

  useEffect(() => {
    if (didMount) {
      const selectedOptionLabel = findSelectedOptionLabel(options, value);

      if (isAutoComplete && selectedOptionLabel !== inputValue && value) {
        handleRemoveAll();
      }

      setDisplayValue(
        changeDisplayValue
          ? changeDisplayValue(selectedOptionLabel)
          : inputValue || selectedOptionLabel,
      );

      const shouldRemoveValue = isEmpty(options) && !!value;
      if (shouldRemoveValue) {
        setDisplayValue('');

        if (setFormValue) {
          setFormValue(name, null);
        }
      }
    }
  }, [
    name,
    value,
    options,
    setFormValue,
    inputValue,
    changeDisplayValue,
    didMount,
  ]);

  function handleChange(event) {
    const newValue = get(event, 'target.value');
    const newLabel = get(event, 'target.label');

    setDisplayValue(
      changeDisplayValue ? changeDisplayValue(newLabel) : toString(newLabel),
    );

    if (isAutoComplete && onInputChange) {
      onInputChange({ target: { value: toString(newLabel) } });
    }

    if (setFormValue) {
      setFormValue(name, newValue);
    }

    if (onChange) {
      onChange(event);
    }
  }

  return (
    <SelectUI
      name={name}
      value={value}
      onChange={handleChange}
      onRemoveAll={handleRemoveAll}
      displayValue={displayValue}
      options={options}
      onInputChange={onInputChange}
      showCaret={showCaret}
      isAutoComplete={isAutoComplete}
      {...otherProps}
    />
  );
}

Select.propTypes = {
  ...SelectUI.propTypes,
  name: PropTypes.string,
  setFormValue: PropTypes.func,
  onChange: PropTypes.func,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  clearFilterPrefix: PropTypes.func,
  changeDisplayValue: PropTypes.func,
  isAutoComplete: PropTypes.bool,
  showCaret: PropTypes.bool,
};

export default memo(Select);
