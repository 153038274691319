import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Colors } from 'modules/theme';
import { LodashUtil } from 'modules/core';
import { Icon } from '../Icon';
import styles from './Breadcrumps.module.scss';

export default function BreadcrumbItem({ path, last, onClick }) {
  const titleClasses = classNames(
    !last && 'lightGrayColor',
    styles.breadcrumps__title,
  );

  const pathTitle = LodashUtil.truncateText(path.title);

  return (
    <>
      <div className={titleClasses} onClick={() => onClick(path)}>
        {pathTitle}
      </div>
      {!last && (
        <Icon
          className={styles.breadcrumps__chevron}
          color={Colors.inputPlaceholder}
          name="rightChevron"
          size={12}
        />
      )}
    </>
  );
}

BreadcrumbItem.propTypes = {
  onClick: PropTypes.func,
  last: PropTypes.bool,
  path: PropTypes.shape({
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  }),
};
