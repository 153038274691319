import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FileControls } from 'modules/assets';
import SimpleButtonAudioPreview from './SimpleButtonAudioPreview';
import styles from './AudioInput.module.scss';

export function MessageAudioPreview({
  className,
  disabled,
  value,
  handleRemoveFileClick,
  noReplaceButton,
  handleReplaceClick,
  deleteInProgress,
  setDeleteInProgress,
}) {
  const containerClassnames = classNames(
    styles['fileContainer--simpleButton'],
    className,
  );
  return (
    <SimpleButtonAudioPreview
      className={containerClassnames}
      disabled={disabled}
      details={value}
      controlOptionsComponent={
        <FileControls
          file={value}
          onRemoveFile={handleRemoveFileClick}
          removeIconClassName={styles.hoverIcon}
          onReplaceFile={!noReplaceButton && handleReplaceClick}
          deleteInProgress={deleteInProgress}
          setDeleteInProgress={setDeleteInProgress}
        />
      }
    />
  );
}

MessageAudioPreview.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.object,
  handleRemoveFileClick: PropTypes.func,
  handleReplaceClick: PropTypes.func,
  noReplaceButton: PropTypes.bool,
  setDeleteInProgress: PropTypes.func,
  deleteInProgress: PropTypes.bool,
};
