import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import { Icon } from 'modules/ui';
import { Colors } from 'modules/theme';

const StyledListItem = withStyles({
  root: {
    minHeight: 39,
    textDecoration: 'none!important',
    fontSize: '14px',
    lineHeight: '24px',
    alignItems: 'center',
    color: Colors.darkBlue,
  },
})(props => <MaterialMenuItem {...props} />);

const styles = {
  active: {
    fontWeight: 'bold',
  },
};

function MenuItem({
  text,
  icon,
  iconSize,
  iconColor,
  iconStyle,
  onClick,
  disabled,
  active,
  textStyle,
}) {
  const style = active ? styles.active : null;
  return (
    <StyledListItem onClick={onClick} disabled={disabled}>
      {icon && (
        <Icon
          className="mr-12"
          name={icon}
          color={iconColor}
          size={iconSize}
          style={iconStyle}
        />
      )}
      <div style={{ ...style, ...textStyle }}>{text}</div>
    </StyledListItem>
  );
}

MenuItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  iconSize: PropTypes.number,
  textStyle: PropTypes.object,
  iconColor: PropTypes.string,
  iconStyle: PropTypes.object,
};

MenuItem.defaultProps = {
  iconSize: 16,
  textStyle: {},
  iconColor: Colors.grayishBlue,
};

export default memo(MenuItem);
