function formatName(name) {
  if (name === 'facebook') {
    return 'Facebook';
  }
  if (name === 'instagram') {
    return 'Instagram';
  }
  if (name === 'x') {
    return 'X (formerly Twitter)';
  }
  if (name === 'linkedin') {
    return 'LinkedIn';
  }
  if (name === 'imdb') {
    return 'IMDB';
  }
  if (name === 'youtube') {
    return 'YouTube';
  }

  return '';
}

export default { formatName };
