import includes from 'lodash/includes';
import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import { File } from 'modules/core';
import { ALLOWED_EXTENSIONS } from '../const';

const BLOB_URL_PREFIX = 'blob:';

function createAccountContext(id) {
  return { account: { id } };
}

function createTalentContext(id) {
  return { talent: { id } };
}

function createProjectContext(producerId, projectId) {
  return { producer: { id: producerId }, project: { id: projectId } };
}

function createAuditionContext(auditionId, producerId) {
  return { audition: { id: auditionId }, producer: { id: producerId } };
}

function createAuditionMessageContext(auditionId, applicationId, messageId) {
  return {
    audition: { id: auditionId },
    auditionApplication: { id: applicationId },
    message: { id: messageId },
  };
}

function createProducerContext(id) {
  return { producer: { id } };
}

function getMediaName(extension) {
  const isAudio = includes(ALLOWED_EXTENSIONS.audio, extension);
  const isDocument = includes(ALLOWED_EXTENSIONS.document, extension);

  if (isAudio) {
    return 'Audio';
  }

  if (isDocument) {
    return 'Document';
  }

  return '';
}

function getNameWithoutExtension(name) {
  if (!name) {
    return null;
  }

  return name
    .split('.')
    .slice(0, -1)
    .join('.');
}

function getFileName(url) {
  if (!url) {
    return null;
  }

  return url.split('/').pop();
}

function getExtension(url) {
  if (!url) {
    return null;
  }

  return url.split('.').pop();
}

function convertBytesToMegaBytes(bytes) {
  return bytes / 1000000;
}

async function createMeta(file) {
  const type = get(file, 'type');

  if (!file) {
    return Promise.resolve({});
  }

  const media = new Audio();
  media.src = URL.createObjectURL(file);

  return new Promise(resolve => (media.onloadedmetadata = resolve)).then(() => {
    window.URL.revokeObjectURL(media.src);
    const duration = media.duration;

    return {
      readableDuration: File.getDuration(duration),
      type: type,
    };
  });
}

function isBlobUrl(urlString) {
  return startsWith(urlString, BLOB_URL_PREFIX);
}

export default {
  createMeta,
  getMediaName,
  getNameWithoutExtension,
  convertBytesToMegaBytes,
  getFileName,
  getExtension,
  createAccountContext,
  createTalentContext,
  createAuditionContext,
  createAuditionMessageContext,
  createProjectContext,
  createProducerContext,
  isBlobUrl,
};
