import isNil from 'lodash/isNil';

import { Api } from 'modules/core';
import { AccountType } from 'modules/accounts';

const resourceType = 'audition-applications';

export function getApplicationsEndpoint(auditionId) {
  return `auditions/${auditionId}/applications`;
}

function getApplicationEndpoint(auditionId, applicationId) {
  return `${getApplicationsEndpoint(auditionId)}/${applicationId}`;
}

export function fetchAllTalentApplications(talentId) {
  if (!talentId) {
    return Promise.resolve(null);
  }

  const endpoint = `talents/${talentId}/applications/all`;
  const params = { include: 'audition.producer, lastMessage' };
  return Api.find(endpoint, params);
}

export function fetchTalentApplications(talentId, sort, limit) {
  if (!talentId || !sort || !limit) {
    return Promise.resolve(null);
  }

  const endpoint = `talents/${talentId}/applications`;
  const params = {
    include: 'audition.producer, lastMessage',
    sort,
    page: { limit },
  };
  return Api.find(endpoint, params);
}

export function fetchAllProducerApplications(producerId) {
  if (!producerId) {
    return Promise.resolve(null);
  }

  const endpoint = `producers/${producerId}/applications/all`;
  const params = { include: 'talent, audition, lastMessage' };
  return Api.find(endpoint, params);
}

export function fetchProducerApplications(producerId, sort, limit) {
  if (!producerId || !sort || !limit) {
    return Promise.resolve(null);
  }

  const endpoint = `producers/${producerId}/applications`;
  const params = {
    include: `talent, audition, lastMessage`,
    sort,
    page: { limit },
  };
  return Api.find(endpoint, params);
}

export function fetchAuditionApplications(auditionId) {
  if (!auditionId) {
    return Promise.resolve(null);
  }

  const endpoint = `${getApplicationsEndpoint(auditionId)}/all`;
  const params = {
    include:
      'talent, auditionMessages.authorAccount, auditionMessages.auditionApplication, auditionMessages.audioClip',
  };

  return Api.find(endpoint, params);
}

export function fetchApplicationForTalent(auditionId, talentId) {
  const endpoint = `${getApplicationsEndpoint(auditionId)}/talent:${talentId}`;
  const params = { include: 'talent, audition.producer' };

  if (!auditionId || !talentId) {
    return Promise.resolve(null);
  }

  return Api.find(endpoint, params);
}

export function postApplication(talentId, auditionId) {
  const endpoint = getApplicationsEndpoint(auditionId);
  const application = { resourceType, talentId };

  return Api.create(endpoint, application);
}

export function fetchApplication(talentId, auditionId) {
  const endpoint = getApplicationsEndpoint(auditionId);
  const applicationEndpoint = `${endpoint}/talent:${talentId}`;

  return Api.find(applicationEndpoint);
}

export function postAuditionApplicationUnreadStatus(
  auditionId,
  applicationId,
  unreadMessageForAccountId,
) {
  if (isNil(auditionId) || isNil(applicationId)) {
    return Promise.resolve();
  }

  const endpoint = `${getApplicationEndpoint(
    auditionId,
    applicationId,
  )}/actions/updateUnread`;
  return Api.update(endpoint, { unreadMessageForAccountId }, resourceType);
}

export function searchApplicationsByName(
  query,
  includeAccount,
  shouldSearchUnread,
  accountId,
) {
  if (isNil(query) || isNil(includeAccount)) {
    return Promise.resolve();
  }
  const endpoint = `auditions/actions/searchApplicationsByName`;
  const body = { resourceType: 'audition-search-actions', searchTerm: query };
  const searchUnreadOnlyFilter = shouldSearchUnread && {
    filter: { unreadMessageForAccountId: accountId },
  };
  const params = {
    ...searchUnreadOnlyFilter,
    include: `${includeAccount}, audition, lastMessage`,
  };
  return Api.create(endpoint, body, undefined, params);
}

export function fetchUnreadApplications(accountId, accountTypeId, accountType, unreadMessageCount) {
  if (!accountId) {
    return Promise.resolve(null);
  }

  const includeParam = AccountType.isTalent(accountType)
    ? 'audition.producer, lastMessage'
    : 'audition, talent, lastMessage';

  const endpoint = `${accountType}s/${accountTypeId}/applications`;

  const params = {
    filter: {
      unreadMessageForAccountId: accountId,
    },
    page: { limit: unreadMessageCount },
    include: includeParam,
  };

  return Api.find(endpoint, params);
}

export function markAllApplicationMessagesAsRead() {
  const endpoint = 'auditions/actions/markAllRead';
  const markReadResourceType = 'audition-message-mark-all-read-actions';

  return Api.update(endpoint, {}, markReadResourceType);
}
