import React, { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import { getAccountType, AccountType } from 'modules/accounts';
import { loadAsset } from 'modules/assets';
import { DateUtil } from 'modules/core';
import { Avatar, Card, LineDivider } from 'modules/ui';
import { Talent, getTalentById } from 'modules/talents';

import OfferStatusDisplay from './OfferStatusDisplay';
import Offer from '../services/offer';
import classes from './OfferCard.module.scss';

const OFFER_CARD_TITLES = {
  PROJECT: 'PROJECT',
  CONTENT_CREATOR: 'CONTENT CREATOR',
  ROLE: 'ROLE',
  RECIEVED: 'DATE RECIEVED',
  EXPIRATION: 'EXPIRATION DATE',
};
function OfferCard({ offer, onClick }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const accountType = useSelector(getAccountType);
  const talentId = get(offer, 'talentId');
  const talent = useSelector(getTalentById(talentId));

  const projectRole = get(offer, 'projectRole');
  const project = get(projectRole, 'project');
  const agentsTalent = get(offer, 'talent');

  const [image, setImage] = useState({});

  useEffect(() => {
    if (AccountType.isAgent(accountType)) {
      const { profileImage } = agentsTalent;
      dispatch(loadAsset(profileImage, true)).then(asset => setImage(asset));
    }
  }, [agentsTalent, dispatch, accountType]);

  const hasAgent = Talent.hasAgent(talent, get(project, 'vertical'));

  function sectionTitle(title) {
    const projectTitle = title === OFFER_CARD_TITLES.PROJECT;
    return (
      <div className={projectTitle ? classes.headerTitle : classes.title}>
        {title}
      </div>
    );
  }

  return (
    <Card width={300} className={classes.card} onClick={onClick}>
      {AccountType.isAgent(accountType) && (
        <>
          <div>
            <Avatar
              photoUrl={image}
              aria-haspopup="true"
              className={classes.avatar}
              onClick={() => history.push(`/talent/${agentsTalent.id}`)}
            />
            {agentsTalent.fullName}
          </div>
          <LineDivider />
        </>
      )}
      <div className={classes.headerContainer}>
        {sectionTitle(OFFER_CARD_TITLES.PROJECT)}
        <div className={classes.projectTitle}>
          {get(offer, 'projectRole.project.title')}
        </div>
        <OfferStatusDisplay
          resolvedStatus={Offer.getResolvedStatus(offer, true)}
          hasAgent={hasAgent}
          showHasAgent={AccountType.isTalent(accountType)}
          statusClassName={classes.status}
        />
      </div>
      <div className={classes.lineDivider}>
        <LineDivider />
      </div>
      <div className={classes.contentContainer}>
        <div>
          {sectionTitle(OFFER_CARD_TITLES.CONTENT_CREATOR)}
          <div className={classes.contentName}>
            {get(offer, 'producer.fullName')}
          </div>
        </div>
        <div>
          {sectionTitle(OFFER_CARD_TITLES.ROLE)}
          <div className={classes.contentName}>
            {get(offer, 'projectRole.name')}
          </div>
        </div>
        <div className={classes.dateContainer}>
          <div>
            {sectionTitle(OFFER_CARD_TITLES.RECIEVED)}
            {DateUtil.formatDate(get(offer, 'createdAt'))}
          </div>
          <div>
            {sectionTitle(OFFER_CARD_TITLES.EXPIRATION)}
            {DateUtil.formatDate(get(offer, 'dueDate'))}
          </div>
        </div>
      </div>
    </Card>
  );
}

OfferCard.propTypes = {
  offer: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(OfferCard);
