import React, { memo, useState, useEffect } from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
import { TextInput } from 'modules/ui';

function ProfessionalAwardInput({ value, error, disabled, onChange }) {
  const [award, setAward] = useState(value);

  useEffect(() => {
    setAward(value);
  }, [value]);

  const handleYearChange = event => {
    const newYear = event.target.value;

    const newAward = {
      ...award,
      year: trim(newYear),
    };

    setAward(newAward);

    if (onChange) {
      onChange(newAward);
    }
  };

  const handleNameChange = event => {
    const newName = event.target.value;

    const newAward = {
      ...award,
      name: newName,
    };

    setAward(newAward);

    if (onChange) {
      onChange(newAward);
    }
  };

  const year = get(award, 'year', null);
  const awardName = get(award, 'name', '');

  return (
    <Grid container item spacing={2}>
      <Grid item xs={4}>
        <NumberFormat
          customInput={TextInput}
          width={200}
          name="year"
          placeholder="Year"
          format="####"
          value={year}
          disabled={disabled}
          error={error && error.year}
          onChange={handleYearChange}
          containerClassName={disabled && 'mb-0'}
        />
      </Grid>
      <Grid item xs={8}>
        <TextInput
          name="name"
          placeholder="Name of Award"
          error={error && error.name}
          value={awardName}
          disabled={disabled}
          onChange={handleNameChange}
          containerClassName={disabled && 'mb-0'}
        />
      </Grid>
    </Grid>
  );
}

ProfessionalAwardInput.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default memo(ProfessionalAwardInput);
