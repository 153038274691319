import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTealiumScriptLoaded } from '../services/api/redux';

function TealiumScriptLoadedProvider() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.onload = () => dispatch(setTealiumScriptLoaded(true));

    return () => {
      window.onload = undefined;
    };
  }, [dispatch]);

  return null;
}

export default TealiumScriptLoadedProvider;
