import React from 'react';
import { NavigationTabLink } from 'modules/ui';

export default function TalentProfileTabs() {
  return (
    <>
      <NavigationTabLink exact to="/profile/edit">
        Personal Info
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/professional-info">
        Professional Info
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/skills">
        Your Skills
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/samples">
        Samples
      </NavigationTabLink>
      <NavigationTabLink exact to="/profile/edit/agents-and-managers">
        Agents & Managers
      </NavigationTabLink>
    </>
  );
}
