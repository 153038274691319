import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import map from 'lodash/map';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { DropdownButton, MenuItem } from 'modules/ui';
import { Colors } from 'modules/theme';
import { LodashUtil } from 'modules/core';
import { loadAuditions } from 'modules/auditions-producers';
import {
  getTopCandidatesByRoleId,
  loadTopCandidates,
} from 'modules/top-candidates';
import { getProducer } from 'modules/producers';
import {
  getActiveAuditionsByRoleId,
  getAuditionsByRoleId,
} from 'modules/auditions';
import { loadOffers } from 'modules/offers/redux';
import { Auditions } from 'modules/auditions/services';
import styles from './ProjectRoleRow.module.scss';
import { checkCanDeleteRole } from '../api';

const customStyles = {
  tableCellTextBold: {
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
};

const RESOURCE_LOAD = 1;

export default function ProjectRoleRow({
  role,
  onClick,
  onEditClick,
  onDeleteClick,
  onAuditionClick,
  projectFinished,
}) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const dropdownRef = useRef(null);

  const { name } = role;
  const roleId = get(role, 'id');
  const projectId = get(role, 'projectId');
  const { id: producerId } = producer;

  const auditions = useSelector(getAuditionsByRoleId(roleId));
  const activeAuditions = useSelector(getActiveAuditionsByRoleId(roleId));
  const topCandidates = useSelector(getTopCandidatesByRoleId(roleId));
  const [resourcesLoaded, setResourcesLoaded] = useState(0);
  const [canDeleteRole, setCanDeleteRole] = useState(false);
  const [auditionsLoaded, setAuditionsLoaded] = useState(false);

  useEffect(() => {
    dispatch(loadAuditions(producerId, { projectRoleId: roleId })).then(() => {
      setAuditionsLoaded(true);
      setResourcesLoaded(loaded => loaded + 1);
    });
  }, [dispatch, producerId, roleId]);

  useEffect(() => {
    if (producerId && role && auditionsLoaded) {
      dispatch(
        checkCanDeleteRole(producerId, projectId, roleId, auditions),
      ).then(canDelete => setCanDeleteRole(canDelete));
    }
  }, [dispatch, producerId, projectId, roleId, auditionsLoaded]);

  function handleClick() {
    if (onClick) {
      onClick(role);
    }
  }

  function handleEditClick(event) {
    if (event) {
      event.stopPropagation();
    }

    if (onEditClick) {
      dropdownRef.current.close();
      onEditClick(role);
    }
  }

  function handleDeleteClick(event) {
    if (event) {
      event.stopPropagation();
    }

    if (onDeleteClick) {
      dropdownRef.current.close();
      onDeleteClick(role);
    }
  }

  function handleAuditionClick(event) {
    if (event) {
      event.stopPropagation();
    }

    if (onAuditionClick) {
      dropdownRef.current.close();
      onAuditionClick(role);
    }
  }

  const auditionsString = `${activeAuditions.length} active`;
  const talentsString = `${size(topCandidates)} talent`;

  const loading = resourcesLoaded < RESOURCE_LOAD;

  if (loading) {
    return (
      <div className={styles.loader}>
        <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
      </div>
    );
  }

  return (
    <TableRow onClick={handleClick}>
      <TableCell
        style={customStyles.tableCellTextBold}
        component="th"
        scope="row"
      >
        {LodashUtil.truncateText(name)}
      </TableCell>
      <TableCell>{auditionsString}</TableCell>
      <TableCell>{talentsString}</TableCell>
      <TableCell>{!isEmpty(role.hiredTalents) ? 'Yes' : 'No'}</TableCell>
      <TableCell align="right">
        <DropdownButton ref={dropdownRef} iconColor={Colors.darkGrey}>
          <MenuItem text="Edit" icon="edit" onClick={handleEditClick} />
          <MenuItem
            text="Delete"
            icon="trash"
            onClick={handleDeleteClick}
            disabled={!canDeleteRole}
          />
          <MenuItem
            text="Add audition"
            icon="edit"
            onClick={handleAuditionClick}
            disabled={projectFinished}
          />
        </DropdownButton>
      </TableCell>
    </TableRow>
  );
}

ProjectRoleRow.propTypes = {
  role: PropTypes.object,
  onClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAuditionClick: PropTypes.func,
  projectFinished: PropTypes.bool,
};
