import React from 'react';
import { fade, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { Colors } from 'modules/theme';
import PropTypes from 'prop-types';
import { Icon } from 'modules/ui';
import styles from './ImageZoomSlider.module.scss';

const ACTIVE_BACKGROUND = Colors.darkBlue;
const INACTIVE_BACKGROUND = Colors.grayishBlue;

const sliderStyle = {
  root: {
    margin: 20,
    marginLeft: 10,
    marginRight: 10,
    width: 127,
  },
  thumb: {
    backgroundColor: ACTIVE_BACKGROUND,
    '&$focused, &$activated, &$jumped, &:hover': {
      boxShadow: `0px 0px 0px ${4}px ${fade(ACTIVE_BACKGROUND, 0.16)}`,
    },
  },
  track: {
    backgroundColor: ACTIVE_BACKGROUND,
    height: 2,
    borderRadius: 4,
  },
  trackAfter: {
    backgroundColor: INACTIVE_BACKGROUND,
    borderRadius: 4,
  },
};

const StyledZoomSlider = withStyles(sliderStyle)(Slider);

function ImageZoomSlider({ onChange, ...otherProps }) {
  return (
    <div className={styles.sliderContainer}>
      <Icon name="image" size={14} color={Colors.grayishBlue} />
      <StyledZoomSlider step={0.01} onChange={onChange} {...otherProps} />
      <Icon name="image" size={21} color={Colors.grayishBlue} />
    </div>
  );
}

ImageZoomSlider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default ImageZoomSlider;
