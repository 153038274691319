import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getAccountType } from 'modules/accounts';
import {
  getLatestGlobalMessagingThread,
  enlargeGlobalMessagingChat,
} from 'modules/global-messages-threads';
import { getLatestAuditionApplication } from 'modules/audition-applications';
import { enlargeAuditionApplicationChat } from 'modules/audition-messages';

import { MessagingHorizontalMenu, MessagingFrame } from '../components';
import { ChatDropdownTab } from '../const';

import classes from './MessagingPage.module.scss';

function MessagingPage({ activeTab, children }) {
  const history = useHistory();

  const accountType = useSelector(getAccountType);

  const latestThread = useSelector(getLatestGlobalMessagingThread);
  const latestAuditionApplication = useSelector(getLatestAuditionApplication);

  function handleTabChange(tabIndex) {
    if (tabIndex === ChatDropdownTab.GLOBAL) {
      enlargeGlobalMessagingChat(latestThread, accountType, history);
    } else {
      enlargeAuditionApplicationChat(latestAuditionApplication, history);
    }
  }

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <MessagingHorizontalMenu
          containerClassName={classes.content}
          tabClassName={classes.tabLink}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />
      </div>
      <MessagingFrame
        className={classNames(classes.content, classes.pageContent)}
      >
        {children}
      </MessagingFrame>
    </div>
  );
}

MessagingPage.propTypes = {
  activeTab: PropTypes.number,
  children: PropTypes.node,
};

export default MessagingPage;
