import get from 'lodash/get';
import startsWith from 'lodash/startsWith';
import { ORDERS } from '../const';

function toObject(sort) {
  const sortOrder = startsWith(sort, '-') ? ORDERS.DESC : ORDERS.ASC;
  const sortOption = sortOrder === ORDERS.DESC ? sort.substring(1) : sort;

  return { option: sortOption, order: sortOrder };
}

function fromObject(sort) {
  const option = get(sort, 'option');
  const order = get(sort, 'order');

  if (order === ORDERS.DESC) {
    return `-${option}`;
  }

  return option;
}

export default {
  toObject,
  fromObject,
};
