import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './MessagePreview.module.scss';

function MessagePreview({ className, children }) {
  return (
    <div className={classNames(classes.preview, className)}>{children}</div>
  );
}

MessagePreview.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
};

export default MessagePreview;
