import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ConfirmationDialog } from 'modules/ui';
import { getProducer } from 'modules/producers';
import { getProjectById } from 'modules/projects';
import { LodashUtil } from 'modules/core';
import { useTealiumView } from 'modules/accounts';
import { AuditionEditModal } from 'modules/auditions-producers';
import { showNotification, Notification } from 'modules/notifications';
import { ProjectRolesTable } from '../components';
import {
  getRolesByProjectId,
  loadProjectRoles,
  removeRole,
  getLoading,
  loadProjectRole,
} from '../redux';
import ProjectRoleDialog from './ProjectRoleDialog';

function ProjectRoles({ projectId, history }) {
  const dispatch = useDispatch();
  const producer = useSelector(getProducer);
  const project = useSelector(getProjectById(projectId));
  const loadingProjectRoles = useSelector(getLoading);
  const projectRoles = useSelector(getRolesByProjectId(projectId));

  const [selectedRole, setSelectedRole] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showProjectRoleDialog, setShowProjectRoleDialog] = useState(false);
  const [showAuditionModal, setShowAuditionModal] = useState(false);

  const { id: producerId } = producer;
  const selectedRoleId = get(selectedRole, 'id');
  const selectedRoleName = LodashUtil.truncateText(get(selectedRole, 'name'));

  useEffect(() => {
    dispatch(loadProjectRoles(producerId, projectId));
  }, [dispatch, producerId, projectId]);

  function handleClick(role) {
    history.push(`/projects/${role.project.id}/roles/${role.id}`);
  }

  function hideAuditionModal() {
    setSelectedRole(null);
    setShowAuditionModal(false);
  }

  function handleAuditionCreated(newAuditionId) {
    if (!newAuditionId) {
      return;
    }

    history.push(`/auditions/${newAuditionId}`);
    dispatch(loadProjectRole(producer.id, projectId, selectedRoleId));
  }

  function handleEditClick(role) {
    setSelectedRole(role);
    return setShowProjectRoleDialog(true);
  }

  function handleAuditionClick(role) {
    setSelectedRole(role);
    setShowAuditionModal(true);
  }

  function handleDeleteClick(role) {
    setSelectedRole(role);
    return setShowDeleteConfirmation(true);
  }

  function handleDeleteConfirmationClose() {
    setShowDeleteConfirmation(false);
  }

  function handleDeleteConfirmationAction() {
    setShowDeleteConfirmation(false);
    dispatch(removeRole(producerId, projectId, selectedRoleId)).then(() =>
      dispatch(
        showNotification({
          message: 'Role successfully deleted',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );
  }

  function handleNewClick() {
    setSelectedRole(null);
    setShowProjectRoleDialog(true);
  }

  function handleProjectRoleDialogCancel() {
    setShowProjectRoleDialog(false);
  }

  return (
    <>
      <ProjectRolesTable
        loading={loadingProjectRoles}
        projectRoles={projectRoles}
        onClick={handleClick}
        onNewClick={handleNewClick}
        onEditClick={handleEditClick}
        onDeleteClick={handleDeleteClick}
        onAuditionClick={handleAuditionClick}
      />
      {showDeleteConfirmation && (
        <ConfirmationDialog
          withOverlay
          title={`Are you sure you want to delete ${selectedRoleName} Role?`}
          message="By deleting this role Top Candidates list associated with it will also be deleted."
          actionBtnLabel="Yes, delete"
          cancelBtnLabel="Cancel"
          onAction={handleDeleteConfirmationAction}
          onCancel={handleDeleteConfirmationClose}
        />
      )}
      {showProjectRoleDialog && (
        <ProjectRoleDialog
          producerId={producerId}
          projectId={projectId}
          projectRole={selectedRole}
          projectRoles={projectRoles}
          onCancel={handleProjectRoleDialogCancel}
        />
      )}
      {showAuditionModal && (
        <AuditionEditModal
          project={project}
          role={selectedRole}
          onClose={hideAuditionModal}
          onSubmit={handleAuditionCreated}
        />
      )}
    </>
  );
}

ProjectRoles.propTypes = {
  projectId: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default withRouter(ProjectRoles);
