import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useWindowSize } from 'modules/core';
import NavBarMobile from './NavBarMobile';
import { Logo } from '../Logo';

import styles from './NavBar.module.scss';

function NavBar({
  className,
  showLogo,
  light,
  lightLogo = !light,
  children,
  containerClassName,
  showMobile,
  ...otherProps
}) {
  const classes = classNames(styles.navBar, light && styles.light, className);
  const { size, desktopWidth } = useWindowSize();

  if (desktopWidth || !size || !showMobile)
    return (
      <div className={classes}>
        <div>
          {showLogo && (
            <Link to="/">
              <Logo dark={!lightLogo} />
            </Link>
          )}
        </div>
        <div className={classNames(styles.navigationLinks, containerClassName)}>
          {children}
        </div>
      </div>
    );
  return <NavBarMobile {...otherProps}>{children}</NavBarMobile>;
}

NavBar.propTypes = {
  className: PropTypes.string,
  showLogo: PropTypes.bool,
  lightLogo: PropTypes.bool,
  light: PropTypes.bool,
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  showMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  showLogo: true,
  showMobile: true,
};

export default memo(NavBar);
