import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './MessagingFrame.module.scss';

function MessagingFrame({ className, children }) {
  return <div className={classNames(classes.frame, className)}>{children}</div>;
}

MessagingFrame.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default MessagingFrame;
