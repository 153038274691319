import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { InlineSections, InlineSection } from 'modules/ui';

function OfferTargetLabel({
  actorName,
  hasAgent,
  agentName,
  sectionClassname,
}) {
  return (
    <InlineSections displayEndingLine>
      {hasAgent && agentName && (
        <InlineSection title="Agent" className={sectionClassname}>
          <p className="body16">{agentName}</p>
        </InlineSection>
      )}
      <InlineSection
        title={hasAgent ? 'For Talent' : 'Talent'}
        className={sectionClassname}
      >
        <p className="body16">{actorName}</p>
      </InlineSection>
    </InlineSections>
  );
}

OfferTargetLabel.propTypes = {
  actorName: PropTypes.string.isRequired,
  hasAgent: PropTypes.bool.isRequired,
  agentName: PropTypes.string,
  sectionClassname: PropTypes.string.isRequired,
};

export default memo(OfferTargetLabel);
