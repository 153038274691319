import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { AccountType, getAccount, getAccountType } from 'modules/accounts';
import {
  loadProducerApplications,
  loadTalentApplications,
  loadUnreadApplications,
} from 'modules/audition-applications';

import { AuditionMessagingMenu } from '../fragments';
import {
  openAuditionApplicationChatPopup,
  getUnreadMessagesCount,
} from '../redux';

import AuditionApplicationChatPlaceholder from './AuditionApplicationChatPlaceholder';
import classes from './AuditionMessagingDropdownMenu.module.scss';

function AuditionMessagingDropdownMenu({
  onClose,
  closeOnCardOpen,
  isResponsiveDrawer,
}) {
  const dispatch = useDispatch();
  const [applicationsLimit, setApplicationsLimit] = useState(20);
  const account = useSelector(getAccount);
  const accountId = get(account, 'id');
  const accountType = useSelector(getAccountType);
  const unreadMessagesCount = useSelector(getUnreadMessagesCount);

  const accountTypeId = get(account, `${accountType}Id`);

  function handleCardClick(auditionId, talentId) {
    dispatch(openAuditionApplicationChatPopup(auditionId, talentId));
    if (closeOnCardOpen) {
      onClose();
    }
  }

  function handleLoadMore() {
    setApplicationsLimit(applicationsLimit + 20);
  }

  function resolveAuditionApplicationThunk() {
    const sortBy = '-updatedAt';

    if (AccountType.isProducer(accountType)) {
      return loadProducerApplications(accountTypeId, sortBy, applicationsLimit);
    }
    return loadTalentApplications(accountTypeId, sortBy, applicationsLimit);
  }

  return (
    <AuditionMessagingMenu
      className={classes.dropdownMenu}
      loadAuditionApplications={resolveAuditionApplicationThunk()}
      onCardClick={handleCardClick}
      onClose={onClose}
      cardsPlaceholder={
        <AuditionApplicationChatPlaceholder
          className={classes.dropdownPlaceholder}
        />
      }
      onLoadMoreClick={handleLoadMore}
      isResponsiveDrawer={isResponsiveDrawer}
      onFilterByUnreadClick={() => {
        return dispatch(
          loadUnreadApplications(
            accountId,
            accountTypeId,
            accountType,
            unreadMessagesCount,
          ),
        );
      }}
      accountId={accountId}
      displaySeeAll
    />
  );
}

AuditionMessagingDropdownMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  closeOnCardOpen: PropTypes.bool,
  isResponsiveDrawer: PropTypes.bool,
};

AuditionMessagingDropdownMenu.defaultProps = {
  closeOnCardOpen: true,
};

export default AuditionMessagingDropdownMenu;
