import React from 'react';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { useSelector, useDispatch } from 'react-redux';
import { getAvatar, loadAvatar } from 'modules/accounts';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getAgent, updateAgent } from '../redux';
import { ProfileDetailsForm, AgentProfileTabs } from '../components';

export default function EditPersonalPage() {
  const dispatch = useDispatch();
  const agent = useSelector(getAgent);
  const avatar = useSelector(getAvatar);

  function handleSubmitPersonalInfo(values) {
    const { firstName, lastName } = values;

    return dispatch(
      updateAgent(agent.account.id, agent.id, {
        ...values,
        ...(firstName && { firstName: trim(firstName) }),
        ...(lastName && { lastName: trim(lastName) }),
      }),
    )
      .then(payload => {
        const profileImage = get(payload, 'data.profileImage');

        dispatch(loadAvatar(profileImage));
        dispatch(
          showNotification({
            message: 'Personal info successfully saved',
            type: Notification.TYPES.SUCCESS,
          }),
        );
        return payload;
      })
      .catch(payload => payload);
  }

  if (!agent) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<AgentProfileTabs />}>
        <ProfileDetailsForm
          updatedValues={agent}
          initialValues={agent}
          image={avatar}
          onSubmit={handleSubmitPersonalInfo}
        />
      </TabLayout>
    </Page>
  );
}
