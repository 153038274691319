import React, { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { AccountType, getAccountType } from 'modules/accounts';
import { loadAsset } from 'modules/assets';
import { Section, InlineSection, Avatar, LinkWrapper } from 'modules/ui';
import { DateUtil } from 'modules/core/services';
import classes from './OfferDetailsSection.module.scss';

function OfferDetailsSection({ offer }) {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType);
  const [image, setImage] = useState();

  const { offerAmount, dueDate, offerDetails, talent } = offer;

  useEffect(() => {
    if (AccountType.isAgent(accountType)) {
      const { profileImage } = talent;
      dispatch(loadAsset(profileImage, true)).then(asset => setImage(asset));
    }
  }, [talent, dispatch, accountType]);

  return (
    <Section title="Offer details" className="mb-36">
      <Grid container item spacing={5}>
        {AccountType.isAgent(accountType) && (
          <Grid item xs={12} sm={6} md={4}>
            <InlineSection title="Talent">
              <LinkWrapper to={`/talent/${talent.id}`}>
                <div className={classes.nameContainer}>
                  <Avatar
                    photoUrl={image}
                    className={classes.avatar}
                    aria-haspopup="true"
                  />
                  <h3 className={classes.title}>{talent.fullName}</h3>
                </div>
              </LinkWrapper>
            </InlineSection>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <InlineSection title="Offer" text={offerAmount} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InlineSection
            title="Respond by"
            text={DateUtil.formatDate(dueDate)}
          />
        </Grid>
        <Grid item xs={12}>
          <InlineSection title="Details" text={offerDetails} />
        </Grid>
      </Grid>
    </Section>
  );
}

OfferDetailsSection.propTypes = {
  offer: PropTypes.object.isRequired,
};

export default memo(OfferDetailsSection);
