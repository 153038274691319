import { createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';

import get from 'lodash/get';
import isNil from 'lodash/isNil';
import unionBy from 'lodash/unionBy';

import {
  loadGlobalMessagingThread,
  findGlobalMessagingThreadWith,
  markGlobalMessagingThreadUnread,
} from 'modules/global-messages-threads';

import {
  fetchGlobalMessages,
  fetchUnreadMessagesCount,
  postGlobalMessage,
} from './api';
import { filterGlobalMessages, byThread } from './services';

const initialState = {
  messages: [],
  unreadMessagesCount: 0,
  loadingMessages: false,
};

export const loadGlobalMessages = createThunk(
  fetchGlobalMessages,
  'loadMessages',
  'globalMessaging',
);

export const loadUnreadMessagesCount = createThunk(
  fetchUnreadMessagesCount,
  'loadUnreadMessagesCount',
  'globalMessaging',
);

export const sendGlobalMessage = createThunk(
  postGlobalMessage,
  'sendMessage',
  'globalMessaging',
);

export const markGlobalMessagingThreadAsRead = (
  threadId,
  oppositeAccountId,
) => dispatch => {
  return dispatch(markGlobalMessagingThreadUnread(threadId, null)).then(() =>
    Promise.all([
      dispatch(loadGlobalMessagingThread(oppositeAccountId)),
      dispatch(loadUnreadMessagesCount()),
    ]),
  );
};

export function loadGlobalMessagesWith(
  accountId,
  oppositeAccountId,
  oppositeAccountType,
) {
  return dispatch => {
    return dispatch(loadGlobalMessagingThread(oppositeAccountId)).then(
      ({ data }) => {
        const thread = findGlobalMessagingThreadWith(
          oppositeAccountId,
          oppositeAccountType,
          data,
        );

        if (isNil(thread)) {
          return Promise.resolve([]);
        }

        const threadId = get(thread, 'id');
        const unreadMessageAccountId = get(thread, 'unreadMessageAccountId');

        return Promise.all([
          dispatch(loadGlobalMessages(threadId)),
          unreadMessageAccountId == accountId &&
            dispatch(
              markGlobalMessagingThreadAsRead(threadId, oppositeAccountId),
            ),
        ]);
      },
    );
  };
}

export const refreshGlobalMessagingWindow = (accountId, chatWindow) => {
  const { oppositeAccountId, oppositeAccountType } = chatWindow;
  return loadGlobalMessagesWith(
    accountId,
    oppositeAccountId,
    oppositeAccountType,
  );
};

export const reducer = createReducer(initialState, {
  [loadGlobalMessages.type.request]: state => {
    state.loadingMessages = true;
  },
  [loadGlobalMessages.type.success]: (state, action) => {
    state.loadingMessages = false;
    state.messages = unionBy(action.payload.data, state.messages, 'id');
  },
  [loadUnreadMessagesCount.type.success]: (state, action) => {
    state.unreadMessagesCount = get(
      action,
      'payload.data.unreadMessageThreadCount',
    );
  },
  [sendGlobalMessage.type.success]: (state, action) => {
    state.messages = [...state.messages, action.payload.data];
  },
});

export const getMessages = threadId =>
  createSelector(
    ['globalMessaging.messages'],
    messages => filterGlobalMessages(messages, byThread(threadId)),
  );

export const getUnreadMessagesCount = createSelector([
  'globalMessaging.unreadMessagesCount',
]);
