import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { ACCOUNT_TYPES, getAccount, getAccountType } from 'modules/accounts';
import { MessagingCard } from 'modules/chat';
import { getGlobalMessageThreadOppositeAccountTypeAndId } from '../services';

function GlobalMessagingCard({ thread, onClick, onClose }) {
  const account = useSelector(getAccount);
  const accountId = get(account, 'id');

  const accountType = useSelector(getAccountType);
  const {
    type: oppositeAccountType,
    account: oppositeAccount,
  } = getGlobalMessageThreadOppositeAccountTypeAndId(thread, accountType);
  const oppositeAccountId = get(oppositeAccount, 'id');

  const fullName = get(oppositeAccount, `${oppositeAccountType}.fullName`);
  const profileImage = get(
    oppositeAccount,
    `${oppositeAccountType}.profileImage`,
  );

  const unreadMessageAccountId = get(thread, 'unreadMessageAccountId');

  const lastMessage = get(thread, 'lastMessage');
  const lastMessageText = get(lastMessage, 'text');
  const createdAt = get(lastMessage, 'createdAt');

  function handleCardClick() {
    onClick(oppositeAccountId, oppositeAccountType);
  }

  return (
    <MessagingCard
      subtitle={fullName}
      photoUrl={profileImage}
      createdAt={createdAt}
      isUnread={unreadMessageAccountId === accountId}
      message={lastMessageText}
      onClick={handleCardClick}
      headingLabel={
        oppositeAccountType === ACCOUNT_TYPES.AGENT
          ? oppositeAccountType
          : undefined
      }
    />
  );
}

GlobalMessagingCard.propTypes = {
  thread: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GlobalMessagingCard;
