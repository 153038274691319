import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import find from 'lodash/find';

import {
  PageHeader,
  Button,
  DropdownButton,
  MenuItem,
  EmptyImagePlaceholder,
  EMPTY_IMAGE_PLACEHOLDER_SIZES,
} from 'modules/ui';
import { LodashUtil } from 'modules/core';

import {
  getProjectById,
  getCoverImage,
  loadCover,
  getLoadingCovers,
} from '../redux';
import styles from './ProjectHeader.module.scss';

function ProjectHeader({
  projectId,
  onToggleProjectStatus,
  onEditClick,
  onDeleteClick,
}) {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const project = useSelector(getProjectById(projectId));
  const loadingCovers = useSelector(getLoadingCovers);
  const { coverImage } = project;

  const coverIsLoading = !!find(loadingCovers, el => {
    return el === coverImage;
  });
  const coverImageSrc = useSelector(getCoverImage(coverImage));

  const [loading, setLoading] = useState(false);

  const projectTitle = get(project, 'title');
  const finished = get(project, 'finished');
  const title = `Project: ${LodashUtil.truncateText(projectTitle, 40)}`;

  const breadcrumbPaths = [
    { title: 'Projects', route: '/projects' },
    { title: projectTitle, route: `/projects/${projectId}` },
  ];

  useEffect(() => {
    if (coverImage && !coverImageSrc) {
      dispatch(loadCover(coverImage));
    }
  }, [dispatch, coverImage, coverImageSrc, coverIsLoading]);

  function handleToggleProjectStatus() {
    setLoading(true);

    const newStatus = !finished;

    return onToggleProjectStatus(newStatus).then(() => {
      setLoading(false);

      if (newStatus) {
        global.utag.link({
          event_type: 'complete_project',
          project_category: project.vertical,
          project_name: project.title,
        });
      }
    });
  }

  const buttonStyle = finished ? styles.finishedButton : styles.submitButton;
  const buttonLabel = finished ? 'Return to active' : 'Mark as done';

  const TitleComponent = (
    <div className={styles.titleContainer}>
      {coverImageSrc ? (
        <img src={coverImageSrc} alt="cover" className={styles.coverImage} />
      ) : (
        <EmptyImagePlaceholder
          isDarkBackground
          size={EMPTY_IMAGE_PLACEHOLDER_SIZES.large}
          isLoading={coverIsLoading}
        />
      )}
      <h1 className={styles.title}>{title}</h1>
    </div>
  );

  return (
    <PageHeader
      breadcrumbPaths={breadcrumbPaths}
      LeftComponent={TitleComponent}
      className={styles.container}
      actionClassName={styles.actions}
    >
      <Button
        primary
        loading={loading}
        onClick={handleToggleProjectStatus}
        className={buttonStyle}
      >
        {buttonLabel}
      </Button>
      <DropdownButton header ref={dropdownRef} arrowRightPosition="15px">
        <MenuItem text="Edit" icon="edit" onClick={onEditClick} />
        {/* <MenuItem text="Delete" icon="trash" onClick={onDeleteClick} /> */}
      </DropdownButton>
    </PageHeader>
  );
}

ProjectHeader.propTypes = {
  projectId: PropTypes.string,
  onToggleProjectStatus: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default ProjectHeader;
