import React, {
  Children,
  cloneElement,
  forwardRef,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { Dialog } from '../Dialog';

function FormDialog({ onClose, dialogMessage, children: form, ...otherProps }) {
  const formRef = useRef(null);
  const [showCloseDialog, setShowCloseDialog] = useState(false);

  function handleClose() {
    if (formRef.current.touched) {
      setShowCloseDialog(true);

      return;
    }

    if (onClose) {
      onClose();
    }
  }

  function renderForm(child) {
    return cloneElement(child, {
      ref: formRef,
      onCancel: handleClose,
    });
  }

  return (
    <Dialog onClose={handleClose} {...otherProps}>
      {Children.map(form, renderForm)}
      {showCloseDialog && (
        <ConfirmationDialog
          withOverlay
          title="Discard changes?"
          message={dialogMessage || 'Any unsaved changes will be discarded.'}
          actionBtnLabel="Discard changes"
          onCancel={() => setShowCloseDialog(false)}
          onAction={onClose}
        />
      )}
    </Dialog>
  );
}

FormDialog.propTypes = {
  ...Dialog.propTypes,
  onClose: PropTypes.func,
  children: PropTypes.node,
  dialogMessage: PropTypes.string,
};

export default forwardRef(FormDialog);
