import { Api } from 'modules/core';

const getPastRecordingEndpoint = talentId =>
  `talents/${talentId}/past-recordings`;

export function fetchPastRecordings(talentId) {
  const endpoint = getPastRecordingEndpoint(talentId);

  return Api.find(endpoint);
}

export function postPastRecording(talentId, values) {
  const { name, publisher } = values;

  const endpoint = getPastRecordingEndpoint(talentId);
  const pastRecording = { resourceType: 'past-recordings', name };

  const relationships = {
    publisher: {
      data: { type: 'publisher', id: publisher.id },
    },
    talent: {
      data: { type: 'talent', id: talentId },
    },
  };

  return Api.create(endpoint, pastRecording, relationships);
}

export function deletePastRecording(talentId, recordingId) {
  const endpoint = getPastRecordingEndpoint(talentId);

  return Api.deleteResource(`${endpoint}/${recordingId}`);
}
