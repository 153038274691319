import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Page } from 'modules/ui';
import { useTealiumView } from 'modules/accounts';
import { useBackBtnListener } from 'modules/core';
import { ProducerTalentDetails } from '../components';
import { clearTalentsFilterData, loadTalent } from '../redux';
import TalentPreviewHeader from './TalentPreviewHeader';

export default function TalentPreviewPage({ match }) {
  const dispatch = useDispatch();
  const talentId = get(match, 'params.talentId');

  useTealiumView('Talent Profile Page');

  useEffect(() => {
    dispatch(loadTalent(talentId));
  }, [dispatch, talentId]);

  useBackBtnListener(() => dispatch(clearTalentsFilterData()));

  return (
    <Page HeaderComponent={<TalentPreviewHeader talentId={talentId} />}>
      <ProducerTalentDetails talentId={talentId} />
    </Page>
  );
}

TalentPreviewPage.propTypes = {
  match: PropTypes.object,
};
