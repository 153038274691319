import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import get from 'lodash/get';
import {
  Page,
  InfoBanner,
  ActionBanner,
  PageHeader,
  Button,
  AvatarHeader,
  IconButton,
  PageLoader,
  NotFoundPage,
} from 'modules/ui';
import { useTealiumView } from 'modules/accounts';
import { getAvatar } from 'modules/accounts';
import { Notification, showNotification } from 'modules/notifications';
import { TalentDetails } from '../components';
import { getTalent } from '../redux';
import { Talent } from '../services';
import classes from './ProfilePage.module.scss';

export default function ProfilePage({ history }) {
  const dispatch = useDispatch();
  const params = useParams();

  const talent = useSelector(getTalent);
  const avatar = useSelector(getAvatar);
  const talentId = get(params, 'talentId');

  const [showActionBanner, setShowActionBanner] = useState(false);

  useTealiumView('Profile Page');

  useEffect(() => {
    if (talent) {
      setShowActionBanner(!Talent.isProfileVisible(talent));
    }
  }, [talent]);

  function handleAddSampleClick() {
    history.push('profile/edit/samples');
  }

  function handleEditButtonClick() {
    history.push('profile/edit');
  }

  function showLinkCopiedNotification() {
    return dispatch(
      showNotification({
        message: 'Profile link copied',
        type: Notification.TYPES.SUCCESS,
      }),
    );
  }

  if (!talent) {
    return <PageLoader />;
  }

  if (talentId && talentId !== talent.id) {
    return <NotFoundPage />;
  }

  const BannerComponent =
    talent &&
    (showActionBanner ? (
      <ActionBanner
        text={
          <p>
            <strong>Alert!</strong> Your profile isn’t yet visible on Ahab.
            Complete the required fields to audition.
          </p>
        }
        onButtonClick={handleEditButtonClick}
        buttonLabel="Edit profile"
      />
    ) : (
      <InfoBanner text="This is how your profile looks to content creators." />
    ));

  const HeaderComponent = (
    <PageHeader
      large
      actionClassName="mb-68"
      LeftComponent={
        <AvatarHeader avatar={avatar} person={talent} size={158} />
      }
    >
      {!showActionBanner && (
        <>
          <Button primary onClick={handleEditButtonClick}>
            Edit profile
          </Button>
          <CopyToClipboard
            text={Talent.getProfileLink(talent)}
            onCopy={showLinkCopiedNotification}
          >
            <IconButton
              iconName="share"
              iconSize={16}
              onClick={() => {}}
              className={classes.shareButton}
              iconClassName={classes.shareIcon}
              iconColor="white"
            />
          </CopyToClipboard>
        </>
      )}
    </PageHeader>
  );

  return (
    <Page BannerComponent={BannerComponent} HeaderComponent={HeaderComponent}>
      <TalentDetails talent={talent} onAddSampleClick={handleAddSampleClick} />
    </Page>
  );
}

ProfilePage.propTypes = {
  history: PropTypes.object,
};
