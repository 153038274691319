import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page, PageLoader, TabLayout } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getAgent, updateAgent } from '../redux';
import { AgentProfileTabs, AgencyInfoForm } from '../components';

export default function EditAgencyPage() {
  const dispatch = useDispatch();
  const agent = useSelector(getAgent);

  function handleSubmitAgencyInfo(values) {
    return dispatch(updateAgent(agent.account.id, agent.id, values)).then(() =>
      dispatch(
        showNotification({
          message: 'Agency info successfully saved',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );
  }

  if (!agent) {
    return <PageLoader />;
  }

  return (
    <Page>
      <TabLayout tabs={<AgentProfileTabs />}>
        <AgencyInfoForm
          initialValues={agent}
          onSubmit={handleSubmitAgencyInfo}
        />
      </TabLayout>
    </Page>
  );
}
