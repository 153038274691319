import React, { memo, useState } from 'react';
import Cropper from 'react-easy-crop';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Canvas } from 'modules/core';
import { Button, ImageZoomSlider } from 'modules/ui';
import styles from './ImageEditModal.module.scss';

const instructionClasses = classNames('instruction', styles.instructionText);

const cropperStyles = {
  cropAreaStyle: {
    border: 'none',
    color: 'rgba(255,255,255,0.4)',
  },
};

function ImageEditModal({
  onSave,
  onCancel,
  image,
  cropSize,
  cropShape,
  showGrid,
  title,
}) {
  const [cropArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [uploading, setUploading] = useState(false);

  const handleCropComplete = (canvasCroppedArea, cropAreaPixels) =>
    setCroppedArea(cropAreaPixels);
  const handleZoomChange = (event, value) => setZoom(value);

  function handleSaveClick() {
    setUploading(true);

    Canvas.getCroppedImage(image, cropArea).then(blob => onSave(blob));
  }

  return (
    <div className="modalContainer">
      <div className={styles.container}>
        <h2 className="mt-30 mb-20">{title}</h2>
        <div className={styles.cropperContainer}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            style={cropperStyles}
            onCropChange={setCrop}
            onCropComplete={handleCropComplete}
            cropShape={cropShape}
            cropSize={cropSize}
            showGrid={showGrid}
          />
          <div className={instructionClasses}>Drag to reposition</div>
        </div>
        <div className={styles.footer}>
          <ImageZoomSlider
            min={1}
            max={2}
            value={zoom}
            aria-labelledby="Zoom"
            onChange={handleZoomChange}
          />
          <div className={styles.footerButtons}>
            <Button secondary onClick={onCancel} className={styles.button}>
              Cancel
            </Button>
            <Button primary loading={uploading} onClick={handleSaveClick}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ImageEditModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  image: PropTypes.string,
  title: PropTypes.string,
  showGrid: PropTypes.bool,
  cropShape: PropTypes.string,
  cropSize: PropTypes.shape({
    width: PropTypes.object,
    height: PropTypes.object,
  }),
};

ImageEditModal.defaultProps = {
  cropShape: 'round',
  showGrid: false,
  title: 'Edit Photo',
};

export default memo(ImageEditModal);
