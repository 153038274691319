import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtil } from 'modules/core';
import PageFooter from './PageFooter';
import styles from './Page.module.scss';

export const HEADER_SCROLL_OFFSET = 233;

export default function Page({
  HeaderComponent,
  BannerComponent,
  children,
  className,
  style,
  contentStyle,
  showFooter,
  hideSubscribeButton = false,
}) {
  const contentClasses = classNames(styles.page__content, className);

  return (
    <div style={style} className={styles.page}>
      {ReactUtil.resolveComponent(HeaderComponent)}
      {ReactUtil.resolveComponent(BannerComponent)}
      <div className={contentClasses} style={contentStyle}>
        {children}
      </div>
      {showFooter && <PageFooter hideSubscribeButton={hideSubscribeButton} />}
    </div>
  );
}

Page.defaultProps = {
  showFooter: true,
};

Page.propTypes = {
  HeaderComponent: PropTypes.node,
  BannerComponent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  showFooter: PropTypes.bool,
  hideSubscribeButton: PropTypes.bool,
};
