function validateApplyOnAudition({ file }) {
  let fileError = null;
  if (!file) {
    fileError = 'Sample is required';
  }

  return {
    ...(!!fileError && { file: fileError }),
  };
}

export default {
  validateApplyOnAudition,
};
