import React, { memo, useRef, useState } from 'react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import Dictionary from 'modules/dictionary';
import {
  TextInput,
  ManagedForm,
  Radio,
  RadioGroup,
  Select,
  AutoCompleteSelect,
  CollapsibleWithButton,
} from 'modules/ui';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Project } from 'modules/projects/services';
import Validator from '../validator';
import { AudioSamples } from '../services';
import { AudioSampleGrid } from './form-grids';

function SampleDetailsForm({
  values,
  updatedValues,
  onSubmit,
  onChangeName,
  collapsed,
}) {
  const formRef = useRef();

  const [currentVertical, setCurrentVertical] = useState(
    get(values, 'vertical') || null,
  );
  const [genreOptions, setGenreOptions] = useState(
    Project.getGenresFromVertical(get(values, 'vertical')) || null,
  );

  function handleValueChange(formValues) {
    const { vertical } = formValues;
    if (vertical !== currentVertical) {
      setCurrentVertical(vertical);
      setGenreOptions(AudioSamples.getGenresFromVertical(vertical));
    }
  }

  return (
    <CollapsibleWithButton target="details" collapsed={collapsed}>
      <ManagedForm
        ref={formRef}
        submitButtonLabel="Save"
        TemplateComponent={AudioSampleGrid}
        onSubmit={onSubmit}
        onChangeValues={handleValueChange}
        initialValues={values}
        updatedValues={updatedValues}
        validate={Validator.validateSampleDetails}
        forceSubmit={!!updatedValues}
        withPrompt
      >
        <TextInput
          autoFocus
          required
          label="Sample name"
          name="name"
          placeholder="Title"
          onChange={onChangeName}
          lightInput
          expected
        />
        <RadioGroup required label="Point of view" name="pointOfView">
          {map(Dictionary.POINTS_OF_VIEW, point => {
            return <Radio label={point} value={point} />;
          })}
        </RadioGroup>
        <Select
          required
          label="Category"
          name="vertical"
          placeholder="Select category"
          lightInput
          options={Dictionary.VERTICAL_OPTIONS}
        />
        <Select
          label="Genre"
          name="genre"
          placeholder="Select genre"
          lightInput
          options={genreOptions}
          disabled={isEmpty(genreOptions)}
        />
        <Select
          required
          label="Vocal Age Range in Sample"
          name="voiceAgeRangeId"
          placeholder="Select age range"
          lightInput
          options={Dictionary.VOICE_RANGE_OPTIONS}
        />
        <AutoCompleteSelect
          required
          label="Primary Language in Sample"
          name="primaryLanguage"
          placeholder="Select primary language"
          lightInput
          options={Dictionary.LANGUAGE_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          label="Language Fluencies in Sample"
          name="foreignLanguages"
          placeholder="Select language(s)"
          lightInput
          collection={Dictionary.LANGUAGE}
          options={Dictionary.LANGUAGE_OPTIONS}
        />
        <AutoCompleteSelect
          isMultiSelect
          label="Accents in Sample"
          name="accents"
          placeholder="Select accent(s)"
          lightInput
          collection={Dictionary.ACCENTS}
          options={Dictionary.ACCENT_OPTIONS}
        />
        <div className={"label".toString()} style={{boxSizing: "border-box", padding: 5 + "px", marginTop: -20 + "px"}}>By submitting my voice sample I agree that it will be handled in accordance with the <a href="https://www.ahabtalent.com/privacy-policy/" target="blank">privacy policy</a> and may be shared with other Ahab users to assist in casting. The sample may remain on the platform until I delete my Ahab profile.</div>
      </ManagedForm>
    </CollapsibleWithButton>
  );
}

SampleDetailsForm.propTypes = {
  collapsed: PropTypes.bool,
  values: PropTypes.object,
  updatedValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onChangeName: PropTypes.func,
};

export default memo(SampleDetailsForm);
