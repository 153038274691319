import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import { Validator } from 'modules/core';

function validateListShareForm(values) {
  const email = get(values, 'email');
  const note = get(values, 'note');

  const errors = {
    email: Validator.validateEmail(email, 'Email'),
    note: Validator.validateNotEmpty(note, 'Message'),
  };

  return omitBy(errors, error => error === false);
}

function validateTopCandidatesShareForm(values) {
  const targetEmail = get(values, 'targetEmail');
  const note = get(values, 'note');

  const errors = {
    targetEmail: Validator.validateEmail(targetEmail, 'Email'),
    note: Validator.validateNotEmpty(note, 'Message'),
  };

  return omitBy(errors, error => error === false);
}

export default {
  validateTopCandidatesShareForm,
  validateListShareForm,
};
