import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import shortid from 'shortid';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import includes from 'lodash/includes';
import some from 'lodash/some';
import keys from 'lodash/keys';
import size from 'lodash/size';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import find from 'lodash/find';
import { ConfirmationDialog, FormFooter } from 'modules/ui';
import {
  getManagerAssignments,
  getManagersLoading,
  loadManagerAssignments,
} from 'modules/managers';
import { Colors } from 'modules/theme';
import {
  createManagerAssignment,
  deleteManagerAssignment,
  updateManagerAssignment,
} from 'modules/managers/redux';
import { showNotification, Notification } from 'modules/notifications';
import { Manager } from 'modules/managers/services';
import ManagerInput from '../components/ManagerInput';
import classes from './ManagersForm.module.scss';

const MANAGER_KEYS = [
  'managerFirstName',
  'managerLastName',
  'managerEmail',
  'companyPhoneNumber',
  'companyName',
];

const styles = {
  loader: {
    marginLeft: 261,
    marginBottom: 100,
  },
};

export default function ManagersForm({
  talentId,
  managerAssignmentsTouched,
  setManagerAssignmentsTouched,
}) {
  const dispatch = useDispatch();

  const [managerValues, setManagerValues] = useState(null);
  const [errors, setErrors] = useState(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentAssignmentId, setCurrentAssignmentId] = useState(null);
  const [currentAssignmentName, setCurrentAssignmentName] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const managerAssignments = useSelector(getManagerAssignments);
  const managersLoading = useSelector(getManagersLoading);

  useEffect(() => {
    dispatch(loadManagerAssignments());
  }, [dispatch]);

  useEffect(() => {
    if (!managersLoading) {
      let reducedValues = reduce(
        [...managerAssignments],
        (accumulator, assignment) => {
          return Object.assign(accumulator, {
            [assignment.id]: pick(assignment, MANAGER_KEYS),
          });
        },
        {},
      );

      if (isEmpty(reducedValues)) {
        reducedValues = {
          [`placeholder${shortid.generate()}`]: {},
        };
        setManagerAssignmentsTouched([]);
      }
      setManagerValues(reducedValues);
      setLoading(false);
    }
  }, [dispatch, managersLoading]);

  function handleManagerValueChange(assignmentKey, assignmentValue) {
    if (!includes(managerAssignmentsTouched, assignmentKey)) {
      setManagerAssignmentsTouched(managerValuesTouched => [
        ...managerValuesTouched,
        assignmentKey,
      ]);
    }
    setManagerValues({ ...managerValues, [assignmentKey]: assignmentValue });
  }

  function getAssignmentName(assignmentId) {
    const currentManager = find(managerAssignments, { id: assignmentId });
    return `manager ${currentManager.managerFirstName} ${currentManager.managerLastName}`;
  }

  function handleRemoveAssignmentClick(assignmentId) {
    if (some(managerAssignments, { id: assignmentId })) {
      setCurrentAssignmentId(assignmentId);
      setCurrentAssignmentName(getAssignmentName(assignmentId));
      setConfirmationOpen(true);
    } else {
      if (size(keys(managerValues)) === 1) {
        setManagerAssignmentsTouched([]);
        setManagerValues({ [assignmentId]: {} });
      } else {
        setManagerAssignmentsTouched(
          filter([...managerAssignmentsTouched], id => id !== assignmentId),
        );
        setManagerValues(omit({ ...managerValues }, assignmentId));
      }
      setErrors(omit({ ...errors }, assignmentId));
    }
  }

  function handleRemoveAssignment() {
    setDeleteInProgress(true);
    dispatch(deleteManagerAssignment(currentAssignmentId)).then(() => {
      dispatch(
        showNotification({
          message: `${currentAssignmentName} successfully removed`,
          type: Notification.TYPES.SUCCESS,
        }),
      );
      setErrors(omit({ ...errors }, [currentAssignmentId]));
      setConfirmationOpen(false);
      setCurrentAssignmentName(null);
      setCurrentAssignmentId(null);
      setManagerAssignmentsTouched(
        filter(
          [...managerAssignmentsTouched],
          id => id !== currentAssignmentId,
        ),
      );
      setDeleteInProgress(false);
    });
  }

  function handleSubmit() {
    let currentErrors = {};

    map(managerAssignmentsTouched, managerAssignmentKey => {
      const error = Manager.validateManagerAssignment(
        get(managerValues, managerAssignmentKey),
      );
      if (!isEmpty(error)) {
        currentErrors = { ...currentErrors, [managerAssignmentKey]: error };
      } else {
        currentErrors = omit({ ...currentErrors }, [managerAssignmentKey]);
      }
    });

    setErrors(currentErrors);

    if (isEmpty(currentErrors)) {
      setSubmitInProgress(true);

      const promises = [];

      map(managerAssignmentsTouched, managerAssignmentKey => {
        if (!some(managerAssignments, { id: managerAssignmentKey })) {
          promises.push(
            dispatch(
              createManagerAssignment(
                talentId,
                get(managerValues, managerAssignmentKey),
              ),
            ),
          );
        } else {
          promises.push(
            dispatch(
              updateManagerAssignment(
                talentId,
                managerAssignmentKey,
                get(managerValues, managerAssignmentKey),
              ),
            ),
          );
        }

        setManagerAssignmentsTouched(
          filter(
            [...managerAssignmentsTouched],
            key => key !== managerAssignmentKey,
          ),
        );
      });

      setManagerAssignmentsTouched([]);

      return Promise.all(promises).then(() => {
        global.utag.link({
          event_type: 'edit_profile',
          module_type: 'Edit profile',
          module_variation: 'Agents & Managers',
        });
        
        dispatch(
          showNotification({
            message: 'Manager successfully saved',
            type: Notification.TYPES.SUCCESS,
          }),
        );
        setSubmitInProgress(false);
      });
    }

    return null;
  }

  return (
    <>
      {loading && (
        <div style={styles.loader}>
          <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
        </div>
      )}
      {!loading && (
        <>
          {map(managerValues, (assignment, assignmentId) => (
            <ManagerInput
              key={assignmentId}
              managerAssignmentId={assignmentId}
              managerAssignment={assignment}
              onChange={handleManagerValueChange}
              onRemoveClick={() => handleRemoveAssignmentClick(assignmentId)}
              label="Manager"
              error={get(errors, assignmentId)}
            />
          ))}
          <FormFooter
            onSubmit={handleSubmit}
            inProgress={submitInProgress}
            submitDisabled={isEmpty(managerAssignmentsTouched)}
            submitButtonLabel="Save manager"
            footerClassName={classes.formFooter}
            style={{ marginBottom: 150 }}
          />
        </>
      )}
      {confirmationOpen && (
        <ConfirmationDialog
          title={`Are you sure you want to remove ${currentAssignmentName}`}
          onAction={handleRemoveAssignment}
          onCancel={() => setConfirmationOpen(false)}
          actionBtnLabel="Yes, remove"
          cancelBtnLabel="Cancel"
          inProgress={deleteInProgress}
          withOverlay
        />
      )}
    </>
  );
}

ManagersForm.propTypes = {
  talentId: PropTypes.string.isRequired,
  managerAssignmentsTouched: PropTypes.array.isRequired,
  setManagerAssignmentsTouched: PropTypes.func.isRequired,
};
