import { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import forIn from 'lodash/forIn';

export default function useLocationPageNames(pages) {
  const [currentPageName, setCurrentPageName] = useState('');
  const location = useLocation();

  useLayoutEffect(() => {
    forIn(pages, (page, url) => {
      if (location.pathname.includes(url)) {
        setCurrentPageName(page);
      }
    });
  }, [location, pages]);

  return currentPageName;
}
