import React from 'react';
import PropTypes from 'prop-types';
import { ManagedForm } from 'modules/ui';
import { DownloadButton } from 'modules/auditions';

const styles = {
  container: {
    paddingTop: 84,
    paddingBottom: 12,
    width: 552,
    display: 'flex',
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    flex: '1 1',
  },
  description: {
    fontSize: 16,
    marginTop: -26,
    paddingBottom: 32,
    lineHeight: '28px',
  },
  content: {
    height: '100%',
  },
};

export default function DownloadFileModalForm({
  title,
  description,
  buttonLabel,
  submitButtonLabel,
  fileUrl,
  onBack,
  onSubmit,
  onCancel,
  backBtnDisabled,
}) {
  return (
    <div style={styles.container}>
      <ManagedForm
        style={styles.form}
        title={title}
        forceSubmit
        showFooterDivider
        onSubmit={onSubmit}
        onCancel={onCancel}
        onBack={onBack}
        submitButtonLabel={submitButtonLabel}
        backBtnDisabled={backBtnDisabled}
      >
        <div style={styles.content}>
          <div style={styles.description}>{description}</div>
          <DownloadButton primary fileUrl={fileUrl}>
            {buttonLabel}
          </DownloadButton>
        </div>
      </ManagedForm>
    </div>
  );
}

DownloadFileModalForm.propTypes = {
  fileUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  backBtnDisabled: PropTypes.bool,
};
