import { Api } from 'modules/core';

const AUDIO_CLIP_TYPE = 'audio-clips';

function getAudioClipUrl(auditionId, applicationId, messageId) {
  return `auditions/${auditionId}/applications/${applicationId}/messages/${messageId}/clips`;
}

export function postAudioClip(auditionId, applicationId, messageId, values) {
  const endpoint = getAudioClipUrl(auditionId, applicationId, messageId);
  const clip = { resourceType: AUDIO_CLIP_TYPE, ...values };

  return Api.create(endpoint, clip);
}
