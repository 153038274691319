import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ACCOUNT_TYPES, getAccountType } from 'modules/accounts';
import { DateUtil, ReactUtil } from 'modules/core';
import { getCoverImage, getLoadingCovers } from 'modules/projects';
import {
  AvatarDescription,
  EmptyImagePlaceholder,
  EMPTY_IMAGE_PLACEHOLDER_SIZES,
} from 'modules/ui';
import { loadAsset } from 'modules/assets';

import { Audition } from '../services';
import styles from './AuditionCard.module.scss';

const footerClasses = classNames(styles.section, styles.footer);

function AuditionCard({
  audition,
  containerClassName,
  HeaderComponent,
  onClick,
}) {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType);
  const loadingCovers = useSelector(getLoadingCovers);
  const [image, setImage] = useState(null);

  const project = get(audition, 'projectRole.project');
  const vertical = get(project, 'vertical') || '-';

  const { coverImage } = project;
  const coverIsLoading = !!find(loadingCovers, cover => {
    return cover === coverImage;
  });

  const coverImageSrc = useSelector(getCoverImage(coverImage));
  const { name, description, dueDate, producer } = audition;

  const dueDateString = Audition.hasAuditionEnded(audition)
    ? 'ENDED'
    : DateUtil.formatDate(dueDate);

  useEffect(() => {
    if (audition && !image) {
      const { profileImage } = audition.producer;
      dispatch(loadAsset(profileImage, true)).then(asset => setImage(asset));
    }
  }, [audition, dispatch, image]);

  function handleClick() {
    if (onClick) {
      onClick(audition);
    }
  }

  const cardContainer = classNames(styles.card, containerClassName);
  const auditionDueDateString = `Due date: ${dueDateString}`;

  const history = useHistory();

  function goToProducerProfile() {
    history.push(`/producers/${producer.id}`);
  }

  return (
    <div className={cardContainer}>
      {accountType === ACCOUNT_TYPES.TALENT &&
        ReactUtil.resolveComponent(HeaderComponent)}
      <div
        className={styles.contentContainer}
        onClick={handleClick}
        role="button"
      >
        <div className={styles.section}>
          <h2 className={styles.name}>{name}</h2>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.detailsSection}>
          {coverImageSrc ? (
            <img
              src={coverImageSrc}
              alt="cover"
              className={styles.coverImage}
            />
          ) : (
            <EmptyImagePlaceholder
              className={styles.coverImageAlt}
              size={EMPTY_IMAGE_PLACEHOLDER_SIZES.small}
              isLoading={coverIsLoading}
            />
          )}
          <div className={styles.dateContainer}>
            <div className={styles.vertical}>{vertical}</div>
            <div className={styles.metadata}>{auditionDueDateString}</div>
          </div>
        </div>
      </div>
      <div className={footerClasses} onClick={goToProducerProfile}>
        <AvatarDescription
          verb="posted"
          avatar={image}
          className={styles.avatarName}
          personName={producer.fullName}
          containerClassName={styles.avatarDescriptionSubContainer}
        />
      </div>
    </div>
  );
}

AuditionCard.propTypes = {
  audition: PropTypes.object,
  containerClassName: PropTypes.string,
  HeaderComponent: PropTypes.node,
  onClick: PropTypes.func,
};

export default AuditionCard;
