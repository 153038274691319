import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

import { MessagingMenu, closeAllChatWindows } from 'modules/chat';
import { getAccountType, AccountType, ACCOUNT_TYPES } from 'modules/accounts';
import {
  getApplicationsSortedByLatestMessages,
  getLatestAuditionApplication,
  searchApplications,
  getSearchedApplications,
  clearSearchedApplications,
  ProducerAuditionMessagingCard,
  TalentAuditionMessagingCard,
  markAllApplicationMessagesAsRead,
} from 'modules/audition-applications';
import { EmptyPlaceholder, ConfirmationDialog } from 'modules/ui';
import { getAuditionMessagesLoading } from 'modules/audition-applications/redux';

import { enlargeAuditionApplicationChat } from '../services';
import { loadUnreadMessagesCount } from '../redux';
import classes from './AuditionMessagingMenu.module.scss';

function AuditionMessagingMenu({
  loadAuditionApplications,
  onCardClick,
  onClose,
  isResponsiveDrawer,
  onLoadMoreClick,
  onFilterByUnreadClick,
  accountId,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingApplications, setLoadingApplications] = useState(true);
  const [filteredByUnread, setFilteredByUnread] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const isLoadingMore = useSelector(getAuditionMessagesLoading);
  const auditionApplications = useSelector(
    getApplicationsSortedByLatestMessages,
  );
  const searchedApplications = useSelector(getSearchedApplications);

  const latestAuditionApplication = useSelector(getLatestAuditionApplication);

  const accountType = useSelector(getAccountType);

  const displayableApplications =
    searchedApplications.length > 0 || filteredByUnread
      ? searchedApplications
      : auditionApplications;

  const gotAllMessages =
    displayableApplications.length > 0
      ? displayableApplications.length % 20 !== 0
      : true;

  const allApplicationsAreNotRead = some(auditionApplications, application => {
    const unreadMessageAccountId = get(application, 'unreadMessageAccountId');
    return unreadMessageAccountId === accountId;
  });

  useEffect(() => {
    dispatch(loadAuditionApplications).then(() => {
      setLoadingApplications(false);
    });
  }, [dispatch, loadAuditionApplications]);

  useEffect(() => {
    if (!searchValue && !filteredByUnread) {
      dispatch(clearSearchedApplications());
    }
  }, [dispatch, searchValue, filteredByUnread]);

  function renderAuditionMessageCard(application) {
    const applicationId = get(application, 'id');

    if (AccountType.isProducer(accountType)) {
      return (
        <ProducerAuditionMessagingCard
          key={applicationId}
          application={application}
          onCardClick={onCardClick}
          onClose={onClose}
        />
      );
    }

    return (
      <TalentAuditionMessagingCard
        key={applicationId}
        application={application}
        onCardClick={onCardClick}
        onClose={onClose}
      />
    );
  }

  const includeAccount = AccountType.isProducer(accountType)
    ? ACCOUNT_TYPES.TALENT
    : ACCOUNT_TYPES.PRODUCER;

  function handleSearch(searchQuery) {
    setSearchValue(searchQuery);

    if (isEmpty(searchQuery)) {
      if (filteredByUnread) {
        setLoadingApplications(true);

        return onFilterByUnreadClick().then(() => {
          setLoadingApplications(false);
        });
      }

      return dispatch(loadAuditionApplications).then(() => {
        setLoadingApplications(false);
        dispatch(clearSearchedApplications());
      });
    }

    setLoadingApplications(true);

    return dispatch(
      searchApplications(
        searchQuery,
        includeAccount,
        filteredByUnread,
        accountId,
      ),
    ).then(() => {
      setLoadingApplications(false);
    });
  }

  function handleSeeAllClick() {
    dispatch(closeAllChatWindows());
    enlargeAuditionApplicationChat(latestAuditionApplication, history);
  }

  function handleViewAllClick() {
    setLoadingApplications(true);

    if (searchValue) {
      return dispatch(searchApplications(searchValue, includeAccount)).then(
        () => {
          setFilteredByUnread(false);
          setLoadingApplications(false);
        },
      );
    }
    return dispatch(loadAuditionApplications).then(() => {
      setFilteredByUnread(false);
      dispatch(clearSearchedApplications());
      setLoadingApplications(false);
    });
  }

  function handleFilterByUnread() {
    setLoadingApplications(true);

    if (!isEmpty(searchedApplications)) {
      return dispatch(
        searchApplications(searchValue, includeAccount, true, accountId),
      ).then(() => {
        setFilteredByUnread(true);
        setLoadingApplications(false);
      });
    }

    return onFilterByUnreadClick().then(() => {
      setFilteredByUnread(true);
      setLoadingApplications(false);
    });
  }

  function handleMarkAllAsRead() {
    setLoadingApplications(true);
    setShowConfirmationDialog(false);

    dispatch(markAllApplicationMessagesAsRead()).then(() => {
      dispatch(loadUnreadMessagesCount());
      if (searchValue) {
        return dispatch(
          searchApplications(
            searchValue,
            includeAccount,
            filteredByUnread,
            accountId,
          ).then(() => {
            setLoadingApplications(false);
          }),
        );
      }
      if (filteredByUnread) {
        return onFilterByUnreadClick().then(() => {
          setLoadingApplications(false);
        });
      }

      return dispatch(loadAuditionApplications).then(() => {
        setLoadingApplications(false);
      });
    });
  }

  const noUnreadPlaceholder = filteredByUnread ? (
    <EmptyPlaceholder
      title="No unread messages"
      description="Click View all to view all of your messages"
      containerClassName={classes.emptyPlaceholderContainer}
    />
  ) : null;

  return (
    <>
      {showConfirmationDialog && (
        <ConfirmationDialog
          withOverlay
          title="Are you sure you want to mark all messages as read?"
          actionBtnLabel="Yes"
          cancelBtnLabel="Cancel"
          onCancel={() => {
            setShowConfirmationDialog(false);
          }}
          onAction={handleMarkAllAsRead}
        />
      )}
      <MessagingMenu
        searchPlaceholder="Search messages by name"
        isLoading={loadingApplications}
        onSearch={handleSearch}
        onSeeAllClick={handleSeeAllClick}
        onClose={onClose}
        isResponsiveDrawer={isResponsiveDrawer}
        loadMoreDisabled={gotAllMessages}
        onLoadMoreClick={onLoadMoreClick}
        isLoadingMore={isLoadingMore}
        onFilterByUnreadClick={handleFilterByUnread}
        onViewAllClick={handleViewAllClick}
        onMarkAllAsReadClick={() => {
          setShowConfirmationDialog(true);
        }}
        showMarkAllButton={allApplicationsAreNotRead}
        isFilterByUnreadActive={filteredByUnread}
        cardsPlaceholder={noUnreadPlaceholder}
        {...otherProps}
      >
        {displayableApplications.map(renderAuditionMessageCard)}
      </MessagingMenu>
    </>
  );
}

AuditionMessagingMenu.propTypes = {
  loadAuditionApplications: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isResponsiveDrawer: PropTypes.bool,
  onLoadMoreClick: PropTypes.func.isRequired,
  onFilterByUnreadClick: PropTypes.func.isRequired,
  accountId: PropTypes.string,
};

export default AuditionMessagingMenu;
