import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'modules/ui';
import styles from './ChooseSignUpPageCard.module.scss';

export default function ChooseSignUpPageCard({
  Icon,
  onClick,
  cardTitle,
  cardSubtitle,
  buttonText,
}) {
  return (
    <Card className={styles.card} width={264} height={319}>
      <Icon className={styles.icon} />
      <h1 className={styles.cardTitle}>{cardTitle}</h1>
      <div className={styles.cardSubtitle}>{cardSubtitle}</div>
      <div className={styles.buttonContainer}>
        <Button className={styles.button} onClick={onClick} primary>
          {buttonText}
        </Button>
      </div>
    </Card>
  );
}

ChooseSignUpPageCard.propTypes = {
  Icon: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  cardTitle: PropTypes.string.isRequired,
  cardSubtitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};
