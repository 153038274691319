import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { Card, Avatar, LinkWrapper } from 'modules/ui';
import { LodashUtil } from 'modules/core';
import { Talent, TalentCardDropdown } from 'modules/talents';
import styles from './TalentCardHeader.module.scss';

export default function TalentCardHeader({
  talent,
  avatar,
  onClick,
  cardClassName,
  showOnlyHeader,
  onAddToList,
  onRemoveFromList,
  onSetVisibility,
  onShareProfile,
  talentVisibleStatus,
  profileLink,
}) {
  const { fullName, country, state, city } = talent;
  const profileLinkRelative = Talent.getRelativeProfileLink(talent);

  function handleClick() {
    if (onClick) {
      onClick(talent);
    }
  }

  const location = compact([
    city,
    get(state, 'name'),
    get(country, 'name'),
  ]).join(', ');

  const cardClasses = classNames(styles.card, cardClassName);

  if (showOnlyHeader) {
    return (
      <Card className={cardClasses} width={264} height={187}>
        <div role="button" onClick={handleClick}>
          <div className={styles.header}>
            <LinkWrapper to={profileLinkRelative}>
              <Avatar photoUrl={avatar} size={80} />
            </LinkWrapper>
            <div className={styles.talentName}>
              <LinkWrapper to={profileLinkRelative}>{fullName}</LinkWrapper>
            </div>
            {location && (
              <div className={styles.talentLocation}>
                {LodashUtil.truncateText(location, 50)}
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
  return (
    <div className={styles.header}>
      <div className={styles.actionDropdown}>
        <TalentCardDropdown
          onAddToList={onAddToList}
          onRemoveFromList={onRemoveFromList}
          onSetVisibility={onSetVisibility}
          onShareProfile={onShareProfile}
          talentVisibleStatus={talentVisibleStatus}
          profileLink={profileLink}
        />
      </div>
      <LinkWrapper to={profileLinkRelative}>
        <Avatar photoUrl={avatar} size={80} />
      </LinkWrapper>
      <div className={styles.talentName}>
        <LinkWrapper to={profileLinkRelative}>{fullName}</LinkWrapper>
      </div>
      {location && (
        <div className={styles.talentLocation}>
          {LodashUtil.truncateText(location, 50)}
        </div>
      )}
    </div>
  );
}

TalentCardHeader.propTypes = {
  talent: PropTypes.object.isRequired,
  cardClassName: PropTypes.string,
  avatar: PropTypes.string,
  onClick: PropTypes.func,
  showOnlyHeader: PropTypes.func,
  onAddToList: PropTypes.func,
  onRemoveFromList: PropTypes.func,
  onSetVisibility: PropTypes.func,
  onShareProfile: PropTypes.func,
  talentVisibleStatus: PropTypes.number,
  profileLink: PropTypes.string.isRequired,
};

TalentCardHeader.defaultProps = {
  cardClassName: styles['card--cols3'],
};
