import omitBy from 'lodash/omitBy';
import get from 'lodash/get';
import { Validator } from 'modules/core';

function validateInviteTalent(values) {
  const sendTo = get(values, 'sendTo');

  const errors = {
    sendTo: Validator.validateNotEmpty(sendTo, 'Talent name'),
  };

  return omitBy(errors, error => error === false);
}

function validateInviteTalentFromSearch(values) {
  const projectId = get(values, 'projectId');
  const auditionId = get(values, 'auditionId');
  const errors = {
    projectId: Validator.validateNotEmpty(projectId, 'Project name'),
    auditionId: Validator.validateNotEmpty(auditionId, 'Audition name'),
  };
  return omitBy(errors, error => error === false);
}

export default {
  validateInviteTalent,
  validateInviteTalentFromSearch,
};
