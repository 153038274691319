import React, {
  memo,
  Children,
  cloneElement,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { ReactUtil } from 'modules/core';
import isEqual from 'lodash/isEqual';
import Form from './Form';
import useForm from './useForm';
import { FormUtil } from './services';

const ManagedForm = forwardRef((props, ref) => {
  const {
    onSubmit,
    onChangeValues,
    validate,
    children,
    withPrompt,
    TemplateComponent,
    initialValues,
    updatedValues,
    forceSubmit,
    submitDisabled,
    removeErrorsKeys,
    ...otherProps
  } = props;

  const { filteredValues, expectedKeys } = FormUtil.extractFromChildren(
    initialValues,
    children,
  );

  const {
    values,
    errors,
    hasErrors,
    resetForm,
    handleSubmit,
    setValue,
    setDefaultValue,
    inProgress,
    touched,
    validateForm,
    getHasChanges,
    setMultipleValues,
  } = useForm(
    onSubmit,
    onChangeValues,
    validate,
    filteredValues,
    expectedKeys,
    forceSubmit,
    removeErrorsKeys,
  );

  useImperativeHandle(ref, () => ({
    reset: resetForm,
    validate: validateForm,
    submit: handleSubmit,
    setValue,
    getHasChanges,
    touched,
  }));

  useEffect(() => {
    if (updatedValues && !isEqual(initialValues, updatedValues)) {
      setMultipleValues(updatedValues);
    }
  }, [updatedValues]);

  function renderChild(child) {
    if (!child) {
      return null;
    }

    const {
      props: { name, defaultValue },
    } = child;

    if (defaultValue) {
      setDefaultValue(name, defaultValue);
    }

    return cloneElement(child, {
      value: values[name],
      error: errors[name],
      setFormValue: setValue,
    });
  }

  const formChildren = Children.map(children, renderChild);

  return (
    <Form
      onSubmit={handleSubmit}
      submitDisabled={hasErrors || submitDisabled}
      inProgress={inProgress}
      {...otherProps}
    >
      {withPrompt && <Prompt when={touched} message="Discard changes?" />}
      {TemplateComponent &&
        ReactUtil.resolveComponent(TemplateComponent, {
          children: formChildren,
        })}
      {!TemplateComponent && formChildren}
    </Form>
  );
});

ManagedForm.propTypes = {
  ...Form.propTypes,
  TemplateComponent: PropTypes.elementType,
  validate: PropTypes.func,
  forceSubmit: PropTypes.bool,
  withPrompt: PropTypes.bool,
  removeErrorsKeys: PropTypes.array,
};

export default memo(ManagedForm);
