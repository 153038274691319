import React from 'react';
import PropTypes from 'prop-types';
import join from 'lodash/join';
import Grid from '@material-ui/core/Grid';
import { Section, InlineSection, SectionLoader } from 'modules/ui';
import Dictionary, { DictionaryUtil } from 'modules/dictionary';
import { VoiceSkills } from '../services';

function VoiceSkillsSection({ talent, placeholderText }) {
  if (!talent) {
    return <SectionLoader />;
  }

  const {
    preferredGenres,
    primaryLanguages,
    accents,
    foreignLanguages,
    recordingExperienceId,
    voiceAgeRanges,
    nativeRegionalAccents,
  } = talent;

  const recordingExperienceObjs = DictionaryUtil.getDictionaryObjects(
    recordingExperienceId,
    Dictionary.RECORDING_EXPERIENCES,
  );

  const recordingExperienceText = VoiceSkills.getRecordingExperienceString(
    recordingExperienceObjs,
  );
  const foreignLanguagesText = foreignLanguages && foreignLanguages.join(', ');
  const genresText = preferredGenres && preferredGenres.join(', ');
  const accentsText = accents && accents.join(', ');

  return (
    <Section title="Voice Skills" className="mb-36">
      <Grid container item spacing={2}>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Vocal Age Range"
            text={VoiceSkills.getRangeString(voiceAgeRanges)}
            placeholderText={placeholderText || 'Add vocal age range.'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Audiobooks Read"
            text={recordingExperienceText}
            placeholderText={placeholderText || 'Add your experience.'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Preferred audiobook genres"
            text={genresText}
            placeholderText={
              placeholderText || 'Add your preferred audiobook genres.'
            }
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Primary languages"
            text={join(primaryLanguages, ', ')}
            placeholderText={placeholderText || 'Add primary languages.'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Accents"
            text={accentsText}
            placeholderText={placeholderText || 'Add accents.'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Language Fluencies"
            text={foreignLanguagesText}
            placeholderText={placeholderText || 'Add language fluencies.'}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <InlineSection
            title="Regional Accents"
            text={nativeRegionalAccents}
            placeholderText={placeholderText || 'Add regional accents.'}
          />
        </Grid>
      </Grid>
    </Section>
  );
}

VoiceSkillsSection.propTypes = {
  talent: PropTypes.object,
  placeholderText: PropTypes.string,
};

export default VoiceSkillsSection;
