import React, { memo, useState, useRef, cloneElement } from 'react';
import size from 'lodash/size';
import map from 'lodash/map';
import includes from 'lodash/includes';
import get from 'lodash/get';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DropdownMenu, OptionUI, TextInput, SelectArrow } from 'modules/ui';
import { Colors } from 'modules/theme';
import { Utils } from 'modules/core/services';
import styles from './Select.module.scss';

const selectStyle = {
  color: 'transparent',
  textShadow: `0px 0px 0px ${Colors.darkBlue}`,
};

const dropdownMenuStyle = {
  maxHeight: 300,
};

function SelectUI({
  name,
  label,
  value,
  displayValue,
  disabled,
  options,
  menuStyle,
  placeholder,
  onChange,
  onRemoveAll,
  className,
  width,
  required,
  isMultiSelect,
  hideIcon,
  onInputChange,
  activeOptions,
  arrowStyle,
  customComponent,
  isInModal,
  showCaret,
  isAutoComplete,
  onClick,
  ...otherProps
}) {
  const inputEl = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleSelectFocus(event) {
    if (onClick) {
      onClick();
      return;
    }

    setAnchorEl(event.currentTarget);
    if (!isMultiSelect && !isAutoComplete) {
      inputEl.current.blur();
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleRemoveAll() {
    onRemoveAll();
    handleClose(true);
  }

  function handleChange(newValue = null, newLabel = '') {
    const event = { target: { name, value: newValue, label: newLabel } };
    onChange(event);

    if (isMultiSelect) {
      inputEl.current.focus();
      return;
    }

    handleClose();
  }

  function isOptionDisabled(optionValue) {
    if (!activeOptions) {
      return disabled;
    }

    return !includes(activeOptions, optionValue);
  }

  function renderOption(option) {
    const childValue = get(option, 'value');
    const childLabel = get(option, 'label');

    const selected = isMultiSelect
      ? includes(value, childValue)
      : value === childValue;

    if (customComponent) {
      return cloneElement(customComponent, {
        key: `${name}-${childValue}`,
        value: childValue,
        label: childLabel,
        selected,
        onClick: () => handleChange(childValue, childLabel),
      });
    }

    return (
      <OptionUI
        divider
        menuStyle={menuStyle}
        key={`${name}-${childValue}`}
        value={value}
        label={childLabel}
        isCheckbox={!!isMultiSelect}
        hideIcon={hideIcon}
        selected={selected}
        disabled={isOptionDisabled(childValue)}
        onClick={() => handleChange(childValue, childLabel)}
      />
    );
  }

  const inputClasses = classNames(
    'showCursor',
    styles['select-input'],
    className,
  );
  const dropdownWidth =
    width || (inputEl.current && inputEl.current.getBoundingClientRect().width);

  const hasOptions = size(options) !== 0;
  const showDropdown = !!anchorEl && hasOptions;
  const showArrow = hasOptions && !disabled;

  return (
    <>
      <TextInput
        setRef={inputEl}
        required={required}
        label={label}
        onClick={handleSelectFocus}
        disabled={onInputChange ? disabled : disabled || !hasOptions}
        placeholder={placeholder}
        name={name}
        value={displayValue}
        className={inputClasses}
        RightIconComponent={
          showArrow && (
            <SelectArrow className={styles.arrow} style={arrowStyle} />
          )
        }
        onChange={onInputChange}
        style={showCaret ? {} : selectStyle}
        onFocus={
          !isInModal
            ? () => Utils.hideAutocompleteOnFocus(inputEl)
            : () =>
                Utils.hideAutocompleteOnFocus(
                  inputEl,
                  Utils.PREVENT_AUTOCOMPLETE_IN_MODAL,
                )
        }
        {...otherProps}
      />
      {showDropdown && (
        <DropdownMenu
          open
          onClose={handleClose}
          width={dropdownWidth}
          anchorEl={anchorEl}
          arrowRightPosition="20px"
          dropdownStyle={dropdownMenuStyle}
        >
          {!required && (
            <span
              role="none"
              className={`label ${styles['option-remove']}`}
              onClick={handleRemoveAll}
            >
              Remove Selection
            </span>
          )}
          {map(options, renderOption)}
        </DropdownMenu>
      )}
    </>
  );
}

SelectUI.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  hideIcon: PropTypes.bool,
  menuStyle: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onRemoveAll: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  customComponent: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
  ),
  onInputChange: PropTypes.func,
  activeOptions: PropTypes.array,
  arrowStyle: PropTypes.object,
  isInModal: PropTypes.bool,
  showCaret: PropTypes.bool,
  isAutoComplete: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(SelectUI);
