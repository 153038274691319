import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactUtil } from 'modules/core/services';
import { SectionLoader } from 'modules/ui';
import styles from './Section.module.scss';

function getPlaceholder(text) {
  return <p className="body14 grayishBlueColor">{text}</p>;
}

function Section({
  title,
  titleStyle,
  titleClassName,
  isEmpty,
  placeholderText,
  PlaceholderComponent,
  ActionComponent,
  children,
  style,
  loading,
  className,
  titleContainerClassName,
}) {
  const placeholder = PlaceholderComponent
    ? ReactUtil.resolveComponent(PlaceholderComponent)
    : getPlaceholder(placeholderText);

  const titleClasses = classNames(styles.title, titleClassName);
  const classes = classNames(styles.container, className);
  const sectionEmpty = isEmpty || !children;

  if (loading) {
    return <SectionLoader className="mt-56" />;
  }

  return (
    <div className={classes} style={style}>
      <div
        className={classNames(styles.titleContainer, titleContainerClassName)}
      >
        <h3 className={titleClasses} style={titleStyle}>
          {title}
        </h3>
        {ActionComponent && ReactUtil.resolveComponent(ActionComponent)}
      </div>
      {sectionEmpty && placeholder}
      {!sectionEmpty && children}
    </div>
  );
}

Section.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
  placeholderText: PropTypes.string,
  PlaceholderComponent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  titleContainerClassName: PropTypes.string,
  style: PropTypes.object,
  titleClassName: PropTypes.string,
  titleStyle: PropTypes.object,
  ActionComponent: PropTypes.node,
};

export default Section;
