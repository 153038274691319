import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'modules/ui';
import Collapsible from './Collapsible';
import styles from './CollapsibleWithButton.module.scss';

export default function CollapsibleWithButton({ collapsed, children, target }) {
  const [showChildren, setShowChildren] = useState(!collapsed);

  useEffect(() => {
    setShowChildren(!collapsed);
  }, [collapsed]);

  function handleToggleClick() {
    setShowChildren(!showChildren);
  }

  const detailsText = showChildren ? `Hide ${target}` : `Show ${target}`;

  return (
    <div className={styles.container}>
      <div className={showChildren && styles.expandedChildren}>
        <Collapsible collapsed={!showChildren}>{children}</Collapsible>
      </div>
      <Button
        small
        primary
        onClick={handleToggleClick}
        className={styles.toggleButton}
      >
        {detailsText}
      </Button>
    </div>
  );
}

CollapsibleWithButton.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.node,
  target: PropTypes.string,
};

CollapsibleWithButton.defaultProps = {
  collapsed: true,
  target: '',
};
