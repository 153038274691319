import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// TODO: Import through a barrel file once circular dependencies resolved.
import withPopupWindow from 'modules/chat/components/ChatPopupWindow';
import { Drawer } from 'modules/ui';
import { useWindowSize } from 'modules/core';

import { AuditionApplicationChatWindow } from '../fragments';

import AuditionApplicationChatPopupOptions from './AuditionApplicationChatPopupOptions';
import { closeAuditionApplicationChatPopup } from '../redux';
import classes from './AuditionApplicationChatPopup.module.scss';

const AuditionApplicationPopupWindow = withPopupWindow(
  AuditionApplicationChatWindow,
);

function AuditionApplicationChatPopup({ auditionId, talentId }) {
  const dispatch = useDispatch();
  const { desktopWidth } = useWindowSize();

  function handleCloseDrawer() {
    dispatch(closeAuditionApplicationChatPopup(auditionId, talentId));
  }

  if (desktopWidth) {
    return (
      <AuditionApplicationPopupWindow
        auditionId={auditionId}
        talentId={talentId}
        headerClassName={classes.chatHeader}
        headingTitleClassName={classes.chatHeaderText}
        headingSubtitleClassName={classes.chatHeaderText}
        displayAvatar={false}
        options={
          <AuditionApplicationChatPopupOptions
            auditionId={auditionId}
            talentId={talentId}
          />
        }
      />
    );
  }

  return (
    <Drawer
      open
      onClose={handleCloseDrawer}
      drawerTitle="Chat"
      containerClassName={classes.drawerContainer}
    >
      <AuditionApplicationPopupWindow
        auditionId={auditionId}
        talentId={talentId}
        headerClassName={classes.chatHeader}
        headingTitleClassName={classes.chatHeaderText}
        headingSubtitleClassName={classes.chatHeaderText}
        displayAvatar={false}
      />
    </Drawer>
  );
}

AuditionApplicationChatPopup.propTypes = {
  auditionId: PropTypes.string.isRequired,
  talentId: PropTypes.string.isRequired,
};

export default AuditionApplicationChatPopup;
