import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'modules/ui';
import { Icon } from '../Icon';
import styles from './IconButton.module.scss';

function IconButton({
  iconName,
  iconColor,
  iconSize,
  iconClassName,
  className,
  label,
  disabled,
  showActiveIndicator,
  isFlippedVertical,
  ...otherProps
}) {
  const classes = classNames(
    styles.btn,
    disabled && styles.disabled,
    className,
  );

  return (
    <Button
      small
      type="button"
      className={classes}
      disabled={disabled}
      {...otherProps}
    >
      <Icon
        name={iconName}
        color={iconColor}
        size={iconSize}
        className={iconClassName}
        isFlippedVertical={isFlippedVertical}
      />
      {label}
      {showActiveIndicator && <div className={styles.activeIndicator} />}
    </Button>
  );
}

IconButton.propTypes = {
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  showActiveIndicator: PropTypes.bool,
  isFlippedVertical: PropTypes.bool,
};

IconButton.defaultProps = {
  isFlippedVertical: false,
  showActiveIndicator: false,
};

export default memo(IconButton);
