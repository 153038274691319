import _ from 'lodash';
import { Validator } from 'modules/core';

function validateSampleDetails({
  name,
  vertical,
  pointOfView,
  genre,
  primaryLanguage,
  voiceAgeRangeId,
}) {
  const errors = {
    name: Validator.validateNotEmpty(name, 'Name'),
    vertical: Validator.validateNotEmpty(vertical, 'Sample category'),
    pointOfView: Validator.validateNotEmpty(pointOfView, 'Point of view'),
    primaryLanguage: Validator.validateNotEmpty(
      primaryLanguage,
      'Primary language',
    ),
    voiceAgeRangeId: Validator.validateNotEmpty(
      voiceAgeRangeId,
      'Vocal Age Range',
    ),
  };

  return _.omitBy(errors, error => error === false);
}

export default {
  validateSampleDetails,
};
