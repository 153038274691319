import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import { useLocationPageNames } from 'modules/core';
import { NavBar, NavBarLink } from 'modules/ui';
import {
  AccountAvatarMenu,
  AccountNavBarEnvelope,
  PRODUCER_PAGES,
} from 'modules/accounts';
import { getProducer } from '../redux';

function ProducerNavBar({ avatar, onLogout, history }) {
  const windowLocation = useLocation();
  const currentPage = useLocationPageNames(PRODUCER_PAGES.PAGES);
  const producer = useSelector(getProducer);

  const fullName = get(producer, 'fullName');
  const city = get(producer, 'city');
  const state = get(producer, 'state.name');
  const country = get(producer, 'country.name');

  function handleEditProfileClick() {
    history.push('/profile/edit');
  }

  function handleSettingsClick() {
    history.push('/settings/change-password');
  }

  return (
    <NavBar
      activePageName={currentPage}
      rightIconComponent={<AccountNavBarEnvelope />}
      showMobile={false}
      className="pt-4"
    >
      <NavBarLink
        to="/"
        id="projects-link"
        isActive={(match, location) => {
          return (
            location.pathname === '/' ||
            startsWith(location.pathname, '/projects') ||
            startsWith(location.pathname, '/auditions')
          );
        }}
      >
        Projects
      </NavBarLink>
      <NavBarLink
        to="/search"
        id="search-link"
        isActive={(match, location) => {
          return (
            location.pathname === '/search' ||
            startsWith(location.pathname, '/talent')
          );
        }}
      >
        Talent Search
      </NavBarLink>
      <NavBarLink to="/lists" id="lists-link">
        Lists
      </NavBarLink>
      <AccountNavBarEnvelope />
      <AccountAvatarMenu
        avatar={avatar}
        fullName={fullName}
        city={city}
        state={state}
        country={country}
        onLogout={onLogout}
        onEditProfile={handleEditProfileClick}
        onSettings={handleSettingsClick}
        isActive={startsWith(windowLocation.pathname, '/profile')}
        enableHoverBorder
      />
    </NavBar>
  );
}

ProducerNavBar.propTypes = {
  onLogout: PropTypes.func,
  history: PropTypes.object,
  avatar: PropTypes.string,
};

export default withRouter(ProducerNavBar);
