import React from 'react';
import PropTypes from 'prop-types';
import join from 'lodash/join';
import Grid from '@material-ui/core/Grid';

import { Section, InlineSection, SectionLoader } from 'modules/ui';

export default function PersonalInfoSection({ talent, placeholderText }) {
  if (!talent) {
    return <SectionLoader />;
  }

  const { genders, ethnicities, pronouns } = talent;

  return (
    <Section title="Personal Info" className="mb-36">
      <Grid container item spacing={2}>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Pronouns"
            text={join(pronouns, ', ')}
            placeholderText={placeholderText || 'Add pronouns.'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Gender"
            text={join(genders, ', ')}
            placeholderText={placeholderText || 'Add gender.'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Cultural Background"
            text={join(ethnicities, ', ')}
            placeholderText={placeholderText || 'Add cultural background.'}
          />
        </Grid>
      </Grid>
    </Section>
  );
}

PersonalInfoSection.propTypes = {
  talent: PropTypes.object.isRequired,
  placeholderText: PropTypes.string,
};
