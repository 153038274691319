import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Section, InlineSection } from 'modules/ui';
import DownloadButton from './DownloadButton';

function DetailsSection({ audition, ...otherProps }) {
  const name = get(audition, 'name');
  const description = get(audition, 'description');
  const scriptNotes = get(audition, 'scriptNotes');
  const scriptUrl = get(audition, 'scriptUrl');

  const handleDownloadClick = () => {
    global.utag.link({
      event_type: 'file_download',
      page_type: 'Audition Detail Page',
      module_type: name,
    });
  };

  return (
    <Section title="Audition Details" {...otherProps}>
      <InlineSection
        title="Description"
        text={description}
        className="mb-36"
        placeholderText="No description to show"
      />
      <InlineSection title="Script">
        {scriptNotes && <div className="body16">{scriptNotes}</div>}
        {scriptUrl && (
          <DownloadButton
            className="mt-16"
            primary
            fileUrl={scriptUrl}
            onClick={handleDownloadClick}
          >
            Download Script
          </DownloadButton>
        )}
      </InlineSection>
    </Section>
  );
}

DetailsSection.propTypes = {
  audition: PropTypes.object,
  className: PropTypes.string,
};

export default DetailsSection;
