import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  EmptyPlaceholder,
  SectionLoader,
  TableWithPagination,
} from 'modules/ui';
import { HEADER_SCROLL_OFFSET } from 'modules/ui/components/Page';
import styles from './TopCandidatesSharingTable.module.scss';
import TopCandidatesSharingTableRow from './TopCandidatesSharingTableRow';
import TopCandidatesSharingTableHeader from './TopCandidatesSharingTableHeader';

const customStyles = {
  emptyPlaceholder: {
    maxHeight: 524,
    marginBottom: 78,
  },
};

function TopCandidatesSharingTable({
  topCandidates,
  sort,
  loading,
  onSortClick,
  token,
}) {
  if (loading) {
    return <SectionLoader />;
  }

  if (isEmpty(topCandidates)) {
    return (
      <EmptyPlaceholder
        title="List is empty"
        description="Contact content creator for more information"
        style={customStyles.emptyPlaceholder}
      />
    );
  }

  function renderHeader() {
    return (
      <TopCandidatesSharingTableHeader sort={sort} onSortClick={onSortClick} />
    );
  }

  function renderRow(topCandidate) {
    return (
      <TopCandidatesSharingTableRow
        key={topCandidate.id}
        topCandidate={topCandidate}
        token={token}
      />
    );
  }

  if (isEmpty(topCandidates)) {
    return null;
  }

  return (
    <TableWithPagination
      count={topCandidates.length}
      rows={topCandidates}
      renderHeader={renderHeader}
      renderRow={renderRow}
      rowsPerPageLabel="Talents Per Page"
      className={styles.card}
      containerClassName={styles.containerClassName}
      pageChangeScrollY={HEADER_SCROLL_OFFSET}
    />
  );
}

TopCandidatesSharingTable.propTypes = {
  loading: PropTypes.bool,
  topCandidates: PropTypes.array.isRequired,
  sort: PropTypes.string.isRequired,
  onSortClick: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default memo(TopCandidatesSharingTable);
