import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import { Avatar } from 'modules/ui';
import { getAsset } from 'modules/assets';
import styles from './AvatarHeader.module.scss';

const textClasses = classNames('body16', styles.text);

function AvatarHeader({ person, description, avatar, size }) {
  const [avatarUrl, setAvatarUrl] = useState(avatar);
  const profileImage = get(person, 'profileImage');
  const fullName = get(person, 'fullName');
  const organizationName =
    get(person, 'organizationName') || get(person, 'agencyName');
  const professionalHeadline =
    get(person, 'professionalHeadline') || get(person, 'title');

  useEffect(() => {
    if (profileImage && !avatarUrl) {
      getAsset(profileImage).then(url => setAvatarUrl(url));
    }
  }, [avatarUrl, person, profileImage]);

  if (!person) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Avatar photoUrl={avatarUrl} size={size} />
      <div className={styles['info-container']}>
        <h1 className={styles.name}>
          {fullName}
        </h1>
        {description && <div className={textClasses}>{description}</div>}
        {organizationName ? (
          <div className={textClasses}>
            {professionalHeadline && <>{professionalHeadline},</>}{' '}
            {organizationName}
          </div>
        ) : (
          <div className={textClasses}>{professionalHeadline}</div>
        )}
      </div>
    </div>
  );
}

AvatarHeader.propTypes = {
  size: PropTypes.number,
  person: PropTypes.object,
  description: PropTypes.string,
  avatar: PropTypes.string,
};

AvatarHeader.defaultProps = {
  size: 180,
};

export default AvatarHeader;
