import _ from 'lodash';
import { Validator } from 'modules/core';

function validateProfileDetails(values) {
  const firstNameError = Validator.validateNotEmpty(
    values.firstName,
    'First name',
  );
  const lastNameError = Validator.validateNotEmpty(
    values.lastName,
    'Last name',
  );

  return {
    ...(firstNameError && { firstName: firstNameError }),
    ...(lastNameError && { lastName: lastNameError }),
  };
}

function validateSocialLinks(producer) {
  const socialMediaLinks = _.pick(producer, [
    'facebookLink',
    'instagramLink',
    'twitterLink',
    'linkedinLink',
    'imdbLink',
  ]);

  return _.reduce(
    socialMediaLinks,
    (result, link, name) => {
      if (_.isEmpty(link)) {
        return result;
      }
      const socialMedia = name.slice(0, -4); // instagramLink => instagram
      const linkError = Validator.validateLink(link, socialMedia);
      if (linkError) {
        // eslint-disable-next-line no-param-reassign
        result[name] = linkError;
      }
      return result;
    },
    {},
  );
}

export default {
  validateSocialLinks,
  validateProfileDetails,
};
