import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import { getAvatar } from 'modules/accounts';
import { SectionLoader, Section } from 'modules/ui';
import { Project } from '../services';
import {
  getLoadingProjectsStats,
  getLoadingRecentlyViewed,
  getProjectsStats,
  getRecentlyViewedProjects,
} from '../redux';
import ProjectCard from './ProjectCard';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    margin: -12,
  },
};

export default function RecentlyViewedProjects({ onProjectClick }) {
  const avatar = useSelector(getAvatar);
  const recentlyViewedProjects = useSelector(getRecentlyViewedProjects);
  const projectsStats = useSelector(getProjectsStats);
  const loadingStats = useSelector(getLoadingProjectsStats);
  const loading = useSelector(getLoadingRecentlyViewed);

  if (loading) {
    return <SectionLoader height="300px" className="mt-72" />;
  }

  return (
    <Section title="Recently Viewed">
      <div style={styles.container}>
        {map(recentlyViewedProjects, recentlyViewedProject => (
          <ProjectCard
            key={recentlyViewedProject.id}
            project={recentlyViewedProject.project}
            stats={Project.resolveStatsForProject(
              projectsStats,
              recentlyViewedProject.project.id,
            )}
            loadingStats={loadingStats}
            producer={recentlyViewedProject.producer}
            avatar={avatar}
            onClick={onProjectClick}
          />
        ))}
      </div>
    </Section>
  );
}

RecentlyViewedProjects.propTypes = {
  onProjectClick: PropTypes.func,
};
