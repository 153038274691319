import includes from 'lodash/includes';
import get from 'lodash/get';
import Dictionary from '../../dictionary';

function resolveStudioAddressField(values) {
  const newValues = { ...values };

  if (
    !includes(
      newValues.recordingLocations,
      Dictionary.RECORDING_LOCATIONS.ON_SITE_STUDIO,
    )
  ) {
    newValues.studioAddress = null;
  }

  return newValues;
}

function resolveRecordingDuration(values) {
  const { recordingLength, ...rest } = values;
  if (recordingLength) {
    const amount = get(recordingLength, 'amount');
    if (!amount) return rest;
  }
  return values;
}

export default {
  resolveStudioAddressField,
  resolveRecordingDuration,
};
