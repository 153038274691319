import React from 'react';
import PropTypes from 'prop-types';
import { Section, InlineSection } from 'modules/ui';
import Grid from '@material-ui/core/Grid';

function AdditionalInfoSection({ talent }) {
  return (
    <Section title="Additional Information" className="mb-36">
      <Grid container item spacing={2}>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Home Studio"
            text={talent.ownsHomestudio ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Home Studio Editing"
            text={talent.homeStudioEditing ? 'Yes' : 'No'}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <InlineSection
            title="Trained in Motion Capture"
            text={talent.trainedInMotionCapture ? 'Yes' : 'No'}
          />
        </Grid>
      </Grid>
    </Section>
  );
}

AdditionalInfoSection.propTypes = {
  talent: PropTypes.object.isRequired,
};
export default AdditionalInfoSection;
