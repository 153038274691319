import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SwipableDrawer from '@material-ui/core/SwipeableDrawer';
import DrawerHeader from './DrawerHeader';
import classes from './Drawer.module.scss';

function Drawer({
  children,
  anchor,
  open,
  onOpen,
  onClose,
  className,
  paperClassName,
  containerClassName,
  showHeader,
  variant,
  ...otherProps
}) {
  const paperClasses = classNames(paperClassName, classes.paperContent);
  const containerClasses = classNames(classes.content, containerClassName);

  return (
    <SwipableDrawer
      open={open}
      anchor={anchor}
      onClose={onClose}
      onOpen={onOpen}
      variant={variant}
      PaperProps={{ className: paperClasses }}
      className={className}
      transitionDuration={{ enter: 400, exit: 400 }}
      disableSwipeToOpen
    >
      {showHeader && <DrawerHeader {...otherProps} closeDrawer={onClose} />}
      <div className={containerClasses}>{children}</div>
    </SwipableDrawer>
  );
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  paperClassName: PropTypes.string,
  variant: PropTypes.string,
  showHeader: PropTypes.bool,
  containerClassName: PropTypes.string,
};

Drawer.defaultProps = {
  showHeader: true,
};

export default Drawer;
