import reject from 'lodash/reject';
import { createAction, createReducer } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import filter from 'lodash/filter';
import unionBy from 'lodash/unionBy';
import createSelector from 'selectorator';
import { Mapper } from 'modules/core';
import { resolveErrorMessage } from 'modules/errors';
import {
  fetchTopCandidates,
  patchTopCandidate,
  postTopCandidate,
  deleteTopCandidate,
  fetchSharedTopCandidates,
} from './api';

const INITIAL_STATE = {
  error: null,
  loading: false,

  // TODO: turn arrays of data into object maps (AHAB-597)
  topCandidates: [],
};

export const loadTopCandidates = createThunk(
  fetchTopCandidates,
  'load',
  'topCandidates',
);

export const loadSharedTopCandidates = createThunk(
  fetchSharedTopCandidates,
  'loadShared',
  'topCandidates',
);

export const addTopCandidate = createThunk(
  postTopCandidate,
  'create',
  'topCandidates',
);

export const removeTopCandidatesError = createAction(
  'projectRoles/removeTopCandidatesError',
);

export const removeErrorAction = createAction('topCandidates/error/remove');

export const updateTopCandidate = createThunk(
  patchTopCandidate,
  'update',
  'topCandidates',
);

export const removeTopCandidate = createThunk(
  deleteTopCandidate,
  'delete',
  'topCandidates',
);

export const reducer = createReducer(INITIAL_STATE, {
  [loadTopCandidates.type.request]: state => {
    state.topCandidatesLoading = true;
  },
  [loadTopCandidates.type.success]: (state, action) => {
    state.topCandidates = unionBy(
      action.payload.data,
      state.topCandidates,
      'id',
    );
    state.topCandidatesLoading = false;
  },
  [loadTopCandidates.type.error]: state => {
    state.topCandidatesLoading = false;
  },
  [loadSharedTopCandidates.type.success]: (state, action) => {
    state.topCandidates = unionBy(
      action.payload.data,
      state.topCandidates,
      'id',
    );
  },
  [loadSharedTopCandidates.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [updateTopCandidate.type.success]: (state, action) => {
    const updated = action.payload.data;

    state.topCandidates = Mapper.addOrReplace(state.topCandidates, updated);
    state.error = null;
  },
  [updateTopCandidate.type.error]: (state, action) => {
    state.error = resolveErrorMessage(action.error);
  },
  [removeErrorAction]: state => {
    state.topCandidatesError = null;
  },
  [removeTopCandidatesError]: state => {
    state.topCandidatesError = null;
  },
  [addTopCandidate.type.request]: state => {
    state.loading = true;
  },
  [addTopCandidate.type.success]: (state, action) => {
    const newCandidate = action.payload.data;

    state.topCandidates = [...state.topCandidates, newCandidate];
    state.loading = false;
  },
  [addTopCandidate.type.error]: state => {
    state.loading = false;
  },
  [removeTopCandidate.type.request]: state => {
    state.loading = true;
  },
  [removeTopCandidate.type.success]: (state, action) => {
    const removedCandidateId = action.request[3];

    state.topCandidates = reject(
      state.topCandidates,
      candidate => candidate.id === removedCandidateId,
    );
  },
});

export const getTopCandidatesError = createSelector(['topCandidates.error']);
export const getTopCandidates = createSelector(['topCandidates.topCandidates']);
export const getTopCandidatesByRoleId = roleId =>
  createSelector(
    [getTopCandidates],
    topCandidates =>
      filter(
        topCandidates,
        topCandidate => topCandidate.projectRoleId === roleId,
      ),
  );
export const getTopCandidatesLoading = createSelector([
  'topCandidates.topCandidatesLoading',
]);
