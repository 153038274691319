import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import { TextInput } from 'modules/ui';

function PhoneInput({
  required,
  disabled,
  value,
  error,
  setFormValue,
  name,
  placeholder,
  onChange,
}) {
  function handlePhoneNumberChange(event) {
    const number = event.target.value;

    if (setFormValue) {
      setFormValue(name, number);
    }

    if (onChange) {
      onChange(event);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <NumberFormat
          required={required}
          customInput={TextInput}
          name={name}
          disabled={disabled}
          type="text"
          onChange={handlePhoneNumberChange}
          value={value}
          placeholder={placeholder || '+'}
          format="+###############"
          error={error}
          label="Phone Number"
        />
      </Grid>
    </Grid>
  );
}

PhoneInput.propTypes = {
  required: PropTypes.bool,
  ...TextInput.propTypes,
};

export default memo(PhoneInput);
