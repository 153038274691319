import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavBar, Button } from 'modules/ui';
import classes from './AuthHeader.module.scss';

function AuthHeader({
  className,
  showLogo,
  lightLogo,
  light,
  dark,
  link,
  linkLabel,
  linkDescription,
}) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  return (
    <NavBar
      className={className}
      showLogo={showLogo}
      lightLogo={lightLogo}
      showMobile={false}
      light
    >
      <div>
        {linkDescription && (
          <span className="grayishBlueColor">{linkDescription}</span>
        )}
        <Button
          className={classes['auth-button']}
          light={light}
          dark={dark}
          onClick={handleClick}
        >
          {linkLabel}
        </Button>
      </div>
    </NavBar>
  );
}

AuthHeader.propTypes = {
  className: PropTypes.string,
  showLogo: PropTypes.bool,
  lightLogo: PropTypes.bool,
  light: PropTypes.bool,
  dark: PropTypes.bool,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  linkDescription: PropTypes.string,
};

AuthHeader.defaultProps = {
  showLogo: true,
  lightLogo: false,
};

export default memo(AuthHeader);
