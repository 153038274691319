import { createAction, createReducer, createSelector } from 'redux-starter-kit';
import { createThunk } from 'redux-scope';
import map from 'lodash/map';
import find from 'lodash/find';
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import head from 'lodash/head';
import { resolveErrorMessage } from 'modules/errors';
import { Mapper } from 'modules/core';
// Don't change, this resolves circular dependency
import { getInvitationsByAuditionId } from '../auditions-invitations/redux';
import {
  postApplication,
  fetchApplication,
  fetchAllTalentApplications,
  fetchTalentApplications,
  fetchAllProducerApplications,
  fetchProducerApplications,
  fetchAuditionApplications,
  fetchApplicationForTalent,
  postAuditionApplicationUnreadStatus,
  searchApplicationsByName,
  fetchUnreadApplications,
  markAllApplicationMessagesAsRead as apiMarkAllApplicationMessagesAsRead,
} from './api';
import { AuditionApplications } from './services';

const INITIAL_STATE = {
  applications: [],
  searchedApplications: [],
  loading: false,
  submitError: null,
  applicationsLoading: false,
  applicationLoading: false,
  auditionMessagesLoading: false,
  auditioneesSort: '-createdAt',
};

export const loadAllTalentApplications = createThunk(
  fetchAllTalentApplications,
  'loadAllTalent',
  'auditionApplications',
);

export const loadTalentApplications = createThunk(
  fetchTalentApplications,
  'loadTalent',
  'auditionApplications',
);

export const loadAllProducerApplications = createThunk(
  fetchAllProducerApplications,
  'loadAllProducer',
  'auditionApplications',
);

export const loadProducerApplications = createThunk(
  fetchProducerApplications,
  'loadProducer',
  'auditionApplications',
);

export const loadAuditionApplications = createThunk(
  fetchAuditionApplications,
  'loadAll',
  'auditionApplications',
);

export const loadApplicationForTalent = createThunk(
  fetchApplicationForTalent,
  'loadApplication',
  'auditionApplications',
);

export const setAuditioneesSort = createAction(
  'auditionApplications/sortAuditionees/set',
);

export const loadApplication = createThunk(
  fetchApplication,
  'load',
  'applications',
);

export const createApplication = createThunk(
  postApplication,
  'create',
  'applications',
);

export const setAuditionApplicationUnreadStatus = createThunk(
  postAuditionApplicationUnreadStatus,
  'markUnread',
  'applications',
);

export const searchApplications = createThunk(
  searchApplicationsByName,
  'search',
  'applications',
);

export const clearSearchedApplications = createAction(
  'applications/clearSearchedApplications',
);

export const loadUnreadApplications = createThunk(
  fetchUnreadApplications,
  'loadUnread',
  'applications',
);

export const markAllApplicationMessagesAsRead = createThunk(
  apiMarkAllApplicationMessagesAsRead,
  'markAllasRead',
  'applications',
);

export const reducer = createReducer(INITIAL_STATE, {
  [loadAllTalentApplications.type.request]: state => {
    state.applicationsLoading = true;
  },
  [loadAllTalentApplications.type.success]: (state, action) => {
    const newApplications = action.payload.data;

    state.applicationsLoading = false;
    state.applications = unionBy(newApplications, state.applications, 'id');
  },
  [loadAllTalentApplications.type.error]: state => {
    state.applicationsLoading = false;
  },
  [loadTalentApplications.type.request]: state => {
    state.auditionMessagesLoading = true;
  },
  [loadTalentApplications.type.success]: (state, action) => {
    const newApplications = action.payload.data;

    state.auditionMessagesLoading = false;
    state.applications = unionBy(newApplications, state.applications, 'id');
  },
  [loadTalentApplications.type.error]: state => {
    state.auditionMessagesLoading = false;
  },
  [loadAllProducerApplications.type.request]: state => {
    state.applicationsLoading = true;
  },
  [loadAllProducerApplications.type.success]: (state, action) => {
    const newApplications = action.payload.data;

    state.applicationsLoading = false;
    state.applications = unionBy(newApplications, state.applications, 'id');
  },
  [loadAllProducerApplications.type.error]: state => {
    state.applicationsLoading = false;
  },
  [loadProducerApplications.type.request]: state => {
    state.applicationsLoading = true;
  },
  [loadProducerApplications.type.success]: (state, action) => {
    const newApplications = action.payload.data;

    state.applicationsLoading = false;
    state.applications = unionBy(newApplications, state.applications, 'id');
  },
  [loadProducerApplications.type.error]: state => {
    state.applicationsLoading = false;
  },
  [loadApplication.type.success]: (state, action) => {
    const application = action.payload.data;
    state.applications = Mapper.addOrReplace(state.applications, application);
  },
  [createApplication.type.success]: (state, action) => {
    const newApplication = action.payload.data;

    state.applications = [...state.applications, newApplication];
    state.submitError = false;
  },
  [createApplication.type.error]: (state, action) => {
    state.submitError = resolveErrorMessage(action.error);
  },
  [loadAuditionApplications.type.request]: state => {
    state.applicationsLoading = true;
  },
  [loadAuditionApplications.type.success]: (state, action) => {
    const newApplications = action.payload.data;

    state.applications = unionBy(newApplications, state.applications, 'id');
    state.applicationsLoading = false;
  },
  [loadApplicationForTalent.type.request]: state => {
    state.applicationLoading = true;
  },
  [loadApplicationForTalent.type.success]: (state, action) => {
    const newApplication = action.payload.data;

    state.applicationLoading = false;
    state.applications = Mapper.addOrReplace(
      state.applications,
      newApplication,
    );
  },
  [loadApplicationForTalent.type.error]: state => {
    state.applicationLoading = false;
  },
  [setAuditioneesSort]: (state, action) => {
    state.auditioneesSort = action.payload;
  },
  [searchApplications.type.request]: state => {
    state.applicationsLoading = true;
  },
  [searchApplications.type.success]: (state, action) => {
    state.applicationsLoading = false;
    state.searchedApplications = action.payload.data;
  },
  [searchApplications.type.error]: state => {
    state.applicationLoading = false;
  },
  [clearSearchedApplications]: state => {
    state.searchedApplications = [];
  },
  [loadUnreadApplications.type.request]: state => {
    state.applicationsLoading = true;
  },
  [loadUnreadApplications.type.success]: (state, action) => {
    state.applicationsLoading = false;
    state.searchedApplications = action.payload.data;
  },
  [loadUnreadApplications.type.error]: state => {
    state.applicationLoading = false;
  },
  [markAllApplicationMessagesAsRead.type.request]: state => {
    state.applicationLoading = true;
  },
  [markAllApplicationMessagesAsRead.type.success]: state => {
    state.applicationLoading = false;
  },
  [markAllApplicationMessagesAsRead.type.error]: state => {
    state.applicationLoading = false;
  },
});

export const getApplications = createSelector([
  'auditionApplications.applications',
]);

export const getApplicationsLoading = createSelector([
  'auditionApplications.applicationsLoading',
]);

export const getApplicationLoading = createSelector([
  'auditionApplications.applicationLoading',
]);

export const getAuditionMessagesLoading = createSelector([
  'auditionApplications.auditionMessagesLoading',
]);

export const getAuditioneesSort = createSelector([
  'auditionApplications.auditioneesSort',
]);

export const getApplicationByTalentAndAuditionId = (talentId, auditionId) =>
  createSelector(
    [getApplications],
    stateApplications => {
      return find(
        stateApplications,
        application =>
          application.auditionId === auditionId &&
          application.talentId === talentId,
      );
    },
  );

export const getApplicationsByAuditionId = auditionId =>
  createSelector(
    [getApplications],
    stateApplications => {
      return filter(
        stateApplications,
        application => application.auditionId === auditionId,
      );
    },
  );

export const getApplicationById = applicationId =>
  createSelector(
    [getApplications],
    stateApplications => {
      return find(
        stateApplications,
        application => application.id === applicationId,
      );
    },
  );

export const getAuditionees = auditionId =>
  createSelector(
    [
      getInvitationsByAuditionId(auditionId),
      getApplicationsByAuditionId(auditionId),
    ],
    (invitations, applications) => {
      const invitationsWithoutSubmission = reject(
        invitations,
        invitation => !!find(applications, { talentId: invitation.talentId }),
      );
      const talentsWithoutSubmission = map(
        invitationsWithoutSubmission,
        invitation => {
          return { ...invitation.talent, source: 'Invited' };
        },
      );
      const applicants = map(applications, application => {
        return { ...application.talent, source: 'Applied' };
      });

      return [...applicants, ...talentsWithoutSubmission];
    },
  );

export const getApplicationsSortedByLatestMessages = createSelector(
  [getApplications],
  applications => {
    return AuditionApplications.applySortOptions(
      applications,
      undefined,
      AuditionApplications.SORT_OPTIONS.UPDATED_AT,
      'desc',
    );
  },
);

export const getLatestAuditionApplication = createSelector(
  [getApplicationsSortedByLatestMessages],
  head,
);

export const getSearchedApplications = createSelector([
  'auditionApplications.searchedApplications',
]);
