const CANVAS_SAVE_ERROR = 'Could not save image.';
const IMAGE_2X_SIZE = 360;

function createImage(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });
}

function createImageString(canvas, format) {
  const dataURL = canvas.toDataURL(format);

  return new Promise((resolve, reject) => {
    if (!dataURL) {
      const error = new Error(CANVAS_SAVE_ERROR);
      reject(error);
    }
    resolve(dataURL);
  });
}

function createImageBlob(canvas, format) {
  return new Promise((resolve, reject) =>
    canvas.toBlob(blob => {
      if (!blob) {
        const error = new Error(CANVAS_SAVE_ERROR);
        reject(error);
      }

      resolve(blob);
    }, format),
  );
}

function createCrop(
  imageSrc,
  pixelCrop,
  format = 'image/jpeg',
  exportAs = 'blob',
) {
  return createImage(imageSrc)
    .then(image => {
      const canvas = document.createElement('canvas');
      canvas.width = IMAGE_2X_SIZE;
      canvas.height = IMAGE_2X_SIZE;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        IMAGE_2X_SIZE,
        IMAGE_2X_SIZE,
      );

      if (exportAs === 'base64') {
        const string = createImageString(canvas, format);
        return Promise.resolve(string);
      }

      const blob = createImageBlob(canvas, format);
      return Promise.resolve(blob);
    })
    .catch(err => Promise.reject(err));
}

export default {
  getCroppedImage: createCrop,
  toBlob: createImageBlob,
};
