import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import noop from 'lodash/noop';
import MaterialTablePagination from '@material-ui/core/TablePagination';
import PaginationActions from './PaginationActions';

const SELECT_PROPS = {
  inputProps: { 'aria-label': 'Rows Per Page' },
  native: true,
};

const StyledPagination = withStyles({
  root: {
    border: 'none',
    display: 'flex',
    flex: 1,
  },
  toolbar: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: 'none',
    padding: '0',
    flex: 1,
  },
  input: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(5, 21, 40, 0.07)',
    borderRadius: 4,
    boxSizing: 'border-box',
    marginRight: 0,
    color: 'black',
    fontFamily: 'Inter',
    background: 'white',
  },
})(MaterialTablePagination);

export default function TablePagination({
  page,
  count,
  rowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
  rowsPerPageLabel,
  pageChangeScrollY,
}) {
  function handleChangePage(event, newPage) {
    onChangePage(event, newPage);
    if (pageChangeScrollY) {
      window.scrollTo(0, pageChangeScrollY);
    }
  }
  return (
    <div>
      <StyledPagination
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={5}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={noop}
        labelRowsPerPage={rowsPerPageLabel}
        SelectProps={SELECT_PROPS}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={PaginationActions}
      />
    </div>
  );
}

TablePagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  rowsPerPageLabel: PropTypes.string,
  pageChangeScrollY: PropTypes.number,
};

TablePagination.defaultProps = {
  rowsPerPageOptions: [10, 30, 60, 90, 120],
};
