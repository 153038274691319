import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDidMount } from 'modules/core';
import Table from './Table';
import TablePagination from './StyledTablePagination';

export default function TableWithPagination({
  count,
  onPageChange,
  onRowsPerPageChange,
  rows,
  renderHeader,
  renderRow,
  rowsPerPageLabel,
  className,
  containerClassName,
  pageChangeScrollY,
  defaultPage,
  defaultRowsPerPage,
  rowsPerPageOptions,
  RowsPlaceholderComponent,
  rowIsRemoved,
  resetRowIsRemoved,
}) {
  const didMount = useDidMount();
  const tableRef = useRef(null);

  const [page, setPage] = React.useState(defaultPage || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage || 10,
  );

  useEffect(() => {
    if (didMount && !rowIsRemoved) {
      setPage(0);
    }
    if (rowIsRemoved) {
      resetRowIsRemoved();
    }
  }, [count]);

  function handleChangePage(event, newPage) {
    setPage(newPage);

    if (onPageChange) {
      onPageChange(newPage);
    }

    window.scrollTo(0, pageChangeScrollY || tableRef.current.offsetTop);
  }

  function handleChangeRowsPerPage(event) {
    const newRowsPerPage = parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);
    setPage(0);

    if (onRowsPerPageChange) {
      onRowsPerPageChange(newRowsPerPage);
    }
  }

  const currentPage = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <div className={containerClassName} ref={tableRef}>
      <Table
        renderHeader={renderHeader}
        renderRow={renderRow}
        rows={currentPage}
        className={className}
        RowsPlaceholderComponent={RowsPlaceholderComponent}
      />
      <TablePagination
        page={page}
        count={count}
        rowsPerPage={rowsPerPage}
        rowsPerPageLabel={rowsPerPageLabel}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  );
}

TableWithPagination.propTypes = {
  rows: PropTypes.array,
  renderHeader: PropTypes.func,
  RowsPlaceholderComponent: PropTypes.node,
  renderRow: PropTypes.func,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPageLabel: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  pageChangeScrollY: PropTypes.number,
  defaultPage: PropTypes.number,
  defaultRowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  rowIsRemoved: PropTypes.bool,
  resetRowIsRemoved: PropTypes.func,
};
