import get from 'lodash/get';
import find from 'lodash/find';
import first from 'lodash/first';
import values from 'lodash/values';
import {
  DEFAULT_ERROR_MESSAGE,
  ERRORS,
  API_ERRORS,
  STATUS_CODES,
} from '../const';

function mapErrorCodeToMessage(code) {
  if (!code) {
    return null;
  }

  return get(find(ERRORS, { code }), 'message');
}

function mapErrorStatusToMessage(status) {
  status = '' + status; // Convert to string just in case
  if (!status || status.match(STATUS_CODES.SERVER_ERRORS_REGEX)) {
    return get(
      find(API_ERRORS, { status: STATUS_CODES.SERVER_ERROR }),
      'message',
    );
  }

  return get(find(API_ERRORS, { status }), 'message');
}

export function resolveErrorMessage(error) {
  // Depending on what is sent to the function from reducer
  // and if there is any preprocessing of data
  const apiError =
    get(error, ['payload', 'response', 'errors', '0']) ||
    get(error, ['payload', 'message']) ||
    first(values(error)) ||
    error;

  console.log('apiError: ', apiError);
  const { code, status, detail, message } = apiError;

  const mappedErrorCodeMessage = mapErrorCodeToMessage(code);
  const mappedErrorStatusMessage = mapErrorStatusToMessage(status);

  return (
    mappedErrorCodeMessage ||
    detail ||
    mappedErrorStatusMessage ||
    message ||
    DEFAULT_ERROR_MESSAGE
  );
}
