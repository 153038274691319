import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { NotFoundPage } from 'modules/ui';
import { ProtectedRoute } from 'modules/auth';
import { AuditionSearchPage, AuditionPage } from 'modules/auditions';
import { ProfilePage } from 'modules/producers/fragments';
import { OfferPage } from 'modules/offers';
import { AgentPreviewPage } from 'modules/agents';
import {
  EditPersonalPage,
  EditProfessionalPage,
  EditSamplesPage,
  EditSkillsPage,
  EditAgentsManagersPage,
  ProfilePage as TalentProfilePage,
} from './fragments';

const SubmissionsPage = lazy(() =>
  import('modules/submissions/fragments/SubmissionsPage'),
);

const OffersPage = lazy(() => import('modules/offers/fragments/OffersPage'));

const GlobalMessagingPage = lazy(() =>
  import('modules/global-messages/pages/GlobalMessagingPage'),
);

const AuditionsMessagingPage = lazy(() =>
  import('modules/audition-messages/pages/AuditionsMessagingPage'),
);

export default function TalentSwitch() {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={AuditionSearchPage} />
      <ProtectedRoute
        exact
        path="/auditions/:auditionId"
        component={AuditionPage}
      />
      <ProtectedRoute path="/auditions" component={AuditionSearchPage} />
      <ProtectedRoute path="/submissions" component={SubmissionsPage} />
      <ProtectedRoute path="/offers/:offerId" component={OfferPage} />
      <ProtectedRoute path="/offers" component={OffersPage} />
      <ProtectedRoute
        exact
        path={['/profile', '/talent/:talentId']}
        component={TalentProfilePage}
      />
      <ProtectedRoute exact path="/profile/edit" component={EditPersonalPage} />
      <ProtectedRoute
        exact
        path="/profile/edit/skills"
        component={EditSkillsPage}
      />
      <ProtectedRoute
        exact
        path="/profile/edit/professional-info"
        component={EditProfessionalPage}
      />
      <ProtectedRoute
        exact
        path="/profile/edit/samples"
        component={EditSamplesPage}
      />
      <ProtectedRoute
        exact
        path="/profile/edit/agents-and-managers"
        component={EditAgentsManagersPage}
      />
      <ProtectedRoute
        exact
        path="/producers/:producerId"
        component={ProfilePage}
      />
      <ProtectedRoute path="/agents/:agentId" component={AgentPreviewPage} />
      <ProtectedRoute
        path="/messaging/auditions/:auditionId/:talentId"
        component={AuditionsMessagingPage}
      />
      <ProtectedRoute
        path="/messaging/auditions"
        component={AuditionsMessagingPage}
      />
      <ProtectedRoute
        path="/messaging/global/:oppositeAccountType/:oppositeAccountId"
        component={GlobalMessagingPage}
      />
      <ProtectedRoute
        path="/messaging/global"
        component={GlobalMessagingPage}
      />
      <ProtectedRoute component={NotFoundPage} />
    </Switch>
  );
}
