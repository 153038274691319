import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import { DropdownMenu, Button, Icon } from 'modules/ui';

const DropdownButton = forwardRef((props, ref) => {
  const {
    children,
    iconName,
    iconColor,
    vertical,
    header,
    dropdownWidth,
    arrowRightPosition,
    buttonStyle,
    buttonClassName,
    label,
    iconSize,
    iconStyle,
    ...otherProps
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOnClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    if (event) {
      event.stopPropagation();
    }

    setAnchorEl(null);
  }

  useImperativeHandle(ref, () => ({ close: handleClose }));

  const resolvedArrowPosition = arrowRightPosition || '20px';
  const resolvedButtonStyles = {
    ...buttonStyle,
    ...(vertical && {
      transform: 'rotate(90deg)',
    }),
    ...(header && {
      alignSelf: 'center',
      minWidth: 40,
      height: 40,
      marginLeft: 16,
      backgroundColor: Colors.darkGrey,
    }),
  };

  return (
    <>
      <Button
        small
        onClick={handleOnClick}
        className={buttonClassName}
        style={resolvedButtonStyles}
        {...otherProps}
      >
        {label}
        <Icon
          style={iconStyle}
          name={iconName}
          color={iconColor}
          size={iconSize}
        />
      </Button>
      <DropdownMenu
        onClick={handleClose}
        onClose={handleClose}
        width={dropdownWidth}
        open={!!anchorEl}
        anchorEl={anchorEl}
        arrowRightPosition={resolvedArrowPosition}
      >
        {children}
      </DropdownMenu>
    </>
  );
});

DropdownButton.propTypes = {
  children: PropTypes.node,
  vertical: PropTypes.bool,
  iconName: PropTypes.string,
  dropdownWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  buttonStyle: PropTypes.object,
  iconColor: PropTypes.string,
  arrowRightPosition: PropTypes.string,
  buttonClassName: PropTypes.string,
  label: PropTypes.string,
  iconSize: PropTypes.number,
  iconStyle: PropTypes.object,
  header: PropTypes.bool,
};

DropdownButton.defaultProps = {
  iconName: 'moreHorizontal',
  iconColor: Colors.white,
  iconSize: 16,
};

export default memo(DropdownButton);
