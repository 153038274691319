import { createThunk } from 'redux-scope';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Assets, uploadAssetActions } from 'modules/assets';
import { postAudioSample, patchAudioSample, deleteAudioSample } from './api';

export function postSample(talentId, values, file) {
  const context = Assets.createTalentContext(talentId);
  const metaCall = Assets.createMeta(file);

  const assetPathCall = uploadAssetActions(
    file,
    'audio',
    'talentAudioSample',
    context,
  );

  return Promise.all([metaCall, assetPathCall]).then(responses => {
    const sampleValues = {
      ...values,
      meta: responses[0],
      url: responses[1],
    };

    return postAudioSample(talentId, sampleValues);
  });
}

export function patchSample(talentId, audioSampleId, values, file) {
  const context = Assets.createTalentContext(talentId);
  const oldMeta = get(values, 'meta');
  const metaCall = Assets.createMeta(file);

  if (!values.isReplacement) {
    return metaCall.then(meta => {
      const newMeta = !isEmpty(meta) ? meta : oldMeta;
      return patchAudioSample(talentId, audioSampleId, {
        ...values,
        meta: newMeta,
      });
    });
  }

  const assetPathCall = uploadAssetActions(
    file,
    'audio',
    'talentAudioSample',
    context,
  );

  return Promise.all([metaCall, assetPathCall]).then(responses => {
    const newMeta = !isEmpty(responses[0]) ? responses[0] : oldMeta;
    const sampleValues = {
      ...values,
      meta: newMeta,
      url: responses[1],
    };

    return patchAudioSample(talentId, audioSampleId, sampleValues);
  });
}

export const createAudioSample = createThunk(
  postSample,
  'create',
  'audioSamples',
);

export const updateAudioSample = createThunk(
  patchSample,
  'update',
  'audioSamples',
);

export const removeAudioSample = createThunk(
  deleteAudioSample,
  'remove',
  'audioSamples',
);
