import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import truncate from 'lodash/truncate';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { FullScreenModal, InfoText } from 'modules/ui';
import { Colors } from 'modules/theme';
import { getTalent } from 'modules/talents';
import { showNotification, Notification } from 'modules/notifications';
import { usePreventClose } from 'modules/core/services/hooks';
import { DownloadFileModalForm, ApplyModalForm } from '../components';
import { acceptNda, apply, loadNdaAcceptance } from '../redux';

const FORM_TYPES = {
  NDA: 'nda',
  SCRIPT: 'script',
  APPLY: 'apply',
};

const styles = {
  loader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  loaderInfo: {
    paddingTop: 20,
    fontWeight: 'bold',
  },
};

function resolveStartForm(nda, script) {
  if (nda) {
    return FORM_TYPES.NDA;
  }

  if (script) {
    return FORM_TYPES.SCRIPT;
  }

  return FORM_TYPES.APPLY;
}

function resolveLoadingModal(activeForm) {
  return activeForm === FORM_TYPES.NDA;
}

export default function AuditionApplyModal({
  open,
  onClose,
  audition,
  history,
}) {
  const dispatch = useDispatch();

  const nda = get(audition, 'nonDisclosureAgreement');
  const ndaUrl = get(nda, 'url');
  const scriptUrl = get(audition, 'scriptUrl');
  const auditionName = truncate(get(audition, 'name'), { length: 34 });
  const ndaFormTitle = `Non-Disclosure Agreement for ${auditionName} audition`;
  const scriptFormTitle = `${auditionName} audition script`;
  const scriptFormDescription =
    'Click to download the script for your audition.';

  const [loading, setLoading] = useState(false);
  const [startForm, setStartForm] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [loadingModal, setLoadingModal] = useState(null);

  const modalTitle = `Audition for role ${audition.projectRole.name} in ${audition.projectRole.project.title}`;
  const talent = useSelector(getTalent);

  const auditionId = get(audition, 'id');
  const talentId = get(talent, 'id');

  useEffect(() => {
    const resolvedActiveForm = resolveStartForm(nda, scriptUrl);
    setActiveForm(resolvedActiveForm);
    setStartForm(resolvedActiveForm);
  }, [audition, nda, scriptUrl]);

  useEffect(() => {
    const resolvedLoadingModal = resolveLoadingModal(activeForm);
    setLoadingModal(resolvedLoadingModal);
  }, [activeForm]);

  useEffect(() => {
    if (activeForm === FORM_TYPES.NDA) {
      dispatch(loadNdaAcceptance(auditionId)).then(payload => {
        const data = get(payload, 'data');
        if (data) {
          setActiveForm(FORM_TYPES.SCRIPT);
        }

        setLoadingModal(false);
      });
    }
  }, [dispatch, activeForm, auditionId]);

  usePreventClose(loading);

  function handleClose() {
    setActiveForm(resolveStartForm(nda, scriptUrl));
    onClose();
  }

  async function handleSubmit(data) {
    setLoading(true);
    dispatch(
      showNotification({
        message: `Please wait for your file to upload before closing this page.
        `,
        type: Notification.TYPES.SUCCESS,
        duration: null,
      }),
    );
    return dispatch(apply(data, talentId, auditionId)).then(response => {
      console.log('got past apply in audition modal');
      if (history) {
        history.push('/submissions');
      }

      handleClose();
      setLoading(false);

      global.utag.link({
        event_type: 'submit_audition',
        page_type: 'Audition Detail Page',
        module_type: auditionName,
      });

      return dispatch(
        showNotification({
          message: `You have applied to ${auditionName} audition.`,
          type: Notification.TYPES.SUCCESS,
        }),
      );
    });
  }

  function handleAcceptNda() {
    return dispatch(acceptNda(auditionId)).then(payload => {
      const data = get(payload, 'data');
      if (data) {
        setActiveForm(FORM_TYPES.SCRIPT);
      }
    });
  }

  function handleScriptNext() {
    return Promise.resolve(setActiveForm(FORM_TYPES.APPLY));
  }

  function renderLoader() {
    return (
      <div style={styles.loader}>
        <Loader type="Oval" color={Colors.darkBlue} width={50} height={50} />
        {activeForm === FORM_TYPES.APPLY && (
          <div style={styles.loaderInfo}>
            <InfoText text="File upload in progress." />
          </div>
        )}
      </div>
    );
  }

  function handleBack(currentForm) {
    if (currentForm === FORM_TYPES.SCRIPT) {
      setActiveForm(FORM_TYPES.NDA);
    }
    if (currentForm === FORM_TYPES.APPLY) {
      setActiveForm(FORM_TYPES.SCRIPT);
    }
  }

  function renderContent() {
    return (
      <>
        {activeForm === FORM_TYPES.NDA && (
          <DownloadFileModalForm
            fileUrl={ndaUrl}
            title={ndaFormTitle}
            submitButtonLabel="I consent"
            buttonLabel="Download NDA"
            description="This script is protected by non-disclosure agreement, before
            accessing it you need to accept NDA. By clicking “I consent” you
            agree to the terms written in non-disclosure agreement."
            onSubmit={handleAcceptNda}
            backBtnDisabled
          />
        )}
        {activeForm === FORM_TYPES.SCRIPT && (
          <DownloadFileModalForm
            fileUrl={scriptUrl}
            title={scriptFormTitle}
            submitButtonLabel="Next"
            buttonLabel="Download script"
            description={scriptFormDescription}
            onSubmit={handleScriptNext}
            onBack={() => handleBack(FORM_TYPES.SCRIPT)}
            backBtnDisabled={startForm === FORM_TYPES.SCRIPT}
          />
        )}
        {activeForm === FORM_TYPES.APPLY && (
          <ApplyModalForm
            audition={audition}
            onBack={() => handleBack(FORM_TYPES.APPLY)}
            onSubmit={handleSubmit}
          />
        )}
      </>
    );
  }

  return (
    <FullScreenModal
      open={open}
      title={modalTitle}
      onClose={!loading && handleClose}
    >
      {loadingModal || loading ? renderLoader() : renderContent()}
    </FullScreenModal>
  );
}

AuditionApplyModal.propTypes = {
  history: PropTypes.object,
  audition: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
