import map from 'lodash/map';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import size from 'lodash/size';
import { OptionUtil } from 'modules/ui/components/Option/services';

const SORT_OPTIONS = {
  FULLNAME: 'fullName',
};

function extractSubmissions(applicationsMessages) {
  const messages = flatten(
    map(applicationsMessages, applicationMessages => applicationMessages.data),
  );

  return filter(messages, message => message.audioClip);
}

function countSubmissions(applicationsMessages) {
  return size(extractSubmissions(applicationsMessages));
}

function createSubmissionOptions(auditionMessages) {
  const submissions = filter(
    auditionMessages,
    message => message.authorAccount && message.audioClip,
  );

  const modifiedSubmissions = map(submissions, submission => {
    const modifiedSubmission = { ...submission };
    const { id, fullName } = submission.authorAccount.talent;

    modifiedSubmission.audioClip.talentId = id;
    modifiedSubmission.audioClip.talentFullName = fullName;

    return modifiedSubmission;
  });

  return OptionUtil.getOptions(modifiedSubmissions, submission =>
    OptionUtil.mapOptionFromModel(submission, 'audioClipId', 'audioClip'),
  );
}

function resolveSortOptionPath(sortOption) {
  if (sortOption === SORT_OPTIONS.FULLNAME) {
    return 'authorAccount.talent.fullName';
  }
}

export default {
  countSubmissions,
  createSubmissionOptions,
  resolveSortOptionPath,
  SORT_OPTIONS,
};
