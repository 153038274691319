import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import get from 'lodash/get';
import size from 'lodash/size';
import { Icon, TableCell, Button, Select, OptionUtil } from 'modules/ui';
import { Colors } from 'modules/theme';
import { getApplicationsLoading } from 'modules/audition-applications';
import { getSubmissions } from 'modules/audition-messages';
import { DateUtil } from 'modules/core/services';
import { Audition } from 'modules/auditions/services';
import { Notification, showNotification } from 'modules/notifications';
import { fetchAuditionApplications } from 'modules/audition-applications/api';
import { fetchAllAuditionMessages } from 'modules/audition-messages/api';
import { Submission } from 'modules/submissions/services';
import AuditionRowMenu from './AuditionRowMenu';
import classes from './AuditionsTableInfoHeader.module.scss';

const styles = {
  statusIcon: {
    marginRight: 10,
    marginBottom: 2,
  },
  nameArrow: {
    marginTop: 5,
  },
  inviteTalent: {
    backgroundColor: Colors.tableHover,
  },
};

function AuditionsTableInfoHeader({
  audition,
  auditions,
  onAuditionChange,
  onEditClick,
  onDeleteClick,
  onRunClick,
  onStopClick,
  onPreviewAuditionClick,
  onInviteTalentClick,
  inviteTalentDisabled,
}) {
  const dispatch = useDispatch();

  const loading = useSelector(getApplicationsLoading);
  const submissions = useSelector(getSubmissions);
  const submissionsNumber = size(submissions);

  function handleRunClick(id) {
    if (!DateUtil.hasDatePassed(get(audition, 'dueDate'))) {
      onRunClick(id);
    } else {
      dispatch(
        showNotification({
          message:
            'In order to run the audition, edit its due date to a date in the future',
          type: Notification.TYPES.WARNING,
        }),
      );
    }
  }

  function resolveIconName(status) {
    if (status === Audition.STATUSES.ACTIVE) {
      return 'indicatorActive';
    }
    if (status === Audition.STATUSES.DRAFT) {
      return 'indicatorDraft';
    }
    if (status === Audition.STATUSES.INACTIVE) {
      return 'indicatorInactive';
    }
    return null;
  }

  if (!audition) {
    return null;
  }

  const auditionStatus = Audition.getAuditionStatus(audition);
  const auditionType = Audition.getAuditionType(audition);

  return (
    <TableCell className={classes.container} colSpan={6}>
      <div className={classes.dateInfo}>
        <div>Due Date: {DateUtil.formatDate(audition.dueDate)}</div>
        <div>
          <AuditionRowMenu
            onEditClick={() => onEditClick(audition.id)}
            onDeleteClick={
              Audition.getAuditionStatus(audition) ===
                Audition.STATUSES.DRAFT && (() => onDeleteClick(audition.id))
            }
            onRunClick={
              Audition.getAuditionStatus(audition) ===
                Audition.STATUSES.INACTIVE && handleRunClick
            }
            onStopClick={
              Audition.getAuditionStatus(audition) ===
                Audition.STATUSES.ACTIVE && (() => onStopClick(audition.id))
            }
            onPreviewClick={onPreviewAuditionClick}
          />
        </div>
      </div>
      <div>
        <Select
          options={OptionUtil.getOptions(
            auditions,
            OptionUtil.mapOptionFromModel,
          )}
          onChange={onAuditionChange}
          value={OptionUtil.mapOptionFromModel(audition).value}
          containerClassName={classes.nameContainer}
          className={classes.name}
          arrowStyle={styles.nameArrow}
          required
          lightInput
        />
      </div>

      <div className={classes.infoContainer}>
        <div>
          <div className={classes.info}>
            <Icon
              name={resolveIconName(auditionStatus)}
              size={8}
              style={styles.statusIcon}
            />
            {auditionStatus}
          </div>
          {loading && (
            <div className={classNames(classes.info, classes.submissions)}>
              <Loader
                type="Oval"
                color={Colors.darkBlue}
                width={15}
                height={15}
              />
            </div>
          )}
          {!loading && (
            <div className={classes.info}>
              {submissionsNumber} Submission{submissionsNumber === 1 ? '' : 's'}
            </div>
          )}
          <div className={classes.info}>{auditionType}</div>
        </div>
        {onInviteTalentClick && (
          <div>
            <Button
              className={classes.actionButton}
              onClick={onInviteTalentClick}
              disabled={inviteTalentDisabled}
              style={styles.inviteTalent}
            >
              + Invite talent
            </Button>
          </div>
        )}
      </div>
    </TableCell>
  );
}

AuditionsTableInfoHeader.propTypes = {
  audition: PropTypes.object.isRequired,
  auditions: PropTypes.array.isRequired,
  onAuditionChange: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onPreviewAuditionClick: PropTypes.func.isRequired,
  onInviteTalentClick: PropTypes.func.isRequired,
  inviteTalentDisabled: PropTypes.bool.isRequired,
  onRunClick: PropTypes.func,
  onStopClick: PropTypes.func,
};
export default AuditionsTableInfoHeader;
