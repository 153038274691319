import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { getAccount } from 'modules/accounts';

import AuditionMessagingCard from './AuditionMessagingCard';

function TalentAuditionMessagingCard({ application, onCardClick }) {
  const audition = get(application, 'audition');
  const auditionId = get(audition, 'id');

  const producer = get(audition, 'producer');
  const producerFullName = get(producer, 'fullName');
  const producerProfileImage = get(producer, 'profileImage');

  const account = useSelector(getAccount);
  const talentId = get(account, 'talentId');

  function handleCardClick() {
    onCardClick(auditionId, talentId);
  }

  return (
    <AuditionMessagingCard
      application={application}
      subtitle={producerFullName}
      profileImage={producerProfileImage}
      onClick={handleCardClick}
    />
  );
}

TalentAuditionMessagingCard.propTypes = {
  application: PropTypes.object.isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default TalentAuditionMessagingCard;
