import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PageHeader, SearchInput } from 'modules/ui';
import { useWindowSize } from 'modules/core';
import get from 'lodash/get';
import classes from './SearchHeader.module.scss';

const styles = {
  title: {
    marginTop: 10,
  },
};
export default function SearchHeader({
  onChange,
  title,
  placeholder,
  defaultValue,
  description,
  onClear,
}) {
  const [value, setValue] = useState(defaultValue || '');

  const { mobileWidth } = useWindowSize();
  const TitleComponent = (
    <div className={classes.contentContainer}>
      {!mobileWidth && (
        <>
          <h1 className={styles.title}>{title}</h1>
          {description && <p className={classes.description}>{description}</p>}
        </>
      )}
    </div>
  );

  function handleChange(event) {
    const newValue = get(event, 'target.value');
    setValue(newValue);

    onChange(newValue);
  }

  function handleClear() {
    setValue('');
    onClear();
  }

  return (
    <PageHeader
      className={classes.headerContainer}
      contentClassName={classes.headerContent}
      LeftComponent={!mobileWidth ? TitleComponent : null}
    >
      <SearchInput
        theme="dark"
        name="query"
        placeholder={placeholder}
        onChange={handleChange}
        containerClassName={classes.inputContainer}
        value={value}
        onClear={handleClear}
      />
    </PageHeader>
  );
}

SearchHeader.propTypes = {
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  description: PropTypes.string,
  onClear: PropTypes.func,
};

SearchHeader.defaultProps = {
  title: 'Search',
  placeholder: 'Search',
};
