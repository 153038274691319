import React, { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styles from './Countdown.module.scss';

function Countdown({ dueDate }) {
  const now = moment(new Date());
  const end = moment(dueDate);
  const difference = end.diff(now);

  if (difference < 0) {
    return <p className="grayishBlue body16">Due date has passed.</p>;
  }

  const duration = moment.duration(difference);
  const days = parseInt(duration.asDays(), 10);
  const hours = parseInt(duration.asHours(), 10) - days * 24;
  const minutes = parseInt(duration.asMinutes(), 10) - days * 1440 - hours * 60;

  return (
    <div>
      <h2 className={styles.time}>
        <span>
          {days}
          <span>:</span>
        </span>
        <span>
          {hours}
          <span>:</span>
        </span>
        <span>{minutes}</span>
      </h2>
      <p className={styles.timeText}>
        <span>Days</span>
        <span>Hours</span>
        <span>Minutes</span>
      </p>
    </div>
  );
}

Countdown.propTypes = {
  dueDate: PropTypes.string,
};

Countdown.defaultProps = {
  dueDate: new Date(),
};

export default memo(Countdown);
