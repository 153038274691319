import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormDialog, Textarea, ManagedForm } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { getTopCandidatesError } from 'modules/top-candidates';
import { postSharedTopCandidates } from 'modules/top-candidates/api';
import { TextInput } from 'modules/ui/components/TextInput';
import { Validator } from '../services';

const styles = {
  container: {
    height: 460,
  },
  title: {
    marginBottom: 0,
  },
};

function TopCandidatesShareModal({ producerId, projectId, roleId, onCancel }) {
  const dispatch = useDispatch();

  const error = useSelector(getTopCandidatesError);

  function handleSubmit(values) {
    return postSharedTopCandidates(producerId, projectId, roleId, values).then(
      () => {
        onCancel();
        dispatch(
          showNotification({
            message: `List shared with ${values.targetEmail}`,
            type: Notification.TYPES.SUCCESS,
          }),
        );
      },
    );
  }

  return (
    <FormDialog
      title="Share Top Candidates"
      subtitleText="Share your list of Top Candidates with anyone outside of Ahab. Your notes about each sample will be included."
      onClose={onCancel}
      containerStyle={styles.container}
      titleStyle={styles.title}
    >
      <ManagedForm
        error={error}
        validate={Validator.validateTopCandidatesShareForm}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitButtonLabel="Share"
      >
        <TextInput
          required
          label="Email address"
          name="targetEmail"
          placeholder="Enter recipient's mail"
        />
        <Textarea
          required
          label="Message"
          name="note"
          placeholder="Add a message"
        />
      </ManagedForm>
    </FormDialog>
  );
}

TopCandidatesShareModal.propTypes = {
  producerId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  roleId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withRouter(TopCandidatesShareModal);
