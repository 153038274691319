import React, { cloneElement, useState } from 'react';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import slice from 'lodash/slice';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { ReactUtil } from 'modules/core';
import { Label, RepeaterRow } from 'modules/ui';
import PropTypes from 'prop-types';

export default function Repeater({
  label,
  value: valueArray,
  name,
  required,
  Component,
  className,
  setFormValue,
  onRemoveField,
  validate,
  maxFields,
  ...otherProps
}) {
  const [newValue, setNewValue] = useState(null);
  const [values, setValues] = useState(valueArray);
  const [error, setError] = useState(null);

  const inputElement = ReactUtil.resolveComponent(Component);

  const handleNewValueChange = changedValue => {
    setNewValue(changedValue);
    setError(null);
  };

  const handleAddField = () => {
    const newValues = !isArray(values) ? [newValue] : [...values, newValue];

    if (isFunction(validate)) {
      const newError = validate(newValue);
      const hasError = !isEmpty(newError);

      if (hasError) {
        setError(newError);
        return;
      }
    }

    setNewValue(null);
    setValues(newValues);
    setError(null);

    if (setFormValue) {
      setFormValue(name, newValues);
    }
  };

  function handleRemoveField(item, index) {
    const newValues = [...slice(values, 0, index), ...slice(values, index + 1)];

    setValues(newValues);

    if (setFormValue) {
      setFormValue(name, newValues);
    }

    if (onRemoveField) {
      onRemoveField(item);
    }
  }

  function renderItem(item, index) {
    const key = `${JSON.stringify(item)}_${index}`;

    return (
      <RepeaterRow
        key={key}
        value={item}
        index={index}
        onRemove={() => handleRemoveField(item, index)}
        {...otherProps}
      >
        {cloneElement(inputElement, { value: item, disabled: true })}
      </RepeaterRow>
    );
  }

  return (
    <div className={className}>
      <Label className="mb-0" label={label} required={required} error={error} />
      {map(values, renderItem)}
      {(!maxFields || (maxFields && size(values) < maxFields)) && (
        <RepeaterRow
          key="empty"
          value={newValue}
          onAdd={handleAddField}
          {...otherProps}
        >
          {cloneElement(inputElement, {
            value: newValue,
            onChange: handleNewValueChange,
            error,
          })}
        </RepeaterRow>
      )}
    </div>
  );
}

Repeater.propTypes = {
  name: PropTypes.string,
  setFormValue: PropTypes.func,
  onRemoveField: PropTypes.func,
  required: PropTypes.bool,
  validate: PropTypes.func,
  Component: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.array,
  maxFields: PropTypes.number,
};
