import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Card } from '../Card';

const StyledTable = withStyles({
  root: {
    minWidth: 500,
    width: '100%',
  },
})(MaterialTable);

export default function Table({
  rows,
  renderHeader,
  renderRow,
  RowsPlaceholderComponent,
  className,
}) {
  return (
    <Card className={className}>
      <StyledTable>
        {renderHeader && <TableHead>{renderHeader()}</TableHead>}
        <TableBody>{rows.map(renderRow)}</TableBody>
        {isEmpty(rows) && RowsPlaceholderComponent && (
          <TableBody>{RowsPlaceholderComponent}</TableBody>
        )}
      </StyledTable>
    </Card>
  );
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  renderHeader: PropTypes.func,
  renderRow: PropTypes.func,
  RowsPlaceholderComponent: PropTypes.node,
  className: PropTypes.string,
};
