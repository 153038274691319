import uniq from 'lodash/uniq';

function aggregateByTalentId(submissions) {
  const submittedTalentsIds = uniq(
    submissions.map(submission => submission.authorAccount.talentId),
  );

  const aggregated = {};

  submittedTalentsIds.forEach(talentId => {
    aggregated[talentId] = [];
  });

  submissions.forEach(submission => {
    aggregated[submission.authorAccount.talentId].push(submission);
  });

  return aggregated;
}
export default { aggregateByTalentId };
