import React from 'react';
import isObject from 'lodash/isObject';
import get from 'lodash/get';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { Colors } from 'modules/theme';
import { LodashUtil } from 'modules/core';
import { Assets } from 'modules/assets';
import styles from './FilePreview.module.scss';

function getTitle(file) {
  if (isObject(file)) {
    return LodashUtil.truncateText(get(file, 'name'), 40);
  }

  return LodashUtil.truncateText(Assets.getFileName(file), 40);
}

export default function FilePreview({ file }) {
  if (!file) {
    return (
      <Loader type="Oval" color={Colors.darkBlue} width={40} height={40} />
    );
  }

  const name = getTitle(file);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{name}</div>
    </div>
  );
}

FilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
