import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import size from 'lodash/size';
import { Colors } from 'modules/theme';
import { AccountType, getAccountType } from 'modules/accounts';
import { PageLoader } from 'modules/ui';
import { Talent } from '../services';
import { getTalent } from '../redux';
import classes from './TalentProfileVisibiltyPanel.module.scss';

const LABEL_SEPARATOR = ', ';

function TalentProfileVisibilityPanel() {
  const accountType = useSelector(getAccountType);
  const talent = useSelector(getTalent);
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    if (talent && accountType && AccountType.isTalent(accountType)) {
      if (Talent.isProfileVisible(talent)) {
        setTimeout(() => setShowPanel(false), 1000);
      } else {
        setShowPanel(true);
      }
    }
  }, [accountType, talent]);

  if (!showPanel) {
    return null;
  }

  if (!talent) {
    return <PageLoader className={classes.loader} />;
  }

  const requiredFieldsCount = Talent.countAllVisibilityRequirements();
  const populatedFieldsCount = Talent.countPopulatedVisibilityRequirements(
    talent,
  );

  const populatedFieldRequirements = Talent.getPopulatedFieldRequirements(
    talent,
  );
  const personalInfoEmptyRequiredFields = Talent.PERSONAL_INFO_REQUIRED_FIELDS.filter(
    field => !populatedFieldRequirements[field],
  );
  const professionalInfoEmptyRequiredFields = Talent.PROFESSIONAL_INFO_REQUIRED_FIELDS.filter(
    field => !populatedFieldRequirements[field],
  );
  const skillsEmptyRequiredFields = Talent.SKILLS_REQUIRED_FIELDS.filter(
    field => !populatedFieldRequirements[field],
  );

  return (
    <div className={classes.container}>
      <div className={classes.headingContainer}>
        <div className={classes.progressBar}>
          <CircularProgressbarWithChildren
            minValue={0}
            maxValue={requiredFieldsCount}
            value={populatedFieldsCount}
            styles={buildStyles({
              strokeLinecap: 'round',
              textSize: '30px',
              pathTransitionDuration: 1,
              pathColor: Colors.secondary,
              textColor: Colors.darkBlue,
              trailColor: 'rgba(5, 21, 40, 0.05)',
            })}
          >
            <div className={classes.text}>
              {`${populatedFieldsCount}/${requiredFieldsCount}`}
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <span className={classes.heading}>
          Become visible to content creators
        </span>
      </div>
      <div className={classes.rows}>
        {!isEmpty(personalInfoEmptyRequiredFields) && (
          <div className={classes.row}>
            <span className={classes.bold}>Personal Info</span> -{' '}
            {Talent.mapFieldNamesToLabels(personalInfoEmptyRequiredFields).join(
              LABEL_SEPARATOR,
            )}
          </div>
        )}
        {!isEmpty(professionalInfoEmptyRequiredFields) && (
          <div className={classes.row}>
            <span className={classes.bold}>Professional Info</span> -{' '}
            {Talent.mapFieldNamesToLabels(
              professionalInfoEmptyRequiredFields,
            ).join(LABEL_SEPARATOR)}
          </div>
        )}
        {!isEmpty(skillsEmptyRequiredFields) && (
          <div className={classes.row}>
            <span className={classes.bold}>Your Skills</span> -{' '}
            {Talent.mapFieldNamesToLabels(skillsEmptyRequiredFields).join(
              LABEL_SEPARATOR,
            )}
          </div>
        )}
        {size(talent.audioSamples) < 2 && (
          <div className={classes.row}>
            <span className={classes.bold}>Samples</span> -{' '}
            {`${size(talent.audioSamples)}/${Talent.REQUIRED_SAMPLES_COUNT}`}
          </div>
        )}
      </div>
    </div>
  );
}

export default TalentProfileVisibilityPanel;
