import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Dialog, TextInput } from 'modules/ui';
import { updateTopCandidate } from 'modules/top-candidates';
import { Utils } from 'modules/core/services';

const ENTER_KEY = 'Enter';

const styles = {
  title: {
    marginBottom: 0,
  },
};

function EditNoteDialog({ note, producer, project, topCandidate, onCancel }) {
  const dispatch = useDispatch();

  const [value, setValue] = useState(note);
  const [touched, setTouched] = useState(false);
  const [inProgress, setInProgress] = useState(null);

  function handleValueChange(event) {
    const input = event.target.value.trimStart();
    setValue(input);

    setTouched(true);
  }

  function handleEditNote() {
    setInProgress(true);

    const producerId = get(producer, 'id');
    const projectId = get(project, 'id');
    const roleId = get(topCandidate, 'projectRoleId');
    const topCandidateId = get(topCandidate, 'id');

    return dispatch(
      updateTopCandidate(
        producerId,
        projectId,
        roleId,
        topCandidateId,
        null,
        null,
        value,
      ),
    )
      .then(() => setInProgress(false))
      .then(() => onCancel());
  }

  function handleKeyPressed(event) {
    if (event.key === ENTER_KEY) {
      handleEditNote();
    }
  }

  const title = 'Edit Sample Notes';

  return (
    <Dialog
      title={title}
      onAction={handleEditNote}
      onCancel={onCancel}
      onClose={onCancel}
      actionBtnLabel="Save"
      actionDisabled={!touched}
      inProgress={inProgress}
      titleStyle={styles.title}
    >
      <TextInput
        autoFocus
        label="Edit sample note"
        name="note"
        placeholder="Edit notes on this sample"
        onKeyPress={event => handleKeyPressed(event)}
        onChange={handleValueChange}
        value={value}
        autoComplete={Utils.PREVENT_AUTOCOMPLETE_IN_MODAL}
      />
    </Dialog>
  );
}

EditNoteDialog.propTypes = {
  note: PropTypes.string.isRequired,
  producer: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  topCandidate: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default EditNoteDialog;
