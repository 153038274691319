import React, { memo, useState, useEffect } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
import { TextInput } from 'modules/ui';
import { Utils } from 'modules/core/services';

function BipartiteTextInput({ value, error, disabled, onChange }) {
  const [bipartiteInput, setBipartiteInput] = useState(value);

  useEffect(() => {
    setBipartiteInput(value);
  }, [value]);

  function handleInputChange(event) {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    const newBipartiteInput = {
      ...bipartiteInput,
      [fieldName]: fieldValue,
    };

    setBipartiteInput(newBipartiteInput);

    if (onChange) {
      onChange(newBipartiteInput);
    }
  }

  return (
    <Grid container item spacing={2}>
      <Grid item xs={4}>
        <TextInput
          name="name"
          placeholder="Website Name"
          error={error && error.name}
          value={get(bipartiteInput, 'name')}
          disabled={disabled}
          onChange={handleInputChange}
          containerClassName={disabled && 'mb-0'}
          autoComplete={Utils.PREVENT_AUTOCOMPLETE}
        />
      </Grid>
      <Grid item xs={8}>
        <TextInput
          name="url"
          placeholder="www.sample.com"
          error={error && error.url}
          value={get(bipartiteInput, 'url')}
          disabled={disabled}
          onChange={handleInputChange}
          containerClassName={disabled && 'mb-0'}
        />
      </Grid>
    </Grid>
  );
}

BipartiteTextInput.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default memo(BipartiteTextInput);
