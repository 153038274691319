import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/ui';
import styles from './CardTag.module.scss';

const containerStyle = (background, color) => {
  return {
    background,
    color,
  };
};

export default function CardTag({ icon, text, background, color }) {
  return (
    <div style={containerStyle(background, color)} className={styles.container}>
      <Icon size={20} className={styles.icon} color={color} name={icon} />
      {text}
    </div>
  );
}

CardTag.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
};

CardTag.defaultProps = {
  color: 'white',
};
