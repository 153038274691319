import Offer from 'modules/offers/services/offer';

const SORT_OPTIONS = {
  FULLNAME: 'fullName',
};

const {
  SENT,
  NEGOTIATING,
  DECLINED,
  CANCELED,
  HIRED,
  EXPIRED,
} = Offer.RESOLVED_STATUSES_PRODUCER;

function resolveTopCandidateMenuOptions(offerStatus, hasAgent) {
  switch (offerStatus) {
    case SENT:
      if (hasAgent) {
        return ['setNegotiating', 'cancelOffer', 'previewOffer', 'editNote'];
      }

      return ['markHired', 'cancelOffer', 'previewOffer', 'editNote'];

    case NEGOTIATING:
      if (hasAgent) {
        return [
          'markHired',
          'cancelOffer',
          'setDeclined',
          'previewOffer',
          'editNote',
        ];
      }
      return ['markHired', 'cancelOffer', 'previewOffer', 'editNote'];

    case DECLINED:
      return ['previewOffer', 'editNote'];
    case CANCELED:
      return ['previewOffer', 'editNote'];
    case HIRED:
      return ['previewOffer', 'editNote'];
    case EXPIRED:
      return ['markHired', 'previewOffer', 'editNote'];

    // this means offer does not exist yet
    case undefined:
      return ['removeFromList', 'editNote'];

    default:
      return [];
  }
}

function resolveSortOptionPath(sortOption) {
  if (sortOption === SORT_OPTIONS.FULLNAME) {
    return 'talent.fullName';
  }
}

export default {
  resolveTopCandidateMenuOptions,
  resolveSortOptionPath,
  SORT_OPTIONS,
};
