import React from 'react';
import PropTypes from 'prop-types';
import { Collapsible, IconButton, MultiSelect } from 'modules/ui';
import { Colors } from 'modules/theme';
import Dictionary from 'modules/dictionary';
import styles from './SearchForm.module.scss';

export default function SampleSearchForm({
  toggleSamplesForm,
  samplesFormOpen,
  handleSetFormValue,
  getValue,
}) {
  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownContainerTitle}>
        <span className={styles.dropdownTitle}>Samples</span>
        <IconButton
          iconSize={22}
          iconName="expandMore"
          iconColor={Colors.darkGrey}
          className={styles.dropdownIconButton}
          onClick={toggleSamplesForm}
        />
      </div>
      <Collapsible hideButton collapsed={!samplesFormOpen}>
        <MultiSelect
          name="verticals"
          value={getValue('verticals')}
          placeholder="Category"
          setFormValue={handleSetFormValue}
          containerClassName={styles.select}
          collection={Dictionary.VERTICALS}
          options={Dictionary.VERTICAL_OPTIONS}
        />
      </Collapsible>
    </div>
  );
}

SampleSearchForm.propTypes = {
  handleSetFormValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  toggleSamplesForm: PropTypes.func.isRequired,
  samplesFormOpen: PropTypes.func.isRequired,
};
