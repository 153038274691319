export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export const STATUS_CODES = {
  SERVER_ERRORS_REGEX: /5[0-9][0-9]/,
  SERVER_ERROR: '500',
  NOT_FOUND: '404',
  FORBIDDEN: '403',
  BAD_REQUEST: '400',
  NOT_AUTHORIZED: '401',
};

export const API_ERRORS = [
  {
    status: STATUS_CODES.BAD_REQUEST,
    message: 'Bad request',
  },
  {
    status: STATUS_CODES.FORBIDDEN,
    message: 'Forbidden',
  },
  {
    status: STATUS_CODES.NOT_FOUND,
    message: 'Not found',
  },
  {
    status: STATUS_CODES.SERVER_ERROR,
    message: 'Our servers are down, please try again later.',
  },
];

export const ERRORS = [
  {
    code: 'topCandidateEntry_conflict_topCandidateEntryProjectRoleIdConflict',
    message: 'Sample already added to top candidates',
  },
  {
    code: 'notAuthorized_invalidAuthToken',
    message: 'Your login session has expired. Please log in again to continue.',
  },
  {
    code: 'auditionInvitation_conflict_auditionInvitationTalentIdConflict',
    message: 'Talent was already invited to this audition.',
  },
  {
    code: 'producerSharedList_conflict_producerSharedListListIdConflict',
    message: 'List was already shared with this producer.',
  },
  {
    code: 'producerSharedList_conflict_producerSharedListProducerIdConflict',
    message: 'List was already shared with this producer.',
  },
  {
    code: 'account_notFound_accountNotFound',
    message: "Producer with this email doesn't exist.",
  },
];
