export const AUDITION_STATUSES = {
  APPLIED: 'applied',
  INVITED: 'invited',
  SAVED: 'saved',
  SUGGESTED: 'via agent',
};

export const AUDITIONS_DEFAULT_ROWS_PER_PAGE = 15;
export const AUDITIONS_DEFAULT_ROWS_PER_PAGE_MOBILE = 6;
export const AUDITIONS_TALENT_DEFAULT_SORT = '-matchRank,+id';
export const AUDITIONS_AGENT_DEFAULT_SORT = '-createdAt';

export const AUDITIONS_TALENT_SORT_OPTIONS = [
  { label: 'Best Match', value: '-matchRank,+id' },
  { label: 'Newest', value: '-createdAt,+id' },
  { label: 'A-Z', value: 'name,+id' },
  { label: 'Due Date', value: 'dueDate,+id' },
];

export const AUDITIONS_AGENT_SORT_OPTIONS = [
  { label: 'Newest', value: '-createdAt' },
  { label: 'A-Z', value: 'name' },
  { label: 'Due Date', value: 'dueDate' },
];

export const AUDITIONS_TABS = {
  ALL: 'all',
  INVITED: 'invited',
  SAVED: 'saved',
  APPLIED: 'applied',
  SUGGESTED: 'suggested',
};

export const AUDITIONS_PLACEHOLDERS = {
  [AUDITIONS_TABS.ALL]: {
    title: '0 auditions found that match your search.',
    description: "Try adjusting your search to find what you're looking for",
  },
  [AUDITIONS_TABS.INVITED]: {
    title: "You haven't been invited to an audition yet.",
    description:
      'Visit the All Auditions tab to view other casting opportunities.',
  },
  [AUDITIONS_TABS.SAVED]: {
    title: 'No auditions saved yet.',
    description:
      'Visit the All Auditions tab to save new casting opportunities.',
  },
  [AUDITIONS_TABS.APPLIED]: {
    title:
      "You haven't applied to any auditions yet or the auditions you have applied to are inactive.",
    description:
      'Visit the All Auditions tab to find new casting opportunities or your My Submissions tab to see past auditions.',
  },
};

export const NO_OPEN_AUDITIONS_PLACEHOLDER = {
  title: 'There are no open call auditions available at this time.',
  description: 'Content Creators also cast recordings via private auditions and profile samples.',
  description2: 'Update your profile to showcase your recent recordings, vocal skills, and accolades.',
};

export const AUDITIONS_DESCRIPTIONS = {
  [AUDITIONS_TABS.ALL]: '',
  [AUDITIONS_TABS.INVITED]: 'You have been invited to',
  [AUDITIONS_TABS.SAVED]: 'You have saved',
  [AUDITIONS_TABS.APPLIED]: 'You have applied to',
};

export const AUDITIONS_REGEX = RegExp('/auditions/(\\d+)');
