import React from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Colors } from 'modules/theme';
import { Button } from '../Button';
import { CloseButton } from '../CloseButton';
import styles from './Dialog.module.scss';

function Dialog({
  onClose,
  title,
  onAction,
  onSecondaryAction,
  secondaryActionBtnLabel,
  onCancel,
  actionBtnLabel,
  actionDisabled,
  children,
  loading,
  inProgress,
  cancelBtnLabel,
  containerStyle,
  titleStyle,
  subtitleStyle,
  subtitleText,
  renderFooterInfo,
  error,
  errorMessage,
  footerStyle,
}) {
  function handleCancel() {
    if (onCancel) {
      onCancel();
    }

    if (onSecondaryAction) {
      onSecondaryAction();
    }

    if (onClose) {
      onClose();
    }
  }

  return (
    <div className="modalContainer">
      <ClickAwayListener onClickAway={handleCancel}>
        <div className={styles.container} style={containerStyle}>
          {loading && (
            <div className={styles.loaderContainer}>
              <Loader
                type="Oval"
                color={Colors.darkBlue}
                width={30}
                height={30}
              />
            </div>
          )}
          <div className={styles.header}>
            <div className={styles.title}>
              <h2 style={titleStyle}>{title}</h2>
              <div className={styles.closeButtonContainer}>
                <CloseButton onClick={handleCancel} />
              </div>
            </div>
          </div>
          {error && <p className={styles.error}>{errorMessage}</p>}
          {subtitleText && (
            <div className={styles.subtitle} style={subtitleStyle}>
              {subtitleText}
            </div>
          )}
          <div className={styles.children}>{children}</div>
          <div className={styles.footer} style={footerStyle}>
            {onCancel && (
              <Button
                secondary
                className={styles.button}
                onClick={onSecondaryAction || handleCancel}
              >
                {secondaryActionBtnLabel || cancelBtnLabel}
              </Button>
            )}
            {onAction && (
              <Button
                disabled={actionDisabled}
                className={styles.button}
                onClick={onAction}
                primary
                id="submit-button"
                type="submit"
                loading={inProgress}
              >
                {actionBtnLabel}
              </Button>
            )}
          </div>
          {renderFooterInfo && renderFooterInfo()}
        </div>
      </ClickAwayListener>
    </div>
  );
}

Dialog.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  actionBtnLabel: PropTypes.string,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  secondaryActionBtnLabel: PropTypes.string,
  actionDisabled: PropTypes.bool,
  onSecondaryAction: PropTypes.func,
  children: PropTypes.node,
  inProgress: PropTypes.bool,
  loading: PropTypes.bool,
  cancelBtnLabel: PropTypes.string,
  containerStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  subtitleStyle: PropTypes.object,
  subtitleText: PropTypes.string,
  renderFooterInfo: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  footerStyle: PropTypes.object,
};

Dialog.defaultProps = {
  cancelBtnLabel: 'Cancel',
};

export default Dialog;
