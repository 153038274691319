import { createReducer } from 'redux-starter-kit';
import get from 'lodash/get';
import { createThunk } from 'redux-scope';
import createSelector from 'selectorator';
import { Assets, uploadAsset } from 'modules/assets';
import { Audition } from 'modules/auditions';
import filter from 'lodash/filter';
import {
  fetchProducer,
  fetchProducerStats,
  postProducer,
  patchProducer,
  fetchProducerAuditions,
  fetchSharedProducer,
  searchProducers,
} from './api';

const INITIAL_STATE = {
  loading: false,
  producer: null,
  producerStats: null,
  searchedProducers: [],
};

export const loadProducer = createThunk(fetchProducer, 'load', 'producers');
export const loadSharedProducer = createThunk(
  fetchSharedProducer,
  'loadShared',
  'producers',
);
export const loadProducerAuditions = createThunk(
  fetchProducerAuditions,
  'loadProducerAuditions',
  'producers',
);
export const loadProducerStats = createThunk(
  fetchProducerStats,
  'loadStats',
  'producers',
);
export const createProducer = createThunk(postProducer, 'create', 'producers');
const update = createThunk(patchProducer, 'update', 'producers');

export function uploadProducerProfileImage(accountId, imageFile) {
  return dispatch => {
    const context = Assets.createAccountContext(accountId);
    return dispatch(uploadAsset(imageFile, 'image', 'profileImage', context));
  };
}

export function updateProducer(accountId, producerId, values) {
  return dispatch => {
    const profileImage = get(values, 'profileImage');

    if (profileImage) {
      return dispatch(uploadProducerProfileImage(accountId, profileImage)).then(
        assetPath => {
          const producerValues = {
            ...values,
            profileImage: assetPath,
          };

          return dispatch(update(producerId, producerValues));
        },
      );
    }

    return dispatch(update(producerId, values));
  };
}

export const loadSearchedProducers = createThunk(
  searchProducers,
  'loadSearched',
  'producers',
);

export const reducer = createReducer(INITIAL_STATE, {
  [loadProducer.type.request]: state => {
    state.loading = true;
  },
  [loadProducer.type.success]: (state, action) => {
    state.loading = false;
    state.producer = action.payload.data;
  },
  [loadSharedProducer.type.success]: (state, action) => {
    state.producer = action.payload.data;
  },
  [loadProducerAuditions.type.request]: state => {
    state.loading = true;
  },
  [loadProducerAuditions.type.success]: (state, action) => {
    state.loading = false;
    // TODO Ahab clinic (516) move this to auditions-producers module
    state.producerAuditions = action.payload.data;
  },
  [loadProducerStats.type.request]: state => {
    state.loading = true;
  },
  [loadProducerStats.type.success]: (state, action) => {
    state.loading = false;
    state.producerStats = action.payload.data;
  },
  [loadProducerStats.type.error]: state => {
    state.loading = false;
  },
  [createProducer.type.request]: state => {
    state.loading = true;
  },
  [createProducer.type.success]: (state, action) => {
    state.loading = false;
    state.producer = action.payload.data;
  },
  [update.type.request]: state => {
    state.loading = true;
  },
  [update.type.success]: (state, action) => {
    state.loading = false;
    state.producer = action.payload.data;
  },
  [update.type.error]: state => {
    state.loading = false;
  },
  [loadSearchedProducers.type.request]: state => {
    state.loading = true;
  },
  [loadSearchedProducers.type.success]: (state, action) => {
    state.searchedProducers = action.payload.data;
    state.loading = false;
  },
  [loadSearchedProducers.type.error]: state => {
    state.loading = false;
  },
});

export const getProducer = createSelector(['producers.producer']);
export const getProducerId = createSelector(['producers.producer.id']);
export const getProducerStats = createSelector(['producers.producerStats']);
export const getActiveAuditionsByProducerId = producerId =>
  createSelector(
    ['producers.producerAuditions'],
    auditions => {
      return filter(
        auditions,
        audition =>
          Audition.getAuditionStatus(audition) === Audition.STATUSES.ACTIVE &&
          audition.producerId === producerId,
      );
    },
  );
export const getSearchedProducers = createSelector([
  'producers.searchedProducers',
]);
export const getProducersLoading = createSelector(['producers.loading']);
