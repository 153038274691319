import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import { Section, InlineSection } from 'modules/ui';
import { DateUtil } from 'modules/core/services';
import RecordingLocationInlineSection from 'modules/auditions/components/RecordingLocationInlineSection';

function RecordingDetailsSection({ offer }) {
  const amount = get(offer.recordingLength, 'amount');
  const type = get(offer.recordingLength, 'type');
  const recordingLength =
    !amount || !type ? null : `${amount} ${lowerCase(type)}`;

  const formattedDateRange = `${DateUtil.formatDate(
    offer.recordingStartDate,
  )} - ${DateUtil.formatDate(offer.recordingEndDate)}`;

  return (
    <Section title="Recording Details">
      <Grid container item spacing={4}>
        <Grid item xs={12}>
          <InlineSection title="Recording window" text={formattedDateRange} />
        </Grid>
        <Grid item xs={12}>
          <InlineSection title="Recording length" text={recordingLength} />
        </Grid>
        <Grid item xs={12}>
          <RecordingLocationInlineSection
            recordingLocations={offer.recordingLocations}
            studioAddress={offer.studioAddress}
          />
        </Grid>
      </Grid>
    </Section>
  );
}

RecordingDetailsSection.propTypes = {
  offer: PropTypes.object.isRequired,
};
export default memo(RecordingDetailsSection);
