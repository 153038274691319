import { createReducer, createAction } from 'redux-starter-kit';
import createSelector from 'selectorator';

import { refreshAuditionMessagingWindow } from 'modules/audition-messages';
import { refreshGlobalMessagingWindow } from 'modules/global-messages';

import { MAX_NUM_OF_OPENED_CHATS, ChatType } from './const';
import { findChatPopup, existsChatPopup, withinChatWindows } from './services';

const INITIAL_STATE = {
  windows: [],
};

export const openChat = createAction('chat/open-window');
export const closeChat = createAction('chat/close-window');
export const closeAllChatWindows = createAction('chat/close-all-chat-windows');

export function openChatPopup(id, type, params, filter) {
  return (dispatch, getState) => {
    if (existsChatPopup(withinChatWindows(getState), filter)) {
      return;
    }
    dispatch(openChat({ id, type, ...params }));
  };
}

export function closeChatPopup(filter) {
  return (dispatch, getState) => {
    const { id } = findChatPopup(withinChatWindows(getState), filter);
    dispatch(closeChat(id));
  };
}

export function refreshChatWindow(accountId, chatWindow) {
  const { type } = chatWindow;
  if (type === ChatType.GLOBAL) {
    return refreshGlobalMessagingWindow(accountId, chatWindow);
  }
  return refreshAuditionMessagingWindow(accountId, chatWindow);
}

export function refreshChatWindows(accountId) {
  return (dispatch, getState) => {
    const chatWindows = withinChatWindows(getState);
    chatWindows.forEach(chatWindow => {
      dispatch(refreshChatWindow(accountId, chatWindow));
    });
  };
}

export const reducer = createReducer(INITIAL_STATE, {
  [openChat]: (state, action) => {
    if (state.windows.length === MAX_NUM_OF_OPENED_CHATS) {
      state.windows = state.windows.slice(1);
    }

    const { id, type, ...params } = action.payload;
    const chatWindow = { id, type, ...params };

    state.windows = [...state.windows, chatWindow];
  },
  [closeChat]: (state, action) => {
    state.windows = state.windows.filter(
      window => window.id !== action.payload,
    );
  },
  [closeAllChatWindows]: state => {
    state.windows = [];
  },
});

export const getChatWindows = createSelector(['chat.windows']);
