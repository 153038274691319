import { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { loadUnreadMessagesCount as loadUnreadApplicationsCount } from 'modules/audition-messages';
import { loadUnreadMessagesCount as loadUnreadGlobalMessagesCount } from 'modules/global-messages';

import { refreshChatWindows } from './redux';
import { CHAT_REFRESH_RATE } from './const';

export function useScrollToBottom(dependency) {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [dependency]);
  return ref;
}

export function useChatRefresh(accountId) {
  const dispatch = useDispatch();

  const refreshChat = useCallback(() => {
    dispatch(loadUnreadApplicationsCount());
    dispatch(loadUnreadGlobalMessagesCount());
    dispatch(refreshChatWindows(accountId));
  }, [dispatch, accountId]);

  useEffect(() => {
    refreshChat();
    const chatRefreshInterval = setInterval(refreshChat, CHAT_REFRESH_RATE);
    return () => clearInterval(chatRefreshInterval);
  }, [refreshChat]);
}
