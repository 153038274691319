import React from 'react';
import { TextInput, ManagedForm } from 'modules/ui';
import { Validator } from '../services';
import { SocialLinksGrid } from './form-grids';
import classes from './ProfileDetailsForm.module.scss';

export default function SocialLinksForm(props) {
  return (
    <ManagedForm
      withPrompt
      title="Social Media"
      description="Add your social media links"
      submitButtonLabel="Save Social Media"
      TemplateComponent={SocialLinksGrid}
      validate={Validator.validateSocialLinks}
      titleContainerClassName={classes.titleContainer}
      {...props}
    >
      <TextInput
        link
        label="LinkedIn"
        name="linkedinLink"
        placeholder="linkedin.com/in/username"
      />
      <TextInput
        link
        label="Instagram"
        name="instagramLink"
        placeholder="instagram.com/username"
      />
      <TextInput
        link
        label="X (formerly Twitter)"
        name="twitterLink"
        placeholder="x.com/username"
      />
      <TextInput
        autoFocus
        link
        label="Facebook"
        name="facebookLink"
        placeholder="facebook.com/username"
      />
    </ManagedForm>
  );
}
