import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import Dictionary from 'modules/dictionary';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import Offer from './offer';

const ALL_OFFERS = { value: 'All', label: 'All' };

const SORT_OPTIONS = {
  CLIENT: 'talent',
  PROJECT_NAME: 'projectName',
  DATE_RECEIVED: 'createdAt',
  DUE_DATE: 'dueDate',
};

function offerStatusNotSelected(status) {
  return status === null || status === ALL_OFFERS.value;
}

function filterByStatus(offers, status, isTalent) {
  if (offerStatusNotSelected(status)) {
    return offers;
  }

  return filter(
    offers,
    offer => Offer.getResolvedStatus(offer, isTalent) === status,
  );
}

function getTalentStatusOptions() {
  return [ALL_OFFERS, ...Dictionary.OFFER_STATUSES_TALENT_OPTIONS];
}

function getUniqueStatuses(offers) {
  if (!offers) {
    return [];
  }

  return uniq(map(offers, offer => Offer.getResolvedStatus(offer, true)));
}

function applySortOptions(offers, sortOption, sortOrder) {
  if (!sortOption) {
    return offers;
  }

  if (sortOption === 'projectName') {
    return orderBy(
      offers,
      [
        offer =>
          sortOption === SORT_OPTIONS.PROJECT_NAME
            ? get(offer, 'projectRole.project.title').toLowerCase()
            : offer[sortOption].toLowerCase(),
      ],
      sortOrder,
    );
  }
  return orderBy(
    offers,
    [
      offer =>
        sortOption === SORT_OPTIONS.CLIENT
          ? get(offer, 'talent.fullName').toLowerCase()
          : offer[sortOption].toLowerCase(),
    ],
    sortOrder,
  );
}

export default {
  filterByStatus,
  ALL_OFFERS,
  SORT_OPTIONS,
  getUniqueStatuses,
  getTalentStatusOptions,
  applySortOptions,
};
