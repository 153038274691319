import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
import classNames from 'classnames';
import { Button } from 'modules/ui';
import styles from './RepeaterRow.module.scss';

export default function RepeaterRowButtons({
  value,
  index,
  onAdd,
  onRemove,
  style,
}) {
  const handleRemove = () => onRemove(index);

  const addButtonClasses = classNames(styles.button, styles['button--add']);
  const removeButtonClasses = classNames(
    styles.button,
    styles['button--remove'],
  );

  return (
    <Grid item xs={2}>
      {onAdd && (
        <Button
          primary
          small
          className={addButtonClasses}
          onClick={onAdd}
          disabled={!value}
          style={style}
        >
          Add
        </Button>
      )}
      {onRemove && (
        <Button
          primary
          small
          className={removeButtonClasses}
          onClick={handleRemove}
          style={style}
        >
          Remove
        </Button>
      )}
    </Grid>
  );
}

RepeaterRowButtons.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  style: PropTypes.object,
};
