import get from 'lodash/get';
import has from 'lodash/has';
import { Api } from 'modules/core';

const INCLUDE = 'producer,projectRole.project,nonDisclosureAgreement';

export function fetchAudition(auditionId) {
  if (!auditionId) {
    return Promise.resolve(null);
  }

  const endpoint = `auditions/${auditionId}`;
  const params = { include: INCLUDE };

  return Api.find(endpoint, params);
}

export function search(searchOptions) {
  const endpoint = 'auditions/actions/search';
  const data = { resourceType: 'audition-search-actions', ...searchOptions };
  const params = { include: INCLUDE };
  const sort = get(data, 'sort');

  if (sort) {
    params.sort = sort;
    delete data.sort;
  }

  if (has(data, 'offset')) {
    params['page[offset]'] = get(data, 'offset');
    delete data.offset;
  }

  if (has(data, 'limit')) {
    params['page[limit]'] = get(data, 'limit');
    delete data.limit;
  }

  return Api.create(endpoint, data, null, params);
}
