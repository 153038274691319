import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import Grid from '@material-ui/core/Grid';
import { useTealiumView } from 'modules/accounts';
import { InlineSection, Section } from 'modules/ui';
import { ProjectRoles } from 'modules/project-roles';
import { getProjectById } from '../redux';
import { Project } from '../services';
import styles from './ProjectDetails.module.scss';

export default function ProjectDetails({ projectId }) {
  const project = useSelector(getProjectById(projectId));

  const recordingDueDate = get(project, 'recordingDueDate');
  const recordingDate = moment(recordingDueDate).format('MMM DD');
  const recordingYear = moment(recordingDueDate).format('YYYY');
  const genre = get(project, 'genre');
  const length = get(project, 'length');
  const lengthType = capitalize(startCase(get(project, 'lengthType')));
  const lengthTitle = `${length} ${lengthType}`;
  const category = get(project, 'vertical');
  const description = get(project, 'description');

  return (
    <Grid container spacing={4}>
      <Grid item xs={9}>
        <ProjectRoles projectId={projectId} />
        <Section title="Overview">
          <InlineSection
            title="Description"
            isEmpty={isEmpty(description)}
            placeholderText="No description to show"
          >
            <p className="body16">{description}</p>
          </InlineSection>
        </Section>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2} className="mt-80">
        <div className={styles.rightContainer}>
          <InlineSection
            title="Recording due date"
            isEmpty={isEmpty(recordingDueDate)}
            placeholderText="Not provided."
          >
            <div className={styles.recordingDate}>{recordingDate}</div>
            <div className={styles.recordingYear}>{recordingYear}</div>
          </InlineSection>
          <InlineSection
            title="Recording duration"
            isEmpty={isEmpty(length)}
            placeholderText="Not provided."
          >
            <p className="body16">{lengthTitle}</p>
          </InlineSection>
          <InlineSection
            title="Category"
            isEmpty={isEmpty(category)}
            placeholderText="Not provided."
          >
            <p className="body16">{category}</p>
          </InlineSection>
          {Project.verticalHasGenres(category) && (
            <InlineSection
              title="Genre"
              isEmpty={isEmpty(genre)}
              placeholderText="Not provided."
            >
              <p className="body16">{genre}</p>
            </InlineSection>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

ProjectDetails.propTypes = {
  projectId: PropTypes.string,
};
