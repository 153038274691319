import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './InfoBanner.module.scss';

function InfoBanner({ text, textStyle, containerStyle }) {
  const containerClasses = classNames(classes.banner);

  const textClasses = classNames('body16', classes.text);

  return (
    <div style={containerStyle} className={containerClasses}>
      <div style={textStyle} className={textClasses}>
        {text}
      </div>
    </div>
  );
}

InfoBanner.propTypes = {
  text: PropTypes.string,
  containerStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

export default memo(InfoBanner);
