import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SelectArrow, DropdownMenu, TextInput } from 'modules/ui';
import { Colors } from 'modules/theme';
import { DateUtil, useDidMount, Utils } from 'modules/core';
import styles from './DatePicker.module.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.secondary,
    },
  },
});

const selectStyle = {
  color: 'transparent',
  textShadow: `0px 0px 0px ${Colors.darkGrey}`,
};

const DAY_END = { hour: 20, minute: 0, second: 0 };
const DAY_START = { hour: 4, minute: 0, second: 0 };

export default function DatePickerInput({
  name,
  value,
  minDate,
  maxDate,
  error,
  label,
  placeholder,
  width,
  setFormValue,
  arrowClassName,
  setAsDayStart,
  ...otherProps
}) {
  const didMount = useDidMount();

  const inputEl = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(DateUtil.formatDate(value));

  function handleSetDate(isoDate) {
    const formattedDate = DateUtil.formatDate(isoDate);
    setSelectedDate(formattedDate);

    if (setFormValue) {
      setFormValue(name, isoDate);
    }
  }

  useEffect(() => {
    if (didMount) {
      handleSetDate(value);
    }
  }, [value]);

  const isOpen = !!anchorEl;

  function handleSelectFocus(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(event) {
    // hack for Mui Date picker to prevent triggering dropdown close
    const className = get(event, 'currentTarget.className');
    if (includes(className, 'Mui')) {
      return;
    }

    setAnchorEl(null);
  }

  function handleDateChange(date) {
    if (date && isOpen) {
      date.set(setAsDayStart ? DAY_START : DAY_END);

      const isoDate = date.toISOString(true);
      handleSetDate(isoDate);
    }

    handleClose();
  }

  function handleMonthChange(date) {
    if (date && isOpen) {
      let newDate = moment(date);

      if (minDate && newDate < minDate) {
        newDate = minDate;
      }

      if (maxDate && newDate > maxDate) {
        newDate = maxDate;
      }

      newDate.set(setAsDayStart ? DAY_START : DAY_END);

      const isoDate = newDate.toISOString();
      const formattedDate = DateUtil.formatDate(isoDate);
      setSelectedDate(formattedDate);

      if (setFormValue) {
        setFormValue(name, isoDate);
      }
    }
  }

  return (
    <>
      <TextInput
        setRef={inputEl}
        label={label}
        name={name}
        value={selectedDate}
        error={error}
        placeholder={placeholder}
        onClick={handleSelectFocus}
        RightIconComponent={
          <SelectArrow className={classNames(styles.arrow, arrowClassName)} />
        }
        className={styles.input}
        style={selectStyle}
        autoComplete={Utils.PREVENT_AUTOCOMPLETE}
        {...otherProps}
      />
      <DropdownMenu
        onClose={handleClose}
        width={width}
        open={isOpen}
        anchorEl={anchorEl}
        arrowRightPosition="20px"
      >
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disableToolbar
              variant="static"
              minDate={minDate}
              maxDate={maxDate}
              value={selectedDate}
              onChange={handleDateChange}
              onMonthChange={handleMonthChange}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </DropdownMenu>
    </>
  );
}

DatePickerInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setFormValue: PropTypes.func,
  arrowClassName: PropTypes.string,
  setAsDayStart: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  placeholder: 'Pick a date',
  minDate: moment(),
  setAsDayStart: false,
};
