import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'modules/ui';
import styles from './WizardFooter.module.scss';

const WizardFooter = ({
  onNextClick,
  onPreviousClick,
  previousButtonLabel,
  nextButtonLabel,
  hasPreviousButton,
}) => {
  const [previousInProgress, setPreviousInProgress] = useState(false);
  const [nextInProgress, setNextInProgress] = useState(false);

  const handlePreviousClick = () => {
    setPreviousInProgress(true);

    return onPreviousClick().finally(() => {
      setPreviousInProgress(false);
    });
  };

  const handleNextClick = () => {
    setNextInProgress(true);

    return onNextClick().finally(() => {
      setNextInProgress(false);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.footer}>
        <div className={styles.btnContainer}>
          {hasPreviousButton && (
            <Button
              secondary
              loading={previousInProgress}
              onClick={handlePreviousClick}
              className={styles.btn}
            >
              {previousButtonLabel}
            </Button>
          )}
          <Button
            primary
            loading={nextInProgress}
            onClick={handleNextClick}
            className={styles.btn}
          >
            {nextButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

WizardFooter.propTypes = {
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
  previousButtonLabel: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  hasPreviousButton: PropTypes.bool,
};

WizardFooter.defaultProps = {
  previousButtonLabel: 'Previous step',
  nextButtonLabel: 'Next step',
  hasPreviousButton: true,
};

export default memo(WizardFooter);
