import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'modules/ui';
import classes from './MessageAvatar.module.scss';

function MessageAvatar({ photoUrl }) {
  return <Avatar size={24} photoUrl={photoUrl} className={classes.avatar} />;
}

MessageAvatar.propTypes = {
  photoUrl: PropTypes.string,
};

export default MessageAvatar;
