import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAsset } from 'modules/assets';
import { showNotification, Notification } from 'modules/notifications';
import { TalentCard } from 'modules/talents';
import { Utils } from 'modules/core';

function SearchTalentCard({
  history,
  talent,
  addToList,
  onInviteToAudition,
  selectedVerticals,
  selectedPreferredGenres,
}) {
  const dispatch = useDispatch();

  const { profileImage } = talent;
  const [profileUrl, setProfileUrl] = useState(null);

  useEffect(() => {
    if (profileImage) {
      getAsset(profileImage).then(url => setProfileUrl(url));
    }
  }, [profileImage, setProfileUrl]);

  function handleClick() {
    const talentPath = `talent/${talent.id}`;
    history.push(talentPath);
  }

  function handleAddToList(event) {
    if (event) {
      event.stopPropagation();
    }

    global.utag.link({ event_type: 'list_add' });

    if (addToList) {
      addToList(talent);
    }
  }

  function handleInviteToAudition() {
    global.utag.link({ event_type: 'audition_invite' });

    if (onInviteToAudition) {
      onInviteToAudition(talent);
    }
  }

  function handleShareProfileClick() {
    global.utag.link({ event_type: 'share_profile' });

    return Utils.copyToClipboard(
      `${window.location.host}/talent/${talent.id}`,
    ).then(() =>
      dispatch(
        showNotification({
          message: 'Profile link copied',
          type: Notification.TYPES.SUCCESS,
        }),
      ),
    );
  }

  return (
    <TalentCard
      onClick={handleClick}
      talent={talent}
      avatar={profileUrl}
      selectedVerticals={selectedVerticals}
      selectedPreferredGenres={selectedPreferredGenres}
      onAddToList={handleAddToList}
      onShareProfile={handleShareProfileClick}
      onInviteToAudition={handleInviteToAudition}
    />
  );
}

SearchTalentCard.propTypes = {
  history: PropTypes.object,
  onInviteToAudition: PropTypes.func,
  talent: PropTypes.object,
  addToList: PropTypes.func,
  selectedVerticals: PropTypes.array,
  selectedPreferredGenres: PropTypes.array,
};

export default withRouter(SearchTalentCard);
