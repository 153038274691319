import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import {
  PhoneInput,
  Textarea,
  ManagedForm,
  ManagedImageInput,
  OptionUtil,
  AutoCompleteSelect,
  TextInput,
  MultiSelect,
} from 'modules/ui';
import Dictionary from 'modules/dictionary';

import { Talent } from '../services';
import GdprModal, { GDPR_ETHNICITY } from './GdprModal';
import { PersonalInfoGrid } from './form-grids';
import classes from './PersonalInfoForm.module.scss';

const styles = {
  image: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  noLabel: {
    marginTop: 34,
  },
  rightPadded: {
    paddingRight: 40,
  },
  location: {
    marginTop: 0,
  },
  addressInput: {
    marginTop: 4,
  },
  gdprFooter: {
    alignSelf: 'center',
  },
};

export default function PersonalInfoForm({
  talent,
  onSubmit,
  image,
  onSubmitEthnicityGdprConsent,
  onCloseEthnicityGdprConsent,
  ...otherProps
}) {
  const countryId = get(talent, 'country.id');
  const { ethnicityGdprAcceptedAt } = talent;
  const [country, setCountry] = useState(
    find(Dictionary.LOCATIONS, { id: countryId }),
  );
  const states = country ? country.states : null;
  const [availableStates, setAvailableStates] = useState(states);
  const [gdprModalOpen, setGdprModalOpen] = useState(
    Talent.isEthnicityGdprNotAccepted(talent),
  );

  const handleCountryChange = event => {
    const id = event.target.value;
    const currentCountry = find(Dictionary.LOCATIONS, { id });

    const foundStates = currentCountry ? currentCountry.states : null;

    setAvailableStates(foundStates);
    setCountry(currentCountry);
  };

  function handleValueChange(values) {
    if (!values.countryId) {
      setAvailableStates([]);
    }
  }

  const stateOptions = OptionUtil.getOptions(
    availableStates,
    OptionUtil.mapOptionFromModel,
  );

  return (
    <>
      <ManagedForm
        withPrompt
        title="Personal Info"
        description="Tell us about yourself"
        submitButtonLabel="Save personal info"
        onSubmit={onSubmit}
        TemplateComponent={PersonalInfoGrid}
        initialValues={talent}
        onChangeValues={handleValueChange}
        {...otherProps}
      >
        <TextInput
          autoFocus
          label="First Name"
          name="firstName"
          placeholder="E.g. John"
        />
        <TextInput
          autoFocus
          label="Last Name"
          name="lastName"
          placeholder="E.g. Doe"
        />
        <ManagedImageInput
          name="profileImage"
          label="Profile photo"
          title="Edit profile photo"
          initialImage={image}
          cropShape="round"
          showGrid={false}
          imageStyle={styles.image}
          className={classes.profileImage}
        />
        <MultiSelect
          label="Gender"
          name="genders"
          placeholder="Select gender"
          options={Dictionary.GENDER_OPTIONS}
          arrowStyle={styles.unionsArrow}
        />
        <MultiSelect
          label="Pronouns"
          name="pronouns"
          placeholder="Select pronoun"
          options={Dictionary.PRONOUN_OPTIONS}
        />
        <PhoneInput autoFocus label="Contact number" name="contactNumber" />

        <AutoCompleteSelect
          label="Address"
          name="countryId"
          placeholder="Enter country"
          onChange={handleCountryChange}
          options={Dictionary.LOCATIONS_OPTIONS}
          containerStyle={styles.location}
          style={styles.rightPadded}
        />
        <AutoCompleteSelect
          name="stateId"
          placeholder="Enter state"
          options={stateOptions}
          disabled={isEmpty(stateOptions)}
          containerStyle={styles.location}
          style={styles.noLabel}
          arrowStyle={styles.noLabel}
        />
        <TextInput
          name="city"
          placeholder="City"
          containerStyle={styles.location}
        />
        <TextInput
          name="zipCode"
          placeholder="ZIP Code"
          containerStyle={styles.location}
        />
        <MultiSelect
          label="Cultural background"
          name="ethnicities"
          placeholder="Select cultural background"
          collection={Dictionary.ETHNICITIES}
          options={Dictionary.ETHNICITY_OPTIONS}
          arrowStyle={styles.unionsArrow}
          onClick={
            !ethnicityGdprAcceptedAt &&
            (() => {
              setGdprModalOpen(true);
            })
          }
        />
        <Textarea
          label="Biography"
          name="biography"
          maxChars={1000}
          placeholder="Tell us about yourself."
        />
        <Textarea
          label="General knowledge"
          name="generalKnowledge"
          maxChars={1000}
          placeholder="Share your hobbies, additional expertise or anything else you think is relevant."
        />
      </ManagedForm>
      {gdprModalOpen && (
        <GdprModal
          type={GDPR_ETHNICITY}
          onSubmitEthnicityGdprConsent={onSubmitEthnicityGdprConsent}
          onCloseEthnicityGdprConsent={onCloseEthnicityGdprConsent}
          handleModalOpen={isOpen => setGdprModalOpen(isOpen)}
          isDataFilledWithoutConsent={Talent.isEthnicityGdprNotAccepted(talent)}
        />
      )}
    </>
  );
}

PersonalInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  talent: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onSubmitEthnicityGdprConsent: PropTypes.func.isRequired,
  onCloseEthnicityGdprConsent: PropTypes.func.isRequired,
};
