import React from 'react';
import Loader from 'react-loader-spinner';
import { Colors } from 'modules/theme';

const styles = {
  loader: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    paddingTop: 54,
  },
};

export default function PageLoader() {
  return (
    <div style={styles.loader}>
      <Loader type="Oval" color={Colors.darkBlue} width={30} height={30} />
    </div>
  );
}
