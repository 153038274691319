import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Textarea, Button } from 'modules/ui';
import { AudioInput } from 'modules/audio';
import { Assets, Validator as AssetValidator } from 'modules/assets';
import { getAccountType, AccountType } from 'modules/accounts';

import { ArrowIcon, UploadSampleIcon } from '../assets';
import { MAX_MESSAGE_LENGTH } from '../const';

import classes from './SendMessageChatSection.module.scss';

function SendMessageChatSection({
  className,
  enableSampleUploading,
  onSubmitClick,
}) {
  const textRef = useRef(null);
  const inputRef = useRef(null);

  const [text, setText] = useState('');

  const [file, setFile] = useState(null);
  const [details, setDetails] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);

  const accountType = useSelector(getAccountType);
  const isTalent = AccountType.isTalent(accountType);

  function handleTextChange(textValue) {
    setText(textValue);
  }

  const handleSubmitClick = () => {
    const submitData = {
      text,
      file,
      details,
    };

    if (!text) {
      return Promise.resolve();
    }

    setSendingMessage(true);

    return onSubmitClick(submitData).finally(() => {
      setText(null);
      textRef.current.reset();
      textRef.current.focus();

      setDetails(null);
      setFile(null);
      setSendingMessage(false);
    });
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmitClick();
    }
  };

  function handleRemoveFile() {
    setFile(null);
    setDetails(null);

    return Promise.resolve();
  }

  function handleSelectFile(fileObject) {
    const { name } = fileObject;
    const filename = Assets.getNameWithoutExtension(name);

    setFile(fileObject);

    setDetails({ name: filename });
  }

  const textareaContainerClassNames = classNames(
    classes.textareaContainer,
    file
      ? classes.textareaFileSelectedContainer
      : classes.textareaNoFileSelectedContainer,
  );

  const textareaClassNames = classNames(
    classes.textarea,
    file
      ? classes.textareaFileSelected
      : isTalent && classes.textareaNoFileSelected,
  );

  const sampleContainerClasses = classNames(
    classes.uploadSampleButtonContainer,
    file
      ? classes.uploadSampleButtonContainerFileSelected
      : classes.uploadSampleButtonContainerNoFileSelected,
  );

  return (
    <div className={classNames(classes.sendMessageSection, className)}>
      <Textarea
        disabled={sendingMessage}
        ref={textRef}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        className={textareaContainerClassNames}
        textareaClassnames={textareaClassNames}
        maxLength={MAX_MESSAGE_LENGTH}
        placeholder="Add a message"
      />
      {enableSampleUploading && isTalent && (
        <AudioInput
          required
          simpleButtonInput
          disabled={sendingMessage}
          noBorder
          noReplaceButton
          name="file"
          className={sampleContainerClasses}
          uploadBtnClassName={classes.uploadSampleButton}
          value={details}
          file={file}
          setRef={inputRef}
          buttonLabel={<UploadSampleIcon />}
          onSelectFile={handleSelectFile}
          validate={newFile => AssetValidator.validate(newFile, 'audio')}
          onRemoveFileClick={handleRemoveFile}
        />
      )}
      <Button
        primary
        small
        disabled={sendingMessage || !text}
        loading={sendingMessage}
        onClick={handleSubmitClick}
        className={classes.sendButton}
      >
        <ArrowIcon />
      </Button>
    </div>
  );
}

SendMessageChatSection.propTypes = {
  className: PropTypes.string,
  enableSampleUploading: PropTypes.bool,
  onSubmitClick: PropTypes.func,
};

SendMessageChatSection.defaultProps = {
  enableSampleUploading: true,
};

export default SendMessageChatSection;
