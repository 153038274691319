import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactUtil } from 'modules/core/services';
import classes from './PageHeaderTitle.module.scss';

export default function PageHeaderTitle({
  title,
  description,
  SubtitleComponent,
  titleContainerClasses,
}) {
  const containerClasses = classNames(
    classes.titleContainer,
    titleContainerClasses,
  );
  return (
    <div className={containerClasses}>
      <h1 className={classes.title}>{title}</h1>
      {description && <p className={classes.description}>{description}</p>}
      {ReactUtil.resolveComponent(SubtitleComponent)}
    </div>
  );
}

PageHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  SubtitleComponent: PropTypes.elementType,
  description: PropTypes.string,
  titleContainerClasses: PropTypes.string,
};
