import React, { useState } from 'react';
import shortid from 'shortid';
import get from 'lodash/get';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Assets, MAX_FILE_SIZE } from 'modules/assets';
import { LodashUtil } from 'modules/core';
import { AudioInput } from 'modules/audio';
import { Label } from 'modules/ui';
import { showNotification, Notification } from 'modules/notifications';
import { SampleDetailsForm } from '../components';
import {
  createAudioSample,
  removeAudioSample,
  updateAudioSample,
} from '../redux';
import styles from './SampleManager.module.scss';

const INFO = ['readableDuration'];

export default function SampleManager({
  talent,
  label,
  initialValues,
  onSubmit,
  onRemove,
  moreSamplesBtn,
  moreSamplesBtnDisabled,
  placeholderVisible,
}) {
  const dispatch = useDispatch();
  const [details, setDetails] = useState(initialValues);
  const [updatedDetails, setUpdatedDetails] = useState(null);
  const [file, setFile] = useState(null);
  const [formCollapsed, setFormCollapsed] = useState(true);
  const [initialFileDetails, setInitialFileDetails] = useState(initialValues);

  function handleSelectFile(selectedFile) {
    const isReplacement = !!get(details, 'id', null);
    const { name } = selectedFile;
    const filename = LodashUtil.truncateText(
      Assets.getNameWithoutExtension(name),
      240,
    );

    setFile(selectedFile);
    setFormCollapsed(false);

    if (!details) {
      setInitialFileDetails({ name: filename, isInitial: true });
      setDetails({ name: filename });
      return;
    }

    if (isReplacement) {
      setDetails({ ...details, name: filename, isReplacement });
      setUpdatedDetails({ name: filename });
      return;
    }

    if (file && file !== selectedFile) {
      setUpdatedDetails({ name: filename });
      setDetails({ ...details, name: filename });
      return;
    }

    setDetails({ name: filename });
  }

  function handleChangeName(event) {
    const name = LodashUtil.truncateText(event.target.value, 240);
    setDetails({ ...details, name });
  }

  function handleSubmitDetails(values) {
    const audioSampleId = get(details, 'id');
    const audioSampleName = get(details, 'name');
    const resolvedValues = { ...details, ...values };
    const resolvedData = { ...resolvedValues, name: audioSampleName };
    delete resolvedData.isReplacement;

    if (!audioSampleId) {
      return dispatch(createAudioSample(talent.id, resolvedData, file)).then(
        payload => {
          const data = get(payload, 'data');
          dispatch(
            showNotification({
              message: `Audio sample ${audioSampleName} successfully created.`,
              type: Notification.TYPES.SUCCESS,
            }),
          );
          setFormCollapsed(true);
          setDetails(payload.data);
          onSubmit(data);
        },
      );
    }

    return dispatch(
      updateAudioSample(talent.id, audioSampleId, resolvedValues, file),
    ).then(payload => {
      const data = get(payload, 'data');
      dispatch(
        showNotification({
          message: `Audio sample ${audioSampleName} successfully updated.`,
          type: Notification.TYPES.SUCCESS,
        }),
      );

      setFormCollapsed(true);
      return onSubmit(data);
    });
  }

  function handleRemoveFileClick() {
    const audioSampleId = get(details, 'id');
    const audioSampleName = get(details, 'name');

    if (!audioSampleId) {
      setFile(null);
      setDetails(null);
      setUpdatedDetails(null);
      return Promise.resolve();
    }

    return dispatch(removeAudioSample(talent.id, audioSampleId))
      .then(() => {
        setFile(null);
        setDetails(null);
        setUpdatedDetails(null);

        dispatch(
          showNotification({
            message: `Audio sample ${audioSampleName} successfully deleted.`,
            type: Notification.TYPES.SUCCESS,
          }),
        );

        return onRemove(audioSampleId);
      })
      .catch(error => {
        dispatch(
          showNotification({
            message: 'There was a problem deleting the file.',
            type: Notification.TYPES.ERROR,
          }),
        );
        Promise.reject(error);
      });
  }

  const fileContainerClasses = classNames(
    styles.fileContainer,
    details && styles['fileContainer--withForm'],
  );

  const size = Assets.convertBytesToMegaBytes(MAX_FILE_SIZE.audio);
  const description = `Supported formats: .mp3, .wav. Max size ${size}MB`;

  const containerStyles = classNames(
    details && styles.container,
    !details && styles.emptyContainer,
  );

  return (
    <div className={containerStyles}>
      {details && <Label label={label} />}
      <div className={fileContainerClasses}>
        <AudioInput
          detailsPaths={INFO}
          buttonLabel="Upload sample"
          value={details}
          file={file}
          description={description}
          onRemoveFileClick={handleRemoveFileClick}
          onSelectFile={handleSelectFile}
          moreSamplesBtn={moreSamplesBtn}
          moreSamplesBtnDisabled={moreSamplesBtnDisabled}
          containerClassName={
            !details && !placeholderVisible && styles.emptyInput
          }
        />
        {details && (
          <SampleDetailsForm
            collapsed={formCollapsed}
            values={initialFileDetails}
            updatedValues={updatedDetails}
            onChangeName={handleChangeName}
            onSubmit={handleSubmitDetails}
          />
        )}
      </div>
    </div>
  );
}

SampleManager.propTypes = {
  initialValues: PropTypes.object,
  label: PropTypes.string,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
  talent: PropTypes.object,
  moreSamplesBtn: PropTypes.bool,
  moreSamplesBtnDisabled: PropTypes.bool,
  placeholderVisible: PropTypes.bool,
};
