import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import WizardTabs from '../Wizard/WizardTabs';
import WizardFooter from '../Wizard/WizardFooter';
import { ConfirmationDialog } from '../ConfirmationDialog';

export default function FormEditWizard({ children, hasChanges, resetChanges }) {
  const [page, setPage] = useState(0);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [nextTabKey, setNextTabKey] = useState(null);

  function changePage(index) {
    setPage(index);
    resetChanges();
  }

  function resolveConfirmationDialog() {
    if (nextTabKey !== null) {
      changePage(nextTabKey);
    }
    setShowConfirmationDialog(false);
  }

  function handlePageSelect(key) {
    if (!hasChanges) {
      changePage(key);
      return;
    }

    setNextTabKey(key);
    setShowConfirmationDialog(true);
  }

  const renderChild = (child, index) => {
    const isActive = index === page;

    return cloneElement(child, {
      pageIndex: index,
      isActive,
    });
  };

  return (
    <>
      <WizardTabs onClick={handlePageSelect}>
        {Children.map(children, renderChild)}
      </WizardTabs>
      {showConfirmationDialog && (
        <ConfirmationDialog
          withOverlay
          title="Discard changes?"
          message="Changes made to this section won’t be saved."
          actionBtnLabel="Discard changes"
          cancelBtnLabel="Keep editing"
          onCancel={() => setShowConfirmationDialog(false)}
          onAction={resolveConfirmationDialog}
        />
      )}
    </>
  );
}

FormEditWizard.propTypes = {
  ...WizardFooter.propTypes,
  children: PropTypes.node,
  hasChanges: PropTypes.bool,
  resetChanges: PropTypes.func,
};
