import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import flatten from 'lodash/flatten';
import validator from 'validator';

const FIRST_NAME = 'agentFirstName';
const LAST_NAME = 'agentLastName';
const EMAIL = 'agentEmail';
const NOTIFICATION_SETTING = 'notificationSetting';
const VERTICALS = 'verticals';

const SOCIAL_MEDIA_LINKS = [
  'linkedinLink',
  'instagramLink',
  'twitterLink',
  'facebookLink',
];

function getRelativeProfileLink(agent) {
  const agentId = get(agent, 'id');
  return `/agent/${agentId}`;
}

function getProfileLink(agent) {
  const origin = get(window, 'location.origin');
  return `${origin}${getRelativeProfileLink(agent)}`;
}

function validateAgentAssignment(assignment, selectedVerticals) {
  let error = {};

  if (isEmpty(trim(get(assignment, FIRST_NAME)))) {
    error = { ...error, [FIRST_NAME]: 'First name cannot be empty' };
  }
  if (isEmpty(trim(get(assignment, LAST_NAME)))) {
    error = { ...error, [LAST_NAME]: 'Last name cannot be empty' };
  }
  if (!validator.isEmail(trim(get(assignment, EMAIL)))) {
    error = { ...error, [EMAIL]: 'This is not a valid email' };
  }
  if (isEmpty(trim(get(assignment, EMAIL)))) {
    error = { ...error, [EMAIL]: 'Email cannot be empty' };
  }
  if (isEmpty(trim(get(assignment, NOTIFICATION_SETTING)))) {
    error = {
      ...error,
      [NOTIFICATION_SETTING]: 'Notification settings not provided',
    };
  }

  const duplicates = filter(
    flatten(selectedVerticals),
    (value, index, values) => includes(values, value, index + 1),
  );

  if (!isEmpty(duplicates)) {
    error = {
      ...error,
      [VERTICALS]: `You already have an agent for 
        ${duplicates.join(', ')}`,
    };
  }
  if (isEmpty(get(assignment, VERTICALS))) {
    error = { ...error, [VERTICALS]: 'Category not provided' };
  }

  return error;
}
export default { validateAgentAssignment, SOCIAL_MEDIA_LINKS, getProfileLink };
