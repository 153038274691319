import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import size from 'lodash/size';
import find from 'lodash/find';
import get from 'lodash/get';
import { AvatarNamed, Button } from 'modules/ui';
import { TableCell, TableRow } from '@material-ui/core';
import { AudioPreview } from 'modules/audio';
import { DateUtil } from 'modules/core/services';
import { Colors } from 'modules/theme';
import { getExchangedMessages } from 'modules/audition-messages/redux';
import AudioPreviewPlaceholder from './AudioPreviewPlaceholder';

const styles = {
  statusIcon: {
    marginRight: 10,
    marginBottom: 2,
  },
  messagesCell: {
    verticalAlign: 'top',
    paddingTop: 30,
  },
  nameCell: {
    fontFamily: 'Noto Serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    verticalAlign: 'top',
    paddingTop: 20,
    textDecoration: 'none',
  },
  item: {
    marginBottom: 20,
    width: 220,
    minHeight: 46,
  },
  lastItem: {
    width: 220,
  },
  topCandidateBtnContainer: {
    height: 46,
    width: 220,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionButton: {
    height: 32,
    width: 145,
    background: Colors.tableHover,
  },
  row: { cursor: 'pointer' },
  actionButtonCell: {
    width: 239,
  },
  samplesRows: {
    display: 'flex',
    flexDirection: 'column',
  },
  sampleRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 500,
  },
};

function AuditionsTableRow({
  row,
  producerId,
  auditionId,
  topCandidates,
  onTopCandidateAddClick,
  onTopCandidateRemoveClick,
  onMessagesClick,
  onRowClick,
}) {
  const isSubmission = Array.isArray(row);
  const talent = isSubmission ? row[0].authorAccount.talent : row;
  const talentId = talent.id;

  const messages = useSelector(
    getExchangedMessages(talentId, producerId, auditionId),
  );

  function handleMessagesClick() {
    if (isSubmission) {
      onMessagesClick(talentId);
    }
  }

  return (
    <TableRow style={styles.row}>
      <TableCell
        align="left"
        component={Link}
        to={`/talent/${talent.id}`}
        scope="row"
        style={styles.nameCell}
        onClick={() => onRowClick(talentId)}
      >
        {isSubmission ? (
          <AvatarNamed
            avatarUrl={row[0].authorAccount.talent.profileImage}
            name={row[0].authorAccount.talent.fullName}
          />
        ) : (
          <AvatarNamed avatarUrl={row.profileImage} name={row.fullName} />
        )}
      </TableCell>
      <TableCell
        align="left"
        style={styles.messagesCell}
        onClick={handleMessagesClick}
      >
        {isSubmission ? `Messages (${size(messages)})` : 'No messages'}
      </TableCell>
      <TableCell>
        <div style={styles.samplesRows}>
          {isSubmission ? (
            row.map((submission, index, submissions) => {
              const { audioClipId } = submission;
              const topCandidate = find(topCandidates, {
                talentId,
                audioClipId,
              });
              const isTopCandidate = !!topCandidate;
              return (
                <div style={styles.sampleRowContainer}>
                  <AudioPreview
                    details={submission.audioClip}
                    truncateLength={22}
                    description={DateUtil.formatDate(submission.createdAt)}
                    containerStyle={
                      index < size(submissions) - 1
                        ? styles.item
                        : styles.lastItem
                    }
                  />
                  <div
                    style={
                      index < size(submissions) - 1
                        ? { ...styles.topCandidateBtnContainer, ...styles.item }
                        : styles.topCandidateBtnContainer
                    }
                  >
                    <Button
                      onClick={
                        isTopCandidate
                          ? event =>
                              onTopCandidateRemoveClick(event, topCandidate)
                          : event => onTopCandidateAddClick(event, submission)
                      }
                      disabled={isTopCandidate}
                      secondary={!isTopCandidate}
                      style={styles.actionButton}
                      small
                    >
                      {isTopCandidate ? 'Top Candidate' : '+ Top Candidate'}
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <AudioPreviewPlaceholder />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

AuditionsTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  producerId: PropTypes.string.isRequired,
  auditionId: PropTypes.string.isRequired,
  topCandidates: PropTypes.array.isRequired,
  onTopCandidateAddClick: PropTypes.func.isRequired,
  onTopCandidateRemoveClick: PropTypes.func.isRequired,
  onMessagesClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
export default memo(AuditionsTableRow);
