import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'modules/ui';
import styles from './EmptySamplePlaceholder.module.scss';

export default function EmptySamplePlaceholder({
  index,
  onAddClick,
  containerClassName,
}) {
  const title = `Sample #${index}`;

  const containerClasses = classNames(
    styles.container,
    styles[`container--${index}`],
    containerClassName,
  );

  return (
    <div className={containerClasses}>
      <div className={styles.circle} />
      <div className={styles.text}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <Button secondary onClick={onAddClick} className={styles.button}>
        Add sample
      </Button>
    </div>
  );
}

EmptySamplePlaceholder.propTypes = {
  onAddClick: PropTypes.func,
  index: PropTypes.number,
  containerClassName: PropTypes.string,
};
