import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import Dictionary from 'modules/dictionary';
import {
  CheckboxInput,
  ManagedForm,
  MultiSelect,
  PageLoader,
  ProfessionalAwardInput,
  Repeater,
  Select,
  TextInput,
  BipartiteTextInput,
} from 'modules/ui';
import { Talent } from '../services';
import Validator from '../validator';
import GdprModal, { GDPR_UNION } from './GdprModal';
import { ProfessionalInfoGrid } from './form-grids';

const styles = {
  ownsHomeStudio: {
    whiteSpace: 'nowrap',
  },
  professionalAwards: {
    marginLeft: 10,
  },
  noLabel: {
    marginTop: 34,
  },
  unionsArrow: {
    top: 51,
  },
  homeStudioEditing: {
    marginTop: 50,
  },
};

const MAX_ADDITIONAL_WEBSITES = 4;

export default function ProfessionalInfoForm({
  onSubmit,
  onRemovePastRecordingField,
  talent,
  onSubmitUnionGdprConsent,
  onCloseUnionGdprConsent,
  ...otherProps
}) {
  const { unionGdprAcceptedAt } = talent;
  const [unionsDisabled, setUnionsDisabled] = useState(isEmpty(talent.unions));
  const [isUnionMemberDisabled, setIsUnionMemberDisabled] = useState(
    !unionsDisabled,
  );
  const [removeErrorsKeys, setRemoveErrorsKeys] = useState([]);
  const [gdprModalOpen, setGdprModalOpen] = useState(
    Talent.isUnionsGdprNotAccepted(talent),
  );

  function handleIsUnionMemberChange(isUnionMember) {
    setUnionsDisabled(!isUnionMember);
  }

  function handleValueChange(formValues) {
    if (!isUnionMemberDisabled && !isEmpty(formValues.unions)) {
      setIsUnionMemberDisabled(true);
    }
    if (isUnionMemberDisabled && isEmpty(formValues.unions)) {
      setIsUnionMemberDisabled(false);
    }
    if (!formValues.isUnionMember) {
      setRemoveErrorsKeys(['unions']);
    }
  }

  if (!talent) {
    return <PageLoader />;
  }

  const additionalWebsites = compact([
    talent.customUrl1,
    talent.customUrl2,
    talent.customUrl3,
    talent.customUrl4,
  ]);

  return (
    <>
      <ManagedForm
        withPrompt
        title="Professional Info"
        description="Tell us about your work"
        submitButtonLabel="Save professional info"
        onChangeValues={handleValueChange}
        onSubmit={onSubmit}
        removeErrorsKeys={removeErrorsKeys}
        TemplateComponent={ProfessionalInfoGrid}
        initialValues={Object.assign(talent, {
          isUnionMember: !isEmpty(talent.unions),
          additionalWebsites,
        })}
        {...otherProps}
      >
        <CheckboxInput
          label="Unions"
          name="isUnionMember"
          text="I am a union member"
          disabled={isUnionMemberDisabled}
          onChange={handleIsUnionMemberChange}
        />
        <MultiSelect
          name="unions"
          placeholder="Unions"
          collection={Dictionary.UNIONS}
          options={Dictionary.UNION_OPTIONS}
          style={styles.noLabel}
          arrowStyle={styles.unionsArrow}
          disabled={unionsDisabled}
          onClick={
            !unionGdprAcceptedAt &&
            (() => {
              setGdprModalOpen(true);
            })
          }
        />
        <MultiSelect
          label="Preferred audiobook genres"
          name="preferredGenres"
          placeholder="Select a genre"
          collection={Dictionary.AUDIOBOOKS_GENRES}
          options={Dictionary.AUDIOBOOK_GENRE_OPTIONS}
        />
        <Select
          autoFocus
          label="Audiobooks recorded"
          name="recordingExperienceId"
          placeholder="No. of audiobooks recorded"
          options={Dictionary.RECORDING_EXPERIENCE_OPTIONS}
        />
        <CheckboxInput
          label="Recording options"
          name="ownsHomestudio"
          text="I have a home recording studio"
          style={styles.ownsHomeStudio}
          labelDisabled={false}
        />
        <CheckboxInput
          name="homeStudioEditing"
          text="I can do editing at home"
          style={styles.homeStudioEditing}
        />
        <CheckboxInput
          label="Training"
          name="trainedInMotionCapture"
          text="I am trained in Mo-cap (Motion capture)"
        />
        <Repeater
          validate={Validator.validateProfessionalAward}
          label="Awards"
          name="professionalAwards"
          Component={ProfessionalAwardInput}
          style={styles.professionalAwards}
        />
        <TextInput
          link
          label="Your website"
          name="websiteLink"
          placeholder="Add a link"
        />
        <Repeater
          validate={Validator.validateAdditionalWebsite}
          label="Additional links"
          name="additionalWebsites"
          Component={BipartiteTextInput}
          style={styles.professionalAwards}
          maxFields={MAX_ADDITIONAL_WEBSITES}
        />
        <TextInput
          link
          label="YouTube"
          name="youtubeLink"
          placeholder="youtube.com/username"
        />
        <TextInput
          link
          label="X (formerly Twitter)"
          name="twitterLink"
          placeholder="x.com/username"
        />
        <TextInput
          link
          label="Instagram"
          name="instagramLink"
          placeholder="instagram.com/username"
        />
        <TextInput
          link
          label="Facebook"
          name="facebookLink"
          placeholder="facebook.com/username"
        />
        <TextInput
          link
          label="Linkedin"
          name="linkedinLink"
          placeholder="linkedin.com/in/username"
        />
        <TextInput
          link
          label="IMDB"
          name="imdbLink"
          placeholder="imdb.com/user/username"
        />
      </ManagedForm>
      {gdprModalOpen && (
        <GdprModal
          type={GDPR_UNION}
          onSubmitUnionGdprConsent={onSubmitUnionGdprConsent}
          onCloseUnionGdprConsent={onCloseUnionGdprConsent}
          handleModalOpen={isOpen => {
            setGdprModalOpen(isOpen);
          }}
          isDataFilledWithoutConsent={Talent.isUnionsGdprNotAccepted(talent)}
        />
      )}
    </>
  );
}

ProfessionalInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  talent: PropTypes.object,
  onRemovePastRecordingField: PropTypes.func,
  onRemoveAwardField: PropTypes.func,
  onSubmitUnionGdprConsent: PropTypes.func.isRequired,
  onCloseUnionGdprConsent: PropTypes.func.isRequired,
};
