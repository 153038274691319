import { createAction, createReducer } from 'redux-starter-kit';
import createSelector from 'selectorator';

const INITIAL_STATE = {
  notification: null,
  duration: 5000,
};

export const showNotification = createAction('notifications/set');
export const clearNotification = createAction('notifications/clear');

export const reducer = createReducer(INITIAL_STATE, {
  [showNotification]: (state, action) => {
    state.notification = action.payload;
  },
  [clearNotification]: state => {
    state.notification = null;
  },
});

export const getNotification = createSelector(['notifications.notification']);
