import moment from 'moment';
import toInteger from 'lodash/toInteger';
import find from 'lodash/find';
import { OptionUtil } from 'modules/ui';
import Dictionary from 'modules/dictionary';

const AVERAGE_WORDS_BY_SECONDS = 3;

function convertLengthToSeconds(length, lengthType) {
  if (!length) {
    return null;
  }

  if (lengthType === 'words') {
    return Math.round(toInteger(length) / AVERAGE_WORDS_BY_SECONDS);
  }

  return null;
}

function convertLengthToDuration(length, lengthType) {
  const seconds = convertLengthToSeconds(length, lengthType);

  if (!seconds) {
    return null;
  }

  if (seconds >= 3600) {
    return moment
      .utc(moment.duration(seconds, 'seconds').asMilliseconds())
      .format('h[h]');
  }

  if (seconds >= 60) {
    return moment
      .utc(moment.duration(seconds, 'seconds').asMilliseconds())
      .format('m[min]');
  }

  return moment
    .utc(moment.duration(seconds, 'seconds').asMilliseconds())
    .format('s[sec]');
}

function resolveStatsForProject(projectsStats, projectId) {
  return find(
    projectsStats,
    projectStats => projectStats.projectId === projectId,
  );
}

function createProjectOptions(projects) {
  return OptionUtil.getOptions(projects, project =>
    OptionUtil.mapOptionFromModel(project, 'id', 'title'),
  );
}

function getGenresFromVertical(vertical) {
  if (vertical === Dictionary.VERTICALS.AUDIOBOOKS) {
    return Dictionary.AUDIOBOOK_GENRE_OPTIONS;
  }
  if (vertical === Dictionary.VERTICALS.VIDEOGAMES) {
    return Dictionary.VIDEOGAME_GENRE_OPTIONS;
  }

  return null;
}

function verticalHasGenres(vertical) {
  return getGenresFromVertical(vertical) !== null;
}

export default {
  convertLengthToDuration,
  createProjectOptions,
  resolveStatsForProject,
  getGenresFromVertical,
  verticalHasGenres,
};
