import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { IconButton, DropdownMenu, MenuItem } from 'modules/ui';
import { Colors } from 'modules/theme';
import { OfferPreviewModal } from 'modules/offers';
import { Offer } from 'modules/offers/services';
import EditNoteDialog from 'modules/project-roles/fragments/EditNoteDialog';
import { RemoveTopCandidateConfirmation } from 'modules/project-roles/fragments';
import { SetOfferStatusConfirmation } from 'modules/offers/components';

const ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
};

const TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
};

const styles = {
  iconButton: {
    background: 'transparent',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  textStyle: {
    color: Colors.secondary,
  },
};

function TopCandidateManagerMenu({
  topCandidate,
  producer,
  project,
  role,
  selectedMenuOptions,
  offerId,
  disabled,
  onRemoveTalent,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const [
    removeTopCandidateConfirmationOpen,
    setRemoveTopCandidateConfirmationOpen,
  ] = useState(false);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
  const [previewOfferModalOpen, setPreviewOfferModalOpen] = useState(false);
  const [currentDialogStatus, setCurrentDialogStatus] = useState(null);

  function handleOptionsClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleDialogClose(setDialogOpen) {
    setDialogOpen(false);
  }

  function handleDialogOpen(setDialogOpen) {
    handleClose();
    setDialogOpen(true);
  }

  const defaultMenuOptions = {
    removeFromList: {
      text: 'Remove from list',
      icon: 'removeFromList',
      onClick: () => handleDialogOpen(setRemoveTopCandidateConfirmationOpen),
    },
    editNote: {
      text: 'Edit note',
      icon: 'edit',
      onClick: () => handleDialogOpen(setEditNoteModalOpen),
    },
    markHired: {
      text: 'Mark as hired',
      icon: 'tick',
      iconColor: Colors.secondary,
      onClick: () =>
        setCurrentDialogStatus(Offer.RESOLVED_STATUSES_PRODUCER.ACCEPTED),
      textStyle: styles.textStyle,
    },
    cancelOffer: {
      text: 'Cancel offer',
      icon: 'close',
      onClick: () =>
        setCurrentDialogStatus(Offer.RESOLVED_STATUSES_PRODUCER.CANCELED),
    },
    previewOffer: {
      text: 'Preview offer',
      icon: 'offer',
      onClick: () => handleDialogOpen(setPreviewOfferModalOpen),
    },
    setDeclined: {
      text: 'Set to declined',
      icon: 'offerDeclined',
      onClick: () =>
        setCurrentDialogStatus(Offer.RESOLVED_STATUSES_PRODUCER.DECLINED),
    },
    setNegotiating: {
      text: 'Set to negotiating',
      icon: 'offers',
      iconColor: Colors.secondary,
      onClick: () =>
        setCurrentDialogStatus(Offer.RESOLVED_STATUSES_PRODUCER.NEGOTIATING),
      textStyle: styles.textStyle,
    },
  };

  return (
    <>
      <div>
        <IconButton
          style={styles.iconButton}
          onClick={handleOptionsClick}
          iconName="moreVertical"
          iconColor="black"
          iconSize={24}
          disabled={disabled}
        />
        <DropdownMenu
          open={open}
          width={172}
          onClose={handleClose}
          anchorEl={anchorEl}
          transformOrigin={TRANSFORM_ORIGIN}
          anchorOrigin={ANCHOR_ORIGIN}
          arrowRightPosition="14px"
        >
          {map(selectedMenuOptions, option =>
            cloneElement(<MenuItem />, defaultMenuOptions[option]),
          )}
        </DropdownMenu>
      </div>
      <div>
        {removeTopCandidateConfirmationOpen && (
          <RemoveTopCandidateConfirmation
            topCandidate={topCandidate}
            producer={producer}
            onRemoveTalent={onRemoveTalent}
            project={project}
            role={role}
            onCancel={() =>
              handleDialogClose(setRemoveTopCandidateConfirmationOpen)
            }
          />
        )}
        {editNoteModalOpen && (
          <EditNoteDialog
            note={topCandidate.note}
            producer={producer}
            project={project}
            topCandidate={topCandidate}
            onCancel={() => handleDialogClose(setEditNoteModalOpen)}
          />
        )}
        {currentDialogStatus && (
          <SetOfferStatusConfirmation
            nextOfferStatus={Offer.getStatusForProducer(currentDialogStatus)}
            offerId={offerId}
            project={project}
            onCancel={() => setCurrentDialogStatus(null)}
            role={role}
            topCandidate={topCandidate}
          />
        )}
        <OfferPreviewModal
          offerId={offerId}
          open={!!previewOfferModalOpen}
          onClose={() => handleDialogClose(setPreviewOfferModalOpen)}
        />
      </div>
    </>
  );
}

TopCandidateManagerMenu.propTypes = {
  topCandidate: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  producer: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  selectedMenuOptions: PropTypes.array.isRequired,
  offerId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onRemoveTalent: PropTypes.func.isRequired,
};
export default TopCandidateManagerMenu;
