import React, { memo, useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input } from 'modules/ui';
import { Utils } from 'modules/core/services';
import styles from './TextInput.module.scss';

function TextInput({
  disabled,
  error,
  className,
  name,
  type,
  light,
  id,
  value,
  width,
  autoFocus,
  placeholder,
  onChange,
  onClick,
  link,
  lightInput,
  style,
  setFormValue,
  containerStyle,
  setRef,
  onFocus,
  autoComplete,
  maskFunction,
  ...otherProps
}) {
  const inputRef = useRef();

  const inputClasses = classNames(
    styles.input,
    disabled && styles.disabled,
    error && styles.error,
    link && styles.link,
    lightInput && styles['input--light'],
    className,
  );

  function handleChange(event) {
    const newValue = get(event, 'target.value');

    if (maskFunction && !maskFunction(newValue)) {
      event.stopPropagation();
      return;
    }

    if (setFormValue) {
      setFormValue(name, newValue);
    }

    if (onChange) {
      onChange(event);
    }
  }

  const preventAutocomplete =
    Utils.shouldPreventAutocomplete(name) && !setRef && !autoComplete;

  return (
    <Input
      disabled={disabled}
      error={error}
      style={containerStyle}
      autoComplete={
        preventAutocomplete ? Utils.PREVENT_AUTOCOMPLETE : autoComplete
      }
      setRef={preventAutocomplete ? inputRef : setRef}
      onFocus={
        preventAutocomplete
          ? event => {
              Utils.hideAutocompleteOnFocus(inputRef);
              if (onFocus) {
                onFocus(event);
              }
            }
          : onFocus
      }
      {...otherProps}
    >
      <input
        style={style}
        value={value || ''}
        width={width}
        id={id}
        name={name}
        className={inputClasses}
        disabled={disabled}
        type={type !== 'number' ? type : 'text'}
        placeholder={placeholder}
        onChange={handleChange}
        onClick={onClick}
      />
    </Input>
  );
}

TextInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  name: PropTypes.string,
  light: PropTypes.bool,
  setFormValue: PropTypes.func,
  onChange: PropTypes.func,
  link: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  lightInput: PropTypes.bool,
  type: PropTypes.string,
  containerStyle: PropTypes.object,
  style: PropTypes.object,
  maskFunction: PropTypes.func,
};

export default memo(TextInput);
