import { createReducer } from 'redux-starter-kit';
import remove from 'lodash/remove';
import createSelector from 'selectorator';
import { createThunk } from 'redux-scope';
import {
  fetchSavedAuditions,
  fetchNdaAcceptance,
  acceptNdaAction,
  saveAuditionAction,
  unsaveAuditionAction,
  applyToAudition,
} from './api';

const INITIAL_STATE = {
  savedAuditions: [],
  loadingSavedAuditions: false,
};

export const saveAudition = createThunk(
  saveAuditionAction,
  'save-audition',
  'auditionsTalents',
);

export const unsaveAudition = createThunk(
  unsaveAuditionAction,
  'unsave-audition',
  'auditionsTalents',
);

export const loadSavedAuditions = createThunk(
  fetchSavedAuditions,
  'saved',
  'auditionsTalents',
);

export const loadNdaAcceptance = createThunk(
  fetchNdaAcceptance,
  'fetchNda',
  'auditionsTalents',
);
export const acceptNda = createThunk(
  acceptNdaAction,
  'acceptNda',
  'auditionsTalents',
);

export const apply = applyToAudition;

export const reducer = createReducer(INITIAL_STATE, {
  // Saved
  [loadSavedAuditions.type.request]: state => {
    state.loadingSavedAuditions = true;
  },
  [loadSavedAuditions.type.success]: (state, action) => {
    state.loadingSavedAuditions = false;
    state.savedAuditions = action.payload.data;
  },
  [loadSavedAuditions.type.error]: state => {
    state.loadingSavedAuditions = false;
  },
  [saveAudition.type.request]: state => {
    state.loadingSavedAuditions = true;
  },
  [saveAudition.type.success]: (state, action) => {
    state.loadingSavedAuditions = false;

    // TODO: It's not working, cause we don't get an audition in the payload
    // Backend has got a task for this: AHAB-719
    state.savedAuditions = [...state.savedAuditions, action.payload.data];
  },
  [saveAudition.type.error]: state => {
    state.loadingSavedAuditions = false;
  },
  [unsaveAudition.type.request]: state => {
    state.loadingSavedAuditions = true;
  },
  [unsaveAudition.type.success]: (state, action) => {
    const auditionId = action.request[1];
    state.loadingSavedAuditions = false;
    remove(state.savedAuditions, audition => audition.id === auditionId);
  },
  [unsaveAudition.type.error]: state => {
    state.loadingSavedAuditions = false;
  },
});

export const getSavedAuditions = createSelector([
  'auditionsTalents.savedAuditions',
]);

export const getLoadingSavedAuditions = createSelector([
  'auditionsTalents.loadingSavedAuditions',
]);
