import includes from 'lodash/includes';
import { AUDITION_STATUSES } from '../../auditions/const';

function resolveAuditionStatus(
  audition,
  savedAuditionIds,
  invitedAuditionIds,
  appliedAuditionIds,
) {
  if (audition.suggested) {
    return AUDITION_STATUSES.SUGGESTED;
  }

  const appliedAudition = includes(appliedAuditionIds, audition.id);
  if (appliedAudition) {
    return AUDITION_STATUSES.APPLIED;
  }

  const invitedAudition = includes(invitedAuditionIds, audition.id);
  if (invitedAudition) {
    return AUDITION_STATUSES.INVITED;
  }

  const savedAudition = includes(savedAuditionIds, audition.id);
  if (savedAudition) {
    return AUDITION_STATUSES.SAVED;
  }

  return null;
}

export default {
  resolveAuditionStatus,
};
