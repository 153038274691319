import React from 'react';
import { useTealiumView } from 'modules/accounts';
import { NavigationTabLink } from 'modules/ui';

export default function SettingsTabs() {
  useTealiumView('Settings Page');
  return (
    <>
      <NavigationTabLink exact to="/settings/change-password">
        Change Password
      </NavigationTabLink>
      <NavigationTabLink exact to="/settings/change-email">
        Change Email
      </NavigationTabLink>
      <NavigationTabLink exact to="/settings/delete-account">
        Delete Account
      </NavigationTabLink>
    </>
  );
}
