import isEmpty from 'lodash/isEmpty';
import withQuery from 'with-query';
import Config from 'modules/config';
import { isAuthenticated, isAuthenticationValid, getToken } from 'modules/auth';
import { setDisablePreventClose } from 'modules/core';
import Mapper from './mapper';
import { replaceHistoryWith } from '../utils';
import { store } from '../../../../store';

const EMPTY_PAYLOAD_HTTP_STATUSES = [204];

export const SET_SERVER_ERROR = '@@core/serverError/set';
export const RESET_SERVER_ERROR = '@@core/serverError/reset';

function getJsonResponse(response) {
  if (EMPTY_PAYLOAD_HTTP_STATUSES.includes(response.status)) {
    return Promise.resolve(null);
  }

  return response.json();
}

function denormalizeResponse(response) {
  if (!response) {
    return null;
  }

  if (!isEmpty(response.errors)) {
    throw Mapper.mapErrors(response.errors);
  }

  return Mapper.modelToView(response);
}

function withOptions(config) {
  return {
    ...config,
    headers: {
      ...config.headers,
    },
  };
}

async function callApi(endpoint, config) {
  const url = `${Config.apiEndpoint}/v1/${endpoint}`;
  const options = withOptions(config);

  if (await isAuthenticated()) {
    const accessToken = await getToken();
    if (await isAuthenticationValid()) {
      options.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      store.dispatch(setDisablePreventClose(true));
      return replaceHistoryWith('/');
    }
  }

  return fetch(url, options)
    .then(getJsonResponse)
    .then(denormalizeResponse);
}

function callAmazonApi(endpoint, config) {
  return fetch(endpoint, withOptions(config));
}

export function find(endpoint, params, options = {}, headers = {}) {
  return callApi(withQuery(endpoint, params, options), {
    method: 'GET',
    ...headers,
  });
}

export function update(endpoint, item, resourceType, relationships, params) {
  const view = { ...item, resourceType };
  const body = Mapper.viewToModel(view, relationships);

  const config = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/vnd.api+json' },
    body: JSON.stringify(body),
  };

  return callApi(withQuery(endpoint, params), config);
}

export function deleteResource(endpoint) {
  const config = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/vnd.api+json' },
  };

  return callApi(endpoint, config);
}

// DB upload
export function create(endpoint, item, relationships, params, headers = {}) {
  const body = Mapper.viewToModel(item, relationships);
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      ...headers,
    },
    body: body && JSON.stringify(body),
  };

  return callApi(withQuery(endpoint, params), config);
}

// S3 upload
export function upload(endpoint, file) {
  const config = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/octet-stream' },
    body: file,
  };

  return callAmazonApi(endpoint, config);
}

export function createRaw(endpoint, body, params) {
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
    body: body && JSON.stringify(body),
  };
  return callApi(withQuery(endpoint, params), config);
}

export function deleteRaw(endpoint, body, params) {
  const config = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
    body: body && JSON.stringify(body),
  };

  return callApi(withQuery(endpoint, params), config);
}

export default {
  find,
  create,
  createRaw,
  update,
  upload,
  deleteResource,
  deleteRaw,
};
