import { Api } from 'modules/core';
import omit from 'lodash/omit';
import toString from 'lodash/join';

const AGENT_ASSIGNMENT_TYPE = 'talent-agent-assignments';

const AGENT_INCLUDES = ['country', 'state'];

function getAgentAssignmentsURL() {
  return AGENT_ASSIGNMENT_TYPE;
}

export function fetchAgent(agentId) {
  if (!agentId) {
    return Promise.resolve();
  }

  const endpoint = `agents/${agentId}`;
  const params = { include: toString(AGENT_INCLUDES) };

  return Api.find(endpoint, params);
}

export function postAgent(firstName, lastName) {
  const endpoint = 'agents';
  const agent = { resourceType: 'agents', firstName, lastName };

  return Api.create(endpoint, agent);
}

export function patchAgent(agentId, values) {
  const relationshipPaths = ['country', 'state'];
  const { countryId, stateId } = values;
  const updatedValues = omit(values, relationshipPaths);
  const relationships = {
    ...(countryId && {
      country: {
        data: { type: 'countries', id: countryId },
      },
    }),
    ...(stateId && {
      state: {
        data: { type: 'states', id: stateId },
      },
    }),
  };

  const params = { include: toString(AGENT_INCLUDES) };

  const endpoint = `agents/${agentId}`;
  return Api.update(endpoint, updatedValues, 'agents', relationships, params);
}

export function fetchAgentAssignments() {
  const endpoint = `${getAgentAssignmentsURL()}/all`;

  return Api.find(endpoint);
}

export function postAgentAssignment(talentId, values) {
  const endpoint = getAgentAssignmentsURL();
  const assignment = {
    resourceType: AGENT_ASSIGNMENT_TYPE,
    ...values,
  };
  const relationships = {
    ...(talentId && {
      talent: {
        data: {
          type: 'talents',
          id: talentId,
        },
      },
    }),
  };

  return Api.create(endpoint, assignment, relationships);
}

export function patchAgentAssignment(talentId, assignmentId, values) {
  const endpoint = `${getAgentAssignmentsURL()}/${assignmentId}`;
  const relationships = {
    ...(talentId && {
      talent: {
        data: {
          type: 'talents',
          id: talentId,
        },
      },
    }),
  };
  return Api.update(endpoint, values, AGENT_ASSIGNMENT_TYPE, relationships);
}

export function removeAgentAssignment(assignmentId) {
  const endpoint = `${getAgentAssignmentsURL()}/${assignmentId}`;

  return Api.deleteResource(endpoint);
}

export function sendClientInvite(email, message = '') {
  const endpoint = 'agents/inviteClient';
  const body = {
    resourceType: 'agent-invite-client-actions',
    email,
    message,
  };

  return Api.create(endpoint, body);
}

export function fetchAgentAssignmentsByAgentId(agentId) {
  const endpoint = `${getAgentAssignmentsURL()}?agentId=${agentId}`;

  return Api.find(endpoint);
}
