import React from 'react';
import { useHistory } from 'react-router-dom';

import { Colors } from 'modules/theme';

const styles = {
  footerInfo: {
    textAlign: 'center',
    marginTop: 65,
    color: Colors.grayishBlue,
  },
  talentSearch: {
    paddingLeft: 10,
    color: Colors.secondary,
    cursor: 'pointer',
  },
};

export default function NavigateToTalentSearchFooterOption() {
  const history = useHistory();

  function navigateToSearch() {
    if (history) {
      history.push('/search');
    }
  }

  return (
    <div style={styles.footerInfo}>
      Can't find the voice you're looking for?{' '}
      <span role="link" style={styles.talentSearch} onClick={navigateToSearch}>
        Go to talent search
      </span>
    </div>
  );
}
