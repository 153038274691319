import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AudioPreview } from 'modules/audio';
import { Colors } from 'modules/theme';

import Message from './Message';

import classes from './SentMessage.module.scss';

const styles = {
  fileTitle: {
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: '24px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: Colors.white,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  sliderStyle: {
    color: Colors.white,
  },
};

function SentMessage({ message, displayTimestamps }) {
  const { text, audioClip, createdAt } = message;
  return (
    <Message className={classes.dark}>
      {text}
      {audioClip && (
        <div className={classes.sampleContainer}>
          <AudioPreview
            details={audioClip}
            titleStyle={styles.fileTitle}
            durationLabelClassName={classes.sampleDurationLabel}
            sliderStyle={styles.sliderStyle}
          />
        </div>
      )}
      {displayTimestamps && (
        <p className={classes.timestampText}>
          {moment(createdAt).format('MMM D, YYYY HH:mm')}
        </p>
      )}
    </Message>
  );
}

SentMessage.propTypes = {
  message: PropTypes.object.isRequired,
  displayTimestamps: PropTypes.bool,
};

SentMessage.defaultProps = {
  displayTimestamps: false,
};

export default SentMessage;
